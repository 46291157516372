import loadable from '@loadable/component';
import React from 'react';

import * as loaderNames from '../../consts/loader/loaderNames';
import LoadableComponentLoader from '../LoaderGlobal/LoadableComponentLoader';

// IA: webpackChunkName - this is webpack magic comment,
//     it is used to merge multiple components into one chunk
//
// lazy-chunk.s8e - Standalone version only pages(s8e - standalone)
// lazy-chunk.c5t - (c5t - connect)

const AboutDevicePage = loadable(() =>
    import(
        /* webpackPrefetch: true */
        /* webpackChunkName: "lazy-chunk.c5t" */
        '../AboutDevicePage/AboutDevicePage'
    )
);

const AgeGatePage = loadable(() => import(/* webpackChunkName: "lazy-chunk.s8e" */ '../AgeGatePage/AgeGatePage'));
const AgeVerificationFailedPage = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.s8e" */ '../AgeVerificationFailedPage/AgeVerificationFailedPage')
);
const BenefitPage = loadable(() => import(/* webpackChunkName: "lazy-chunk.c5t" */ '../BenefitPage/BenefitPage'));
const AppIntroPage = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.comp_intro" */ '../BrowserCompatibility/AppIntroPage/AppIntroPage')
);
const NotCompatibleBrowserPage = loadable(() =>
    import(
        /* webpackChunkName: "lazy-chunk.comp_notComp" */ '../BrowserCompatibility/NotCompatibleBrowserPage/NotCompatibleBrowserPage'
    )
);
const CleaningGuidePage = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.c5t" */ '../CleaningGuidePages/CleaningGuidePage')
);
const DeviceActivationPage = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.c5t" */ '../DeviceActivationPage/DeviceActivationPage')
);
const DeviceRegistrationPage = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.c5t" */ '../DeviceRegistrationPage/DeviceRegistrationPage')
);
const DeviceSyncPage = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.c5t" */ '../DeviceSyncPage/DeviceSyncPage')
);
const PreferenceCenterSettingsPage = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.c5t" */ '../DeviceSettingsPage/components/PreferenceCenterSettingsPage')
);
const DeviceSettingsPage = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.c5t" */ '../DeviceSettingsPage/DeviceSettingsPage')
);
const ErrorHandlingPage = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.c5t" */ '../ErrorHandlingPage/ErrorHandlingPage')
);
const LegalDisclaimerPage = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.c5t" */ '../LegalDisclaimerPage/LegalDisclaimerPage')
);
const LoginPage = loadable(() => import(/* webpackChunkName: "lazy-chunk.s8e" */ '../LoginPage/LoginPage'));
const MyDevicesPage = loadable(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "lazy-chunk.c5t" */
            '../MyDevicesPage/MyDevicesPage'
        ),
    {
        fallback: <LoadableComponentLoader name={loaderNames.DEVICE_LIST_LOADER} isOpaque delay={200} />,
    }
);
const NoDevicesPage = loadable(() => import(/* webpackChunkName: "lazy-chunk.c5t" */ '../NoDevicesPage/NoDevicesPage'));
const PairingAlertPage = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.c5t" */ '../PairingAlertPage/PairingAlertPage')
);
const PairingGuidePage = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.c5t" */ '../PairingGuidePage/PairingGuidePage')
);
const PairingSuccessPage = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.c5t" */ '../PairingSuccessPage/PairingSuccessPage')
);
const ResetDevicePage = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.c5t" */ '../ResetDevicePage/ResetDevicePage')
);
const SelectMarketPage = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.s8e" */ '../SelectMarketPage/SelectMarketPage')
);
const SelectYourDevicePage = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.c5t" */ '../SelectYourDevicePage/SelectYourDevicePage')
);
const TipsAndTricksPage = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.s8e" */ '../TipsAndTricksPage/TipsAndTricksPage')
);
const TutorialIntroPage = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.c5t" */ '../TutorialPages/TutorialIntroPage/TutorialIntroPage')
);
const TutorialList = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.c5t" */ '../TutorialPages/TutorialList/TutorialList')
);
const TutorialPage = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.c5t" */ '../TutorialPages/TutorialPage/TutorialPage')
);
const UpdateFWPage = loadable(() => import(/* webpackChunkName: "lazy-chunk.c5t" */ '../UpdateFWPage/UpdateFWPage'));
const WelcomeUserPage = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.s8e" */ '../WelcomeUserPage/WelcomeUserPage')
);

export default {
    AboutDevicePage,
    AgeGatePage,
    AgeVerificationFailedPage,
    AppIntroPage,
    BenefitPage,
    CleaningGuidePage,
    DeviceActivationPage,
    DeviceSyncPage,
    DeviceRegistrationPage,
    DeviceSettingsPage,
    ErrorHandlingPage,
    LegalDisclaimerPage,
    LoginPage,
    MyDevicesPage,
    NoDevicesPage,
    NotCompatibleBrowserPage,
    PairingAlertPage,
    PairingGuidePage,
    PairingSuccessPage,
    PreferenceCenterSettingsPage,
    ResetDevicePage,
    SelectMarketPage,
    SelectYourDevicePage,
    TipsAndTricksPage,
    TutorialIntroPage,
    TutorialList,
    TutorialPage,
    UpdateFWPage,
    WelcomeUserPage,
};
