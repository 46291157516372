import appConfig from '../../config/appConfig';
import * as httpErrorsTypes from '../../consts/app/httpErrorsTypes';
import * as scpMethods from '../../consts/scp/scpMethods';
import {setMwDeviceInternalId} from '../../state/ducks/mwDevice';
import {selectMwDeviceInternalId, selectMwDeviceIsIdentified} from '../../state/selectors/mwDevice';
import {dispatch, getState} from '../../state/store';
import urlUtils from '../../utils/urlUtils';
import appInstanceService from '../app/appInstanceService';
import backendService from '../app/backendService';
import authDataService from '../auth/authDataService';
import gamClient from '../gam/gamClient';
import iccService from '../icc/iccService';
import consoleLog from '../logger/consoleLogger';
import log from '../logger/log';
import {validateTokenMapping} from '../mapping/scpMappings';
import marketTypeService from '../marketTypeService';
import server from '../server/server';
import storageService from '../storage/storageService';
import cloudWatchProvider from './cloudWatchProvider';

let credentials = null;
let isAwsCredentialsRequested = false;
let currentAwsCredentialsRequest = null;

const getCredentials = async (fetchNew) => {
    if (currentAwsCredentialsRequest) {
        await currentAwsCredentialsRequest;
    } else if (fetchNew || !credentials) {
        await fetchAwsCredentials();
    }

    return credentials;
};

const getRequestConfig = async (isDCE2) => {
    const state = getState();
    const authToken = await authDataService.getAuthToken();
    const appInstanceId = appInstanceService.getAppInstanceId();
    const isIdentified = selectMwDeviceIsIdentified(state);
    const deviceSerialNumber = isIdentified ? selectMwDeviceInternalId(state) : undefined;
    const isGAMBackend = backendService.isGamBackend();
    const {codentify} = isDCE2 ? storageService.getDeviceCodentifyFactoryFromLocalStorage() || {} : {};

    const data = {
        app_id: appConfig.getIccApiHeader_a(),
        app_instance_id: appInstanceId,
        cloudwatch_logging: appConfig.getIsAwsLoggingEnabled(),
        dusn: deviceSerialNumber,
        market_iso: appConfig.getScpCloudTopicMarket(),
        market_iso_prefix: appConfig.getScpCloudTopicMarketPrefix(),
        codentify,
        version: appConfig.getScpCloudVersion(),
    };

    const getHeaders = () => {
        let headers;

        if (isGAMBackend) {
            const gigyaToken = gamClient.getGamGigyaToken();
            // eslint-disable-next-line no-undef
            const gigyaApiKey = gigya.apiKey;

            headers = {
                Token: gigyaToken,
                'Api-Key': gigyaApiKey,
            };
        } else {
            headers = {Token: authToken};
        }
        return headers;
    };

    const headers = getHeaders();

    return {data, headers};
};

const getScpApiUrl = () => {
    const isGAMBackend = backendService.isGamBackend();

    return urlUtils.join(
        appConfig.getScpApiUrl(),
        isGAMBackend ? scpMethods.VALIDATE_SCP_TOKEN_V2 : scpMethods.VALIDATE_SCP_TOKEN
    );
};

const fetchAwsCredentials = async () => {
    credentials = null;
    isAwsCredentialsRequested = true;
    const isDCE2 = marketTypeService.isDCE2();
    const requestConfig = await getRequestConfig(isDCE2);
    const scpApiUrl = getScpApiUrl();

    try {
        currentAwsCredentialsRequest = server.post(scpApiUrl, requestConfig);
        const response = await currentAwsCredentialsRequest;

        currentAwsCredentialsRequest = null;

        isAwsCredentialsRequested = false;

        log.debug(`fetchAwsCredentials: fetchAwsCredentials has successful response`);

        return onResponse(response);
    } catch (e) {
        currentAwsCredentialsRequest = null;
        isAwsCredentialsRequested = false;
        consoleLog.info(`awsAuthenticationService: fetchAwsCredentials, error: ${e}`);

        return onError(e);
    }
};

const setInternalId = ({dusn}) => {
    const isIdentified = selectMwDeviceIsIdentified(getState());

    dispatch(setMwDeviceInternalId({deviceSerialNumber: dusn, isIdentified}));
};

const onResponse = (response) => {
    const mappedData = validateTokenMapping(response.data);

    setInternalId(mappedData);
    updateCredentials(mappedData);

    return mappedData;
};

const onError = async (e) => {
    if (e?.response?.status === httpErrorsTypes.NOT_AUTHORIZED) {
        if (isAwsCredentialsRequested) return;

        const isGAMBackend = backendService.isGamBackend();

        isAwsCredentialsRequested = true;
        if (isGAMBackend) {
            if (await gamClient.refreshGigyaToken()) return;
        } else {
            await iccService.refreshIccToken();

            return fetchAwsCredentials();
        }
    }
    throw e;
};

const updateCredentials = (data) => {
    credentials = data;

    cloudWatchProvider.updateCloudWatchClient(credentials);
};

export default {
    getCredentials,
};
