import * as localizationKeys from '../../../consts/localization/localizationKeys';
import connectivityService from '../../../services/device/connectivityService';

const pairingGuideErrorsContent = () => {
    const isBle = connectivityService.isBle();

    return {
        PAIRING_ERRORS: {
            title: isBle ? localizationKeys.BLE_PAIRING_FAIL_TITLE : localizationKeys.USB_PAIRING_FAIL_TITLE,
            description: isBle ? localizationKeys.BLE_PAIRING_FAIL_MESSAGE : localizationKeys.USB_PAIRING_FAIL_MESSAGE,
            mainButtonText: localizationKeys.PAIR_FAIL_TRY_AGAIN_BUTTON,
            secondaryButtonText: localizationKeys.PAIR_FAIL_SKIP_BUTTON,
        },
        CONNECTION_ERRORS: {
            DEV_TYPE_V24P_CHARGER: {
                title: localizationKeys.PAIRING_FAIL_DESCRIPTION_ORDINARY,
                description: isBle
                    ? localizationKeys.PAIRING_TROUBLESHOOTING_TEXT_ORDINARY
                    : localizationKeys.USB_PAIRING_TROUBLESHOOTING_TEXT,
            },
            DEV_TYPE_P1V30_CHARGER: {
                title: localizationKeys.PAIRING_FAIL_DESCRIPTION_CORTEZ,
                description: isBle
                    ? localizationKeys.PAIRING_TROUBLESHOOTING_TEXT_CORTEZ
                    : localizationKeys.USB_PAIRING_TROUBLESHOOTING_TEXT,
            },
            DEV_TYPE_P1V31_CHARGER: {
                title: localizationKeys.PAIRING_FAIL_DESCRIPTION_SOYUZ,
                description: isBle
                    ? localizationKeys.PAIRING_TROUBLESHOOTING_TEXT_SOYUZ
                    : localizationKeys.USB_PAIRING_TROUBLESHOOTING_TEXT,
            },
            DEV_TYPE_P1V30M: {
                title: localizationKeys.PAIRING_FAIL_DESCRIPTION_VESPUCCI,
                description: isBle
                    ? localizationKeys.PAIRING_TROUBLESHOOTING_TEXT_VESPUCCI
                    : localizationKeys.USB_PAIRING_TROUBLESHOOTING_TEXT,
            },
            DEV_TYPE_P4_M3_G2: {
                title: localizationKeys.PAIRING_FAIL_DESCRIPTION_TITAN,
                description: isBle
                    ? localizationKeys.PAIRING_TROUBLESHOOTING_TEXT_TITAN
                    : localizationKeys.USB_PAIRING_TROUBLESHOOTING_TEXT,
            },
            DEV_TYPE_P1V40V_CHARGER: {
                title: localizationKeys.PAIRING_FAIL_DESCRIPTION_P1V40,
                description: isBle
                    ? localizationKeys.PAIRING_TROUBLESHOOTING_TEXT_P1V40
                    : localizationKeys.USB_PAIRING_TROUBLESHOOTING_TEXT,
            },
            DEV_TYPE_P1V40C_CHARGER: {
                title: localizationKeys.PAIRING_FAIL_DESCRIPTION_P1V40M,
                description: isBle
                    ? localizationKeys.PAIRING_TROUBLESHOOTING_TEXT_P1V40M
                    : localizationKeys.USB_PAIRING_TROUBLESHOOTING_TEXT,
            },
            DEV_TYPE_P1V40D_CONTROL: {
                title: localizationKeys.PAIRING_FAIL_DESCRIPTION_P1V40D,
                description: isBle
                    ? localizationKeys.PAIRING_TROUBLESHOOTING_TEXT_P1V40D
                    : localizationKeys.USB_PAIRING_TROUBLESHOOTING_TEXT,
            },
            mainButtonText: localizationKeys.PAIR_FAIL_TRY_AGAIN_BUTTON,
            secondaryButtonText: localizationKeys.PAIR_FAIL_SKIP_BUTTON,
        },
        DEVICE_SUPPORT_FAILED_ERRORS: {
            title: localizationKeys.PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_TITLE,
            description: localizationKeys.PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_MESSAGE,
            mainButtonText: localizationKeys.PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_TRY_AGAIN_BUTTON,
            secondaryButtonText: localizationKeys.PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_SKIP_BUTTON,
        },
        WRONG_DEVICE_ERRORS: {
            //TODO: add description if p1 support will be added for GAM
            DEV_TYPE_V24P_CHARGER: {
                title: localizationKeys.PAIRING_FAIL_DESCRIPTION_ORDINARY,
            },
            DEV_TYPE_P1V30_CHARGER: {
                title: localizationKeys.PAIRING_FAIL_DESCRIPTION_CORTEZ,
            },
            DEV_TYPE_P1V31_CHARGER: {
                title: localizationKeys.PAIRING_FAIL_DESCRIPTION_SOYUZ,
            },
            DEV_TYPE_P1V30M: {
                title: localizationKeys.PAIRING_FAIL_DESCRIPTION_VESPUCCI,
            },
            DEV_TYPE_P4_M3_G2: {
                title: localizationKeys.PAIRING_FAIL_DESCRIPTION_TITAN,
                description: localizationKeys.PAIRING_WRONG_DEVICE_FAIL_MESSAGE,
            },
            DEV_TYPE_P1V40V_CHARGER: {
                title: localizationKeys.PAIRING_FAIL_DESCRIPTION_P1V40,
                description: localizationKeys.PAIRING_WRONG_DEVICE_FAIL_MESSAGE_P1V40,
            },
            DEV_TYPE_P1V40C_CHARGER: {
                title: localizationKeys.PAIRING_FAIL_DESCRIPTION_P1V40M,
                description: localizationKeys.PAIRING_WRONG_DEVICE_FAIL_MESSAGE_P1V40,
            },
            DEV_TYPE_P1V40D_CONTROL: {
                title: localizationKeys.PAIRING_FAIL_DESCRIPTION_P1V40D,
                description: localizationKeys.PAIRING_WRONG_DEVICE_FAIL_MESSAGE_P1V40,
            },
            mainButtonText: localizationKeys.PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_TRY_AGAIN_BUTTON,
            secondaryButtonText: localizationKeys.PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_SKIP_BUTTON,
        },
    };
};

export {pairingGuideErrorsContent};
