import appConfig from '../../config/appConfig';
import {setFWUCheking, setIsConnected, setIsDisconnected} from '../../state/ducks/iotDevice/actions';
import {setMwDeviceIsDisconnectedWithNoReconnect} from '../../state/ducks/mwDevice';
import {
    setDeviceInfoReceived,
    setHolderInfoReceived,
    setYapSynchronizeDeviceDataCollectionReceived,
    setYapSynchronizeHolderDataCollectionReceived,
} from '../../state/ducks/yapEncrypted';
import {makeSelectIsYapFirmwareInProgress} from '../../state/selectors/yapEncrypted';
import {dispatch, getState} from '../../state/store';
import helpers from '../../utils/helpers';
import backendService from '../app/backendService';
import framesProcessingService from '../device/framesProcessingService';
import deviceConnectionDetectorService from '../deviceConnectionDetectorService';
import DeviceRequestTimeoutError from '../errors/deviceRequestTimeoutError';
import DisconnectEventEmitter from '../events/disconnectEventEmitter';
import iotService from '../iot/iotService';
import log from '../logger/log';
import pairingService from '../pairingService';
import mwIotMessageRequestService from '../scpCloud/mwIotMessageRequestService';
import scpCloudService from '../scpCloud/scpCloudService';
import uiIotMessageResponseChecker from '../scpCloud/uiIotMessageResponseChecker';
import IqosBleClient from './iqosBleClient';

const DEVICE_REQUEST_TIMEOUT_MS = 10 * 1000;

const connect = async ({isNewDevice, onConnect, onError}) => {
    try {
        let deviceSelectTimeout;

        const options = {
            onDeviceConnect: () => {
                pairingService.addBleAvailabilityListener();
                framesProcessingService.createNew();

                if (backendService.isGamBackend()) {
                    dispatch(setIsConnected());
                }
            },
            onDisconnected: async (isDisconnectedByUser) => {
                const disconnectEventEmitter = new DisconnectEventEmitter();

                if (isDisconnectedByUser) {
                    dispatch(setMwDeviceIsDisconnectedWithNoReconnect(true));
                } else {
                    deviceConnectionDetectorService.showFailedReconnectDeviceGuide();
                }

                disconnectEventEmitter.emitDeviceDisconnect();
                disconnectEventEmitter.removeAllDisconnectListeners();

                pairingService.stopDeviceSchedulers();

                if (appConfig.getIsDataCollectionEnabled() || backendService.isUamBackend()) {
                    dispatch(setYapSynchronizeDeviceDataCollectionReceived(false));
                    dispatch(setYapSynchronizeHolderDataCollectionReceived(false));
                    dispatch(setDeviceInfoReceived(false));
                    dispatch(setHolderInfoReceived(false));
                }

                if (backendService.isGamBackend()) {
                    dispatch(setIsDisconnected());

                    const isYapFirmwareInProgress = makeSelectIsYapFirmwareInProgress()(getState());

                    if (isYapFirmwareInProgress) dispatch(setFWUCheking());
                } else {
                    scpCloudService.unsubscribeFromDeviceTopic();
                    uiIotMessageResponseChecker.clearResponsesTimeouts();
                    mwIotMessageRequestService.publishDisconnectedEvent();
                }
            },
            onForcedDisconnect: () => {
                pairingService.disconnectAndForwardToMyDevicesPage();
            },
            onDeviceReconnectSuccess: () => {
                iotService.initIotService(true);
            },
            onReconnectFail: async () => {
                deviceConnectionDetectorService.showFailedReconnectDeviceGuide();
            },
            onDeviceSelect: () => {
                const deviceSelectPromise = new Promise((res, rej) => {
                    deviceSelectTimeout = setTimeout(() => {
                        rej(new DeviceRequestTimeoutError());
                    }, DEVICE_REQUEST_TIMEOUT_MS);
                });

                deviceSelectPromise.catch((e) => {
                    helpers.runFunction(onError, [e]);
                });
            },
            onFinishDeviceSelect: () => clearTimeout(deviceSelectTimeout),
        };

        const iqosBleClient = new IqosBleClient(options);

        await iqosBleClient.connect({isNewDevice, onConnect, onError});
    } catch (e) {
        log.debug(`iqosBleService: device connection failed, error: ${e}`);
        helpers.runFunction(onError, [e]);
        throw e;
    }
};

const isClientInitialized = () => {
    try {
        new IqosBleClient();

        return true;
    } catch (e) {
        return false;
    }
};

const disconnect = () => {
    try {
        const iqosBleClient = new IqosBleClient();

        iqosBleClient.disconnect();
        iqosBleClient.destroy();
    } catch (e) {
        log.info(`iqosBleService: disconnect() error: ${e}`);
    }
};

const isDeviceConnected = () => {
    try {
        const iqosBleClient = new IqosBleClient();

        return iqosBleClient.isDeviceConnected();
    } catch (e) {
        log.debug(`iqosBleService: isDeviceConnected() error: ${e}`);

        return false;
    }
};

const unmount = () => {
    try {
        const iqosBleClient = new IqosBleClient();

        iqosBleClient.unmount();
    } catch (e) {
        log.info(`iqosBleService: unmount() error: ${e}`);
    }
};

export default {
    connect,
    disconnect,
    isClientInitialized,
    isDeviceConnected,
    unmount,
};
