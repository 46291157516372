import React, {FC} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import ModalTypes from '../../enums/app/modalTypes';
import {makeSelectModals} from '../../state/selectors/modal';
import ConnectivitySelectPopup from './ConnectivitySelectPopup/ConnectivitySelectPopup';
import DeviceInSblModePopup from './DeviceInSblModePopup/DeviceInSblModePopup';
import FWUAdditionalStepsGuidePopup from './FWUAdditionalStepsGuidePopup/FWUAdditionalStepsGuidePopup';
import FWUErrorPopup from './FWUErrorPopup/FWUErrorPopup';
import FWULedCheckPopup from './FWULedCheckPopup/FWULedCheckPopup';
import HidReconnectPopup from './HidReconnectPopup/HidReconnectPopup';
import PairingGuideErrorPopup from './PairingGuideErrorPopup/PairingGuideErrorPopup';
import PairingSkipPopup from './PairingSkipPopup/PairingSkipPopup';
import PurchaseDateFullPagePopup from './PurchaseDateFullPagePopup/PurchaseDateFullPagePopup';
import ReconnectDeviceGuidePopup from './ReconnectDeviceGuidePopup/ReconnectDeviceGuidePopup';
import RegistrationFailedPopup from './RegistrationFailedPopup/RegistrationFailedPopup';
import RegistrationHolderPopup from './RegistrationHolderPopup/RegistrationHolderPopup';
import UpdateFWPopup from './UpdateFWPopup/UpdateFWPopup';

const MODAL_COMPONENTS = {
    [ModalTypes.CONNECTIVITY_SELECT]: ConnectivitySelectPopup,
    [ModalTypes.PAIRING_GUIDE_ERROR]: PairingGuideErrorPopup,
    [ModalTypes.PAIRING_SKIP]: PairingSkipPopup,
    [ModalTypes.REGISTRATION_FAILED]: RegistrationFailedPopup,
    [ModalTypes.HID_RECONNECT]: HidReconnectPopup,
    [ModalTypes.DEVICE_IS_IN_SBL]: DeviceInSblModePopup,
    [ModalTypes.RECONNECT_DEVICE_GUIDE]: ReconnectDeviceGuidePopup,
    [ModalTypes.FWU_LED_CHECK]: FWULedCheckPopup,
    [ModalTypes.FWU_ADDITIONAL_STEPS_GUIDE]: FWUAdditionalStepsGuidePopup,
    [ModalTypes.FWU_ERROR]: FWUErrorPopup,
    [ModalTypes.UPDATE_FW]: UpdateFWPopup,
    [ModalTypes.REGISTRATION_HOLDER]: RegistrationHolderPopup,
    [ModalTypes.PURCHASE_DATE]: PurchaseDateFullPagePopup,
};

const ModalsRoot: FC = () => {
    const modals = useSelector(makeSelectModals);
    const dispatch = useDispatch();

    if (!modals.length) return null;

    return (
        <>
            {modals.map((modal) => {
                const {modalType, modalProps} = modal;
                const SpecificModal = MODAL_COMPONENTS[modalType];

                return SpecificModal ? (
                    <SpecificModal key={modalType} {...modalProps} modalType={modalType} dispatch={dispatch} />
                ) : null;
            })}
        </>
    );
};

export default ModalsRoot;
