import cn from 'classnames';
import React from 'react';

import PopupError from '../PopupError';
import PopupInfo from '../PopupInfo';
import styles from './PopupStyled.module.scss';

const PopupStyled = (props) => {
    const {title, description, children, isError, descriptionHtml, ...restProps} = props;
    const PopupComponent = isError ? PopupError : PopupInfo;

    const Description = (props) => {
        if (descriptionHtml) {
            return <p {...props} dangerouslySetInnerHTML={{__html: descriptionHtml}} />;
        } else if (description) {
            return <p {...props}>{description}</p>;
        } else {
            return null;
        }
    };

    return (
        <PopupComponent {...restProps}>
            <h2 className={styles.Title}>{title}</h2>
            <Description className={cn(styles.Description, 'ica--txt-regular')} />
            {children}
        </PopupComponent>
    );
};

export default PopupStyled;
