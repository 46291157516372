import {makeSelectIotDeviceData} from '../../state/selectors/iotDevice';
import {getState} from '../../state/store';
import connectivityService from '../device/connectivityService';
import uiMessageRequestService from '../iot/uiMessageRequestService';

const publishDeviceStatusCharacteristicUsbMessageIfNeeded = () => {
    const iotDevice = makeSelectIotDeviceData()(getState());
    const {device, device_global_features_config} = iotDevice;
    const needsToPublishHolderState =
        connectivityService.isUsb() && device_global_features_config.is_holder_check && !device?.is_device_in_sbl_mode;

    if (needsToPublishHolderState) {
        uiMessageRequestService(true).publishDeviceStatusCharacteristicUsbMessage();
    }
};

export default {
    publishDeviceStatusCharacteristicUsbMessageIfNeeded,
};
