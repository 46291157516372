import cn from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import Popup from '../../../components/Popup/Popup';
import {getReconnectDeviceGuideData} from '../../../config/dataConfig/dataConfig';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {hideModalAction} from '../../../state/ducks/modal';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../../state/selectors/consumer';
import {makeLoaders} from '../../../state/selectors/global';
import {dispatch} from '../../../state/store';
import SignedImage from '../../System/SignedImage';
import styles from '../ReconnectDeviceGuidePopup/ReconnectDeviceGuidePopup.module.scss';
import reconnectDeviceGuidePopupService from './reconnectDeviceGuidePopupService';

const selector = createStructuredSelector({
    iotDevice: makeSelectIotDeviceMergedWithIccProduct(),
    loaders: makeLoaders(),
});

const ReconnectDeviceGuidePopup = (props) => {
    const {modalType, isReconnectFailed} = props;
    const {iotDevice, loaders} = useSelector(selector);
    const isAnyLoader = loaders.length > 0;
    const {device} = iotDevice;
    const {type} = device || {};
    const localizedStrings = getLocalizedStrings();
    const reconnectDeviceGuideData = getReconnectDeviceGuideData(type);

    if (!reconnectDeviceGuideData || isAnyLoader) return null;

    const {reconnectData, failedReconnectData} = reconnectDeviceGuideData || {};
    const {header, reconnectStepList} = isReconnectFailed ? failedReconnectData : reconnectData;
    const {mediaData, defaultImage} = isReconnectFailed ? {} : reconnectDeviceGuidePopupService.getImageData(type);
    const onClose = () => dispatch(hideModalAction(modalType));

    return (
        <Popup onClose={onClose} className={styles.Popup} containerClassName={styles.PopupContainerClassName}>
            {!isReconnectFailed && (
                <div className={styles.ImageContainer}>
                    <SignedImage
                        defaultImage={defaultImage}
                        imgClassName={styles.Image}
                        isThumbnailAvailable
                        mediaData={mediaData}
                        signIfNull
                    />
                </div>
            )}
            <div className={styles.ContentContainer}>
                <h2 className={styles.Title}>{localizedStrings[header]}</h2>
                <ul className={cn(styles.StepList, 'ica--txt-regular')}>
                    {reconnectStepList.map((step, i) => {
                        const stepText = localizedStrings[step];

                        return stepText ? (
                            <li key={i}>
                                <p>{stepText}</p>
                            </li>
                        ) : null;
                    })}
                </ul>
            </div>
        </Popup>
    );
};

export default ReconnectDeviceGuidePopup;
