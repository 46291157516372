import PRODUCT_COLORS from '../../consts/product/productColors';
import PRODUCT_COLORS_MAPPING from '../../consts/product/productColorsMapping';

const getVariableName = (variableObj) => Object.keys(variableObj)[0];

const getIdentificatorObject = (variableObj) => {
    const identificatorName = getVariableName(variableObj);
    const identificatorValue = variableObj[identificatorName];

    return {
        identificatorName,
        identificatorValue,
    };
};

const getProductIdentificator = (product) => {
    const {codentify, deviceSerialNumber, isP4} = product;
    const identificatorObject =
        isP4 && codentify ? getIdentificatorObject({codentify}) : getIdentificatorObject({deviceSerialNumber});

    return identificatorObject;
};

const findProduct = ({products, isP4, deviceSerialNumber, codentify}) => {
    const {identificatorName, identificatorValue} = getProductIdentificator({
        isP4,
        deviceSerialNumber,
        codentify,
    });

    return products?.find((product) => product[identificatorName] === identificatorValue);
};

const someProduct = ({products, isP4, deviceSerialNumber, codentify}) => {
    const {identificatorName, identificatorValue} = getProductIdentificator({
        isP4,
        deviceSerialNumber,
        codentify,
    });

    return products?.some((product) => product[identificatorName] === identificatorValue);
};

const filterProduct = ({products, isP4, deviceSerialNumber, codentify}) => {
    const {identificatorName, identificatorValue} = getProductIdentificator({
        isP4,
        deviceSerialNumber,
        codentify,
    });

    return products?.filter((product) => product[identificatorName] !== identificatorValue);
};

const getHexColorByMaterialColor = (type, color) => {
    const productTypeColor = PRODUCT_COLORS_MAPPING[type];

    return productTypeColor?.[color] ? productTypeColor[color] : PRODUCT_COLORS.DEFAULT;
};

const getNewProductsListWithUpdatedItem = ({products, isP4, deviceSerialNumber, codentify, data}) => {
    const isProductExist = someProduct({
        products,
        isP4,
        deviceSerialNumber,
        codentify,
    });

    let newList = products?.length ? [...products] : [];

    if (isProductExist) {
        newList = newList.map((product) => {
            if (product.deviceSerialNumber === deviceSerialNumber || product.codentify === codentify) {
                return {...product, ...data};
            }

            return product;
        });
    } else {
        newList.push({
            deviceSerialNumber,
            codentify,
            ...data,
        });
    }

    return newList;
};

export default {
    getProductIdentificator,
    findProduct,
    someProduct,
    filterProduct,
    getHexColorByMaterialColor,
    getNewProductsListWithUpdatedItem,
};
