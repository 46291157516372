import React from 'react';

import Icon from './Icon';

const AgeGatePageIcon = (props) => {
    return (
        <Icon width='96' height='96' viewBox='0 0 96 96' {...props}>
            <g fill='none' fillRule='evenodd' stroke='#383839' strokeWidth='2'>
                <path
                    strokeLinejoin='round'
                    d='M55 9.999c0 5.523-4.478 9.999-9.999 9.999-5.523 0-10.001-4.476-10.001-10C35 4.477 39.478 0 45.001 0 50.522 0 55 4.476 55 9.999z'
                    transform='translate(17 17)'
                />
                <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M26.266 35.2c1.04-4.412 5.004-7.697 9.734-7.697h16.54c5.523 0 10 4.477 10 10v18.98c0 2.21-1.79 4-4 4H30'
                    transform='translate(17 17)'
                />
                <path
                    d='M22 35c2.485 0 4.735 1.007 6.364 2.636C29.993 39.265 31 41.515 31 44h0v14c0 .828-.336 1.578-.879 2.121-.543.543-1.293.879-2.121.879h0H4c-.828 0-1.578-.336-2.121-.879C1.336 59.578 1 58.828 1 58h0V44c0-2.485 1.007-4.735 2.636-6.364C5.265 36.007 7.515 35 10 35h0z'
                    transform='translate(17 17)'
                />
                <path
                    strokeLinejoin='round'
                    d='M24 19.999c0 4.419-3.582 7.999-7.999 7.999-4.419 0-8.001-3.58-8.001-8C8 15.58 11.582 12 16.001 12c4.417 0 8 3.58 8 7.999z'
                    transform='translate(17 17)'
                />
            </g>
        </Icon>
    );
};

export default AgeGatePageIcon;
