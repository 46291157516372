import React from 'react';

import {ButtonDark} from '../../../components/Button';
import PopupInfoStyled from '../../../components/Popup/PopupStyled/PopupInfoStyled';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import hidReconnectHandlerService from '../../../services/hid/hidReconnectHandlerService';
import iqosHidService from '../../../services/hid/iqosHidService';
import iotService from '../../../services/iot/iotService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import modalService from '../../../services/modalService';

const HidReconnectPopupTemplate = (props) => {
    const {modalType, isCloseEnabled, descriptionKey, showReconnectModalOnError} = props;
    const localizedStrings = getLocalizedStrings();

    const onClick = async () => {
        onClose();
        try {
            await iqosHidService.connect({onConnect: () => iotService.initIotService(true)});
            // eslint-disable-next-line no-empty
        } catch (e) {
            if (showReconnectModalOnError) {
                hidReconnectHandlerService.showHidReconnectModal();
            }
        }
    };

    const onClose = () => modalService.hideModal(modalType);

    return (
        <PopupInfoStyled
            title={localizedStrings[localizationKeys.USB_RECONNECT_POPUP_TITLE_TEXT]}
            description={localizedStrings[descriptionKey]}
            onClose={isCloseEnabled ? onClose : undefined}
        >
            <ButtonDark onClick={onClick} showArrow>
                {localizedStrings[localizationKeys.USB_POPUP_BTN_RECONNECT]}
            </ButtonDark>
        </PopupInfoStyled>
    );
};

export default HidReconnectPopupTemplate;
