import cn from 'classnames';
import React, {FC} from 'react';

import AppPopup from '../../Popup/AppPopup';
import styles from './AppLoader.module.scss';
import AppLoaderContent from './AppLoaderContent';

interface IProps {
    loaderText: string;
    isOpaque: boolean;
}
const AppLoader: FC<IProps> = ({loaderText, isOpaque}) => {
    const overlayClassName = cn(styles.Overlay, {[styles.Opaque]: isOpaque});

    return (
        <AppPopup
            isOpen
            className={styles.AppLoaderContainer}
            overlayClassName={overlayClassName}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <AppLoaderContent loaderText={loaderText} />
        </AppPopup>
    );
};

export default AppLoader;
