import hidInputResponseTimeouts from '../../consts/hid/hidInputResponseTimeouts';
import * as firmwareErrorCodes from '../../consts/iot/firmwareErrorCodes';
import helpers from '../../utils/helpers';
import BaseFwuClient from '../device/baseFwuClient';
import fwuErrorDetailsService from '../device/fwuErrorDetailsService';
import mwMessageRequestService from '../iot/mwMessageRequestService';
import log from '../logger/log';
import HIDClient from './hidClient';

let instance = null;

export default class DeviceHidFwuClient extends BaseFwuClient {
    constructor(options) {
        if (options && instance) {
            instance.stop();
            instance = null;
        }

        if (instance) {
            return instance;
        }

        super({fwuPackageData: options.fwuPackageData, fwu_target: options.fwu_target});
        instance = this;
        this.currentFwuTry = 0;
        this.currentBlockIndex = 0;
        this.hidClient = new HIDClient();
        this.onFwuError = options.onFwuError;
        this.onFwuStatusEvent = options.onFwuStatusEvent;

        this.pollingTimeout;
        this.pollingTryNumber = 0;
        this.currentTryNumber = 0;
        this.isStopped = false;
    }

    stop = () => {
        this.isStopped = true;
        clearTimeout(this.pollingTimeout);
    };

    isMaxTriesExceeded = () => {
        const FWU_MAX_TRIES = 10;

        if (this.currentTryNumber < FWU_MAX_TRIES) {
            this.currentTryNumber = this.currentTryNumber + 1;

            return false;
        }

        this.onFwuFail();
        return true;
    };

    startFwUpdate = (package_index) => {
        log.info(`DeviceHidFwuClient: start firmware update`);

        this.setStartTs();

        if (package_index && package_index > 0) {
            this.sendProgramBlocks(package_index);
        } else {
            if (this.fwuPackageData.suspend_master_communication?.f) {
                if (this.isFwuHolderRelatedTarget()) {
                    this.sendSuspendMasterCommunication();
                    return;
                } else {
                    //TODO: replace with warning
                    log.info(
                        'DeviceHidFwuClient: there is a suspend_master_communication frame in fwu package but target is not holder'
                    );
                }
            }

            this.sendAuthenticationOrStartProgramming();
        }
    };

    sendAuthenticationOrStartProgramming = () => {
        if (this.fwuPackageData.authentication?.f) {
            this.sendAuthenticationRequest();
        } else {
            this.sendStartProgrammingRequest();
        }
    };

    sendRequestAndSubscribeOnReport = ({blockData, onReport, onPollingSuccess, responseTimeoutMs}) => {
        const {f, v, n} = blockData;

        this.hidClient.subscribeOnInputReport({
            onResponse: (value) => {
                const isValueValid = v ? this.isValidResponse(value, v) : true;

                if (isValueValid) {
                    this.sendStatusMessage(n);
                    onReport();
                } else if (this.fwuPackageData.status_polling) {
                    this.startPolling(onPollingSuccess);
                } else {
                    this.onHidFwuError({errorFrame: value});
                }
            },
        });
        this.hidClient.sendReport({command: f, onResponseTimeoutError: this.onFwuFail, responseTimeoutMs});
    };

    isValidResponse = (response, validValue) => response.toLowerCase().includes(validValue.toLowerCase());

    sendSuspendMasterCommunication = () => {
        if (this.isMaxTriesExceeded()) return;
        log.info(`DeviceHidFwuClient: send sendSuspendMasterCommunication`);

        this.sendRequestAndSubscribeOnReport({
            blockData: this.fwuPackageData.suspend_master_communication,
            onReport: this.sendAuthenticationOrStartProgramming,
            onPollingSuccess: this.sendAuthenticationOrStartProgramming,
        });
    };

    sendAuthenticationRequest = () => {
        if (this.isMaxTriesExceeded()) return;
        log.info(`DeviceHidFwuClient: send sendAuthenticationRequest`);

        this.sendRequestAndSubscribeOnReport({
            blockData: this.fwuPackageData.authentication,
            onReport: this.sendStartProgrammingRequest,
            responseTimeoutMs: hidInputResponseTimeouts.FWU_AUTH,
        });
    };

    sendStartProgrammingRequest = () => {
        if (this.isMaxTriesExceeded()) return;
        log.info(`DeviceHidFwuClient: send sendStartProgrammingRequest`);

        this.sendRequestAndSubscribeOnReport({
            blockData: this.fwuPackageData.start_programming,
            onReport: this.sendProgramBlocks,
            onPollingSuccess: this.sendProgramBlocks,
            responseTimeoutMs: hidInputResponseTimeouts.FWU_START_PROGRAMMING,
        });
    };

    sendEndProgrammingRequest = () => {
        log.info(`DeviceHidFwuClient: send sendEndProgrammingRequest`);

        this.sendRequestAndSubscribeOnReport({
            blockData: this.fwuPackageData.end_programming,
            onReport: this.publishFwuFinish,
            responseTimeoutMs: hidInputResponseTimeouts.FWU_END_PROGRAMMING,
        });
    };

    sendProgramBlocks = (blockIndex = 0) => {
        if (this.isMaxTriesExceeded()) return;
        log.info(`DeviceHidFwuClient: send sendProgramBlocks`);

        this.subscribeOnReportForProgramBlock();
        this.sendProgramBlock(blockIndex);
    };

    subscribeOnReportForProgramBlock = () => {
        this.hidClient.subscribeOnInputReport({onResponse: this.onProgramBlockInputReport, isDebug: false});
    };

    onProgramBlockInputReport = (value) => {
        if (this.isStopped) return;

        const {program_blocks} = this.fwuPackageData;
        const {v, n} = program_blocks[this.currentBlockIndex];
        const isValueValid = this.isValidResponse(value, v);
        const sendNextProgramBlock = () => this.sendProgramBlock(this.currentBlockIndex + 1);

        if (isValueValid) {
            this.sendStatusMessage(n);

            if (this.currentBlockIndex >= program_blocks.length - 1) {
                this.sendEndProgrammingRequest();
            } else {
                sendNextProgramBlock();
            }
        } else if (this.fwuPackageData.status_polling) {
            this.startPolling(() => {
                this.subscribeOnReportForProgramBlock();
                this.sendProgramBlock(this.currentBlockIndex);
            }, value);
        } else {
            this.onHidFwuError({errorFrame: value, programBlockResponse: value});
        }
    };

    sendProgramBlock = (blockIndex) => {
        this.currentBlockIndex = blockIndex;
        const {f} = this.fwuPackageData.program_blocks[blockIndex];

        this.hidClient.sendReport({
            command: f,
            isDebug: false,
            onResponseTimeoutError: this.onFwuFail,
            responseTimeoutMs: hidInputResponseTimeouts.FWU_PROGRAM_BLOCKS,
        });
    };

    startPolling = (onPollingSuccess, programBlockResponse) => {
        log.info(`DeviceHidFwuClient: start polling`);

        this.sendPollingRequestAndSubscribeOnReport(onPollingSuccess, programBlockResponse);
    };

    sendPollingRequestAndSubscribeOnReport = (onSuccess, programBlockResponse) => {
        const {busy, counter, delay, read, success} = this.fwuPackageData.status_polling;

        if (this.pollingTryNumber < counter) {
            this.pollingTryNumber = this.pollingTryNumber + 1;

            this.hidClient.subscribeOnInputReport({
                onResponse: (value) => {
                    const isSuccess = this.isValidResponse(value, success);

                    if (isSuccess) {
                        // this.pollingTryNumber = 0;

                        log.info(`DeviceHidFwuClient: polling result - success`);

                        helpers.runFunction(onSuccess);
                    } else {
                        const isBusy = this.isValidResponse(value, busy);

                        if (isBusy) {
                            log.info(`DeviceHidFwuClient: polling result - busy`);

                            this.pollingTimeout = setTimeout(() => {
                                this.sendPollingRequestAndSubscribeOnReport(onSuccess, programBlockResponse);
                            }, delay);
                        } else {
                            log.info(`DeviceHidFwuClient: polling result - fail`);

                            this.onHidFwuError({errorFrame: value, programBlockResponse});
                        }
                    }
                },
            });
            this.hidClient.sendReport({command: read});
        } else {
            log.info(`DeviceHidFwuClient: polling tries exceeded`);

            this.onFwuFail(programBlockResponse);
        }
    };

    onFwuFail = (programBlockResponse) => {
        this.onHidFwuError({errorCode: firmwareErrorCodes.GENERAL, programBlockResponse});
    };

    onHidFwuError = ({errorCode, errorFrame, programBlockResponse}) => {
        this.stop();
        const errorData = {errorCode, onError: this.onFwuError, errorFrame};
        const errorDetails = fwuErrorDetailsService.getErrorDetails({
            allRequests: this.fwuPackageData.program_blocks,
            currentIndex: this.currentBlockIndex,
            response: programBlockResponse,
        });

        this.publishFwuError({...errorData, ...errorDetails});
    };

    sendStatusMessage = (n) => {
        if (n) {
            mwMessageRequestService.onFwuStatusEvent({
                onSuccess: this.onFwuStatusEvent,
                status: n,
                fwu_target: this.fwu_target,
            });
        }
    };
}
