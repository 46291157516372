import React from 'react';

import Icon from './Icon';

const IconCommonCharge = (props) => {
    return (
        <Icon width='38' height='38' viewBox='0 0 38 38' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g strokeWidth='1.708'>
                    <path
                        d='M1.183 9.382V29.19c0 1.18.478 2.247 1.25 3.02.773.772 1.84 1.25 3.02 1.25h5.487c1.179 0 2.246-.478 3.019-1.25.773-.773 1.25-1.84 1.25-3.02V9.382c0-1.18-.477-2.247-1.25-3.02-.773-.772-1.84-1.25-3.02-1.25H5.454c-1.18 0-2.247.478-3.02 1.25-.772.773-1.25 1.84-1.25 3.02z'
                        transform='translate(10.248 2.408)'
                    />
                    <path
                        strokeLinecap='square'
                        d='M8.869 26.628L6.307 14.672'
                        transform='translate(10.248 2.408) rotate(-90 8.015 20.65)'
                    />
                    <path
                        strokeLinecap='round'
                        d='M8.869 -2.408L8.869 3.042'
                        transform='translate(10.248 2.408) rotate(-90 8.869 1.008)'
                    />
                </g>
            </g>
        </Icon>
    );
};

export default IconCommonCharge;
