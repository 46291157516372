import React from 'react';

import Icon from './Icon';

const IconCloudReducedWhite = (props) => {
    return (
        <Icon width='49' height='50' viewBox='0 0 49 50' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g fillRule='nonzero' strokeWidth='2'>
                    <path
                        d='M28.426 28H9.576C4.295 28 0 23.675 0 18.361c0-5.315 4.296-9.639 9.575-9.639h1.757C12.018 3.814 16.254 0 21.284 0c5.223 0 9.53 4.03 10.01 9.164C35.23 10.407 38 14.134 38 18.36 38 23.675 33.705 28 28.426 28z'
                        transform='translate(-810 -417) translate(475 404) translate(259 13) translate(76) translate(6 11)'
                    />
                </g>
            </g>
        </Icon>
    );
};

export default IconCloudReducedWhite;
