import appConfig from '../../config/appConfig';
import {setMwDeviceIsDisconnectedWithNoReconnect} from '../../state/ducks/mwDevice';
import {
    setDeviceInfoReceived,
    setHolderInfoReceived,
    setYapSynchronizeDeviceDataCollectionReceived,
    setYapSynchronizeHolderDataCollectionReceived,
} from '../../state/ducks/yapEncrypted';
import {makeSelectIsDeviceReady, selectIsFirmwareInProgress} from '../../state/selectors/iotDevice';
import {dispatch, getState} from '../../state/store';
import helpers from '../../utils/helpers';
import backendService from '../app/backendService';
import framesProcessingService from '../device/framesProcessingService';
import log from '../logger/log';
import pairingService from '../pairingService';
import mwIotMessageRequestService from '../scpCloud/mwIotMessageRequestService';
import scpCloudService from '../scpCloud/scpCloudService';
import uiIotMessageResponseChecker from '../scpCloud/uiIotMessageResponseChecker';
import HIDClient from './hidClient';
import hidReconnectHandlerService from './hidReconnectHandlerService';

const connect = async ({isNewDevice, onConnect, onError, isInitialConnect}) => {
    try {
        const options = {
            onDeviceConnect: () => {
                framesProcessingService.createNew();
                hidReconnectHandlerService.clearHidReconnectHandlerState();
            },
            onDisconnected: async (isDisconnectedByUser) => {
                if (isDisconnectedByUser) {
                    dispatch(setMwDeviceIsDisconnectedWithNoReconnect(true));
                } else {
                    const state = getState();
                    const isDeviceReady = makeSelectIsDeviceReady()(state);
                    const isFirmwareInProgress = selectIsFirmwareInProgress(state);
                    const showHidReconnectModal = isInitialConnect ? isDeviceReady || isFirmwareInProgress : true;

                    if (showHidReconnectModal) {
                        hidReconnectHandlerService.showHidReconnectModal();
                        if (appConfig.getIsDataCollectionEnabled() || backendService.isUamBackend()) {
                            dispatch(setYapSynchronizeDeviceDataCollectionReceived(false));
                            dispatch(setYapSynchronizeHolderDataCollectionReceived(false));
                            dispatch(setDeviceInfoReceived(false));
                            dispatch(setHolderInfoReceived(false));
                        }
                    }
                }

                pairingService.stopDeviceSchedulers();
                scpCloudService.unsubscribeFromDeviceTopic();
                uiIotMessageResponseChecker.clearResponsesTimeouts();
                mwIotMessageRequestService.publishDisconnectedEvent();
            },
        };
        const hidClient = new HIDClient(true, options);

        await hidClient.connectDevice({isNewDevice, onConnect});
    } catch (e) {
        log.debug(`iqosHidService: device connection failed, error: ${e}`);
        helpers.runFunction(onError, [e]);
        throw e;
    }
};

const isClientInitialized = () => {
    try {
        new HIDClient();

        return true;
    } catch (e) {
        return false;
    }
};

const disconnect = () => {
    const hidClient = new HIDClient();

    hidClient.disconnect();
};

const isDeviceConnected = () => {
    const hidClient = new HIDClient();

    return hidClient.isDeviceConnected();
};

const unmount = () => {};

export default {
    connect,
    disconnect,
    isClientInitialized,
    isDeviceConnected,
    unmount,
};
