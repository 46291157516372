import ModalTypes from '../../enums/app/modalTypes';
import {hideModalAction, showHidReconnectModalAction} from '../../state/ducks/modal';
import {dispatch} from '../../state/store';

let isHidReconnectModalActionNeedsToBeCalled = false;
let onDisconnectHandler;

const setCustomOHandler = (handler) => {
    onDisconnectHandler = handler;
    dispatch(hideModalAction(ModalTypes.HID_RECONNECT));
};

const clearCustomHandler = () => (onDisconnectHandler = undefined);

const clearCustomHandlerAndShowReconnectModal = () => {
    clearCustomHandler();
    showHidReconnectModalIfNeeded();
};

const showHidReconnectModal = () => {
    if (onDisconnectHandler) {
        isHidReconnectModalActionNeedsToBeCalled = true;
        onDisconnectHandler();
    } else {
        dispatch(showHidReconnectModalAction());
    }
};

const showHidReconnectModalIfNeeded = () => {
    if (isHidReconnectModalActionNeedsToBeCalled) {
        showHidReconnectModal();
    }
};

const clearHidReconnectHandlerState = () => (isHidReconnectModalActionNeedsToBeCalled = false);

export default {
    showHidReconnectModal,
    showHidReconnectModalIfNeeded,
    setCustomOHandler,
    clearCustomHandlerAndShowReconnectModal,
    clearHidReconnectHandlerState,
};
