export const CONNECTED = 0;
export const DISCONNECTED = 1;
export const STARTED = 2;
export const IN_PROGRESS = 3;
export const FINISHED = 4;
export const ERROR = 5;
export const CHECK = 6;
export const PACKAGE_LOADED = 7;
export const GET_PACKAGE = 10;
export const INITIATE = 11;
