type TFormatString = (string: string, ...values: any[]) => string;
type TFormatByName = (str: string, values: {[k: string]: any}) => string;
type TIsNullOrEmpty = (str: string | null | undefined) => boolean;
type TGetIsTextIncludesFormatChar = (customTemplateText: string) => boolean;

const formatString: TFormatString = (string, ...values) => {
    for (let i = 0; i < values.length; i++) {
        string = string.replace(`{${i}}`, values[i]);
    }

    return string;
};

const formatByName: TFormatByName = (str, values) => {
    if (!str) return str;

    return str.replace(/\{\{|\}\}|\{(\w+)\}/g, (m: string, n: string) => {
        if (m == '{{') {
            return '{';
        }
        if (m == '}}') {
            return '}';
        }
        return values[n];
    });
};

const isAlphaNumeric = (str: string): boolean => {
    let code, i, len;

    for (i = 0, len = str.length; i < len; i++) {
        code = str.charCodeAt(i);
        if (
            !(code > 47 && code < 58) && // numeric (0-9)
            !(code > 64 && code < 91) && // upper alpha (A-Z)
            !(code > 96 && code < 123)
        ) {
            // lower alpha (a-z)
            return false;
        }
    }
    return true;
};

const isNullOrEmpty: TIsNullOrEmpty = (str) => !str || str === 'null';

const getIsTextIncludesFormatChar: TGetIsTextIncludesFormatChar = (inputText) => {
    const FORMAT_CHAR = '{0}';

    return !!inputText?.includes(FORMAT_CHAR);
};

export default {formatString, formatByName, isNullOrEmpty, isAlphaNumeric, getIsTextIncludesFormatChar};
