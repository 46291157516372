import React from 'react';
import {useDispatch} from 'react-redux';

import standaloneLocalization from '../../../config/standaloneConfig/standaloneLocalization';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import networkStatusService from '../../../services/networkStatusService';
import {setNotificationInfo} from '../../../state/ducks/global';
import elementsTesIds from '../../../test/consts/elementsTesIds';
import helpers from '../../../utils/helpers';
import {IButton} from '../ButtonTypes';

interface IProps extends IButton {
    button: React.FC<any>;
    ariaLabelLastSlide?: string;
    customClassName?: string;
    isTabletShowArrows?: boolean;
}

const ConnectionTrackButton: React.FC<IProps> = (props) => {
    const {button: Button, onClick, ...restProps} = props;
    const dispatch = useDispatch();
    const onClickOffline = () => {
        const localizedStrings = getLocalizedStrings();
        const text = localizedStrings
            ? localizedStrings[localizationKeys.NO_INTERNET_CONNECTION_SCENARIO_2_NOTIFICATION_TEXT]
            : standaloneLocalization.NO_INTERNET_CONNECTION_SCENARIO_2_NOTIFICATION_TEXT;

        dispatch(setNotificationInfo(text, elementsTesIds.ABOUT_MY_DEVICE_OFFLINE_NOTIFICATION));
    };

    return (
        <Button
            {...restProps}
            onClick={(e: React.MouseEvent<HTMLElement>) =>
                networkStatusService.isOnline() ? helpers.runFunction(onClick, e) : onClickOffline()
            }
        />
    );
};

export default ConnectionTrackButton;
