import appConfig from '../../config/appConfig';
import ACTIVATION_ERRORS_DATA from '../../consts/analytics/activationErrorsData';
import httpErrorsTypes from '../../consts/analytics/registrationErrorsData';
import * as errorPageTypes from '../../consts/app/errorPageTypes';
import * as logMessageTypes from '../../consts/app/logMessageTypes';
import * as uamMethods from '../../consts/uam/uamMethods';
import * as yapActionStatusTypes from '../../consts/yap/yapActionStatusTypes';
import * as yapConnectionStatusTypes from '../../consts/yap/yapConnectionStatusTypes';
import * as yapDataChangeTypes from '../../consts/yap/yapDataChangePropertyTypes';
import * as yapDeviceEventChangeTypes from '../../consts/yap/yapDeviceEventChangeTypes';
import {setFWUError, setFWUStarted, setIsDeviceReady} from '../../state/ducks/iotDevice/actions';
import {
    setDeviceDataChangeReceived,
    setDeviceInfoReceived,
    setHolderInfoReceived,
    setSyncLastRequestId,
    setYapActivationInProgress,
    setYapAssets,
    setYapCancelDataCollectionInProgress,
    setYapDeviceConnected,
    setYapFindDeviceReceived,
    setYapFirmwareInProgress,
    setYapHolderConnected,
    setYapLastRequestId,
    setYapSynchronizeDataCollectionInProgress,
    setYapSynchronizeDeviceDataCollectionReceived,
    setYapSyncInProgress,
    setYapUpdateSettingsInProgress,
} from '../../state/ducks/yapEncrypted';
import {makeSelectIotDeviceData, selectIsFirmwareInProgress} from '../../state/selectors/iotDevice';
import {
    makeSelectAsset,
    makeSelectIsDeviceSyncInProgress,
    makeSelectIsHolderByAssetId,
    makeSelectIsYapActivationInProgress,
    makeSelectIsYapDataCollectionAllowed,
    makeSelectIsYapFirmwareInProgress,
    makeSelectIsYapSynchronizeDataCollectionInProgress,
    makeSelectIsYapSynchronizeDeviceDataCollectionReceived,
    makeSelectIsYapSynchronizeHolderDataCollectionReceived,
    makeSelectIsYapUpdateSettingsInProgress,
    makeSelectYapCancelDataCollectionInProgress,
    makeSelectYapLastRequestId,
} from '../../state/selectors/yapEncrypted';
import {dispatch, getState} from '../../state/store';
import helpers from '../../utils/helpers';
import analyticsService from '../analyticsService';
import appErrorService from '../app/appErrorService';
import backendService from '../app/backendService';
import cmClientService from '../communicationLayer/cmClientService';
import IqosServiceClient from '../device/iqosServiceClient';
import iccService from '../icc/iccService';
import iotDeviceService from '../iotDevice/iotDeviceService';
import log from '../logger/log';
import {uamAssetsMapping} from '../mapping/uamMappings';
import appRouterService from '../route/appRouterService';
import userBrowserService from '../user/userBrowserService';
import yapService from '../yap/yapService';
import yapStateService from '../yap/yapStateService';
import uamClient from './uamClient';
import uamService from './uamService';

const getAssets = async (isShowError = false, isHolder) => {
    const isDataCollectionEnabled = appConfig.getIsDataCollectionEnabled();

    if (!yapService.isYapEncryptedMode() && !isDataCollectionEnabled && !backendService.isUamBackend()) return false;

    let isValid = false;

    try {
        isValid = await iccService.fetchUamToken(false, isHolder);
        if (isValid) {
            isValid =
                (await uamClient.callGet({
                    methodName: uamMethods.GET_ASSETS,
                    action: setYapAssets,
                    mapper: uamAssetsMapping,
                    isHolder,
                    ignoreErrorCodes: httpErrorsTypes.NOT_FOUND,
                    isNeedRedirectForOtherErrors: false,
                })) !== null;
        }
    } catch (e) {
        isValid = false;
    }

    if (isShowError && !isValid) {
        appRouterService.forwardToErrorHandlingPage(errorPageTypes.UAM_TEMPORARY_ERROR);
    }
    return isValid;
};

const getUamInfo = async (assetId) => {
    //TODO: remove this implementation after feature config will be added at client side
    uamService.updateUamDeviceConfig();

    if (!assetId) return false;

    const deviceInfo = await uamClient.callGet({
        methodName: uamMethods.GET_DEVICE_INFO,
        requestConfig: {args: assetId},
        isNeedRedirectForOtherErrors: false,
    });

    if (!deviceInfo) return false;

    const {
        responsiveDrawProfile,
        vapeCloudSize,
        parentProtection,
        ledIntensity,
        firmwareVersion,
        codentify,
        yapActivationStatus,
        batteryInfo,
        extHapticProfile,
        heatingVibration,
        batteryChargedVibration,
        uiBatteryChargedVibration,
        systemStatus,
        liftUpGesture,
        doubleTapGesture,
        autoStartHeating,
    } = deviceInfo[0].device || {};

    if (!yapActivationStatus) {
        return false;
    }
    await uamService.updateUamActivationStatus(yapActivationStatus);

    const state = getState();
    const isHolder = makeSelectIsHolderByAssetId(assetId)(state);
    const {batteryChargedOneExp, batteryChargedTwoExp} = uiBatteryChargedVibration || {};
    const batteryCharged = batteryChargedVibration?.batteryCharged;
    const liftUpGestureState = liftUpGesture?.gestureState;
    const doubleTapGestureState = doubleTapGesture?.gestureState;
    const autoStartHeatingState = autoStartHeating?.featureState;
    const extHapticDurations = extHapticProfile?.durations;
    const extHapticProfileIsOn = extHapticDurations ? extHapticDurations[0]?.durationIsOn : undefined;
    const {endOfHeating, fullyHeated, nearEnd, startHeating, hapticProfile} = heatingVibration || {};

    await yapStateService.updateDeviceCharacteristics({
        eventType: yapDeviceEventChangeTypes.SYSTEM_STATUS,
        details: systemStatus,
    });

    const updateDeviceSettings = (type, newValue) => {
        yapStateService.updateDeviceSettings({type, newValue, isHolder});
    };

    updateDeviceSettings(yapDataChangeTypes.YAP_RESPONSIVE_DRAW_PROPERTY, responsiveDrawProfile?.hapticPattern);
    updateDeviceSettings(yapDataChangeTypes.YAP_VAPE_CLOUD_SIZE_PROPERTY, vapeCloudSize?.heatingProfile);
    updateDeviceSettings(yapDataChangeTypes.YAP_PARENT_PROTECTION_PROPERTY, parentProtection?.protectionType);
    updateDeviceSettings(yapDataChangeTypes.YAP_LED_PROPERTY, ledIntensity?.intensity);
    updateDeviceSettings(yapDataChangeTypes.YAP_VIBRATION_HAPTIC_PROFILE_PROPERTY, hapticProfile);
    updateDeviceSettings(yapDataChangeTypes.YAP_VIBRATION_END_OF_HEATING_PROPERTY, endOfHeating);
    updateDeviceSettings(yapDataChangeTypes.YAP_VIBRATION_NEAR_END_PROPERTY, nearEnd);
    updateDeviceSettings(yapDataChangeTypes.YAP_VIBRATION_START_HEATING_PROPERTY, startHeating);
    updateDeviceSettings(yapDataChangeTypes.YAP_VIBRATION_FULLY_HEATED_PROPERTY, fullyHeated);
    updateDeviceSettings(yapDataChangeTypes.YAP_EXT_VIBRATION_PROPERTY, extHapticProfileIsOn);
    updateDeviceSettings(yapDataChangeTypes.YAP_BATTERY_CHARGED_VIBRATION_PROPERTY, batteryCharged);
    updateDeviceSettings(yapDataChangeTypes.YAP_LIFT_UP_GESTURE_PROPERTY, liftUpGestureState);
    updateDeviceSettings(yapDataChangeTypes.YAP_DOUBLE_TAP_GESTURE_PROPERTY, doubleTapGestureState);
    updateDeviceSettings(yapDataChangeTypes.YAP_AUTO_START_HEATING_PROPERTY, autoStartHeatingState);
    updateDeviceSettings(yapDataChangeTypes.YAP_BATTERY_CHARGED_ONE_EXP_VIBRATION_PROPERTY, batteryChargedOneExp);
    updateDeviceSettings(yapDataChangeTypes.YAP_BATTERY_CHARGED_TWO_EXP_VIBRATION_PROPERTY, batteryChargedTwoExp);

    uamService.updateIotDevice({
        version: firmwareVersion,
        batteryCharge: batteryInfo?.batteryChargeUnified,
        codentify: codentify?.codentify,
        isHolder,
    });

    synchronizeDataRequestCurrentTries = 0;

    return true;
};

let activeProcessTimeout = null;
let activeHolderProcessTimeout = null;

const updateUamSettings = async (body, isHolder) => {
    const isYapUpdateSettingsInProgress = makeSelectIsYapUpdateSettingsInProgress(isHolder)(getState());

    if (isYapUpdateSettingsInProgress) return;

    if (isHolder) {
        clearTimeout(activeHolderProcessTimeout);
    } else {
        clearTimeout(activeProcessTimeout);
    }

    dispatch(setYapUpdateSettingsInProgress(true, isHolder));
    dispatch(setDeviceDataChangeReceived(false));

    const state = getState();
    const {deviceSerialNumber: chargerSerialNumber, holder} = makeSelectIotDeviceData()(state);
    const {deviceSerialNumber: holderSerialNumber} = holder || {};
    const serialNumber = isHolder ? holderSerialNumber : chargerSerialNumber;
    const uamAsset = makeSelectAsset(serialNumber)(state);

    if (uamAsset) {
        try {
            await uamService.syncAndSubscribeOnDevice(isHolder);
            const isConnected = await cmClientService.waitUntilConnected(uamAsset?.assetId);

            if (isConnected) {
                const isCancelSuccess = await waitUntilDataCollectionCanceled();

                if (isCancelSuccess) {
                    log.info('Start updateUamSettings process.');

                    const {requestId} =
                        (await uamClient.callPost({
                            methodName: uamMethods.UPDATE_SETTINGS,
                            requestConfig: {args: uamAsset?.assetId, data: body},
                            isNeedRedirectForOtherErrors: false,
                        })) || {};

                    if (requestId) {
                        dispatch(setYapLastRequestId(requestId, isHolder));

                        const activeTimeout = setTimeout(() => {
                            const isYapUpdateSettingsInProgress = makeSelectIsYapUpdateSettingsInProgress(isHolder)(
                                getState()
                            );

                            if (!isYapUpdateSettingsInProgress) return;

                            dispatch(setYapUpdateSettingsInProgress(false, isHolder));
                            appErrorService.showDeviceSettingsGlobalError();
                        }, appConfig.getUamUpdateSettingTimeout() * 1000);

                        if (isHolder) {
                            activeHolderProcessTimeout = activeTimeout;
                        } else {
                            activeProcessTimeout = activeTimeout;
                        }

                        return;
                    }
                }
            }
        } catch (e) {
            log.error(`uamClientService: updateUamSettings failed. error: ${e}`, logMessageTypes.UAM);
        }
    }

    appErrorService.showDeviceSettingsGlobalError();
    dispatch(setYapUpdateSettingsInProgress(false, isHolder));
};

const waitUntilGetUamAsset = async (deviceSerialNumber, timer = appConfig.getUamWaitAssetTimeout()) => {
    const uamAsset = makeSelectAsset(deviceSerialNumber)(getState());

    if (uamAsset) return uamAsset;

    if (--timer < 0) {
        log.error('uamClientService: Uam asset does not exist for registered device.', logMessageTypes.UAM);
        return null;
    }

    await helpers.timeout(1000);
    return waitUntilGetUamAsset(deviceSerialNumber, timer);
};

const waitUntilDataCollectionCanceled = async (timer = appConfig.getUamWaitAssetTimeout()) => {
    const state = getState();
    const isYapSynchronizeDataCollectionInProgress = makeSelectIsYapSynchronizeDataCollectionInProgress()(state);

    if (!isYapSynchronizeDataCollectionInProgress) return true;

    if (--timer < 0) {
        log.error('uamClientService: Uam Data Collection failed to cancel.', logMessageTypes.UAM);
        return false;
    }

    await helpers.timeout(1000);
    return await waitUntilDataCollectionCanceled(timer);
};

const activateDevice = async (deviceSerialNumber) => {
    await tryActivateDeactivateDevice(deviceSerialNumber, true);
};

const deactivateDevice = async (deviceSerialNumber) => {
    await tryActivateDeactivateDevice(deviceSerialNumber, false);
};

const tryActivateDeactivateDevice = async (deviceSerialNumber, isActivate) => {
    const isYapActivationInProgress = makeSelectIsYapActivationInProgress()(getState());

    if (isYapActivationInProgress) return;

    clearTimeout(activeProcessTimeout);
    dispatch(setYapActivationInProgress(true));

    const isCancelSuccess = await waitUntilDataCollectionCanceled();

    if (!isCancelSuccess) {
        dispatch(setYapActivationInProgress(false));
        return;
    }

    try {
        const uamAsset = await waitUntilGetUamAsset(deviceSerialNumber);

        if (uamAsset) {
            try {
                await uamService.syncAndSubscribeOnDevice();
                return await activateDeactivateDevice(uamAsset, isActivate);
            } catch (e) {
                log.error(`uamClientService: IsActivate: ${isActivate} failed. error: ${e}`, logMessageTypes.UAM);
            }
        }

        await getAssets();
        dispatch(setYapActivationInProgress(false));
    } catch (e) {
        log.error(`uamClientService: IsActivate: ${isActivate} failed. error: ${e}`, logMessageTypes.UAM);
        dispatch(setYapActivationInProgress(false));
    }
};

const activateDeactivateDevice = async (uamAsset, isActivate) => {
    const isConnected = await cmClientService.waitUntilConnected(uamAsset?.assetId);

    if (isConnected) {
        if (isActivate) {
            log.info('Start activation process.');
        } else {
            log.info('Start deactivation process.');
        }

        const method = isActivate ? uamMethods.ACTIVATE_DEVICE : uamMethods.DEACTIVATE_DEVICE;
        const {requestId} =
            (await uamClient
                .callPost({
                    methodName: method,
                    requestConfig: {args: uamAsset?.assetId},
                    isNeedRedirectForOtherErrors: false,
                })
                .catch(() => {
                    log.error(`uamClientService: IsActivate: ${isActivate} failed. Action error`, logMessageTypes.UAM);
                    dispatch(setYapActivationInProgress(false));
                })) || {};

        if (requestId) {
            dispatch(setYapLastRequestId(requestId));

            activeProcessTimeout = setTimeout(() => {
                const isYapActivationInProgress = makeSelectIsYapActivationInProgress()(getState());

                if (!isYapActivationInProgress) return;

                analyticsService.pushActivationFailedError(ACTIVATION_ERRORS_DATA.ERROR_ACTIVATION_TIMEOUT_ERROR);

                dispatch(setYapActivationInProgress(false));
            }, appConfig.getUamTimeout() * 1000);
        } else {
            analyticsService.pushActivationFailedError(ACTIVATION_ERRORS_DATA.ERROR_IOT_SYSTEM_ERROR);
            dispatch(setYapActivationInProgress(false));
        }
    } else {
        dispatch(setYapActivationInProgress(false));
    }
};

const checkConnectivityStatus = async (assetId) => {
    const isConnected = await cmClientService.isCmClientConnected(assetId);

    if (isConnected) {
        const data = await uamClient.callGet({
            methodName: uamMethods.CHECK_CONNECTIVITY_STATUS,
            requestConfig: {args: assetId},
            isNeedRedirectForOtherErrors: false,
        });

        if (data) {
            const isHolderAssetId = makeSelectIsHolderByAssetId(assetId)(getState());

            if (data.status === yapConnectionStatusTypes.CONNECTED) {
                if (isHolderAssetId) {
                    dispatch(setYapHolderConnected(true));
                } else {
                    dispatch(setYapDeviceConnected(true));
                }
            } else if (data.status === yapConnectionStatusTypes.DISCONNECTED) {
                if (isHolderAssetId) {
                    dispatch(setYapHolderConnected(true));
                } else {
                    dispatch(setYapDeviceConnected(false));
                }
            }
        }
    }
};

let syncProcessTimeout;
const synchronizeDevice = async (isHolder, serialNumber) => {
    const state = getState();
    const isDeviceSyncInProgress = makeSelectIsDeviceSyncInProgress(isHolder)(state);

    if (isDeviceSyncInProgress) return;

    clearTimeout(syncProcessTimeout);
    dispatch(setYapSyncInProgress(true, isHolder));

    const uamAsset = await waitUntilGetUamAsset(serialNumber);

    if (!uamAsset?.assetId) {
        dispatch(setYapSyncInProgress(false, isHolder));
        return;
    }

    const isYapActivationInProgress = makeSelectIsYapActivationInProgress()(state);
    const isYapUpdateSettingsInProgress = makeSelectIsYapUpdateSettingsInProgress()(state);
    const isYapHolderUpdateSettingsInProgress = makeSelectIsYapUpdateSettingsInProgress(true)(state);
    const isYapFirmwareInProgress = makeSelectIsYapFirmwareInProgress()(state);
    const isFirmwareInProgress = selectIsFirmwareInProgress(state);
    const isSomeFlowInProgress =
        isFirmwareInProgress ||
        isYapActivationInProgress ||
        isYapUpdateSettingsInProgress ||
        isYapFirmwareInProgress ||
        isYapHolderUpdateSettingsInProgress;

    if (!isSomeFlowInProgress) {
        //forced reconnect to CM before sync because of 500 error code for sync (busy for new flow)
        dispatch(setYapDeviceConnected(false));
    }

    await uamService.syncAndSubscribeOnDevice(isHolder);
    const isConnected = await cmClientService.waitUntilConnected(uamAsset?.assetId);

    if (isConnected) {
        const iotDevice = makeSelectIotDeviceData()(getState());
        const {isHolderSbl, isSblMode} = iotDeviceService.checkIfDeviceIsInSblMode(iotDevice) || {};

        if (isSblMode) {
            if (isHolder && isHolderSbl) {
                dispatch(setHolderInfoReceived(true));
                dispatch(setYapSyncInProgress(false, isHolder));
                return;
            } else if (!isHolder && !isHolderSbl) {
                dispatch(setDeviceInfoReceived(true));
                dispatch(setYapSyncInProgress(false, isHolder));
                return;
            }
        }

        const iqosService = new IqosServiceClient();
        let isDeviceConnected = iqosService.isDeviceConnected();

        if (isDeviceConnected) {
            if (isSomeFlowInProgress) {
                const isDeviceInfoValid = await getUamInfo(uamAsset?.assetId);

                if (isDeviceInfoValid) {
                    isDeviceConnected = iqosService.isDeviceConnected();
                    if (isDeviceConnected) {
                        dispatch(setIsDeviceReady());
                    }
                }
                dispatch(setYapSyncInProgress(false, isHolder));
                return;
            }

            log.info('Start synchronization process.');
            const {requestId} =
                (await uamClient.callPost({
                    methodName: uamMethods.SYNCHRONIZE_DEVICE,
                    requestConfig: {args: uamAsset?.assetId},
                    isNeedRedirectForOtherErrors: false,
                })) || {};

            if (requestId) {
                dispatch(setSyncLastRequestId(requestId));

                syncProcessTimeout = setTimeout(async () => {
                    const isDeviceSyncInProgress = makeSelectIsDeviceSyncInProgress(isHolder)(getState());

                    if (!isDeviceSyncInProgress) return;

                    log.info('End synchronization process. Timeout.');
                    dispatch(setYapSyncInProgress(false, isHolder));
                }, appConfig.getUamUpdateSettingTimeout() * 1000);
                return;
            }
        }
    }

    dispatch(setYapSyncInProgress(false, isHolder));
};

const connectDevice = (assetId) =>
    uamClient.callGet({
        methodName: uamMethods.CONNECT_DEVICE,
        requestConfig: {args: assetId},
        isNeedRedirectForOtherErrors: false,
    });

const assetSubscribe = (assetId) =>
    uamClient.callGet({
        methodName: uamMethods.ASSET_SUBSCRIBE,
        requestConfig: {args: assetId},
        isNeedRedirectForOtherErrors: false,
    });

const getActivationStatus = (codentify) =>
    uamClient.callGet({
        methodName: uamMethods.ASSET_ACTIVATION_STATUS,
        requestConfig: {args: codentify},
        ignoreErrorCodes: true,
    });

const getUamFirmwareAvailability = async (targets_holder) => {
    const {deviceSerialNumber} = makeSelectIotDeviceData()(getState());
    const uamAsset = makeSelectAsset(deviceSerialNumber)(getState());
    const assetId = uamAsset?.assetId;

    const firmwareResponse = await uamClient.callGet({
        methodName: uamMethods.GET_FIRMWARE_AVAILABILITY,
        requestConfig: {args: assetId},
        isNeedRedirectForOtherErrors: false,
    });

    if (!firmwareResponse) return false;

    yapStateService.updateDeviceSettings({
        type: yapDataChangeTypes.YAP_AVAILABLE_FIRMWARE_VERSION_PROPERTY,
        newValue: firmwareResponse,
        isHolder: targets_holder,
    });
    return true;
};

const updateUamFirmware = async (fwuTarget) => {
    const isYapFirmwareInProgress = makeSelectIsYapFirmwareInProgress()(getState());

    if (isYapFirmwareInProgress) return;

    clearTimeout(activeProcessTimeout);

    dispatch(setYapFirmwareInProgress(true));
    dispatch(setFWUStarted(fwuTarget));

    const {deviceSerialNumber} = makeSelectIotDeviceData()(getState());
    const uamAsset = makeSelectAsset(deviceSerialNumber)(getState());

    if (uamAsset) {
        try {
            await uamService.syncAndSubscribeOnDevice();

            const isConnected = await cmClientService.waitUntilConnected(uamAsset?.assetId);

            if (isConnected) {
                log.info('Start firmware process.');

                const {requestId} =
                    (await uamClient.callPost({
                        methodName: uamMethods.UPDATE_FIRMWARE,
                        requestConfig: {args: uamAsset?.assetId},
                        isNeedRedirectForOtherErrors: false,
                    })) || {};

                if (requestId) {
                    dispatch(setYapLastRequestId(requestId));
                    return;
                }
            }
        } catch (e) {
            log.error(`uamClientService: updateUamFirmware failed. error: ${e}`, logMessageTypes.GAM);
        }
    }

    dispatch(setYapFirmwareInProgress(false));
    dispatch(setFWUError());
};

const checkUamRequestStatus = async ({assetId, requestId, isSync = false, isHolder = false}) => {
    const data = await uamClient.callGet({
        methodName: uamMethods.CHECK_REQUEST_STATUS,
        requestConfig: {args: [assetId, requestId]},
        isNeedRedirectForOtherErrors: false,
    });

    const isCompleted = data?.status === yapActionStatusTypes.SUCCESS || data?.status === yapActionStatusTypes.FAILED;

    if (isCompleted) {
        if (!isSync) {
            await getUamInfo(assetId);

            const isYapUpdateSettingsInProgress = makeSelectIsYapUpdateSettingsInProgress(isHolder)(getState());

            if (isYapUpdateSettingsInProgress && data?.status === yapActionStatusTypes.FAILED) {
                appErrorService.showDeviceSettingsGlobalError();
            }
        }
        return data?.status;
    }

    return yapActionStatusTypes.PENDING;
};

const findMyDevice = async (data) => {
    const isYapUpdateSettingsInProgress = makeSelectIsYapUpdateSettingsInProgress()(getState());

    if (isYapUpdateSettingsInProgress) return;

    clearTimeout(activeProcessTimeout);

    const state = getState();
    const {deviceSerialNumber} = makeSelectIotDeviceData()(state);
    const uamAsset = makeSelectAsset(deviceSerialNumber)(state);
    const assetId = uamAsset?.assetId;

    dispatch(setYapUpdateSettingsInProgress(true));
    dispatch(setYapFindDeviceReceived(false));

    const updateSettingsFailedAction = () => {
        dispatch(setYapUpdateSettingsInProgress(false));
        appErrorService.showDeviceSettingsGlobalError();
    };

    const isCancelSuccess = await waitUntilDataCollectionCanceled();

    if (!isCancelSuccess) {
        updateSettingsFailedAction();

        return;
    }

    await uamClient
        .callPost({
            methodName: uamMethods.FIND_MY_DEVICE,
            requestConfig: {args: assetId, params: data},
            isNeedRedirectForOtherErrors: false,
        })
        .then((response) => {
            const {requestId} = response || {};

            if (requestId) {
                dispatch(setYapLastRequestId(requestId));

                activeProcessTimeout = setTimeout(() => {
                    const isYapUpdateSettingsInProgress = makeSelectIsYapUpdateSettingsInProgress()(getState());

                    if (!isYapUpdateSettingsInProgress) return;

                    updateSettingsFailedAction();
                }, appConfig.getUamUpdateSettingTimeout() * 1000);
            } else {
                updateSettingsFailedAction();
            }
        });
};

const SYNCHRONIZE_DATA_RETRY_COUNT = 3;
let synchronizeDataRequestCurrentTries = 0;
const proceedSynchronizeAllData = async (isHolder, isRetry = false) => {
    if (isRetry) {
        synchronizeDataRequestCurrentTries++;
    }

    if (synchronizeDataRequestCurrentTries >= SYNCHRONIZE_DATA_RETRY_COUNT) {
        if (!isHolder) {
            // set device data collection to success to run holder data collection flow
            dispatch(setYapSynchronizeDeviceDataCollectionReceived(true));
            synchronizeDataRequestCurrentTries = 0;
        }
        log.error(`uamClientService: synchronizeAllData failed.`, logMessageTypes.UAM);
        return resetSynchronizeDataCollection();
    }

    const state = getState();
    const isDeviceDataCollectionReceived = makeSelectIsYapSynchronizeDeviceDataCollectionReceived()(state);
    const isHolderDataCollectionReceived = makeSelectIsYapSynchronizeHolderDataCollectionReceived()(state);
    const isDataCollectionReceived = isHolder ? isHolderDataCollectionReceived : isDeviceDataCollectionReceived;

    if (isDataCollectionReceived) return resetSynchronizeDataCollection();

    const isYapSynchronizeDataCollectionInProgress = makeSelectIsYapSynchronizeDataCollectionInProgress()(state);

    if (isYapSynchronizeDataCollectionInProgress && !isRetry) return;

    const {device} = makeSelectIotDeviceData()(state) || {};
    const {isHolderConnected} = device || {};

    if (!isHolderConnected && isHolder) return resetSynchronizeDataCollection();

    await synchronizeAllData(isHolder);
};

const getDataCollectionUserAgent = () => {
    const appId = appConfig.getIccApiHeader_a();
    const appVersion = appConfig.getAppVersion();
    const userAgent = userBrowserService.getUserAgent();

    return `${appId}/${appVersion} ${userAgent}`;
};

const resetSynchronizeDataCollection = () => {
    const isYapSynchronizeDataCollectionInProgress = makeSelectIsYapSynchronizeDataCollectionInProgress()(getState());

    if (isYapSynchronizeDataCollectionInProgress) {
        dispatch(setYapSynchronizeDataCollectionInProgress(false));
    }
};

const synchronizeAllData = async (isHolder) => {
    const state = getState();

    const {deviceSerialNumber: chargerSerialNumber, holder} = makeSelectIotDeviceData()(state);
    const {deviceSerialNumber: holderSerialNumber} = holder || {};
    const serialNumber = isHolder ? holderSerialNumber : chargerSerialNumber;
    const uamAsset = await waitUntilGetUamAsset(serialNumber);
    const {assetId} = uamAsset || {};

    await uamService.syncAndSubscribeOnDevice(isHolder);
    const isConnected = await cmClientService.waitUntilConnected(assetId);

    if (!isConnected) return resetSynchronizeDataCollection();

    const isYapDataCollectionAllowed = makeSelectIsYapDataCollectionAllowed()(getState());

    if (!isYapDataCollectionAllowed) return resetSynchronizeDataCollection();

    dispatch(setYapSynchronizeDataCollectionInProgress(true));

    const body = {
        overrideEntitiesToCollect: ['SETTINGS', 'USAGE_NEWEST_FIRST', 'ERRORS', 'EVENTS'],
    };

    const onFail = async () => {
        await helpers.timeout(1000);
        return await proceedSynchronizeAllData(isHolder, true);
    };

    const userAgent = getDataCollectionUserAgent();
    // eslint-disable-next-line no-unused-vars
    const headers = {...uamClient.getHeaders(isHolder), 'User-Agent': userAgent};

    await uamClient
        .callPost({
            methodName: uamMethods.SYNCHRONIZE_ALL_DATA,
            requestConfig: {args: assetId, data: body},
            isNeedRedirectForOtherErrors: false,
        })
        .then((response) => {
            const {requestId} = response || {};

            if (requestId) {
                dispatch(setYapLastRequestId(requestId));
                return;
            }

            onFail();
        });
};

const CANCEL_RETRY_COUNT = 3;
let cancelCurrentTries = 0;

const cancelSynchronizeData = async (isRetry = false) => {
    const state = getState();
    const isYapCancelDataCollectionInProgress = makeSelectYapCancelDataCollectionInProgress()(state);

    if (isYapCancelDataCollectionInProgress && !isRetry) return;
    dispatch(setYapCancelDataCollectionInProgress(true));

    const isYapSynchronizeDataCollectionInProgress = makeSelectIsYapSynchronizeDataCollectionInProgress()(state);

    if (!isYapSynchronizeDataCollectionInProgress) {
        dispatch(setYapCancelDataCollectionInProgress(false));
        return;
    }

    const onFail = async (error) => {
        if (cancelCurrentTries < CANCEL_RETRY_COUNT) {
            cancelCurrentTries++;
            await helpers.timeout(1000);
            await cancelSynchronizeData(true);
        } else {
            log.info(`uamClientService: cancelSynchronizeData failed. error: ${error}`, logMessageTypes.UAM);
            cancelCurrentTries = 0;
            dispatch(setYapCancelDataCollectionInProgress(false));
        }
    };

    const previousRequestId = makeSelectYapLastRequestId()(state);

    if (!previousRequestId) {
        await onFail('Request id is not defined to cancel data collection');
        return;
    }

    const isDeviceDataCollectionReceived = makeSelectIsYapSynchronizeDeviceDataCollectionReceived()(state);
    const {deviceSerialNumber: chargerSerialNumber, holder} = makeSelectIotDeviceData()(state);
    const {deviceSerialNumber: holderSerialNumber} = holder || {};
    const serialNumber = isDeviceDataCollectionReceived ? holderSerialNumber : chargerSerialNumber;
    const uamAsset = makeSelectAsset(serialNumber)(state);
    const assetId = uamAsset?.assetId;

    if (!assetId) {
        dispatch(setYapCancelDataCollectionInProgress(false));
        return;
    }

    try {
        const response = await uamClient.callGet({
            methodName: uamMethods.CANCEL_REQUEST,
            requestConfig: {args: [assetId, previousRequestId]},
            isNeedRedirectForOtherErrors: false,
        });

        const {code} = response;
        const CANCEL_SUCCESS_CODE = 'REQUEST_CANCELED';
        const REQUEST_ALREADY_FINISHED = 'REQUEST_ALREADY_FINISHED';

        if (code === CANCEL_SUCCESS_CODE || code === REQUEST_ALREADY_FINISHED) {
            dispatch(setYapLastRequestId(null));
            dispatch(setYapSynchronizeDataCollectionInProgress(false));
            dispatch(setYapCancelDataCollectionInProgress(false));
            cancelCurrentTries = 0;
            return;
        }
        await onFail('Could not cancel synchronize all data');
    } catch (e) {
        await onFail(e);
    }
};

export default {
    activateDevice,
    assetSubscribe,
    cancelSynchronizeData,
    checkConnectivityStatus,
    checkUamRequestStatus,
    connectDevice,
    deactivateDevice,
    findMyDevice,
    getActivationStatus,
    getAssets,
    getUamFirmwareAvailability,
    getUamInfo,
    proceedSynchronizeAllData,
    synchronizeDevice,
    updateUamFirmware,
    updateUamSettings,
    waitUntilGetUamAsset,
    waitUntilDataCollectionCanceled,
};
