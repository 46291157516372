import React from 'react';

import Icon from './Icon';

const IconLocate = (props) => {
    return (
        <Icon width='56' height='56' viewBox='0 0 56 56' {...props}>
            <g fill='none' fillRule='evenodd' strokeWidth='2.261'>
                <path d='M28 48c11.046 0 20-8.954 20-20S39.046 8 28 8 8 16.954 8 28s8.954 20 20 20z' />
                <path d='M28 33.652a5.652 5.652 0 1 0 0-11.304 5.652 5.652 0 0 0 0 11.304z' />
                <path strokeLinecap='round' d='M28 42v12M28 14V2M42 28h12M2 28h12' />
            </g>
        </Icon>
    );
};

export default IconLocate;
