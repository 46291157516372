import {config} from '../config';

export default {
    getGamCheckRequestInterval: () => config.data.gam.gamCheckRequestInterval || 0,
    getGamCheckStatusInterval: () => config.data.gam.gamCheckStatusInterval,
    getGamTimeout: () => config.data.gam.gamTimeout,
    getGamWebSocketMessageTimeout: () => config.data.gam.gamWebSocketMessageTimeout,
    getGamUpdateSettingTimeout: () => config.data.gam.gamUpdateSettingTimeout,
    getGamFirmwareTimeout: () => config.data.gam.gamFirmwareTimeout,
    getGamApiUrl: () => config.data.gam.gamApiUrl,
    getGamWssUrl: () => config.data.gam.gamWssUrl,
};
