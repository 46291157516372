import * as httpErrorsTypes from '../../consts/app/httpErrorsTypes';

export const REGISTERED_PRODUCT_LIMIT = {
    errorCode: httpErrorsTypes.BAD_REQUEST,
    errorMessage: 'You have reached max number of registered products',
};
export const TOO_MANY_REGISTRATION_REQUESTS = {
    errorCode: httpErrorsTypes.TOO_MANY_REQUESTS,
    errorMessage: 'Too Many Requests',
};

export const REGISTRATION_FAILED_ERRORS = [REGISTERED_PRODUCT_LIMIT, TOO_MANY_REGISTRATION_REQUESTS];
