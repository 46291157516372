import cn from 'classnames';
import React, {FC} from 'react';

import * as localizationKeys from '../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import routerService from '../../services/routerService';
import Button from '../Button/Button';
import {IButton} from '../Button/ButtonTypes';
import BackIcon from '../Icons/BackIcon/BackIcon';
import styles from './BackButton.module.scss';

interface IProps extends IButton {
    ariaLabel?: string;
    testId?: string;
    onClick?: () => void;
    className?: string;
}

const BackButton: FC<IProps> = ({className, onClick = routerService.goBack, alt, ...restProps}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <Button className={cn(styles.Button, className)} onClick={onClick} {...restProps}>
            <BackIcon ariaLabel={alt} />
            <span>{localizedStrings && localizedStrings[localizationKeys.GLOBAL_BACK_BUTTON_TEXT]}</span>
        </Button>
    );
};

export default BackButton;
