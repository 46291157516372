import authDataStoreService from '../../auth/authDataStoreService';
import domService from '../../domService';
import {tokenMapping} from '../../mapping/dceMarketMappings';
import server from '../../server/server';

const fetchDce1AuthToken = async () => {
    try {
        const tokenUrl = domService.getTokenUrlFromRoot();
        const response = await server.get(tokenUrl);
        const {dceAuthToken} = tokenMapping(response.data);

        authDataStoreService.setSpiceToken(dceAuthToken);

        return {dceAuthToken};
    } catch (e) {
        Promise.reject(`fetchDce1AuthToken error: ${e}`);
    }
};

export default {
    fetchDce1AuthToken,
};
