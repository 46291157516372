import {getMappedInitialLanguageCode} from '../../services/localization/localizationService';
import appConfig from '../appConfig';
import {config} from '../config';
import {TConfigGetterReturn} from './types';

const CULTURE_CODE_MARKER = '{culture_code}';

const getCorrectLink = (linkPath: any): string | undefined => {
    if (linkPath) {
        const language = getMappedInitialLanguageCode();

        if (typeof linkPath === 'object') {
            const isSelectedLanguageLinkExists = !!linkPath[language];

            if (isSelectedLanguageLinkExists) {
                return linkPath[language];
            } else {
                const defaultLanguage = appConfig.getLocalizationKey();

                if (defaultLanguage) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    return linkPath[defaultLanguage.toLowerCase()];
                }
            }
        } else if (typeof linkPath === 'string') {
            const isCultureCodeInsertNeeded = linkPath.indexOf(CULTURE_CODE_MARKER);

            if (isCultureCodeInsertNeeded) {
                return linkPath.replace(CULTURE_CODE_MARKER, language);
            } else {
                return linkPath;
            }
        }
    }
};

export default {
    getLinkAndroidStoreChrome: (): TConfigGetterReturn<string> =>
        getCorrectLink(config.data.appLinks.linkAndroidStoreChrome),
    getLinkAndroidStoreIqos: (): TConfigGetterReturn<string> =>
        getCorrectLink(config.data.appLinks.linkAndroidStoreIqos),
    getLinkDesktopApps: (): TConfigGetterReturn<string> => getCorrectLink(config.data.appLinks.linkDesktopApps),
    getLinkDesktopChrome: (): TConfigGetterReturn<string> => getCorrectLink(config.data.appLinks.linkDesktopChrome),
    getLinkIosStoreBluenet: (): TConfigGetterReturn<string> => getCorrectLink(config.data.appLinks.linkIosStoreBluenet),
    getLinkIosStoreIqos: (): TConfigGetterReturn<string> => getCorrectLink(config.data.appLinks.linkIosStoreIqos),
    getLinkMacWidget: (): TConfigGetterReturn<string> => getCorrectLink(config.data.appLinks.linkMacWidget),
    getLinkWindowsWidget: (): TConfigGetterReturn<string> => getCorrectLink(config.data.appLinks.linkWindowsWidget),
};
