import cn from 'classnames';
import React, {FC, forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import Slider from 'react-slick';

import * as localizationKeys from '../../consts/localization/localizationKeys';
import domService from '../../services/domService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import log from '../../services/logger/log';
import helpers from '../../utils/helpers';
import Button from '../Button/Button';
import ConnectionTrackButton from '../Button/ConnectionTrackButton/ConnectionTrackButton';
import styles from './Carousel.module.scss';
import CarouselArrowNext from './CarouselArrow/CarouselArrowNext';
import CarouselArrowPrev from './CarouselArrow/CarouselArrowPrev';
import {IBuildOption, ICarousel} from './CarouselTypes';

const afterChange = () => {
    try {
        const carouselEl = document.getElementsByClassName('slick-track')[0];
        const sliders = carouselEl.querySelectorAll('.slick-slide');

        sliders.forEach((slider) => {
            const isSliderVisible = slider.getAttribute('aria-hidden') !== 'true';
            const button = slider.querySelector('button, a'); //'Device settings' button is rendered as anchor(paired device)

            if (button) {
                const buttonTabIndex = button.getAttribute('tabindex');
                const requiredTabIndex = isSliderVisible ? '0' : '-1';

                if (buttonTabIndex !== requiredTabIndex) {
                    button.setAttribute('tabindex', requiredTabIndex);
                }
            }
        });
    } catch (e) {
        log.debug(`Carousel: afterChange error: ${e}`);
    }
};

const Carousel: FC<ICarousel> = forwardRef((props, ref) => {
    const {
        ariaLabelLastSlide,
        ariaLabelLeft,
        ariaLabelRight,
        arrowNextTestId,
        arrowPrevTestId,
        children,
        controlArrowClassName,
        dotsClassName,
        dotsTestId,
        focusOnSelect,
        isAccessibilityEnabled = true,
        isAdaptiveHeight,
        isArrowVisible = true,
        isCommonNextSlideButtonVisible,
        isControlArrowVisible,
        isDotsFocusEnabled = true,
        isDotsVisible = true,
        isDraggable = true,
        isInfinite,
        isSwipeEnabled = true,
        lastSlideButton,
        lastSlideRoutePath,
        isNextSlideButtonConnectionTrack,
        nextButtonClassName,
        nextSlideButtonClassName,
        nextSlideButtonTestId,
        onEdge,
        onTransitionEnd,
        options,
        isTabletShowArrows = false,
    } = props;
    const localizedStrings = getLocalizedStrings();
    const [isLastSlide, setIsLastSlide] = useState(false);
    const isLastSlideButtonVisible = isArrowVisible && lastSlideButton && isLastSlide;
    const isNextSlideButtonVisible = isArrowVisible && isLastSlide && !lastSlideButton;
    const routePath = isLastSlide && lastSlideRoutePath ? lastSlideRoutePath : null;
    const itemsCount = children.length;
    const sliderRef: any = useRef(null);

    useEffect(() => {
        if (sliderRef.current) {
            (() => onSliderInit())();
        }
    }, [sliderRef.current]);

    const onNextBtnClick = () => {
        sliderRef.current.slickNext();
    };

    const afterSlide = (index: number) => {
        setIsLastSlide(index === itemsCount - 1);
        helpers.runFunction(onTransitionEnd, `${index}`);
    };

    const getCustomPaging = (i: number) => {
        const currentDotNumber = i + 1;
        const ariaLabel = localizedStrings.formatString(
            localizedStrings[localizationKeys.CAROUSEL_DOTS_BTN_ARIA_LABEL],
            currentDotNumber,
            itemsCount
        );

        return (
            <button aria-label={ariaLabel} tabIndex={isDotsFocusEnabled ? 0 : -1}>
                {currentDotNumber}
            </button>
        );
    };

    const onSliderInit = () => {
        const isElementSingle = itemsCount === 1;

        if (isElementSingle) {
            setIsLastSlide(true);
        }

        helpers.runFunction(afterChange, [0]);
    };

    const addDotsTestIDs = () => {
        if (dotsTestId) {
            const dots = domService.getAllElementsBySelector(`.${styles.Dots} li button`);

            dots.forEach((element) => {
                element.dataset.testid = dotsTestId;
            });
        }
    };

    const builtinOptions: IBuildOption = {
        customPaging: getCustomPaging,
        adaptiveHeight: isAdaptiveHeight,
        arrows: isControlArrowVisible,
        dots: isDotsVisible,
        dotsClass: cn(dotsClassName, styles.Dots, {
            [styles.DotsFocus]: isDotsFocusEnabled,
        }),
        infinite: isInfinite,
        nextArrow: (
            <CarouselArrowNext
                ariaLabelLastSlide={ariaLabelLastSlide}
                ariaLabel={ariaLabelRight}
                testId={arrowNextTestId}
                customClassName={controlArrowClassName}
                to={routePath || undefined}
                isTabletShowArrows={isTabletShowArrows}
                isConnectionTrackButton={isLastSlide && isNextSlideButtonConnectionTrack}
            />
        ),
        prevArrow: (
            <CarouselArrowPrev
                ariaLabel={ariaLabelLeft}
                customClassName={controlArrowClassName}
                testId={arrowPrevTestId}
                isInfinite={isInfinite}
                isTabletShowArrows={isTabletShowArrows}
            />
        ),
        speed: 300,
        waitForAnimate: true,
        focusOnSelect,
        accessibility: isAccessibilityEnabled,
        draggable: isDraggable,
        swipe: isSwipeEnabled,
        onEdge,
        onInit: () => {
            addDotsTestIDs();
        },
    };

    /* Replace with afterChange when fix on 'react-slick'
    comes out (current version ^ 0.26.1),
    problem when adaptiveHeight and afterChange are used together */
    if (isAdaptiveHeight) {
        builtinOptions.beforeChange = (current: number, next: number) => afterSlide(next);
        builtinOptions.afterChange = afterChange;
    } else {
        builtinOptions.afterChange = (index: number) => {
            afterSlide(index);
            helpers.runFunction(afterChange, [index]);
        };
    }

    const mergedOptions = {...builtinOptions, ...options};

    useImperativeHandle(ref, () => ({
        slide: (index: number) => sliderRef.current.slickGoTo(index),
        next: () => sliderRef.current.slickNext(),
        prev: () => sliderRef.current.slickPrev(),
        slider: sliderRef.current,
    }));

    return (
        <div>
            <Slider {...mergedOptions} ref={sliderRef}>
                {children}
            </Slider>

            {isLastSlideButtonVisible && lastSlideButton}

            {isNextSlideButtonVisible &&
                (isNextSlideButtonConnectionTrack ? (
                    <ConnectionTrackButton
                        button={Button}
                        className={cn(styles.NextBtn, nextButtonClassName)}
                        onClick={isLastSlide ? undefined : onNextBtnClick}
                        showArrow
                        to={routePath || undefined}
                        ariaLabel={ariaLabelLastSlide}
                    >
                        {localizedStrings[localizationKeys.QUICK_START_GUIDE_NEXT_BUTTON_TEXT]}
                    </ConnectionTrackButton>
                ) : (
                    <Button
                        className={cn(styles.NextBtn, nextButtonClassName)}
                        onClick={isLastSlide ? undefined : onNextBtnClick}
                        showArrow
                        to={routePath || undefined}
                        ariaLabel={ariaLabelLastSlide}
                    >
                        {localizedStrings[localizationKeys.QUICK_START_GUIDE_NEXT_BUTTON_TEXT]}
                    </Button>
                ))}

            {isCommonNextSlideButtonVisible &&
                !isNextSlideButtonVisible &&
                !isLastSlide &&
                (isNextSlideButtonConnectionTrack ? (
                    <ConnectionTrackButton
                        button={Button}
                        ariaLabel={ariaLabelRight}
                        className={nextSlideButtonClassName}
                        onClick={onNextBtnClick}
                        showArrow
                        testId={nextSlideButtonTestId}
                    >
                        {localizedStrings[localizationKeys.QUICK_START_GUIDE_NEXT_BUTTON_TEXT]}
                    </ConnectionTrackButton>
                ) : (
                    <Button
                        ariaLabel={ariaLabelRight}
                        className={nextSlideButtonClassName}
                        onClick={onNextBtnClick}
                        showArrow
                        testId={nextSlideButtonTestId}
                    >
                        {localizedStrings[localizationKeys.QUICK_START_GUIDE_NEXT_BUTTON_TEXT]}
                    </Button>
                ))}
        </div>
    );
});

Carousel.displayName = 'Carousel';

export default Carousel;
