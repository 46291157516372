import appConfig from '../../config/appConfig';
import * as iotHolderStatusTypes from '../../consts/iot/iotHolderStatusTypes';
import * as iotMessageErrorCodes from '../../consts/iot/iotMessageErrorCodes';
import * as iotMessageStatusTypes from '../../consts/iot/iotMessageStatusTypes';
import * as iotMessageTypes from '../../consts/iot/iotMessageTypes';
import {clearCurrentFlowType, setMessage} from '../../state/ducks/iot';
import {
    clearDeviceConnectionCareState,
    clearIotDevice,
    clearIotDeviceData,
    clearIotDeviceHolderData,
    setFWUError,
    setFWUFinished,
    setIsConnected,
    setIsDeviceReady,
    setIsDisconnected,
    setIsPairingInProgress,
    setIsUpdateSettingsNeeded,
    updateIotDeviceData,
} from '../../state/ducks/iotDevice/actions';
import {clearMwDeviceInternalId, setMwDeviceInternalId} from '../../state/ducks/mwDevice';
import {
    setDeviceInfoReceived,
    setHolderInfoReceived,
    setYapSynchronizeHolderDataCollectionReceived,
} from '../../state/ducks/yapEncrypted';
import {selectIsCurrentFlowCleaning} from '../../state/selectors/iot';
import {
    makeSelectIotDeviceData,
    makeSelectIsDeviceReconnected,
    makeSelectIsPairingInProgress,
    makeSelectIsReset,
    selectIsFirmwareFinished,
    selectIsIotDeviceP4,
} from '../../state/selectors/iotDevice';
import {dispatch, getState} from '../../state/store';
import helpers from '../../utils/helpers';
import appErrorService from '../app/appErrorService';
import backendService from '../app/backendService';
import connectivityService from '../device/connectivityService';
import deviceAssetService from '../device/deviceAssetService';
import fwuTargetService from '../device/fwuTargetService';
import ErrorHandlerService from '../errorHandlerService';
import AnotherSerialNumberError from '../errors/anotherSerialNumberError';
import iccConsumerProducts from '../icc/iccConsumerProducts';
import uiMessageRequestService from '../iot/uiMessageRequestService';
import uiRequestService from '../iot/uiRequestService';
import deviceConfigService from '../iotDevice/iotDeviceConfigService';
import iotDeviceResetService from '../iotDevice/iotDeviceResetService';
import iotDeviceService from '../iotDevice/iotDeviceService';
import log from '../logger/log';
import {deviceInfoMapping, deviceParentModeMapping, uiResponseBaseMapping} from '../mapping/iotMappings';
import productService from '../product/productService';
import appRouterService from '../route/appRouterService';
import uamService from '../uam/uamService';
import yapService from '../yap/yapService';
import scpCloudService from './scpCloudService';
import uiIotMessageRequestService from './uiIotMessageRequestService';
import uiIotMessageResponseChecker from './uiIotMessageResponseChecker';

const DEVICE_RESET_TIMEOUT = 60 * 1000;
let deviceResetTimeout;

const onMessage = async (topic, message) => {
    try {
        const {request_id, type, status, body} = message;
        const data = uiResponseBaseMapping(body?.data);
        const error_code = body?.error?.code;

        if (request_id) {
            const isRequestExists = uiIotMessageResponseChecker.some(request_id);

            // During SCP Cloud performance tests was founded that
            // sometimes duplication of messages is possible (with ~3 mins delay).
            // So here we are checking if request with such requestId is not already processed.
            // If request doesn't exist -> skip processing.

            if (isRequestExists) {
                uiIotMessageResponseChecker.clearResponseTimeout(request_id);

                if (status === iotMessageStatusTypes.FINISHED) {
                    uiIotMessageResponseChecker.removeRequestData(request_id);
                }
            } else {
                return;
            }
        }

        switch (type) {
            case iotMessageTypes.CONNECTION:
                onConnectionMessage(status, data);
                break;
            case iotMessageTypes.DEVICE_INFO:
                await onDeviceInfoMessage(status, data);
                break;
            case iotMessageTypes.DEVICE_STATUS_CHARACTERISTIC:
            case iotMessageTypes.DEVICE_STATUS_CHARACTERISTIC_USB:
                onDeviceStatusCharacteristic(data);
                break;
            case iotMessageTypes.CONNECTION_CARE:
                onConnectionCareMessage(status, data);
                break;
            case iotMessageTypes.LED:
                if (data) {
                    const ledData = {ledData: data};

                    dispatch(updateIotDeviceData(ledData));
                }
                break;
            case iotMessageTypes.VIBRATION:
                if (data) {
                    const vibrationData = {vibrationData: data};

                    dispatch(updateIotDeviceData(vibrationData));
                }
                break;
            case iotMessageTypes.AUTO_START:
                if (data) {
                    const autoStartData = {autoStartData: data};

                    dispatch(updateIotDeviceData(autoStartData));
                }
                break;
            case iotMessageTypes.GESTURE_MODE:
                if (data) {
                    const gestureModeData = {gestureModeData: data};

                    dispatch(updateIotDeviceData(gestureModeData));
                }
                break;
            case iotMessageTypes.FIRMWARE_UPDATE:
                await onFWUMessage(status, data, error_code);
                break;
            case iotMessageTypes.PARENT_PROTECTION:
                const mappedData = deviceParentModeMapping(data);

                dispatch(updateIotDeviceData(mappedData));
                break;
            case iotMessageTypes.HOLDER_STATUS:
                dispatch(updateIotDeviceData(data));
                break;
            case iotMessageTypes.CLEANING:
                setFlow(iotMessageTypes.CLEANING, status);
                dispatch(updateIotDeviceData(data));
                break;
            case iotMessageTypes.RESPONSIVE_DRAW_PROFILES:
                if (data) {
                    const responsiveDrawData = {responsiveDrawData: data};

                    dispatch(updateIotDeviceData(responsiveDrawData));
                }
                break;
            case iotMessageTypes.VAPE_CLOUD_SIZE:
                if (data) {
                    const vapeCloudSizeData = {vapeCloudSizeData: data};

                    dispatch(updateIotDeviceData(vapeCloudSizeData));
                }
                break;

            case iotMessageTypes.BATTERY_INFORMATION_CHARACTERISTIC:
                if (data) {
                    dispatch(updateIotDeviceData(data));
                }
                break;

            default:
                break;
        }

        if (status === iotMessageStatusTypes.ERROR) {
            new ErrorHandlerService().handleError(message);
        }

        dispatch(setMessage(message));
    } catch (e) {
        const logMethod = e instanceof AnotherSerialNumberError ? log.info : log.error;

        logMethod(`uiIotMessageResponseService: onMessage error: ${e}`);
        appErrorService.showGlobalErrorWithAppReset();
    }
};

const setFlow = (type, status) => {
    switch (status) {
        case iotMessageStatusTypes.FINISHED:
            // CLEANING flow is not finished after FINISHED status - check DeviceCleaningModePopup
            if (type === iotMessageTypes.CLEANING) break;

            dispatch(clearCurrentFlowType());
            break;
        case iotMessageStatusTypes.ERROR:
            dispatch(clearCurrentFlowType());
            break;
    }
};

const onConnectionMessage = (status) => {
    switch (status) {
        case iotMessageStatusTypes.CONNECTED:
            clearDeviceResetTimeout();
            break;
        case iotMessageStatusTypes.DISCONNECTED:
            const state = getState();
            const isReset = makeSelectIsReset()(state);
            const isFirmwareFinished = selectIsFirmwareFinished(state);

            dispatch(setIsDisconnected());
            dispatch(clearMwDeviceInternalId());
            scpCloudService.disconnectIoT();
            const shouldInitResetTimeout = (isReset || isFirmwareFinished) && connectivityService.isBle();

            if (shouldInitResetTimeout) {
                initDeviceResetTimeout();
            }
            break;
        default:
            break;
    }
};

const publishParentProtectionStatusMessage = (iotDevice) => {
    const isParentProtectionMode = deviceConfigService.isParentProtectionMode(iotDevice);

    if (isParentProtectionMode) {
        uiMessageRequestService(true).publishParentProtectionStatusMessage();
    }
};

const publishParentProtectionStatusMessageIfNeeded = (mappedData) => {
    const state = getState();
    const isCurrentFlowCleaning = selectIsCurrentFlowCleaning(state);

    if (isCurrentFlowCleaning) return;

    const {deviceSerialNumber, holder_state} = mappedData?.holder || {};

    if (!deviceSerialNumber || holder_state !== iotHolderStatusTypes.READY_TO_USE) {
        return;
    }

    publishParentProtectionStatusMessage(mappedData);
    dispatch(setIsUpdateSettingsNeeded(true));
};

const onDeviceInfoMessage = async (status, data) => {
    const onInfoMessage = async () => {
        if (data) {
            const mappedData = deviceInfoMapping(data);

            dispatch(updateIotDeviceData(mappedData));
            loadIotDeviceAssets(mappedData);
        }

        if (status !== iotMessageStatusTypes.FINISHED) return;

        const state = getState();
        const isReset = makeSelectIsReset()(state);
        const isDeviceReconnected = makeSelectIsDeviceReconnected()(state);
        const iotDevice = makeSelectIotDeviceData()(state);
        const isDataCollectionEnabled = appConfig.getIsDataCollectionEnabled();
        const isUamBackend = backendService.isUamBackend();

        if (isReset) {
            iotDeviceResetService.onResetDevice();
        }

        dispatch(setIsDeviceReady());
        dispatch(setIsPairingInProgress(false));
        productService.setProductRegistrationAttempts(0);

        if (isDeviceReconnected && (isDataCollectionEnabled || isUamBackend)) {
            dispatch(setDeviceInfoReceived(false));
            dispatch(setHolderInfoReceived(false));
            await uamService.checkIsDeviceActivatedInUam();
            await uamService.initSynchronizeFlow({isLoaderVisible: true});
        }

        sendPingMessageIfNeeded();
        publishParentProtectionStatusMessage(iotDevice);
        uiRequestService.publishDeviceStatusCharacteristicUsbMessageIfNeeded();

        iotDeviceService.checkIfDeviceIsInSblMode(iotDevice);
    };

    await checkIfIsDeviceIdentificationFlow(data, onInfoMessage);
};

const sendPingMessageIfNeeded = () => {
    if (!yapService.isYapEncryptedMode()) {
        const isP4Device = selectIsIotDeviceP4(getState());

        if (isP4Device) {
            uiIotMessageRequestService.publishDevicePingMessage();
        }
    }
};

const onDeviceStatusCharacteristic = (data) => {
    if (data) {
        const mappedData = deviceInfoMapping(data);
        const holderState = mappedData?.holder?.holder_state;

        if (mappedData.holder?.mediaId) {
            loadIotDeviceAssets(mappedData);
        }

        if (holderState === iotHolderStatusTypes.UNPLUGGED) {
            const isDataCollectionEnabled = appConfig.getIsDataCollectionEnabled();

            if (isDataCollectionEnabled) {
                dispatch(setHolderInfoReceived(false));
                dispatch(setYapSynchronizeHolderDataCollectionReceived(false));
            }
        }

        publishParentProtectionStatusMessageIfNeeded(mappedData);
        dispatch(updateIotDeviceData(mappedData));

        if (holderState === iotHolderStatusTypes.UNPLUGGED) {
            dispatch(clearIotDeviceHolderData());
        }

        iotDeviceService.checkIfDeviceIsInSblMode(mappedData);
    }
};

const loadIotDeviceAssets = (data) => {
    const {device, holder} = data || {};
    const mediaNamesList = deviceAssetService.getDeviceAssetsList([
        device?.mediaId,
        holder?.mediaId,
        holder?.signleHolderMediaId,
    ]);

    if (mediaNamesList.length) {
        iccConsumerProducts.getProductsAssets(mediaNamesList);
    }
};

const onConnectionCareMessage = (status, data) => {
    switch (status) {
        case iotMessageStatusTypes.STARTED:
            dispatch(clearDeviceConnectionCareState());
            break;
        case iotMessageStatusTypes.IN_PROGRESS:
        case iotMessageStatusTypes.FINISHED:
            if (data) {
                dispatch(updateIotDeviceData(data));
            }
            break;
        default:
            break;
    }
};

const onFWUMessage = async (status, data, error_code) => {
    switch (status) {
        case iotMessageStatusTypes.CHECK:
            updateFwuCheckData(data);
            break;
        case iotMessageStatusTypes.PACKAGE_LOADED:
            updatePackageLoadedData(data, true);
            break;
        case iotMessageStatusTypes.IN_PROGRESS:
            const isUsb = connectivityService.isUsb();

            if (isUsb) {
                await checkIfIsDeviceIdentificationFlow(data);
            }
            dispatch(updateIotDeviceData(data));

            break;
        case iotMessageStatusTypes.FINISHED:
            dispatch(setFWUFinished());
            dispatch(updateIotDeviceData(data));

            break;
        case iotMessageStatusTypes.ERROR:
            onFwuErrorMessage(data, error_code);
            break;
        default:
            dispatch(updateIotDeviceData(data));
    }

    const isPairingInProgress = makeSelectIsPairingInProgress()(getState());

    if (isPairingInProgress) {
        dispatch(setIsPairingInProgress(false));
    }
};

const onFwuErrorMessage = (data, error_code) => {
    dispatch(setFWUError());
    if (error_code === iotMessageErrorCodes.FW_CHECK_ERROR_DOWNLOAD_OR_PARSE) {
        updatePackageLoadedData(data, false);
    }
};

const checkIfIsDeviceIdentificationFlow = async (data, onInfoMessage) => {
    const dataInternalId = data?.deviceSerialNumber;
    const isIdentificationFlow = !!dataInternalId;

    if (isIdentificationFlow) {
        await onIdentifyDevice(dataInternalId, onInfoMessage);
    } else {
        helpers.runFunction(onInfoMessage);
    }
};

const onIdentifyDevice = async (deviceSerialNumber, onInfoMessage) => {
    const deviceFromState = makeSelectIotDeviceData()(getState());
    const deviceFromStateInternalId = deviceFromState?.deviceSerialNumber;
    const isSameDeviceInState = deviceFromStateInternalId === deviceSerialNumber;

    if (deviceFromStateInternalId) {
        if (isSameDeviceInState) {
            dispatch(clearIotDeviceData());
        } else if (connectivityService.isUsb()) {
            throw new AnotherSerialNumberError(
                `There is device with another serialNumber in state, device in state: ${deviceFromStateInternalId}, serial number from response: ${deviceSerialNumber}`
            );
        } else {
            dispatch(clearIotDevice());
        }
    }

    dispatch(setMwDeviceInternalId({deviceSerialNumber, isIdentified: true}));
    dispatch(setIsConnected());
    await scpCloudService.reInitIoT(onInfoMessage);
};

const onDisconnect = () => {
    appRouterService.forwardToMyDevicesPage();
};

const initDeviceResetTimeout = () => {
    deviceResetTimeout = setTimeout(onDeviceResetTimeout, DEVICE_RESET_TIMEOUT);
};

const onDeviceResetTimeout = () => {
    log.info(`uiIotMessageResponseService: onDeviceResetTimeout, device reset timeout error`);
    onDisconnect();
    iotDeviceResetService.onResetDevice();
};

const clearDeviceResetTimeout = () => {
    clearTimeout(deviceResetTimeout);
};

const updateFwuCheckData = (data) => {
    const [iotTargetName] = Object.keys(data);
    const updateData = helpers.mergeDeep(data, {
        [iotTargetName]: {
            firmware: {
                isCheckInProgress: false,
            },
        },
    });

    dispatch(updateIotDeviceData(updateData));
};

const updatePackageLoadedData = (data, package_loaded) => {
    const {fwu_target} = data;
    const iotTargetName = fwuTargetService.getIotTargetNameByFwuTarget(fwu_target);
    const updateData = {[iotTargetName]: {firmware: {package_loaded}}};

    dispatch(updateIotDeviceData(updateData));
};

export default {
    onMessage,
};
