import imgIconAppleDesktop3 from '../../../assets/images/compatible-browser/icon-apple-mobile@3x.jpg';
import imgIconAndroidDesktop3 from '../../../assets/images/compatible-browser/icon-google-mobile@3x.png';
import imgIconMacOSDesktop3 from '../../../assets/images/compatible-browser/icon-macos-desktop@3x.png';
import imgIconWindowsDesktop3 from '../../../assets/images/compatible-browser/icon-windows-desktop@3x.png';
import imgP1_1Desktop from '../../../assets/images/compatible-browser/image-p1-desktop.jpg';
import imgP1_2Desktop from '../../../assets/images/compatible-browser/image-p1-desktop@2x.jpg';
import imgP1_3Desktop from '../../../assets/images/compatible-browser/image-p1-desktop@3x.jpg';
import imgP4_1Desktop from '../../../assets/images/compatible-browser/image-p4-desktop.jpg';
import imgP4_2Desktop from '../../../assets/images/compatible-browser/image-p4-desktop@2x.jpg';
import imgP4_3Desktop from '../../../assets/images/compatible-browser/image-p4-desktop@3x.jpg';
import imgP1_1Mobile from '../../../assets/images/not-compatible-browser/iphoneP1.png';
import imgP1_2Mobile from '../../../assets/images/not-compatible-browser/iphoneP1@2x.png';
import imgP1_3Mobile from '../../../assets/images/not-compatible-browser/iphoneP1@3x.png';
import imgP4_1Mobile from '../../../assets/images/not-compatible-browser/iphoneP4.png';
import imgP4_2Mobile from '../../../assets/images/not-compatible-browser/iphoneP4@2x.png';
import imgP4_3Mobile from '../../../assets/images/not-compatible-browser/iphoneP4@3x.png';
import appConfig from '../../config/appConfig';
import languageTypes from '../../consts/localization/languageTypes';
import * as localizationKeys from '../../consts/localization/localizationKeys';
import deviceSupportService from '../../services/device/deviceSupportService';
import {getLocalizedLanguage, getLocalizedStrings} from '../../services/localization/localizationService';

let data;

const getBrowserCompatibilityPageData = (availableContent, isBrowserCompatible) => {
    if (!data) {
        const localizedStrings = getLocalizedStrings();
        const {isAndroidContent, isIOSContent, isMacOSContent, isWindowsContent} = availableContent || {};

        if (isAndroidContent) {
            data = {
                link: appConfig.getLinkAndroidStoreIqos(),
                browserLink: appConfig.getLinkAndroidStoreChrome(),
                srcSet: [imgIconAndroidDesktop3],
                text: isBrowserCompatible
                    ? localizedStrings[localizationKeys.COMPATIBLE_BROWSER_PAGE_GOOGLE_BUTTON_TEXT]
                    : localizedStrings[localizationKeys.NOT_COMP_ANDROID_APP_BUTTON_TEXT],
                ariaLabel: isBrowserCompatible
                    ? localizedStrings[localizationKeys.APP_INTRO_PAGE_BTN_DOWNLOAD_ANDROID_OS_ARIA_LABEL]
                    : null,
            };
        } else if (isIOSContent) {
            data = {
                link: appConfig.getLinkIosStoreIqos(),
                browserLink: appConfig.getLinkIosStoreBluenet(),
                srcSet: [imgIconAppleDesktop3],
                text: isBrowserCompatible
                    ? localizedStrings[localizationKeys.COMPATIBLE_BROWSER_PAGE_APPLE_BUTTON_TEXT]
                    : localizedStrings[localizationKeys.NOT_COMP_BLUEFY_APP_BUTTON_TEXT],
                ariaLabel: isBrowserCompatible
                    ? localizedStrings[localizationKeys.APP_INTRO_PAGE_BTN_DOWNLOAD_IOS_OS_ARIA_LABEL]
                    : null,
            };
        } else if (isMacOSContent) {
            data = {
                link: appConfig.getLinkMacWidget(),
                browserLink: appConfig.getLinkDesktopChrome(),
                srcSet: [imgIconMacOSDesktop3],
                text: isBrowserCompatible
                    ? localizedStrings[localizationKeys.COMPATIBLE_BROWSER_PAGE_MACOS_BUTTON_TEXT]
                    : localizedStrings[localizationKeys.NOT_COMP_MAC_WIDGET_APP_BUTTON_TEXT],
                ariaLabel: isBrowserCompatible
                    ? localizedStrings[localizationKeys.APP_INTRO_PAGE_BTN_DOWNLOAD_MAC_OS_ARIA_LABEL]
                    : null,
            };
        } else if (isWindowsContent) {
            data = {
                link: appConfig.getLinkWindowsWidget(),
                browserLink: appConfig.getLinkDesktopChrome(),
                srcSet: [imgIconWindowsDesktop3],
                text: isBrowserCompatible
                    ? localizedStrings[localizationKeys.COMPATIBLE_BROWSER_PAGE_WINDOWS_BUTTON_TEXT]
                    : localizedStrings[localizationKeys.NOT_COMP_WINDOWS_WIDGET_APP_BUTTON_TEXT],
                ariaLabel: isBrowserCompatible
                    ? localizedStrings[localizationKeys.APP_INTRO_PAGE_BTN_DOWNLOAD_WINDOWS_OS_ARIA_LABEL]
                    : null,
            };
        }
    }

    return data;
};

const getImage = () => {
    const isP1DevicesSupported = deviceSupportService.isP1DevicesSupported();
    const {compatiblePath, notCompatiblePath} = appConfig.getIntroAssetsPaths();
    const uniqueDesktopMediaList = getMediaSet(compatiblePath);
    const uniqueMobileMediaList = getMediaSet(notCompatiblePath);

    const image = {
        desktop: {
            mediaList: [...uniqueDesktopMediaList],
            defaultImage: isP1DevicesSupported
                ? [imgP1_1Desktop, imgP1_2Desktop, imgP1_3Desktop]
                : [imgP4_1Desktop, imgP4_2Desktop, imgP4_3Desktop],
        },
        mobile: {
            mediaList: [...uniqueMobileMediaList],
            defaultImage: isP1DevicesSupported
                ? [imgP1_1Mobile, imgP1_2Mobile, imgP1_3Mobile]
                : [imgP4_1Mobile, imgP4_2Mobile, imgP4_3Mobile],
        },
    };

    return image;
};

const getMediaSet = (path) => {
    const selectedAssetPath = getSelectedAssetByPath(path);
    const defaultByMarketAssetPath = getDefaultAssetByPath(path);
    const uniqueMediaList = new Set([selectedAssetPath, defaultByMarketAssetPath]);

    return uniqueMediaList;
};

const getAssetPathByLangCode = (assetPath, langCode) => {
    const localizedStrings = getLocalizedStrings();

    return localizedStrings.formatString(assetPath, langCode);
};

const getSelectedAssetByPath = (path) => {
    const selectedLangCode = getLocalizedLanguage().toLowerCase();

    const languageCode =
        selectedLangCode === languageTypes.LANGUAGE_DEFAULT ? languageTypes.LANGUAGE_EN : selectedLangCode;

    return getAssetPathByLangCode(path, languageCode);
};

const getDefaultAssetByPath = (path) => {
    const langCodeByMarket = appConfig.getLocalizationKey().toLowerCase();

    return getAssetPathByLangCode(path, langCodeByMarket);
};

export {getBrowserCompatibilityPageData, getImage};
