import React from 'react';

import Icon from './Icon';

const IconLock = (props) => {
    return (
        <Icon width='13' height='16' viewBox='0 0 13 16' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g stroke='#272A33'>
                    <g transform='translate(-314 -231) translate(314 232)'>
                        <rect width='11.6' height='9.6' x='.7' y='4.7' strokeWidth='1.4' rx='1' />
                        <path strokeLinecap='round' strokeWidth='1.2' d='M6.346 8L6.346 10.51' />
                        <path strokeWidth='1.5' d='M11 4.5C11 2.015 8.985 0 6.5 0S2 2.015 2 4.5' />
                    </g>
                </g>
            </g>
        </Icon>
    );
};

export default IconLock;
