import * as errorPageTypes from '../consts/app/errorPageTypes';
import {makeSelectIsDeviceReady} from '../state/selectors/iotDevice';
import {getState} from '../state/store';
import helpers from '../utils/helpers';
import appRouterService from './route/appRouterService';

let instance = null;

export default class HttpErrorHandlerService {
    constructor() {
        if (instance) {
            return instance;
        }

        instance = this;
        this.errorHandler = null;
        this.globalHandler = null;
        this.httpErrorTypes = [];
    }

    setErrorHandler({httpErrorTypes, handler, globalHandler}) {
        this.errorHandler = handler;
        this.httpErrorTypes = httpErrorTypes;
        this.globalHandler = globalHandler;
    }

    removeErrorHandler() {
        this.errorHandler = null;
        this.globalHandler = null;
        this.httpErrorTypes = [];
    }

    handleError(httpError) {
        const serviceError = httpError?.response?.data?.serviceError;
        const errorCode = serviceError?.code;
        const errorMessage = serviceError?.message?.value;

        const targetHttpError = this.httpErrorTypes.find(
            (errorType) => errorType.errorCode === errorCode && errorType.errorMessage === errorMessage
        );

        if (targetHttpError) {
            helpers.runFunction(this.errorHandler, targetHttpError);
        } else if (this.globalHandler) {
            helpers.runFunction(this.globalHandler, errorCode);
        } else {
            const isDeviceReady = makeSelectIsDeviceReady()(getState());

            if (!isDeviceReady) {
                appRouterService.forwardToErrorHandlingPage(errorPageTypes.ICC_TEMPORARY_ERROR);
            }
        }
    }
}
