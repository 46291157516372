import LayoutTypes from '../enums/layout/layoutTypes';

const isDesktopLayout = (layout: LayoutTypes): boolean => layout === LayoutTypes.DESKTOP;
const isMobileLayout = (layout: LayoutTypes): boolean => layout === LayoutTypes.MOBILE;
const isTabletLayout = (layout: LayoutTypes): boolean => layout === LayoutTypes.TABLET;

export default {
    isDesktopLayout,
    isMobileLayout,
    isTabletLayout,
};
