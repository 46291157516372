import React from 'react';

import Icon from './Icon';

const IconHolder = (props) => {
    return (
        <Icon width='8' height='45' viewBox='0 0 8 45' {...props}>
            <g fill='none' fillRule='evenodd' transform='translate(.577 .817)'>
                <path
                    stroke='#383839'
                    strokeWidth='2.26'
                    d='M7.415 17.958l.265.717a2.58 2.58 0 0 1-.068.35l-.666.246-.391-1.06.86-.253zm-1.15 2.737c-.135.07-.277.13-.425.178l-2.284.724a2.705 2.705 0 0 1-2.074-.177v13.981c0 1.988.175 3.986.52 5.936l.254 1.488c.01.06.078.115.202.115h2.83c.11 0 .188-.061.197-.121l.261-1.488c.344-1.937.518-3.935.518-5.93V20.695zm-4.919.65a2.67 2.67 0 0 1-.302-.21l.438-.628v.787l-.136.05zM.345 18.995l-.27-.727c.02-.133.05-.263.09-.39l.627-.232.392 1.06-.84.289zm1.137-2.672a2.67 2.67 0 0 1 .423-.173l2.291-.73a2.71 2.71 0 0 1 2.068.181v-6.97c0-1.986-.174-3.974-.518-5.903L5.481 1.23c-.006-.04-.081-.101-.193-.101h-2.83c-.125 0-.194.055-.203.112L2 2.728a33.887 33.887 0 0 0-.518 5.903v7.692zM6.3 15.62c.125.067.245.145.359.232l-.395.567v-.786l.036-.013z'
                />
                <rect
                    width='1.972'
                    height='1.408'
                    x='3.69'
                    y='17.524'
                    fill='#383839'
                    rx='.704'
                    transform='rotate(-18 4.676 18.228)'
                />
            </g>
        </Icon>
    );
};

export default IconHolder;
