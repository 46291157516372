import ConfigClient from './configClient';
import * as marketNames from './marketNames';

let marketConfigsList;

if (process.env.REACT_APP_IS_BUILD_REF === 'true') {
    marketConfigsList = [
        {
            urls: [
                `https://local.pmi.com/`,
                `https://fieldcoach.qa-pmidce2.com/`,
                `https://www.origin.dev.iqos.com/fr`,
                `https://www.dev.iqos.com/fr`,
                `https://www.dev.veev-vape.com/fr/`,
            ],
            configImport: () => import(/* webpackChunkName: "lazy-chunk.16" */ '../marketConfigs/fr/stg-ref'),
        },
    ];
} else {
    const pnnEnvUrl = 'https://iqos-connect-stg.pnnproducts.com';
    const pnnEnvUrlPublic = 'https://public.iqos-connect-stg.pnnproducts.com';
    const envUrl = 'https://iqos-connect-stg-web.iot.dev-pmidce.com';

    marketConfigsList = [
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.RO_P4}`,
                `${pnnEnvUrlPublic}/${marketNames.RO_P4}`,
                `${envUrl}/${marketNames.RO_P4}`,
            ],
            configImport: () => import('../marketConfigs/ro_p4/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.RO}`,
                `${pnnEnvUrlPublic}/${marketNames.RO}`,
                `${envUrl}/${marketNames.RO}`,
            ],
            configImport: () => import('../marketConfigs/ro/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.NZ}`,
                `${pnnEnvUrlPublic}/${marketNames.NZ}`,
                `${envUrl}/${marketNames.NZ}`,
            ],
            configImport: () => import('../marketConfigs/nz/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.CZ}`,
                `${pnnEnvUrlPublic}/${marketNames.CZ}`,
                `${envUrl}/${marketNames.CZ}`,
            ],
            configImport: () => import('../marketConfigs/cz/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.FR}`,
                `${pnnEnvUrlPublic}/${marketNames.FR}`,
                `${envUrl}/${marketNames.FR}`,
            ],
            configImport: () => import('../marketConfigs/fr/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.FI}`,
                `${pnnEnvUrlPublic}/${marketNames.FI}`,
                `${envUrl}/${marketNames.FI}`,
            ],
            configImport: () => import('../marketConfigs/fi/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.IT_P4}`,
                `${pnnEnvUrlPublic}/${marketNames.IT_P4}`,
                `${envUrl}/${marketNames.IT_P4}`,
            ],
            configImport: () => import('../marketConfigs/it_p4/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.IT_P1}`,
                `${pnnEnvUrlPublic}/${marketNames.IT_P1}`,
                `${envUrl}/${marketNames.IT_P1}`,
            ],
            configImport: () => import('../marketConfigs/it_p1/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.GR}`,
                `${pnnEnvUrlPublic}/${marketNames.GR}`,
                `${envUrl}/${marketNames.GR}`,
            ],
            configImport: () => import('../marketConfigs/gr/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.UK_P4}`,
                `${pnnEnvUrlPublic}/${marketNames.UK_P4}`,
                `${envUrl}/${marketNames.UK_P4}`,
            ],
            configImport: () => import('../marketConfigs/uk_p4/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.CH}`,
                `${pnnEnvUrlPublic}/${marketNames.CH}`,
                `${envUrl}/${marketNames.CH}`,
            ],
            configImport: () => import('../marketConfigs/ch/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.PMI}`,
                `${pnnEnvUrlPublic}/${marketNames.PMI}`,
                `${envUrl}/${marketNames.PMI}`,
            ],
            configImport: () => import('../marketConfigs/pmi/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.UAT}`,
                `${pnnEnvUrlPublic}/${marketNames.UAT}`,
                `${envUrl}/${marketNames.UAT}`,
            ],
            configImport: () => import('../marketConfigs/uat/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.D_M}`,
                `${pnnEnvUrlPublic}/${marketNames.D_M}`,
                `${envUrl}/${marketNames.D_M}`,
            ],
            configImport: () => import('../marketConfigs/d_m/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.CAON}`,
                `${pnnEnvUrlPublic}/${marketNames.CAON}`,
                `${envUrl}/${marketNames.CAON}`,
            ],
            configImport: () => import('../marketConfigs/caon/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.CAAB}`,
                `${pnnEnvUrlPublic}/${marketNames.CAAB}`,
                `${envUrl}/${marketNames.CAAB}`,
            ],
            configImport: () => import('../marketConfigs/caab/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.CABC}`,
                `${pnnEnvUrlPublic}/${marketNames.CABC}`,
                `${envUrl}/${marketNames.CABC}`,
            ],
            configImport: () => import('../marketConfigs/cabc/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.PL_P1}`,
                `${pnnEnvUrlPublic}/${marketNames.PL_P1}`,
                `${envUrl}/${marketNames.PL_P1}`,
            ],
            configImport: () => import('../marketConfigs/pl_p1/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.JP}`,
                `${pnnEnvUrlPublic}/${marketNames.JP}`,
                `${envUrl}/${marketNames.JP}`,
            ],
            configImport: () => import('../marketConfigs/jp/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.NL}`,
                `${pnnEnvUrlPublic}/${marketNames.NL}`,
                `${envUrl}/${marketNames.NL}`,
            ],
            configImport: () => import('../marketConfigs/nl/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.UA}`,
                `${pnnEnvUrlPublic}/${marketNames.UA}`,
                `${envUrl}/${marketNames.UA}`,
            ],
            configImport: () => import('../marketConfigs/ua/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.HR_P4}`,
                `${pnnEnvUrlPublic}/${marketNames.HR_P4}`,
                `${envUrl}/${marketNames.HR_P4}`,
            ],
            configImport: () => import('../marketConfigs/hr_p4/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.ES_P1}`,
                `${pnnEnvUrlPublic}/${marketNames.ES_P1}`,
                `${envUrl}/${marketNames.ES_P1}`,
            ],
            configImport: () => import('../marketConfigs/es_p1/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.AU}`,
                `${pnnEnvUrlPublic}/${marketNames.AU}`,
                `${envUrl}/${marketNames.AU}`,
            ],
            configImport: () => import('../marketConfigs/au/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.CO}`,
                `${pnnEnvUrlPublic}/${marketNames.CO}`,
                `${envUrl}/${marketNames.CO}`,
            ],
            configImport: () => import('../marketConfigs/co/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.AU_P4}`,
                `${pnnEnvUrlPublic}/${marketNames.AU_P4}`,
                `${envUrl}/${marketNames.AU_P4}`,
            ],
            configImport: () => import('../marketConfigs/au_p4/stg-web'),
        },
    ];
}

export default class ConfigClientStg extends ConfigClient {
    constructor() {
        super(marketConfigsList);
    }
}
