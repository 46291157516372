import {setIsBluetoothAvailable} from '../../state/ducks/global';
import {dispatch} from '../../state/store';
import uaUtils from '../../utils/uaUtils';
import log from '../logger/log';

const onTouchStartEvent = 'ontouchstart';

const isMobile = {
    Android: () => navigator.userAgent.match(/Android/i),
    iOS: () => navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iOS/i),
    any: () => isMobile.Android() || isMobile.iOS(),
};

const isTablet = {
    iOS: () => {
        const userAgent = navigator.userAgent;

        const isIPadWordExists = userAgent.match(/iPad/i);

        if (isIPadWordExists) {
            return isIPadWordExists;
        } else {
            const isMacintoshWordExists = userAgent.match(/Macintosh/i);

            if (isMacintoshWordExists) {
                const isTouchDevice = isTouchSupported();

                return !userAgent.match(/Chrome/i) && isTouchDevice;
            }
        }
    },
};

const isDesktop = {
    Windows: () => navigator.userAgent.match(/Windows/i),
    Mac: () => navigator.userAgent.match(/Macintosh/i),
    Linux: () => navigator.userAgent.match(/Linux/i),
};

const isTouchSupported = () => {
    return onTouchStartEvent in window || navigator.maxTouchPoints || navigator.msMaxTouchPoints;
};

const isMobileDevice = () => !!isMobile.any();

const isMobileAndroidDevice = () => !!isMobile.Android();

const isMobileIOSDevice = () => !!isMobile.iOS();

const isTabletIOSDevice = () => !!isTablet.iOS();

const isDesktopWindows = () => !!isDesktop.Windows();

const isDesktopMac = () => !!isDesktop.Mac();

const isDesktopLinux = () => !!isDesktop.Linux();

const isIos_13_4_x = () => {
    const {name, version} = uaUtils.getOS();

    return name.toLowerCase() === 'ios' && version.match('13.4');
};

const isIosLessThan_13_5 = () => {
    const ACCEPTABLE_VERSION_MAJOR = 13;
    const ACCEPTABLE_VERSION_MINOR = 5;

    const {name, version} = uaUtils.getOS();

    const versionMajor = version.split('.')[0];
    const versionMinor = version.split('.')[1];

    return (
        name.toLowerCase() === 'ios' &&
        versionMajor <= ACCEPTABLE_VERSION_MAJOR &&
        versionMinor < ACCEPTABLE_VERSION_MINOR
    );
};

const isBluetoothSupported = () => !!navigator.bluetooth;

const isBluetoothEventListenersSupported = () =>
    navigator.bluetooth.addEventListener && navigator.bluetooth.removeEventListener;

const isUsbSupported = () => !!navigator.hid;

const isAppleTouchDevice = () => isDesktopMac() && isTouchSupported();

const checkBluetoothAvailability = async () => {
    try {
        let isBluetoothAvailable = true;

        if (navigator.bluetooth?.getAvailability) {
            isBluetoothAvailable = await navigator.bluetooth.getAvailability();
        }

        log.info(`userDeviceService: checkBluetoothAvailability isBluetoothAvailable: ${isBluetoothAvailable}`);
        dispatch(setIsBluetoothAvailable(isBluetoothAvailable));
    } catch (e) {
        log.info(`userDeviceService: checkBluetoothAvailability error: ${e}`);
    }
};

export default {
    checkBluetoothAvailability,
    isAppleTouchDevice,
    isBluetoothEventListenersSupported,
    isBluetoothSupported,
    isDesktopLinux,
    isDesktopMac,
    isDesktopWindows,
    isIos_13_4_x,
    isIosLessThan_13_5,
    isMobileAndroidDevice,
    isMobileDevice,
    isMobileIOSDevice,
    isTabletIOSDevice,
    isTouchSupported,
    isUsbSupported,
};
