export const REFRESH_TOKEN = '/identity/refresh-token?identity-key={0}';
export const GET_ASSETS = '/assets';
export const ACTIVATE_DEVICE = '/asset/{0}/device/actions/activate';
export const DEACTIVATE_DEVICE = '/asset/{0}/device/actions/deactivate';
export const CONNECT_DEVICE = '/asset/{0}/device/connectivity/connect';
export const CHECK_REQUEST_STATUS = '/asset/{0}/device/request-status/{1}';
export const CHECK_CONNECTIVITY_STATUS = '/asset/{0}/device/connectivity/status';
export const SYNCHRONIZE_DEVICE = '/asset/{0}/device/actions/synchronize';
export const GET_DEVICE_INFO = '/assets?filter-asset={0}';
export const ASSET_SUBSCRIBE = '/assets/{0}/subscription';
export const ASSET_ACTIVATION_STATUS = '/asset/device/activation-status?codentify-code={0}';
export const UPDATE_SETTINGS = '/asset/{0}/device/actions/updateSetting';
export const GET_FIRMWARE_AVAILABILITY = '/asset/{0}/device/firmwareUpdateAvailability';
export const UPDATE_FIRMWARE = '/asset/{0}/device/actions/updateFirmware';
export const FIND_MY_DEVICE = '/asset/{0}/device/actions/find-my-device';
export const SYNCHRONIZE_ALL_DATA = '/asset/{0}/device/actions/synchronize-all-data';
export const CANCEL_REQUEST = '/asset/{0}/device/connectivity/cancel/{1}';
