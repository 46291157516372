import React from 'react';

import Icon from './Icon';

const FontSizeIcon = (props) => {
    return (
        <Icon width='28' height='24' viewBox='0 0 28 24' {...props}>
            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g transform='translate(-135.000000, -10.000000)'>
                    <g transform='translate(81.000000, 7.000000)'>
                        <g transform='translate(0.000000, 3.000000)'>
                            <g transform='translate(54.000000, 0.000000)'>
                                <g transform='translate(1.000000, 4.000000)' fill='#000000'>
                                    <path d='M14.1230469,15 L14.1230469,14.6777344 C14.1230469,13.0045489 14.2988264,11.570644 14.6503906,10.3759766 C15.0019549,9.18130913 15.5943969,8.00130791 16.4277344,6.8359375 L15.9882812,6.41601562 C15.2786423,5.7519498 14.6373727,5.19856992 14.0644531,4.75585938 L16.3691406,3.07617188 C17.8990962,4.28711543 19.5169186,5.89517227 21.2226562,7.90039062 C21.6132832,7.22330391 21.8720697,6.63086191 21.9990234,6.12304688 C22.1259772,5.61523184 22.1894531,4.91862422 22.1894531,4.03320312 L22.1894531,3.31054688 L24.8945312,3.31054688 L24.8945312,3.87695312 C24.8945312,6.07748496 24.1556063,7.98176279 22.6777344,9.58984375 C23.745448,10.8789127 24.6666628,12.2265555 25.4414062,13.6328125 L23.2636719,15.2441406 C21.9746029,12.9264207 20.2233184,10.6477977 18.0097656,8.40820312 C17.2220013,9.63216758 16.828125,11.5006385 16.828125,14.0136719 L16.828125,15 L14.1230469,15 Z' />
                                    <path d='M10.5371094,15 L9.50195312,11.6015625 L4.296875,11.6015625 L3.26171875,15 L0,15 L5.0390625,0.6640625 L8.74023438,0.6640625 L13.7988281,15 L10.5371094,15 Z M8.77929688,9.0625 C7.82226084,5.98305752 7.28352925,4.24153848 7.16308594,3.83789062 C7.04264263,3.43424277 6.95638047,3.11523555 6.90429688,2.88085938 C6.68945205,3.71419687 6.07422383,5.77472314 5.05859375,9.0625 L8.77929688,9.0625 Z' />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </Icon>
    );
};

export default FontSizeIcon;
