import React, {FC} from 'react';

import {getLocalizedStrings} from '../../services/localization/localizationService';
import Button from '../Button/Button';
import ButtonDark from '../Button/ButtonDark/ButtonDark';
import ConnectionTrackButton from '../Button/ConnectionTrackButton/ConnectionTrackButton';
import PopupErrorStyled from '../Popup/PopupStyled/PopupErrorStyled';
import styles from './FailedPopup.module.scss';

interface IProps {
    headerKey: string;
    descriptionKey: string;
    okBtnKey: string;
    cancelBtnKey: string;
    onClose: () => void;
    onTryAgain: () => void;
    isClosePopupFeaturesDisable: boolean;
    altKey: string;
    ariaLabelTryAgainKey: string;
    ariaLabelDoItLaterKey: string;
    ariaLabelCloseKey: string;
}

const FailedPopup: FC<IProps> = (props) => {
    const {
        headerKey,
        descriptionKey,
        okBtnKey,
        cancelBtnKey,
        onClose,
        onTryAgain,
        isClosePopupFeaturesDisable,
        altKey,
        ariaLabelTryAgainKey,
        ariaLabelDoItLaterKey,
        ariaLabelCloseKey,
    } = props;
    const localizedStrings = getLocalizedStrings();

    return (
        <PopupErrorStyled
            alt={localizedStrings[altKey]}
            closeButtonAriaLabel={localizedStrings[ariaLabelCloseKey]}
            onClose={isClosePopupFeaturesDisable ? undefined : onClose}
            title={localizedStrings[headerKey]}
            description={localizedStrings[descriptionKey]}
        >
            {okBtnKey && (
                <ConnectionTrackButton
                    button={ButtonDark}
                    ariaLabel={localizedStrings[ariaLabelTryAgainKey]}
                    onClick={onTryAgain}
                    showArrow
                >
                    {localizedStrings[okBtnKey]}
                </ConnectionTrackButton>
            )}
            <Button
                ariaLabel={localizedStrings[ariaLabelDoItLaterKey]}
                className={styles.ButtonSecondary}
                onClick={onClose}
                showUnderline
            >
                {localizedStrings[cancelBtnKey]}
            </Button>
        </PopupErrorStyled>
    );
};

export default FailedPopup;
