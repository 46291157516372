import React from 'react';

import Icon from './Icon';

const IconInfo = (props) => {
    return (
        <Icon width='50' height='50' viewBox='0 0 50 50' {...props}>
            <g fill='none' fillRule='evenodd'>
                <circle cx='24.87' cy='24.87' r='23.739' strokeWidth='2.261' />
                <g fill='#383839' transform='translate(22.609 11.304)'>
                    <circle cx='2.261' cy='1.261' r='2.261' />
                    <rect width='4.522' height='20.348' y='6.783' rx='2.261' />
                </g>
            </g>
        </Icon>
    );
};

export default IconInfo;
