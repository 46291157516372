import React from 'react';

import Icon from './Icon';

const QlabVeevPulseLogo = (props) => {
    return (
        <Icon width='35' height='50' viewBox='-2 -8 55 50' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g fill='#272A33'>
                    <path
                        d='M195.672 254c9.458 0 12.434 4.233 12.434 13.095v8.466c0 2.248-.199 4.233-.661 5.886l4.894 3.175c.463.265.661.662.661 1.19 0 .596-.264 1.323-.794 2.05-.462.662-.992.993-1.587.993-.463 0-.926-.133-1.455-.463l-4.034-2.646c-1.985 1.918-4.96 2.91-9.458 2.91h-20.238c-9.458 0-12.434-4.232-12.434-13.095v-8.466c0-8.862 2.976-13.095 12.434-13.095zm-.661 4.696h-18.916c-4.563 0-8.2 1.389-8.2 7.474v10.383c0 6.085 3.637 7.407 8.2 7.407h18.916c2.116 0 4.1-.264 5.555-1.19l-9.59-6.283c-.463-.264-.661-.662-.661-1.19 0-.464.198-1.059.595-1.654.53-.793 1.125-1.19 1.852-1.19.463 0 .992.132 1.521.462l8.796 5.688c.133-.595.199-1.322.199-2.05V266.17c0-6.085-3.704-7.474-8.267-7.474z'
                        transform='translate(-163 -254)'
                    />
                </g>
            </g>
        </Icon>
    );
};

export default QlabVeevPulseLogo;
