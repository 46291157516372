import React, {FC} from 'react';

import * as localizationKeys from '../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import elementsTesIds from '../../../test/consts/elementsTesIds';
import styles from './AppLoader.module.scss';

interface IProps {
    loaderText: string;
}

const AppLoaderContent: FC<IProps> = ({loaderText}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <>
            <div className={styles.AppLoader} data-testid={elementsTesIds.LOADER_CONTROL}>
                <div />
                <div />
                <div />
                <div />
            </div>
            <div className={styles.LoaderMessage}>
                {loaderText !== undefined
                    ? loaderText
                    : localizedStrings && (
                          <>
                              <p className={styles.TextMain}>
                                  {localizedStrings[localizationKeys.GLOBAL_LOADER_DEFAULT_TEXT_MAIN]}
                              </p>
                              <p className={styles.TextSecondary}>
                                  {localizedStrings[localizationKeys.GLOBAL_LOADER_DEFAULT_TEXT_SECONDARY]}
                              </p>
                          </>
                      )}
            </div>
        </>
    );
};

export default AppLoaderContent;
