import cn from 'classnames';
import React, {FC} from 'react';

import routerService from '../../../services/routerService';
import helpers from '../../../utils/helpers';
import ButtonRaw from '../../Button/ButtonRaw';
import styles from './CarouselArrow.module.scss';
import {ICarouselArrowNext} from './types';

interface IProps extends ICarouselArrowNext {
    currentSlide?: number;
    isInfinite?: boolean;
}

const CarouselArrowPrev: FC<IProps> = (props) => {
    const {
        testId,
        className,
        onClick,
        to,
        customClassName,
        ariaLabel,
        ariaLabelLastSlide,
        currentSlide,
        isInfinite,
        isTabletShowArrows,
    } = props;
    const disabled = !isInfinite && currentSlide === 0;

    const onButtonClick = () => {
        if (to) {
            routerService.forwardTo(to);
        } else {
            helpers.runFunction(onClick);
        }
    };

    const arrowClassName = cn(styles.Arrow, className, customClassName, {
        [styles.IsTabletArrowNotVisible]: !isTabletShowArrows,
    });

    return (
        <ButtonRaw
            ariaLabel={to ? ariaLabelLastSlide : ariaLabel}
            className={arrowClassName}
            testId={testId}
            onClick={onButtonClick}
            disabled={disabled}
        />
    );
};

export default CarouselArrowPrev;
