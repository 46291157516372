import React from 'react';

import Icon from './Icon';

const IconHowTo = (props) => {
    return (
        <Icon width='38' height='38' viewBox='0 0 38 38' {...props}>
            <defs>
                <path id='prefix__a' d='M0 0H37.576V37.576H0z' />
            </defs>
            <g fill='none' fillRule='evenodd'>
                <mask id='prefix__b' fill='#fff'>
                    <use xlinkHref='#prefix__a' />
                </mask>
                <g strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.708' mask='url(#prefix__b)'>
                    <g>
                        <path
                            d='M22.364 15.587c-.761 2.035-2.08 3.8-3.77 5.105-.389.297-.795.573-1.22.82l-.008.104v2.231c0 2.182-1.784 3.966-3.966 3.966H9.692c-2.182 0-3.967-1.784-3.967-3.966v-2.231l-.01-.103c-.422-.248-.83-.524-1.217-.821C1.35 18.262-.508 14.245.123 9.838.85 4.755 5.069.67 10.17.081c6.99-.81 12.922 4.634 12.922 11.464 0 1.414-.254 2.767-.718 4.018l-.01.024zM15.265 33.686L7.756 33.686'
                            transform='translate(7.515 1.879)'
                        />
                    </g>
                </g>
            </g>
        </Icon>
    );
};

export default IconHowTo;
