import {useRef} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import useDidUpdate from '../../../hooks/useDidUpdate';
import iotDeviceSettingsService from '../../../services/iotDevice/iotDeviceSettingsService';
import productService from '../../../services/product/productService';
import uamClientService from '../../../services/uam/uamClientService';
import yapService from '../../../services/yap/yapService';
import {setIsYapDataCollectionAllowed} from '../../../state/ducks/yapEncrypted';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../../state/selectors/consumer';
import {
    makeSelectDeviceActivationStatus,
    makeSelectIsDeviceReady,
    selectIsFirmwareInProgress,
} from '../../../state/selectors/iotDevice';
import {
    makeSelectIsYapActivationInProgress,
    makeSelectIsYapDeviceInfoReceived,
    makeSelectIsYapFirmwareInProgress,
    makeSelectIsYapHolderInfoReceived,
    makeSelectIsYapSynchronizeDataCollectionInProgress,
    makeSelectIsYapSynchronizeDeviceDataCollectionReceived,
    makeSelectIsYapUpdateSettingsInProgress,
} from '../../../state/selectors/yapEncrypted';
import {dispatch} from '../../../state/store';

const mapStateToProps = createStructuredSelector({
    isYapDeviceInfoReceived: makeSelectIsYapDeviceInfoReceived(),
    isYapHolderInfoReceived: makeSelectIsYapHolderInfoReceived(),
    iotProduct: makeSelectIotDeviceMergedWithIccProduct(),
    isDeviceReady: makeSelectIsDeviceReady(),
    isYapUpdateSettingsInProgress: makeSelectIsYapUpdateSettingsInProgress(),
    isYapHolderUpdateSettingsInProgress: makeSelectIsYapUpdateSettingsInProgress(true),
    isYapActivationInProgress: makeSelectIsYapActivationInProgress(),
    isYapFirmwareInProgress: makeSelectIsYapFirmwareInProgress(),
    isDeviceActivated: makeSelectDeviceActivationStatus(),
    isYapSynchronizeDeviceDataCollectionReceived: makeSelectIsYapSynchronizeDeviceDataCollectionReceived(),
    isYapSynchronizeDataCollectionInProgress: makeSelectIsYapSynchronizeDataCollectionInProgress(),
    isFirmwareInProgress: selectIsFirmwareInProgress,
});

const DataCollectionHandler = ({
    isYapDeviceInfoReceived,
    isYapHolderInfoReceived,
    isYapSynchronizeDeviceDataCollectionReceived,
    iotProduct,
    isDeviceReady,
    isYapUpdateSettingsInProgress,
    isYapHolderUpdateSettingsInProgress,
    isYapActivationInProgress,
    isYapFirmwareInProgress,
    isDeviceActivated,
    isYapSynchronizeDataCollectionInProgress,
    isFirmwareInProgress,
}) => {
    const {device, holder} = iotProduct || {};
    const {status: deviceStatus, is_device_in_sbl_mode, isP4, isHolderConnected} = device || {};
    const {status: holderStatus} = holder || {};
    const isHolderInSBLMode = iotDeviceSettingsService.isHolderInSBLMode(iotProduct);
    const isDeviceRegistered = productService.getIsRegistered(deviceStatus);
    const isHolderRegistered = productService.getIsRegistered(holderStatus);
    const isDataCollectionEnabled = yapService.isDataCollectionEnabled();
    const DATA_COLLECTION_TIMEOUT = 10000;
    const isActivated = isP4 ? isDeviceActivated : true;
    const dataCollectionDelayTimer = useRef(false);

    useDidUpdate(async () => {
        const isStartDeviceDataCollection =
            isDataCollectionEnabled &&
            isDeviceReady &&
            isYapDeviceInfoReceived &&
            isDeviceRegistered &&
            !isYapUpdateSettingsInProgress &&
            !isYapHolderUpdateSettingsInProgress &&
            !isYapActivationInProgress &&
            !isYapFirmwareInProgress &&
            !isFirmwareInProgress &&
            isActivated &&
            !isYapSynchronizeDeviceDataCollectionReceived &&
            !is_device_in_sbl_mode;

        const isStartHolderDataCollection =
            isDataCollectionEnabled &&
            isDeviceReady &&
            isYapDeviceInfoReceived &&
            !isYapUpdateSettingsInProgress &&
            !isYapHolderUpdateSettingsInProgress &&
            !isYapActivationInProgress &&
            !isYapFirmwareInProgress &&
            !isFirmwareInProgress &&
            isYapHolderInfoReceived &&
            isHolderConnected &&
            isDeviceRegistered &&
            isHolderRegistered &&
            isYapSynchronizeDeviceDataCollectionReceived &&
            !isHolderInSBLMode;

        if (isStartDeviceDataCollection || isStartHolderDataCollection) {
            dataCollectionDelayTimer.current = setTimeout(async () => {
                dispatch(setIsYapDataCollectionAllowed(true));
                await uamClientService.proceedSynchronizeAllData(isStartHolderDataCollection);
            }, DATA_COLLECTION_TIMEOUT);
        } else {
            clearTimeout(dataCollectionDelayTimer.current);
        }
    }, [
        isYapDeviceInfoReceived,
        isDeviceRegistered,
        isHolderRegistered,
        isDeviceReady,
        isYapUpdateSettingsInProgress,
        isYapHolderUpdateSettingsInProgress,
        isYapActivationInProgress,
        isActivated,
        isYapHolderInfoReceived,
        isYapSynchronizeDeviceDataCollectionReceived,
        isDataCollectionEnabled,
        isHolderConnected,
        isYapFirmwareInProgress,
        isFirmwareInProgress,
    ]);

    useDidUpdate(async () => {
        const isStopDeviceDataCollection =
            !isYapSynchronizeDeviceDataCollectionReceived &&
            (!isDataCollectionEnabled ||
                !isDeviceReady ||
                !isDeviceRegistered ||
                isYapUpdateSettingsInProgress ||
                isYapHolderUpdateSettingsInProgress ||
                isYapActivationInProgress ||
                isYapFirmwareInProgress ||
                isFirmwareInProgress ||
                !isActivated ||
                is_device_in_sbl_mode);

        const isStopHolderDataCollection =
            isYapSynchronizeDeviceDataCollectionReceived &&
            (!isDataCollectionEnabled ||
                !isDeviceReady ||
                !isDeviceRegistered ||
                !isHolderRegistered ||
                !isHolderConnected ||
                isYapUpdateSettingsInProgress ||
                isYapHolderUpdateSettingsInProgress ||
                isYapActivationInProgress ||
                isYapFirmwareInProgress ||
                isFirmwareInProgress ||
                isHolderInSBLMode);

        if (isStopDeviceDataCollection || isStopHolderDataCollection) {
            clearTimeout(dataCollectionDelayTimer.current);
            dispatch(setIsYapDataCollectionAllowed(false));
            await uamClientService.cancelSynchronizeData();
        }
    }, [
        isYapDeviceInfoReceived,
        isDeviceRegistered,
        isHolderRegistered,
        isDeviceReady,
        isYapUpdateSettingsInProgress,
        isYapHolderUpdateSettingsInProgress,
        isYapActivationInProgress,
        isYapFirmwareInProgress,
        isActivated,
        isYapHolderInfoReceived,
        isYapSynchronizeDeviceDataCollectionReceived,
        isDataCollectionEnabled,
        isHolderConnected,
        isHolderInSBLMode,
        is_device_in_sbl_mode,
        isYapSynchronizeDataCollectionInProgress,
        isFirmwareInProgress,
    ]);

    return null;
};

export default connect(mapStateToProps)(DataCollectionHandler);
