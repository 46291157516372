import React from 'react';

import Icon from './Icon';

const StatusGestureActiveIcon = (props) => {
    return (
        <Icon width='23' height='31' viewBox='0 0 23 31' {...props}>
            <g fill='none' fillRule='evenodd' strokeLinejoin='round' strokeWidth='1.5'>
                <path
                    d='M16.132 16.461c-.33 0-.643.089-.919.247v-.044c0-1.155-.875-2.095-1.951-2.095-.332 0-.645.09-.92.247v-.047c0-1.156-.875-2.095-1.95-2.095-.333 0-.645.087-.918.243V7.294c0-1.141-.85-2.095-1.896-2.126-.515-.016-1.006.182-1.383.558-.396.395-.624.955-.624 1.536v12.517l-1.533-2.121c-.836-1.157-2.31-1.489-3.506-.789-.497.292-.678.957-.404 1.483.924 1.77 3.22 6.082 4.62 7.948.19.283 2.37 3.408 6.15 3.408 2.092 0 3.852-.767 5.09-2.218.908-1.064 1.485-2.468 1.626-3.954l.467-4.929c.002-.017.002-.034.002-.05 0-1.156-.875-2.096-1.951-2.096h0z'
                    transform='translate(-410 -649) translate(388 633) translate(23 17) translate(.167)'
                />
                <path
                    strokeLinecap='round'
                    d='M13.33 8.932c.316-.762.49-1.598.49-2.474C13.82 2.891 10.93 0 7.363 0 3.796 0 .904 2.891.904 6.458c0 .945.203 1.842.567 2.65'
                    transform='translate(-410 -649) translate(388 633) translate(23 17) translate(.167)'
                />
            </g>
        </Icon>
    );
};

export default StatusGestureActiveIcon;
