import React from 'react';

import Icon from './Icon';

const VibrateIcon = (props) => {
    return (
        <Icon width='39' height='36' viewBox='0 0 39 36' {...props}>
            <g fill='none' fillRule='evenodd' strokeWidth='1.5'>
                <path d='M20.25.75a4.5 4.5 0 0 0-4.5 4.5v25.5a4.5 4.5 0 0 0 4.5 4.5h3.599a.902.902 0 0 0 .901-.901V5.25a4.5 4.5 0 0 0-4.5-4.5z' />
                <g strokeLinecap='round' strokeLinejoin='round'>
                    <path d='M30.976 10.222a12.909 12.909 0 0 1-.367 13.513M35.714 7.54c3.57 6.183 3.134 13.582-.505 19.186' />
                </g>
                <g strokeLinecap='round' strokeLinejoin='round'>
                    <path d='M8.465 10.222a12.909 12.909 0 0 0 .367 13.513M3.727 7.54c-3.57 6.183-3.134 13.582.505 19.186' />
                </g>
            </g>
        </Icon>
    );
};

export default VibrateIcon;
