import React from 'react';

import Icon from './Icon';

const IconExpansionPanelArrow = (props) => {
    return (
        <Icon width='12' height='8' viewBox='0 0 12 8' {...props}>
            <path
                fill='none'
                fillRule='evenodd'
                stroke='#666'
                strokeLinecap='round'
                strokeWidth='1.6'
                d='M1 1l5 5 5-5'
            />
        </Icon>
    );
};

export default IconExpansionPanelArrow;
