import React from 'react';

import Icon from './Icon';

const IconCrossaint = (props) => {
    return (
        <Icon width='31' height='32' viewBox='0 0 31 32' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g strokeWidth='1.5'>
                    <path
                        d='M13.155.054c.396-.012.382.357.146.616-1.966 2.156-3.172 5.069-3.172 8.274 0 6.645 5.183 12.032 11.576 12.032 2.094 0 4.059-.578 5.754-1.59.356-.212 1.134-1.11 1.032-.693-.103.418-.222.829-.358 1.232C26.16 25.792 20.788 30 14.47 30 6.478 30 0 23.266 0 14.96 0 7.217 5.63.841 12.866.012c.387-.045-.107.054.289.042z'
                        transform='translate(-135 -321) translate(72 123) translate(40 178) translate(24 21)'
                    />
                </g>
            </g>
        </Icon>
    );
};

export default IconCrossaint;
