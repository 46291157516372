import {config} from '../config';

export default {
    getIsBackgroundFwuCheckEnabled: () => config.data.fwu.isBackgroundFwuCheckEnabled,
    getIsBleFWAvailableP1: () => config.data.fwu.ble.isP1Available,
    getIsBleFWAvailableP1V4: () => config.data.fwu.ble.isP1V4Available,
    getIsBleFWAvailableP4: () => config.data.fwu.ble.isP4Available,
    getIsUsbFWAvailableP1: () => config.data.fwu.usb.isP1Available,
    getIsUsbFWAvailableP1V4: () => config.data.fwu.usb.isP1V4Available,
    getIsUsbFWAvailableP4: () => config.data.fwu.usb.isP4Available,
};
