import * as loaderNames from '../../consts/loader/loaderNames';
import connectivityService from '../../services/device/connectivityService';
import {hideLoader, showLoader} from '../../state/ducks/global';
import {setIsReset} from '../../state/ducks/iotDevice/actions';
import {dispatch} from '../../state/store';
import helpers from '../../utils/helpers';
import uiMessageRequestService from '../iot/uiMessageRequestService';

const resetDevice = () => {
    showBleLoader();
    uiMessageRequestService().publishResetMessage();
    dispatch(setIsReset(true));
};

const onResetDevice = () => {
    dispatch(setIsReset(false));
    hideBleLoader();
};

const showBleLoader = () => {
    bleLoaderChecker(() => dispatch(showLoader({name: loaderNames.LOADER_RESET})));
};

const hideBleLoader = () => {
    bleLoaderChecker(() => dispatch(hideLoader(loaderNames.LOADER_RESET)));
};

const bleLoaderChecker = (loaderHandler) => {
    const isBle = connectivityService.isBle();

    if (isBle) {
        helpers.runFunction(loaderHandler);
    }
};

export default {
    onResetDevice,
    resetDevice,
};
