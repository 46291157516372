import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {ButtonDark} from '../../../components/Button';
import ConnectionTrackButton from '../../../components/Button/ConnectionTrackButton/ConnectionTrackButton';
import PopupErrorStyled from '../../../components/Popup/PopupStyled/PopupErrorStyled';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import fwuService from '../../../services/ble/fwuService';
import connectivityService from '../../../services/device/connectivityService';
import fwuTargetService from '../../../services/device/fwuTargetService';
import uiMessageRequestService from '../../../services/iot/uiMessageRequestService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import modalService from '../../../services/modalService';
import appRouterService from '../../../services/route/appRouterService';
import {selectIsDeviceDisconnected} from '../../../state/selectors/iotDevice';

const DeviceInSblModePopup = (props) => {
    const {modalType, fwu_target, isInitialFlow} = props;
    const isBle = connectivityService.isBle();
    const isDeviceDisconnected = useSelector(selectIsDeviceDisconnected);
    const localizedStrings = getLocalizedStrings();
    const isFwuTargetHolderRelated = fwuTargetService.isFwuTargetHolderRelated(fwu_target);
    const isHolderViaBle = isBle && isFwuTargetHolderRelated;
    const hideModal = () => modalService.hideModal(modalType);
    const onClick = () => {
        hideModal();

        if (isHolderViaBle) return;

        appRouterService.forwardToUpdateFWPage(fwu_target);

        if (isInitialFlow) {
            uiMessageRequestService().publishInitiateUpdateFirmwareMessage(fwu_target);
        } else {
            fwuService.startFwUpdate({fwu_target});
        }
    };
    const onClose = () => hideModal();

    useEffect(() => {
        if (isDeviceDisconnected) {
            hideModal();
        }
    }, [isDeviceDisconnected]);

    const titleKey = isFwuTargetHolderRelated
        ? localizationKeys.HOLDER_IN_SBL_USB_UPDATE_POPUP_TITLE_TEXT
        : localizationKeys.CHARGER_IN_SBL_USB_UPDATE_POPUP_TITLE_TEXT;
    const descriptionKey = isFwuTargetHolderRelated
        ? localizationKeys.HOLDER_IN_SBL_USB_UPDATE_POPUP_MESSAGE_TEXT
        : localizationKeys.CHARGER_IN_SBL_USB_UPDATE_POPUP_MESSAGE_TEXT;

    let btnKey = localizationKeys.CHARGER_IN_SBL_USB_UPDATE_POPUP_UPDATE_BUTTON_TEXT;

    if (isFwuTargetHolderRelated) {
        btnKey = isBle
            ? localizationKeys.HOLDER_IN_SBL_BLE_UPDATE_POPUP_OK_BUTTON_TEXT
            : localizationKeys.HOLDER_IN_SBL_USB_UPDATE_POPUP_UPDATE_BUTTON_TEXT;
    }

    return (
        <PopupErrorStyled
            title={localizedStrings[titleKey]}
            description={localizedStrings[descriptionKey]}
            onClose={isHolderViaBle ? onClose : undefined}
        >
            <ConnectionTrackButton button={ButtonDark} onClick={onClick}>
                {localizedStrings[btnKey]}
            </ConnectionTrackButton>
        </PopupErrorStyled>
    );
};

export default DeviceInSblModePopup;
