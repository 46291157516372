import framesProcessingService from '../device/framesProcessingService';
import log from '../logger/log';

const PING_INTERVAL_MS = 2 * 60 * 1000;
let pingInterval = null;

const startDevicePing = (frames) => {
    log.debug('devicePingService: startDevicePing is called');
    stopDevicePing();
    if (frames) {
        writePingFrame(frames);
        pingInterval = setInterval(() => writePingFrame(frames), PING_INTERVAL_MS);
    }
};

const writePingFrame = (frames) => {
    framesProcessingService.writeFrameAndPublishResponse({
        frames,
    });
};

const stopDevicePing = () => {
    if (pingInterval) {
        log.debug('devicePingService: stopDevicePing is called');
        clearInterval(pingInterval);
        pingInterval = null;
    }
};

export default {
    startDevicePing,
    stopDevicePing,
};
