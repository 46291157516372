import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import ProgressiveImage from '../../components/Image/ProgressiveImage/ProgressiveImage';
import mediaService from '../../services/mediaService';
import {makeSelectSignedMediaListItem} from '../../state/selectors/media';
import arrayUtils from '../../utils/arrayUtils';

const mapStateToProps = (state, ownProps) => {
    const {mediaData, isThumbnailAvailable} = ownProps;

    const mediaIdList = arrayUtils.toArray(mediaData);
    const thumbnailMediaIdList = mediaService.getThumbnailNameList(mediaIdList);
    const selectors = {
        signedMediaItem: makeSelectSignedMediaListItem(mediaIdList),
    };

    if (isThumbnailAvailable) {
        selectors.mediaItemThumbnailAsset = makeSelectSignedMediaListItem(thumbnailMediaIdList);
    }

    return createStructuredSelector(selectors);
};

const SignedImage = (props) => {
    const {
        mediaData,
        mediaItemThumbnailAsset,
        signedMediaItem,
        defaultImage,
        imgClassName,
        signIfNull,
        isThumbnailAvailable,
        alt,
    } = props;
    const mediaIdList = arrayUtils.toArray(mediaData);
    const filteredMediaIdList = mediaIdList.filter((item) => item);
    const isMediaListNotEmpty = filteredMediaIdList?.length;
    const assetExpiredList = signedMediaItem?.filter((item) => item.isExpired());
    const mapUrl = (item) => item.url;
    const mediaItemAssetUrlList = signedMediaItem?.map(mapUrl);
    let deps = '';
    let srcSet;

    if (mediaItemAssetUrlList) {
        srcSet = [...mediaItemAssetUrlList];
        deps = assetExpiredList.map(mapUrl).join(',');
    }

    useEffect(() => {
        const isExpiredDataNotEmpty = !!assetExpiredList?.length;
        const isSignMediaNeeded = signedMediaItem ? isExpiredDataNotEmpty : signIfNull;

        if (isSignMediaNeeded && isMediaListNotEmpty) {
            const thumbnailMediaIdList = mediaService.getThumbnailNameList(filteredMediaIdList);
            const mediaList = isThumbnailAvailable
                ? [...filteredMediaIdList, ...thumbnailMediaIdList]
                : filteredMediaIdList;

            mediaService.getSignedMediaUrl(mediaList);
        }
    }, [deps]);

    const mediaItemThumbnailAssetUrlList = mediaItemThumbnailAsset?.map((item) => item.url);
    const isMediaUrlFetched = isMediaListNotEmpty ? mediaItemAssetUrlList : true;

    return (
        <ProgressiveImage
            alt={alt}
            imgClassName={imgClassName}
            srcSet={srcSet}
            placeholderSrc={mediaItemThumbnailAssetUrlList}
            isMediaUrlFetched={isMediaUrlFetched}
            defaultImageSrcSet={defaultImage}
        />
    );
};

export default connect(mapStateToProps)(SignedImage);
