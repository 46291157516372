import React, {useLayoutEffect, useRef} from 'react';

const Icon = (props) => {
    const {ariaLabel, className, width, height, viewBox, children, alt} = props;

    const iconRef = useRef();

    useLayoutEffect(() => {
        if (iconRef.current) {
            const childrenElements = [...iconRef.current.getElementsByTagName('*')];

            if (childrenElements.length) {
                childrenElements.forEach((element) => {
                    element.setAttribute('aria-hidden', true);
                });
            }
        }
    }, []);

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox={viewBox}
            className={className}
            aria-label={ariaLabel}
            alt={alt}
            ref={iconRef}
        >
            {children}
        </svg>
    );
};

export default Icon;
