import BLE_UUID_TYPES from '../../enums/ble/bleUuidTypes';
import converter from '../../utils/converter';
import helpers from '../../utils/helpers';
import BleClienNotInitializedError from '../errors/bleClientNotInitializedError';
import BLEClient from './bleClient';
import {IConnectProps, IIqosBleClientOptions} from './bleClientTypes';

let instance: IqosBLEClient | null = null;

export default class IqosBLEClient extends BLEClient {
    private scpCharacteristic?: BluetoothRemoteGATTCharacteristic;
    private deviceStatusCharacteristic?: BluetoothRemoteGATTCharacteristic;
    private batteryInformationCharacteristic?: BluetoothRemoteGATTCharacteristic;
    private fwuDataCharacteristic?: BluetoothRemoteGATTCharacteristic;
    private fwuControlCharacteristic?: BluetoothRemoteGATTCharacteristic;
    private fwuStatusCharacteristic?: BluetoothRemoteGATTCharacteristic;

    // @ts-expect-error cannot call 'super()' as first statement
    constructor(options?: IIqosBleClientOptions) {
        if (!options && instance) {
            return instance;
        }
        if (!options) {
            throw new BleClienNotInitializedError();
        }
        const bleClientOptions = {
            ...options,
            onReconnectSuccess: async () => {
                await this.onConnect();
                options.onDeviceReconnectSuccess();
            },
        };

        super(bleClientOptions);
        instance = this;
    }

    destroy(): void {
        instance = null;
    }

    connect = async ({isNewDevice, onConnect, onError}: IConnectProps): Promise<void> => {
        await this.connectDevice({
            isNewDevice,
            services: [BLE_UUID_TYPES.RRP_SERVICE],
            onConnect: async () => {
                try {
                    this.options.onFinishDeviceSelect();
                    await this.onConnect();
                    onConnect();
                } catch (e: any) {
                    onError(e);
                }
            },
            onError,
        });
    };

    getFwuServices = async (): Promise<void> => {
        this.fwuDataCharacteristic = await this.getPrimaryServiceCharacteristic(BLE_UUID_TYPES.FW_UPGRADE_DATA);
        this.fwuControlCharacteristic = await this.getPrimaryServiceCharacteristic(BLE_UUID_TYPES.FW_UPGRADE_CONTROL);
        this.fwuStatusCharacteristic = await this.getPrimaryServiceCharacteristic(BLE_UUID_TYPES.FW_UPGRADE_STATUS);
    };

    addScpCharacteristicListener = (handler: (value: DataView) => void): void =>
        this.addCharacteristicListener(this.scpCharacteristic, handler);

    addDeviceStatusCharacteristicListener = (handler: (value: DataView) => void): void =>
        this.addCharacteristicListener(this.deviceStatusCharacteristic, handler);

    addBatteryInformationCharacteristicListener = (handler: (value: DataView) => void): void =>
        this.addCharacteristicListener(this.batteryInformationCharacteristic, handler);

    addFwuStatusCharacteristicListener = (handler: (value: DataView) => void): void =>
        this.addCharacteristicListener(this.fwuStatusCharacteristic, handler);

    removeFwuStatusCharacteristicListener = (): void => this.removeCharacteristicListener(this.fwuStatusCharacteristic);

    writeValueToScpCharacteristic = (frame: string, throwError: boolean): Promise<void> =>
        this.writeFrameToCharacteristic(this.scpCharacteristic, frame, throwError);

    writeValueToFwuControlCharacteristic = (frame: string, throwError: boolean): Promise<void> =>
        this.writeFrameToCharacteristic(this.fwuControlCharacteristic, frame, throwError);

    writeValueToFwuDataCharacteristic = (frame: string, throwError: boolean): Promise<void> =>
        this.writeFrameToCharacteristic(this.fwuDataCharacteristic, frame, throwError);

    readFwuDataCharacteristic = (handler: (value: DataView) => void): Promise<void> =>
        this.readCharacteristic(this.fwuDataCharacteristic, handler);

    private onConnect = async (): Promise<void> => {
        await helpers.timeout(200);
        await this.getPrimaryService(BLE_UUID_TYPES.RRP_SERVICE);

        await helpers.timeout(200);
        this.scpCharacteristic = await this.getPrimaryServiceCharacteristic(BLE_UUID_TYPES.SCP_CONTROL_POINT);
        this.deviceStatusCharacteristic = await this.getPrimaryServiceCharacteristic(BLE_UUID_TYPES.DEVICE_STATUS_CHAR);
        this.batteryInformationCharacteristic = await this.getPrimaryServiceCharacteristic(
            BLE_UUID_TYPES.DEVICE_BATTERY_CHAR
        );

        this.options.onDeviceConnect();
    };

    private writeFrameToCharacteristic = async (
        characteristic: BluetoothRemoteGATTCharacteristic | undefined,
        frame: string,
        throwError: boolean
    ): Promise<void> => {
        const chunks = this.splitFrame(frame);

        for (let j = 0; j < chunks.length; j++) {
            const chunk = chunks[j];

            await this.writeValueToCharacteristic(characteristic, chunk, throwError);
        }
    };

    private splitFrame = (frame: string): string[] => {
        const result: string[] = [];

        this.splitChunkRecursive(frame, result);

        return result;
    };

    private splitChunkRecursive = (frame: string, result: string[]): void => {
        const CHUNK_COUNT_LENGTH = 2;
        const CHUNK_LENGTH = 40;
        const chunk = frame.substring(0, CHUNK_LENGTH);

        result.push(chunk);

        const chunkLengthHex = frame.substring(0, CHUNK_COUNT_LENGTH);
        const chunkLengthDec = converter.hex2dec(chunkLengthHex);

        if (+chunkLengthDec > 0 && frame.length > CHUNK_LENGTH) {
            const frameRest = frame.substring(CHUNK_LENGTH);

            this.splitChunkRecursive(frameRest, result);
        }
    };
}
