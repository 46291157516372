import appConfig from '../config/appConfig';
import * as cookieKeys from '../consts/app/cookieKeys';
import cookieService from '../services/cookie/cookieService';
import dataTimeUtils from '../utils/dateTimeUtils';
import storageService from './storage/storageService';
import userBrowserService from './user/userBrowserService';

const isQualtricsTrackingEnabled = () => {
    const configPermission = appConfig.getIsQualtricsTrackingEnabled();
    const advertisementPermission = isCookieAdvertisementPermission();

    return configPermission && advertisementPermission;
};

const getIncrementedCookieValueByKey = (cookie, key) => {
    if (cookie) {
        if (cookie[key]) {
            cookie[key]++;
        } else {
            cookie[key] = 1;
        }
    } else {
        cookie = {};
        cookie[key] = 1;
    }
    return cookie;
};

const getIncrementedValue = (value) => {
    if (value) {
        value++;
    } else {
        value = 1;
    }
    return value;
};

const getChangedFeatureFrequencyOfUseCookie = (cookieDetailsKey) => {
    const oldCountValue = cookieService.getCountFeatureFrequencyOfUseFromCookieStorage();
    const newCountValue = getIncrementedCookieValueByKey(oldCountValue, cookieDetailsKey);

    return newCountValue;
};

const isCookieAdvertisementPermission = () => {
    const ADVERTISEMENT_COOKIE_ACCESS_VALUE = 'opt-in';
    const advertisementCookieValue = cookieService.getDwAdvertisementFromCookieStorage();

    return advertisementCookieValue === ADVERTISEMENT_COOKIE_ACCESS_VALUE;
};

const setQualtricsCookie = (setterFunc) => {
    const permissionToUse = isQualtricsTrackingEnabled();

    if (permissionToUse) {
        setterFunc();
    }
};

const setFeatureFrequencyCookie = (key) =>
    setQualtricsCookie(() => {
        const updatedValue = getChangedFeatureFrequencyOfUseCookie(key);

        cookieService.setCountFeatureFrequencyOfUseToCookieStorage(updatedValue);
    });

const getEncodedNavigatorUserAgent = () => {
    const navigatorUA = userBrowserService.getUserAgent();
    const navigatorUAEncoded = encodeURI(navigatorUA);

    return navigatorUAEncoded;
};

const getNumberOfDays = (firstTimeDateRunApp) => {
    const todayDate = Date.now();
    const firstTimeDateRunAppParsed = new Date(firstTimeDateRunApp).getTime();
    const diffMs = todayDate - firstTimeDateRunAppParsed;
    const diffDays = dataTimeUtils.msToDays(diffMs);

    return diffDays;
};

const storeNumberOfDaysInApp = () => {
    setQualtricsCookie(() => {
        const firstAppRunDate = storageService.getFirstAppRunFromLocalStorage();

        if (firstAppRunDate) {
            const difference = getNumberOfDays(firstAppRunDate);

            cookieService.setFirstAppRunToCookieStorage(difference);
        } else {
            const todayDate = Date.now();

            storageService.setFirstAppRunToLocalStorage(todayDate);
        }
    });
};

const storeFrequencyUserAppRuns = () => {
    setQualtricsCookie(() => {
        const userFrequencyValue = cookieService.getCountAppRunsFromCookieStorage();
        const updatedValue = getIncrementedValue(userFrequencyValue);

        cookieService.setCountAppRunsToCookieStorage(updatedValue);
    });
};

const storeBrowserUserAgentCookie = () => {
    setQualtricsCookie(() => {
        const navigatorEncodedUserAgent = getEncodedNavigatorUserAgent();

        cookieService.setUserBrowserToCookieStorage(navigatorEncodedUserAgent);
    });
};

const incrementDeviceCleaningCookie = () => {
    setFeatureFrequencyCookie(cookieKeys.FEATURE_FREQUENCY_DETAILS.DEVICE_CLEANING);
};

const incrementChangeVibrationsCookie = () => {
    setFeatureFrequencyCookie(cookieKeys.FEATURE_FREQUENCY_DETAILS.CHANGE_VIBRATIONS);
};

const incrementLocateYourDeviceCookie = () => {
    setFeatureFrequencyCookie(cookieKeys.FEATURE_FREQUENCY_DETAILS.LOCATE_YOUR_DEVICE);
};

const incrementRenameYourDeviceCookie = () => {
    setFeatureFrequencyCookie(cookieKeys.FEATURE_FREQUENCY_DETAILS.RENAME_YOUR_DEVICE);
};

const incrementIlluminationModeCookie = () => {
    setFeatureFrequencyCookie(cookieKeys.FEATURE_FREQUENCY_DETAILS.CHANGE_ILLUMINATION_MODE);
};

const incrementNearestShopCookie = () => {
    setFeatureFrequencyCookie(cookieKeys.FEATURE_FREQUENCY_DETAILS.SHOP_ONLINE);
};

const incrementTipAndTutorialsCookie = () => {
    setFeatureFrequencyCookie(cookieKeys.FEATURE_FREQUENCY_DETAILS.TIPS_AND_TUTORIALS);
};

const incrementDeviceSoftwareUpdateCookie = () => {
    setFeatureFrequencyCookie(cookieKeys.FEATURE_FREQUENCY_DETAILS.DEVICE_SOFTWARE_UPDATE);
};

const incrementDeviceTroubleshootingCookie = () => {
    setFeatureFrequencyCookie(cookieKeys.FEATURE_FREQUENCY_DETAILS.DEVICE_TROUBLESHOOTING);
};

export default {
    storeNumberOfDaysInApp,
    storeFrequencyUserAppRuns,
    storeBrowserUserAgentCookie,
    incrementIlluminationModeCookie,
    incrementTipAndTutorialsCookie,
    incrementDeviceTroubleshootingCookie,
    incrementDeviceSoftwareUpdateCookie,
    incrementNearestShopCookie,
    incrementRenameYourDeviceCookie,
    incrementLocateYourDeviceCookie,
    incrementChangeVibrationsCookie,
    incrementDeviceCleaningCookie,
    isQualtricsTrackingEnabled,
};
