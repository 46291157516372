import {config} from '../config';

export default {
    getEnableCortezSupport: () => config.data.deviceSupport.enableCortezSupport,
    getEnableCosmosSupport: () => config.data.deviceSupport.enableCosmosSupport,
    getEnableDeltaSupport: () => config.data.deviceSupport.enableDeltaSupport,
    getEnableOrdinarySupport: () => config.data.deviceSupport.enableOrdinarySupport,
    getEnableP4Support: () => config.data.deviceSupport.enableP4Support,
    getEnableSoyuzSupport: () => config.data.deviceSupport.enableSoyuzSupport,
    getEnableVespucciSupport: () => config.data.deviceSupport.enableVespucciSupport,
    getEnableVoyagerSupport: () => config.data.deviceSupport.enableVoyagerSupport,
};
