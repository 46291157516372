import React from 'react';

import Icon from './Icon';

const IconBubbly = (props) => {
    return (
        <Icon width='24' height='24' viewBox='0 0 36 38' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g strokeWidth='1.5'>
                    <g transform='translate(-219 -429) translate(72 123) translate(127 288) translate(20 18.75)'>
                        <ellipse cx='13.154' cy='6.615' rx='5.481' ry='5.865' />
                        <ellipse cx='27.692' cy='14.7' rx='7.558' ry='8.07' />
                        <ellipse cx='10.385' cy='25.725' rx='9.635' ry='10.275' />
                    </g>
                </g>
            </g>
        </Icon>
    );
};

export default IconBubbly;
