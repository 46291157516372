import React from 'react';

import Icon from './Icon';

const IconStaccato = (props) => {
    return (
        <Icon width='21' height='23' viewBox='0 0 36 38' {...props}>
            <g fill='none' fillRule='evenodd' strokeLinecap='round'>
                <g strokeWidth='1.5'>
                    <g>
                        <path
                            d='M19.344 7.759c3.084-2.137 5.646-2.137 7.688 0 2.041 2.137 4.583 1.823 7.624-.941'
                            transform='translate(-220 -595) translate(72 123) translate(127 452) translate(22 21) translate(0 .063) rotate(-180 14.75 11.484) rotate(90 27 7.656)'
                        />
                        <path
                            d='M-6.156 7.759c3.084-2.137 5.646-2.137 7.688 0 2.041 2.137 4.583 1.823 7.624-.941'
                            transform='translate(-220 -595) translate(72 123) translate(127 452) translate(22 21) translate(0 .063) rotate(-180 14.75 11.484) matrix(0 1 1 0 -6.156 6.156)'
                        />
                    </g>
                    <g>
                        <path
                            d='M.75 0L.75 32.156M8.25 0L8.25 32.156'
                            transform='translate(-220 -595) translate(72 123) translate(127 452) translate(22 21) translate(0 .063) translate(10.75)'
                        />
                    </g>
                </g>
            </g>
        </Icon>
    );
};

export default IconStaccato;
