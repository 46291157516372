import * as logMessageTypes from '../consts/app/logMessageTypes';
import iccMarketService from '../services/icc/iccMarketService';
import log from '../services/logger/log';
import server from '../services/server/server';
import appConfig from './appConfig';
import {config} from './config';

const initExternalConfig = async () => {
    try {
        const externalConfigUrl = appConfig.getExternalConfigLinksPath();

        if (externalConfigUrl) {
            const externalConfigLink = await iccMarketService.getMediaUrl({
                mediaNameList: externalConfigUrl,
            });

            const externalConfigPath = externalConfigLink[0]?.url;

            const receivedExternalConfig = await server.get(externalConfigPath);

            config.data = {...config.data, ...receivedExternalConfig.data};
        } else {
            log.info('externalConfigService: externalConfigPath is missing in market config');
        }
    } catch (e) {
        log.error(`initExternalConfig: error: ${e}`, logMessageTypes.ICC);
    }
};

export default {initExternalConfig};
