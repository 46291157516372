import cn from 'classnames';
import React from 'react';

import Icon from '../Icon';
import styles from './BackIcon.module.scss';

const BackIcon = (props) => {
    const {className} = props;
    const butonIconClassName = cn(className, styles.BackIcon);

    return (
        <Icon className={butonIconClassName} width='26' height='14' viewBox='0 0 26 14' {...props}>
            <g fill='none' fillRule='nonzero' stroke='#383839' strokeWidth='1.5'>
                <path
                    d='M-2.474 8.589L2.845 3.363 8.164 8.589'
                    transform='translate(-27 -164) translate(28 165) matrix(0 1 1 0 -3.13 3.13)'
                />
                <path d='M1.031 6.079L23.438 6.079' transform='translate(-27 -164) translate(28 165)' />
            </g>
        </Icon>
    );
};

export default BackIcon;
