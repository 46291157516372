import * as cookieKeys from '../../consts/app/cookieKeys';
import cookieStorageClient from './cookieClient';

const MAX_DAYS_AGE_COOKIES = 3650;

const setFirstAppRunToCookieStorage = (data) =>
    cookieStorageClient.setItem(cookieKeys.NUMBER_OF_DAYS, data, {
        expires: MAX_DAYS_AGE_COOKIES,
    });
const getFirstAppRunFromCookieStorage = () => cookieStorageClient.getItem(cookieKeys.NUMBER_OF_DAYS);

const getCountAppRunsFromCookieStorage = () => cookieStorageClient.getItem(cookieKeys.FREQUENCY_OF_USE);
const setCountAppRunsToCookieStorage = (data) =>
    cookieStorageClient.setItem(cookieKeys.FREQUENCY_OF_USE, data, {expires: MAX_DAYS_AGE_COOKIES});

const getCountFeatureFrequencyOfUseFromCookieStorage = () =>
    cookieStorageClient.getObjItem(cookieKeys.FEATURE_FREQUENCY_OF_USE);
const setCountFeatureFrequencyOfUseToCookieStorage = (data) =>
    cookieStorageClient.setObjItem(cookieKeys.FEATURE_FREQUENCY_OF_USE, data, {
        expires: MAX_DAYS_AGE_COOKIES,
    });

const getUserBrowserFromCookieStorage = () => cookieStorageClient.getItem(cookieKeys.USER_BROWSER);
const setUserBrowserToCookieStorage = (data) =>
    cookieStorageClient.setItem(cookieKeys.USER_BROWSER, data, {expires: MAX_DAYS_AGE_COOKIES});

const getDwAdvertisementFromCookieStorage = () => cookieStorageClient.getItem(cookieKeys.DW_ADVERTISEMENT_COOKIE);

export default {
    getDwAdvertisementFromCookieStorage,
    getUserBrowserFromCookieStorage,
    setUserBrowserToCookieStorage,
    getCountFeatureFrequencyOfUseFromCookieStorage,
    setCountFeatureFrequencyOfUseToCookieStorage,
    setFirstAppRunToCookieStorage,
    getFirstAppRunFromCookieStorage,
    getCountAppRunsFromCookieStorage,
    setCountAppRunsToCookieStorage,
};
