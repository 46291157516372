const getConfigClientModule = () => {
    let client;

    if (process.env.REACT_APP_ENV === 'dev') {
        client = require('./configClient/configClientDev');
    }
    if (process.env.REACT_APP_ENV === 'stg') {
        client = require('./configClient/configClientStg');
    }
    if (process.env.REACT_APP_ENV === 'qa') {
        client = require('./configClient/configClientQA');
    }
    if (process.env.REACT_APP_ENV === 'qa-preprod') {
        client = require('./configClient/configClientQAPreprod');
    }
    if (process.env.REACT_APP_ENV === 'preprod') {
        client = require('./configClient/configClientPreprod');
    }
    if (process.env.REACT_APP_ENV === 'prod') {
        client = require('./configClient/configClientProd');
    }

    return client.default;
};

const getConfigData = (market) => {
    const ConfigClient = getConfigClientModule();
    const configClient = new ConfigClient();
    const config = configClient.getConfig(market);

    return config;
};

export default {
    getConfigData,
};
