import React from 'react';

import Icon from './Icon';

const IconDrop = (props) => {
    return (
        <Icon width='34' height='45' viewBox='0 0 34 45' {...props}>
            <path
                fill='none'
                fillRule='evenodd'
                strokeWidth='2'
                d='M17 44c8.837 0 16-7.036 16-15.714 0-5.496-4.812-14.279-14.436-26.348a2 2 0 0 0-3.128 0C5.812 14.008 1 22.79 1 28.286 1 36.964 8.163 44 17 44z'
            />
        </Icon>
    );
};

export default IconDrop;
