import React from 'react';

import * as localizationKeys from '../../../../consts/localization/localizationKeys';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import appRouterService from '../../../../services/route/appRouterService';
import PreferenceCenterControl from './PreferenceCenterControl';

const PreferenceCenterPage = () => {
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[localizationKeys.PREFERENCE_CENTER_PAGE_TITLE]);

    return (
        <PreferenceCenterControl
            backButtonAriaLabel={localizedStrings[localizationKeys.PREFERENCE_CENTER_PAGE_BTN_RETURNS_USER_ARIA_LABEL]}
            onApproved={appRouterService.forwardToMyDevicesPage}
            backButtonClick={appRouterService.forwardToTNCDataCollectionPage}
        />
    );
};

export default PreferenceCenterPage;
