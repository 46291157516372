import {useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import useDidUpdate from '../../../hooks/useDidUpdate';
import ErrorHandlerService from '../../../services/errorHandlerService';
import PairingHandlerService from '../../../services/pairingHandlerService';
import {setIsPairingInProgress} from '../../../state/ducks/iotDevice/actions';
import {makeSelectIsPairingInProgress} from '../../../state/selectors/iotDevice';

const SCP_CLOUD_PAIRING_TIMEOUT = 30;

const ScpCloudPairingChecker = () => {
    const isPairingInProgress = useSelector(makeSelectIsPairingInProgress());
    const dispatch = useDispatch();
    const activeProcessTimeout = useRef(false);

    useDidUpdate(() => {
        clearTimeout(activeProcessTimeout.current);

        if (isPairingInProgress) {
            new PairingHandlerService().pairingIsStarted();

            activeProcessTimeout.current = setTimeout(async () => {
                dispatch(setIsPairingInProgress(false));
            }, SCP_CLOUD_PAIRING_TIMEOUT * 1000);
        } else {
            new ErrorHandlerService().checkScpCloudError();

            new PairingHandlerService().pairingIsStopped();
        }
    }, [isPairingInProgress]);

    return null;
};

export default ScpCloudPairingChecker;
