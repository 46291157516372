import PCancelable from 'p-cancelable';

import BLE_UUID_TYPES from '../../enums/ble/bleUuidTypes';
import converter from '../../utils/converter';
import ExponentialBackOff from '../../utils/exponentialBackOff';
import helpers from '../../utils/helpers';
import errorService from '../errors/errorService';
import GetPermittedBuetoothDeviceError from '../errors/getPermittedBuetoothDeviceError';
import ReconectPromiseCancelError from '../errors/reconectPromiseCancelError';
import RequestDeviceError from '../errors/requestDeviceError';
import log from '../logger/log';
import userDeviceService from '../user/userDeviceService';
import {IConnectProps, IIqosBleClientOptions, TOnError} from './bleClientTypes';

interface IListener {
    characteristic: BluetoothRemoteGATTCharacteristic;
    handler: (e: any) => void;
}

interface IManagedListeners {
    [index: string]: IListener;
}

interface IConnectDeviceProps extends IConnectProps {
    services: BluetoothServiceUUID[];
}

interface IGetDeviceProps extends Omit<IConnectDeviceProps, 'onConnect'> {
    onDeviceConnect: TOnConnect;
}

type TOnConnect = (device: BluetoothDevice) => void;

interface IOptions extends Omit<IIqosBleClientOptions, 'onDeviceReconnectSuccess'> {
    onReconnectSuccess: () => Promise<void>;
}

export default class BLEClient {
    private isConnected: boolean;
    private getPermittedDeviceTimeout?: ReturnType<typeof setTimeout>;
    private removeAdverismentReceivedListener?: () => void;
    private connectPromise?: PCancelable<unknown>;
    private reconnectPromise?: PCancelable<unknown>;
    private isDisconnectedByUser?: boolean;
    private device?: BluetoothDevice;
    private server?: BluetoothRemoteGATTServer;
    private primaryService?: BluetoothRemoteGATTService;
    private managedListeners: IManagedListeners = {};
    protected options: IOptions;

    constructor(options: IOptions) {
        this.options = options;
        this.isConnected = false; //device is connected after getPrimaryService
    }

    isDeviceConnected = (): boolean => this.isConnected;

    getPermittedBluetoothDevices = async (
        onConnect: TOnConnect,
        onError: TOnError
    ): Promise<BluetoothDevice[] | null> => {
        const GET_PERMITTED_DEVICE_TIMEOUT_MS = 10 * 1000;

        try {
            const devices = await navigator.bluetooth.getDevices();

            if (devices.length) {
                log.debug(`BLEClient: getPermittedBluetoothDevices, there are ${devices.length} permitted device(s)`);

                this.removeAdverismentReceivedListener = () => {
                    for (const device of devices) {
                        // @ts-expect-error wrong types
                        device.onadvertisementreceived = null;
                    }
                };

                this.getPermittedDeviceTimeout = setTimeout(() => {
                    this.runRemoveAdverismentReceivedListener();
                    onError(new GetPermittedBuetoothDeviceError());
                }, GET_PERMITTED_DEVICE_TIMEOUT_MS);

                for (const device of devices) {
                    const abortController = new AbortController();

                    // @ts-expect-error wrong watchAdvertisements types
                    await device.watchAdvertisements({signal: abortController.signal});

                    device.onadvertisementreceived = async (evt: any) => {
                        log.debug(
                            `BLEClient: getPermittedBluetoothDevices, advertisementreceived, device name: ${evt.target?.name}`
                        );

                        this.clearGetPermittedDevices();
                        abortController.abort();

                        await (evt.device as BluetoothDevice).gatt?.connect();
                        onConnect(evt.device);
                    };
                }
            }

            return devices;
        } catch (e) {
            this.clearGetPermittedDevices();

            log.info(`BLEClient: getPermittedBluetoothDevices error: ${errorService.getErrorMessage(e)}`);
            return null;
        }
    };

    unmount = (): void => {
        this.clearGetPermittedDevices();
    };

    requestDevice = async (
        services: BluetoothServiceUUID[],
        onConnect: TOnConnect,
        onError: TOnError
    ): Promise<void> => {
        log.info('BLEClient: requesting Bluetooth Device');

        try {
            const device = await navigator.bluetooth.requestDevice({
                acceptAllDevices: false,
                filters: [{services}],
            });

            onConnect(device);
        } catch (e) {
            onError(new RequestDeviceError(e));
        }
    };

    connectDevice = async ({isNewDevice, services, onConnect, onError}: IConnectDeviceProps): Promise<void> => {
        try {
            this.isDisconnectedByUser = false;

            await this.getDevice({
                isNewDevice,
                services,
                onDeviceConnect: async (device: BluetoothDevice) => {
                    this.device = device;

                    log.info(`BLEClient: device with id: ${device.id} is connected`);

                    this.options.onDeviceSelect();

                    device.addEventListener('gattserverdisconnected', this.onDisconnected);

                    device.addEventListener('gattserverforcedisconnected', this.onGattServerForceDisconnected);

                    let server;

                    try {
                        server = await this.gattConnect(device);
                        this.server = server;

                        onConnect();
                    } catch (e) {
                        log.info(`BLEClient: gattConnect: ${errorService.getErrorMessage(e)}`);
                    }
                },
                onError,
            });
        } catch (e) {
            log.info(`BLEClient: requestDeviceService failed, error: ${errorService.getErrorMessage(e)}`);

            throw e;
        }
    };

    getDevice = async ({isNewDevice, services, onDeviceConnect, onError}: IGetDeviceProps): Promise<void> => {
        let isRequestDevice = true;

        if (!isNewDevice) {
            const permittedDevices = await this.getPermittedBluetoothDevices(onDeviceConnect, onError);

            isRequestDevice = !permittedDevices?.length;
        }

        if (isRequestDevice) {
            await this.requestDevice(services, onDeviceConnect, onError);
        }
    };

    gattConnect = (device: BluetoothDevice): Promise<BluetoothRemoteGATTServer> => {
        const connectPromise = new PCancelable((resolve, reject, onCancel) => {
            log.info('BLEClient: connecting to GATT Server');
            device.gatt?.connect().then((server) => resolve(server));

            onCancel(() => {
                this.removeOnDisconnectedListener(device);
                reject('connectPromise rejected');
            });
        });

        this.connectPromise = connectPromise;

        return connectPromise as Promise<BluetoothRemoteGATTServer>;
    };

    cancelConnect = (): void => {
        try {
            if (this.connectPromise) {
                this.connectPromise.cancel();
                this.connectPromise = undefined;
                this.device = undefined;
                this.server = undefined;
            }
        } catch (e) {
            log.debug(`BLEClient: cancel connect promise failed error: ${e}`);
        }
    };

    getPrimaryService = async (serviceUuid: BLE_UUID_TYPES): Promise<void> => {
        try {
            log.debug('BLEClient: getting Primary Service');

            this.primaryService = await this.server!.getPrimaryService(serviceUuid);

            log.debug('BLEClient: getting Primary Service success');
            this.isConnected = true;
        } catch (e) {
            log.info(`BLEClient: getPrimaryService failed, error: ${errorService.getErrorMessage(e)}`);

            throw e;
        }
    };

    getPrimaryServiceCharacteristic = async (
        characteristicUuid: BLE_UUID_TYPES
    ): Promise<BluetoothRemoteGATTCharacteristic | undefined> => {
        log.debug('BLEClient: getting characteristics');
        return this.primaryService?.getCharacteristic(characteristicUuid);
    };

    addCharacteristicListener = (
        characteristic: BluetoothRemoteGATTCharacteristic | undefined,
        handler: (value: any) => void
    ): void => {
        try {
            if (characteristic) {
                const {uuid} = characteristic;

                this.removeEventListener(uuid);

                this.managedListeners[uuid] = {
                    characteristic,
                    handler: (event: any) => {
                        handler(event.target.value);
                    },
                };

                characteristic.addEventListener('characteristicvaluechanged', this.managedListeners[uuid].handler);
                characteristic.startNotifications().catch((e) => {
                    log.info(`BLEClient: addCharacteristicListener error: ${errorService.getErrorMessage(e)}`);
                });
            } else {
                log.info(`BLEClient: addCharacteristicListener error: characteristic is undefined`);
            }
        } catch (e) {
            log.info(`BLEClient: addCharacteristicListener error: ${errorService.getErrorMessage(e)}`);
        }
    };

    writeValueToCharacteristic = async (
        characteristic: BluetoothRemoteGATTCharacteristic | undefined,
        frame: string,
        throwError: boolean
    ): Promise<void> => {
        if (!this.isDeviceConnected()) return;

        try {
            const frameDecoded = converter.hex2bin(frame);
            const value = frameDecoded.buffer;

            return await characteristic!.writeValue(value);
        } catch (e) {
            log.info(
                `BLEClient: frame: ${frame}, writeValueToCharacteristic error: ${errorService.getErrorMessage(e)}`
            );

            if (throwError) {
                throw e;
            }
        }
    };

    readCharacteristic = async (
        characteristic: BluetoothRemoteGATTCharacteristic | undefined,
        handler: (value: DataView) => void
    ): Promise<void> => {
        if (!this.isDeviceConnected()) return;

        try {
            log.debug('BLEClient: try to read characteristic');
            const value = await characteristic!.readValue();

            handler(value);
        } catch (e) {
            log.info(`BLEClient: read characteristic error: ${errorService.getErrorMessage(e)}`);
            throw e;
        }
    };

    removeCharacteristicListener = (characteristic: BluetoothRemoteGATTCharacteristic | undefined): void => {
        if (characteristic) {
            const {uuid} = characteristic;

            this.removeEventListener(uuid);
        }
    };

    disconnect = (): void => {
        this.isDisconnectedByUser = true;
        this.removeAllEventListeners();
        this.removeOnDisconnectedListener(this.device);
        this.disconnectByDevice(this.device);
        this.cancelConnect(); //clear connect promise
        this.cancelReconnect(); //clear reconnect promise if device is already disconnected
        this.clearGetPermittedDevices(); //clear watchAdvertisements
    };

    reconnect = async (): Promise<void> => {
        const fail = (e: Error) => {
            const isReconnectCanceled = e instanceof ReconectPromiseCancelError;

            if (!isReconnectCanceled) {
                log.debug('BLEClient: device reconnection failed');

                helpers.runFunction(this.options.onReconnectFail);
            }
        };

        try {
            const device = this.device;

            if (device) {
                const toTry = <BluetoothRemoteGATTServer>(
                    attemptNumber: number
                ): Promise<BluetoothRemoteGATTServer> => {
                    const reconnectPromise = new PCancelable((resolve, reject, onCancel) => {
                        device.gatt
                            ?.connect()
                            .then((server) => resolve(server))
                            .catch((e) => reject(e));

                        onCancel(() => {
                            log.debug('BLEClient: device reconnection canceled');
                            reject(new ReconectPromiseCancelError());
                        });
                    });

                    this.reconnectPromise = reconnectPromise;

                    log.debug(`BLEClient: try to reconnect device, attempt: ${attemptNumber}`);

                    return reconnectPromise as Promise<BluetoothRemoteGATTServer>;
                };

                const success = (server: BluetoothRemoteGATTServer) => {
                    log.info(`BLEClient: device with id: ${device.id} is reconnected`);
                    this.reconnectPromise = undefined;
                    this.device = device;
                    this.server = server;

                    this.options.onReconnectSuccess();
                };

                //300 ~= 10 min
                const retriesCount = userDeviceService.isMobileAndroidDevice() ? 300 : 3;

                const exponentialBackOff = new ExponentialBackOff({
                    max: retriesCount,
                    delay: () => 2000,
                    toTry,
                    success,
                    fail,
                });

                exponentialBackOff.run();
            }
        } catch (e: any) {
            fail(e);
        }
    };

    cancelReconnect = (): boolean => {
        try {
            if (this.reconnectPromise) {
                this.reconnectPromise.cancel();
                this.reconnectPromise = undefined;
                this.device = undefined;
                this.server = undefined;

                return true;
            }
        } catch (e) {
            log.info(`BLEClient: cancel reconnect promise failed error: ${errorService.getErrorMessage(e)}`);
        }

        return false;
    };

    private runRemoveAdverismentReceivedListener = () => {
        helpers.runFunction(this.removeAdverismentReceivedListener);
        this.removeAdverismentReceivedListener = undefined;
    };

    private clearGetPermittedDevices = (): void => {
        this.runRemoveAdverismentReceivedListener();
        clearTimeout(this.getPermittedDeviceTimeout!);
    };

    private onGattServerForceDisconnected = () => {
        log.info(`BLEClient: "gattserverforcedisconnected" is fired`);

        this.options.onForcedDisconnect();
        this.onDisconnected();
        // IA - call onDisconnected directly, because onDisconnect
        // is not called after gattserverforcedisconnected event
    };

    private onDisconnected = () => {
        const {isDisconnectedByUser} = this;

        log.info('BLEClient: device disconnected');

        this.isConnected = false;
        this.options.onDisconnected(isDisconnectedByUser);
        this.removeAllEventListeners();

        if (isDisconnectedByUser) {
            this.removeOnDisconnectedListener(this.device);
            this.device = undefined;
        } else {
            this.reconnect();
        }
    };

    private removeOnDisconnectedListener = (device?: BluetoothDevice): void => {
        device?.removeEventListener('gattserverdisconnected', this.onDisconnected);
        device?.removeEventListener('gattserverforcedisconnected', this.onGattServerForceDisconnected);
    };

    private removeAllEventListeners = () => {
        if (this.managedListeners) {
            Object.keys(this.managedListeners).forEach(this.removeEventListener);
        }

        this.managedListeners = {};
    };

    private removeEventListener = (uuid: string): void => {
        try {
            const listener = this.managedListeners[uuid];

            if (listener) {
                const {characteristic, handler} = listener;

                characteristic.removeEventListener('characteristicvaluechanged', handler);
                delete this.managedListeners[uuid];
            }
        } catch (e) {
            log.info(`BLEClient: removeEventListener error: ${errorService.getErrorMessage(e)}`);
        }
    };

    private disconnectByDevice = (device?: BluetoothDevice): void => {
        if (device) {
            log.info(`BLEClient: disconnect device with id: ${device.id}`);
            device.gatt?.disconnect();
        }
    };
}
