import cn from 'classnames';
import React, {FC} from 'react';

import Button from '../Button';
import {IButton} from '../ButtonTypes';
import styles from './ButtonBorder.module.scss';

const ButtonBorder: FC<IButton> = (props) => {
    const {arrowClassName, className} = props;

    return <Button {...props} arrowClassName={arrowClassName} className={cn(styles.ButtonBorder, className)} />;
};

export default ButtonBorder;
