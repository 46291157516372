import cn from 'classnames';
import React, {FC, useState} from 'react';
import {Dispatch} from 'redux';

import {ErrorIcon, IconSuccess, WarningIcon} from '../../../components/Icons';
import PopupCloseButton from '../../../components/Popup/components/PopupCloseButton/PopupCloseButton';
import standaloneLocalization from '../../../config/standaloneConfig/standaloneLocalization';
import * as notificationTypes from '../../../consts/app/notificationTypes';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import NotificationModalTypes from '../../../enums/app/notificationModalTypes';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {removeNotification} from '../../../state/ducks/global';
import ReconnectNotification from './components/ReconnectNotification/ReconnectNotification';
import RenameNotification from './components/RenameNotification/RenameNotification';
import styles from './Notification.module.scss';
import {INotification} from './NotificationTypes';

interface IProps {
    dispatch: Dispatch<any>;
    notification: INotification;
    key: number;
}

const Notification: FC<IProps> = (props) => {
    const {dispatch, notification} = props;
    const {notificationId, text, type, testId, notificationType, autoFocus} = notification;
    const [isActive, setIsActive] = useState(false);
    const textClassName = cn(styles.Text, 'ica--txt-regular');
    const notificationClassName = cn(styles.NotificationContainer, {
        [styles.CloseAnimation]: isActive,
    });
    const localizedStrings = getLocalizedStrings();
    let icon;

    switch (type) {
        case notificationTypes.FAILED:
            icon = <ErrorIcon className={styles.FailMessageIcon} />;
            break;

        case notificationTypes.SUCCESS:
            icon = <IconSuccess className={styles.SuccessfulMessageIcon} />;
            break;

        case notificationTypes.INFO:
            icon = <WarningIcon className={styles.InfoMessageIcon} />;
            break;

        default:
            break;
    }

    const onNotificationClick = () => {
        if (!isActive) {
            setIsActive(true);

            setTimeout(() => {
                hideNotification();
            }, 500);
        }
    };

    const hideNotification = () => {
        dispatch(removeNotification(notificationId));
    };

    let customNotification;

    switch (notificationType) {
        case NotificationModalTypes.RECONNECT_DEVICE:
            customNotification = (
                <ReconnectNotification onClick={onNotificationClick} hideNotification={hideNotification} />
            );
            break;
        case NotificationModalTypes.RENAME_DEVICE:
            customNotification = <RenameNotification />;
            break;
    }

    return (
        <div className={notificationClassName} data-testid={testId}>
            {icon}
            <p className={textClassName} role={autoFocus ? '' : 'status'}>
                {customNotification ? customNotification : text}
            </p>
            <PopupCloseButton
                ariaLabel={
                    localizedStrings
                        ? localizedStrings[localizationKeys.CLOSE_NOTIFICATION_ARIA_LABEL]
                        : standaloneLocalization.CLOSE_NOTIFICATION_ARIA_LABEL
                }
                className={styles.IconCrossBlack}
                onClick={onNotificationClick}
            />
        </div>
    );
};

export default Notification;
