import mediaService from '../mediaService';

const getDeviceAssetsList = (assets) => {
    const mediaNames = assets.filter((mediaId) => mediaId);
    const mediaThumbnails = mediaNames.map((mediaId) => mediaService.getThumbnailName(mediaId));
    const result = [...mediaNames, ...mediaThumbnails];

    return result;
};

export default {
    getDeviceAssetsList,
};
