import cn from 'classnames';
import React, {FC} from 'react';

import Button from '../Button';
import {IButton} from '../ButtonTypes';
import styles from './FloatButton.module.scss';

interface IProps extends IButton {
    isLeft?: boolean;
}

const FloatButton: FC<IProps> = (props) => {
    const {className, isLeft, ...restProps} = props;
    const buttonClassName = cn(styles.FloatButton, className, {[styles.IsLeft]: isLeft});

    return <Button {...restProps} className={buttonClassName} />;
};

export default FloatButton;
