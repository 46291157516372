import deepmerge from 'deepmerge';
import uuid from 'uuid';

import arrayUtils from './arrayUtils';

type TGuid = () => string;
type TTimeout = (ms: number) => Promise<void>;
type TRunFunction = (func: ((...args: any[]) => void) | null | undefined, arg?: any | any[]) => void;

const guid: TGuid = () => uuid.v4();

const timeout: TTimeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const mergeDeep = deepmerge;

const runFunction: TRunFunction = (func, arg) => {
    const args = arrayUtils.toArray(arg);

    if (typeof func === 'function') {
        args ? func(...args) : func();
    }
};

export default {
    guid,
    mergeDeep,
    runFunction,
    timeout,
};
