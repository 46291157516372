import React from 'react';

import Icon from './Icon';

const IconPlus = (props) => {
    return (
        <Icon width='15' height='15' viewBox='0 0 10 16' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g stroke='#383839' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2.5'>
                    <path
                        d='M4 0L4 8'
                        transform='translate(-199 -1542) translate(199 1542) translate(1) translate(0 4)'
                    />
                    <path
                        d='M4 0L4 8'
                        transform='translate(-199 -1542) translate(199 1542) translate(1) translate(0 4) rotate(90 4 4)'
                    />
                </g>
            </g>
        </Icon>
    );
};

export default IconPlus;
