import defaultImage_DEV_TYPE_P1V30_CHARGER from '../../../../assets/images/reconnect-guide/DEV_TYPE_P1V30_CHARGER.jpg';
import defaultImage_DEV_TYPE_P1V30M from '../../../../assets/images/reconnect-guide/DEV_TYPE_P1V30M.jpg';
import defaultImage_DEV_TYPE_P1V31_CHARGER from '../../../../assets/images/reconnect-guide/DEV_TYPE_P1V31_CHARGER.jpg';
import defaultImage_DEV_TYPE_P1V40C from '../../../../assets/images/reconnect-guide/DEV_TYPE_P1V40C_CHARGER.jpg';
import defaultImage_DEV_TYPE_P1V40D from '../../../../assets/images/reconnect-guide/DEV_TYPE_P1V40D_CONTROL.jpg';
import defaultImage_DEV_TYPE_P1V40V from '../../../../assets/images/reconnect-guide/DEV_TYPE_P1V40V_CHARGER.jpg';
import defaultImage_DEV_TYPE_P4_M3_G2 from '../../../../assets/images/reconnect-guide/DEV_TYPE_P4_M3_G2.jpg';
import defaultImage_DEV_TYPE_V24P_CHARGER from '../../../../assets/images/reconnect-guide/DEV_TYPE_V24P_CHARGER.jpg';
import appConfig from '../../../config/appConfig';
import DEVICE_TYPES from '../../../enums/device/deviceTypes';
import stringUtils from '../../../utils/stringUtils';

const defaultImages = {
    [DEVICE_TYPES.DEV_TYPE_P1V30_CHARGER]: defaultImage_DEV_TYPE_P1V30_CHARGER,
    [DEVICE_TYPES.DEV_TYPE_P1V30M]: defaultImage_DEV_TYPE_P1V30M,
    [DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER]: defaultImage_DEV_TYPE_P1V31_CHARGER,
    [DEVICE_TYPES.DEV_TYPE_P4_M3_G2]: defaultImage_DEV_TYPE_P4_M3_G2,
    [DEVICE_TYPES.DEV_TYPE_V24P_CHARGER]: defaultImage_DEV_TYPE_V24P_CHARGER,
    [DEVICE_TYPES.DEV_TYPE_P1V40V_CHARGER]: defaultImage_DEV_TYPE_P1V40V,
    [DEVICE_TYPES.DEV_TYPE_P1V40C_CHARGER]: defaultImage_DEV_TYPE_P1V40C,
    [DEVICE_TYPES.DEV_TYPE_P1V40D_CONTROL]: defaultImage_DEV_TYPE_P1V40D,
};

const getImageData = (type) => {
    const assetsPath = appConfig.getReconnectGuideAssetsPath();
    const data = {
        defaultImage: defaultImages[type],
        mediaData: assetsPath ? stringUtils.formatString(assetsPath, type) : undefined,
    };

    return data;
};

export default {getImageData};
