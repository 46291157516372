import cn from 'classnames';
import React, {useEffect, useState} from 'react';

import {ButtonDark} from '../../../../components/Button';
import ConnectionTrackButton from '../../../../components/Button/ConnectionTrackButton/ConnectionTrackButton';
import Container from '../../../../components/Layout/Container/Container';
import * as localizationKeys from '../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import tncService from '../../../../services/tncService';
import uamService from '../../../../services/uam/uamService';
import {hideLoader, showLoader} from '../../../../state/ducks/global';
import {dispatch} from '../../../../state/store';
import elementsTesIds from '../../../../test/consts/elementsTesIds';
import DCExpansionPanel from './DCExpansionPanel/DCExpansionPanel';
import styles from './PreferenceCenter.module.scss';

const DEVICE_SECURITY_ERRORS_CODE = 'WEB_SECURITYERRORS';

const PreferenceCenter = (props) => {
    const {acceptedLegals, legals, onApproved, ariaLabel} = props;
    const acceptedLegalsWithSettings = acceptedLegals.filter((al) => al.settings != null);
    const acceptedFilteredSettingList = acceptedLegalsWithSettings
        .filter((legal) => !legal.isRevoked)
        .map(({code}) => code);
    const [acceptedSettingList, setAcceptedSettingList] = useState(acceptedFilteredSettingList);
    const legalsSettingsNotNull = [...legals.filter((l) => l.settings != null), ...acceptedLegalsWithSettings];

    useEffect(() => {
        setAcceptedSettingList(acceptedFilteredSettingList);
    }, [acceptedLegals]);

    const onSettingLegalChange = (code, isAccepted) => {
        if (isAccepted) {
            setAcceptedSettingList([...acceptedSettingList, code]);
        } else {
            const isCodeInList = acceptedSettingList.indexOf(code) !== -1;

            if (isCodeInList) {
                const filteredList = acceptedSettingList.filter((el) => el !== code);

                setAcceptedSettingList(filteredList);
            }
        }
    };

    const onSaveButtonClick = async () => {
        dispatch(showLoader());

        await tncService.setPreferenceCenterSettings(legals, legalsSettingsNotNull, acceptedSettingList, onApproved);
        await uamService.checkDataCollectionFlow();

        dispatch(hideLoader());
    };
    const localizedStrings = getLocalizedStrings();

    return (
        <section className={styles.Wrapper}>
            <Container className={styles.Container}>
                <section className={cn(styles.PaddingSection, 'padding--content')}>
                    <h1 className={styles.Title}>{localizedStrings[localizationKeys.PREFERENCE_CENTER_TITLE_TEXT]}</h1>
                    <p
                        className={cn(styles.Description, 'ica--txt-regular')}
                        dangerouslySetInnerHTML={{
                            __html: localizedStrings[localizationKeys.PREFERENCE_CENTER_DESCRIPTION_TEXT],
                        }}
                    />
                    <div className={styles.SettingList}>
                        {legalsSettingsNotNull.map((element) => {
                            const {code, title, text} = element;
                            const isAccepted = acceptedSettingList.includes(code);

                            return (
                                <div key={code}>
                                    <DCExpansionPanel
                                        index={code}
                                        title={title}
                                        innerText={text}
                                        onChange={onSettingLegalChange}
                                        isActive={isAccepted}
                                        testId={
                                            code === DEVICE_SECURITY_ERRORS_CODE
                                                ? elementsTesIds.PREF_CENTER_TOGGLE_1
                                                : null
                                        }
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <ConnectionTrackButton
                        ariaLabel={ariaLabel}
                        button={ButtonDark}
                        className={styles.ContinueBtn}
                        onClick={onSaveButtonClick}
                        showArrow
                        testId={elementsTesIds.PREF_CENTER_BTN_SAVE}
                    >
                        {localizedStrings[localizationKeys.PREFERENCE_CENTER_SAVE_AND_CONTINUE_BUTTON_TEXT]}
                    </ConnectionTrackButton>
                </section>
            </Container>
        </section>
    );
};

export default PreferenceCenter;
