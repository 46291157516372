export const AC_ABOUT_MY_DEVICE = 'About My Device';
export const AC_AUTO_CLEANING = 'device auto cleaning';
export const AC_DIAGNOSTIC = 'device diagnostic';
export const AC_FIND_DEVICE = 'device find my device';
export const AC_FW_UPDATE = 'device FW upgrade';
export const AC_LEGAL_AND_POLICY = 'Legal and Policy';
export const AC_LIGHT_MODE = 'device light mode';
export const AC_LOCK_MY_DEVICE = 'Lock My Device';
export const AC_PREFERENCE_CENTER = 'Preference Center';
export const AC_RENAME = 'device rename';
export const AC_RESET = 'device reset';
export const AC_RESPONSIVE_DRAW = 'device responsive draw';
export const AC_STORE_LOCATOR = 'Store Locator';
export const AC_TIPS_AND_TUTORIALS = 'Tips and Tutorials';
export const AC_UNLOCK_MY_DEVICE = 'Unlock My Device';
export const AC_UNPAIR_DEVICE = 'unpair device';
export const AC_VAPE_CLOUD_sIZE = 'device vape cloud size';
export const AC_VIBRATIONS = 'device vibrations';
export const AC_AUTOSTART = 'device autostart';
export const AC_SMART_GESTURES = 'device smart gestures';
export const AC_RESET_DEVICE = 'device reset';
