import {useEffect} from 'react';

import {hideModalAction} from '../state/ducks/modal';
import {makeSelectIotDeviceData} from '../state/selectors/iotDevice';
import {dispatch, getState} from '../state/store';

const useHideModalIfNoDevice = (modalType) => {
    const iotDevice = makeSelectIotDeviceData()(getState());
    const {device} = iotDevice;

    useEffect(() => {
        //hide the modal if the device is not present in state
        if (!device) {
            dispatch(hideModalAction(modalType));
        }
    }, [device]);
};

export default useHideModalIfNoDevice;
