import appConfig from '../../config/appConfig';
import * as cleaningSupportCodes from '../../consts/iot/cleaningSupportCodes';
import * as activationStatusTypes from '../../consts/uam/activationStatusTypes';
import {hideLoader, showLoader} from '../../state/ducks/global';
import {setDeviceActivationStatus, updateIotDeviceData} from '../../state/ducks/iotDevice/actions';
import {setYapAssets} from '../../state/ducks/yapEncrypted';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../state/selectors/consumer';
import {makeSelectIotDeviceData} from '../../state/selectors/iotDevice';
import {
    makeSelectAsset,
    makeSelectAssetByAssetId,
    makeSelectIsYapDeviceInfoReceived,
} from '../../state/selectors/yapEncrypted';
import {dispatch, getState} from '../../state/store';
import backendService from '../app/backendService';
import cmClientService from '../communicationLayer/cmClientService';
import framesProcessingService from '../device/framesProcessingService';
import log from '../logger/log';
import {deviceInfoMapping} from '../mapping/iotMappings';
import productService from '../product/productService';
import yapService from '../yap/yapService';
import uamClientService from './uamClientService';
import uamWebSocketService from './uamWebSocketService';

const isDeviceActivatedInUam = async (deviceSerialNumber, assetId) => {
    let isDeviceActivated = false;
    const state = getState();

    let uamAsset;

    if (assetId) {
        uamAsset = makeSelectAssetByAssetId(assetId)(state);
    } else {
        uamAsset = makeSelectAsset(deviceSerialNumber)(state);
    }

    if (uamAsset?.assetId) {
        isDeviceActivated = uamAsset.yapActivated;
    } else {
        const {device} = makeSelectIotDeviceData()(state);
        const codentify = device?.codentifyOriginal;

        if (codentify) {
            const activated = await uamClientService.getActivationStatus(codentify);

            if (activated == null) throw new Error(`Could not retrieve activation status for ${codentify}`);

            isDeviceActivated = activated.activationStatus?.toLowerCase() === activationStatusTypes.UAM_ACTIVATED;
        }
    }

    if (deviceSerialNumber) {
        log.info(`uamService: isDeviceActivatedInUam: ${isDeviceActivated} for SN: ${deviceSerialNumber}.`);
    } else {
        log.info(`uamService: isDeviceActivatedInUam: ${isDeviceActivated} for assetId: ${assetId}.`);
    }

    dispatch(setDeviceActivationStatus(isDeviceActivated));
    return isDeviceActivated;
};

const syncAndSubscribeOnDevice = async (isHolder) => {
    const {deviceSerialNumber: chargerSerialNumber, holder, device} = makeSelectIotDeviceData()(getState());
    const {deviceSerialNumber: holderSerialNumber} = holder || {};
    const {isHolderConnected} = device || {};
    const deviceSerialNumber = isHolder ? holderSerialNumber : chargerSerialNumber;

    if (isHolder && !isHolderConnected) {
        log.info('uamService: syncAndSubscribeOnDevice is ignored for holder. Holder is not connected');
        return;
    }

    if (!deviceSerialNumber) throw new Error('Device is not initialized');

    const state = getState();
    const uamAsset = makeSelectAsset(deviceSerialNumber)(state);
    const assetId = uamAsset?.assetId;

    if (!assetId) throw new Error('Asset is not initialized');

    await uamWebSocketService.initUamWebSocket(assetId, undefined, isHolder);

    await cmClientService.initCmClient(assetId, isHolder);
};

const checkIsDeviceActivatedInUam = async () => {
    const isYAPFlowEnabled = yapService.isYapMode();

    if (!isYAPFlowEnabled) {
        dispatch(setDeviceActivationStatus(true));
        return;
    }

    const state = getState();
    const {deviceSerialNumber, device} = makeSelectIotDeviceData()(state);
    const {isP4} = device || {};

    if (!isP4) {
        dispatch(setDeviceActivationStatus(true));
        return;
    }

    let isDeviceActivated = false;
    const uamAsset = makeSelectAsset(deviceSerialNumber)(state);

    if (uamAsset?.assetId) {
        isDeviceActivated = uamAsset.yapActivated;
    }

    log.info(`uamService: checkIsDeviceActivatedInUam: ${isDeviceActivated}`);
    dispatch(setDeviceActivationStatus(isDeviceActivated));
};

const updateUamActivationStatus = async (yapActivationStatus) => {
    const state = getState();
    const {deviceSerialNumber} = makeSelectIotDeviceData()(state);
    const uamAsset = makeSelectAsset(deviceSerialNumber)(state);

    if (yapActivationStatus && uamAsset?.assetId) {
        uamAsset.yapActivated = yapActivationStatus.toLowerCase() === activationStatusTypes.UAM_ACTIVATED;
        dispatch(setYapAssets([uamAsset]));
        await checkIsDeviceActivatedInUam();
    }
};

const updateIotDevice = ({version, batteryCharge, codentify, isHolder}) => {
    const iotDevice = makeSelectIotDeviceData()(getState());

    if (!iotDevice?.device) return;

    const data = {
        [isHolder ? 'holder' : 'device']: {
            version,
            battery_charge: batteryCharge,
            codentify,
        },
    };

    dispatch(updateIotDeviceData(data));
};

const initSynchronizeFlow = async (params) => {
    const {isHolder, isLoaderVisible, isNeedBackendCheck = true} = params || {};
    const isDataCollectionEnabled = yapService.isDataCollectionEnabled();
    const isUamEnabled = backendService.isUamBackend() || isDataCollectionEnabled;

    if (!isUamEnabled && isNeedBackendCheck) return;

    const state = getState();
    const {device, deviceSerialNumber: chargerSerialNumber, holder} = makeSelectIotDeviceMergedWithIccProduct()(state);

    if (!device) return;

    const {status: deviceStatus} = device || {};
    const {status: holderStatus} = holder || {};
    const isCurrentDeviceRegistered = productService.getIsRegistered(deviceStatus);
    const isCurrentHolderRegistered = productService.getIsRegistered(holderStatus);

    if (!isCurrentDeviceRegistered || (!isCurrentHolderRegistered && isHolder)) return;

    const {deviceSerialNumber: holderSerialNumber} = holder || {};
    const serialNumber = isHolder ? holderSerialNumber : chargerSerialNumber;
    const uamAsset = makeSelectAsset(serialNumber)(state);

    if (!uamAsset) {
        if (isLoaderVisible) {
            dispatch(showLoader());
        }

        await uamClientService.getAssets(false, isHolder);

        if (isLoaderVisible) {
            dispatch(hideLoader());
        }
    }

    const isResetFramesDataExists = framesProcessingService.isResetFramesDataExists();

    if (!isResetFramesDataExists) {
        await uamClientService.synchronizeDevice(isHolder, serialNumber);
    }
};

const checkDataCollectionFlow = async () => {
    const isGamBackend = backendService.isGamBackend();

    if (isGamBackend) return;

    const isDataCollectionAvailable = yapService.isDataCollectionEnabled();
    const isDataCollectionEnabled = appConfig.getIsDataCollectionEnabled();
    const isUamBackend = backendService.isUamBackend();

    if (isDataCollectionEnabled || isUamBackend) {
        await uamClientService.cancelSynchronizeData();
        await uamClientService.getAssets();
        const iotDevice = makeSelectIotDeviceMergedWithIccProduct()(getState());
        const {device} = iotDevice || {};
        const {isHolderConnected} = device || {};

        if (isHolderConnected) {
            await uamClientService.getAssets(false, true);
        }
    }

    if (!isDataCollectionAvailable) {
        await yapService.disconnectIfNeeded();
    } else {
        const isYapDeviceInfoReceived = makeSelectIsYapDeviceInfoReceived()(getState());

        if (!isYapDeviceInfoReceived) {
            await initSynchronizeFlow();
        }
    }
};

const updateUamDeviceConfig = () => {
    const isUamBackend = backendService.isUamBackend();

    if (!isUamBackend) return;

    const iotDevice = makeSelectIotDeviceData()(getState());
    const {device_global_features_config: global_config, device_management_features_config: features} = iotDevice || {};

    if (!global_config || !features) return;

    //global config
    const isAutoStartEnabled = global_config.is_autostart && appConfig.getIsAutoStartEnabled();
    const isLedEnabled = global_config.is_led_intensity && appConfig.getIsLedEnabled();
    const isVibrationEnabled = global_config.is_vibration && appConfig.getIsVibrationEnabled();
    const isSelfCleaningEnabled = global_config.is_self_cleaning && appConfig.getIsSelfCleaningEnabled();
    const isDeviceAlarmEnabled = global_config.is_device_alarm && appConfig.getIsDeviceAlarmEnabled();
    const isGestureEnabled = global_config.is_status_gestures && appConfig.getIsGestureEnabled();
    const isParentProtectionEnabled = global_config.is_parent_protection && appConfig.getIsParentProtectionEnabled();

    //features config
    const isAutoStartFeature = features.autostart_feature && appConfig.getIsAutoStartEnabled();
    const isGestureFeature = features.status_gestures && appConfig.getIsGestureEnabled();
    const isConnectCareFeature = features.connect_care_mode && appConfig.getIsConnectCareEnabled();
    const isDeviceLedFeature = features.device_led_intensity_mode && appConfig.getIsLedEnabled();
    const isHolderLedFeature = features.holder_led_intensity_mode && appConfig.getIsLedEnabled();
    const isParentProtectionFeature =
        features.device_parent_protection_mode && appConfig.getIsParentProtectionEnabled();
    const isHolderChargerVibrationFeature =
        features.device_holder_charged_vibration && appConfig.getIsVibrationEnabled();
    const isStartVibrationFeature = features.start_charging_vibration && appConfig.getIsVibrationEnabled();
    const isExtendedVibrationFeature = features.extended_haptic_profile_vibration && appConfig.getIsVibrationEnabled();
    const isProgrammableVibrationFeature =
        features.programmable_vibrations.setting_supported && appConfig.getIsVibrationEnabled();
    const isCommonVibrationFeature =
        features.programmable_vibrations.common_vibration_switcher && appConfig.getIsVibrationEnabled();
    const isSelfCleaningFeature = features.device_self_cleaning && appConfig.getIsSelfCleaningEnabled();
    const isDeviceAlarmFeature = features.device_alarm && appConfig.getIsDeviceAlarmEnabled();
    const isVapeCloudSizeFeature = features.device_vape_cloud_size_mode && appConfig.getIsVapeCloudSizeEnabled();
    const isResponsiveDrawProfileFeature =
        features.device_responsive_draw_profile_mode && appConfig.getIsResponsiveDrawProfileEnabled();

    const data = {
        device_global_features_config: {
            is_holder_check: global_config.isHolderCheck,
            is_autostart: isAutoStartEnabled,
            is_led_intensity: isLedEnabled,
            is_vibration: isVibrationEnabled,
            is_self_cleaning: isSelfCleaningEnabled,
            is_device_alarm: isDeviceAlarmEnabled,
            is_status_gestures: isGestureEnabled,
            is_parent_protection: isParentProtectionEnabled,
        },
        device_management_features_config: {
            autostart_feature: isAutoStartFeature,
            status_gestures: isGestureFeature,
            connect_care_mode: isConnectCareFeature,
            device_led_intensity_mode: isDeviceLedFeature,
            holder_led_intensity_mode: isHolderLedFeature,
            device_parent_protection_mode: isParentProtectionFeature,
            device_holder_charged_vibration: isHolderChargerVibrationFeature,
            extended_haptic_profile_vibration: isExtendedVibrationFeature,
            start_charging_vibration: isStartVibrationFeature,
            programmable_vibrations: {
                setting_supported: isProgrammableVibrationFeature,
                common_vibration_switcher: isCommonVibrationFeature,
            },
            device_self_cleaning: isSelfCleaningFeature
                ? cleaningSupportCodes.FULL_AVAILABLE
                : cleaningSupportCodes.NOT_AVAILABLE,
            device_alarm: isDeviceAlarmFeature,
            device_alarm_duration: 30,
            device_vape_cloud_size_mode: isVapeCloudSizeFeature,
            device_responsive_draw_profile_mode: isResponsiveDrawProfileFeature,
        },
    };

    const mappedData = deviceInfoMapping(data);

    dispatch(updateIotDeviceData(mappedData));
};

const isUamMode = (isP4) => (isP4 ? yapService.isYapEncryptedMode() : false);

export default {
    checkDataCollectionFlow,
    checkIsDeviceActivatedInUam,
    initSynchronizeFlow,
    isDeviceActivatedInUam,
    isUamMode,
    syncAndSubscribeOnDevice,
    updateIotDevice,
    updateUamActivationStatus,
    updateUamDeviceConfig,
};
