import React from 'react';

import Icon from './Icon';

const ToolColorIcon = (props) => {
    const {children} = props;

    return (
        <Icon width='28' height='24' viewBox='0 0 28 24' {...props}>
            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g transform='translate(-182.000000, -10.000000)'>
                    <g transform='translate(81.000000, 7.000000)'>
                        <g transform='translate(100.000000, 3.000000)'>
                            <g transform='translate(6.000000, 4.000000)'>{children}</g>
                        </g>
                    </g>
                </g>
            </g>
        </Icon>
    );
};

export default ToolColorIcon;
