import React from 'react';

import appConfig from '../../config/appConfig';
import AccessibilityToolChecker from '../ServiceContainers/AccessibilityToolChecker/AccessibilityToolChecker';
import AccessibilityTool from './AccessibilityTool/AccessibilityTool';

const Accessibility = () => {
    const isAccessibilityToolEnabled = appConfig.getIsAccessibilityToolEnabled();
    const isAccessibilityToolVisible = isAccessibilityToolEnabled && process.env.REACT_APP_IS_BUILD_REF !== 'true';

    return (
        <>
            {isAccessibilityToolVisible && <AccessibilityTool />}
            {isAccessibilityToolEnabled && <AccessibilityToolChecker />}
        </>
    );
};

export default Accessibility;
