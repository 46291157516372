import arrayUtils from '../../utils/arrayUtils';

const REQUESTS_RESPONSES_COUNT = 5;

const getErrorDetails = ({allRequests, currentIndex, response}) => {
    const result = {};

    if (response) {
        result.lastRequestFrames = getLastRequestsList(allRequests, currentIndex);
        result.lastResponseFrames = getLastResponsesList(response);
    }

    return result;
};

const getLastRequestsList = (fullList, currentIndex) => {
    const startIndex = currentIndex > REQUESTS_RESPONSES_COUNT ? currentIndex - REQUESTS_RESPONSES_COUNT : 0;
    const slicedList = fullList.slice(startIndex, currentIndex + 1).map(({f}) => f);

    return arrayUtils.fillArray(REQUESTS_RESPONSES_COUNT, slicedList);
};

const getLastResponsesList = (response) => arrayUtils.fillArray(REQUESTS_RESPONSES_COUNT, [response]);

export default {
    getErrorDetails,
    getLastRequestsList,
    getLastResponsesList,
};
