import React from 'react';

import ErrorIcon from '../Icons/ErrorIcon';
import PopupHeaderIcon from './components/PopupHeaderIcon/PopupHeaderIcon';
import Popup from './Popup';
import styles from './Popup.module.scss';

const PopupError = (props) => {
    const {alt} = props;

    return (
        <Popup className={styles.PopupError} {...props}>
            <PopupHeaderIcon ariaLabel={alt} icon={ErrorIcon} />
            {props.children}
        </Popup>
    );
};

export default PopupError;
