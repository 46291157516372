import {useSelector} from 'react-redux';

import {makeSelectIsDeviceReady} from '../../state/selectors/iotDevice';
import useFWUInfoLoaded from '../fwu/useFWUInfoLoaded';
import usePublishCheckFirmware from '../fwu/usePublishCheckFirmware';

interface IProps {
    isCheckEnabled: boolean;
    forceCheck?: boolean;
    iotDevice?: any; //TODO add iotDevice type
}

const useCheckFirmware = (props: IProps): void => {
    const {isCheckEnabled, forceCheck, iotDevice} = props;
    const isDeviceReady = useSelector(makeSelectIsDeviceReady());
    const {device, device_engine, holder, holder_engine} = iotDevice || {};
    const isCheckAllowed = isCheckEnabled && isDeviceReady;
    const iotTargets = isCheckAllowed ? {device, device_engine, holder, holder_engine} : undefined;
    const {
        device: deviceFwu,
        device_engine: deviceEngineFwu,
        holder: holderFwu,
        holder_engine: holderEgineFwu,
    } = useFWUInfoLoaded({
        iotTargets,
        device,
    });

    usePublishCheckFirmware(deviceFwu, forceCheck);
    usePublishCheckFirmware(deviceEngineFwu, forceCheck);
    usePublishCheckFirmware(holderFwu, forceCheck);
    usePublishCheckFirmware(holderEgineFwu, forceCheck);
};

export default useCheckFirmware;
