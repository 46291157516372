//IA: move to redux if needed
let _isOnline = true;

const setIsOnline = (): void => void (_isOnline = true);
const setIsOffline = (): void => void (_isOnline = false);
const isOnline = (): boolean => _isOnline;
const isOffline = (): boolean => !_isOnline;

export default {
    setIsOnline,
    setIsOffline,
    isOnline,
    isOffline,
};
