import appConfig from '../../config/appConfig';
import {logoutClearData} from '../../state/ducks/global';
import {dispatch} from '../../state/store';
import stringUtils from '../../utils/stringUtils';
import iccService from '../icc/iccService';
import log from '../logger/log';
import marketLoginService from '../market/marketLoginService';
import appRouterService from '../route/appRouterService';
import routerService from '../routerService';
import authDataService from './authDataService';
import authDataStoreService from './authDataStoreService';

const login = async () => {
    const {spiceToken, username} = authDataService.getInitialCredentials();
    const isSpiceTokenNullEmpty = stringUtils.isNullOrEmpty(spiceToken);
    const accessProtectCheckResult = accessProtectCheck(spiceToken);

    if (accessProtectCheckResult) {
        if (!isSpiceTokenNullEmpty) {
            authDataStoreService.setSpiceToken(spiceToken, username);
        }

        await iccService.fetchIccToken({isAnonymous: isSpiceTokenNullEmpty});
    }
};

const standaloneLogin = async (standaloneCredentials) => {
    const {password, username} = standaloneCredentials;

    authDataStoreService.setSpiceToken(password, username);

    try {
        await iccService.fetchIccToken({isStandaloneLoginPage: true});
    } catch (e) {
        authDataStoreService.removeSpiceApiData();
        throw e;
    }
};

const logout = () => {
    localStorage.clear();
    dispatch(logoutClearData());

    appRouterService.forwardToSelectMarketPage();
};

const accessProtectCheck = (dataUserToken) => {
    if (process.env.REACT_APP_IS_BUILD_REF === 'true') {
        const isAccessProtected = appConfig.getIsAccessProtected();

        if (isAccessProtected && !dataUserToken) {
            showMarketLogin();

            return false;
        }
    }

    return true;
};

const showMarketLogin = () => {
    const loginUrl = appConfig.getLoginUrlFormatted();

    log.info('authService: showMarketLogin');

    if (loginUrl) {
        routerService.redirect(loginUrl);
    } else {
        marketLoginService.showLoginOverlay();
    }
};

export default {
    login,
    logout,
    showMarketLogin,
    standaloneLogin,
};
