export default {
    ABOUT_DEVICE_BTN_BACK: 'aboutMyDeviceBtnBack',
    ABOUT_DEVICE_BTN_CHARGER: 'aboutMyDeviceBtnChargerSection',
    ABOUT_DEVICE_BTN_FWU_CHECK_CHARGER: 'aboutMyDeviceBtnCheckChargerFirmwareUpdate',
    ABOUT_DEVICE_BTN_FWU_CHECK_HOLDER: 'aboutMyDeviceBtnCheckHolderFirmwareUpdate',
    ABOUT_DEVICE_BTN_HOLDER: 'aboutMyDeviceBtnHolderSection',
    ABOUT_DEVICE_BTN_REGISTER: 'aboutMyDeviceBtnRegisterDevice',
    ABOUT_DEVICE_BTN_UNREGISTER: 'aboutMyDeviceBtnUnregisterDevice',
    ABOUT_DEVICE_BTN_UNREGISTER_CONFIRM: 'aboutMyDeviceUnregisterBtnConfirm',
    ABOUT_DEVICE_CHARGER_CODENTIFY: 'aboutMyDeviceTextChargerCodentify',
    ABOUT_DEVICE_PAGE: 'aboutMyDevicePage',
    ABOUT_DEVICE_PAGE_UNREGISTER_POPUP: 'aboutMyDeviceBtnUnregisterPopup',
    ABOUT_DEVICE_SUCC_DEACTIV_NOTIF: 'aboutMyDeviceSuccDeactivationNotification',
    ABOUT_DEVICE_SUCC_REG_NOTIF: 'aboutMyDeviceSuccRegisterNotification',
    ABOUT_DEVICE_SUCC_UNREG_NOTIF: 'aboutMyDeviceSuccUnregisterNotification',
    AUTO_START_SETTINGS_BTN: 'statusAutoStartWhenStickInserted',
    ACTIVATION_FLOW_ACTIVATING_TITLE: 'activationFlowTitleActivating',
    ACTIVATION_FLOW_REGISTERING_TITLE: 'activationFlowTitleRegistering',
    AGE_VERIFICATION_PAGE: 'verifyAgePage',
    APP_INTRO_PAGE_NATIVE_APP_DOWNLOAD_BTN: 'compatibilityBrowserBtnDownloadNativeApp',
    AUTO_START_MODE_POPUP: 'autoStartPopup',
    AUTO_START_WHEN_STICK_TEXT: 'autoStartWhenStickBtn',
    AUTOSTART_BUTTON_FALSE: 'autoStartBtnFalse',
    AUTOSTART_BUTTON_TRUE: 'autoStartBtnTrue',
    BROWSER_COMPATIBLE_PAGE: 'compatibilityBrowserPage',
    CAROUSEL_DOT: 'carouselItem',
    CLEANING_BTN_START: 'cleaningBtnStart',
    CLEANING_MODE_POPUP: 'cleaningPopup',
    CLEANING_RECENTLY_CLEANED_NOTIFICATION: 'cleaningRecentlyCleanedNotification',
    COMPATIBILITY_BROWSER_BTN_CONTINUE: 'compatibilityBrowserBtnContinue',
    DATA_COLLECTION_PAGE: 'dataCollectionPage',
    DEVICE_LIST_BTN_ACTIVATE: 'deviceListBtnActivate',
    DEVICE_LIST_BTN_ADD_NEW: 'deviceListBtnAddNew',
    DEVICE_LIST_BTN_LOCK: 'deviceListBtnLock',
    DEVICE_LIST_BTN_PAIR: 'deviceListBtnPairDevice',
    DEVICE_LIST_BTN_PAIRING_IFO_POPUP_PAIR_DEVICE: 'deviceListBtnPairingInfoPopupPairDevice',
    DEVICE_LIST_BTN_SET_UP_DEVICE_POPUP_BLE: 'deviceListBtnSetUpDevicePopupBle',
    DEVICE_LIST_BTN_SET_UP_DEVICE_POPUP_USB: 'deviceListBtnSetUpDevicePopupUSB',
    DEVICE_LIST_BTN_SETTINGS: 'deviceListBtnSettings',
    DEVICE_LIST_BTN_AVAILABLE_FWU: 'deviceListBtnAvailableFirmware',
    DEVICE_LIST_BTN_AVAILABLE_FWU_CLOSE_BTN: 'deviceListBtnAvailableFirmwareCloseBtn',
    DEVICE_LIST_BTN_SETUP_NEW_DEVICE: 'deviceListBtnSetUpNewDevice',
    DEVICE_LIST_BTN_UNLOCK: 'deviceListBtnUnlock',
    DEVICE_LIST_DEVICE_CORTEZ: 'pairingSequenceBtnSelectCortez',
    DEVICE_LIST_DEVICE_MULTI: 'pairingSequenceBtnSelectMulti',
    DEVICE_LIST_DEVICE_ORDINARY: 'pairingSequenceBtnSelectOrdinary',
    DEVICE_LIST_DEVICE_SOYUZ: 'pairingSequenceBtnSelectSoyuz',
    DEVICE_LIST_ITEM: 'DeviceListItem',
    DEVICE_LIST_PAGE: 'deviceListPage',
    DEVICE_LOCK_BUTTON: 'DeviceLockBtn',
    DEVICE_LEARN_MORE_BUTTON: 'deviceListLearnMoreButton',
    DEVICE_SETTINGS_BTN_ABOUT: 'deviceSettingsBtnAboutMyDevice',
    DEVICE_SETTINGS_BTN_AUTO_START: 'deviceSettingsBtnAutoStart',
    DEVICE_SETTINGS_BTN_BACK: 'deviceSettingsBtnBack',
    DEVICE_SETTINGS_BTN_CLEANING: 'deviceSettingsBtnCleaning',
    DEVICE_SETTINGS_BTN_DIAGNOSTIC: 'deviceSettingsBtnDiagnostic',
    DEVICE_SETTINGS_BTN_LED: 'deviceSettingsBtnLed',
    DEVICE_SETTINGS_BTN_LEGALS: 'deviceSettingsBtnLegalAndPolicy',
    DEVICE_SETTINGS_BTN_LOCATE: 'deviceSettingsBtnLocate',
    DEVICE_SETTINGS_BTN_LOCK: 'deviceSettingsBtnLock',
    DEVICE_SETTINGS_BTN_PREFERENCE_CENTER: 'deviceSettingsBtnPreferenceCenter',
    DEVICE_SETTINGS_BTN_RENAME: 'deviceSettingsBtnRename',
    DEVICE_SETTINGS_BTN_RESP_DRAW: 'deviceSettingsBtnResponsiveDraw',
    DEVICE_SETTINGS_BTN_STATUS_GESTURE: 'deviceSettingsBtnStatusGesture',
    DEVICE_SETTINGS_BTN_TIPS_TRICKS_MORE: 'deviceSettingsBtnTipsAndTutorialsMore',
    DEVICE_SETTINGS_BTN_UNLOCK: 'deviceSettingsBtnUnlock',
    DEVICE_SETTINGS_BTN_UNPAIR: 'deviceSettingsBtnUnpairDevice',
    DEVICE_SETTINGS_BTN_VAPE_CLOUD_SIZE: 'deviceSettingsBtnVapeCloudSize',
    DEVICE_SETTINGS_BTN_VIBRATION: 'deviceSettingsBtnVibration',
    DEVICE_SETTINGS_DEVICE_NAME: 'deviceSettingsTextDeviceName',
    DEVICE_SETTINGS_DIAGNOSTIC_NO_ERRORS_NOTIFICATION: 'deviceSettingsDiagnosticNoErrorsNotification',
    DEVICE_SETTINGS_DIAGNOSTIC_NOT_AVAILABLE_NOTIFICATION: 'deviceSettingsDiagnosticNotAvailableNotification',
    DEVICE_SETTINGS_PAGE: 'deviceSettingsPage',
    DEVICE_SETTINGS_STORE_LOCATOR: 'deviceSettingsBtnShop',
    DEVICE_SETTINGS_UNPAIR_BTN_NO: 'deviceSettingsUnpairBtnNo',
    DEVICE_SETTINGS_UNPAIR_BTN_YES: 'deviceSettingsUnpairBtnYes',
    FWU_ADDITIONAL_STEPS_GUIDE_BTN_BACK: 'fwuAdditionalStepsGuideBtnBack',
    FWU_NOT_NEEDED_GOT_IT_BTN: 'firmwareNotNeededBtnGotIt',
    FWU_BEFORE_START_START_BTN: 'checkFirmwareBtnConfirm',
    FWU_BEFORE_START_CANCEL_BTN: 'checkFirmwareBtnCancel',
    GESTURE_MODE_DOUBLE_TAP_BTN: 'statusGestureDoubleTapBtn',
    GESTURE_MODE_LIFT_UP_BTN: 'statusGestureListUpBtn',
    GESTUREMODE_BUTTON_FALSE: 'gestureModetBtnFalse',
    GESTUREMODE_BUTTON_TRUE: 'gestureModetBtnTrue',
    LED_MODE_POPUP: 'ledPopup',
    LED_POPUP_BTN_100: 'ledBtn100',
    LED_POPUP_BTN_50: 'ledBtn50',
    LEGAL_AND_POLICY_BTN_BACK: 'legalAndPolicyBtnBack',
    LEGAL_AND_POLICY_POPUP: 'legalAndPolicyPopup',
    LEGAL_DISCLAIMER_BTN_BACKS: 'legalDisclaimerBtnBack',
    LEGAL_DISCLAIMER_BTN_CONTINUE: 'legalDisclaimerBtnContinue',
    LEGAL_DISCLAIMER_PAGE: 'legalDisclaimerPage',
    LOADER_CONTROL: 'LoaderControl',
    LOCATE_DEVICE_POPUP: 'locateMyDevicePopup',
    LOCATE_DEVICE_POPUP_BTN_START: 'locateMyDeviceBtnStartLocate',
    LOCATE_DEVICE_POPUP_BTN_STOP: 'locateMyDeviceBtnStopLocate',
    LOCK_POPUP: 'lockPopup',
    LOCK_POPUP_BTN_CANCEL: 'lockBtnCancel',
    LOCK_POPUP_BTN_LOCK: 'lockBtnLock',
    LOGIN_BTN_BACK: 'loginBtnBack',
    LOGIN_BTN_LOGIN: 'loginBtnLogin',
    LOGIN_INPUT_PASSWORD: 'loginInputPassword',
    LOGIN_INPUT_USERNAME: 'loginInputUsername',
    LOGIN_PAGE: 'LoginPage',
    MARKET_BTN_CONFIRM: 'marketSelectBtnConfirm',
    MARKET_DDL_LANGUAGE: 'marketSelectDDLLanguage',
    MARKET_DDL_MARKET: 'marketSelectDDLMarket',
    MARKET_SELECT_PAGE: 'marketSelectPage',
    PAIRING_SEQUENCE_ACTIVATION_PAGE: 'pairingSequenceActivationPage',
    PAIRING_SEQUENCE_BTN_SKIP: 'pairingSequenceBtnSkip',
    PAIRING_SEQUENCE_BTN_NEXT: 'pairingSequenceBtnNext',
    PAIRING_SEQUENCE_BTN_SELECT_P1V40: 'pairingSequenceBtnSelectVoyager',
    PAIRING_SEQUENCE_BTN_SELECT_P1V40D: 'pairingSequenceBtnSelectDelta',
    PAIRING_SEQUENCE_BTN_SELECT_P1V40M: 'pairingSequenceBtnSelectCosmos',
    PAIRING_SEQUENCE_BTN_SELECT_VEEV: 'pairingSequenceBtnSelectVeev',
    PAIRING_SEQUENCE_DEVICE_SELECT_PAGE: 'pairingSequenceDeviceSelectPage',
    PAIRING_SEQUENCE_ERROR_SKIP_BTN: 'pairingSequenceErrorBtnSkip',
    PAIRING_SEQUENCE_ERROR_TRY_AGAIN_BTN: 'pairingSequenceErrorBtnTryAgain',
    PAIRING_SEQUENCE_PROGRESS_PAGE: 'pairingSequenceProgressPage',
    PAIRING_SEQUENCE_REGISTERING_PAGE: 'pairingSequenceRegisteringPage',
    PAIRING_SEQUENCE_SKIP_PAGE: 'pairingSequenceSkipPage',
    PAIRING_SEQUENCE_SUCCESSFUL_PAIRED_TITLE: 'pairingSequenceSuccessfullyPairedTextTitle',
    PAIRING_SEQUENCE_TITLE: 'pairingSequenceTitle',
    PAIRING_SUCCESS_DEVICE_LIST_BUTTON: 'pairingSequenceBtnDeviceList',
    PAIRING_SUCCESS_TITLE: 'pairingSequenceTitleSuccessfullyPaired',
    PAIRING_SKIP_POPUP_TRY_AGAIN_BUTTON: 'pairingSequenceSkipBtnTryAgain',
    PAIRING_SKIP_POPUP_SKIP_BUTTON: 'pairingSequenceSkipBtnSkip',
    POPUP_COMMON_BTN_CLOSE: 'popupBtnClose',
    PREF_CENTER_BTN_BACK: 'prefCenterBtnBack',
    PREF_CENTER_BTN_SAVE: 'prefCenterBtnSave',
    PREF_CENTER_TOGGLE_1: 'prefCenterTogle1',
    PREFERENCE_CENTER_PAGE: 'preferenceCenterPage',
    QSG_PAGE: 'qsgPage',
    QSG_PAGE_BTN_BACK: 'qsgBtnBack',
    QSG_PAGE_BTN_NEXT: 'qsgBtnNext',
    QSG_PAGE_BTN_PREV: 'qsgBtnPrevious',
    QSG_PAGE_BTN_SKIP: 'qsgBtnSkip',
    REGISTRATION_FAILED_POPUP_BUTTON: 'registrationFailedPopupButton',
    RENAME_DEVICE_POPUP: 'renameMyDevicePopup',
    RENAME_DEVICE_POPUP_BTN_CANCEL: 'renameMyDeviceBtnCancel',
    RENAME_DEVICE_POPUP_BTN_CLEAR_INPUT: 'renameMyDeviceBtnClearInput',
    RENAME_DEVICE_POPUP_BTN_OK: 'renameMyDeviceBtnOk',
    RENAME_DEVICE_POPUP_INPUT: 'renameMyDeviceInputDeviceName',
    RESP_DRAW_POPUP_BTN_BUBBLY: 'responsiveDrawBtnBubbly',
    RESP_DRAW_POPUP_BTN_CRESCENDO: 'responsiveDrawBtnCrescendo',
    RESP_DRAW_POPUP_BTN_DISABLE: 'responsiveDrawBtnOff',
    RESP_DRAW_POPUP_BTN_SIREN: 'responsiveDrawBtnSiren',
    RESP_DRAW_POPUP_BTN_STACCATO: 'responsiveDrawBtnStaccato',
    RESP_DRAW_POPUP_BTN_VIVACE: 'responsiveDrawBtnVivace',
    RESPONSIVE_DRAW_POPUP: 'responsiveDrawPopup',
    SETUP_NEW_IQOS_PAGE: 'setUpNewIqosPopup',
    STATUS_GESTURE_MODE_POPUP: 'statusGesturePopup',
    TERMS_OF_USE_PAGE: 'termOfUsePage',
    TIPS_BTN_GET_STARTED: 'tipsBtnGetStarted',
    TIPS_BTN_PAGER_LAST: 'tipsBtnPagerLast', //TODO: add
    TIPS_TRICKS_PAGE: 'tipsPage',
    TIPS_TUTORIALS_BTN_BACK: 'tipsAndTutorialsBtnBack',
    TIPS_TUTORIALS_BTN_DETAILS_BACK: 'tipsAndTutorialsBtnDetailsBack',
    TIPS_TUTORIALS_BTN_DETAILS_EXPLORE: 'tipsAndTutorialsBtnDetailsExplore',
    TIPS_TUTORIALS_BTN_DETAILS_NEXT: 'tipsAndTutorialsBtnDetailsNext',
    TIPS_TUTORIALS_BTN_DETAILS_NEXT_SECTION: 'tipsAndTutorialsBtnDetailsNextSection',
    TIPS_TUTORIALS_BTN_P1_B: 'tipsAndTutorialsBtnP1Battery',
    TIPS_TUTORIALS_BTN_P1_C: 'tipsAndTutorialsBtnP1Cleaning',
    TIPS_TUTORIALS_BTN_P1_HTU: 'tipsAndTutorialsBtnP1HowToUse',
    TIPS_TUTORIALS_BTN_P1_HT: 'tipsAndTutorialsBtnP1HelpfulTips',
    TIPS_TUTORIALS_BTN_P1_T: 'tipsAndTutorialsBtnP1Tips',
    TIPS_TUTORIALS_BTN_P4_B: 'tipsAndTutorialsBtnP4Battery',
    TIPS_TUTORIALS_BTN_P4_C: 'tipsAndTutorialsBtnP4Cleaning',
    TIPS_TUTORIALS_BTN_P4_HTU: 'tipsAndTutorialsBtnP4HowToUse',
    TIPS_TUTORIALS_BTN_P4_P: 'tipsAndTutorialsBtnP4Pod',
    TIPS_TUTORIALS_BTN_P4_RD: 'tipsAndTutorialsBtnP4ResponsiveDraw',
    TIPS_TUTORIALS_BTN_P4_T: 'tipsAndTutorialsBtnP4Tips',
    TIPS_TUTORIALS_BTN_COSMOS_AF: 'tipsAndTutorialsBtnCosmosAdvancedFeature',
    TIPS_TUTORIALS_PAGE: 'tipsAndTutorialsPage',
    UNLOCK_POPUP: 'unlockPopup',
    UNLOCK_POPUP_BTN_CANCEL: 'unlockBtnCancel',
    UNLOCK_POPUP_BTN_UNLOCK: 'unlockBtnUnlock',
    VAPE_CLOUD_POPUP_BTN_REDUCED: 'VapeCloudSizeBtnReduced',
    VAPE_CLOUD_POPUP_BTN_REGULAR: 'vapeCloudSizeBtnRegular',
    VAPE_CLOUD_SIZE_POPUP: 'vapeCloudSizePopup',
    VERIFY_AGE_BTN_BACK: 'verifyAgeBtnBack',
    VERIFY_AGE_BTN_CONFIRM: 'verifyAgeBtnConfirm',
    VERIFY_AGE_INPUT_DAY: 'verifyAgeInputDay',
    VERIFY_AGE_INPUT_MONTH: 'verifyAgeInputMonth',
    VERIFY_AGE_INPUT_YEAR: 'verifyAgeInputYear',
    VIBRATION_BTN_DUR_USG_ALMOST_FINISHED: 'vibrationBtnDuringUsageAlmostFinished',
    VIBRATION_BTN_DUR_USG_EXPERIENCE_ENDED: 'vibrationBtnDuringUsageExperienceEnded',
    VIBRATION_BTN_DUR_USG_ORDINARY_HEAT: 'vibrationBtnDuringUsageOrdinaryHeat',
    VIBRATION_BTN_DUR_USG_READY_TO_USE: 'vibrationBtnDuringUsageReadyToUse',
    VIBRATION_BTN_DUR_USG_START_HEATING: 'vibrationBtnDuringUsageStartHitting',
    VIBRATION_BTN_HOLDER_BATTERY_CHARGED: 'vibrationBtnHolderBatteryCharged',
    VIBRATION_BTN_RESET_TO_DEFAULT: 'vibrationBtnResetToDefault',
    VIBRATION_BUTTON_FALSE: 'vibrationBtnFalse',
    VIBRATION_BUTTON_TRUE: 'vibrationBtnTrue',
    VIBRATION_MODE_POPUP: 'vibrationPopup',
    RENAME_MY_DEVICE_SUCCESS_NOTIFICATION: 'renameMyDeviceSuccessNotification',
    DEVICE_SETTINGS_BTN_COUNTDOWN_MODE: 'deviceSettingsBtnCountdownMode',
    COUNTDOWN_MODE_POPUP: 'countdownPopup',
    COUNTDOWN_MODE_DURING_EXP_BTN: 'countdownModeDuringExperienceBtn',
    COUNTDOWN_MODE_FALSE_BTN: 'countdownModeFalseBtn',
    COUNTDOWN_MODE_TRUE_BTN: 'countdownModeTrueBtn',
    TNC_TOU_BTN_AGREE: 'termOfUseBtnAgree',
    TNC_TOU_BTN_DECLINE: 'termOfUseBtnDecline',
    TNC_TOU_BTN_DETAILED: 'termOfUseBtnDetailed',
    TNC_TOU_NOTIFICATION: 'termOfUseNotification',
    TNC_DC_AGREE: 'dataCollectionBtnAgree',
    TNC_DC_DECLINE: 'dataCollectionBtnDecline',
    TNC_DC_PREF_CENTER: 'dataCollectionBtnPrefCenter',
    ABOUT_MY_DEVICE_OFFLINE_NOTIFICATION: 'aboutMyDeviceOfflineNotification',
    RENAME_MY_DEVICE_FAILED_NOTIFICATION: 'renameMyDeviceFailedNotification',
    DEVICE_SETTINGS_RESTART_DEVICE_POPUP: 'deviceSettingsRestartDevicePopup',
    DEVICE_SETTINGS_RESTART_DEVICE_BTN: 'deviceSettingsRestartDeviceBtn',
    DEVICE_SETTINGS_RESTART_DEVICE_POPUP_ACCEPT_BTN: 'deviceSettingsRestartDevicePopupAcceptBtn',
    DEVICE_SETTINGS_RESTART_DEVICE_POPUP_CANCEL_BTN: 'deviceSettingsRestartDevicePopupCancelBtn',
    PURCHASE_DATE_BTN_CONTINUE: 'purchaseDateBtnContinue',
    PURCHASE_DATE_BTN_LATER: 'purchaseDateBtnLater',
    PURCHASE_DATE_PICKER: 'purchaseDatePicker',
    PURCHASE_DATE_LATER_POPUP_BTN_OK: 'purchaseDateLaterPopupBtnOk',
};
