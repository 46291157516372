import React from 'react';

import Icon from './Icon';

const IconCloudNormalWhite = (props) => {
    return (
        <Icon width='54' height='32' viewBox='0 0 54 32' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g fillRule='nonzero' strokeWidth='1.7'>
                    <path
                        d='M31.335 1.509c2.687-.765 5.649-.676 8.482.324 2.869.99 5.174 2.75 6.649 4.984 1.435 2.175 2.077 4.797 1.687 7.568-.033.232-.072.46-.118.686.206.099.41.206.61.32 1.352.776 2.448 1.85 3.203 3.104.74 1.233 1.152 2.64 1.152 4.105 0 2.3-1.026 4.387-2.662 5.903-1.665 1.543-3.96 2.497-6.48 2.497h0-33.572c-2.583 0-4.912-.949-6.596-2.487C2.031 26.996 1 24.904 1 22.6c0-1.683.526-3.25 1.437-4.578.955-1.39 2.33-2.517 3.97-3.246 1.595-3.71 4.045-6.141 7.056-7.548 3.054-1.426 6.693-1.803 10.099-.75.232.07.46.148.687.232.13-.201.269-.4.413-.594 1.703-2.308 4.057-3.863 6.673-4.607z'
                        transform='translate(-460 -384) translate(375 168) translate(60 184) translate(25 32)'
                    />
                </g>
            </g>
        </Icon>
    );
};

export default IconCloudNormalWhite;
