import cn from 'classnames';
import React from 'react';

import Popup from '../../components/Popup/Popup';
import styles from './Popup.module.scss';

const PopupRounded = ({className, ...props}) => {
    return <Popup {...props} className={cn(styles.PopupRounded, className)} />;
};

export default PopupRounded;
