import React from 'react';
import {Route, Switch} from 'react-router-dom';

import * as routePaths from '../../consts/route/routePaths';
import BrowserVerificationPage from '../BrowserCompatibility/BrowserVerificationPage/BrowserVerificationPage';
import Root from '../Root/Root';
import TNC from '../TNC/TNC';
import AppRoute from './AppRoute';
import loadableRoutes from './loadableRoutes';

const Routes = () => {
    return (
        <Switch>
            <Route exact path={routePaths.ROOT} component={Root} />
            <AppRoute path={routePaths.ABOUT_DEVICE} component={loadableRoutes.AboutDevicePage} />
            <AppRoute path={routePaths.ACTIVATION} component={loadableRoutes.DeviceActivationPage} />
            <AppRoute path={routePaths.AGE_GATE} component={loadableRoutes.AgeGatePage} />
            <AppRoute path={routePaths.AGE_VERIFICATION_FAILED} component={loadableRoutes.AgeVerificationFailedPage} />
            <AppRoute path={routePaths.APP_INTRO} component={loadableRoutes.AppIntroPage} />
            <AppRoute path={routePaths.BENEFIT_PAGE} component={loadableRoutes.BenefitPage} />
            <AppRoute path={routePaths.BROWSER_VERIFICATION} component={BrowserVerificationPage} />
            <AppRoute path={routePaths.CLEAN_TUTORIAL} component={loadableRoutes.CleaningGuidePage} />
            <AppRoute path={routePaths.DEVICE_SETTINGS} component={loadableRoutes.DeviceSettingsPage} />
            <AppRoute path={routePaths.LEGAL_DISCLAIMER} component={loadableRoutes.LegalDisclaimerPage} />
            <AppRoute path={routePaths.LOGIN} component={loadableRoutes.LoginPage} />
            <AppRoute path={routePaths.MY_DEVICES} component={loadableRoutes.MyDevicesPage} />
            <AppRoute path={routePaths.NO_DEVICES} component={loadableRoutes.NoDevicesPage} />
            <AppRoute path={routePaths.ERROR_PAGE_PARAMETERIZED} component={loadableRoutes.ErrorHandlingPage} />
            <AppRoute path={routePaths.NOT_COMPATIBLE_BROWSER} component={loadableRoutes.NotCompatibleBrowserPage} />
            <AppRoute path={routePaths.PAIRING_ALERT} component={loadableRoutes.PairingAlertPage} />
            <AppRoute path={routePaths.PAIRING_GUIDE_PARAMETERIZED} component={loadableRoutes.PairingGuidePage} />
            <AppRoute path={routePaths.PAIRING_SUCCESS} component={loadableRoutes.PairingSuccessPage} />
            <AppRoute path={routePaths.REGISTRATION} component={loadableRoutes.DeviceRegistrationPage} />
            <AppRoute path={routePaths.SYNC} component={loadableRoutes.DeviceSyncPage} />
            <AppRoute path={routePaths.RESET_DEVICE} component={loadableRoutes.ResetDevicePage} />
            <AppRoute path={routePaths.SELECT_DEVICE} component={loadableRoutes.SelectYourDevicePage} />
            <AppRoute path={routePaths.SELECT_MARKET} component={loadableRoutes.SelectMarketPage} />
            <AppRoute path={routePaths.TIPS_AND_TRICKS} component={loadableRoutes.TipsAndTricksPage} />
            <AppRoute
                path={routePaths.TNC_DATA_PREFERENCE_SETTINGS}
                component={loadableRoutes.PreferenceCenterSettingsPage}
            />
            <AppRoute path={routePaths.TNC} component={TNC} />
            <AppRoute path={routePaths.TUTORIAL_INTRO_PARAMETERIZED} component={loadableRoutes.TutorialIntroPage} />
            <AppRoute path={routePaths.TUTORIAL_LIST} component={loadableRoutes.TutorialList} />
            <AppRoute path={routePaths.TUTORIAL_PARAMETERIZED} component={loadableRoutes.TutorialPage} />
            <AppRoute path={routePaths.UPDATE_FW_PARAMETERIZED} component={loadableRoutes.UpdateFWPage} />
            <AppRoute path={routePaths.WELCOME_USER} component={loadableRoutes.WelcomeUserPage} />
        </Switch>
    );
};

export default Routes;
