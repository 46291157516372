import log from '../services/logger/log';
import {addReduxLoggerIfEnabled} from '../state/store';
import appConfig from './appConfig';
import configService from './configService';
import configTemplate from './marketConfigs/configTemplate';

const config = {...configTemplate};

const initConfig = async (market) => {
    const configData = await configService.getConfigData(market);

    config.data = {...config.data, ...configData};
};

const customInitConfig = async (countryCode) => {
    await initConfig(countryCode);

    if (appConfig.getIsMarketInitialized()) {
        log.setLevel(appConfig.getLogLevel(), appConfig.getAwsLogLevel());
    }

    await addReduxLoggerIfEnabled();
};

export {config, initConfig, customInitConfig};
