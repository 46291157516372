import uaUtils from '../../utils/uaUtils';
import userDeviceService from './userDeviceService';

const CHROME_NAME = 'Chrome';

const isBluefyBrowser = () => !!navigator.userAgent.match(/Bluefy/i);

const isChromeBrowser = () => uaUtils.getBrowserName() === CHROME_NAME;

const isEdgeBrowser = () => !!navigator.userAgent.match(/Edg/i);

const isEdgeAndroidBrowser = () => !!navigator.userAgent.match(/EdgA/i);

const isEdgeIOSBrowser = () => !!navigator.userAgent.match(/EdgiOS/i);

const isBraveBrowser = () => !!navigator.brave;

const isBrowserTrusted = () => {
    const isBrowserTrusted =
        (isBluefyBrowser() || (isChromeBrowser() && !!window.chrome) || isEdgeBrowser() || isEdgeAndroidBrowser()) &&
        !isBraveBrowser();

    if (userDeviceService.isMobileDevice()) {
        // RS: Check mobile browser on extensions availability
        // to avoid some mobile browsers (Kiwi Browser)
        return isBrowserTrusted && (isEdgeAndroidBrowser() ? true : !isExtensionsAvailable()) && !isEdgeIOSBrowser();
    } else {
        return isBrowserTrusted;
    }
};

const isExtensionsAvailable = () => !!window.chrome && !!window.chrome.runtime;

const getUserAgent = () => navigator.userAgent;

export default {
    getUserAgent,
    isBluefyBrowser,
    isBrowserTrusted,
    isEdgeBrowser,
};
