import urlUtils from '../../utils/urlUtils';
import {config} from '../config';

const getAssembledExternalConfigPathByFileName = (fileName) => {
    const storagePath = getStoragePath();
    const storageFolderName = config.data.storage.externalConfig.folderName;

    if (storagePath && storageFolderName && fileName) {
        const assembledExternalConfigPath = urlUtils.join(storagePath, storageFolderName, fileName);

        return assembledExternalConfigPath;
    }
};

const getCombinedPathAssetPath = (customPath) => {
    const storagePath = getStoragePath();

    if (storagePath && customPath) {
        const combinedPath = urlUtils.join(storagePath, customPath);

        return combinedPath;
    }
};

const getLinksFileName = () => config.data.storage.externalConfig.linksFileName;
const getVideoGuidesConfigFileName = () => config.data.storage.externalConfig.videoGuidesConfigFileName;
const getAssetsName = () => config.data.storage.assetsPath;
const reconnectGuideAssetsPath = () => config.data.storage.reconnectGuideAssetsPath;
const holderAssetsPath = () => config.data.storage.holderAssetsPath;
const getStoragePath = () => config.data.storage.path;
const getIntroAssetsPaths = () => {
    const {compatible, notCompatible} = config.data.storage.introAssets;

    return {
        compatiblePath: getCombinedPathAssetPath(compatible),
        notCompatiblePath: getCombinedPathAssetPath(notCompatible),
    };
};

export default {
    getExternalConfigLinksPath: () => getAssembledExternalConfigPathByFileName(getLinksFileName()),
    getExternalVideoGuidesConfigPath: () => getAssembledExternalConfigPathByFileName(getVideoGuidesConfigFileName()),
    getAssetsPath: () => getCombinedPathAssetPath(getAssetsName()),
    getHolderAssetsPath: () => getCombinedPathAssetPath(holderAssetsPath()),
    getReconnectGuideAssetsPath: () => getCombinedPathAssetPath(reconnectGuideAssetsPath()),
    getIntroAssetsPaths,
    getCombinedPathAssetPath,
};
