import cn from 'classnames';
import React, {FC} from 'react';

import styles from './Button.module.scss';
import ButtonArrowEnterSVG from './ButtonArrowEnterSVG/ButtonArrowEnterSVG';
import ButtonArrowSVG from './ButtonArrowSVG/ButtonArrowSVG';
import ButtonRaw from './ButtonRaw';
import {IButton} from './ButtonTypes';

const Button: FC<IButton> = ({
    showArrow,
    showEnterArrow,
    showUnderline,
    children,
    arrowClassName,
    className,
    ...props
}) => {
    const btnClassName = cn(styles.Button, className, {
        [styles.Underline]: showUnderline,
    });

    return (
        <ButtonRaw {...props} className={btnClassName}>
            {children}
            {showArrow && <ButtonArrowSVG arrowClassName={arrowClassName} />}
            {showEnterArrow && <ButtonArrowEnterSVG />}
        </ButtonRaw>
    );
};

export default Button;
