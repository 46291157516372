import appConfig from '../../config/appConfig';
import ACTIVATION_ERRORS_DATA from '../../consts/analytics/activationErrorsData';
import * as logMessageTypes from '../../consts/app/logMessageTypes';
import * as uamWebSocketActionValues from '../../consts/uam/uamWebSocketActionValues';
import * as yapActionStatusTypes from '../../consts/yap/yapActionStatusTypes';
import * as yapConnectionStatusTypes from '../../consts/yap/yapConnectionStatusTypes';
import * as yapDataChangePropertyTypes from '../../consts/yap/yapDataChangePropertyTypes';
import * as yapWebSocketActionTypes from '../../consts/yap/yapWebSocketActionTypes';
import * as yapWebSocketEventTypes from '../../consts/yap/yapWebSocketEventTypes';
import {setDeviceActivationStatus, setIsWrongDevice, updateIotDeviceData} from '../../state/ducks/iotDevice/actions';
import {
    setDeviceDataChangeReceived,
    setDeviceInfoReceived,
    setHolderInfoReceived,
    setSyncLastRequestId,
    setYapActivationInProgress,
    setYapDeviceConnected,
    setYapFindDeviceReceived,
    setYapHolderConnected,
    setYapLastRequestId,
    setYapSynchronizeDataCollectionInProgress,
    setYapSynchronizeDeviceDataCollectionReceived,
    setYapSynchronizeHolderDataCollectionReceived,
    setYapSyncInProgress,
    setYapUpdateSettingsInProgress,
} from '../../state/ducks/yapEncrypted';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../state/selectors/consumer';
import {makeSelectIsDeviceReady} from '../../state/selectors/iotDevice';
import {
    makeSelectIsDeviceDataChangeReceived,
    makeSelectIsHolderByAssetId,
    makeSelectIsYapActivationInProgress,
    makeSelectIsYapUpdateSettingsInProgress,
    makeSelectSyncLastRequestId,
    makeSelectYapLastRequestId,
} from '../../state/selectors/yapEncrypted';
import {dispatch, getState} from '../../state/store';
import analyticsService from '../analyticsService';
import appErrorService from '../app/appErrorService';
import cmClientService from '../communicationLayer/cmClientService';
import IqosServiceClient from '../device/iqosServiceClient';
import log from '../logger/log';
import yapStateService from '../yap/yapStateService';
import uamClientService from './uamClientService';
import uamService from './uamService';

const proceedMessage = async (message) => {
    const data = JSON.parse(message.data) || {};
    const assetId = data?.assetId;

    switch (data?.eventType) {
        case yapWebSocketEventTypes.CONNECTION_STATUS_CHANGE:
            const isHolder = makeSelectIsHolderByAssetId(assetId)(getState());

            if (data.status === yapConnectionStatusTypes.CONNECTED) {
                const {isSBLMode} = data;

                yapStateService.updateDeviceSettings({
                    type: yapDataChangePropertyTypes.IS_SBL_MODE,
                    newValue: isSBLMode,
                    isHolder,
                });

                if (cmClientService.isCmClientConnected(assetId)) {
                    if (isHolder) {
                        dispatch(setYapHolderConnected(true));
                    } else {
                        dispatch(setYapDeviceConnected(true));
                    }
                }
            } else if (data.status === yapConnectionStatusTypes.DISCONNECTED) {
                if (isHolder) {
                    dispatch(setYapHolderConnected(false));
                } else {
                    dispatch(setYapDeviceConnected(false));
                }
            } else if (data.status === yapConnectionStatusTypes.INCORRECT_DEVICE) {
                log.info('End synchronization process. Incorrect Device.');
                dispatch(setIsWrongDevice());
                dispatch(setYapSyncInProgress(false, isHolder));
            }
            break;
        case yapWebSocketEventTypes.ASYNC_REQUEST:
            switch (data.request?.action) {
                case yapWebSocketActionTypes.ACTIVATE:
                    return proceedUamActivationAction(true, data.status, data.request.value, data.requestId);
                case yapWebSocketActionTypes.DEACTIVATE:
                    return proceedUamActivationAction(false, data.status, data.request.value, data.requestId);
                case yapWebSocketActionTypes.REFRESH_INFO:
                    const proceedRefreshStatus = proceedUamAction({
                        status: data.status,
                        requestId: data.requestId,
                        isSync: true,
                    });

                    if (proceedRefreshStatus) {
                        await proceedRefreshInfo(proceedRefreshStatus, assetId);
                    }
                    break;
                case yapWebSocketActionTypes.RUN_COMMAND:
                    const proceedRunCommandStatus = proceedUamAction({status: data.status, requestId: data.requestId});

                    if (proceedRunCommandStatus) {
                        await proceedFindDevice(proceedRunCommandStatus);
                    }
                    break;
                case yapWebSocketActionTypes.DATA_COLLECTION:
                    await proceedSynchronizeDataCollection(data.status, data.requestId, assetId);
                    break;
                case yapWebSocketActionTypes.UPDATE_SETTING:
                    if (proceedUamAction({status: data.status, requestId: data.requestId})) {
                        checkProcessFinish(assetId);
                    }
                    break;
            }
            break;
        case yapWebSocketEventTypes.DEVICE_DATA_CHANGE:
            const {updatedData} = data;

            if (!updatedData) return;

            let isRunCheck = false;

            updatedData.forEach((updatedObj) => {
                switch (updatedObj?.propertyName) {
                    case yapDataChangePropertyTypes.YAP_FIRMWARE_VERSION_PROPERTY:
                        updateFirmwareVersion(assetId, updatedObj.newValue);
                        break;
                    case yapDataChangePropertyTypes.YAP_RESPONSIVE_DRAW_PROPERTY:
                    case yapDataChangePropertyTypes.YAP_VAPE_CLOUD_SIZE_PROPERTY:
                    case yapDataChangePropertyTypes.YAP_PARENT_PROTECTION_PROPERTY:
                    case yapDataChangePropertyTypes.YAP_LED_PROPERTY:
                    case yapDataChangePropertyTypes.YAP_EXT_VIBRATION_PROPERTY:
                    case yapDataChangePropertyTypes.YAP_VIBRATION_FULLY_HEATED_PROPERTY:
                    case yapDataChangePropertyTypes.YAP_VIBRATION_START_HEATING_PROPERTY:
                    case yapDataChangePropertyTypes.YAP_VIBRATION_NEAR_END_PROPERTY:
                    case yapDataChangePropertyTypes.YAP_VIBRATION_END_OF_HEATING_PROPERTY:
                    case yapDataChangePropertyTypes.YAP_VIBRATION_HAPTIC_PROFILE_PROPERTY:
                    case yapDataChangePropertyTypes.YAP_BATTERY_CHARGED_VIBRATION_PROPERTY:
                    case yapDataChangePropertyTypes.YAP_BATTERY_CHARGED_ONE_EXP_VIBRATION_PROPERTY:
                    case yapDataChangePropertyTypes.YAP_BATTERY_CHARGED_TWO_EXP_VIBRATION_PROPERTY:
                    case yapDataChangePropertyTypes.YAP_LIFT_UP_GESTURE_PROPERTY:
                    case yapDataChangePropertyTypes.YAP_DOUBLE_TAP_GESTURE_PROPERTY:
                    case yapDataChangePropertyTypes.YAP_AUTO_START_HEATING_PROPERTY:
                    case yapDataChangePropertyTypes.IS_SBL_MODE:
                        isRunCheck = true;
                        updateDeviceSettings(assetId, updatedObj.propertyName, updatedObj.newValue);
                        break;
                }
            });

            if (isRunCheck) {
                checkProcessFinish(assetId);
            }

            break;
        case yapWebSocketEventTypes.DEVICE_EVENT:
            if (!data.events) return;

            data.events.forEach((event) => {
                yapStateService.updateDeviceCharacteristics(event);
            });
            break;
        default:
            break;
    }
};

const proceedSynchronizeDataCollection = async (status, requestId, assetId) => {
    const proceedDataCollectionStatus = proceedUamAction({status, requestId});

    if (!proceedDataCollectionStatus) return;

    const isSuccess = proceedDataCollectionStatus === yapActionStatusTypes.SUCCESS;
    const state = getState();
    const isDeviceReady = makeSelectIsDeviceReady()(state);
    const isHolder = makeSelectIsHolderByAssetId(assetId)(state);

    if (isSuccess) {
        dispatch(setYapSynchronizeDataCollectionInProgress(false));
        if (isHolder) {
            dispatch(setYapSynchronizeHolderDataCollectionReceived(true));
        } else {
            dispatch(setYapSynchronizeDeviceDataCollectionReceived(true));
        }
    } else if (isDeviceReady) {
        await uamClientService.proceedSynchronizeAllData(isHolder, true);
    } else {
        dispatch(setYapSynchronizeDataCollectionInProgress(false));
    }
};

const proceedFindDevice = async (status) => {
    dispatch(setYapUpdateSettingsInProgress(false));

    if (status === yapActionStatusTypes.SUCCESS) {
        dispatch(setYapFindDeviceReceived(true));
    } else {
        appErrorService.showDeviceSettingsGlobalError();
    }
};

const updateDeviceSettings = (assetId, type, newValue) => {
    const isHolder = makeSelectIsHolderByAssetId(assetId)(getState());

    yapStateService.updateDeviceSettings({type, newValue, isHolder, isNeedInformUser: true});

    dispatch(setDeviceDataChangeReceived(true));
};

const updateFirmwareVersion = (assetId, version) => {
    if (!assetId || !version) return;

    const isHolder = makeSelectIsHolderByAssetId(assetId)(getState());
    const data = {
        [isHolder ? 'holder' : 'device']: {
            version,
        },
    };

    dispatch(updateIotDeviceData(data));
};

let uamCheckProcessFinishTimeout;
const checkProcessFinish = (assetId) => {
    clearTimeout(uamCheckProcessFinishTimeout);

    const state = getState();
    const isHolder = makeSelectIsHolderByAssetId(assetId)(state);
    const isYapActivationInProgress = makeSelectIsYapActivationInProgress()(state);
    const isYapUpdateSettingsInProgress = makeSelectIsYapUpdateSettingsInProgress(isHolder)(state);

    if (!isYapActivationInProgress && !isYapUpdateSettingsInProgress) {
        return;
    }

    const onFinish = () => {
        dispatch(setDeviceDataChangeReceived(false));
        dispatch(setYapLastRequestId(null, isHolder));

        if (isYapUpdateSettingsInProgress) {
            dispatch(setYapUpdateSettingsInProgress(false, isHolder));
        }
    };

    const lastRequestId = makeSelectYapLastRequestId(isHolder)(state);
    const isDeviceDataChangeReceived = makeSelectIsDeviceDataChangeReceived()(state);

    if (isDeviceDataChangeReceived && !lastRequestId) {
        onFinish();
        return;
    }

    const onFinishFailed = async () => {
        clearTimeout(uamCheckProcessFinishTimeout);

        appErrorService.showDeviceSettingsGlobalError();

        onFinish();
    };

    uamCheckProcessFinishTimeout = setTimeout(onFinishFailed, appConfig.getUamWebSocketMessageTimeout() * 1000);
};

const proceedRefreshInfo = async (status, assetId) => {
    const isHolder = makeSelectIsHolderByAssetId(assetId)(getState());
    let isSuccess = false;

    if (yapActionStatusTypes.SUCCESS === status) {
        const iqosService = new IqosServiceClient();
        let isDeviceConnected = iqosService.isDeviceConnected();

        if (isDeviceConnected) {
            const isDeviceInfoValid = await uamClientService.getUamInfo(assetId);

            if (isDeviceInfoValid) {
                isDeviceConnected = iqosService.isDeviceConnected();
                if (isDeviceConnected) {
                    isSuccess = true;
                    if (isHolder) {
                        dispatch(setHolderInfoReceived(true));
                    } else {
                        dispatch(setDeviceInfoReceived(true));
                    }
                }
            }
        }
    }

    if (isSuccess) {
        log.info('End synchronization process. Success.');
    } else {
        log.info('End synchronization process. Failed.');
    }

    dispatch(setYapSyncInProgress(false, isHolder));
};

const proceedUamActivationAction = async (isActivateAction, status, value, requestId) => {
    const isPending = status === yapActionStatusTypes.PENDING;

    if (isPending) return;

    const lastRequestId = makeSelectYapLastRequestId()(getState());

    if (!lastRequestId || requestId !== lastRequestId) {
        log.info(`uamWebSocketService: answer for requestId ${requestId} is ignored. Waiting for ${lastRequestId}`);
        return;
    }

    const isFailed = status === yapActionStatusTypes.FAILED;
    const isSuccess = status === yapActionStatusTypes.SUCCESS && value === uamWebSocketActionValues.COMPLETE_WORKFLOW;

    if (!isFailed && !isSuccess) return;
    if (isFailed) {
        analyticsService.pushActivationFailedError(ACTIVATION_ERRORS_DATA.ERROR_ACTIVATION_FAILED);
        await refreshAssetsAndSetIsActivated();
    } else {
        //success
        dispatch(setDeviceActivationStatus(isActivateAction));
    }
    dispatch(setYapActivationInProgress(false));
};

const proceedUamAction = ({status, requestId, isSync = false}) => {
    const isPending = status === yapActionStatusTypes.PENDING;

    if (isPending) return;

    const lastRequestId = isSync ? makeSelectSyncLastRequestId()(getState()) : makeSelectYapLastRequestId()(getState());
    const holderRequestId = makeSelectYapLastRequestId(true)(getState());

    if (!lastRequestId && !holderRequestId) {
        log.info(`uamWebSocketService: answer for requestId ${requestId} is ignored. Waiting for undefined`);
        return;
    }

    if (requestId !== lastRequestId && requestId !== holderRequestId) {
        log.info(
            `uamWebSocketService: answer for requestId ${requestId} is ignored. Waiting for ${
                holderRequestId ? holderRequestId : lastRequestId
            }`
        );
        return;
    }

    const isFailed = status === yapActionStatusTypes.FAILED;
    const isSuccess = status === yapActionStatusTypes.SUCCESS;

    if (!isFailed && !isSuccess) return;

    if (isSync) {
        dispatch(setSyncLastRequestId(null));
    } else {
        const isHolder = requestId === holderRequestId;

        dispatch(setYapLastRequestId(null, isHolder));
    }

    return isSuccess ? yapActionStatusTypes.SUCCESS : yapActionStatusTypes.FAILED;
};

const refreshAssetsAndSetIsActivated = async () => {
    try {
        const isValid = await uamClientService.getAssets();

        if (!isValid) return;

        const iotProduct = makeSelectIotDeviceMergedWithIccProduct()(getState());
        const {deviceSerialNumber} = iotProduct || {};

        await uamService.isDeviceActivatedInUam(deviceSerialNumber);
    } catch (e) {
        log.error(`uamWebSocketService: check activation action after failed status error: ${e}`, logMessageTypes.UAM);
    }
};

export default {proceedMessage, proceedRefreshInfo};
