import converter from '../../utils/converter';
import ExponentialBackOff from '../../utils/exponentialBackOff';
import helpers from '../../utils/helpers';
import objUtils from '../../utils/objUtils';
import fwuErrorDetailsService from '../device/fwuErrorDetailsService';
import log from '../logger/log';
import FwuDataClient from './fwuDataClient';
import fwuService from './fwuService';
import IqosBLEClient from './iqosBleClient';

const FWU_STATUS_VALUES = {
    NO_IMAGE: '00',
    UPLOAD_PROCESSING: '01',
    UPGRADE_PROCESSING: '02',
    IMAGE_VERIFICATION_PROCESSING: '03',
    IMAGE_UPLOAD_ERROR: '80',
    IMAGE_STORAGE_ERROR: '81',
    IMAGE_SIGNATURE_ERROR: '82',
    PROCEDURE_NOT_ALLOWED: '83',
};

export default class DeviceFwuCharacteristicClient {
    constructor(options) {
        this.options = options;
        this.iqosBLEClient = new IqosBLEClient();
        this.isStopped = true;
        this.isLastFrameLoaded = false;
        this.isReadingFWUDataCharacteristic = false;
        this.exponentialBackOff = undefined;

        this.start();
    }

    start = async () => {
        try {
            await this.iqosBLEClient.getFwuServices();
            await helpers.timeout(100);
            this.startFwUpdate();
        } catch (e) {
            log.info(`DeviceFwuCharacteristicClient: start error: ${e}`);
            this.onFwuFail();
        }
    };

    stop = () => {
        if (this.isStopped) return;

        this.isReadingFWUDataCharacteristic = false;
        this.isStopped = true;
        this.stopFwuDataClient();
        this.iqosBLEClient.removeFwuStatusCharacteristicListener();
    };

    initNewFwuDataClient = () => {
        this.stopFwuDataClient();

        this.fwuDataClient = new FwuDataClient({
            program_blocks: this.options.fwuPackageData.program_blocks,
            onLastFrame: this.onLastFrame,
            onFail: this.onFwuFail,
            onFwuStatusEvent: this.options.onFwuStatusEvent,
        });
    };

    onLastFrame = () => {
        this.isLastFrameLoaded = true;
    };

    startFwUpdate = async () => {
        this.isStopped = false;

        log.debug(`DeviceFwuCharacteristicClient: start firmware update`);

        await this.iqosBLEClient.addFwuStatusCharacteristicListener(this.onFwuStatusNotification);
        await helpers.timeout(1000);

        this.sendStartUpgradeRequest();
    };

    sendStartUpgradeRequest = () => {
        this.tryToWriteValueToFwuControl(this.options.fwuPackageData.start_programming);
    };

    onFwuStatusNotification = async (value) => {
        if (this.isStopped) return;

        this.disableStartExponentialBackOff();
        this.stopFwuDataClient();

        const response = converter.buffer2hex(value);
        const statusName = objUtils.getKeyByValue(FWU_STATUS_VALUES, response);

        log.info(`DeviceFwuCharacteristicClient: fwu status message: ${response} - ${statusName}`);

        switch (response.toString()) {
            case FWU_STATUS_VALUES.UPLOAD_PROCESSING:
                if (this.isReadingFWUDataCharacteristic) return;
                this.startFwuDataClient();
                return;
            case FWU_STATUS_VALUES.IMAGE_UPLOAD_ERROR:
                if (this.isLastFrameLoaded) {
                    this.onFwuFail(response);
                } else {
                    await helpers.timeout(50);
                    this.readFwuDataCharacteristic();
                }
                break;
            case FWU_STATUS_VALUES.NO_IMAGE:
            case FWU_STATUS_VALUES.PROCEDURE_NOT_ALLOWED:
                this.stop();

                if (!this.isLastFrameLoaded && this.options.canRetry) {
                    this.options.retryToUpdateFW();
                } else {
                    this.onFwuFail(response);
                }

                break;
            case FWU_STATUS_VALUES.IMAGE_STORAGE_ERROR:
            case FWU_STATUS_VALUES.IMAGE_SIGNATURE_ERROR:
                this.onFwuFail(response);
                break;
            case FWU_STATUS_VALUES.UPGRADE_PROCESSING:
                this.options.onUpgradeProcessing();
                return;
            default:
                break;
        }
    };

    onFwuFail = (response) => {
        this.stop();

        const errorData = fwuErrorDetailsService.getErrorDetails({
            allRequests: this.options.fwuPackageData.program_blocks,
            currentIndex: this.fwuDataClient?.currentFrameIndex,
            response,
        });

        this.options.onErrorWithoutRetry(errorData);
    };

    readFwuDataCharacteristic = async () => {
        if (this.isReadingFWUDataCharacteristic) return;

        this.isReadingFWUDataCharacteristic = true;

        const toTry = async () => {
            log.debug(`DeviceFwuCharacteristicClient: try to readFwuDataCharacteristic`);
            this.iqosBLEClient.readFwuDataCharacteristic(this.onFwuDataCharacteristicMessage);
        };
        const success = async () => {
            log.debug(`DeviceFwuCharacteristicClient: readFwuDataCharacteristic success`);
        };
        const fail = () => {
            log.debug(`DeviceFwuCharacteristicClient: readFwuDataCharacteristic failed`);
            this.onFwuFail();
        };

        const exponentialBackOff = new ExponentialBackOff({max: 3, delay: 100, toTry, success, fail});

        exponentialBackOff.run();
    };

    onFwuDataCharacteristicMessage = async (value) => {
        if (this.isStopped) return;

        this.isReadingFWUDataCharacteristic = false;
        const response = converter.buffer2hex(value);
        const frameIndex = fwuService.convertHexIndexToInt(response);
        const frame = this.options.fwuPackageData.program_blocks[frameIndex];

        log.debug(`DeviceFwuCharacteristicClient: read data characteristic: ${response}, frameIndex: ${frameIndex}`);

        if (frame) {
            log.debug(`DeviceFwuCharacteristicClient: try to resend frame: ${frameIndex} - ${frame?.f}`);

            await helpers.timeout(500);
            this.isReadingFWUDataCharacteristic = false;
            this.startFwuDataClient(frameIndex);
        } else {
            this.isReadingFWUDataCharacteristic = false;
            this.onFwuFail(response);
        }
    };

    startFwuDataClient = (frameIndex = 0) => {
        if (this.isStopped) return;
        this.initNewFwuDataClient();

        this.fwuDataClient.start(frameIndex);
    };

    stopFwuDataClient = () => {
        if (this.fwuDataClient) {
            this.fwuDataClient.stop();
        }
    };

    tryToWriteValueToFwuControl = (value) => {
        const clearExponentialBackOff = () => (this.exponentialBackOff = undefined);
        const toTry = async () => {
            log.debug(`DeviceFwuCharacteristicClient: try to writeValue: ${value}`);
            await this.iqosBLEClient.writeValueToFwuControlCharacteristic(value, true);
        };
        const success = async () => {
            log.debug(`DeviceFwuCharacteristicClient: writeValue: ${value} success`);
            clearExponentialBackOff();
        };
        const fail = () => {
            log.debug(`DeviceFwuCharacteristicClient: writeValue: ${value} failed`);
            this.onFwuFail();
            clearExponentialBackOff();
        };

        this.exponentialBackOff = new ExponentialBackOff({max: 3, delay: 500, toTry, success, fail});
        this.exponentialBackOff.run();
    };

    disableStartExponentialBackOff = () => {
        if (this.exponentialBackOff) this.exponentialBackOff.disable();
    };
}
