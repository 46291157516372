import {useEffect} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import * as loaderNames from '../../../consts/loader/loaderNames';
import backendService from '../../../services/app/backendService';
import iotDeviceSettingsService from '../../../services/iotDevice/iotDeviceSettingsService';
import productService from '../../../services/product/productService';
import uamService from '../../../services/uam/uamService';
import yapService from '../../../services/yap/yapService';
import {hideLoader} from '../../../state/ducks/global';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../../state/selectors/consumer';
import {
    makeSelectIsHolderConnectEnabled,
    makeSelectIsScpHolderInfoCheckInProgress,
} from '../../../state/selectors/global';
import {
    makeSelectIsDeviceReady,
    makeSelectIsUpdateSettingsNeeded,
    selectIsFirmwareInProgress,
} from '../../../state/selectors/iotDevice';
import {
    makeSelectIsYapDeviceInfoReceived,
    makeSelectIsYapHolderInfoReceived,
} from '../../../state/selectors/yapEncrypted';
import {dispatch} from '../../../state/store';

const mapStateToProps = createStructuredSelector({
    iotProduct: makeSelectIotDeviceMergedWithIccProduct(),
    isDeviceReady: makeSelectIsDeviceReady(),
    isYapDeviceInfoReceived: makeSelectIsYapDeviceInfoReceived(),
    isYapHolderInfoReceived: makeSelectIsYapHolderInfoReceived(),
    isUpdateSettingsNeeded: makeSelectIsUpdateSettingsNeeded(),
    isHolderConnectEnabled: makeSelectIsHolderConnectEnabled(),
    isScpHolderInfoCheckInProgress: makeSelectIsScpHolderInfoCheckInProgress(),
    isFirmwareInProgress: selectIsFirmwareInProgress,
});

const DeviceHolderConnectionChecker = ({
    iotProduct,
    isDeviceReady,
    isYapDeviceInfoReceived,
    isYapHolderInfoReceived,
    isUpdateSettingsNeeded,
    isHolderConnectEnabled,
    isScpHolderInfoCheckInProgress,
    isFirmwareInProgress,
}) => {
    const {device, holder} = iotProduct || {};
    const {status: deviceStatus, isHolderConnected} = device || {};
    const {status: holderStatus, deviceSerialNumber} = holder || {};
    const isDataCollectionEnabled = yapService.isDataCollectionEnabled();
    const isUamEnabled = backendService.isUamBackend() || isDataCollectionEnabled;
    const isCurrentDeviceRegistered = productService.getIsRegistered(deviceStatus);
    const isCurrentHolderRegistered = productService.getIsRegistered(holderStatus);
    const isCurrentHolderRegisteredFailed = productService.getIsRegisterFailed(holderStatus);
    const isHolderInSBLMode = iotDeviceSettingsService.isHolderInSBLMode(iotProduct);

    useEffect(() => {
        if (
            isDeviceReady &&
            isHolderConnected &&
            deviceSerialNumber &&
            isCurrentDeviceRegistered &&
            isCurrentHolderRegistered &&
            isUamEnabled &&
            isYapDeviceInfoReceived &&
            !isYapHolderInfoReceived &&
            isHolderConnectEnabled &&
            !isScpHolderInfoCheckInProgress &&
            !isHolderInSBLMode &&
            !isFirmwareInProgress
        ) {
            uamService.initSynchronizeFlow({isHolder: true, isLoaderVisible: isUamEnabled});
        }
    }, [
        isHolderConnected,
        deviceSerialNumber,
        isCurrentDeviceRegistered,
        isCurrentHolderRegistered,
        isUamEnabled,
        isYapDeviceInfoReceived,
        isScpHolderInfoCheckInProgress,
        isUpdateSettingsNeeded,
        isHolderInSBLMode,
        isFirmwareInProgress,
    ]);

    useEffect(() => {
        if (
            (isCurrentDeviceRegistered && isCurrentHolderRegistered) ||
            (isCurrentDeviceRegistered && isCurrentHolderRegisteredFailed)
        ) {
            dispatch(hideLoader(loaderNames.REGISTERING_LOADER));
        }
    }, [isYapHolderInfoReceived, isCurrentHolderRegisteredFailed, isCurrentHolderRegistered]);

    return null;
};

export default connect(mapStateToProps)(DeviceHolderConnectionChecker);
