import DEVICE_TYPES from '../../enums/device/deviceTypes';

const getDeviceTypeBySerialNumber = (serialNumber) => {
    const getParsedCodeFromSerialNumber = (start, end) => parseInt(serialNumber.substring(start, end), 16);
    const platformId = getParsedCodeFromSerialNumber(2, 6);
    const productId = getParsedCodeFromSerialNumber(6, 10);
    const serialNumberStart = getParsedCodeFromSerialNumber(14, 15);

    if ((productId === 0x0034 && platformId === 0x001a) || (productId === 0x0038 && platformId === 0x001e)) {
        return DEVICE_TYPES.DEV_TYPE_V24_CHARGER;
    } else if (productId === 0x0033 && platformId === 0x0018) {
        return DEVICE_TYPES.DEV_TYPE_V24_HOLDER;
    } else if (
        (productId === 0x0043 && platformId === 0x0023) ||
        (productId === 0x0045 && platformId === 0x0024) ||
        (productId === 0x0045 && platformId === 0x0029)
    ) {
        return DEVICE_TYPES.DEV_TYPE_V24P_CHARGER;
    } else if (productId === 0x0044 && platformId === 0x0018) {
        return DEVICE_TYPES.DEV_TYPE_V24P_HOLDER;
    } else if (productId === 0x0047 && platformId === 0x0028) {
        return DEVICE_TYPES.DEV_TYPE_P1V30_CHARGER;
    } else if (productId === 0x0048 && platformId === 0x0027) {
        if (serialNumberStart === 0xc0 || serialNumberStart === 0x40) {
            return DEVICE_TYPES.DEV_TYPE_P1V31_HOLDER;
        } else {
            return DEVICE_TYPES.DEV_TYPE_P1V30_HOLDER;
        }
    } else if (productId === 0x0049 && platformId === 0x002d) {
        return DEVICE_TYPES.DEV_TYPE_P1V30M;
    } else if (productId === 0x0053 && platformId === 0x0028) {
        return DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER;
    } else if ((productId === 0x0041 && platformId === 0x0021) || (productId === 0x004a && platformId === 0x002b)) {
        return DEVICE_TYPES.DEV_TYPE_P4_M3_G1;
    } else if (productId === 0x0052 && platformId === 0x002f) {
        return DEVICE_TYPES.DEV_TYPE_P4_M3_G2;
    } else if (productId === 0x0057 && platformId === 0x0033) {
        return DEVICE_TYPES.DEV_TYPE_P1V40V_CHARGER;
    } else if (productId === 0x0055 && platformId === 0x0032) {
        return DEVICE_TYPES.DEV_TYPE_P1V40V_HOLDER_CONTROL;
    } else if (productId === 0x005a && platformId === 0x0033) {
        return DEVICE_TYPES.DEV_TYPE_P1V40C_CHARGER;
    } else if (productId === 0x005c && platformId === 0x0034) {
        return DEVICE_TYPES.DEV_TYPE_P1V40D_CONTROL;
    } else {
        return DEVICE_TYPES.DEV_TYPE_UNKNOWN;
    }
};

export default {
    getDeviceTypeBySerialNumber,
};
