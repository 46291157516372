import {addSignedMedia} from '../state/ducks/media';
import {makeSelectSignedMediaList} from '../state/selectors/media';
import {dispatch, getState} from '../state/store';
import arrayUtils from '../utils/arrayUtils';
import iccMarketService from './icc/iccMarketService';

const getThumbnailNameList = (mediaData) => {
    const mediaIdList = arrayUtils.toArray(mediaData);
    const thumbnailNameList = mediaIdList.map((mediaId) => getThumbnailName(mediaId));

    return thumbnailNameList;
};

const getThumbnailName = (mediaId) => {
    const THUMBNAIL_NAME_PREFIX = '_50x50';
    const thumbnailName = mediaId ? mediaId.replace(/(\.[\w\d_-]+)$/i, `${THUMBNAIL_NAME_PREFIX}$1`) : undefined;

    return thumbnailName;
};

const getSignedMediaUrl = async (mediaId) => {
    const data = await iccMarketService.getMediaUrl({mediaNameList: mediaId});

    if (data) {
        dispatch(addSignedMedia(data));
    }
};

const getSignedMediaList = () => makeSelectSignedMediaList()(getState());

export default {
    getSignedMediaUrl,
    getThumbnailNameList,
    getSignedMediaList,
    getThumbnailName,
};
