import cn from 'classnames';
import React, {FC} from 'react';

import styles from './AppLoader.module.scss';
import AppLoaderContent from './AppLoaderContent';

interface IProps {
    loaderClassName?: string;
}
const AppLoaderMedia: FC<IProps> = ({loaderClassName}) => (
    <div className={cn(styles.LoaderCentered, loaderClassName)}>
        <AppLoaderContent loaderText='' />
    </div>
);

export default AppLoaderMedia;
