import * as cmMessageFakeTypes from '../../consts/communicationLayer/cmMessageFakeTypes';
import * as cmMessageTypes from '../../consts/communicationLayer/cmMessageTypes';
import backendService from '../app/backendService';
import cmMessageRequestService from '../communicationLayer/cmMessageRequestService';
import mwIotMessageRequestService from '../scpCloud/mwIotMessageRequestService';

const onFwuErrorHandler = ({
    errorCode,
    errorFrame,
    fwu_target,
    lastRequestFrames,
    lastResponseFrames,
    onError,
    startTs,
    userAgent,
}) => {
    if (onError) {
        onError(errorCode, errorFrame, fwu_target);
    } else {
        const isGamBackend = backendService.isGamBackend();

        if (!isGamBackend)
            mwIotMessageRequestService.publishFwuErrorEvent({
                errorCode,
                errorFrame,
                lastRequestFrames,
                lastResponseFrames,
                startTs,
                userAgent,
                fwu_target,
            });
    }
};

const onPackageLoaded = (onSuccess, fwu_target) => {
    if (onSuccess) {
        onSuccess(fwu_target);
    } else {
        const isGamBackend = backendService.isGamBackend();

        if (!isGamBackend) mwIotMessageRequestService.publishFwuPackageLoadedEvent(fwu_target);
    }
};

const onFwuStatusEvent = ({onSuccess, status, fwu_target}) => {
    if (onSuccess) {
        onSuccess(status);
    } else {
        const isGamBackend = backendService.isGamBackend();

        if (!isGamBackend) mwIotMessageRequestService.publishFwuStatusEvent({status, fwu_target});
    }
};

const publishFwuFinishEvent = ({startTs, onSuccess, fwu_target}) => {
    if (onSuccess) {
        onSuccess(fwu_target);
    } else {
        const isGamBackend = backendService.isGamBackend();

        if (!isGamBackend) mwIotMessageRequestService.publishFwuFinishEvent({fwu_target, startTs});
    }
};

const publishFwuFinishAfterRebootEvent = () => {
    const isGamBackend = backendService.isGamBackend();

    if (isGamBackend) cmMessageRequestService.publishFwuFinishAfterRebootEvent();
};

const publishDeviceCharacteristicEvent = (frame) => {
    const isGamBackend = backendService.isGamBackend();
    const isUamBackend = backendService.isUamBackend();

    if (isGamBackend || isUamBackend) {
        cmMessageRequestService.publishDeviceCharacteristicEvent(frame);
    } else {
        mwIotMessageRequestService.publishDeviceCharacteristicEvent(frame);
    }
};

const publishBatteryCharacteristicEvent = (frame) => {
    const isGamBackend = backendService.isGamBackend();
    const isUamBackend = backendService.isUamBackend();

    if (isGamBackend || isUamBackend) {
        cmMessageRequestService.publishBatteryCharacteristicEvent(frame);
    } else {
        mwIotMessageRequestService.publishBatteryCharacteristicEvent(frame);
    }
};

const publishResponseEvent = (data) => {
    const isGamBackend = backendService.isGamBackend();
    const isUamBackend = backendService.isUamBackend();
    const {frames, type, cyclicId} = data || {};
    const isCmResponse = cyclicId && type === cmMessageTypes.STATUS_CHARACTERISTICS && frames;

    if (isGamBackend || isUamBackend) {
        if (isCmResponse) {
            const frame = frames[0];

            if (cyclicId === cmMessageFakeTypes.SYSTEM_STATUS) {
                cmMessageRequestService.publishDeviceCharacteristicEvent(frame);
            } else if (cyclicId === cmMessageFakeTypes.BATTERY_STATUS) {
                cmMessageRequestService.publishBatteryCharacteristicEvent(frame);
            }
        }
    } else if (!isCmResponse) {
        mwIotMessageRequestService.publishResponseEvent(data);
    }
};

const publishErrorEvent = (data) => {
    const isGamBackend = backendService.isGamBackend();
    const isUamBackend = backendService.isUamBackend();

    if (isGamBackend || isUamBackend) return;

    mwIotMessageRequestService.publishErrorEvent(data);
};

export default {
    onFwuErrorHandler,
    onPackageLoaded,
    onFwuStatusEvent,
    publishBatteryCharacteristicEvent,
    publishDeviceCharacteristicEvent,
    publishErrorEvent,
    publishFwuFinishEvent,
    publishFwuFinishAfterRebootEvent,
    publishResponseEvent,
};
