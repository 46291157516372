import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import BackButton from '../../../../components/BackButton/BackButton';
import Container from '../../../../components/Layout/Container/Container';
import * as tncTypes from '../../../../consts/tnc/tncTypes';
import {makeSelectAcceptedTncByType, makeSelectTncByType} from '../../../../state/selectors/consumer';
import elementsTesIds from '../../../../test/consts/elementsTesIds';
import PreferenceCenter from './PreferenceCenter';
import styles from './PreferenceCenter.module.scss';

const mapStateToProps = createStructuredSelector({
    dataCollectionTnc: makeSelectTncByType(tncTypes.WEB_PREFERENCE_CENTER),
    acceptedTermsOfUse: makeSelectAcceptedTncByType(tncTypes.WEB_PREFERENCE_CENTER),
});

const PreferenceCenterControl = (props) => {
    const {dataCollectionTnc, acceptedTermsOfUse, onApproved, backButtonClick, backButtonAriaLabel} = props;

    let legals = dataCollectionTnc?.legals;
    let acceptedLegals = acceptedTermsOfUse?.legals;

    legals = legals ? legals : [];
    acceptedLegals = acceptedLegals
        ? acceptedLegals.filter((al) => !legals.some((l) => l.code.toLowerCase() === al.code.toLowerCase()))
        : [];

    return (
        <div className={styles.Page} data-testid={elementsTesIds.PREFERENCE_CENTER_PAGE}>
            <Container isFloatHeight>
                <BackButton
                    ariaLabel={backButtonAriaLabel}
                    onClick={backButtonClick}
                    testId={elementsTesIds.PREF_CENTER_BTN_BACK}
                />
            </Container>
            <PreferenceCenter legals={legals} acceptedLegals={acceptedLegals} onApproved={onApproved} />
        </div>
    );
};

export default connect(mapStateToProps)(PreferenceCenterControl);
