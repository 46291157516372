import React from 'react';

import Icon from './Icon';

const IconAdvancedFeatures = (props) => {
    return (
        <Icon width='116' height='120' viewBox='0 0 116 120' {...props}>
            <g transform='translate(0.000000,120.000000) scale(0.100000,-0.100000)' fill='#383839' stroke='none'>
                <path
                    d='M603 1073 c-10 -3 -13 -38 -13 -124 l0 -118 -90 -63 c-110 -77 -116
-98 -39 -123 l51 -17 -226 -227 c-124 -124 -226 -234 -226 -243 0 -9 8 -19 17
-22 12 -5 79 55 259 235 l242 241 28 -13 c15 -8 31 -25 36 -39 7 -23 -6 -40
-162 -196 -93 -93 -170 -175 -170 -181 0 -7 9 -17 21 -24 19 -10 36 4 178 145
86 86 159 156 163 156 3 0 9 -16 12 -35 8 -39 30 -67 50 -59 7 3 41 45 75 94
l62 89 122 3 c101 3 122 6 125 19 1 8 -27 54 -63 102 -36 47 -65 92 -65 100 0
7 14 53 30 102 34 100 36 123 15 131 -8 3 -62 -10 -119 -29 l-105 -35 -92 69
c-51 38 -95 69 -98 68 -3 0 -12 -3 -18 -6z m183 -181 c17 -9 37 -6 98 14 43
15 80 24 83 21 3 -3 -4 -34 -16 -68 -30 -91 -28 -107 29 -183 l51 -66 -90 0
c-102 0 -99 2 -163 -94 l-42 -61 -26 82 c-14 45 -30 87 -36 93 -5 5 -47 23
-93 38 l-82 28 75 52 76 53 0 95 0 95 58 -44 c31 -24 67 -49 78 -55z'
                />
            </g>
        </Icon>
    );
};

export default IconAdvancedFeatures;
