import cn from 'classnames';
import React from 'react';
import {connect} from 'react-redux';
import {Route} from 'react-router-dom';
import {createStructuredSelector} from 'reselect';

import appConfig from '../../config/appConfig';
import domClassNames from '../../consts/app/domClassNames';
import useFwuBackgroundCheck from '../../hooks/fwu/useFwuBackgroundCheck';
import useLayoutChange from '../../hooks/useLayoutChange';
import useScrollTop from '../../hooks/useScrollTop';
import backendService from '../../services/app/backendService';
import connectivityService from '../../services/device/connectivityService';
import userDeviceService from '../../services/user/userDeviceService';
import yapService from '../../services/yap/yapService';
import {makeSelectIsTncPopupVisible} from '../../state/selectors/tnc';
import Accessibility from '../Accessibility/Accessibility';
import LoaderGlobal from '../LoaderGlobal/LoaderGlobal';
import ModalsRoot from '../Modals/ModalsRoot';
import Notifications from '../Notifications/Notifications';
import ProductRegistrationStatus from '../ProductRegistrationStatus/ProductRegistrationStatus';
import ConsumerSettingsChecker from '../ServiceContainers/ConsumerSettingsChecker/ConsumerSettingsChecker';
import GamPairingChecker from '../ServiceContainers/GamPairingChecker/GamPairingChecker';
import ScpCloudPairingChecker from '../ServiceContainers/ScpCloudPairingChecker/ScpCloudPairingChecker';
import UamPairingChecker from '../ServiceContainers/UamPairingChecker/UamPairingChecker';
import YapRequestStatusChecker from '../ServiceContainers/YapRequestStatusChecker/YapRequestStatusChecker';
import TermsOfUsePopup from '../TNC/TermsOfUsePopup/TermsOfUsePopup';
import styles from './AppLayout.module.scss';
import DataCollectionHandler from './components/DataCollectionHandler';
import DeviceConnectionDetector from './components/DeviceConnectionDetector';
import DeviceHolderConnectionChecker from './components/DeviceHolderConnectionChecker';
import DeviceHolderRegistrationChecker from './components/DeviceHolderRegistrationChecker';
import NotCompatibleOSPopup from './components/NotCompatibleOSPopup';
import OfflineDetector from './components/OfflineDetector';
import Logs from './Logs/Logs';

const mapStateToProps = createStructuredSelector({
    isTncPopupVisible: makeSelectIsTncPopupVisible(),
});

const AppLayout = (props) => {
    const {component: Component, path, isTncPopupVisible} = props;
    const isNotCompatibleVersion = userDeviceService.isIos_13_4_x();
    const isScpCloudBackend = backendService.isScpCloudBackend();
    const isGamBackend = backendService.isGamBackend();
    const isUamBackend = backendService.isUamBackend();
    const isYapEncryptedMode = yapService.isYapEncryptedMode();
    const isDataCollectionEnabled = appConfig.getIsDataCollectionEnabled();
    const isUam = isUamBackend || isYapEncryptedMode || isDataCollectionEnabled;
    const isTouchSupported = userDeviceService.isTouchSupported();
    const appClassName = cn(styles.App, domClassNames.APP_CLASSNAME, {['touch']: isTouchSupported});
    const componentClassName = cn(styles.ComponentWrapper, domClassNames.COMPONENTS_CONTAINER_CLASS_NAME);
    const isReconnectDeviceGuideEnabled = appConfig.getIsReconnectDeviceGuideEnabled();
    const isBle = connectivityService.isBle();
    const isVisibleDeviceConnectionDetector = isReconnectDeviceGuideEnabled && isBle;
    const isScpCloudEnabled = isUamBackend || isScpCloudBackend;

    useScrollTop();
    useLayoutChange();
    useFwuBackgroundCheck();

    return (
        <Route
            path={path}
            render={(matchProps) => (
                <div className={appClassName}>
                    <Accessibility />
                    <Notifications />
                    {isTncPopupVisible && <TermsOfUsePopup matchProps={matchProps} />}
                    <div className={componentClassName}>
                        <Component {...matchProps} />
                    </div>
                    <ModalsRoot />
                    <LoaderGlobal />
                    {isNotCompatibleVersion ? (
                        <NotCompatibleOSPopup />
                    ) : (
                        <>
                            <ProductRegistrationStatus />
                            <ConsumerSettingsChecker />
                        </>
                    )}
                    {(appConfig.getIsLogsVisible() || appConfig.getIsAppVersionVisible()) && <Logs />}
                    <OfflineDetector />
                    {isVisibleDeviceConnectionDetector && <DeviceConnectionDetector />}
                    {isGamBackend && <GamPairingChecker />}
                    {isUam && <UamPairingChecker />}
                    {isScpCloudEnabled && <ScpCloudPairingChecker />}
                    {(isGamBackend || isYapEncryptedMode) && <YapRequestStatusChecker />}
                    <DataCollectionHandler />
                    <DeviceHolderRegistrationChecker />
                    <DeviceHolderConnectionChecker />
                </div>
            )}
        />
    );
};

export default connect(mapStateToProps)(AppLayout);
