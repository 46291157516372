import cn from 'classnames';
import React, {FC} from 'react';

import Button from '../Button';
import {IButton} from '../ButtonTypes';
import styles from './ButtonText.module.scss';

const ButtonText: FC<IButton> = ({className, ...props}) => (
    <Button {...props} className={cn(styles.ButtonText, className)} />
);

export default ButtonText;
