import {getDeviceTypesData} from '../../consts/device/deviceTypesData';
import {IDeviceDataTypeOptions} from '../../consts/device/deviceTypesData';
import DEVICE_TYPES from '../../enums/device/deviceTypes';

type TGetDeviceModelByType = (type: string) => string | undefined;
type TIsEqualPlatform = (type: DEVICE_TYPES) => boolean | undefined;
type TGetProductNameByType = (type?: string) => string | undefined;
type TIsEqualType = (type: string) => boolean | undefined;
type TGetDevicePlatform = (deviceType: string) => string | null;

const getDeviceTypesDataByType = (type: string): IDeviceDataTypeOptions | null => {
    if (!type) return null;

    const result = getDeviceTypesData()[type as DEVICE_TYPES];

    return result ? result : null;
};

const getDeviceModelByType: TGetDeviceModelByType = (type) => getDeviceTypesDataByType(type)?.deviceModel;
const getProductNameByType: TGetProductNameByType = (type) => (type ? getDeviceTypesDataByType(type)?.name : undefined);
const isCortez: TIsEqualType = (type) =>
    getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_P1V30_CHARGER;
const isCosmos: TIsEqualType = (type) =>
    getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_P1V40C_CHARGER;
const isOrdinary: TIsEqualType = (type) =>
    getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_V24P_CHARGER;
const isP1: TIsEqualPlatform = (type) => getDeviceTypesDataByType(type)?.type === DEVICE_TYPES.DEV_TYPE_P1;
const isP4: TIsEqualPlatform = (type) => getDeviceTypesDataByType(type)?.type === DEVICE_TYPES.DEV_TYPE_P4;
const isP1V4: TIsEqualPlatform = (type) => getDeviceTypesDataByType(type)?.type === DEVICE_TYPES.DEV_TYPE_P1_V4;
const isSoyuz: TIsEqualType = (type) =>
    getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER;
const isVespucci: TIsEqualType = (type) => getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_P1V30M;
const isVoyager: TIsEqualType = (type) =>
    getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_P1V40V_CHARGER;
const isDelta: TIsEqualType = (type) =>
    getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_P1V40D_CONTROL;
const isMono: TIsEqualType = (type) => getDeviceTypesDataByType(type)?.isMono;
const isIluma: TIsEqualType = (type) => isCosmos(type) || isVoyager(type) || isDelta(type);

const getDevicePlatform: TGetDevicePlatform = (deviceType) => {
    const type = getDeviceTypesDataByType(deviceType)?.type;

    if (!type) return null;

    switch (type) {
        case DEVICE_TYPES.DEV_TYPE_P1:
            return DEVICE_TYPES.DEV_TYPE_P1;
        case DEVICE_TYPES.DEV_TYPE_P4:
            return DEVICE_TYPES.DEV_TYPE_P4;
        case DEVICE_TYPES.DEV_TYPE_P1_V4:
            return DEVICE_TYPES.DEV_TYPE_P1_V4;
        default:
            return null;
    }
};

export default {
    getDeviceModelByType,
    getDevicePlatform,
    getProductNameByType,
    isCortez,
    isCosmos,
    isDelta,
    isMono,
    isOrdinary,
    isP1,
    isP1V4,
    isP4,
    isSoyuz,
    isVespucci,
    isVoyager,
    getDeviceTypesDataByType,
    isIluma,
};
