import cn from 'classnames';
import React, {FC} from 'react';

import {ButtonDark} from '../../../../../components/Button';
import Popup from '../../../../../components/Popup/Popup';
import {IPurchaseLaterPopup} from '../../PurchaseDateFullPagePopupTypes';
import styles from './PurchaseLaterPopup.module.scss';

const PurchaseLaterPopup: FC<IPurchaseLaterPopup> = (props) => {
    const {onClose, title, description, buttonText, onCancelClick, testId} = props;

    return (
        <Popup onClose={onClose} className={styles.PurchaseLaterPopup}>
            <h2 className={styles.PurchaseLaterTitle}>{title}</h2>
            <div className={cn(styles.PurchaseLaterDescription, 'ica--txt-regular')}>{description}</div>
            <ButtonDark className={styles.PurchaseLaterButton} showArrow onClick={onCancelClick} testId={testId}>
                {buttonText}
            </ButtonDark>
        </Popup>
    );
};

export default PurchaseLaterPopup;
