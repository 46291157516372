import cn from 'classnames';
import React from 'react';

import elementsTesIds from '../../../../test/consts/elementsTesIds';
import FloatButton from '../../../Button/FloatButton/FloatButton';
import styles from './PopupCloseButton.module.scss';

const PopupCloseButton = (props) => {
    const {onClick, ariaLabel, className} = props;
    const btnClassName = cn(styles.CloseButton, className);

    return (
        <FloatButton
            ariaLabel={ariaLabel}
            className={btnClassName}
            onClick={onClick}
            testId={elementsTesIds.POPUP_COMMON_BTN_CLOSE}
        />
    );
};

export default PopupCloseButton;
