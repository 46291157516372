import * as httpErrorsTypes from '../../consts/app/httpErrorsTypes';

type TIsRequestValid = (status?: number) => boolean;

const isNotAuthorized: TIsRequestValid = (status) => status === httpErrorsTypes.NOT_AUTHORIZED;
const isBadRequest: TIsRequestValid = (status) => status === httpErrorsTypes.BAD_REQUEST;

export default {
    isNotAuthorized,
    isBadRequest,
};
