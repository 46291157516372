import * as localizationKeys from '../../consts/localization/localizationKeys';
import {setNotificationInfo} from '../../state/ducks/global';
import {dispatch} from '../../state/store';
import helpers from '../../utils/helpers';
import backendService from '../app/backendService';
import fwuService from '../ble/fwuService';
import connectivityService from '../device/connectivityService';
import {getLocalizedStrings} from '../localization/localizationService';

let vibrationAlarmTimeout;

const setVibrationAlarmTimeout = (deviceAlarmDuration, callback) => {
    vibrationAlarmTimeout = setTimeout(() => helpers.runFunction(callback), deviceAlarmDuration);
};

const clearVibrationAlarmTimeout = () => clearTimeout(vibrationAlarmTimeout);

const isSettingAvailable = (iotDevice, isGlobalSupported, isSupported, isNeedHolderCheck = true) => {
    if (isSupported && isGlobalSupported) return true;

    if (!isGlobalSupported) return false;

    const {device_global_features_config, device, holder} = iotDevice || {};

    if (!device_global_features_config || !device) return false;

    if (isNeedHolderCheck && device_global_features_config.is_holder_check) {
        if (!device?.isHolderConnected || !holder?.type) {
            return true;
        }
    }
    const isFWUAvailable = fwuService.isFWUAvailable(device.type);

    return isFWUAvailable;
};

const checkSettingAvailable = (iotDevice, is_supported) => {
    const {device_global_features_config, device, holder} = iotDevice;
    const isBle = connectivityService.isBle();

    if (!device_global_features_config) return false;

    const localizedStrings = getLocalizedStrings();

    if (device_global_features_config.is_holder_check) {
        if (device?.isHolderConnected && holder?.type) {
            if (!is_supported) {
                dispatch(
                    setNotificationInfo(
                        localizedStrings[localizationKeys.DEVICE_SETTINGS_UPDATE_HOLDER_NOTIFICATION_TEXT]
                    )
                );
                return false;
            }
        } else {
            dispatch(
                setNotificationInfo(
                    localizedStrings[
                        isBle
                            ? localizationKeys.DEVICE_SETTINGS_INSERT_HOLDER_NOTIFICATION_TEXT
                            : localizationKeys.USB_DEVICE_SETTINGS_INSERT_HOLDER_NOTIFICATION_TEXT
                    ]
                )
            );
            return false;
        }
    } else if (!is_supported) {
        dispatch(
            setNotificationInfo(localizedStrings[localizationKeys.DEVICE_SETTINGS_UPDATE_DEVICE_NOTIFICATION_TEXT])
        );
        return false;
    }
    return true;
};

const checkAdvancedSettingAvailable = (iotDevice, is_suported) => {
    const {device_global_features_config, device} = iotDevice;
    const isBle = connectivityService.isBle();

    if (!device_global_features_config) return false;

    const localizedStrings = getLocalizedStrings();

    if (device?.isHolderConnected) {
        if (!is_suported) {
            dispatch(
                setNotificationInfo(localizedStrings[localizationKeys.DEVICE_SETTINGS_UPDATE_HOLDER_NOTIFICATION_TEXT])
            );
            return false;
        }
        return true;
    } else {
        dispatch(
            setNotificationInfo(
                localizedStrings[
                    isBle
                        ? localizationKeys.DEVICE_SETTINGS_INSERT_HOLDER_NOTIFICATION_TEXT
                        : localizationKeys.USB_DEVICE_SETTINGS_INSERT_HOLDER_NOTIFICATION_TEXT
                ]
            )
        );
        return false;
    }
};

const isHolderConnectedAndInSBLMode = ({device, holder}) => device?.isHolderConnected && isHolderInSBLMode({holder});

const isHolderInSBLMode = ({holder}) => {
    const isUamBackend = backendService.isUamBackend();

    if (isUamBackend) {
        return holder?.is_device_in_sbl_mode;
    }

    return holder?.is_holder_manageable !== undefined && !holder?.is_holder_manageable;
};

const isHolderEngineAvailable = ({holder_engine}) => !!holder_engine;

const isHolderEngineInSBLMode = ({holder_engine}) => holder_engine?.is_device_in_sbl_mode;

export default {
    checkAdvancedSettingAvailable,
    checkSettingAvailable,
    clearVibrationAlarmTimeout,
    isHolderConnectedAndInSBLMode,
    isHolderEngineAvailable,
    isHolderEngineInSBLMode,
    isHolderInSBLMode,
    isSettingAvailable,
    setVibrationAlarmTimeout,
};
