import DisconnectEventEmitter from '../events/disconnectEventEmitter';

//TODO: replace with p-cancellable
export default class ConnectedExponentialBackOff {
    constructor() {
        this.isEnabled = true;
        this.timeout = undefined;
        this.subscribe();
    }

    subscribe() {
        const disconnectEventEmitter = new DisconnectEventEmitter();

        disconnectEventEmitter.onDeviceDisconnect(this.disable);
    }

    unsubscribe() {
        const disconnectEventEmitter = new DisconnectEventEmitter();

        disconnectEventEmitter.offDeviceDisconnect(this.disable);
    }

    run(max, delay, toTry, success, fail) {
        if (this.isEnabled) {
            toTry()
                .then((result) => {
                    this.unsubscribe();
                    success(result);
                })
                .catch((error) => {
                    if (max <= 0) {
                        this.unsubscribe();
                        fail(error);
                    } else {
                        if (this.isEnabled) {
                            this.timeout = setTimeout(() => {
                                this.run(--max, delay * 2, toTry, success, fail);
                            }, delay);
                        } else {
                            this.unsubscribe();
                        }
                    }
                });
        } else {
            this.unsubscribe();
        }
    }

    disable() {
        clearTimeout(this.timeout);
        this.isEnabled = false;
    }
}
