import cn from 'classnames';
import React from 'react';

import {Button, ButtonDark} from '../../../components/Button';
import PopupWarning from '../../../components/Popup/PopupWarning';
import styles from './UpdateFWPopup.module.scss';

const UpdateFWPopupTemplate = (props) => {
    const {
        onClose,
        title,
        descriptionPart1,
        descriptionPart2,
        secondaryBtnText,
        secondaryBtnAction,
        primaryBtnText,
        primaryBtnAction,
        popupIconAlt,
        ariaLabelClose,
        ariaLabelCancel,
        primaryBtnTestId,
        secondaryBtnTestId,
    } = props;

    return (
        <PopupWarning
            closeButtonAriaLabel={ariaLabelClose}
            alt={popupIconAlt}
            onClose={onClose}
            className={styles.UpdateFWPopup}
        >
            <h2 data-testid='UpdateFWPopupTemplateHeader'>{title}</h2>
            <div className={cn(styles.UpdateFWPopupDescription, 'ica--txt-regular')}>
                {descriptionPart1}
                <b>{descriptionPart2}</b>
            </div>
            <ButtonDark showArrow onClick={secondaryBtnAction} testId={secondaryBtnTestId}>
                {secondaryBtnText}
            </ButtonDark>
            {primaryBtnText && (
                <Button ariaLabel={ariaLabelCancel} showUnderline onClick={primaryBtnAction} testId={primaryBtnTestId}>
                    {primaryBtnText}
                </Button>
            )}
        </PopupWarning>
    );
};

export default UpdateFWPopupTemplate;
