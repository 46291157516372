import {config} from '../../config';
import {TConfigGetterReturn} from '../types';
import {TDeviceGetFeatures} from './types';

const getSupportedDeviceFeatureByType = (deviceType: string) =>
    (config.data.promo.tryFeature.supportedDeviceFeatures as any)[deviceType];

export default {
    getIsTryFeatureEnabled: (): TConfigGetterReturn<boolean> => config.data.promo.tryFeature.isTryFeatureEnabled,
    getIsGetFeatureEnabled: (): TConfigGetterReturn<boolean> => config.data.promo?.getFeature?.isGetFeatureEnabled,
    getTryFeatureAvailabilityDays: (): TConfigGetterReturn<number> =>
        config.data.promo.tryFeature.tryFeatureAvailabilityDays,
    getIsCleaningModeTryFeatureEnabled: (deviceType: string): TConfigGetterReturn<boolean> =>
        getSupportedDeviceFeatureByType(deviceType).isCleaningModeEnabled,
    getIsVibrateModeTryFeatureEnabled: (deviceType: string): TConfigGetterReturn<boolean> =>
        getSupportedDeviceFeatureByType(deviceType).isVibrateModeEnabled,
    getIsResponsiveDrawModeTryFeatureEnabled: (deviceType: string): TConfigGetterReturn<boolean> =>
        getSupportedDeviceFeatureByType(deviceType).isResponsiveDrawModeEnabled,
    getIsVapeCloudSizeModeTryFeatureEnabled: (deviceType: string): TConfigGetterReturn<boolean> =>
        getSupportedDeviceFeatureByType(deviceType).isVapeCloudSizeModeEnabled,
    getIsLedModeTryFeatureEnabled: (deviceType: string): TConfigGetterReturn<boolean> =>
        getSupportedDeviceFeatureByType(deviceType).isLedModeEnabled,
    getIsDeviceDiagnosticTryFeatureEnabled: (deviceType: string): TConfigGetterReturn<boolean> =>
        getSupportedDeviceFeatureByType(deviceType).isDeviceDiagnosticEnabled,
    getIsAutoStartModeTryFeatureEnabled: (deviceType: string): TConfigGetterReturn<boolean> =>
        getSupportedDeviceFeatureByType(deviceType).isAutoStartModeEnabled,
    getIsGestureModeTryFeatureEnabled: (deviceType: string): TConfigGetterReturn<boolean> =>
        getSupportedDeviceFeatureByType(deviceType).isGestureModeEnabled,
    getIsCountdownModeTryFeatureEnabled: (deviceType: string): TConfigGetterReturn<boolean> =>
        getSupportedDeviceFeatureByType(deviceType).isCountdownModeEnabled,
    getDeviceGetFeatures: (deviceType: string): TConfigGetterReturn<TDeviceGetFeatures> => {
        const {supportedDeviceFeatures} = config.data.promo.getFeature || {};

        return supportedDeviceFeatures ? (supportedDeviceFeatures as any)[deviceType] : undefined;
    },
};
