import yapCheckStatusService from '../yap/yapCheckStatusService';
import YapDeviceWebSocketClient from '../yap/yapDeviceWebSocketClient';
import YapHolderWebSocketClient from '../yap/yapHolderWebSocketClient';
import uamClientService from './uamClientService';
import uamWebSocketResponseService from './uamWebSocketResponseService';

let uamDeviceReconnectTimeout;
let uamHolderReconnectTimeout;
const clearReconnectTimeout = (isHolder) =>
    clearTimeout(isHolder ? uamHolderReconnectTimeout : uamDeviceReconnectTimeout);

let isUamSocketClosed = true;

const getYapWebSocketClient = (isHolder) => (assetId, createNew, options) => {
    const Client = isHolder ? YapHolderWebSocketClient : YapDeviceWebSocketClient;

    return new Client(assetId, createNew, options);
};

const initUamWebSocket = async (assetId, isNewConnection = true, isHolder) => {
    if (!isNewConnection && isUamSocketClosed) {
        return;
    }

    isUamSocketClosed = false;

    clearReconnectTimeout(isHolder);

    if (isUamWebSocketConnected(assetId, isHolder)) {
        return;
    }

    const onError = () => {
        if (!isNewConnection && isUamSocketClosed) {
            return;
        }

        yapCheckStatusService.createCheckStatusScheduler(assetId, isHolder);

        const RECONNECT_TIMEOUT_MS = 5000;

        disconnectUamWebSocket(false, !!isHolder);

        const timeout = setTimeout(() => initUamWebSocket(assetId, false, isHolder), RECONNECT_TIMEOUT_MS);

        if (isHolder) {
            uamHolderReconnectTimeout = timeout;
        } else {
            uamDeviceReconnectTimeout = timeout;
        }
    };

    const uamTopicSubscribeSettings = await uamClientService.assetSubscribe(assetId);

    if (uamTopicSubscribeSettings) {
        if (!isNewConnection && isUamSocketClosed) {
            return;
        }

        const uamClient = getYapWebSocketClient(isHolder)(assetId, true, uamTopicSubscribeSettings);

        uamClient.attachCloseHandler(async (message) => {
            //normal close from code
            if (message?.code !== 1000) {
                onError();
            }
        });

        uamClient.attachConnectHandler(() => {
            yapCheckStatusService.setIsOneMoreCheckStatusNeeded(true);
            subscribeOnMessage(isHolder);
        });
    } else {
        onError();
    }
};

const disconnectUamWebSocket = (isForceDisconnect = true, isHolder) => {
    if (isForceDisconnect) {
        isUamSocketClosed = true;
        yapCheckStatusService.stopCheckStatusScheduler();
    }

    clearReconnectTimeout(isHolder);

    let yapWebSocketClient;

    if (isHolder === undefined) {
        yapWebSocketClient = new YapDeviceWebSocketClient();
        yapWebSocketClient.disconnectClient();
        yapWebSocketClient = new YapHolderWebSocketClient();
        yapWebSocketClient.disconnectClient();
    } else {
        yapWebSocketClient = getYapWebSocketClient(isHolder)();
        yapWebSocketClient.disconnectClient();
    }
};

const subscribeOnMessage = (isHolder) => {
    getYapWebSocketClient(isHolder)().attachMessageHandler((message) => {
        uamWebSocketResponseService.proceedMessage(message);
    });
};

const isUamWebSocketConnected = (assetId, isHolder) => getYapWebSocketClient(isHolder)().isConnectedCheck(assetId);

export default {
    initUamWebSocket,
    disconnectUamWebSocket,
    isUamWebSocketConnected,
};
