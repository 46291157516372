import * as logMessageTypes from '../../consts/app/logMessageTypes';
import consoleLog from './consoleLogger';
import SmartLogger from './smartLogger';

const log = {
    setLevel: (consoleLogLevel, awsLoggerLevel) => {
        const smartLogger = new SmartLogger();

        consoleLog.setLevel(consoleLogLevel);
        smartLogger.setLevel(awsLoggerLevel);
    },
    debug: (msg, logMessageType = logMessageTypes.INTERNAL) => {
        const smartLogger = new SmartLogger();

        consoleLog.debug(msg);
        smartLogger.debug(msg, logMessageType);
    },
    info: (msg, logMessageType = logMessageTypes.INTERNAL) => {
        const smartLogger = new SmartLogger();

        consoleLog.info(msg);
        smartLogger.info(msg, logMessageType);
    },
    error: (msg, logMessageType = logMessageTypes.INTERNAL) => {
        const smartLogger = new SmartLogger();

        consoleLog.error(msg);
        smartLogger.error(msg, logMessageType);
    },
    fatal: (msg, logMessageType = logMessageTypes.INTERNAL) => {
        const smartLogger = new SmartLogger();

        consoleLog.error(msg);
        smartLogger.fatal(msg, logMessageType);
    },
    resetLogger: () => {
        const smartLogger = new SmartLogger();

        smartLogger.resetLogger();
    },
};

export default log;
