import * as firmwareErrorCodes from '../../consts/iot/firmwareErrorCodes';
import helpers from '../../utils/helpers';
import BaseFwuClient from '../device/baseFwuClient';
import log from '../logger/log';
import DeviceFwuCharacteristicClient from './deviceFwuCharacteristicClient';

const FWU_MAX_RETRY_COUNT = 3;
let instance = null;

export default class DeviceFwuClient extends BaseFwuClient {
    constructor(options) {
        if (options && instance) {
            instance.stop();
            instance = null;
        }

        if (instance) {
            return instance;
        }
        if (!options) {
            throw new Error('DeviceFwuClient is not initialized');
        }

        super({fwuPackageData: options.fwuPackageData, fwu_target: options.fwu_target});
        instance = this;
        this.currentFwuTry = 0;
        this.deviceFwuCharacteristicClient = undefined;
        this.onFwuError = options?.onFwuError;
        this.onFwuStatusEvent = options?.onFwuStatusEvent;
        this.onFwuFinish = options?.onFwuFinish;
    }

    stop = () => {
        if (this.deviceFwuCharacteristicClient) {
            this.deviceFwuCharacteristicClient.stop();
            this.deviceFwuCharacteristicClient = undefined;
        }
    };

    startFwUpdate = async () => {
        this.setStartTs();

        this.deviceFwuCharacteristicClient = new DeviceFwuCharacteristicClient({
            fwuPackageData: this.fwuPackageData,
            canRetry: this.currentFwuTry <= FWU_MAX_RETRY_COUNT,
            onFwuStatusEvent: this.onFwuStatusEvent,
            onUpgradeProcessing: () => {
                this.publishFwuFinish();
            },
            retryToUpdateFW: async () => {
                log.debug(`DeviceFwuClient: try to start FWU again: try #${this.currentFwuTry}`);
                this.currentFwuTry = this.currentFwuTry + 1;
                await helpers.timeout(500);
                await this.startFwUpdate();
            },
            onErrorWithoutRetry: ({lastRequestFrames, lastResponseFrames}) => {
                this.publishFwuError({errorCode: firmwareErrorCodes.GENERAL, lastRequestFrames, lastResponseFrames});
            },
        });
    };
}
