import tutorialTypes from '../../consts/app/tutorialTypes';
import * as routePaths from '../../consts/route/routePaths';
import * as routeValues from '../../consts/route/routeValues';
import urlUtils from '../../utils/urlUtils';
import routerService from '../routerService';

const forwardToAboutDevicePage = () => routerService.forwardTo(routePaths.ABOUT_DEVICE);
const forwardToAgeGatePage = () => routerService.forwardTo(routePaths.AGE_GATE);
const forwardToAgeVerificationFailedPage = () => routerService.forwardTo(routePaths.AGE_VERIFICATION_FAILED);
const forwardToAppIntroPage = () => routerService.forwardTo(routePaths.APP_INTRO);
const forwardToBrowserVerificationPage = () => routerService.forwardTo(routePaths.BROWSER_VERIFICATION);
const forwardToDeviceActivationPage = () => routerService.forwardTo(routePaths.ACTIVATION);
const forwardToDeviceSyncPage = () => routerService.forwardTo(routePaths.SYNC);
const forwardToDeviceRegistrationPage = () => routerService.forwardTo(routePaths.REGISTRATION);
const forwardToDeviceSettingsPage = () => routerService.forwardTo(routePaths.DEVICE_SETTINGS);
const forwardToLoginPage = () => routerService.forwardTo(routePaths.LOGIN);
const forwardToMyDevicesPage = () => routerService.forwardTo(routePaths.MY_DEVICES);
const forwardToNoDevicesPage = () => routerService.forwardTo(routePaths.NO_DEVICES);
const forwardToErrorHandlingPage = (errorType) =>
    routerService.forwardTo(urlUtils.join(routePaths.ERROR_PAGE, errorType));
const forwardToNotCompatibleBrowserPage = () => routerService.forwardTo(routePaths.NOT_COMPATIBLE_BROWSER);
const forwardToPairingAlertPage = () => routerService.forwardTo(routePaths.PAIRING_ALERT);
const forwardToPairingGuidePage = ({deviceType, isNew, redirectToAfterConnect = ''}) =>
    routerService.forwardTo(
        urlUtils.join(
            routePaths.PAIRING_GUIDE,
            deviceType,
            isNew ? routeValues.BLE_CONNECTION_NEW : redirectToAfterConnect ? '_' : '',
            redirectToAfterConnect
        )
    );
const forwardToPairingSuccessPage = () => routerService.forwardTo(routePaths.PAIRING_SUCCESS);
const forwardToQuickStartGuidePage = () => forwardToTutorialPage(tutorialTypes.TUTORIAL_TYPE_QUICK_START_GUIDE);
const forwardToRoot = () => routerService.forwardTo(routePaths.ROOT);
const forwardToSelectDevice = () => routerService.replace(routePaths.SELECT_DEVICE);
const forwardToSelectMarketPage = () => routerService.forwardTo(routePaths.SELECT_MARKET);
const forwardToTipsAndTricksPage = () => routerService.forwardTo(routePaths.TIPS_AND_TRICKS);
const forwardToTNCDataCollectionPage = () => routerService.forwardTo(routePaths.TNC_DATA_COLLECTION);
const forwardToTNCPreferenceCenterPage = () => routerService.forwardTo(routePaths.TNC_DATA_PREFERENCE);
const forwardToTNCPreferenceCenterSettingsPage = () => routerService.forwardTo(routePaths.TNC_DATA_PREFERENCE_SETTINGS);
const forwardToTNCTermsOfUsePage = () => routerService.forwardTo(routePaths.TNC_TERMS_OF_USE);
const forwardToTutorialIntroPage = (tutorialType) =>
    routerService.forwardTo(urlUtils.join(routePaths.TUTORIAL_INTRO, tutorialType));
const forwardToTutorialList = () => routerService.forwardTo(routePaths.TUTORIAL_LIST);
const forwardToTutorialPage = (tutorialType) =>
    routerService.forwardTo(urlUtils.join(routePaths.TUTORIAL, tutorialType));
const forwardToUpdateFWPage = (fwu_target, redirectUrl = '') =>
    routerService.forwardTo(urlUtils.join(routePaths.UPDATE_FW, fwu_target, redirectUrl));
const forwardToWelcomeUserPage = () => routerService.forwardTo(routePaths.WELCOME_USER);
const forwardToLegalDisclaimerPage = () => routerService.forwardTo(routePaths.LEGAL_DISCLAIMER);
const forwardToBenefitPage = () => routerService.forwardTo(routePaths.BENEFIT_PAGE);

export default {
    forwardToAboutDevicePage,
    forwardToAgeGatePage,
    forwardToAgeVerificationFailedPage,
    forwardToAppIntroPage,
    forwardToBenefitPage,
    forwardToBrowserVerificationPage,
    forwardToDeviceActivationPage,
    forwardToDeviceSyncPage,
    forwardToDeviceRegistrationPage,
    forwardToDeviceSettingsPage,
    forwardToErrorHandlingPage,
    forwardToLegalDisclaimerPage,
    forwardToLoginPage,
    forwardToMyDevicesPage,
    forwardToNoDevicesPage,
    forwardToNotCompatibleBrowserPage,
    forwardToPairingAlertPage,
    forwardToPairingGuidePage,
    forwardToPairingSuccessPage,
    forwardToQuickStartGuidePage,
    forwardToRoot,
    forwardToSelectDevice,
    forwardToSelectMarketPage,
    forwardToTipsAndTricksPage,
    forwardToTNCDataCollectionPage,
    forwardToTNCPreferenceCenterPage,
    forwardToTNCPreferenceCenterSettingsPage,
    forwardToTNCTermsOfUsePage,
    forwardToTutorialIntroPage,
    forwardToTutorialList,
    forwardToTutorialPage,
    forwardToUpdateFWPage,
    forwardToWelcomeUserPage,
};
