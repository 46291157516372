import React from 'react';

import Icon from './Icon';

const IconClean = (props) => {
    return (
        <Icon width='38' height='38' viewBox='0 0 38 38' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g strokeWidth='1.708'>
                    <path
                        strokeLinecap='round'
                        d='M16.129 4.188c-.398-.532-1.237-1.607-2.516-3.227-.579-.733-1.642-.858-2.375-.279-.102.08-.194.173-.275.275C3.654 10.147 0 16.842 0 21.04c0 6.67 5.506 12.078 12.298 12.078 4.95 0 9.216-2.872 11.166-7.012'
                        transform='translate(5.636 2.579)'
                    />
                    <path
                        d='M22.059 21.282c3.268 0 5.918-2.704 5.918-6.039 0-1.924-1.477-4.897-4.432-8.917h0c-.603-.821-1.758-.998-2.578-.394-.151.11-.284.243-.395.394-2.954 4.02-4.431 6.993-4.431 8.917 0 3.335 2.65 6.039 5.918 6.039z'
                        transform='translate(5.636 2.579)'
                    />
                </g>
            </g>
        </Icon>
    );
};

export default IconClean;
