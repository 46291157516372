export const ADD_SIGNED_MEDIA = 'app/media/ADD_SIGNED_MEDIA';

export default function reducer(
    state = {
        signedMediaList: [],
    },
    action
) {
    switch (action.type) {
        case ADD_SIGNED_MEDIA: {
            const {data} = action.payload;
            const signedMediaListFromState = state.signedMediaList;
            const filteredSignedMediaListFromState = signedMediaListFromState.filter(
                ({mediaId}) => !data.some((item) => mediaId === item.mediaId)
            );

            return {
                ...state,
                signedMediaList: [...filteredSignedMediaListFromState, ...data],
            };
        }

        default: {
            return state;
        }
    }
}

export const addSignedMedia = (data) => ({type: ADD_SIGNED_MEDIA, payload: {data}});
