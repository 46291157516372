import React from 'react';

import ToolColorIcon from './ToolColorIcon';

const ToolColorGreyscaleIcon = (props) => {
    return (
        <ToolColorIcon {...props}>
            <rect fill='#D1D1D1' x='0' y='0' width='6' height='16' />
            <rect fill='#969696' x='6' y='0' width='6' height='16' />
            <rect fill='#363636' x='12' y='0' width='6' height='16' />
        </ToolColorIcon>
    );
};

export default ToolColorGreyscaleIcon;
