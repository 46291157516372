import cn from 'classnames';
import React, {useEffect} from 'react';

import * as localizationKeys from '../../../consts/localization/localizationKeys';
import useListenBluetoothAvailabilityChange from '../../../hooks/useListenBluetoothAvailabilityChange';
import appIntroService from '../../../services/app/appIntroService';
import iccMarketService from '../../../services/icc/iccMarketService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import marketConfigService from '../../../services/marketConfigService';
import appRouterService from '../../../services/route/appRouterService';
import userBrowserService from '../../../services/user/userBrowserService';
import userDeviceService from '../../../services/user/userDeviceService';
import elementsTesIds from '../../../test/consts/elementsTesIds';
import loadableRoutes from '../../Routes/loadableRoutes';
import browserCompatibilityService from '../browserCompatibilityService';
import * as styles from './BrowserVerificationPage.module.scss';
import PulseLogo from './PulseLogo';

const BrowserVerificationPage = () => {
    const localizedStrings = getLocalizedStrings();
    let timer;

    useEffect(() => {
        verifyBrowser();

        return () => clearTimeout(timer);
    }, []);

    const verifyBrowser = async () => {
        const isBleSupported = userDeviceService.isBluetoothSupported();
        const downloadButtonData = browserCompatibilityService.getBrowserCompatibleButtonData();
        const isDownloadButtonAvailable = !!downloadButtonData?.link;
        const isMobileIOSDevice = userDeviceService.isMobileIOSDevice();
        const isTabletIOSDevice = userDeviceService.isTabletIOSDevice();
        const isAppleTouchDevice = isMobileIOSDevice || isTabletIOSDevice;
        let shouldRedirectToNotCompatiblePage;

        if (isAppleTouchDevice) {
            shouldRedirectToNotCompatiblePage = isAppleTouchDevice && !userBrowserService.isBrowserTrusted();
        }

        const timeout = shouldRedirectToNotCompatiblePage ? 2000 : 0;

        await userDeviceService.checkBluetoothAvailability();

        const timeoutFunction = async () => {
            const forwardToNotCompatibleBrowserPage = async () => {
                await loadableRoutes.NotCompatibleBrowserPage.load();
                appRouterService.forwardToNotCompatibleBrowserPage();
            };

            if (shouldRedirectToNotCompatiblePage) {
                await forwardToNotCompatibleBrowserPage();
            } else {
                const isBleNotSupportedWithAvailableConnectApp = !isBleSupported && isDownloadButtonAvailable;
                const forwardToAppIntroPage = async () => {
                    await loadableRoutes.AppIntroPage.load();
                    appRouterService.forwardToAppIntroPage();
                };

                if (isBleSupported || isBleNotSupportedWithAvailableConnectApp) {
                    const isIntroPageVisible = appIntroService.getIsIntroPageVisible();
                    const isIccJourneyScreensEnabled = marketConfigService.isIccJourneyScreensEnabled();

                    if (isIntroPageVisible || !isIccJourneyScreensEnabled) {
                        await forwardToAppIntroPage();
                    } else {
                        if (isBleSupported) {
                            await iccMarketService.getConsumerAggregatedSettings();

                            const isTNCEnabled = marketConfigService.isTNCEnabled();

                            if (isTNCEnabled) {
                                appRouterService.forwardToTNCTermsOfUsePage();
                            } else {
                                appRouterService.forwardToMyDevicesPage();
                            }
                        } else {
                            await forwardToNotCompatibleBrowserPage();
                        }
                    }
                } else {
                    await forwardToAppIntroPage();
                }
            }
        };

        timer = setTimeout(() => {
            timeoutFunction();
        }, timeout);
    };

    useListenBluetoothAvailabilityChange();

    return (
        <div className={styles.Page}>
            <div>
                <div className={styles.PulseContainer} data-testid={elementsTesIds.LOADER_CONTROL}>
                    <div className={cn(styles.PulseCircle, styles.Circle1)} />
                    <div className={cn(styles.PulseCircle, styles.Circle2)}>
                        <PulseLogo className={styles.Logo} />
                    </div>
                </div>
            </div>
            <h1 className={styles.Title}>{localizedStrings[localizationKeys.BROWSER_VERIFICATION_TITLE]}</h1>
            <div className={styles.Text}>{localizedStrings[localizationKeys.BROWSER_VERIFICATION_TEXT]}</div>
        </div>
    );
};

export default BrowserVerificationPage;
