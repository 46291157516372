import PromiseCancelError from '../services/errors/promiseCancelError';

type TDelay = (currentAttempt: number) => number;
type TToTry = <T>(currentAttempt: number) => Promise<T>;
type TSuccess = (result: any) => void;
type TFail = (error: any) => void;

interface IProps {
    max: number;
    delay: number | TDelay;
    toTry: TToTry;
    success: TSuccess;
    fail: TFail;
}

export default class ExponentialBackOff {
    isEnabled: boolean;
    timeout?: ReturnType<typeof setTimeout>;
    currentAttempt: number;
    max: number;
    delay: TDelay;
    toTry: TToTry;
    success: TSuccess;
    fail: TFail;

    constructor(props: IProps) {
        const {max, delay, toTry, success, fail} = props;

        this.isEnabled = true;
        this.timeout = undefined;
        this.currentAttempt = 1;

        this.max = max;
        this.delay = typeof delay === 'function' ? delay : (i) => i * delay;
        this.toTry = toTry;
        this.success = success;
        this.fail = fail;
    }

    run(): void {
        if (this.isEnabled) {
            this.toTry(this.currentAttempt)
                .then((result) => this.success(result))
                .catch((error) => {
                    if (this.max <= this.currentAttempt || error instanceof PromiseCancelError) {
                        this.fail(error);
                    } else {
                        if (this.isEnabled) {
                            const timeoutDelay = this.delay(this.currentAttempt);

                            this.timeout = setTimeout(() => {
                                this.currentAttempt = this.currentAttempt + 1;

                                this.run();
                            }, timeoutDelay);
                        }
                    }
                });
        }
    }

    disable(): void {
        this.isEnabled = false;
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }
}
