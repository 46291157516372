import PCancelable from 'p-cancelable';

import appConfig from '../../config/appConfig';
import * as iotTopicTypes from '../../consts/iot/iotTopicTypes';
import {setIsPairingInProgress} from '../../state/ducks/iotDevice/actions';
import {selectMwDeviceInternalId, selectMwDeviceIsIdentified} from '../../state/selectors/mwDevice';
import {dispatch, getState} from '../../state/store';
import helpers from '../../utils/helpers';
import urlUtils from '../../utils/urlUtils';
import appInstanceService from '../app/appInstanceService';
import backendService from '../app/backendService';
import awsAuthenticationService from '../aws/awsAuthenticationService';
import IoT from '../aws/awsIot';
import framesProcessingService from '../device/framesProcessingService';
import networkStatusService from '../networkStatusService';
import mwIotMessageRequestService from './mwIotMessageRequestService';
import mwIotMessageResponseService from './mwIotMessageResponseService';
import uiIotMessageResponseService from './uiIotMessageResponseService';

let _iotReconnectTimeout;
let _getCredentialsPromise;
let _iotDusn;
let _messageQueue = [];

const clearReconnectTimeout = () => clearTimeout(_iotReconnectTimeout);

const initIoT = async (onIoTConnect) => {
    if (backendService.isGamBackend()) return;

    const onError = () => {
        const RECONNECT_TIMEOUT_MS = 5000;

        disconnectIoT();
        _iotReconnectTimeout = setTimeout(() => initIoT(onIoTConnect), RECONNECT_TIMEOUT_MS);
    };

    if (networkStatusService.isOffline()) {
        onError();
        return;
    }

    clearReconnectTimeout();

    dispatch(setIsPairingInProgress(true));

    try {
        _getCredentialsPromise = new PCancelable(async (resolve, reject) => {
            try {
                const credentials = await awsAuthenticationService.getCredentials(true);

                resolve(credentials);
            } catch (e) {
                reject(e);
            }
        });
        const credentials = await _getCredentialsPromise;

        if (credentials) {
            _iotDusn = credentials.dusn;
            IoT.initNewClient(credentials);
            IoT.attachErrorHandler(onError);

            IoT.attachConnectHandler(() => onIoTConnectHandler(onIoTConnect));
        } else {
            onError();
        }
    } catch (e) {
        if (networkStatusService.isOnline()) {
            if (!_getCredentialsPromise?.isCanceled) {
                dispatch(setIsPairingInProgress(false));
            }
        } else {
            onError();
        }
    }
};

const onIoTConnectHandler = (onConnect) => {
    const isIdentified = selectMwDeviceIsIdentified(getState());

    subscribeOnMessage();
    subscribeOnAppTopic();
    subscribeOnDeviceTopic();

    if (isIdentified) {
        processMessageQueue();
        framesProcessingService.processQueue();
        mwIotMessageRequestService.processMessageQueue();
    } else {
        clearMessageQueue();
        mwIotMessageRequestService.clearMessageQueue();
        mwIotMessageRequestService.publishInitializationEvent();
    }
    helpers.runFunction(onConnect);
};

const reInitIoT = async (onIoTConnect) => {
    disconnectIoT();
    await initIoT(onIoTConnect);
};

const disconnectIoT = () => {
    _getCredentialsPromise?.cancel();
    clearReconnectTimeout();
    IoT.disconnect();
};

const subscribeOnAppTopic = (deviceSerialNumber) => {
    const topic = getTopicName(iotTopicTypes.PREFIX_UI, iotTopicTypes.SUFFIX_OUTPUT, deviceSerialNumber);

    subscribeOnTopic(topic);
};

const subscribeOnDeviceTopic = (deviceSerialNumber) => {
    const topic = getTopicName(iotTopicTypes.PREFIX_MW, iotTopicTypes.SUFFIX_OUTPUT, deviceSerialNumber);

    subscribeOnTopic(topic);
};

const subscribeOnTopic = (topic) => {
    IoT.subscribe(topic);
};

const unsubscribeFromTopic = (topic) => {
    IoT.unsubscribe(topic);
};

const unsubscribeFromDeviceTopic = () => {
    const topic = getTopicName(iotTopicTypes.PREFIX_MW, iotTopicTypes.SUFFIX_OUTPUT);

    if (topic) {
        unsubscribeFromTopic(topic);
    }
};

const DEVICE_TOPIC_PREFIX_INDEX = 1;

const subscribeOnMessage = () => {
    IoT.attachMessageHandler((topic, message) => {
        const topicSplitted = topic.split('/');
        const messageObj = JSON.parse(message.toString());
        const isMwTopic = topicSplitted[DEVICE_TOPIC_PREFIX_INDEX] === iotTopicTypes.PREFIX_MW;

        if (isMwTopic) {
            mwIotMessageResponseService.onIotMessage(topic, messageObj);
        } else {
            uiIotMessageResponseService.onMessage(topic, messageObj);
        }
    });
};

const publishUiMessage = (message) => publishMessage(message, iotTopicTypes.PREFIX_UI);

const publishMwMessage = (message) => publishMessage(message, iotTopicTypes.PREFIX_MW);

const publishMessage = (message, topicAppType) => {
    const topic = getTopicName(topicAppType, iotTopicTypes.SUFFIX_INPUT);

    if (topic) {
        const isSubscribedDusn = isTopicIncludeSubscribedDusn(topic);

        if (isSubscribedDusn) {
            return IoT.publish(topic, JSON.stringify(message));
        } else {
            _messageQueue.push({topic, message, topicAppType});
        }
    } else {
        return Promise.reject(new Error('topic is null'));
    }
};

const isTopicIncludeSubscribedDusn = (topic) => topic.includes(_iotDusn);

const processMessageQueue = () => {
    try {
        _messageQueue.forEach(({message, topic, topicAppType}) => {
            const isSubscribedDusn = isTopicIncludeSubscribedDusn(topic);

            if (isSubscribedDusn) {
                publishMessage(message, topicAppType);
            }
        });
        // eslint-disable-next-line no-empty
    } catch (e) {}
};

const clearMessageQueue = () => (_messageQueue = []);

const getInternalId = () => selectMwDeviceInternalId(getState());

const getCompoundedScpCloudTopicMarket = (customCloudTopicMarket) => {
    const scpCloudTopicMarketPrefix = appConfig.getScpCloudTopicMarketPrefix();
    const scpCloudTopicMarket = customCloudTopicMarket ? customCloudTopicMarket : appConfig.getScpCloudTopicMarket();

    if (scpCloudTopicMarketPrefix) {
        const scpCloudTopicMarketParts = [scpCloudTopicMarket, scpCloudTopicMarketPrefix];
        const compoundedScpCloudTopicMarket = scpCloudTopicMarketParts.join('_');

        return compoundedScpCloudTopicMarket;
    }

    return scpCloudTopicMarket;
};

const getTopicName = (topicAppType, topicSuffix, deviceSerialNumber) => {
    const appInstanceId = appInstanceService.getAppInstanceId();
    const topicInternalId = deviceSerialNumber || getInternalId();

    return topicInternalId
        ? urlUtils.join(
              appConfig.getScpCloudTopicPrefix(),
              topicAppType,
              getCompoundedScpCloudTopicMarket(),
              appConfig.getScpCloudVersion(),
              appInstanceId,
              topicInternalId,
              topicSuffix
          )
        : null;
};

export default {
    disconnectIoT,
    getCompoundedScpCloudTopicMarket,
    initIoT,
    publishMwMessage,
    publishUiMessage,
    reInitIoT,
    subscribeOnAppTopic,
    subscribeOnDeviceTopic,
    subscribeOnTopic,
    unsubscribeFromDeviceTopic,
    unsubscribeFromTopic,
};
