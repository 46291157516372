export const NUMBER_OF_DAYS = '_qt_ftud';
export const FREQUENCY_OF_USE = '_qt_fof';
export const USER_BROWSER = '_qt_b';
export const FEATURE_FREQUENCY_OF_USE = '_qt_ffof';
export const DW_ADVERTISEMENT_COOKIE = 'dw_Advertisement_cookie';
export const FEATURE_FREQUENCY_DETAILS = {
    DEVICE_CLEANING: 'A',
    CHANGE_VIBRATIONS: 'B',
    CHANGE_ILLUMINATION_MODE: 'C',
    LOCATE_YOUR_DEVICE: 'D',
    RENAME_YOUR_DEVICE: 'E',
    SHOP_ONLINE: 'F',
    TIPS_AND_TUTORIALS: 'G',
    DEVICE_SOFTWARE_UPDATE: 'H',
    DEVICE_TROUBLESHOOTING: 'I',
};
