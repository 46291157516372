import FWU_TARGET_TYPES from '../../enums/iot/fwuTargetTypes';
import {showDeviceIsInSblModalAction} from '../../state/ducks/modal';
import {selectIsFirmwareError, selectIsFirmwareStarted} from '../../state/selectors/iotDevice';
import {dispatch, getState} from '../../state/store';
import fwuService from '../ble/fwuService';
import iotDeviceSettingsService from './iotDeviceSettingsService';

const checkIfDeviceIsInSblMode = (iotDevice) => {
    if (!iotDevice) return;

    const {is_device_in_sbl_mode: isDeviceInSblMode} = iotDevice.device || {};
    const {is_device_in_sbl_mode: isDeviceEngineInSblMode} = iotDevice.device_engine || {};

    if (isDeviceInSblMode || isDeviceEngineInSblMode) {
        const fwu_target = isDeviceInSblMode ? FWU_TARGET_TYPES.CHARGER_OR_MONO : FWU_TARGET_TYPES.MONO_ENGINE;

        dispatch(showDeviceIsInSblModalAction({isInitialFlow: true, fwu_target}));
        return getResponse(false, true);
    }

    const isHolderInSBLMode = iotDeviceSettingsService.isHolderInSBLMode(iotDevice);
    const isHolderEngineInSblMode = iotDeviceSettingsService.isHolderEngineInSBLMode(iotDevice);

    if (isHolderInSBLMode || isHolderEngineInSblMode) {
        const fwu_target = isHolderInSBLMode ? FWU_TARGET_TYPES.HOLDER_DEVICE : FWU_TARGET_TYPES.HOLDER_ENGINE;
        const state = getState();
        const isFirmwareStarted = selectIsFirmwareStarted(state);
        const isFirmwareError = selectIsFirmwareError(state);
        const isHolderFwuInProgress = isFirmwareStarted;

        // IA: Don't do anything if FWU error popup is displayed
        if (isFirmwareError) return;

        if (isHolderFwuInProgress) {
            fwuService.initiateUpdateFirmwareMessage(fwu_target);
        } else {
            dispatch(showDeviceIsInSblModalAction({isInitialFlow: true, fwu_target}));
        }
        return getResponse(true, true);
    }
};

const getResponse = (isHolderSbl, isSblMode) => {
    return {isHolderSbl, isSblMode};
};

export default {
    checkIfDeviceIsInSblMode,
};
