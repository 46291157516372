import Cookies from 'js-cookie';

class CookieClient {
    constructor() {
        this.cookie = Cookies;
    }

    getObjItem = (key) => {
        const result = this.getItem(key);

        if (result !== undefined) {
            return JSON.parse(result);
        } else {
            return null;
        }
    };

    setObjItem = (key, value, option) => this.setItem(key, JSON.stringify(value), option);

    getItem = (key) => this.cookie.get(key);

    setItem = (key, value, option) => this.cookie.set(key, value, option);

    removeItem = (key, path) => this.cookie.remove(key, path);
}

const cookieClient = new CookieClient();

export default cookieClient;
