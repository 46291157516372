import cn from 'classnames';
import React, {FC} from 'react';

import styles from './ButtonArrowSVG.module.scss';

interface IProps {
    arrowClassName?: string;
}

const ButtonArrowSVG: FC<IProps> = ({arrowClassName}) => {
    return (
        <div className={styles.ArrowContainer}>
            <div className={styles.IconFrontWrap}>
                <div className={cn(styles.IconFront, arrowClassName)}>
                    <ArrowSVGIcon />
                </div>
            </div>
            <div className={styles.IconBackWrap}>
                <div className={styles.IconBack}>
                    <ArrowSVGIcon />
                </div>
            </div>
        </div>
    );
};

const ArrowSVGIcon: FC = () => {
    return (
        <svg viewBox='0 0 32 12' className={styles.Arrow}>
            <path
                d='M30.243,7.414 L30.242,7.414
                            L26.000,11.656 L24.586,10.242
                            L27.828,7.000 L-0.000,7.000
                            L-0.000,4.999 L27.828,4.999
                            L24.586,1.757 L26.000,0.343
                            L31.657,6.000 L30.243,7.414
                            Z'
            />
        </svg>
    );
};

export default ButtonArrowSVG;
