import UAParser, {IOS} from 'ua-parser-js';

type TGetBrowserName = () => string | undefined;
type TGetOS = () => IOS;
type TGetOSShortName = () => string | undefined;

const getBrowserName: TGetBrowserName = () => {
    const uaParser = new UAParser();

    return uaParser.getBrowser().name;
};

const getOS: TGetOS = () => {
    const uaParser = new UAParser();

    return uaParser.getOS();
};

const getOSShortName: TGetOSShortName = () => {
    const {name} = getOS();

    return name?.toLowerCase().substr(0, 3);
};

export default {
    getBrowserName,
    getOS,
    getOSShortName,
};
