export const INTERNAL_SERVER_ERROR = 0;
export const IO_ERROR_IO_GENERAL = 10;
export const IO_ERROR_IO_READ = 11;
export const IO_ERROR_IO_WRITE = 12;
export const NOT_SUPPORTED = 20;
export const BAD_REQUEST = 30;
export const FW_GENERAL = 40;
export const FW_CHECK_BAD_URL_OR_BLOCK_COUNT = 41;
export const FW_CHECK_ERROR_DOWNLOAD_OR_PARSE = 42;
export const FW_START_NOT_FOUND_DATA = 43;
export const FW_SBL_FOR_BLE_ERROR = 44;
export const FW_DEVICE_NOT_IN_SBL_ERROR = 46;
export const DEVICE_NOT_SUPPORTED_ERROR = 50;
export const ALARM_IS_NOT_SUPPORTED_BY_DEVICE = 20;

export const FW_ERRORS = [
    FW_GENERAL,
    FW_CHECK_BAD_URL_OR_BLOCK_COUNT,
    FW_CHECK_ERROR_DOWNLOAD_OR_PARSE,
    FW_START_NOT_FOUND_DATA,
    FW_DEVICE_NOT_IN_SBL_ERROR,
];
