import React from 'react';

import Icon from './Icon';

const IconSun = (props) => {
    return (
        <Icon width='50' height='50' viewBox='0 0 50 50' {...props}>
            <g fillRule='evenodd' strokeWidth='0'>
                <path
                    d='M5.646 23.786H1.393C.623 23.786 0 24.33 0 25c0 .67.624 1.214 1.393 1.214h4.253c.769 0 1.393-.544 1.393-1.214 0-.67-.624-1.214-1.393-1.214zm42.961 0h-4.253c-.769 0-1.393.544-1.393 1.214 0 .67.624 1.214 1.393 1.214h4.253C49.376 26.214 50 25.67 50 25c0-.67-.624-1.214-1.393-1.214zM25 42.961c-.67 0-1.214.624-1.214 1.393v4.253c0 .77.544 1.393 1.214 1.393.67 0 1.214-.624 1.214-1.393v-4.253c0-.769-.544-1.393-1.214-1.393zm0-33.495c.67 0 1.214-.645 1.214-1.44V1.44C26.214.645 25.67 0 25 0c-.67 0-1.214.645-1.214 1.44v6.586c0 .795.544 1.44 1.214 1.44zm1.213 7.282c-5.219 0-9.465 4.246-9.465 9.466 0 5.22 4.246 9.466 9.465 9.466 5.22 0 9.467-4.247 9.467-9.467 0-5.22-4.248-9.465-9.467-9.465zm-.122 16.504c-3.947 0-7.159-3.211-7.159-7.16 0-3.948 3.212-7.16 7.16-7.16s7.16 3.212 7.16 7.16c0 3.949-3.212 7.16-7.16 7.16zM9.9 38.45L7.38 40.97c-.455.456-.455 1.194.002 1.65.227.228.526.341.825.341.299 0 .598-.113.826-.342l2.519-2.52c.455-.457.455-1.195-.002-1.651-.455-.454-1.195-.456-1.651.002zM40.967 7.382l-2.519 2.52c-.455.456-.455 1.195.002 1.65.227.228.526.341.825.341.299 0 .598-.113.826-.342l2.519-2.52c.455-.457.455-1.195-.002-1.651-.455-.454-1.195-.456-1.651.002zm-.869 31.067c-.455-.456-1.194-.456-1.65 0-.455.456-.455 1.194 0 1.65l2.52 2.52c.229.229.527.342.826.342.299 0 .597-.113.825-.341.456-.456.456-1.195 0-1.65l-2.52-2.521zM9.578 7.676c-.526-.526-1.377-.526-1.902 0-.526.524-.526 1.376 0 1.901l4.348 4.35c.263.263.607.393.951.393s.689-.13.951-.393c.526-.525.526-1.376 0-1.902l-4.349-4.35z'
                    transform='translate(-1035 -375) translate(375 168) translate(630 184) translate(30 23)'
                />
            </g>
        </Icon>
    );
};

export default IconSun;
