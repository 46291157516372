import {useSelector} from 'react-redux';

import useCheckFirmware from '../../hooks/fwu/useCheckFirmware';
import fwuService from '../../services/ble/fwuService';
import getFeatureService from '../../services/getFeatureService';
import {makeSelectIotDeviceData} from '../../state/selectors/iotDevice';

const useFwuBackgroundCheck = () => {
    const iotDevice = useSelector(makeSelectIotDeviceData());
    const isBackgroundFwuCheckEnabled = fwuService.getIsBackgroundFwuCheckEnabled();
    const isGetFeatureAllowed = getFeatureService.getIsGetFeatureAllowed(iotDevice?.device?.type);
    const isFwuCheckEnabled = isBackgroundFwuCheckEnabled || isGetFeatureAllowed;

    useCheckFirmware({
        isCheckEnabled: isFwuCheckEnabled,
        iotDevice,
    });
};

export default useFwuBackgroundCheck;
