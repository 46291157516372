import {updateIotDeviceData} from '../../state/ducks/iotDevice/actions';
import {dispatch} from '../../state/store';
import fwuTargetService from '../device/fwuTargetService';

const isFirmwarePackageAvailable = (iotTarget) => iotTarget?.firmware?.package_available;

const isFirmwareCheckInProgress = (iotTarget) => iotTarget?.firmware?.isCheckInProgress;

const isFirmwareInfoLoadedWithCheck = (iotDevice, fwu_target) => {
    const package_available = getFirmwarePackageAvailable(iotDevice, fwu_target);

    return package_available !== undefined;
};

const getFirmwarePackageAvailable = (iotDevice, fwu_target) => {
    const target = fwuTargetService.getIotTargetByFwuTarget(iotDevice, fwu_target);

    return target?.firmware?.package_available;
};

const isFirmwareUpToDate = (device = {}, holder = {}) => {
    const {isHolderConnected, firmwarefirmware: deviceFirmware} = device;
    const holderFirmware = holder.firmware;
    const isDevicePackageAvailable = deviceFirmware?.package_available;
    const isHolderPackageAvailable = holderFirmware?.package_available;
    const isDeviceFWUpToDate = deviceFirmware && !isDevicePackageAvailable;
    const isHolderFWUpToDate = isHolderConnected ? holderFirmware && !isHolderPackageAvailable : true;

    return isDeviceFWUpToDate && isHolderFWUpToDate;
};

const isSblFirmware = (availableFirmwares) => {
    if (!availableFirmwares || !Array.isArray(availableFirmwares)) return false;

    return availableFirmwares.filter((availableFirmware) => availableFirmware.hasBootloaderUpdate).length > 0;
};

const setIotTargetFwuStateOnFwuCheckRequest = (fwuTarget) => {
    const iotTargetName = fwuTargetService.getIotTargetNameByFwuTarget(fwuTarget);
    const newState = {[iotTargetName]: {firmware: {isCheckInProgress: true}}};

    dispatch(updateIotDeviceData(newState));
};

export default {
    getFirmwarePackageAvailable,
    isFirmwareCheckInProgress,
    isFirmwareInfoLoadedWithCheck,
    isFirmwarePackageAvailable,
    isFirmwareUpToDate,
    isSblFirmware,
    setIotTargetFwuStateOnFwuCheckRequest,
};
