import * as activationStatusTypes from '../../consts/gam/activationStatusTypes';
import DEVICE_TYPES from '../../enums/device/deviceTypes';
import productHelpers from '../product/productHelpers';
import {assetMapping} from './iccMarketMappings';

export const authGamMapping = (responseData) => {
    return {
        authToken: responseData.authtoken,
        assetId: responseData.assetid,
    };
};

export const gamAssetMapping = (data) => {
    return [
        {
            assetId: data.assetId,
            yapActivated: data.device?.yapActivationStatus?.toLowerCase() === activationStatusTypes.GAM_ACTIVATED,
            materialId: getMediaId(data.material),
            type: DEVICE_TYPES.DEV_TYPE_P4_M3_G2,
            colorHex: productHelpers.getHexColorByMaterialColor(
                DEVICE_TYPES.DEV_TYPE_P4_M3_G2,
                data.material.materialColor
            ),
        },
    ];
};

const getMediaId = (material) => {
    if (!material) return null;

    try {
        const mediaIdParts = [
            material.device_material_group,
            material.materialBrandFamily,
            material.materialModelNumber,
            material.materialColor,
        ];
        const mediaName = mediaIdParts.join('_');

        return assetMapping(mediaName);
    } catch (e) {
        return null;
    }
};
