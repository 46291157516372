import React from 'react';

import Icon from './Icon';

const Personalization = ({className}) => {
    return (
        <Icon className={className} width='30' height='30' viewBox='0 0 30 30'>
            <g fill='none' fillRule='evenodd' stroke='#FFF' strokeLinecap='round' strokeLinejoin='round'>
                <path d='M6 7.5h20M26 23.5H6M11 21v5M21 5v5' />
            </g>
        </Icon>
    );
};

export default Personalization;
