import * as cmMessageTypes from '../../consts/communicationLayer/cmMessageTypes';
import {makeSelectCmMessageData} from '../../state/selectors/yapEncrypted';
import {getState} from '../../state/store';
import fwuService from '../ble/fwuService';
import connectivityService from '../device/connectivityService';
import cmWebSocketService from './cmClientService';

const getDataSuccess = (isSuccess) => {
    return {success: isSuccess};
};

const publishInitializationEvent = (isHolder) => {
    const message = {
        request_id: null,
        token: null,
        type: cmMessageTypes.CONNECTED,
        communication_interface: getCommunicationInterface(),
    };

    publishMWMessage(message, isHolder);
};

const publishDeviceCharacteristicEvent = (frame) => {
    publishCharacteristicEvent({
        system_status: {
            frame,
        },
    });
};

const publishBatteryCharacteristicEvent = (frame) => {
    publishCharacteristicEvent({
        battery_status: {
            frame,
        },
    });
};

const publishCharacteristicEvent = (data) => {
    const message = {
        request_id: null,
        token: null,
        type: cmMessageTypes.STATUS_CHARACTERISTICS,
        communication_interface: getCommunicationInterface(),
        data,
    };

    publishMWMessage(message);
};

const publishFwuPackageLoadedEvent = (request_id, token, isHolder) => {
    const message = {
        request_id,
        token,
        type: cmMessageTypes.FIRMWARE_UPDATE_PACKAGE_LOAD,
        communication_interface: getCommunicationInterface(),
        data: getDataSuccess(true),
    };

    publishMWMessage(message, isHolder);
};

const publishFwuStatusEvent = (type, request_id, token, n, isHolder) => {
    const message = {
        request_id,
        token,
        type,
        communication_interface: getCommunicationInterface(),
        data: n,
    };

    publishMWMessage(message, isHolder);
};

const publishFwuFinishEvent = () => {};

const publishFwuFinishAfterRebootEvent = () => {
    const msg = fwuService.getFwUpdateFinishAfterRebootEvent();

    if (!msg) return;

    const {cmToken, cmRequestId} = makeSelectCmMessageData()(getState());

    if (cmToken && cmRequestId) {
        const message = {
            request_id: cmRequestId,
            token: cmToken,
            type: cmMessageTypes.FIRMWARE_UPDATE_START,
            communication_interface: getCommunicationInterface(),
            data: msg,
        };

        publishMWMessage(message);
    }
};

const publishFwuErrorEvent = (type, request_id, token, errorCode, errorFrame, isHolder) => {
    const message = {
        request_id,
        token,
        type,
        communication_interface: getCommunicationInterface(),
    };

    if (errorFrame) {
        message.data = getDataSuccess(false);
        message.frames = [errorFrame];
    }

    if (errorCode) {
        message.error = errorCode;
    }

    publishMWMessage(message, isHolder);
};

const publishResponseEvent = ({type, request_id, token, frames, isHolder}) => {
    const message = {
        request_id,
        token,
        type,
        communication_interface: getCommunicationInterface(),
        frames,
    };

    publishMWMessage(message, isHolder);
};

const publishErrorEvent = ({request_id, token, errorCode, isHolder}) => {
    const message = {
        request_id,
        token,
        type: cmMessageTypes.ERROR,
        communication_interface: getCommunicationInterface(),
        error: errorCode,
    };

    publishMWMessage(message, isHolder);
};

const publishMWMessage = (message, isHolder) => {
    cmWebSocketService.publishMessage(message, isHolder);
};

const getCommunicationInterface = () => (connectivityService.isUsb() ? 'usb' : 'ble');

export default {
    publishBatteryCharacteristicEvent,
    publishDeviceCharacteristicEvent,
    publishErrorEvent,
    publishFwuErrorEvent,
    publishFwuPackageLoadedEvent,
    publishFwuStatusEvent,
    publishFwuFinishAfterRebootEvent,
    publishFwuFinishEvent,
    publishInitializationEvent,
    publishResponseEvent,
};
