import appConfig from '../config/appConfig';
import marketTypeService from '../services/marketTypeService';
import deviceSupportService from './device/deviceSupportService';
import deviceTypesService from './device/deviceTypesService';

const getImageSrcSetBySupportedDevice = (iqosSrcSet, veevSrcSet, qlabSet) => {
    const isP1DevicesSupported = deviceSupportService.isP1DevicesSupported();
    const enableP4Support = appConfig.getEnableP4Support();

    if (marketTypeService.isCanadaMarket()) {
        return qlabSet;
    } else if (isP1DevicesSupported && enableP4Support) {
        return iqosSrcSet;
    } else if (enableP4Support) {
        return veevSrcSet;
    } else {
        return iqosSrcSet;
    }
};

const getImageSrcSetByMarket = (iqosSrcSet, veevSrcSet, qlabVeevSet) => {
    const PULSE_LOGO_VEEV = 'veev';
    const browserCheckPageLogoType = appConfig.getBrowserCheckPageLogoType()?.toLowerCase();

    if (marketTypeService.isCanadaMarket()) {
        return qlabVeevSet;
    } else if (browserCheckPageLogoType) {
        return browserCheckPageLogoType === PULSE_LOGO_VEEV ? veevSrcSet : iqosSrcSet;
    } else {
        return getImageSrcSetBySupportedDevice(iqosSrcSet, veevSrcSet);
    }
};

const getSvgIconSetByDeviceType = (deviceType, p1Icon, p4Icon) => {
    const isP1 = deviceTypesService.isP1(deviceType);

    return isP1 ? p1Icon : p4Icon;
};

export default {
    getImageSrcSetBySupportedDevice,
    getImageSrcSetByMarket,
    getSvgIconSetByDeviceType,
};
