import {useEffect} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import backendService from '../../../services/app/backendService';
import productService from '../../../services/product/productService';
import yapService from '../../../services/yap/yapService';
import {showDeviceHolderRegistrationModalAction} from '../../../state/ducks/modal';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../../state/selectors/consumer';
import {
    makeSelectIsHolderConnectEnabled,
    makeSelectIsHolderRegisterFailed,
    makeSelectIsScpHolderInfoCheckInProgress,
} from '../../../state/selectors/global';
import {makeSelectIsDeviceReady} from '../../../state/selectors/iotDevice';
import {makeSelectIsYapDeviceInfoReceived} from '../../../state/selectors/yapEncrypted';
import {dispatch} from '../../../state/store';

const mapStateToProps = createStructuredSelector({
    iotProduct: makeSelectIotDeviceMergedWithIccProduct(),
    isDeviceReady: makeSelectIsDeviceReady(),
    isYapDeviceInfoReceived: makeSelectIsYapDeviceInfoReceived(),
    isScpHolderInfoCheckInProgress: makeSelectIsScpHolderInfoCheckInProgress(),
    isHolderConnectEnabled: makeSelectIsHolderConnectEnabled(),
    isHolderRegisterFailed: makeSelectIsHolderRegisterFailed(),
});

const DeviceHolderRegistrationChecker = ({
    iotProduct,
    isDeviceReady,
    isYapDeviceInfoReceived,
    isScpHolderInfoCheckInProgress,
    isHolderConnectEnabled,
    isHolderRegisterFailed,
}) => {
    const {device, holder} = iotProduct || {};
    const {status: deviceStatus, isHolderConnected} = device || {};
    const {status: holderStatus, deviceSerialNumber} = holder || {};
    const isUamBackend = backendService.isUamBackend();
    const isDataCollectionEnabled = yapService.isDataCollectionEnabled();
    const isUamEnabled = isUamBackend || isDataCollectionEnabled;
    const isCurrentDeviceRegistered = productService.getIsRegistered(deviceStatus);
    const isHolderUnregistered = productService.getIsUnregistered(holderStatus);

    useEffect(() => {
        if (
            deviceSerialNumber &&
            !isScpHolderInfoCheckInProgress &&
            isHolderConnected &&
            isDeviceReady &&
            isCurrentDeviceRegistered &&
            isUamEnabled &&
            isYapDeviceInfoReceived &&
            isHolderConnectEnabled &&
            isHolderUnregistered &&
            !isHolderRegisterFailed
        ) {
            dispatch(showDeviceHolderRegistrationModalAction());
        }
    }, [
        isHolderConnected,
        isDeviceReady,
        isCurrentDeviceRegistered,
        isYapDeviceInfoReceived,
        isScpHolderInfoCheckInProgress,
        isHolderUnregistered,
        deviceSerialNumber,
        isUamEnabled,
        isHolderConnectEnabled,
        isHolderRegisterFailed,
    ]);

    return null;
};

export default connect(mapStateToProps)(DeviceHolderRegistrationChecker);
