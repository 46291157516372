import FWU_TARGET_TYPES from '../../enums/iot/fwuTargetTypes';
import IOT_DEVICE_TARGET_NAMES from '../../enums/iot/iodDeviceTargetNames';
import {IDevice, IIotDeviceTargets, IIotTarget} from '../../models/iotDevice';
import fwuTargetService from '../../services/device/fwuTargetService';
import iotDeviceStateService from '../../services/iotDevice/iotDeviceFWUStateService';

interface IProps {
    iotTargets?: IIotDeviceTargets;
    device: IDevice;
}

export interface IFwuInfo {
    fwuTarget?: FWU_TARGET_TYPES;
    isAvailable?: boolean;
    isCheckInProgress?: boolean;
    isLoaded?: boolean;
}

interface IReturn {
    [IOT_DEVICE_TARGET_NAMES.DEVICE]?: IFwuInfo;
    [IOT_DEVICE_TARGET_NAMES.DEVICE_ENGINE]?: IFwuInfo;
    [IOT_DEVICE_TARGET_NAMES.HOLDER]?: IFwuInfo;
    [IOT_DEVICE_TARGET_NAMES.HOLDER_ENGINE]?: IFwuInfo;
    isInfoLoadedForAllTargets?: boolean;
    isAnyFwuAvailable?: boolean;
}

const useFWUInfoLoaded = (props: IProps): IReturn => {
    const {iotTargets, device} = props;
    const result: IReturn = {};
    const getFwuInfoByIotTarget = (target: IIotTarget, fwuTarget?: FWU_TARGET_TYPES): IFwuInfo => {
        const isAvailable = iotDeviceStateService.isFirmwarePackageAvailable(target);
        const isCheckInProgress = iotDeviceStateService.isFirmwareCheckInProgress(target);
        const isLoaded = isAvailable !== undefined;

        return {
            fwuTarget,
            isAvailable,
            isCheckInProgress,
            isLoaded,
        };
    };
    let isInfoLoadedForAllTargets = true;
    let isAnyFwuAvailable = false;

    if (iotTargets) {
        Object.entries(iotTargets).forEach(([key, value]) => {
            if (value) {
                const isHolderRelatedTarget = isHolderRelatedIotTarget(key);
                const omitHolderTarget = isHolderRelatedTarget && !device?.isHolderConnected;

                if (omitHolderTarget) return;

                const fwuTarget = fwuTargetService.getFwuTargetByIotTarget({[key as IOT_DEVICE_TARGET_NAMES]: true});
                const fwuInfo = getFwuInfoByIotTarget(value as IIotTarget, fwuTarget);

                if (!isInfoLoadedForAllTargets || !fwuInfo.isLoaded || fwuInfo.isCheckInProgress) {
                    isInfoLoadedForAllTargets = false;
                }
                if (fwuInfo.isAvailable) {
                    isAnyFwuAvailable = true;
                }

                result[key as IOT_DEVICE_TARGET_NAMES] = fwuInfo;
            }
        });
    }

    result.isInfoLoadedForAllTargets = isInfoLoadedForAllTargets;
    result.isAnyFwuAvailable = isAnyFwuAvailable;

    return result;
};

export default useFWUInfoLoaded;

const isHolderRelatedIotTarget = (key: string) =>
    key === IOT_DEVICE_TARGET_NAMES.HOLDER || key === IOT_DEVICE_TARGET_NAMES.HOLDER_ENGINE;
