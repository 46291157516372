import React from 'react';

import Icon from './Icon';

const IconSpecialOffers = (props) => {
    return (
        <Icon width='30' height='30' viewBox='0 0 30 30' {...props}>
            <g fill='none' fillRule='evenodd'>
                <path d='M0 0H30V30H0z' />
                <g fill='#343534' stroke='#272A33'>
                    <path
                        d='M.476 25.884H17.2V3.474H.476v22.41zM0 26.36h17.676V2.998H0V26.36z'
                        transform='translate(4 1)'
                    />
                    <path
                        d='M8.67 15.002c-2.567 0-4.656-2.088-4.656-4.656V.498c0-.132.107-.238.238-.238s.238.106.238.238v9.848c0 2.305 1.875 4.18 4.18 4.18 2.305 0 4.18-1.875 4.18-4.18V.498c0-.132.106-.238.238-.238.131 0 .238.106.238.238v9.848c0 2.568-2.088 4.656-4.656 4.656'
                        transform='translate(4 1)'
                    />
                    <path
                        strokeLinejoin='round'
                        d='M18.392 25.888L20.916 25.391 20.916 4.009 18.35 3.457 18.525 3.014 21.392 3.533 21.392 25.782 18.484 26.355z'
                        transform='translate(4 1)'
                    />
                    <path
                        d='M7.228 3.35c-.131 0-.238-.107-.238-.238V1.356c0-.131.107-.238.238-.238s.238.107.238.238v1.756c0 .131-.107.238-.238.238zm8.621-.238V1.356c0-.131.107-.238.239-.238.13 0 .237.107.237.238v1.756c0 .131-.106.238-.237.238-.132 0-.239-.107-.239-.238z'
                        transform='translate(4 1)'
                    />
                </g>
            </g>
        </Icon>
    );
};

export default IconSpecialOffers;
