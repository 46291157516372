import {setIsDeviceReconnected} from '../../state/ducks/iotDevice/actions';
import {dispatch} from '../../state/store';
import backendService from '../app/backendService';
import gamClientService from '../gam/gamClientService';
import gamService from '../gam/gamService';
import scpCloudService from '../scpCloud/scpCloudService';

const initIotService = async (isReconnect) => {
    const isGamBackend = backendService.isGamBackend();

    if (isReconnect) {
        dispatch(setIsDeviceReconnected(true));
    }

    if (isGamBackend) {
        if (!isReconnect) {
            await gamService.initGamData();
        }
        await gamClientService.synchronizeGamDevice();
    } else {
        await scpCloudService.initIoT();
    }
};

export default {
    initIotService,
};
