import {createSelector} from 'reselect';

import ModalTypes from '../../enums/app/modalTypes';
import {IRootState} from '../state';
import {TModalState} from '../types/modal';

const selectModal = (state: IRootState) => state.modal;

const makeSelectModals = createSelector(selectModal, (modalState) => modalState);

const makeSelectVisibleModal = createSelector(
    selectModal,
    (state: TModalState, modalType: ModalTypes) => modalType,
    (modalState, modalType) => modalState.some((modal) => modal.modalType === modalType)
);

const makeSelectModal = (modalType: ModalTypes): any =>
    createSelector(selectModal, (modalState) => modalState.find((modal) => modal.modalType === modalType));

export {makeSelectModal, makeSelectModals, makeSelectVisibleModal};
