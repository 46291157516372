import appConfig from '../config/appConfig';
import * as marketTypes from '../consts/market/marketTypes';

const isDCE1 = (): boolean => !!appConfig.getIsDCE1();
const isDCE2 = (): boolean => !!appConfig.getIsDCE2();
const isDCS = (): boolean => !!appConfig.getIsDCS();
const isMarketDefined = (): boolean => isDCE1() || isDCE2() || isDCS();

const getP4DefaultImageByMarket = (iqosP4ImgSet: string, veevP4ImgSet: string): string => {
    const DEFAULT_IMAGE_VEEV = 'veev';
    const browserCheckPageLogoType = appConfig.getDefaultVeevImage()?.toLowerCase();
    const isVeevTitleVeevImage = browserCheckPageLogoType === DEFAULT_IMAGE_VEEV;

    return isVeevTitleVeevImage ? veevP4ImgSet : iqosP4ImgSet;
};

const isCanadaMarket = (): boolean => {
    const marketType = appConfig.getScpCloudTopicMarket();

    return marketType === marketTypes.CABC || marketType === marketTypes.CAON || marketType === marketTypes.CAAB;
};

const isJpMarket = (): boolean => {
    const marketType = appConfig.getScpCloudTopicMarket();

    return marketType === marketTypes.JP;
};

const isUaMarket = (): boolean => {
    const marketType = appConfig.getScpCloudTopicMarket();

    return marketType === marketTypes.UA;
};

export default {
    getP4DefaultImageByMarket,
    isCanadaMarket,
    isDCE1,
    isDCE2,
    isDCS,
    isJpMarket,
    isMarketDefined,
    isUaMarket,
};
