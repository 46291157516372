import * as iotHolderStatusTypes from '../../consts/iot/iotHolderStatusTypes';
import * as loaderNames from '../../consts/loader/loaderNames';
import * as scp from '../../consts/scp/scpFrames';
import DEVICE_TYPES from '../../enums/device/deviceTypes';
import ICC_PRODUCT_STATUS_TYPES from '../../enums/icc/iccProductStatusTypes';
import connectivityService from '../../services/device/connectivityService';
import devicePlatformService from '../../services/device/devicePlatformService';
import {hideLoader, showLoader} from '../../state/ducks/global';
import {setScpHolderCheckInProgress} from '../../state/ducks/global';
import {updateIotDeviceData} from '../../state/ducks/iotDevice/actions';
import {dispatch} from '../../state/store';
import converter from '../../utils/converter';
import helpers from '../../utils/helpers';
import stringUtils from '../../utils/stringUtils';
import backendService from '../app/backendService';
import ScpCharacteristicClient from '../ble/scpCharacteristicClient';
import framesProcessingService from '../device/framesProcessingService';
import iccMarketService from '../icc/iccMarketService';
import log from '../logger/log';
import {calculateChecksum} from './crcService';

const GENERAL_READING_TIMEOUT_SEC = 20;

let scpReadingState;

const isUsb = () => !connectivityService.isBle();

const getEmptyDeviceState = () => {
    return {
        type: undefined,
        codentify: undefined,
        serial: undefined,
        supportedCommands: {
            codentify: false,
            serial: false,
        },
        readingStatus: {
            serialReadStarted: false,
            serialReadFinished: false,
            codentifyReadStarted: false,
            codentifyReadFinished: false,
        },
    };
};

const resetState = () => {
    scpReadingState = {
        charger: getEmptyDeviceState(),
        holder: getEmptyDeviceState(),
    };
};

const identifyDevice = async () => {
    resetState();

    await waitSupportedDataReadFinish(GENERAL_READING_TIMEOUT_SEC);

    return scpReadingState;
};

const waitSupportedDataReadFinish = async (timer) => {
    const holderStatus = {...scpReadingState.holder.readingStatus};
    const chargerStatus = {...scpReadingState.charger.readingStatus};

    const allDataReady =
        holderStatus.serialReadFinished &&
        chargerStatus.serialReadFinished &&
        chargerStatus.codentifyReadFinished &&
        holderStatus.codentifyReadFinished;

    if (allDataReady) {
        return;
    }

    readSupportedData();

    if (timer-- < 0) {
        log.error('SCP Service. General data reading timeout exceeded');
        const scpClient = new ScpCharacteristicClient();

        scpClient.disable();
        return;
    }

    await helpers.timeout(1000);
    await waitSupportedDataReadFinish(timer);
};

const readSupportedData = () => {
    if (toStartChargerSerialNumberReading()) {
        scpReadingState.charger.readingStatus.serialReadStarted = true;
        return readSerialCommon();
    }

    if (toStartHolderSerialNumberReading()) {
        scpReadingState.holder.readingStatus.serialReadStarted = true;
        return readSerialCommon(true);
    }

    if (isDeviceIdentificationFinished()) {
        if (toStartChargerCodentifyReading()) {
            scpReadingState.charger.readingStatus.codentifyReadStarted = true;
            return readCodentifyCommon();
        }
        if (toStartHolderCodentifyReading()) {
            scpReadingState.holder.readingStatus.codentifyReadStarted = true;
            return readCodentifyCommon(true);
        }
    }
};

const initDeviceCapabilitites = () => {
    const charger = scpReadingState.charger;
    const holder = scpReadingState.holder;

    switch (charger.type) {
        case DEVICE_TYPES.DEV_TYPE_P1V30M:
            holder.supportedCommands.codentify = false;
            holder.supportedCommands.serial = false;
            charger.supportedCommands.codentify = false;

            holder.readingStatus.codentifyReadFinished = true;
            holder.readingStatus.serialReadFinished = true;
            charger.readingStatus.codentifyReadFinished = true;
            break;
        case DEVICE_TYPES.DEV_TYPE_P4_M3_G2:
            charger.supportedCommands.codentify = true;
            holder.supportedCommands.serial = false;

            holder.readingStatus.codentifyReadFinished = true;
            holder.readingStatus.serialReadFinished = true;
            break;
        case DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER:
        case DEVICE_TYPES.DEV_TYPE_P1V31_HOLDER:
        case DEVICE_TYPES.DEV_TYPE_P1V30_CHARGER:
        case DEVICE_TYPES.DEV_TYPE_P1V30_HOLDER:
        case DEVICE_TYPES.DEV_TYPE_P1V40V_CHARGER:
        case DEVICE_TYPES.DEV_TYPE_P1V40C_CHARGER:
            holder.supportedCommands.codentify = true;
            holder.supportedCommands.serial = true;
            charger.supportedCommands.codentify = true;
            break;
        case DEVICE_TYPES.DEV_TYPE_V24P_CHARGER:
        case DEVICE_TYPES.DEV_TYPE_V24P_HOLDER:
            holder.supportedCommands.codentify = false;
            holder.supportedCommands.serial = true;
            charger.supportedCommands.codentify = false;

            holder.readingStatus.codentifyReadFinished = true;
            charger.readingStatus.codentifyReadFinished = true;
            break;
    }
};

const readSerialCommon = (isHolder, retryCount = 1) => {
    const device = isHolder ? scpReadingState.holder : scpReadingState.charger;

    device.readingStatus.serialReadFinished = false;

    let frameToSend;

    if (isUsb()) {
        frameToSend = isHolder ? scp.FRAME_HOLDER_SERIAL_USB : scp.FRAME_CHARGER_SERIAL_USB;
    } else {
        frameToSend = isHolder ? scp.FRAME_HOLDER_SERIAL : scp.FRAME_CHARGER_SERIAL;
    }
    return sendFrame(
        frameToSend,
        (frames) => {
            const frame = mapResponseFrame(converter.buffer2hexArr(frames[0]));

            if (frame.isError || !frame.isCrcValid) {
                if (retryCount-- > 0) {
                    return readSerialCommon(isHolder, retryCount);
                } else {
                    return onCommonReadError(isHolder);
                }
            }

            const deviceSerial = mapSerialNumberFramePayload(frame.payload);

            const upperCaseSerialNumber = deviceSerial?.dusn.toUpperCase();

            device.serial = '0' + upperCaseSerialNumber.charAt(1).toLowerCase() + upperCaseSerialNumber.slice(2); // need transform device serial number in right format

            setDeviceTypeBySerial(device.serial, frame.isHolderResponse);

            if (frame.isChargerResponse) {
                initDeviceCapabilitites();

                if (device.type === DEVICE_TYPES.DEV_TYPE_UNKNOWN) {
                    onCommonReadError();
                }
            }

            device.readingStatus.serialReadFinished = true;

            readSupportedData();
        },
        () => onCommonReadError(isHolder)
    );
};

const readCodentifyCommon = (isHolder, retryCount = 1) => {
    const device = isHolder ? scpReadingState.holder : scpReadingState.charger;

    device.readingStatus.codentifyReadFinished = false;

    let frameToSend;

    if (isUsb()) {
        frameToSend = isHolder ? scp.FRAME_HOLDER_CODENTIFY_USB : scp.FRAME_CHARGER_CODENTIFY_USB;
    } else {
        frameToSend = isHolder ? scp.FRAME_HOLDER_CODENTIFY : scp.FRAME_CHARGER_CODENTIFY;
    }

    return sendFrame(
        frameToSend,
        (frames) => {
            const frame = mapResponseFrame(converter.buffer2hexArr(frames[0]));

            if (frame.isError || !frame.isCrcValid) {
                if (retryCount-- > 0) {
                    return readCodentifyCommon(isHolder, retryCount);
                } else {
                    return onCommonReadError(isHolder);
                }
            }

            const codentify = String.fromCharCode.apply(null, frame.payload);

            if (stringUtils.isAlphaNumeric(codentify)) {
                device.codentify = codentify.toUpperCase();
            }
            device.readingStatus.codentifyReadFinished = true;

            readSupportedData();
        },
        () => onCommonReadError(isHolder)
    );
};

const onCommonReadError = (isHolder) => {
    const device = isHolder ? scpReadingState.holder : scpReadingState.charger;
    const holder = scpReadingState.holder;

    device.readingStatus.serialReadFinished = true;
    device.readingStatus.codentifyReadFinished = true;

    if (!isHolder) {
        //don't read all other holder data w/o serial
        holder.readingStatus.serialReadFinished = true;
        holder.readingStatus.codentifyReadFinished = true;
    }

    readSupportedData();
};

const isDeviceIdentificationFinished = () => {
    return (
        scpReadingState.charger.readingStatus.serialReadFinished &&
        scpReadingState.holder.readingStatus.serialReadFinished
    );
};

const toStartChargerSerialNumberReading = () => {
    return (
        !scpReadingState.charger.readingStatus.serialReadStarted &&
        !scpReadingState.charger.readingStatus.serialReadFinished
    );
};

const toStartHolderSerialNumberReading = () => {
    return (
        scpReadingState.holder.supportedCommands.serial &&
        !scpReadingState.holder.readingStatus.serialReadStarted &&
        !scpReadingState.holder.readingStatus.serialReadFinished
    );
};

const toStartChargerCodentifyReading = () => {
    return (
        scpReadingState.charger.supportedCommands.codentify &&
        !scpReadingState.charger.readingStatus.codentifyReadStarted &&
        !scpReadingState.charger.readingStatus.codentifyReadFinished
    );
};

const toStartHolderCodentifyReading = () => {
    return (
        scpReadingState.holder.supportedCommands.codentify &&
        !scpReadingState.holder.readingStatus.codentifyReadFinished &&
        !scpReadingState.holder.readingStatus.codentifyReadStarted
    );
};

const sendFrame = (frame, onSuccess, onError) => {
    framesProcessingService.writeFrameAndPublishResponse({
        frames: [frame],
        processImmediately: true,
        onSuccess: (...frames) => {
            onSuccess(frames);
        },
        onError,
    });
};

const mapResponseFrame = (frame) => {
    if (isUsb()) {
        //skip usb header
        frame.shift();
    }

    let mappedFrame = {
        isError: (frame[scp.OFFSET_APP_LAYER_HEADER] & (1 << 5)) !== 0,
        isChargerResponse: frame[scp.OFFSET_DEVICE_TYPE] === scp.HEADER_CHARGER_RESPONSE,
        isHolderResponse: frame[scp.OFFSET_DEVICE_TYPE] === scp.HEADER_HOLDER_RESPONSE,
        frameHeader: frame.slice(scp.OFFSET_APP_LAYER_HEADER, scp.OFFSET_PAYLOAD_START),
        payload: frame.slice(scp.OFFSET_PAYLOAD_START, isUsb() ? frame[0] : frame.length - 1),
        crc: isUsb() ? frame[frame[0]] : frame[frame.length - 1],
    };

    mappedFrame = {...mappedFrame, isCrcValid: isValidCrc(mappedFrame)};

    if (!mappedFrame.isCrcValid) {
        log.error(`ScpReadingService. Frame CRC ${mappedFrame?.crc} is not valid.`);
    }

    return mappedFrame;
};

const mapSerialNumberFramePayload = (payload) => {
    if (!payload) {
        return;
    }

    const deviceSerial = {
        platformCode: undefined,
        productCode: undefined,
        siteCode: undefined,
        deviceNumber: undefined,
        dusn: undefined,
    };

    (deviceSerial.platformCode = payload.slice(scp.PLATFORM_CODE_OFFSET, scp.PRODUCT_CODE_OFFSET).reverse()),
        (deviceSerial.productCode = payload.slice(scp.PRODUCT_CODE_OFFSET, scp.SITE_CODE_OFFSET).reverse()),
        (deviceSerial.siteCode = payload.slice(scp.SITE_CODE_OFFSET, scp.DEVICE_NUMBER_OFFSET).reverse()),
        (deviceSerial.deviceNumber = payload.slice(scp.DEVICE_NUMBER_OFFSET, scp.DEVICE_NUMBER_END).reverse());
    deviceSerial.dusn = [
        '0x',
        deviceSerial.platformCode.map((x) => x.toString(16).padStart(2, '0')).join(''),
        deviceSerial.productCode.map((x) => x.toString(16).padStart(2, '0')).join(''),
        deviceSerial.siteCode.map((x) => x.toString(16).padStart(2, '0')).join(''),
        deviceSerial.deviceNumber.map((x) => x.toString(16).padStart(2, '0')).join(''),
    ].join('');

    return deviceSerial;
};

const isValidCrc = (frame) => {
    if (frame.crc != null && frame.payload != null) {
        const calculatedCrc = calculateChecksum([...frame.frameHeader, ...frame.payload]);

        return calculatedCrc === frame.crc;
    } else {
        return false;
    }
};

const setDeviceTypeBySerial = (serialNumber, isHolder) => {
    if (!serialNumber) {
        return;
    }

    const device = isHolder ? scpReadingState.holder : scpReadingState.charger;

    device.type = devicePlatformService.getDeviceTypeBySerialNumber(serialNumber);
};

const getHolderInfo = async () => {
    const isUamBackend = backendService.isUamBackend();

    if (!isUamBackend) return;

    dispatch(setScpHolderCheckInProgress(true));
    dispatch(showLoader(loaderNames.CHECK_HOLDER_LOADER));

    const scpReadResult = await identifyDevice();
    const {serial, codentify, type: holderType} = scpReadResult?.holder;
    let codentifyId = codentify;
    const consumerProducts = await iccMarketService.getConsumerProducts();
    const product = consumerProducts?.find(
        (product) => product.deviceSerialNumber === serial && product.status === ICC_PRODUCT_STATUS_TYPES.REGISTERED
    );

    if (serial && !codentifyId) {
        codentifyId = product?.codentify;
    }

    if (!codentifyId && !serial) {
        dispatch(updateIotDeviceData({holder: null}));
    } else {
        dispatch(
            updateIotDeviceData({
                holder: {
                    deviceSerialNumber: serial,
                    codentify: codentifyId,
                    holder_state: iotHolderStatusTypes.READY_TO_USE,
                    type: holderType,
                },
            })
        );
    }

    dispatch(setScpHolderCheckInProgress(false));
    dispatch(hideLoader(loaderNames.CHECK_HOLDER_LOADER));

    return {deviceSerialNumber: serial, codentify};
};

export default {
    identifyDevice,
    getHolderInfo,
};
