type TKey = string | undefined;
type TValue = string | number;
type TGetKeyByValue = (obj: {[k: string]: TValue}, value: TValue) => TKey;
type TObjMap = (obj: {[k: string]: any}, func: (arg0: TValue, arg1: string) => any) => any[];
type TReturnEmptyObjectIfUndefined = (obj?: Record<string, unknown>) => Record<string, unknown>;

const getKeyByValue: TGetKeyByValue = (obj, value) => Object.keys(obj).find((k) => obj[k] === value);

const objMap: TObjMap = (obj, func) => Object.keys(obj).map((name) => func(obj[name], name));

const returnEmptyObjectIfUndefined: TReturnEmptyObjectIfUndefined = (obj) => (obj ? obj : {});

export default {
    getKeyByValue,
    objMap,
    returnEmptyObjectIfUndefined,
};
