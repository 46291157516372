import React, {FC} from 'react';

import BackButton from '../../../../../components/BackButton/BackButton';
import {ButtonDark} from '../../../../../components/Button';
import Container from '../../../../../components/Layout/Container/Container';
import * as localizationKeys from '../../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import {setNotificationSuccess} from '../../../../../state/ducks/global';
import {setFWUSuccess} from '../../../../../state/ducks/iotDevice/actions';
import {dispatch} from '../../../../../state/store';
import elementsTesIds from '../../../../../test/consts/elementsTesIds';
import styles from './FWUStartFinishStep.module.scss';

interface IProps {
    onClose: () => void;
    onNextClick?: () => void;
    onBackButtonClick?: () => void;
    isFlowFinish?: boolean;
}

const FWUStartFinishStep: FC<IProps> = ({onClose, onNextClick, isFlowFinish, onBackButtonClick}) => {
    const localizedStrings = getLocalizedStrings();

    const onFinishClick = () => {
        dispatch(setNotificationSuccess(getLocalizedStrings()[localizationKeys.IQOS_DUO_FIRMWARE_UPDATE_SUCCESS]));
        dispatch(setFWUSuccess());
        onClose();
    };

    return (
        <>
            <Container isFloatHeight>
                <BackButton
                    ariaLabel={
                        localizedStrings[
                            localizationKeys.FWU_ADDITIONAL_STEPS_GUIDE_BTN_BACK_TO_ABOUT_DEVICE_ARIA_LABEL
                        ]
                    }
                    onClick={isFlowFinish ? onBackButtonClick : onClose}
                    testId={elementsTesIds.ABOUT_DEVICE_BTN_BACK}
                />
            </Container>
            <Container className={styles.CommonContainer}>
                <div className={styles.TextContainer}>
                    <h2 className={styles.Title}>
                        {
                            localizedStrings[
                                isFlowFinish
                                    ? localizationKeys.IQOS_DUO_STEP6_TITLE
                                    : localizationKeys.IQOS_DUO_STEP1_TITLE
                            ]
                        }
                    </h2>
                    <p className={styles.Description}>
                        {
                            localizedStrings[
                                isFlowFinish
                                    ? localizationKeys.IQOS_DUO_STEP6_DESCRIPTION
                                    : localizationKeys.IQOS_DUO_STEP1_DESCRIPTION
                            ]
                        }
                    </p>
                </div>
                <ButtonDark
                    showArrow
                    className={styles.ContinueButton}
                    onClick={isFlowFinish ? onFinishClick : onNextClick}
                >
                    {
                        localizedStrings[
                            isFlowFinish
                                ? localizationKeys.IQOS_DUO_STEP6_BUTTON_TEXT
                                : localizationKeys.IQOS_DUO_STEP1_BUTTON_TEXT
                        ]
                    }
                </ButtonDark>
            </Container>
        </>
    );
};

export default FWUStartFinishStep;
