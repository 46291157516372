import cn from 'classnames';
import React, {FC} from 'react';

import styles from './Container.module.scss';

interface IProps {
    className?: string;
    children: React.ReactNode;
    isFloatHeight?: boolean;
}
const Container: FC<IProps> = (props) => {
    const {className, children, isFloatHeight} = props;

    return <div className={cn(styles.Container, className, {[styles.Float]: isFloatHeight})}>{children}</div>;
};

export default Container;
