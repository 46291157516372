export const ABOUT_DEVICE_CHECK_FIRMWARE_UPDATE_BUTTON_TEXT = 'ABOUT_DEVICE_CHECK_FIRMWARE_UPDATE_BUTTON_TEXT';
export const ABOUT_DEVICE_DEVICE_CODENTIFY_TEXT = 'ABOUT_DEVICE_DEVICE_CODENTIFY_TEXT';
export const ABOUT_DEVICE_DEVICE_FIRMWARE_TEXT = 'ABOUT_DEVICE_DEVICE_FIRMWARE_TEXT';
export const ABOUT_DEVICE_DEVICE_LAST_UPDATE_TEXT = 'ABOUT_DEVICE_DEVICE_LAST_UPDATE_TEXT';
export const ABOUT_DEVICE_DEVICE_MODEL_TEXT = 'ABOUT_DEVICE_DEVICE_MODEL_TEXT';
export const ABOUT_DEVICE_DEVICE_STATUS_TEXT = 'ABOUT_DEVICE_DEVICE_STATUS_TEXT';
export const AGE_GATE_DAY = 'AGE_GATE_DAY';
export const BENEFITS_FIRST_TEXT = 'BENEFITS_FIRST_TEXT';
export const BENEFITS_THIRD_TEXT = 'BENEFITS_THIRD_TEXT';
export const BENEFITS_FOUR_TEXT = 'BENEFITS_FOUR_TEXT';
export const BENEFITS_START_BUTTON_TEXT = 'BENEFITS_START_BUTTON_TEXT';
export const DEVICE_SETTINGS_TIPS_TUTORIALS_BUTTON_ARIA_LABEL = 'DEVICE_SETTINGS_TIPS_TUTORIALS_BUTTON_ARIA_LABEL';
export const DEVICE_SETTINGS_VIBRATIONS_STARTED_STATUS = 'DEVICE_SETTINGS_VIBRATIONS_STARTED_STATUS';
export const DEVICE_SETTINGS_VIBRATIONS_FINISHED_STATUS = 'DEVICE_SETTINGS_VIBRATIONS_FINISHED_STATUS';
export const AGE_GATE_TITLE = 'AGE_GATE_TITLE';
export const TIPS_TUTORIALS_IMAGE_ARIA_LABEL = 'TIPS_TUTORIALS_IMAGE_ARIA_LABEL';
export const APP_INTRO_PAGE_ALTERNATIVE_DOWNLOAD_DESCRIPTION_TEXT =
    'APP_INTRO_PAGE_ALTERNATIVE_DOWNLOAD_DESCRIPTION_TEXT';
export const APP_INTRO_PAGE_ALTERNATIVE_DOWNLOAD_LINK_TEXT = 'APP_INTRO_PAGE_ALTERNATIVE_DOWNLOAD_LINK_TEXT';
export const AGE_GATE_LIST_LABEL = 'AGE_GATE_LIST_LABEL';
export const ABOUT_DEVICE_DEVICE_UPDATE_TEXT = 'ABOUT_DEVICE_DEVICE_UPDATE_TEXT';
export const ABOUT_DEVICE_FAILED_STATUS_TEXT = 'ABOUT_DEVICE_FAILED_STATUS_TEXT';
export const ABOUT_DEVICE_FIRMWARE_UPDATE_DESCRIPTION_LINK = 'ABOUT_DEVICE_FIRMWARE_UPDATE_DESCRIPTION_LINK';
export const ABOUT_DEVICE_FIRMWARE_UPDATE_DESCRIPTION_TEXT = 'ABOUT_DEVICE_FIRMWARE_UPDATE_DESCRIPTION_TEXT';
export const ABOUT_DEVICE_FIRMWARE_UPDATE_DEVICE_BUTTON_TEXT = 'ABOUT_DEVICE_FIRMWARE_UPDATE_DEVICE_BUTTON_TEXT';
export const APP_INTRO_PAGE_BLUETOOTH_NOT_AVAILABLE_TEXT = 'APP_INTRO_PAGE_BLUETOOTH_NOT_AVAILABLE_TEXT';
export const APP_INTRO_PAGE_BLUETOOTH_NOT_AVAILABLE_BT_TEXT = 'APP_INTRO_PAGE_BLUETOOTH_NOT_AVAILABLE_BT_TEXT';
export const ABOUT_DEVICE_FIRMWARE_UPDATE_FAILED_TEXT = 'ABOUT_DEVICE_FIRMWARE_UPDATE_FAILED_TEXT';
export const COMPATIBLE_BROWSER_PAGE_CONNECT_DESCRIPTION_TEXT = 'COMPATIBLE_BROWSER_PAGE_CONNECT_DESCRIPTION_TEXT';
export const APP_INTRO_PAGE_TITLE_TEXT = 'APP_INTRO_PAGE_TITLE_TEXT';
export const ABOUT_DEVICE_FIRMWARE_UPDATE_HOLDER_BUTTON_TEXT = 'ABOUT_DEVICE_FIRMWARE_UPDATE_HOLDER_BUTTON_TEXT';
export const LOGIN_PAGE_TITLE_TEXT = 'LOGIN_PAGE_TITLE_TEXT';
export const ABOUT_DEVICE_FIRMWARE_UPDATE_IS_UP_TO_DATE_TEXT = 'ABOUT_DEVICE_FIRMWARE_UPDATE_IS_UP_TO_DATE_TEXT';
export const ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_BUTTON_PRIMARY = 'ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_BUTTON_PRIMARY';
export const ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_BUTTON_SECONDARY =
    'ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_BUTTON_SECONDARY';
export const ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_DESCRIPTION_PART_1 =
    'ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_DESCRIPTION_PART_1';
export const ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_DESCRIPTION_PART_2 =
    'ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_DESCRIPTION_PART_2';
export const ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_TITLE = 'ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_TITLE';
export const ABOUT_DEVICE_HOLDER_MODEL_TEXT = 'ABOUT_DEVICE_HOLDER_MODEL_TEXT';
export const ABOUT_DEVICE_HOLDER_STATUS_TEXT = 'ABOUT_DEVICE_HOLDER_STATUS_TEXT';
export const ABOUT_DEVICE_REGISTER_DEVICE_TEXT = 'ABOUT_DEVICE_REGISTER_DEVICE_TEXT';
export const ABOUT_DEVICE_REGISTERED_STATUS_TEXT = 'ABOUT_DEVICE_REGISTERED_STATUS_TEXT';
export const FIRMWARE_SBL_FOUND_POPUP_MESSAGE_USB_CONNECT = 'FIRMWARE_SBL_FOUND_POPUP_MESSAGE_USB_CONNECT';
export const FIRMWARE_SBL_FOUND_POPUP_MESSAGE_DOWNLOAD_APP = 'FIRMWARE_SBL_FOUND_POPUP_MESSAGE_DOWNLOAD_APP';
export const FIRMWARE_SBL_FOUND_POPUP_DOWNLOAD_APP_BUTTON = 'FIRMWARE_SBL_FOUND_POPUP_DOWNLOAD_APP_BUTTON';
export const FIRMWARE_SBL_FOUND_POPUP_RECONNECT_BUTTON = 'FIRMWARE_SBL_FOUND_POPUP_RECONNECT_BUTTON';
export const FIRMWARE_SBL_FOUND_POPUP_MESSAGE_MOBILE = 'FIRMWARE_SBL_FOUND_POPUP_MESSAGE_MOBILE';
export const FIRMWARE_SBL_FOUND_POPUP_MESSAGE_CONTACT_US = 'FIRMWARE_SBL_FOUND_POPUP_MESSAGE_CONTACT_US';
export const ABOUT_DEVICE_REGISTERING_STATUS_TEXT = 'ABOUT_DEVICE_REGISTERING_STATUS_TEXT';
export const ABOUT_DEVICE_REMOVE_DEVICE_TEXT = 'ABOUT_DEVICE_REMOVE_DEVICE_TEXT';
export const ABOUT_DEVICE_TITLE_TEXT = 'ABOUT_DEVICE_TITLE_TEXT';
export const HOLDER_IN_SBL_UPDATE_POPUP_DESCRIPTION_TEXT = 'HOLDER_IN_SBL_UPDATE_POPUP_DESCRIPTION_TEXT';
export const HOLDER_IN_SBL_UPDATE_POPUP_GO_TO_IQOS_COM_BUTTON_TEXT =
    'HOLDER_IN_SBL_UPDATE_POPUP_GO_TO_IQOS_COM_BUTTON_TEXT';
export const HOLDER_IN_SBL_UPDATE_POPUP_OK_GOT_IT_BUTTON_TEXT = 'HOLDER_IN_SBL_UPDATE_POPUP_OK_GOT_IT_BUTTON_TEXT';
export const ABOUT_DEVICE_UNREGISTERED_STATUS_TEXT = 'ABOUT_DEVICE_UNREGISTERED_STATUS_TEXT';
export const ABOUT_DEVICE_UNREGISTERING_STATUS_TEXT = 'ABOUT_DEVICE_UNREGISTERING_STATUS_TEXT';
export const ALL_ITEM_DESCRIPTION_TEXT = 'ALL_ITEM_DESCRIPTION_TEXT';
export const ALLEGRO_FLOW_TEXT = 'ALLEGRO_FLOW_TEXT';
export const ALLEGRO_FLOW_TILE_TEXT = 'ALLEGRO_FLOW_TILE_TEXT';
export const AMBIENT_TEMPERATURE_TOO_HIGH_ERROR_MESSAGE = 'AMBIENT_TEMPERATURE_TOO_HIGH_ERROR_MESSAGE';
export const AMBIENT_TEMPERATURE_TOO_LOW_ERROR_MESSAGE = 'AMBIENT_TEMPERATURE_TOO_LOW_ERROR_MESSAGE';
export const BATTERY_CHARGER_BQ24250_I2C_COMM_ERROR = 'BATTERY_CHARGER_BQ24250_I2C_COMM_ERROR';
export const BATTERY_CHARGER_FAULT = 'BATTERY_CHARGER_FAULT';
export const BATTERY_CHARGING_DURATION_EXCEEDED = 'BATTERY_CHARGING_DURATION_EXCEEDED';
export const BATTERY_VOLTAGE_TOO_LOW_ERROR_MESSAGE = 'BATTERY_VOLTAGE_TOO_LOW_ERROR_MESSAGE';
export const BEFORE_PAIRING_ACCEPT_BUTTON_TEXT = 'BEFORE_PAIRING_ACCEPT_BUTTON_TEXT';
export const BEFORE_PAIRING_CANCEL_BUTTON_TEXT = 'BEFORE_PAIRING_CANCEL_BUTTON_TEXT';
export const BEFORE_PAIRING_NOTIFICATION_TEXT = 'BEFORE_PAIRING_NOTIFICATION_TEXT';
export const BEFORE_PAIRING_TEXT = 'BEFORE_PAIRING_TEXT';
export const BEFORE_PAIRING_TITLE_TEXT = 'BEFORE_PAIRING_TITLE_TEXT';
export const BLE_PAIRING_FAIL_MESSAGE = 'BLE_PAIRING_FAIL_MESSAGE';
export const BLE_PAIRING_FAIL_TITLE = 'BLE_PAIRING_FAIL_TITLE';
export const USB_PAIRING_FAIL_TITLE = 'USB_PAIRING_FAIL_TITLE';
export const BQ24250_CHG_EN_PULL_DOWN_RES_TEST_FAILURE = 'BQ24250_CHG_EN_PULL_DOWN_RES_TEST_FAILURE';
export const BROWSER_VERIFICATION_TEXT = 'BROWSER_VERIFICATION_TEXT';
export const BROWSER_VERIFICATION_TITLE = 'BROWSER_VERIFICATION_TITLE';
export const BUBBLY_FLOW_TEXT = 'BUBBLY_FLOW_TEXT';
export const BUBBLY_FLOW_TILE_TEXT = 'BUBBLY_FLOW_TILE_TEXT';
export const CANCEL_YAP_PROGRESS_POPUP_CANCEL_BUTTON = 'CANCEL_YAP_PROGRESS_POPUP_CANCEL_BUTTON';
export const CANCEL_YAP_PROGRESS_POPUP_CONTINUE_BUTTON = 'CANCEL_YAP_PROGRESS_POPUP_CONTINUE_BUTTON';
export const CANCEL_YAP_PROGRESS_POPUP_MESSAGE = 'CANCEL_YAP_PROGRESS_POPUP_MESSAGE';
export const CANCEL_YAP_PROGRESS_POPUP_TITLE = 'CANCEL_YAP_PROGRESS_POPUP_TITLE';
export const CHARGER_ERROR_BANK_CHRG_DEFECT = 'CHARGER_ERROR_BANK_CHRG_DEFECT';
export const CHARGER_ERROR_CHARGE_TEMP_OUT_OF_RANGE_CHRGN_ERROR_MESSAGE =
    'CHARGER_ERROR_CHARGE_TEMP_OUT_OF_RANGE_CHRGN_ERROR_MESSAGE';
export const CHARGER_ERROR_CHARGE_TEMP_OUT_OF_RANGE_ERROR_MESSAGE =
    'CHARGER_ERROR_CHARGE_TEMP_OUT_OF_RANGE_ERROR_MESSAGE';
export const CHARGER_ERROR_CHARGER_BATTERY_CHARGER_DEFECT = 'CHARGER_ERROR_CHARGER_BATTERY_CHARGER_DEFECT';
export const CHARGER_ERROR_CHARGER_BATTERY_CHARGING_TIMEOUT_ERROR_MESSAGE =
    'CHARGER_ERROR_CHARGER_BATTERY_CHARGING_TIMEOUT_ERROR_MESSAGE';
export const CHARGER_ERROR_DOOR_SWITCH_FAILURE = 'CHARGER_ERROR_DOOR_SWITCH_FAILURE';
export const CHARGER_ERROR_HOLDER_BATTERY_CHARGER_DEFECT = 'CHARGER_ERROR_HOLDER_BATTERY_CHARGER_DEFECT';
export const CHARGER_ERROR_LED_DRIVER_FAILURE = 'CHARGER_ERROR_LED_DRIVER_FAILURE';
export const CHARGER_ERROR_SELF_TEST_FAILURE = 'CHARGER_ERROR_SELF_TEST_FAILURE';
export const CHARGER_ERROR_SELF_TEST_FAILURE_2_4 = 'CHARGER_ERROR_SELF_TEST_FAILURE_2_4';
export const CLEAN_INSTRUCTION_RESET_BUTTON_TEXT = 'CLEAN_INSTRUCTION_RESET_BUTTON_TEXT';
export const CLEAN_INSTRUCTION_RESET_NAVIGATION_BUTTON_TEXT = 'CLEAN_INSTRUCTION_RESET_NAVIGATION_BUTTON_TEXT';
export const CLEAN_INSTRUCTION_SCREEN1_DESCRIPTION_TEXT = 'CLEAN_INSTRUCTION_SCREEN1_DESCRIPTION_TEXT';
export const CLEAN_INSTRUCTION_SCREEN1_TITLE_TEXT = 'CLEAN_INSTRUCTION_SCREEN1_TITLE_TEXT';
export const CLEAN_INSTRUCTION_SCREEN2_DESCRIPTION_TEXT = 'CLEAN_INSTRUCTION_SCREEN2_DESCRIPTION_TEXT';
export const CLEAN_INSTRUCTION_SCREEN2_TITLE_TEXT = 'CLEAN_INSTRUCTION_SCREEN2_TITLE_TEXT';
export const CLEAN_INSTRUCTION_SCREEN3_DESCRIPTION_TEXT = 'CLEAN_INSTRUCTION_SCREEN3_DESCRIPTION_TEXT';
export const CLEAN_INSTRUCTION_SCREEN3_TITLE_TEXT = 'CLEAN_INSTRUCTION_SCREEN3_TITLE_TEXT';
export const CLEAN_INSTRUCTION_SCREEN4_DESCRIPTION_TEXT = 'CLEAN_INSTRUCTION_SCREEN4_DESCRIPTION_TEXT';
export const CLEAN_INSTRUCTION_SCREEN4_TITLE_TEXT = 'CLEAN_INSTRUCTION_SCREEN4_TITLE_TEXT';
export const CLEAN_INSTRUCTION_SCREEN5_DESCRIPTION_TEXT = 'CLEAN_INSTRUCTION_SCREEN5_DESCRIPTION_TEXT';
export const CLEAN_INSTRUCTION_SCREEN5_TITLE_TEXT = 'CLEAN_INSTRUCTION_SCREEN5_TITLE_TEXT';
export const APP_INTRO_PAGE_DIVIDER_TEXT = 'APP_INTRO_PAGE_DIVIDER_TEXT';
export const CLEAN_INSTRUCTION_SCREEN6_DESCRIPTION_TEXT = 'CLEAN_INSTRUCTION_SCREEN6_DESCRIPTION_TEXT';
export const CLEAN_INSTRUCTION_SCREEN6_TITLE_TEXT = 'CLEAN_INSTRUCTION_SCREEN6_TITLE_TEXT';
export const CLEAN_INSTRUCTION_TITLE_TEXT = 'CLEAN_INSTRUCTION_TITLE_TEXT';
export const CLEANING_FAILED_NOTIFICATION_TEXT = 'CLEANING_FAILED_NOTIFICATION_TEXT';
export const CLEANING_HOLDER_NOT_CHARGED_NOTIFICATION_TEXT = 'CLEANING_HOLDER_NOT_CHARGED_NOTIFICATION_TEXT';
export const CLEANING_MODE_DESCRIPTION = 'CLEANING_MODE_DESCRIPTION';
export const CLEANING_MODE_DESCRIPTION_USB_NOTIFICATION = 'CLEANING_MODE_DESCRIPTION_USB_NOTIFICATION';
export const CLEANING_MODE_TITLE = 'CLEANING_MODE_TITLE';
export const CLEANING_NOT_NEEDED_NOTIFICATION_TEXT = 'CLEANING_NOT_NEEDED_NOTIFICATION_TEXT';
export const CLEANING_SUCCESSFUL_NOTIFICATION_TEXT = 'CLEANING_SUCCESSFUL_NOTIFICATION_TEXT';
export const CLEANING_UNPLUGGED_NOTIFICATION_TEXT = 'CLEANING_UNPLUGGED_NOTIFICATION_TEXT';
export const CLOUD_SIZE_DESCRIPTION_TEXT = 'CLOUD_SIZE_DESCRIPTION_TEXT';
export const CLOUD_SIZE_NORMAL_BUTTON_TEXT = 'CLOUD_SIZE_NORMAL_BUTTON_TEXT';
export const CLOUD_SIZE_NORMAL_TEXT = 'CLOUD_SIZE_NORMAL_TEXT';
export const CLOUD_SIZE_POPUP_DESCRIPTION = 'CLOUD_SIZE_POPUP_DESCRIPTION';
export const CLOUD_SIZE_POPUP_TITLE = 'CLOUD_SIZE_POPUP_TITLE';
export const CLOUD_SIZE_REDUCED_BUTTON_TEXT = 'CLOUD_SIZE_REDUCED_BUTTON_TEXT';
export const CLOUD_SIZE_REDUCED_TEXT = 'CLOUD_SIZE_REDUCED_TEXT';
export const CLOUD_SIZE_TITLE_TEXT = 'CLOUD_SIZE_TITLE_TEXT';
export const CLOUD_TEXT = 'CLOUD_TEXT';
export const COMPATIBLE_BROWSER_PAGE_APPLE_BUTTON_TEXT = 'COMPATIBLE_BROWSER_PAGE_APPLE_BUTTON_TEXT';
export const COMPATIBLE_BROWSER_PAGE_CONTINUE_BUTTON_TEXT = 'COMPATIBLE_BROWSER_PAGE_CONTINUE_BUTTON_TEXT';
export const COMPATIBLE_BROWSER_PAGE_DESCRIPTION_ICON_TEXT = 'COMPATIBLE_BROWSER_PAGE_DESCRIPTION_ICON_TEXT';
export const COMPATIBLE_BROWSER_PAGE_NOT_COMPATIBLE_DESCRIPTION_ICON_TEXT =
    'COMPATIBLE_BROWSER_PAGE_NOT_COMPATIBLE_DESCRIPTION_ICON_TEXT';
export const COMPATIBLE_BROWSER_PAGE_DESCRIPTION_TEXT = 'COMPATIBLE_BROWSER_PAGE_DESCRIPTION_TEXT';
export const COMPATIBLE_BROWSER_PAGE_DOWNLOAD_DESCRIPTION_TEXT = 'COMPATIBLE_BROWSER_PAGE_DOWNLOAD_DESCRIPTION_TEXT';
export const COMPATIBLE_BROWSER_PAGE_GOOGLE_BUTTON_TEXT = 'COMPATIBLE_BROWSER_PAGE_GOOGLE_BUTTON_TEXT';
export const COMPATIBLE_BROWSER_PAGE_MACOS_BUTTON_TEXT = 'COMPATIBLE_BROWSER_PAGE_MACOS_BUTTON_TEXT';
export const COMPATIBLE_BROWSER_PAGE_TITLE_TEXT = 'COMPATIBLE_BROWSER_PAGE_TITLE_TEXT';
export const COMPATIBLE_BROWSER_PAGE_TITLE_TEXT_CONNECT = 'COMPATIBLE_BROWSER_PAGE_TITLE_TEXT_CONNECT';
export const COMPATIBLE_BROWSER_PAGE_TITLE_TEXT_DESKTOP = 'COMPATIBLE_BROWSER_PAGE_TITLE_TEXT_DESKTOP';
export const COMPATIBLE_BROWSER_PAGE_TITLE_TEXT_MOBILE = 'COMPATIBLE_BROWSER_PAGE_TITLE_TEXT_MOBILE';
export const COMPATIBLE_BROWSER_PAGE_WINDOWS_BUTTON_TEXT = 'COMPATIBLE_BROWSER_PAGE_WINDOWS_BUTTON_TEXT';
export const CORTEZ_FIRST_ITEM_TITLE = 'CORTEZ_FIRST_ITEM_TITLE';
export const CORTEZ_FOURTH_ITEM_TITLE = 'CORTEZ_FOURTH_ITEM_TITLE';
export const CORTEZ_SECOND_ITEM_TITLE = 'CORTEZ_SECOND_ITEM_TITLE';
export const CORTEZ_THIRD_ITEM_TITLE = 'CORTEZ_THIRD_ITEM_TITLE';
export const CRESCENDO_FLOW_TEXT = 'CRESCENDO_FLOW_TEXT';
export const CRESCENDO_FLOW_TILE_TEXT = 'CRESCENDO_FLOW_TILE_TEXT';
export const DATA_COLLECTION_CONFIRM_BUTTON_TEXT = 'DATA_COLLECTION_CONFIRM_BUTTON_TEXT';
export const DATA_COLLECTION_DECLINE_BUTTON_TEXT = 'DATA_COLLECTION_DECLINE_BUTTON_TEXT';
export const DATA_COLLECTION_PREFERENCE_CENTER_BUTTON_TEXT = 'DATA_COLLECTION_PREFERENCE_CENTER_BUTTON_TEXT';
export const DATA_COLLECTION_TITLE_TEXT = 'DATA_COLLECTION_TITLE_TEXT';
export const DATA_COLLECTION_UPDATE_TITLE_TEXT = 'DATA_COLLECTION_UPDATE_TITLE_TEXT';
export const DEVICE_ALREADY_OWNED_MESSAGE = 'DEVICE_ALREADY_OWNED_MESSAGE';
export const DEVICE_ALREADY_OWNED_TITLE = 'DEVICE_ALREADY_OWNED_TITLE';
export const PURCHASE_DATE_INVALID_MESSAGE = 'PURCHASE_DATE_INVALID_MESSAGE';
export const PURCHASE_DATE_INVALID_TITLE = 'PURCHASE_DATE_INVALID_TITLE';
export const UNKNOWN_EXTERNAL_ERROR_MESSAGE = 'UNKNOWN_EXTERNAL_ERROR_MESSAGE';
export const UNKNOWN_EXTERNAL_ERROR_TITLE = 'UNKNOWN_EXTERNAL_ERROR_TITLE';
export const DEVICE_CAROUSEL_CORTEZ_NAME = 'DEVICE_CAROUSEL_CORTEZ_NAME';
export const DEVICE_CAROUSEL_ORDINARY_NAME = 'DEVICE_CAROUSEL_ORDINARY_NAME';
export const DEVICE_CAROUSEL_SOYUZ_NAME = 'DEVICE_CAROUSEL_SOYUZ_NAME';
export const DEVICE_CAROUSEL_P1V40D_NAME = 'DEVICE_CAROUSEL_P1V40D_NAME';
export const DEVICE_CAROUSEL_TITAN_NAME = 'DEVICE_CAROUSEL_TITAN_NAME';
export const DEVICE_CAROUSEL_P1V40_NAME = 'DEVICE_CAROUSEL_P1V40_NAME';
export const DEVICE_CAROUSEL_P1V40M_NAME = 'DEVICE_CAROUSEL_P1V40M_NAME';
export const DEVICE_CAROUSEL_VESPUCCI_NAME = 'DEVICE_CAROUSEL_VESPUCCI_NAME';
export const DEVICE_CONNECTION_STATUS_PAGE_DEVICE_SETTINGS = 'DEVICE_CONNECTION_STATUS_PAGE_DEVICE_SETTINGS';
export const DEVICE_DIAGNOSTIC_RESULT_NO_ERROR_TITLE = 'DEVICE_DIAGNOSTIC_RESULT_NO_ERROR_TITLE';
export const DEVICE_EXPECTATION_REGISTER__TEXT = 'DEVICE_EXPECTATION_REGISTER__TEXT';
export const DEVICE_LIST_CHOOSER_TITLE = 'DEVICE_LIST_CHOOSER_TITLE';
export const DEVICE_LIST_DEVICES_AVAILABLE_ADD_DEVICE_BUTTON_TEXT =
    'DEVICE_LIST_DEVICES_AVAILABLE_ADD_DEVICE_BUTTON_TEXT';
export const DEVICE_LIST_DEVICES_AVAILABLE_DEVICE_SETTINGS_TEXT = 'DEVICE_LIST_DEVICES_AVAILABLE_DEVICE_SETTINGS_TEXT';
export const DEVICE_LIST_DEVICES_AVAILABLE_PAIR_BUTTON_TEXT = 'DEVICE_LIST_DEVICES_AVAILABLE_PAIR_BUTTON_TEXT';
export const DEVICE_LIST_NO_DEVICE_DESCRIPTION_TEXT = 'DEVICE_LIST_NO_DEVICE_DESCRIPTION_TEXT';
export const DEVICE_LIST_NO_DEVICE_FEATURE_DESCRIPTION_TEXT = 'DEVICE_LIST_NO_DEVICE_FEATURE_DESCRIPTION_TEXT';
export const DEVICE_LIST_NO_DEVICE_LEARN_MORE_BUTTON_TEXT = 'DEVICE_LIST_NO_DEVICE_LEARN_MORE_BUTTON_TEXT';
export const DEVICE_LIST_NO_DEVICE_SET_UP_BUTTON_TEXT = 'DEVICE_LIST_NO_DEVICE_SET_UP_BUTTON_TEXT';
export const DEVICE_LIST_NO_DEVICE_TITLE_TEXT = 'DEVICE_LIST_NO_DEVICE_TITLE_TEXT';
export const DEVICE_LOCK_LOCKED_POPUP_BODY_TEXT = 'DEVICE_LOCK_LOCKED_POPUP_BODY_TEXT';
export const DEVICE_LOCK_LOCKED_POPUP_CANCEL_BUTTON = 'DEVICE_LOCK_LOCKED_POPUP_CANCEL_BUTTON';
export const DEVICE_LOCK_LOCKED_POPUP_HEADER_TEXT = 'DEVICE_LOCK_LOCKED_POPUP_HEADER_TEXT';
export const DEVICE_LOCK_LOCKED_POPUP_UNLOCK_BUTTON = 'DEVICE_LOCK_LOCKED_POPUP_UNLOCK_BUTTON';
export const DEVICE_LOCK_STATUS_LOCKED = 'DEVICE_LOCK_STATUS_LOCKED';
export const DEVICE_LOCK_STATUS_UNLOCKED = 'DEVICE_LOCK_STATUS_UNLOCKED';
export const DEVICE_LOCK_UNLOCKED_NOTIFICATION = 'DEVICE_LOCK_UNLOCKED_NOTIFICATION';
export const DEVICE_LOCK_UNLOCKED_POPUP_HEADER_TEXT = 'DEVICE_LOCK_UNLOCKED_POPUP_HEADER_TEXT';
export const DEVICE_LOCK_UNLOCKED_POPUP_ROW1_TEXT = 'DEVICE_LOCK_UNLOCKED_POPUP_ROW1_TEXT';
export const DEVICE_LOCK_UNLOCKED_POPUP_ROW2_FIRST_WORD = 'DEVICE_LOCK_UNLOCKED_POPUP_ROW2_FIRST_WORD';
export const DEVICE_LOCK_UNLOCKED_POPUP_ROW2_TEXT = 'DEVICE_LOCK_UNLOCKED_POPUP_ROW2_TEXT';
export const DEVICE_LOCK_UNLOCKED_POPUP_TIME_BUTTON_TEXT = 'DEVICE_LOCK_UNLOCKED_POPUP_TIME_BUTTON_TEXT';
export const DEVICE_NOT_IDENTIFIABLE = 'DEVICE_NOT_IDENTIFIABLE';
export const DEVICE_REGISTER_SUCCESSFUL_TEXT = 'DEVICE_REGISTER_SUCCESSFUL_TEXT';
export const DEVICE_REMOVED_FROM_MASTER_DB_MESSAGE = 'DEVICE_REMOVED_FROM_MASTER_DB_MESSAGE';
export const DEVICE_REMOVED_FROM_MASTER_DB_TITLE = 'DEVICE_REMOVED_FROM_MASTER_DB_TITLE';
export const DEVICE_SETTINGS_ABOUT_DEVICE = 'DEVICE_SETTINGS_ABOUT_DEVICE';
export const DEVICE_SETTINGS_CLEANING_MODE = 'DEVICE_SETTINGS_CLEANING_MODE';
export const DEVICE_SETTINGS_CLOUD = 'DEVICE_SETTINGS_CLOUD';
export const DEVICE_SETTINGS_CONNECT_NOTIFICATION_TEXT = 'DEVICE_SETTINGS_CONNECT_NOTIFICATION_TEXT';
export const DEVICE_SETTINGS_DIAGNOSTIC = 'DEVICE_SETTINGS_DIAGNOSTIC';
export const DEVICE_SETTINGS_FIND_DEVICE = 'DEVICE_SETTINGS_FIND_DEVICE';
export const DEVICE_SETTINGS_INSERT_HOLDER_NOTIFICATION_TEXT = 'DEVICE_SETTINGS_INSERT_HOLDER_NOTIFICATION_TEXT';
export const USB_DEVICE_SETTINGS_INSERT_HOLDER_NOTIFICATION_TEXT =
    'USB_DEVICE_SETTINGS_INSERT_HOLDER_NOTIFICATION_TEXT';
export const DEVICE_SETTINGS_LED_MODE = 'DEVICE_SETTINGS_LED_MODE';
export const DEVICE_SETTINGS_LEGAL_STATEMENTS = 'DEVICE_SETTINGS_LEGAL_STATEMENTS';
export const DEVICE_SETTINGS_PAIR = 'DEVICE_SETTINGS_PAIR';
export const DEVICE_SETTINGS_PREFERENCE_CENTER = 'DEVICE_SETTINGS_PREFERENCE_CENTER';
export const DEVICE_SETTINGS_RENAME_DEVICE = 'DEVICE_SETTINGS_RENAME_DEVICE';
export const DEVICE_SETTINGS_RESPONSIVE = 'DEVICE_SETTINGS_RESPONSIVE';
export const DEVICE_SETTINGS_TIPS = 'DEVICE_SETTINGS_TIPS';
export const DEVICE_SETTINGS_TIPS_MORE = 'DEVICE_SETTINGS_TIPS_MORE';
export const DEVICE_SETTINGS_UNPAIR = 'DEVICE_SETTINGS_UNPAIR';
export const DEVICE_SETTINGS_USB_UNPAIR = 'DEVICE_SETTINGS_USB_UNPAIR';
export const DEVICE_SETTINGS_UPDATE_DEVICE_NOTIFICATION_TEXT = 'DEVICE_SETTINGS_UPDATE_DEVICE_NOTIFICATION_TEXT';
export const DEVICE_SETTINGS_UPDATE_DEVICE_WITH_HOLDER_NOTIFICATION_TEXT =
    'DEVICE_SETTINGS_UPDATE_DEVICE_WITH_HOLDER_NOTIFICATION_TEXT';
export const DEVICE_SETTINGS_USING_HEADER = 'DEVICE_SETTINGS_USING_HEADER';
export const DEVICE_SETTINGS_VIBRATE_MODE = 'DEVICE_SETTINGS_VIBRATE_MODE';
export const DEVICE_SETTINGS_GLOBAL_ERROR = 'DEVICE_SETTINGS_GLOBAL_ERROR';
export const DEVICE_STATUS_CONNECTED = 'DEVICE_STATUS_CONNECTED';
export const DEVICE_STATUS_DISCONNECTED = 'DEVICE_STATUS_DISCONNECTED';
export const DEVICE_UNREGISTER_SUCCESSFUL_TEXT = 'DEVICE_UNREGISTER_SUCCESSFUL_TEXT';
export const DUTY_CYCLE_TOO_LOW_WHILE_HEATING_ERROR_MESSAGE = 'DUTY_CYCLE_TOO_LOW_WHILE_HEATING_ERROR_MESSAGE';
export const EEPROM_INT_FAILURE = 'EEPROM_INT_FAILURE';
export const ENV_AMB_TEMP_HIGH_ERROR_MESSAGE = 'ENV_AMB_TEMP_HIGH_ERROR_MESSAGE';
export const ENV_AMB_TEMP_LOW_ERROR_MESSAGE = 'ENV_AMB_TEMP_LOW_ERROR_MESSAGE';
export const ENV_HIGH_MCU_VOLTAGE = 'ENV_HIGH_MCU_VOLTAGE';
export const ENV_LOW_MCU_VOLTAGE_ERROR_MESSAGE = 'ENV_LOW_MCU_VOLTAGE_ERROR_MESSAGE';
export const ERR_BAT_TEMP_HIGH_ERROR_MESSAGE = 'ERR_BAT_TEMP_HIGH_ERROR_MESSAGE';
export const ERR_BAT_TEMP_LOW_ERROR_MESSAGE = 'ERR_BAT_TEMP_LOW_ERROR_MESSAGE';
export const ERR_BAT_VOLTAGE_ERROR_MESSAGE = 'ERR_BAT_VOLTAGE_ERROR_MESSAGE';
export const ERR_BLE_INIT = 'ERR_BLE_INIT';
export const ERR_CHRG_CONFIG = 'ERR_CHRG_CONFIG';
export const ERR_CHRG_DRV_I2C = 'ERR_CHRG_DRV_I2C';
export const ERR_CHRG_FAILURE = 'ERR_CHRG_FAILURE';
export const ERR_CHRG_INIT = 'ERR_CHRG_INIT';
export const ERR_DISPLAY_INIT = 'ERR_DISPLAY_INIT';
export const ERR_EEPROM_DRV = 'ERR_EEPROM_DRV';
export const ERR_FLASH_DRV = 'ERR_FLASH_DRV';
export const ERR_FLASH_INIT = 'ERR_FLASH_INIT';
export const ERR_I2C_BUS = 'ERR_I2C_BUS';
export const ERR_LED_DRV = 'ERR_LED_DRV';
export const ERR_POST_FAIL = 'ERR_POST_FAIL';
export const ERR_SYS_VOLTAGE_ERROR_MESSAGE = 'ERR_SYS_VOLTAGE_ERROR_MESSAGE';
export const ERROR_EXT_ENERGY_HEATING_ERROR_MESSAGE = 'ERROR_EXT_ENERGY_HEATING_ERROR_MESSAGE';
export const ERROR_HCM_SWITCHES_FAILED = 'ERROR_HCM_SWITCHES_FAILED';
export const ERROR_HEATER_PMOS_OPEN = 'ERROR_HEATER_PMOS_OPEN';
export const ERROR_HEATER_RESISTANCE = 'ERROR_HEATER_RESISTANCE';
export const ERROR_HIGH_TEMP_ERROR_MESSAGE = 'ERROR_HIGH_TEMP_ERROR_MESSAGE';
export const ERROR_LOW_BATT_ERROR_MESSAGE = 'ERROR_LOW_BATT_ERROR_MESSAGE';
export const ERROR_LOW_TEMP_ERROR_MESSAGE = 'ERROR_LOW_TEMP_ERROR_MESSAGE';
export const ERROR_NMOS_SHORTED = 'ERROR_NMOS_SHORTED';
export const ERROR_OVERHEATING_ERROR_MESSAGE = 'ERROR_OVERHEATING_ERROR_MESSAGE';
export const ERROR_RESISTOR_CHAIN = 'ERROR_RESISTOR_CHAIN';
export const EXPLORE_BUTTON_TEXT = 'EXPLORE_BUTTON_TEXT';
export const EXT_VOLTAGE_REF_FAILURE = 'EXT_VOLTAGE_REF_FAILURE';
export const EXTERNAL_SYSTEM_UNAVAILABLE_MESSAGE = 'EXTERNAL_SYSTEM_UNAVAILABLE_MESSAGE';
export const EXTERNAL_SYSTEM_UNAVAILABLE_TITLE = 'EXTERNAL_SYSTEM_UNAVAILABLE_TITLE';
export const FAQ_TITLE_TEXT = 'FAQ_TITLE_TEXT';
export const FIND_DEVICE_MODE_TITLE = 'FIND_DEVICE_MODE_TITLE';
export const FIND_DEVICE_MODE_VIBRATE_DESCRIPTION = 'FIND_DEVICE_MODE_VIBRATE_DESCRIPTION';
export const FIND_DEVICE_MODE_VIBRATE_DESCRIPTION_USB = 'FIND_DEVICE_MODE_VIBRATE_DESCRIPTION_USB';
export const FIND_DEVICE_MODE_WARNING_NOTIFICATION_TEXT = 'FIND_DEVICE_MODE_WARNING_NOTIFICATION_TEXT';
export const FIRMWARE_DOWNLOADING_FAILED_POPUP_MESSAGE = 'FIRMWARE_DOWNLOADING_FAILED_POPUP_MESSAGE';
export const FIRMWARE_DOWNLOADING_FAILED_YAP_POPUP_DO_IT_LATER_BUTTON =
    'FIRMWARE_DOWNLOADING_FAILED_YAP_POPUP_DO_IT_LATER_BUTTON';
export const FIRMWARE_DOWNLOADING_FAILED_YAP_POPUP_MESSAGE = 'FIRMWARE_DOWNLOADING_FAILED_YAP_POPUP_MESSAGE';
export const FIRMWARE_DOWNLOADING_FAILED_YAP_POPUP_TITLE = 'FIRMWARE_DOWNLOADING_FAILED_YAP_POPUP_TITLE';
export const FIRMWARE_DOWNLOADING_FAILED_YAP_POPUP_TRY_AGAIN_BUTTON =
    'FIRMWARE_DOWNLOADING_FAILED_YAP_POPUP_TRY_AGAIN_BUTTON';
export const FIRMWARE_INSTALL_CONNECTION_FAILED_CANCEL_BUTTON_TEXT =
    'FIRMWARE_INSTALL_CONNECTION_FAILED_CANCEL_BUTTON_TEXT';
export const FIRMWARE_INSTALL_CONNECTION_FAILED_DESCRIPTION = 'FIRMWARE_INSTALL_CONNECTION_FAILED_DESCRIPTION';
export const FIRMWARE_INSTALL_CONNECTION_FAILED_TITLE = 'FIRMWARE_INSTALL_CONNECTION_FAILED_TITLE';
export const FIRMWARE_INSTALL_CONNECTION_FAILED_TRY_AGAIN_BUTTON_TEXT =
    'FIRMWARE_INSTALL_CONNECTION_FAILED_TRY_AGAIN_BUTTON_TEXT';
export const FIRMWARE_INSTALL_DESCRIPTION_PART_1 = 'FIRMWARE_INSTALL_DESCRIPTION_PART_1';
export const FIRMWARE_INSTALL_DESCRIPTION_PART_2 = 'FIRMWARE_INSTALL_DESCRIPTION_PART_2';
export const FIRMWARE_INSTALL_ESTIMATING_TIME = 'FIRMWARE_INSTALL_ESTIMATING_TIME';
export const FIRMWARE_INSTALL_FAILED_CANCEL_BUTTON_TEXT = 'FIRMWARE_INSTALL_FAILED_CANCEL_BUTTON_TEXT';
export const FIRMWARE_INSTALL_FAILED_MESSAGE = 'FIRMWARE_INSTALL_FAILED_MESSAGE';
export const FIRMWARE_INSTALL_USB_FAILED_MESSAGE = 'FIRMWARE_INSTALL_USB_FAILED_MESSAGE';
export const FIRMWARE_CHECK_FAILED_MESSAGE = 'FIRMWARE_CHECK_FAILED_MESSAGE';
export const FIRMWARE_INSTALL_FAILED_TITLE = 'FIRMWARE_INSTALL_FAILED_TITLE';
export const FIRMWARE_INSTALL_FAILED_TRY_AGAIN_BUTTON_TEXT = 'FIRMWARE_INSTALL_FAILED_TRY_AGAIN_BUTTON_TEXT';
export const FIRMWARE_INSTALL_REMAINING_TIME = 'FIRMWARE_INSTALL_REMAINING_TIME';
export const FIRMWARE_INSTALL_TITLE = 'FIRMWARE_INSTALL_TITLE';
export const FIRMWARE_SBL_FOUND_POPUP_GOT_IT_BUTTON = 'FIRMWARE_SBL_FOUND_POPUP_GOT_IT_BUTTON';
export const FIRMWARE_SBL_FOUND_POPUP_TITLE = 'FIRMWARE_SBL_FOUND_POPUP_TITLE';
export const FIRMWARE_UPDATE_DEVICE_BATTERY_LOW_NOTIFICATION_TEXT =
    'FIRMWARE_UPDATE_DEVICE_BATTERY_LOW_NOTIFICATION_TEXT';
export const FIRMWARE_UPDATE_NOT_NEEDED_CONFIRM_TEXT = 'FIRMWARE_UPDATE_NOT_NEEDED_CONFIRM_TEXT';
export const FIRMWARE_UPDATE_NOT_NEEDED_DESCRIPTION = 'FIRMWARE_UPDATE_NOT_NEEDED_DESCRIPTION';
export const FIRMWARE_UPDATE_NOT_NEEDED_TITLE = 'FIRMWARE_UPDATE_NOT_NEEDED_TITLE';
export const FIRMWARE_UPDATE_SUCCESS = 'FIRMWARE_UPDATE_SUCCESS';
export const GLOBAL_BACK_BUTTON_TEXT = 'GLOBAL_BACK_BUTTON_TEXT';
export const GLOBAL_DETECTOR_OFFLINE = 'GLOBAL_DETECTOR_OFFLINE';
export const GLOBAL_DETECTOR_ONLINE = 'GLOBAL_DETECTOR_ONLINE';
export const GLOBAL_ERROR_NOTIFICATION_TEXT = 'GLOBAL_ERROR_NOTIFICATION_TEXT';
export const GLOBAL_ERROR_NOTIFICATION_CONNECT_TEXT = 'GLOBAL_ERROR_NOTIFICATION_CONNECT_TEXT';
export const GLOBAL_LOADER_DEFAULT_TEXT = 'GLOBAL_LOADER_DEFAULT_TEXT';
export const GLOBAL_LOADER_DEFAULT_TEXT_MAIN = 'GLOBAL_LOADER_DEFAULT_TEXT_MAIN';
export const GLOBAL_LOADER_DEFAULT_TEXT_SECONDARY = 'GLOBAL_LOADER_DEFAULT_TEXT_SECONDARY';
export const HAPTIC_MANAGEMENT_ALMOST_ENDED_TITLE_TEXT_KEY = 'HAPTIC_MANAGEMENT_ALMOST_ENDED_TITLE_TEXT_KEY';
export const HAPTIC_MANAGEMENT_DESCRIPTION = 'HAPTIC_MANAGEMENT_DESCRIPTION';
export const HAPTIC_MANAGEMENT_ENDED_TITLE_TEXT_KEY = 'HAPTIC_MANAGEMENT_ENDED_TITLE_TEXT_KEY';
export const HAPTIC_MANAGEMENT_FULLY_HEATED_TITLE_TEXT_KEY = 'HAPTIC_MANAGEMENT_FULLY_HEATED_TITLE_TEXT_KEY';
export const HAPTIC_MANAGEMENT_HEAT_TITLE = 'HAPTIC_MANAGEMENT_HEAT_TITLE';
export const HAPTIC_MANAGEMENT_HOLDER_TITLE = 'HAPTIC_MANAGEMENT_HOLDER_TITLE';
export const HAPTIC_MANAGEMENT_START_HEATING_TITLE_TEXT_KEY = 'HAPTIC_MANAGEMENT_START_HEATING_TITLE_TEXT_KEY';
export const HAPTIC_MANAGEMENT_TITLE = 'HAPTIC_MANAGEMENT_TITLE';
export const HCM_HMG_PULL_DOWN_RES_TEST_FAILURE = 'HCM_HMG_PULL_DOWN_RES_TEST_FAILURE';
export const HEATER_RESISTANCE_FAULT_DETECTED = 'HEATER_RESISTANCE_FAULT_DETECTED';
export const HEATER_TEMPERATURE_ABOVE_LIMIT_ERROR_MESSAGE = 'HEATER_TEMPERATURE_ABOVE_LIMIT_ERROR_MESSAGE';
export const HEATING_DURATION_EXCEEDED = 'HEATING_DURATION_EXCEEDED';
export const HEATING_HCM_SWITCHES_ERROR = 'HEATING_HCM_SWITCHES_ERROR';
export const HEATING_REGULATION_HEATER_CURRENT_HIGH = 'HEATING_REGULATION_HEATER_CURRENT_HIGH';
export const HEATING_REGULATION_HEATER_CURRENT_LOW = 'HEATING_REGULATION_HEATER_CURRENT_LOW';
export const HEATING_REGULATION_HEATER_POWER_OUT_RANGE_ERROR_MESSAGE =
    'HEATING_REGULATION_HEATER_POWER_OUT_RANGE_ERROR_MESSAGE';
export const HEATING_REGULATION_HEATER_TEMP_ABOVE_LIMIT_ERROR_MESSAGE =
    'HEATING_REGULATION_HEATER_TEMP_ABOVE_LIMIT_ERROR_MESSAGE   ';
export const HEATING_SYSTEM_FAULT_CANT_START_HW_SAFETY = 'HEATING_SYSTEM_FAULT_CANT_START_HW_SAFETY';
export const HEATING_SYSTEM_FAULT_HW_SAFETY_TRIGGERED = 'HEATING_SYSTEM_FAULT_HW_SAFETY_TRIGGERED';
export const HEATING_SYSTEM_FAULT_NO_VOLTAGE_VH_MINUS_SW_ON = 'HEATING_SYSTEM_FAULT_NO_VOLTAGE_VH_MINUS_SW_ON';
export const HEATING_SYSTEM_FAULT_NO_VOLTAGE_VH_PLUS_SW_ON = 'HEATING_SYSTEM_FAULT_NO_VOLTAGE_VH_PLUS_SW_ON';
export const HEATING_SYSTEM_FAULT_POWER_DIST_FAILURE = 'HEATING_SYSTEM_FAULT_POWER_DIST_FAILURE';
export const HEATING_SYSTEM_FAULT_UNEXP_VOLTAGE_SW_OFF = 'HEATING_SYSTEM_FAULT_UNEXP_VOLTAGE_SW_OFF';
export const HEATING_TIMEOUT_ERROR = 'HEATING_TIMEOUT_ERROR';
export const HOLDER_CANNOT_COMMUNICATE_WITH_FUEL_GAUGE = 'HOLDER_CANNOT_COMMUNICATE_WITH_FUEL_GAUGE';
export const HOLDER_ERROR_HLDR_CHARGER_DEFECT = 'HOLDER_ERROR_HLDR_CHARGER_DEFECT';
export const HOLDER_ERROR_HLDR_CHRG_ERROR = 'HOLDER_ERROR_HLDR_CHRG_ERROR';
export const HOLDER_ERROR_HLDR_CHRG_TIMEOUT = 'HOLDER_ERROR_HLDR_CHRG_TIMEOUT';
export const HOLDER_ERROR_HLDR_COMM_ERROR = 'HOLDER_ERROR_HLDR_COMM_ERROR';
export const HOLDER_ERROR_HLDR_TEMPERATURE_ERROR_MESSAGE = 'HOLDER_ERROR_HLDR_TEMPERATURE_ERROR_MESSAGE';
export const HOLDER_ERROR_HOLDER_BATTERY_CHARGING_TIMEOUT = 'HOLDER_ERROR_HOLDER_BATTERY_CHARGING_TIMEOUT';
export const HOLDER_ERROR_HOLDER_BATTERY_TEMP_OUTSIDE_RANGE_ERROR_MESSAGE =
    'HOLDER_ERROR_HOLDER_BATTERY_TEMP_OUTSIDE_RANGE_ERROR_MESSAGE';
export const HOLDER_ERROR_HOLDER_IDENTIFICATION_FAILURE = 'HOLDER_ERROR_HOLDER_IDENTIFICATION_FAILURE';
export const HOLDER_ERROR_HOLDER_SYSTEM_DEFECT = 'HOLDER_ERROR_HOLDER_SYSTEM_DEFECT';
export const HOLDER_ERROR_IDENTIFICATION_FAILURE = 'HOLDER_ERROR_IDENTIFICATION_FAILURE';
export const HOLDER_ERROR_SYSTEM_DEFECT = 'HOLDER_ERROR_SYSTEM_DEFECT';
export const HOLDER_START_CHARGING_VIBRATION_TITLE = 'HOLDER_START_CHARGING_VIBRATION_TITLE';
export const HOLDER_WARNING_AUTH_FAILURE = 'HOLDER_WARNING_AUTH_FAILURE';
export const HOLDER_WARNING_BAD_COMM = 'HOLDER_WARNING_BAD_COMM';
export const HOLDER_WARNING_END_OF_LIFE = 'HOLDER_WARNING_END_OF_LIFE';
export const HOLDER_WARNING_HOLDER_BATTERY_AGED_ERROR_MESSAGE = 'HOLDER_WARNING_HOLDER_BATTERY_AGED_ERROR_MESSAGE';
export const HOLDER_WARNING_HOLDER_COMMUNICATION_PROBLEM = 'HOLDER_WARNING_HOLDER_COMMUNICATION_PROBLEM';
export const HOLDER_WARNING_NULL_HOLDER_CHARGING_CURRENT = 'HOLDER_WARNING_NULL_HOLDER_CHARGING_CURRENT';
export const HW_ISSUES_PWR_DIST_FAILURE = 'HW_ISSUES_PWR_DIST_FAILURE';
export const INTERNAL_ERR_ADC_COMP_TIMEOUT = 'INTERNAL_ERR_ADC_COMP_TIMEOUT';
export const INVALID_CALIBRATION_DATA_CRC = 'INVALID_CALIBRATION_DATA_CRC';
export const INVALID_CODENTIFYID_MESSAGE = 'INVALID_CODENTIFYID_MESSAGE';
export const INVALID_CODENTIFYID_TITLE = 'INVALID_CODENTIFYID_TITLE';
export const INVALID_DEVICE_STATUS_MESSAGE = 'INVALID_DEVICE_STATUS_MESSAGE';
export const INVALID_DEVICE_STATUS_TITLE = 'INVALID_DEVICE_STATUS_TITLE';
export const INVALID_MANUF_INFO_BLOCK_CRC = 'INVALID_MANUF_INFO_BLOCK_CRC';
export const IO_ERROR_NOTIFICATION_TEXT = 'IO_ERROR_NOTIFICATION_TEXT';
export const IO_ERROR_USB_NOTIFICATION_TEXT = 'IO_ERROR_USB_NOTIFICATION_TEXT';
export const JTAG_RDP_LEVEL_ERROR = 'JTAG_RDP_LEVEL_ERROR';
export const LEARN_HOW_TO_USE = 'LEARN_HOW_TO_USE';
export const LED_CHARGER_NOT_CHANGED_NOTIFICATION_TEXT = 'LED_CHARGER_NOT_CHANGED_NOTIFICATION_TEXT';
export const LED_DRV_LM3535_I2C_COMM_ERROR = 'LED_DRV_LM3535_I2C_COMM_ERROR';
export const LED_DRV_VLED_EN_PULL_DOWN_TEST_FAILURE = 'LED_DRV_VLED_EN_PULL_DOWN_TEST_FAILURE';
export const LED_HOLDER_NOT_CHANGED_NOTIFICATION_TEXT = 'LED_HOLDER_NOT_CHANGED_NOTIFICATION_TEXT';
export const LED_MODE_DESCRIPTION = 'LED_MODE_DESCRIPTION';
export const LED_MODE_LEFT_BUTTON_TEXT = 'LED_MODE_LEFT_BUTTON_TEXT';
export const LED_MODE_RIGHT_BUTTON_TEXT = 'LED_MODE_RIGHT_BUTTON_TEXT';
export const LED_MODE_TITLE = 'LED_MODE_TITLE';
export const LOCK_BUTTON_TEXT = 'LOCK_BUTTON_TEXT';
export const LOCK_POPUP_CANCEL_BUTTON_TEXT = 'LOCK_POPUP_CANCEL_BUTTON_TEXT';
export const LOCK_POPUP_LOCK_BUTTON_TEXT = 'LOCK_POPUP_LOCK_BUTTON_TEXT';
export const LOCK_POPUP_TITLE = 'LOCK_POPUP_TITLE';
export const LOCK_POPUP_TITLE_DESCRIPTION = 'LOCK_POPUP_TITLE_DESCRIPTION';
export const P1V40_HOLDER_LOCK_POPUP_TITLE_DESCRIPTION = 'P1V40_HOLDER_LOCK_POPUP_TITLE_DESCRIPTION';
export const LOW_SIDE_FAULT_DETECTED = 'LOW_SIDE_FAULT_DETECTED';
export const MULTI_FIRST_ITEM_TITLE = 'MULTI_FIRST_ITEM_TITLE';
export const MULTI_FOURTH_ITEM_TITLE = 'MULTI_FOURTH_ITEM_TITLE';
export const MULTI_SECOND_ITEM_TITLE = 'MULTI_SECOND_ITEM_TITLE';
export const MULTI_THIRD_ITEM_TITLE = 'MULTI_THIRD_ITEM_TITLE';
export const NOT_BROWSER_BUTTON_TEXT = 'NOT_BROWSER_BUTTON_TEXT';
export const NOT_BROWSER_COMP_HEADER_TEXT = 'NOT_BROWSER_COMP_HEADER_TEXT';
export const NOT_BROWSER_COMP_HEADER_TEXT_BOLD = 'NOT_BROWSER_COMP_HEADER_TEXT_BOLD';
export const NOT_BROWSER_COMP_INSTRUCTION_TITLE = 'NOT_BROWSER_COMP_INSTRUCTION_TITLE';
export const NOT_BROWSER_COMP_STEP_1 = 'NOT_BROWSER_COMP_STEP_1';
export const NOT_BROWSER_COMP_STEP_2 = 'NOT_BROWSER_COMP_STEP_2';
export const NOT_BROWSER_COMP_STEP_3 = 'NOT_BROWSER_COMP_STEP_3';
export const NOT_COMP_ANDROID_APP_BUTTON_TEXT = 'NOT_COMP_ANDROID_APP_BUTTON_TEXT';
export const NOT_COMP_BLUEFY_APP_BUTTON_TEXT = 'NOT_COMP_BLUEFY_APP_BUTTON_TEXT';
export const NOT_COMP_DESKTOP_DESCRIPTION_TEXT = 'NOT_COMP_DESKTOP_DESCRIPTION_TEXT';
export const NOT_COMP_DESKTOP_DOWNLOAD_TEXT = 'NOT_COMP_DESKTOP_DOWNLOAD_TEXT';
export const NOT_COMP_MAC_WIDGET_APP_BUTTON_TEXT = 'NOT_COMP_MAC_WIDGET_APP_BUTTON_TEXT';
export const NOT_COMP_WINDOWS_WIDGET_APP_BUTTON_TEXT = 'NOT_COMP_WINDOWS_WIDGET_APP_BUTTON_TEXT';
export const NOT_FOUND_MESSAGE = 'NOT_FOUND_MESSAGE';
export const NOT_FOUND_TITLE = 'NOT_FOUND_TITLE';
export const OFF_FLOW_TEXT = 'OFF_FLOW_TEXT';
export const OFF_FLOW_TILE_TEXT = 'OFF_FLOW_TILE_TEXT';
export const ONBOARD_HOW_TO_USE_TITAN_HINT_1 = 'ONBOARD_HOW_TO_USE_TITAN_HINT_1';
export const ONBOARD_HOW_TO_USE_TITAN_HINT_2 = 'ONBOARD_HOW_TO_USE_TITAN_HINT_2';
export const ONBOARD_HOW_TO_USE_TITAN_HINT_3 = 'ONBOARD_HOW_TO_USE_TITAN_HINT_3';
export const ONBOARD_HOW_TO_USE_TITAN_HINT_4 = 'ONBOARD_HOW_TO_USE_TITAN_HINT_4';
export const ONBOARD_HOW_TO_USE_TITAN_HINT_5 = 'ONBOARD_HOW_TO_USE_TITAN_HINT_5';
export const ONBOARD_HOW_TO_USE_TITAN_TITLE_1 = 'ONBOARD_HOW_TO_USE_TITAN_TITLE_1';
export const ONBOARD_HOW_TO_USE_TITAN_TITLE_2 = 'ONBOARD_HOW_TO_USE_TITAN_TITLE_2';
export const ONBOARD_HOW_TO_USE_TITAN_TITLE_3 = 'ONBOARD_HOW_TO_USE_TITAN_TITLE_3';
export const ONBOARD_HOW_TO_USE_TITAN_TITLE_4 = 'ONBOARD_HOW_TO_USE_TITAN_TITLE_4';
export const ONBOARD_HOW_TO_USE_TITAN_TITLE_5 = 'ONBOARD_HOW_TO_USE_TITAN_TITLE_5';
export const ORDINARY_FIRST_ITEM_TITLE = 'ORDINARY_FIRST_ITEM_TITLE';
export const ORDINARY_FOURTH_ITEM_TITLE = 'ORDINARY_FOURTH_ITEM_TITLE';
export const ORDINARY_SECOND_ITEM_TITLE = 'ORDINARY_SECOND_ITEM_TITLE';
export const ORDINARY_THIRD_ITEM_TITLE = 'ORDINARY_THIRD_ITEM_TITLE';
export const P4_FIRST_ITEM_TITLE = 'P4_FIRST_ITEM_TITLE';
export const P4_FOURTH_ITEM_TITLE = 'P4_FOURTH_ITEM_TITLE';
export const P4_SECOND_ITEM_TITLE = 'P4_SECOND_ITEM_TITLE';
export const P4_THIRD_ITEM_TITLE = 'P4_THIRD_ITEM_TITLE';
export const PAIR_DEVICE_CORTEZ_ENABLE_PAIRING_DESCRIPTION = 'PAIR_DEVICE_CORTEZ_ENABLE_PAIRING_DESCRIPTION';
export const PAIR_DEVICE_CORTEZ_ENABLE_PAIRING_TITLE = 'PAIR_DEVICE_CORTEZ_ENABLE_PAIRING_TITLE';
export const PAIR_DEVICE_CORTEZ_PAIRING_IN_PROCESS_TITLE = 'PAIR_DEVICE_CORTEZ_PAIRING_IN_PROCESS_TITLE';
export const PAIR_DEVICE_CORTEZ_SUCCESSFULLY_PAIRED_TITLE = 'PAIR_DEVICE_CORTEZ_SUCCESSFULLY_PAIRED_TITLE';
export const PAIR_DEVICE_CORTEZ_TURN_OFF_DESCRIPTION_1 = 'PAIR_DEVICE_CORTEZ_TURN_OFF_DESCRIPTION_1';
export const PAIR_DEVICE_CORTEZ_TURN_OFF_DESCRIPTION_2 = 'PAIR_DEVICE_CORTEZ_TURN_OFF_DESCRIPTION_2';
export const PAIR_DEVICE_CORTEZ_TURN_OFF_TITLE_1 = 'PAIR_DEVICE_CORTEZ_TURN_OFF_TITLE_1';
export const PAIR_DEVICE_CORTEZ_TURN_OFF_TITLE_2 = 'PAIR_DEVICE_CORTEZ_TURN_OFF_TITLE_2';
export const PAIR_DEVICE_NO_BLE_FOUNDED_SKIP_TEXT = 'PAIR_DEVICE_NO_BLE_FOUNDED_SKIP_TEXT';
export const PAIR_DEVICE_NO_BLE_FOUNDED_TRY_AGAIN_TEXT = 'PAIR_DEVICE_NO_BLE_FOUNDED_TRY_AGAIN_TEXT';
export const PAIR_DEVICE_ORDINARY_ENABLE_PAIRING_DESCRIPTION = 'PAIR_DEVICE_ORDINARY_ENABLE_PAIRING_DESCRIPTION';
export const PAIR_DEVICE_ORDINARY_ENABLE_PAIRING_TITLE = 'PAIR_DEVICE_ORDINARY_ENABLE_PAIRING_TITLE';
export const PAIR_DEVICE_ORDINARY_PAIRING_IN_PROCESS_TITLE = 'PAIR_DEVICE_ORDINARY_PAIRING_IN_PROCESS_TITLE';
export const PAIR_DEVICE_ORDINARY_SUCCESSFULLY_PAIRED_TITLE = 'PAIR_DEVICE_ORDINARY_SUCCESSFULLY_PAIRED_TITLE';
export const PAIR_DEVICE_ORDINARY_TURN_ON_DESCRIPTION_1 = 'PAIR_DEVICE_ORDINARY_TURN_ON_DESCRIPTION_1';
export const PAIR_DEVICE_ORDINARY_TURN_ON_DESCRIPTION_2 = 'PAIR_DEVICE_ORDINARY_TURN_ON_DESCRIPTION_2';
export const PAIR_DEVICE_ORDINARY_TURN_ON_TITLE_1 = 'PAIR_DEVICE_ORDINARY_TURN_ON_TITLE_1';
export const PAIR_DEVICE_ORDINARY_TURN_ON_TITLE_2 = 'PAIR_DEVICE_ORDINARY_TURN_ON_TITLE_2';
export const PAIR_DEVICE_PAIRED_TITLE = 'PAIR_DEVICE_PAIRED_TITLE';
export const PAIR_DEVICE_SOYUZ_ENABLE_PAIRING_DESCRIPTION = 'PAIR_DEVICE_SOYUZ_ENABLE_PAIRING_DESCRIPTION';
export const PAIR_DEVICE_SOYUZ_ENABLE_PAIRING_TITLE = 'PAIR_DEVICE_SOYUZ_ENABLE_PAIRING_TITLE';
export const PAIR_DEVICE_SOYUZ_PAIRING_IN_PROCESS_TITLE = 'PAIR_DEVICE_SOYUZ_PAIRING_IN_PROCESS_TITLE';
export const PAIR_DEVICE_SOYUZ_SUCCESSFULLY_PAIRED_TITLE = 'PAIR_DEVICE_SOYUZ_SUCCESSFULLY_PAIRED_TITLE';
export const PAIR_DEVICE_SOYUZ_TURNOFF_DESCRIPTION_1 = 'PAIR_DEVICE_SOYUZ_TURNOFF_DESCRIPTION_1';
export const PAIR_DEVICE_SOYUZ_TURNOFF_DESCRIPTION_2 = 'PAIR_DEVICE_SOYUZ_TURNOFF_DESCRIPTION_2';
export const PAIR_DEVICE_SOYUZ_TURNOFF_TITLE_1 = 'PAIR_DEVICE_SOYUZ_TURNOFF_TITLE_1';
export const PAIR_DEVICE_SOYUZ_TURNOFF_TITLE_2 = 'PAIR_DEVICE_SOYUZ_TURNOFF_TITLE_2';
export const PAIR_DEVICE_TITAN_ENABLE_PAIRING_DESCRIPTION_1 = 'PAIR_DEVICE_TITAN_ENABLE_PAIRING_DESCRIPTION_1';
export const PAIR_DEVICE_TITAN_ENABLE_PAIRING_TITLE_1 = 'PAIR_DEVICE_TITAN_ENABLE_PAIRING_TITLE_1';
export const PAIR_DEVICE_TITAN_PAIRING_IN_PROCESS_TITLE = 'PAIR_DEVICE_TITAN_PAIRING_IN_PROCESS_TITLE';
export const PAIR_DEVICE_TITAN_SUCCESSFULLY_PAIRED_TITLE = 'PAIR_DEVICE_TITAN_SUCCESSFULLY_PAIRED_TITLE';
export const PAIR_DEVICE_TITAN_TURN_OFF_DESCRIPTION_1 = 'PAIR_DEVICE_TITAN_TURN_OFF_DESCRIPTION_1';
export const PAIR_DEVICE_TITAN_TURN_OFF_DESCRIPTION_2 = 'PAIR_DEVICE_TITAN_TURN_OFF_DESCRIPTION_2';
export const PAIR_DEVICE_TITAN_TURN_OFF_TITLE_1 = 'PAIR_DEVICE_TITAN_TURN_OFF_TITLE_1';
export const PAIR_DEVICE_TITAN_TURN_OFF_TITLE_2 = 'PAIR_DEVICE_TITAN_TURN_OFF_TITLE_2';
export const PAIR_DEVICE_VESPUCCI_ENABLE_PAIRING_DESCRIPTION = 'PAIR_DEVICE_VESPUCCI_ENABLE_PAIRING_DESCRIPTION';
export const PAIR_DEVICE_VESPUCCI_ENABLE_PAIRING_TITLE = 'PAIR_DEVICE_VESPUCCI_ENABLE_PAIRING_TITLE';
export const PAIR_DEVICE_VESPUCCI_PAIRING_IN_PROCESS_TITLE = 'PAIR_DEVICE_VESPUCCI_PAIRING_IN_PROCESS_TITLE';
export const PAIR_DEVICE_VESPUCCI_SUCCESSFULLY_PAIRED_TITLE = 'PAIR_DEVICE_VESPUCCI_SUCCESSFULLY_PAIRED_TITLE';
export const PAIR_DEVICE_VESPUCCI_TURNOFF_DESCRIPTION_1 = 'PAIR_DEVICE_VESPUCCI_TURNOFF_DESCRIPTION_1';
export const PAIR_DEVICE_VESPUCCI_TURNOFF_DESCRIPTION_2 = 'PAIR_DEVICE_VESPUCCI_TURNOFF_DESCRIPTION_2';
export const PAIR_DEVICE_VESPUCCI_TURNOFF_TITLE_1 = 'PAIR_DEVICE_VESPUCCI_TURNOFF_TITLE_1';
export const PAIR_DEVICE_VESPUCCI_TURNOFF_TITLE_2 = 'PAIR_DEVICE_VESPUCCI_TURNOFF_TITLE_2';
export const PAIR_DEVICE_P1V40_TURN_OFF_TITLE_1 = 'PAIR_DEVICE_P1V40_TURN_OFF_TITLE_1';
export const PAIR_DEVICE_P1V40M_TURN_OFF_TITLE_1 = 'PAIR_DEVICE_P1V40M_TURN_OFF_TITLE_1';
export const PAIR_DEVICE_P1V40_TURN_OFF_DESCRIPTION_1 = 'PAIR_DEVICE_P1V40_TURN_OFF_DESCRIPTION_1';
export const PAIR_DEVICE_P1V40M_TURN_OFF_DESCRIPTION_1 = 'PAIR_DEVICE_P1V40M_TURN_OFF_DESCRIPTION_1';
export const PAIR_DEVICE_P1V40_ENABLE_PAIRING_TITLE = 'PAIR_DEVICE_P1V40_ENABLE_PAIRING_TITLE';
export const PAIR_DEVICE_P1V40M_ENABLE_PAIRING_TITLE = 'PAIR_DEVICE_P1V40M_ENABLE_PAIRING_TITLE';
export const PAIR_DEVICE_P1V40_ENABLE_PAIRING_DESCRIPTION = 'PAIR_DEVICE_P1V40_ENABLE_PAIRING_DESCRIPTION';
export const PAIR_DEVICE_P1V40M_ENABLE_PAIRING_DESCRIPTION = 'PAIR_DEVICE_P1V40M_ENABLE_PAIRING_DESCRIPTION';
export const PAIR_DEVICE_P1V40_PAIRING_IN_PROCESS_TITLE = 'PAIR_DEVICE_P1V40_PAIRING_IN_PROCESS_TITLE';
export const PAIR_DEVICE_P1V40M_PAIRING_IN_PROCESS_TITLE = 'PAIR_DEVICE_P1V40M_PAIRING_IN_PROCESS_TITLE';
export const PAIR_DEVICE_P1V40_SUCCESSFULLY_PAIRED_TITLE = 'PAIR_DEVICE_P1V40_SUCCESSFULLY_PAIRED_TITLE';
export const PAIR_DEVICE_P1V40M_SUCCESSFULLY_PAIRED_TITLE = 'PAIR_DEVICE_P1V40M_SUCCESSFULLY_PAIRED_TITLE';
export const PAIR_FAIL_SKIP_BUTTON = 'PAIR_FAIL_SKIP_BUTTON';
export const PAIR_FAIL_TRY_AGAIN_BUTTON = 'PAIR_FAIL_TRY_AGAIN_BUTTON';
export const PAIRING_FAIL_DESCRIPTION_CORTEZ = 'PAIRING_FAIL_DESCRIPTION_CORTEZ';
export const PAIRING_FAIL_DESCRIPTION_ORDINARY = 'PAIRING_FAIL_DESCRIPTION_ORDINARY';
export const PAIRING_FAIL_DESCRIPTION_SOYUZ = 'PAIRING_FAIL_DESCRIPTION_SOYUZ';
export const PAIRING_FAIL_DESCRIPTION_TITAN = 'PAIRING_FAIL_DESCRIPTION_TITAN';
export const PAIRING_FAIL_DESCRIPTION_P1V40 = 'PAIRING_FAIL_DESCRIPTION_P1V40';
export const PAIRING_FAIL_DESCRIPTION_P1V40D = 'PAIRING_FAIL_DESCRIPTION_P1V40D';
export const PAIRING_FAIL_DESCRIPTION_P1V40M = 'PAIRING_FAIL_DESCRIPTION_P1V40M';
export const PAIRING_FAIL_DESCRIPTION_VESPUCCI = 'PAIRING_FAIL_DESCRIPTION_VESPUCCI';
export const PAIRING_NOT_COMPATIBILITY_VERSION_BUTTON = 'PAIRING_NOT_COMPATIBILITY_VERSION_BUTTON';
export const PAIRING_NOT_COMPATIBILITY_VERSION_MESSAGE = 'PAIRING_NOT_COMPATIBILITY_VERSION_MESSAGE';
export const PAIRING_NOT_COMPATIBILITY_VERSION_TITLE = 'PAIRING_NOT_COMPATIBILITY_VERSION_TITLE';
export const PAIRING_TROUBLESHOOTING_TEXT_CORTEZ = 'PAIRING_TROUBLESHOOTING_TEXT_CORTEZ';
export const PAIRING_TROUBLESHOOTING_TEXT_ORDINARY = 'PAIRING_TROUBLESHOOTING_TEXT_ORDINARY';
export const PAIRING_TROUBLESHOOTING_TEXT_SOYUZ = 'PAIRING_TROUBLESHOOTING_TEXT_SOYUZ';
export const PAIRING_TROUBLESHOOTING_TEXT_TITAN = 'PAIRING_TROUBLESHOOTING_TEXT_TITAN';
export const PAIRING_TROUBLESHOOTING_TEXT_P1V40 = 'PAIRING_TROUBLESHOOTING_TEXT_P1V40';
export const PAIRING_TROUBLESHOOTING_TEXT_P1V40M = 'PAIRING_TROUBLESHOOTING_TEXT_P1V40M';
export const PAIRING_TROUBLESHOOTING_TEXT_P1V40D = 'PAIRING_TROUBLESHOOTING_TEXT_P1V40D';
export const PAIRING_TROUBLESHOOTING_TEXT_VESPUCCI = 'PAIRING_TROUBLESHOOTING_TEXT_VESPUCCI';
export const USB_PAIRING_TROUBLESHOOTING_TEXT = 'USB_PAIRING_TROUBLESHOOTING_TEXT';
export const PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_MESSAGE = 'PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_MESSAGE';
export const PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_SKIP_BUTTON = 'PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_SKIP_BUTTON';
export const PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_TITLE = 'PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_TITLE';
export const PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_TRY_AGAIN_BUTTON =
    'PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_TRY_AGAIN_BUTTON';
export const PAIRING_WRONG_DEVICE_FAIL_MESSAGE = 'PAIRING_WRONG_DEVICE_FAIL_MESSAGE';
export const PAIRING_WRONG_DEVICE_FAIL_MESSAGE_P1V40 = 'PAIRING_WRONG_DEVICE_FAIL_MESSAGE_P1V40';
export const PAIRING_WRONG_DEVICE_FAIL_MESSAGE_P1V40M = 'PAIRING_WRONG_DEVICE_FAIL_MESSAGE_P1V40M';
export const PAIRING_WRONG_DEVICE_FAIL_MESSAGE_P1V40D = 'PAIRING_WRONG_DEVICE_FAIL_MESSAGE_P1V40D';
export const PARENTAL_CONTROL_DEVICE_LOCKED_MESSAGE = 'PARENTAL_CONTROL_DEVICE_LOCKED_MESSAGE';
export const PARENTAL_CONTROL_FIFTEEN_HOURS_TEXT = 'PARENTAL_CONTROL_FIFTEEN_HOURS_TEXT';
export const PARENTAL_CONTROL_FIVE_HOURS_TEXT = 'PARENTAL_CONTROL_FIVE_HOURS_TEXT';
export const PARENTAL_CONTROL_HALF_HOUR_TEXT = 'PARENTAL_CONTROL_HALF_HOUR_TEXT';
export const PARENTAL_CONTROL_ONE_HOUR_TEXT = 'PARENTAL_CONTROL_ONE_HOUR_TEXT';
export const PARENTAL_CONTROL_TEN_HOURS_TEXT = 'PARENTAL_CONTROL_TEN_HOURS_TEXT';
export const PARENTAL_CONTROL_TWENTY_ONE_HOURS_TEXT = 'PARENTAL_CONTROL_TWENTY_ONE_HOURS_TEXT';
export const PARENTAL_CONTROL_TWO_HOURS_TEXT = 'PARENTAL_CONTROL_TWO_HOURS_TEXT';
export const POPUP_CARE_CLEAN_DEVICE_ACTION_BUTTON_TEXT = 'POPUP_CARE_CLEAN_DEVICE_ACTION_BUTTON_TEXT';
export const POPUP_CARE_CLEAN_DEVICE_DESCRIPTION_TEXT = 'POPUP_CARE_CLEAN_DEVICE_DESCRIPTION_TEXT';
export const POPUP_CARE_CLEAN_DEVICE_TITLE_TEXT = 'POPUP_CARE_CLEAN_DEVICE_TITLE_TEXT';
export const POPUP_CARE_CONTACT_DEVICE_ACTION_BUTTON_TEXT = 'POPUP_CARE_CONTACT_DEVICE_ACTION_BUTTON_TEXT';
export const POPUP_CARE_CONTACT_DEVICE_DESCRIPTION_TEXT = 'POPUP_CARE_CONTACT_DEVICE_DESCRIPTION_TEXT';
export const POPUP_CARE_CONTACT_DEVICE_TITLE_TEXT = 'POPUP_CARE_CONTACT_DEVICE_TITLE_TEXT';
export const POPUP_CARE_RESET_DEVICE_ACTION_BUTTON_TEXT = 'POPUP_CARE_RESET_DEVICE_ACTION_BUTTON_TEXT';
export const POPUP_CARE_RESET_DEVICE_DESCRIPTION_TEXT = 'POPUP_CARE_RESET_DEVICE_DESCRIPTION_TEXT';
export const POPUP_CARE_RESET_DEVICE_TITLE_TEXT = 'POPUP_CARE_RESET_DEVICE_TITLE_TEXT';
export const PREFERENCE_CENTER_DESCRIPTION_TEXT = 'PREFERENCE_CENTER_DESCRIPTION_TEXT';
export const PREFERENCE_CENTER_SAVE_AND_CONTINUE_BUTTON_TEXT = 'PREFERENCE_CENTER_SAVE_AND_CONTINUE_BUTTON_TEXT';
export const PREFERENCE_CENTER_TITLE_TEXT = 'PREFERENCE_CENTER_TITLE_TEXT';
export const QUICK_START_GUIDE_CORTEZ_DESCRIPTION_1 = 'QUICK_START_GUIDE_CORTEZ_DESCRIPTION_1';
export const QUICK_START_GUIDE_CORTEZ_DESCRIPTION_2 = 'QUICK_START_GUIDE_CORTEZ_DESCRIPTION_2';
export const QUICK_START_GUIDE_CORTEZ_DESCRIPTION_3 = 'QUICK_START_GUIDE_CORTEZ_DESCRIPTION_3';
export const QUICK_START_GUIDE_CORTEZ_DESCRIPTION_4 = 'QUICK_START_GUIDE_CORTEZ_DESCRIPTION_4';
export const QUICK_START_GUIDE_CORTEZ_DESCRIPTION_5 = 'QUICK_START_GUIDE_CORTEZ_DESCRIPTION_5';
export const QUICK_START_GUIDE_CORTEZ_TITLE_1 = 'QUICK_START_GUIDE_CORTEZ_TITLE_1';
export const QUICK_START_GUIDE_CORTEZ_TITLE_2 = 'QUICK_START_GUIDE_CORTEZ_TITLE_2';
export const QUICK_START_GUIDE_CORTEZ_TITLE_3 = 'QUICK_START_GUIDE_CORTEZ_TITLE_3';
export const QUICK_START_GUIDE_CORTEZ_TITLE_4 = 'QUICK_START_GUIDE_CORTEZ_TITLE_4';
export const QUICK_START_GUIDE_CORTEZ_TITLE_5 = 'QUICK_START_GUIDE_CORTEZ_TITLE_5';
export const QUICK_START_GUIDE_DUO_DESCRIPTION_1 = 'QUICK_START_GUIDE_DUO_DESCRIPTION_1';
export const QUICK_START_GUIDE_DUO_DESCRIPTION_2 = 'QUICK_START_GUIDE_DUO_DESCRIPTION_2';
export const QUICK_START_GUIDE_DUO_DESCRIPTION_3 = 'QUICK_START_GUIDE_DUO_DESCRIPTION_3';
export const QUICK_START_GUIDE_DUO_DESCRIPTION_4 = 'QUICK_START_GUIDE_DUO_DESCRIPTION_4';
export const QUICK_START_GUIDE_DUO_DESCRIPTION_5 = 'QUICK_START_GUIDE_DUO_DESCRIPTION_5';
export const QUICK_START_GUIDE_DUO_TITLE_1 = 'QUICK_START_GUIDE_DUO_TITLE_1';
export const QUICK_START_GUIDE_DUO_TITLE_2 = 'QUICK_START_GUIDE_DUO_TITLE_2';
export const QUICK_START_GUIDE_DUO_TITLE_3 = 'QUICK_START_GUIDE_DUO_TITLE_3';
export const QUICK_START_GUIDE_DUO_TITLE_4 = 'QUICK_START_GUIDE_DUO_TITLE_4';
export const QUICK_START_GUIDE_DUO_TITLE_5 = 'QUICK_START_GUIDE_DUO_TITLE_5';
export const QUICK_START_GUIDE_MULTI_DESCRIPTION_1 = 'QUICK_START_GUIDE_MULTI_DESCRIPTION_1';
export const QUICK_START_GUIDE_MULTI_DESCRIPTION_2 = 'QUICK_START_GUIDE_MULTI_DESCRIPTION_2';
export const QUICK_START_GUIDE_MULTI_DESCRIPTION_3 = 'QUICK_START_GUIDE_MULTI_DESCRIPTION_3';
export const QUICK_START_GUIDE_MULTI_DESCRIPTION_4 = 'QUICK_START_GUIDE_MULTI_DESCRIPTION_4';
export const QUICK_START_GUIDE_MULTI_DESCRIPTION_5 = 'QUICK_START_GUIDE_MULTI_DESCRIPTION_5';
export const QUICK_START_GUIDE_MULTI_TITLE_1 = 'QUICK_START_GUIDE_MULTI_TITLE_1';
export const QUICK_START_GUIDE_MULTI_TITLE_2 = 'QUICK_START_GUIDE_MULTI_TITLE_2';
export const QUICK_START_GUIDE_MULTI_TITLE_3 = 'QUICK_START_GUIDE_MULTI_TITLE_3';
export const QUICK_START_GUIDE_MULTI_TITLE_4 = 'QUICK_START_GUIDE_MULTI_TITLE_4';
export const QUICK_START_GUIDE_MULTI_TITLE_5 = 'QUICK_START_GUIDE_MULTI_TITLE_5';
export const QUICK_START_GUIDE_NEXT_BUTTON_TEXT = 'QUICK_START_GUIDE_NEXT_BUTTON_TEXT';
export const QUICK_START_GUIDE_ORDINARY_DESCRIPTION_1 = 'QUICK_START_GUIDE_ORDINARY_DESCRIPTION_1';
export const QUICK_START_GUIDE_ORDINARY_DESCRIPTION_2 = 'QUICK_START_GUIDE_ORDINARY_DESCRIPTION_2';
export const QUICK_START_GUIDE_ORDINARY_DESCRIPTION_3 = 'QUICK_START_GUIDE_ORDINARY_DESCRIPTION_3';
export const QUICK_START_GUIDE_ORDINARY_DESCRIPTION_4 = 'QUICK_START_GUIDE_ORDINARY_DESCRIPTION_4';
export const QUICK_START_GUIDE_ORDINARY_TITLE_1 = 'QUICK_START_GUIDE_ORDINARY_TITLE_1';
export const QUICK_START_GUIDE_ORDINARY_TITLE_2 = 'QUICK_START_GUIDE_ORDINARY_TITLE_2';
export const QUICK_START_GUIDE_ORDINARY_TITLE_3 = 'QUICK_START_GUIDE_ORDINARY_TITLE_3';
export const QUICK_START_GUIDE_ORDINARY_TITLE_4 = 'QUICK_START_GUIDE_ORDINARY_TITLE_4';
export const QUICK_START_GUIDE_VEEV_DESCRIPTION_1 = 'QUICK_START_GUIDE_VEEV_DESCRIPTION_1';
export const QUICK_START_GUIDE_VEEV_DESCRIPTION_2 = 'QUICK_START_GUIDE_VEEV_DESCRIPTION_2';
export const QUICK_START_GUIDE_VEEV_DESCRIPTION_3 = 'QUICK_START_GUIDE_VEEV_DESCRIPTION_3';
export const QUICK_START_GUIDE_VEEV_DESCRIPTION_4 = 'QUICK_START_GUIDE_VEEV_DESCRIPTION_4';
export const QUICK_START_GUIDE_VEEV_DESCRIPTION_5 = 'QUICK_START_GUIDE_VEEV_DESCRIPTION_5';
export const QUICK_START_GUIDE_VEEV_TITLE_1 = 'QUICK_START_GUIDE_VEEV_TITLE_1';
export const QUICK_START_GUIDE_VEEV_TITLE_2 = 'QUICK_START_GUIDE_VEEV_TITLE_2';
export const QUICK_START_GUIDE_VEEV_TITLE_3 = 'QUICK_START_GUIDE_VEEV_TITLE_3';
export const QUICK_START_GUIDE_VEEV_TITLE_4 = 'QUICK_START_GUIDE_VEEV_TITLE_4';
export const QUICK_START_GUIDE_VEEV_TITLE_5 = 'QUICK_START_GUIDE_VEEV_TITLE_5';
export const QUIT_SET_UP_CONFIRM_BUTTON_TEXT = 'QUIT_SET_UP_CONFIRM_BUTTON_TEXT';
export const QUIT_SET_UP_DESCRIPTION_TEXT = 'QUIT_SET_UP_DESCRIPTION_TEXT';
export const QUIT_SET_UP_EXTRA_FEATURES_DESCRIPTION_TEXT = 'QUIT_SET_UP_EXTRA_FEATURES_DESCRIPTION_TEXT';
export const QUIT_SET_UP_SKIP_BUTTON_TEXT = 'QUIT_SET_UP_SKIP_BUTTON_TEXT';
export const QUIT_SET_UP_TITLE_TEXT = 'QUIT_SET_UP_TITLE_TEXT';
export const QUIT_SET_UP_TROUBLESHOOT_DESCRIPTION_TEXT = 'QUIT_SET_UP_TROUBLESHOOT_DESCRIPTION_TEXT';
export const REGISTRATION_FAIL_TRY_AGAIN_ACTIVATION = 'REGISTRATION_FAIL_TRY_AGAIN_ACTIVATION';
export const REGISTRATION_FAIL_TRY_AGAIN_ACTIVATION_BUTTON_TEXT = 'REGISTRATION_FAIL_TRY_AGAIN_ACTIVATION_BUTTON_TEXT';
export const REGISTRATION_FAILED_CONTACT_BUTTON_TEXT = 'REGISTRATION_FAILED_CONTACT_BUTTON_TEXT';
export const REGISTRATION_FAILED_GOT_IT_BUTTON_TEXT = 'REGISTRATION_FAILED_GOT_IT_BUTTON_TEXT';
export const REGISTRATION_FAILED_TRY_AGAIN_BUTTON_TEXT = 'REGISTRATION_FAILED_TRY_AGAIN_BUTTON_TEXT';
export const REGISTRATION_FAILED_WEB_BUTTON_TEXT = 'REGISTRATION_FAILED_WEB_BUTTON_TEXT';
export const REMOVE_DEVICE_CONFIRM_POPUP_CANCEL_TEXT = 'REMOVE_DEVICE_CONFIRM_POPUP_CANCEL_TEXT';
export const REMOVE_DEVICE_CONFIRM_POPUP_CONFIRM_TEXT = 'REMOVE_DEVICE_CONFIRM_POPUP_CONFIRM_TEXT';
export const REMOVE_DEVICE_CONFIRM_POPUP_MESSAGE_TEXT = 'REMOVE_DEVICE_CONFIRM_POPUP_MESSAGE_TEXT';
export const REMOVE_DEVICE_CONFIRM_POPUP_TITLE_TEXT = 'REMOVE_DEVICE_CONFIRM_POPUP_TITLE_TEXT';
export const RENAME_DEVICE_CONFIRM_POPUP_PRIMARY_TEXT = 'RENAME_DEVICE_CONFIRM_POPUP_PRIMARY_TEXT';
export const RENAME_DEVICE_CONFIRM_POPUP_SECONDARY_TEXT = 'RENAME_DEVICE_CONFIRM_POPUP_SECONDARY_TEXT';
export const RES_CHAIN_FAULT_DETECTED = 'RES_CHAIN_FAULT_DETECTED';
export const RESET_TO_DEFAULT_BUTTON_TEXT = 'RESET_TO_DEFAULT_BUTTON_TEXT';
export const RESPONSIVE_FLOW_ALLEGRO_TITLE_TEXT_KEY = 'RESPONSIVE_FLOW_ALLEGRO_TITLE_TEXT_KEY';
export const RESPONSIVE_FLOW_BUBBLY_TITLE_TEXT_KEY = 'RESPONSIVE_FLOW_BUBBLY_TITLE_TEXT_KEY';
export const RESPONSIVE_FLOW_CRESCENDO_TITLE_TEXT_KEY = 'RESPONSIVE_FLOW_CRESCENDO_TITLE_TEXT_KEY';
export const RESPONSIVE_FLOW_MODE_DESCRIPTION = 'RESPONSIVE_FLOW_MODE_DESCRIPTION';
export const RESPONSIVE_FLOW_MODE_TITLE = 'RESPONSIVE_FLOW_MODE_TITLE';
export const RESPONSIVE_FLOW_OFF_TITLE_TEXT_KEY = 'RESPONSIVE_FLOW_OFF_TITLE_TEXT_KEY';
export const RESPONSIVE_FLOW_POPUP_DESCRIPTION = 'RESPONSIVE_FLOW_POPUP_DESCRIPTION';
export const RESPONSIVE_FLOW_POPUP_TITLE = 'RESPONSIVE_FLOW_POPUP_TITLE';
export const RESPONSIVE_FLOW_SIREN_TITLE_TEXT_KEY = 'RESPONSIVE_FLOW_SIREN_TITLE_TEXT_KEY';
export const RESPONSIVE_FLOW_VIVALDI_TITLE_TEXT_KEY = 'RESPONSIVE_FLOW_VIVALDI_TITLE_TEXT_KEY';
export const RESPONSIVE_TEXT = 'RESPONSIVE_TEXT';
export const S1_PULL_UP_RES_TEST_FAILURE = 'S1_PULL_UP_RES_TEST_FAILURE';
export const SELF_TEST_FAIL = 'SELF_TEST_FAIL';
export const SIREN_FLOW_TEXT = 'SIREN_FLOW_TEXT';
export const SIREN_FLOW_TILE_TEXT = 'SIREN_FLOW_TILE_TEXT';
export const SKIP_LEARN_HOW_TO_USE = 'SKIP_LEARN_HOW_TO_USE';
export const SOYUZ_FIRST_ITEM_TITLE = 'SOYUZ_FIRST_ITEM_TITLE';
export const SOYUZ_FOURTH_ITEM_TITLE = 'SOYUZ_FOURTH_ITEM_TITLE';
export const SOYUZ_SECOND_ITEM_TITLE = 'SOYUZ_SECOND_ITEM_TITLE';
export const SOYUZ_THIRD_ITEM_TITLE = 'SOYUZ_THIRD_ITEM_TITLE';
export const START_CLEANING_BUTTON_TEXT = 'START_CLEANING_BUTTON_TEXT';
export const START_FINDING_BUTTON_TEXT = 'START_FINDING_BUTTON_TEXT';
export const STOP_FINDING_BUTTON_TEXT = 'STOP_FINDING_BUTTON_TEXT';
export const SYSTEM_FAULT_EXT_VOLTAGE_REF_FAILURE_ERROR_MESSAGE = 'SYSTEM_FAULT_EXT_VOLTAGE_REF_FAILURE_ERROR_MESSAGE';
export const SYSTEM_FAULT_HEATER_BROKEN_OR_ABSENT = 'SYSTEM_FAULT_HEATER_BROKEN_OR_ABSENT';
export const SYSTEM_FAULT_INV_MANUF_INFO_BLOCK_CRC = 'SYSTEM_FAULT_INV_MANUF_INFO_BLOCK_CRC';
export const SYSTEM_FAULT_RTC_OSC_FAULT = 'SYSTEM_FAULT_RTC_OSC_FAULT';
export const SYSTEM_FAULT_TOO_MUCH_UNEXP_DUTY_CYCLE_VAR = 'SYSTEM_FAULT_TOO_MUCH_UNEXP_DUTY_CYCLE_VAR';
export const SYSTEM_FAULT_UNEXP_HEATER_CURRENT_SW_ON = 'SYSTEM_FAULT_UNEXP_HEATER_CURRENT_SW_ON';
export const TERMS_SUMMARY_CONFIRM_BUTTON_TEXT = 'TERMS_SUMMARY_CONFIRM_BUTTON_TEXT';
export const TERMS_SUMMARY_DECLINE_BUTTON_TEXT = 'TERMS_SUMMARY_DECLINE_BUTTON_TEXT';
export const TERMS_SUMMARY_ERROR_NOTIFICATION_TEXT = 'TERMS_SUMMARY_ERROR_NOTIFICATION_TEXT';
export const TERMS_SUMMARY_TITLE_TEXT = 'TERMS_SUMMARY_TITLE_TEXT';
export const TERMS_SUMMARY_UPDATE_TITLE_TEXT = 'TERMS_SUMMARY_UPDATE_TITLE_TEXT';
export const TIPS_CORTEZ_BATTERY_DESCRIPTION_1 = 'TIPS_CORTEZ_BATTERY_DESCRIPTION_1';
export const TIPS_CORTEZ_BATTERY_DESCRIPTION_2 = 'TIPS_CORTEZ_BATTERY_DESCRIPTION_2';
export const TIPS_CORTEZ_BATTERY_DESCRIPTION_3 = 'TIPS_CORTEZ_BATTERY_DESCRIPTION_3';
export const TIPS_CORTEZ_BATTERY_DESCRIPTION_4 = 'TIPS_CORTEZ_BATTERY_DESCRIPTION_4';
export const TIPS_CORTEZ_BATTERY_DESCRIPTION_5 = 'TIPS_CORTEZ_BATTERY_DESCRIPTION_5';
export const TIPS_CORTEZ_BATTERY_DESCRIPTION_6 = 'TIPS_CORTEZ_BATTERY_DESCRIPTION_6';
export const TIPS_CORTEZ_BATTERY_TITLE_1 = 'TIPS_CORTEZ_BATTERY_TITLE_1';
export const TIPS_CORTEZ_BATTERY_TITLE_2 = 'TIPS_CORTEZ_BATTERY_TITLE_2';
export const TIPS_CORTEZ_BATTERY_TITLE_3 = 'TIPS_CORTEZ_BATTERY_TITLE_3';
export const TIPS_CORTEZ_BATTERY_TITLE_4 = 'TIPS_CORTEZ_BATTERY_TITLE_4';
export const TIPS_CORTEZ_BATTERY_TITLE_5 = 'TIPS_CORTEZ_BATTERY_TITLE_5';
export const TIPS_CORTEZ_BATTERY_TITLE_6 = 'TIPS_CORTEZ_BATTERY_TITLE_6';
export const TIPS_CORTEZ_CLEANING_DESCRIPTION_1 = 'TIPS_CORTEZ_CLEANING_DESCRIPTION_1';
export const TIPS_CORTEZ_CLEANING_DESCRIPTION_2 = 'TIPS_CORTEZ_CLEANING_DESCRIPTION_2';
export const TIPS_CORTEZ_CLEANING_DESCRIPTION_3 = 'TIPS_CORTEZ_CLEANING_DESCRIPTION_3';
export const TIPS_CORTEZ_CLEANING_DESCRIPTION_4 = 'TIPS_CORTEZ_CLEANING_DESCRIPTION_4';
export const TIPS_CORTEZ_CLEANING_DESCRIPTION_5 = 'TIPS_CORTEZ_CLEANING_DESCRIPTION_5';
export const TIPS_CORTEZ_CLEANING_DESCRIPTION_6 = 'TIPS_CORTEZ_CLEANING_DESCRIPTION_6';
export const TIPS_CORTEZ_CLEANING_DESCRIPTION_7 = 'TIPS_CORTEZ_CLEANING_DESCRIPTION_7';
export const TIPS_CORTEZ_CLEANING_DESCRIPTION_8 = 'TIPS_CORTEZ_CLEANING_DESCRIPTION_8';
export const TIPS_CORTEZ_CLEANING_DESCRIPTION_9 = 'TIPS_CORTEZ_CLEANING_DESCRIPTION_9';
export const TIPS_CORTEZ_CLEANING_TITLE_1 = 'TIPS_CORTEZ_CLEANING_TITLE_1';
export const TIPS_CORTEZ_CLEANING_TITLE_2 = 'TIPS_CORTEZ_CLEANING_TITLE_2';
export const TIPS_CORTEZ_CLEANING_TITLE_3 = 'TIPS_CORTEZ_CLEANING_TITLE_3';
export const TIPS_CORTEZ_CLEANING_TITLE_4 = 'TIPS_CORTEZ_CLEANING_TITLE_4';
export const TIPS_CORTEZ_CLEANING_TITLE_5 = 'TIPS_CORTEZ_CLEANING_TITLE_5';
export const TIPS_CORTEZ_CLEANING_TITLE_6 = 'TIPS_CORTEZ_CLEANING_TITLE_6';
export const TIPS_CORTEZ_CLEANING_TITLE_7 = 'TIPS_CORTEZ_CLEANING_TITLE_7';
export const TIPS_CORTEZ_CLEANING_TITLE_8 = 'TIPS_CORTEZ_CLEANING_TITLE_8';
export const TIPS_CORTEZ_CLEANING_TITLE_9 = 'TIPS_CORTEZ_CLEANING_TITLE_9';
export const TIPS_CORTEZ_DOS_DESCRIPTION_1 = 'TIPS_CORTEZ_DOS_DESCRIPTION_1';
export const TIPS_CORTEZ_DOS_DESCRIPTION_2 = 'TIPS_CORTEZ_DOS_DESCRIPTION_2';
export const TIPS_CORTEZ_DOS_DESCRIPTION_3 = 'TIPS_CORTEZ_DOS_DESCRIPTION_3';
export const TIPS_CORTEZ_DOS_DESCRIPTION_4 = 'TIPS_CORTEZ_DOS_DESCRIPTION_4';
export const TIPS_CORTEZ_DOS_DESCRIPTION_5 = 'TIPS_CORTEZ_DOS_DESCRIPTION_5';
export const TIPS_CORTEZ_DOS_TITLE_1 = 'TIPS_CORTEZ_DOS_TITLE_1';
export const TIPS_CORTEZ_DOS_TITLE_2 = 'TIPS_CORTEZ_DOS_TITLE_2';
export const TIPS_CORTEZ_DOS_TITLE_3 = 'TIPS_CORTEZ_DOS_TITLE_3';
export const TIPS_CORTEZ_DOS_TITLE_4 = 'TIPS_CORTEZ_DOS_TITLE_4';
export const TIPS_CORTEZ_DOS_TITLE_5 = 'TIPS_CORTEZ_DOS_TITLE_5';
export const TIPS_CORTEZ_TITLE = 'TIPS_CORTEZ_TITLE';
export const TIPS_IQOS_DESCRIPTION = 'TIPS_IQOS_DESCRIPTION';
export const TIPS_MULTI_BATTERY_DESCRIPTION_1 = 'TIPS_MULTI_BATTERY_DESCRIPTION_1';
export const TIPS_MULTI_BATTERY_DESCRIPTION_2 = 'TIPS_MULTI_BATTERY_DESCRIPTION_2';
export const TIPS_MULTI_BATTERY_TITLE_1 = 'TIPS_MULTI_BATTERY_TITLE_1';
export const TIPS_MULTI_BATTERY_TITLE_2 = 'TIPS_MULTI_BATTERY_TITLE_2';
export const TIPS_MULTI_CLEANING_DESCRIPTION_1 = 'TIPS_MULTI_CLEANING_DESCRIPTION_1';
export const TIPS_MULTI_CLEANING_DESCRIPTION_2 = 'TIPS_MULTI_CLEANING_DESCRIPTION_2';
export const TIPS_MULTI_CLEANING_DESCRIPTION_3 = 'TIPS_MULTI_CLEANING_DESCRIPTION_3';
export const TIPS_MULTI_CLEANING_DESCRIPTION_4 = 'TIPS_MULTI_CLEANING_DESCRIPTION_4';
export const TIPS_MULTI_CLEANING_DESCRIPTION_5 = 'TIPS_MULTI_CLEANING_DESCRIPTION_5';
export const TIPS_MULTI_CLEANING_DESCRIPTION_6 = 'TIPS_MULTI_CLEANING_DESCRIPTION_6';
export const TIPS_MULTI_CLEANING_DESCRIPTION_7 = 'TIPS_MULTI_CLEANING_DESCRIPTION_7';
export const TIPS_MULTI_CLEANING_DESCRIPTION_8 = 'TIPS_MULTI_CLEANING_DESCRIPTION_8';
export const TIPS_MULTI_CLEANING_DESCRIPTION_9 = 'TIPS_MULTI_CLEANING_DESCRIPTION_9';
export const TIPS_MULTI_CLEANING_TITLE_1 = 'TIPS_MULTI_CLEANING_TITLE_1';
export const TIPS_MULTI_CLEANING_TITLE_2 = 'TIPS_MULTI_CLEANING_TITLE_2';
export const TIPS_MULTI_CLEANING_TITLE_3 = 'TIPS_MULTI_CLEANING_TITLE_3';
export const TIPS_MULTI_CLEANING_TITLE_4 = 'TIPS_MULTI_CLEANING_TITLE_4';
export const TIPS_MULTI_CLEANING_TITLE_5 = 'TIPS_MULTI_CLEANING_TITLE_5';
export const TIPS_MULTI_CLEANING_TITLE_6 = 'TIPS_MULTI_CLEANING_TITLE_6';
export const TIPS_MULTI_CLEANING_TITLE_7 = 'TIPS_MULTI_CLEANING_TITLE_7';
export const TIPS_MULTI_CLEANING_TITLE_8 = 'TIPS_MULTI_CLEANING_TITLE_8';
export const TIPS_MULTI_CLEANING_TITLE_9 = 'TIPS_MULTI_CLEANING_TITLE_9';
export const TIPS_MULTI_DOS_DESCRIPTION_1 = 'TIPS_MULTI_DOS_DESCRIPTION_1';
export const TIPS_MULTI_DOS_DESCRIPTION_2 = 'TIPS_MULTI_DOS_DESCRIPTION_2';
export const TIPS_MULTI_DOS_DESCRIPTION_3 = 'TIPS_MULTI_DOS_DESCRIPTION_3';
export const TIPS_MULTI_DOS_DESCRIPTION_4 = 'TIPS_MULTI_DOS_DESCRIPTION_4';
export const TIPS_MULTI_DOS_DESCRIPTION_5 = 'TIPS_MULTI_DOS_DESCRIPTION_5';
export const TIPS_MULTI_DOS_TITLE_1 = 'TIPS_MULTI_DOS_TITLE_1';
export const TIPS_MULTI_DOS_TITLE_2 = 'TIPS_MULTI_DOS_TITLE_2';
export const TIPS_MULTI_DOS_TITLE_3 = 'TIPS_MULTI_DOS_TITLE_3';
export const TIPS_MULTI_DOS_TITLE_4 = 'TIPS_MULTI_DOS_TITLE_4';
export const TIPS_MULTI_DOS_TITLE_5 = 'TIPS_MULTI_DOS_TITLE_5';
export const TIPS_MULTI_TITLE = 'TIPS_MULTI_TITLE';
export const TIPS_NEXT_TIPS_TEXT = 'TIPS_NEXT_TIPS_TEXT';
export const TIPS_ORDINARY_BATTERY_DESCRIPTION_1 = 'TIPS_ORDINARY_BATTERY_DESCRIPTION_1';
export const TIPS_ORDINARY_BATTERY_DESCRIPTION_2 = 'TIPS_ORDINARY_BATTERY_DESCRIPTION_2';
export const TIPS_ORDINARY_BATTERY_DESCRIPTION_3 = 'TIPS_ORDINARY_BATTERY_DESCRIPTION_3';
export const TIPS_ORDINARY_BATTERY_DESCRIPTION_4 = 'TIPS_ORDINARY_BATTERY_DESCRIPTION_4';
export const TIPS_ORDINARY_BATTERY_DESCRIPTION_5 = 'TIPS_ORDINARY_BATTERY_DESCRIPTION_5';
export const TIPS_ORDINARY_BATTERY_DESCRIPTION_6 = 'TIPS_ORDINARY_BATTERY_DESCRIPTION_6';
export const TIPS_ORDINARY_BATTERY_TITLE_1 = 'TIPS_ORDINARY_BATTERY_TITLE_1';
export const TIPS_ORDINARY_BATTERY_TITLE_2 = 'TIPS_ORDINARY_BATTERY_TITLE_2';
export const TIPS_ORDINARY_BATTERY_TITLE_3 = 'TIPS_ORDINARY_BATTERY_TITLE_3';
export const TIPS_ORDINARY_BATTERY_TITLE_4 = 'TIPS_ORDINARY_BATTERY_TITLE_4';
export const TIPS_ORDINARY_BATTERY_TITLE_5 = 'TIPS_ORDINARY_BATTERY_TITLE_5';
export const TIPS_ORDINARY_BATTERY_TITLE_6 = 'TIPS_ORDINARY_BATTERY_TITLE_6';
export const TIPS_ORDINARY_CLEANING_DESCRIPTION_1 = 'TIPS_ORDINARY_CLEANING_DESCRIPTION_1';
export const TIPS_ORDINARY_CLEANING_DESCRIPTION_2 = 'TIPS_ORDINARY_CLEANING_DESCRIPTION_2';
export const TIPS_ORDINARY_CLEANING_DESCRIPTION_3 = 'TIPS_ORDINARY_CLEANING_DESCRIPTION_3';
export const TIPS_ORDINARY_CLEANING_DESCRIPTION_4 = 'TIPS_ORDINARY_CLEANING_DESCRIPTION_4';
export const TIPS_ORDINARY_CLEANING_DESCRIPTION_5 = 'TIPS_ORDINARY_CLEANING_DESCRIPTION_5';
export const TIPS_ORDINARY_CLEANING_DESCRIPTION_6 = 'TIPS_ORDINARY_CLEANING_DESCRIPTION_6';
export const TIPS_ORDINARY_CLEANING_DESCRIPTION_7 = 'TIPS_ORDINARY_CLEANING_DESCRIPTION_7';
export const TIPS_ORDINARY_CLEANING_DESCRIPTION_8 = 'TIPS_ORDINARY_CLEANING_DESCRIPTION_8';
export const TIPS_ORDINARY_CLEANING_DESCRIPTION_9 = 'TIPS_ORDINARY_CLEANING_DESCRIPTION_9';
export const TIPS_ORDINARY_CLEANING_TITLE_1 = 'TIPS_ORDINARY_CLEANING_TITLE_1';
export const TIPS_ORDINARY_CLEANING_TITLE_2 = 'TIPS_ORDINARY_CLEANING_TITLE_2';
export const TIPS_ORDINARY_CLEANING_TITLE_3 = 'TIPS_ORDINARY_CLEANING_TITLE_3';
export const TIPS_ORDINARY_CLEANING_TITLE_4 = 'TIPS_ORDINARY_CLEANING_TITLE_4';
export const TIPS_ORDINARY_CLEANING_TITLE_5 = 'TIPS_ORDINARY_CLEANING_TITLE_5';
export const TIPS_ORDINARY_CLEANING_TITLE_6 = 'TIPS_ORDINARY_CLEANING_TITLE_6';
export const TIPS_ORDINARY_CLEANING_TITLE_7 = 'TIPS_ORDINARY_CLEANING_TITLE_7';
export const TIPS_ORDINARY_CLEANING_TITLE_8 = 'TIPS_ORDINARY_CLEANING_TITLE_8';
export const TIPS_ORDINARY_CLEANING_TITLE_9 = 'TIPS_ORDINARY_CLEANING_TITLE_9';
export const TIPS_ORDINARY_DOS_DESCRIPTION_1 = 'TIPS_ORDINARY_DOS_DESCRIPTION_1';
export const TIPS_ORDINARY_DOS_DESCRIPTION_2 = 'TIPS_ORDINARY_DOS_DESCRIPTION_2';
export const TIPS_ORDINARY_DOS_DESCRIPTION_3 = 'TIPS_ORDINARY_DOS_DESCRIPTION_3';
export const TIPS_ORDINARY_DOS_DESCRIPTION_4 = 'TIPS_ORDINARY_DOS_DESCRIPTION_4';
export const TIPS_ORDINARY_DOS_DESCRIPTION_5 = 'TIPS_ORDINARY_DOS_DESCRIPTION_5';
export const TIPS_ORDINARY_DOS_TITLE_1 = 'TIPS_ORDINARY_DOS_TITLE_1';
export const TIPS_ORDINARY_DOS_TITLE_2 = 'TIPS_ORDINARY_DOS_TITLE_2';
export const TIPS_ORDINARY_DOS_TITLE_3 = 'TIPS_ORDINARY_DOS_TITLE_3';
export const TIPS_ORDINARY_DOS_TITLE_4 = 'TIPS_ORDINARY_DOS_TITLE_4';
export const TIPS_ORDINARY_DOS_TITLE_5 = 'TIPS_ORDINARY_DOS_TITLE_5';
export const TIPS_ORDINARY_TITLE = 'TIPS_ORDINARY_TITLE';
export const TIPS_P4_BATTERY_DESCRIPTION_1 = 'TIPS_P4_BATTERY_DESCRIPTION_1';
export const TIPS_P4_BATTERY_DESCRIPTION_2 = 'TIPS_P4_BATTERY_DESCRIPTION_2';
export const TIPS_P4_BATTERY_DESCRIPTION_3 = 'TIPS_P4_BATTERY_DESCRIPTION_3';
export const TIPS_P4_BATTERY_TITLE_1 = 'TIPS_P4_BATTERY_TITLE_1';
export const TIPS_P4_BATTERY_TITLE_2 = 'TIPS_P4_BATTERY_TITLE_2';
export const TIPS_P4_BATTERY_TITLE_3 = 'TIPS_P4_BATTERY_TITLE_3';
export const TIPS_P4_DESCRIPTION = 'TIPS_P4_DESCRIPTION';
export const TIPS_P4_POD_DESCRIPTION_1 = 'TIPS_P4_POD_DESCRIPTION_1';
export const TIPS_P4_POD_TITLE_1 = 'TIPS_P4_POD_TITLE_1';
export const TIPS_P4_RESPONSIVE_DRAW_DESCRIPTION_1 = 'TIPS_P4_RESPONSIVE_DRAW_DESCRIPTION_1';
export const TIPS_P4_RESPONSIVE_DRAW_TITLE_1 = 'TIPS_P4_RESPONSIVE_DRAW_TITLE_1';
export const TIPS_P4_TITLE = 'TIPS_P4_TITLE';
export const TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_1 = 'TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_1';
export const TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_2 = 'TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_2';
export const TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_3 = 'TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_3';
export const TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_4 = 'TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_4';
export const TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_5 = 'TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_5';
export const TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_6 = 'TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_6';
export const TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_7 = 'TIPS_QUICK_START_GUIDE_CORTEZ_DESCRIPTION_7';
export const TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_1 = 'TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_1';
export const TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_2 = 'TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_2';
export const TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_3 = 'TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_3';
export const TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_4 = 'TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_4';
export const TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_5 = 'TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_5';
export const TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_6 = 'TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_6';
export const TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_7 = 'TIPS_QUICK_START_GUIDE_CORTEZ_TITLE_7';
export const TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_1 = 'TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_1';
export const TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_2 = 'TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_2';
export const TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_3 = 'TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_3';
export const TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_4 = 'TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_4';
export const TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_5 = 'TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_5';
export const TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_6 = 'TIPS_QUICK_START_GUIDE_MULTI_DESCRIPTION_6';
export const TIPS_QUICK_START_GUIDE_MULTI_TITLE_1 = 'TIPS_QUICK_START_GUIDE_MULTI_TITLE_1';
export const TIPS_QUICK_START_GUIDE_MULTI_TITLE_2 = 'TIPS_QUICK_START_GUIDE_MULTI_TITLE_2';
export const TIPS_QUICK_START_GUIDE_MULTI_TITLE_3 = 'TIPS_QUICK_START_GUIDE_MULTI_TITLE_3';
export const TIPS_QUICK_START_GUIDE_MULTI_TITLE_4 = 'TIPS_QUICK_START_GUIDE_MULTI_TITLE_4';
export const TIPS_QUICK_START_GUIDE_MULTI_TITLE_5 = 'TIPS_QUICK_START_GUIDE_MULTI_TITLE_5';
export const TIPS_QUICK_START_GUIDE_MULTI_TITLE_6 = 'TIPS_QUICK_START_GUIDE_MULTI_TITLE_6';
export const TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_1 = 'TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_1';
export const TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_2 = 'TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_2';
export const TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_3 = 'TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_3';
export const TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_4 = 'TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_4';
export const TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_5 = 'TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_5';
export const TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_6 = 'TIPS_QUICK_START_GUIDE_ORDINARY_DESCRIPTION_6';
export const TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_1 = 'TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_1';
export const TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_2 = 'TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_2';
export const TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_3 = 'TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_3';
export const TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_4 = 'TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_4';
export const TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_5 = 'TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_5';
export const TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_6 = 'TIPS_QUICK_START_GUIDE_ORDINARY_TITLE_6';
export const TIPS_QUICK_START_GUIDE_P4_DESCRIPTION_1 = 'TIPS_QUICK_START_GUIDE_P4_DESCRIPTION_1';
export const TIPS_QUICK_START_GUIDE_P4_DESCRIPTION_2 = 'TIPS_QUICK_START_GUIDE_P4_DESCRIPTION_2';
export const TIPS_QUICK_START_GUIDE_P4_DESCRIPTION_3 = 'TIPS_QUICK_START_GUIDE_P4_DESCRIPTION_3';
export const TIPS_QUICK_START_GUIDE_P4_DESCRIPTION_4 = 'TIPS_QUICK_START_GUIDE_P4_DESCRIPTION_4';
export const TIPS_QUICK_START_GUIDE_P4_DESCRIPTION_5 = 'TIPS_QUICK_START_GUIDE_P4_DESCRIPTION_5';
export const TIPS_QUICK_START_GUIDE_P4_TITLE_1 = 'TIPS_QUICK_START_GUIDE_P4_TITLE_1';
export const TIPS_QUICK_START_GUIDE_P4_TITLE_2 = 'TIPS_QUICK_START_GUIDE_P4_TITLE_2';
export const TIPS_QUICK_START_GUIDE_P4_TITLE_3 = 'TIPS_QUICK_START_GUIDE_P4_TITLE_3';
export const TIPS_QUICK_START_GUIDE_P4_TITLE_4 = 'TIPS_QUICK_START_GUIDE_P4_TITLE_4';
export const TIPS_QUICK_START_GUIDE_P4_TITLE_5 = 'TIPS_QUICK_START_GUIDE_P4_TITLE_5';
export const TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_1 = 'TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_1';
export const TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_2 = 'TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_2';
export const TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_3 = 'TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_3';
export const TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_4 = 'TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_4';
export const TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_5 = 'TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_5';
export const TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_6 = 'TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_6';
export const TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_7 = 'TIPS_QUICK_START_GUIDE_SOYUZ_DESCRIPTION_7';
export const TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_1 = 'TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_1';
export const TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_2 = 'TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_2';
export const TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_3 = 'TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_3';
export const TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_4 = 'TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_4';
export const TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_5 = 'TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_5';
export const TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_6 = 'TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_6';
export const TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_7 = 'TIPS_QUICK_START_GUIDE_SOYUZ_TITLE_7';
export const TIPS_SOYUZ_BATTERY_DESCRIPTION_1 = 'TIPS_SOYUZ_BATTERY_DESCRIPTION_1';
export const TIPS_SOYUZ_BATTERY_DESCRIPTION_2 = 'TIPS_SOYUZ_BATTERY_DESCRIPTION_2';
export const TIPS_SOYUZ_BATTERY_DESCRIPTION_3 = 'TIPS_SOYUZ_BATTERY_DESCRIPTION_3';
export const TIPS_SOYUZ_BATTERY_DESCRIPTION_4 = 'TIPS_SOYUZ_BATTERY_DESCRIPTION_4';
export const TIPS_SOYUZ_BATTERY_DESCRIPTION_5 = 'TIPS_SOYUZ_BATTERY_DESCRIPTION_5';
export const TIPS_SOYUZ_BATTERY_DESCRIPTION_6 = 'TIPS_SOYUZ_BATTERY_DESCRIPTION_6';
export const TIPS_SOYUZ_BATTERY_TITLE_1 = 'TIPS_SOYUZ_BATTERY_TITLE_1';
export const TIPS_SOYUZ_BATTERY_TITLE_2 = 'TIPS_SOYUZ_BATTERY_TITLE_2';
export const TIPS_SOYUZ_BATTERY_TITLE_3 = 'TIPS_SOYUZ_BATTERY_TITLE_3';
export const TIPS_SOYUZ_BATTERY_TITLE_4 = 'TIPS_SOYUZ_BATTERY_TITLE_4';
export const TIPS_SOYUZ_BATTERY_TITLE_5 = 'TIPS_SOYUZ_BATTERY_TITLE_5';
export const TIPS_SOYUZ_BATTERY_TITLE_6 = 'TIPS_SOYUZ_BATTERY_TITLE_6';
export const TIPS_SOYUZ_CLEANING_DESCRIPTION_1 = 'TIPS_SOYUZ_CLEANING_DESCRIPTION_1';
export const TIPS_SOYUZ_CLEANING_DESCRIPTION_2 = 'TIPS_SOYUZ_CLEANING_DESCRIPTION_2';
export const TIPS_SOYUZ_CLEANING_DESCRIPTION_3 = 'TIPS_SOYUZ_CLEANING_DESCRIPTION_3';
export const TIPS_SOYUZ_CLEANING_DESCRIPTION_4 = 'TIPS_SOYUZ_CLEANING_DESCRIPTION_4';
export const TIPS_SOYUZ_CLEANING_DESCRIPTION_5 = 'TIPS_SOYUZ_CLEANING_DESCRIPTION_5';
export const TIPS_SOYUZ_CLEANING_DESCRIPTION_6 = 'TIPS_SOYUZ_CLEANING_DESCRIPTION_6';
export const TIPS_SOYUZ_CLEANING_DESCRIPTION_7 = 'TIPS_SOYUZ_CLEANING_DESCRIPTION_7';
export const TIPS_SOYUZ_CLEANING_DESCRIPTION_8 = 'TIPS_SOYUZ_CLEANING_DESCRIPTION_8';
export const TIPS_SOYUZ_CLEANING_DESCRIPTION_9 = 'TIPS_SOYUZ_CLEANING_DESCRIPTION_9';
export const TIPS_SOYUZ_CLEANING_TITLE_1 = 'TIPS_SOYUZ_CLEANING_TITLE_1';
export const TIPS_SOYUZ_CLEANING_TITLE_2 = 'TIPS_SOYUZ_CLEANING_TITLE_2';
export const TIPS_SOYUZ_CLEANING_TITLE_3 = 'TIPS_SOYUZ_CLEANING_TITLE_3';
export const TIPS_SOYUZ_CLEANING_TITLE_4 = 'TIPS_SOYUZ_CLEANING_TITLE_4';
export const TIPS_SOYUZ_CLEANING_TITLE_5 = 'TIPS_SOYUZ_CLEANING_TITLE_5';
export const TIPS_SOYUZ_CLEANING_TITLE_6 = 'TIPS_SOYUZ_CLEANING_TITLE_6';
export const TIPS_SOYUZ_CLEANING_TITLE_7 = 'TIPS_SOYUZ_CLEANING_TITLE_7';
export const TIPS_SOYUZ_CLEANING_TITLE_8 = 'TIPS_SOYUZ_CLEANING_TITLE_8';
export const TIPS_SOYUZ_CLEANING_TITLE_9 = 'TIPS_SOYUZ_CLEANING_TITLE_9';
export const TIPS_SOYUZ_DOS_DESCRIPTION_1 = 'TIPS_SOYUZ_DOS_DESCRIPTION_1';
export const TIPS_SOYUZ_DOS_DESCRIPTION_2 = 'TIPS_SOYUZ_DOS_DESCRIPTION_2';
export const TIPS_SOYUZ_DOS_DESCRIPTION_3 = 'TIPS_SOYUZ_DOS_DESCRIPTION_3';
export const TIPS_SOYUZ_DOS_DESCRIPTION_4 = 'TIPS_SOYUZ_DOS_DESCRIPTION_4';
export const TIPS_SOYUZ_DOS_DESCRIPTION_5 = 'TIPS_SOYUZ_DOS_DESCRIPTION_5';
export const TIPS_SOYUZ_DOS_TITLE_1 = 'TIPS_SOYUZ_DOS_TITLE_1';
export const TIPS_SOYUZ_DOS_TITLE_2 = 'TIPS_SOYUZ_DOS_TITLE_2';
export const TIPS_SOYUZ_DOS_TITLE_3 = 'TIPS_SOYUZ_DOS_TITLE_3';
export const TIPS_SOYUZ_DOS_TITLE_4 = 'TIPS_SOYUZ_DOS_TITLE_4';
export const TIPS_SOYUZ_DOS_TITLE_5 = 'TIPS_SOYUZ_DOS_TITLE_5';
export const TIPS_SOYUZ_TITLE = 'TIPS_SOYUZ_TITLE';
export const TRIPLET_FLOW_TILE_TEXT = 'TRIPLET_FLOW_TILE_TEXT';
export const TROUBLESHOOTING_CONSENT_DESCRIPTION_TEXT = 'TROUBLESHOOTING_CONSENT_DESCRIPTION_TEXT';
export const UNLOCK_BUTTON_TEXT = 'UNLOCK_BUTTON_TEXT';
export const UNLOCK_POPUP_CANCEL_BUTTON_TEXT = 'UNLOCK_POPUP_CANCEL_BUTTON_TEXT';
export const UNLOCK_POPUP_TITLE = 'UNLOCK_POPUP_TITLE';
export const P1V40_HOLDER_UNLOCK_POPUP_TITLE = 'P1V40_HOLDER_UNLOCK_POPUP_TITLE';
export const UNLOCK_POPUP_TITLE_DESCRIPTION = 'UNLOCK_POPUP_TITLE_DESCRIPTION';
export const UNLOCK_POPUP_UNLOCK_BUTTON_TEXT = 'UNLOCK_POPUP_UNLOCK_BUTTON_TEXT';
export const UNLOCK_SUCCESS_NOTIFICATION = 'UNLOCK_SUCCESS_NOTIFICATION';
export const UNPAIR_DEVICE_CONFIRM_POPUP_DESCRIPTION_TEXT = 'UNPAIR_DEVICE_CONFIRM_POPUP_DESCRIPTION_TEXT';
export const USB_UNPAIR_DEVICE_CONFIRM_POPUP_DESCRIPTION_TEXT = 'USB_UNPAIR_DEVICE_CONFIRM_POPUP_DESCRIPTION_TEXT';
export const UNPAIR_DEVICE_CONFIRM_POPUP_PRIMARY_TEXT = 'UNPAIR_DEVICE_CONFIRM_POPUP_PRIMARY_TEXT';
export const UNPAIR_DEVICE_CONFIRM_POPUP_SECONDARY_TEXT = 'UNPAIR_DEVICE_CONFIRM_POPUP_SECONDARY_TEXT';
export const UNPAIR_DEVICE_CONFIRM_POPUP_TITLE_TEXT = 'UNPAIR_DEVICE_CONFIRM_POPUP_TITLE_TEXT';
export const USB_UNPAIR_DEVICE_CONFIRM_POPUP_TITLE_TEXT = 'USB_UNPAIR_DEVICE_CONFIRM_POPUP_TITLE_TEXT';
export const VIBRATE_MODE_DEVICE_SECTION_TITLE = 'VIBRATE_MODE_DEVICE_SECTION_TITLE';
export const VIBRATION_STATE_OFF = 'VIBRATION_STATE_OFF';
export const VIBRATION_STATE_ON = 'VIBRATION_STATE_ON';
export const VIBRATOR_VMG_PULL_DOWN_TEST_FAILURE = 'VIBRATOR_VMG_PULL_DOWN_TEST_FAILURE';
export const VIVALDI_FLOW_TEXT = 'VIVALDI_FLOW_TEXT';
export const VIVALDI_FLOW_TILE_TEXT = 'VIVALDI_FLOW_TILE_TEXT';
export const WELCOME_TEXT = 'WELCOME_TEXT';
export const YAP_ACTIVATING_DEVICE = 'YAP_ACTIVATING_DEVICE';
export const YAP_ACTIVATION_DEVICES_AVAILABLE_ACTIVATE_BUTTON_TEXT =
    'YAP_ACTIVATION_DEVICES_AVAILABLE_ACTIVATE_BUTTON_TEXT';
export const YAP_ACTIVATION_DEVICES_AVAILABLE_ACTIVATION_DESCRIPTION_TEXT =
    'YAP_ACTIVATION_DEVICES_AVAILABLE_ACTIVATION_DESCRIPTION_TEXT';
export const YAP_ACTIVATION_PAUSED_BLUETOOTH_DESCRIPTION = 'YAP_ACTIVATION_PAUSED_BLUETOOTH_DESCRIPTION';
export const USB_YAP_ACTIVATION_PAUSED_BLUETOOTH_DESCRIPTION = 'USB_YAP_ACTIVATION_PAUSED_BLUETOOTH_DESCRIPTION';
export const YAP_ACTIVATION_PAUSED_TITLE = 'YAP_ACTIVATION_PAUSED_TITLE';
export const YAP_ACTIVATION_REGISTRATION_STATE_ACTIVATION_FLOW_PROGRESS_TEXT =
    'YAP_ACTIVATION_REGISTRATION_STATE_ACTIVATION_FLOW_PROGRESS_TEXT';
export const USB_YAP_ACTIVATION_REGISTRATION_STATE_ACTIVATION_FLOW_PROGRESS_TEXT =
    'USB_YAP_ACTIVATION_REGISTRATION_STATE_ACTIVATION_FLOW_PROGRESS_TEXT';
export const YAP_DEACTIVATION_FAILED__TITLE_TEXT = 'YAP_DEACTIVATION_FAILED__TITLE_TEXT';
export const YAP_DEACTIVATION_FAILED_CANCEL_BUTTON_TEXT = 'YAP_DEACTIVATION_FAILED_CANCEL_BUTTON_TEXT';
export const YAP_DEACTIVATION_FAILED_DESCRIPTION_TEXT = 'YAP_DEACTIVATION_FAILED_DESCRIPTION_TEXT';
export const YAP_DEACTIVATION_FAILED_TRY_AGAIN_BUTTON_TEXT = 'YAP_DEACTIVATION_FAILED_TRY_AGAIN_BUTTON_TEXT';
export const YAP_DEACTIVATION_STATE_TEXT = 'YAP_DEACTIVATION_STATE_TEXT';
export const YAP_DEACTIVATION_SUCCESSFULLY_TEXT = 'YAP_DEACTIVATION_SUCCESSFULLY_TEXT';
export const YAP_DEACTIVE_CONFIRM_POPUP_ACCEPT_BUTTON_TEXT = 'YAP_DEACTIVE_CONFIRM_POPUP_ACCEPT_BUTTON_TEXT';
export const YAP_DEACTIVE_CONFIRM_POPUP_CANCEL_BUTTON_TEXT = 'YAP_DEACTIVE_CONFIRM_POPUP_CANCEL_BUTTON_TEXT';
export const YAP_DEACTIVE_CONFIRM_POPUP_DESCRIPTION_TEXT = 'YAP_DEACTIVE_CONFIRM_POPUP_DESCRIPTION_TEXT';
export const YAP_DEACTIVE_CONFIRM_POPUP_TITLE_TEXT = 'YAP_DEACTIVE_CONFIRM_POPUP_TITLE_TEXT';
export const YAP_DEVICE_LIST_NOT_ACTIVATED = 'YAP_DEVICE_LIST_NOT_ACTIVATED';
export const YAP_REGISTERING = 'YAP_REGISTERING';
export const YAP_SKIP_BUTTON_TEXT = 'YAP_SKIP_BUTTON_TEXT';
export const YAP_UNREGISTRATION_STATE_TEXT = 'YAP_UNREGISTRATION_STATE_TEXT';
export const YAP_UNREGISTRATION_SUCCESSFULLY_TEXT = 'YAP_UNREGISTRATION_SUCCESSFULLY_TEXT';
export const LOGIN_DESCRIPTION_TEXT = 'LOGIN_DESCRIPTION_TEXT';
export const LOGIN_BUTTON_TEXT = 'LOGIN_BUTTON_TEXT';
export const LEGAL_DISCLAIMER_TITLE = 'LEGAL_DISCLAIMER_TITLE';
export const LEGAL_DISCLAIMER_LINK_TITLE = 'LEGAL_DISCLAIMER_LINK_TITLE';
export const LEGAL_DISCLAIMER_SCREEN_DESCRIPTION_TEXT = 'LEGAL_DISCLAIMER_SCREEN_DESCRIPTION_TEXT';
export const FORGOT_PASSWORD_BUTTON_TEXT = 'FORGOT_PASSWORD_BUTTON_TEXT';
export const LOGIN_HINT_TEXT = 'LOGIN_HINT_TEXT';
export const PASSWORD_HINT_TEXT = 'PASSWORD_HINT_TEXT';
export const NOT_VALID_USERNAME_TEXT = 'NOT_VALID_USERNAME_TEXT';
export const LOGIN_INVALID_CREDENTIAL_WARNING_TEXT = 'LOGIN_INVALID_CREDENTIAL_WARNING_TEXT';
export const REQUIRED_FIELD_TEXT = 'REQUIRED_FIELD_TEXT';
export const REGISTER_BUTTON_TEXT = 'REGISTER_BUTTON_TEXT';
export const NEW_TO_IQOS_TEXT = 'NEW_TO_IQOS_TEXT';
export const NOT_VALID_PASSWORD_TEXT = 'NOT_VALID_PASSWORD_TEXT';
export const LOGIN_TITLE_TEXT = 'LOGIN_TITLE_TEXT';
export const AGE_GATE_DESCRIPTION = 'AGE_GATE_DESCRIPTION';
export const BIRTH_DATE_PICKER_TITLE = 'BIRTH_DATE_PICKER_TITLE';
export const AGE_GATE_ERROR_NOTIFICATION_TEXT = 'AGE_GATE_ERROR_NOTIFICATION_TEXT';
export const AGE_GATE_BUTTON_CONFIRM = 'AGE_GATE_BUTTON_CONFIRM';
export const AGE_GATE_MONTH = 'AGE_GATE_MONTH';
export const AGE_GATE_YEAR = 'AGE_GATE_YEAR';
export const AGE_GATE_JANUARY_TEXT = 'AGE_GATE_JANUARY_TEXT';
export const AGE_GATE_FEBRUARY_TEXT = 'AGE_GATE_FEBRUARY_TEXT';
export const AGE_GATE_MARCH_TEXT = 'AGE_GATE_MARCH_TEXT';
export const AGE_GATE_APRIL_TEXT = 'AGE_GATE_APRIL_TEXT';
export const AGE_GATE_MAY_TEXT = 'AGE_GATE_MAY_TEXT';
export const AGE_GATE_JUNE_TEXT = 'AGE_GATE_JUNE_TEXT';
export const AGE_GATE_JULY_TEXT = 'AGE_GATE_JULY_TEXT';
export const AGE_GATE_AUGUST_TEXT = 'AGE_GATE_AUGUST_TEXT';
export const AGE_GATE_SEPTEMBER_TEXT = 'AGE_GATE_SEPTEMBER_TEXT';
export const AGE_GATE_OCTOBER_TEXT = 'AGE_GATE_OCTOBER_TEXT';
export const AGE_GATE_NOVEMBER_TEXT = 'AGE_GATE_NOVEMBER_TEXT';
export const AGE_GATE_DECEMBER_TEXT = 'AGE_GATE_DECEMBER_TEXT';
export const PASSWORD_TITLE_TEXT = 'PASSWORD_TITLE_TEXT';
export const LEGAL_DISCLAIMER_SCREEN_CONTINUE_BUTTON_TEXT = 'LEGAL_DISCLAIMER_SCREEN_CONTINUE_BUTTON_TEXT';
export const YAP_ACTIVATION_DEVICES_DISCONNECTED_DESCRIPTION_TEXT =
    'YAP_ACTIVATION_DEVICES_DISCONNECTED_DESCRIPTION_TEXT';
export const LOGOUT_BUTTON_TEXT = 'LOGOUT_BUTTON_TEXT';
export const DEVICE_SETTINGS_NEAR_SHOP = 'DEVICE_SETTINGS_NEAR_SHOP';
export const MANY_REGISTER_DEVICE_REQUEST_ERROR_POPUP_TITLE_TEXT =
    'MANY_REGISTER_DEVICE_REQUEST_ERROR_POPUP_TITLE_TEXT';
export const MANY_REGISTER_DEVICE_REQUEST_ERROR_POPUP_DESCRIPTION_TEXT =
    'MANY_REGISTER_DEVICE_REQUEST_ERROR_POPUP_DESCRIPTION_TEXT';
export const MANY_REGISTER_DEVICE_REQUEST_ERROR_POPUP_OK_GOT_IT_BUTTON_TEXT =
    'MANY_REGISTER_DEVICE_REQUEST_ERROR_POPUP_OK_GOT_IT_BUTTON_TEXT';
export const REACHED_LIMIT_REGISTERED_DEVICE_ERROR_POPUP_TITLE_TEXT =
    'REACHED_LIMIT_REGISTERED_DEVICE_ERROR_POPUP_TITLE_TEXT';
export const REACHED_LIMIT_REGISTERED_DEVICE_ERROR_POPUP_DESCRIPTION_TEXT =
    'REACHED_LIMIT_REGISTERED_DEVICE_ERROR_POPUP_DESCRIPTION_TEXT';
export const REACHED_LIMIT_REGISTERED_DEVICE_ERROR_POPUP_OK_GOT_IT_BUTTON_TEXT =
    'REACHED_LIMIT_REGISTERED_DEVICE_ERROR_POPUP_OK_GOT_IT_BUTTON_TEXT';
export const NO_REGISTERED_DEVICES_TITLE_TEXT = 'NO_REGISTERED_DEVICES_TITLE_TEXT';
export const NO_REGISTERED_DEVICES_DESCRIPTION_TEXT = 'NO_REGISTERED_DEVICES_DESCRIPTION_TEXT';
export const NO_REGISTERED_DEVICES_LEARN_MORE_BUTTON_TEXT = 'NO_REGISTERED_DEVICES_LEARN_MORE_BUTTON_TEXT';
export const NO_REGISTERED_DEVICES_CONFIRM_BUTTON_TEXT = 'NO_REGISTERED_DEVICES_CONFIRM_BUTTON_TEXT';
export const DISCLAIMER_SCREEN_TITLE_TEXT = 'DISCLAIMER_SCREEN_TITLE_TEXT';
export const DISCLAIMER_SCREEN_DESCRIPTION_TEXT = 'DISCLAIMER_SCREEN_DESCRIPTION_TEXT';
export const DISCLAIMER_SCREEN_CONTINUE_BUTTON_TEXT = 'DISCLAIMER_SCREEN_CONTINUE_BUTTON_TEXT';
export const HARD_AGE_VERIFICATION_FAILED_TITLE_TEXT = 'HARD_AGE_VERIFICATION_FAILED_TITLE_TEXT';
export const HARD_AGE_VERIFICATION_FAILED_DESCRIPTION_TEXT = 'HARD_AGE_VERIFICATION_FAILED_DESCRIPTION_TEXT';
export const HARD_AGE_VERIFICATION_FAILED_GO_TO_WEBSITE_BUTTON_TEXT =
    'HARD_AGE_VERIFICATION_FAILED_GO_TO_WEBSITE_BUTTON_TEXT';
export const GLOBAL_ERROR_TITLE_TEXT = 'GLOBAL_ERROR_TITLE_TEXT';
export const GLOBAL_ERROR_DESCRIPTION_TEXT = 'GLOBAL_ERROR_DESCRIPTION_TEXT';
export const GLOBAL_ERROR_CONTACT_US_BUTTON_TEXT = 'GLOBAL_ERROR_CONTACT_US_BUTTON_TEXT';
export const GLOBAL_ERROR_WITH_RETRY_DESCRIPTION_TEXT = 'GLOBAL_ERROR_WITH_RETRY_DESCRIPTION_TEXT';
export const GLOBAL_ERROR_TRY_AGAIN_BUTTON_TEXT = 'GLOBAL_ERROR_TRY_AGAIN_BUTTON_TEXT';
export const NO_REGISTERED_LEARN_MORE_TITLE_TEXT = 'NO_REGISTERED_LEARN_MORE_TITLE_TEXT';
export const NO_REGISTERED_DEVICES_LEARN_MORE_WARRANTY_DESCRIPTION_TEXT =
    'NO_REGISTERED_DEVICES_LEARN_MORE_WARRANTY_DESCRIPTION_TEXT';
export const NO_REGISTERED_DEVICES_LEARN_MORE_SPECIAL_OFFERS_DESCRIPTION_TEXT =
    'NO_REGISTERED_DEVICES_LEARN_MORE_SPECIAL_OFFERS_DESCRIPTION_TEXT';
export const NO_REGISTERED_DEVICES_LEARN_MORE_REPLACEMENT_DESCRIPTION_TEXT =
    'NO_REGISTERED_DEVICES_LEARN_MORE_REPLACEMENT_DESCRIPTION_TEXT';
export const NO_REGISTERED_DEVICES_LEARN_MORE_TERMS_DESCRIPTION_TEXT =
    'NO_REGISTERED_DEVICES_LEARN_MORE_TERMS_DESCRIPTION_TEXT';

export const SET_UP_POPUP_TITLE_TEXT = 'SET_UP_POPUP_TITLE_TEXT';
export const SET_UP_POPUP_NEW_DEVICE_TITLE_TEXT = 'SET_UP_POPUP_NEW_DEVICE_TITLE_TEXT';
export const SET_UP_POPUP_DESCRIPTION_TEXT = 'SET_UP_POPUP_DESCRIPTION_TEXT';
export const SET_UP_POPUP_CONTINUE_VIA_USB_BUTTON_TEXT = 'SET_UP_POPUP_CONTINUE_VIA_USB_BUTTON_TEXT';
export const SET_UP_POPUP_SET_UP_WITH_BLUETOOTH_BUTTON_TEXT = 'SET_UP_POPUP_SET_UP_WITH_BLUETOOTH_BUTTON_TEXT';
export const USB_DEVICE_IN_PROGRESS_TITLE = 'USB_DEVICE_IN_PROGRESS_TITLE';
export const USB_DEVICE_SUCCESSFULLY_CONNECTED_TITLE = 'USB_DEVICE_SUCCESSFULLY_CONNECTED_TITLE';
export const DEVICE_SETTINGS_CONNECT_VIA_USB_NOTIFICATION_TEXT = 'DEVICE_SETTINGS_CONNECT_VIA_USB_NOTIFICATION_TEXT';
export const NOT_COMPATIBLE_OS_POPUP_IMG_FAILED_ICON_ALT = 'NOT_COMPATIBLE_OS_POPUP_IMG_FAILED_ICON_ALT';
export const APP_INTRO_PAGE_TITLE = 'APP_INTRO_PAGE_TITLE';
export const APP_INTRO_PAGE_IMG_BLUETOOTH_LOGO_ALT = 'APP_INTRO_PAGE_IMG_BLUETOOTH_LOGO_ALT';
export const APP_INTRO_PAGE_IMG_P1_AVAILABLE_FEATURES_ALT = 'APP_INTRO_PAGE_IMG_P1_AVAILABLE_FEATURES_ALT';
export const APP_INTRO_PAGE_IMG_P4_AVAILABLE_FEATURES_ALT = 'APP_INTRO_PAGE_IMG_P4_AVAILABLE_FEATURES_ALT';
export const APP_INTRO_PAGE_BTN_DOWNLOAD_MAC_OS_ARIA_LABEL = 'APP_INTRO_PAGE_BTN_DOWNLOAD_MAC_OS_ARIA_LABEL';
export const APP_INTRO_PAGE_BTN_DOWNLOAD_WINDOWS_OS_ARIA_LABEL = 'APP_INTRO_PAGE_BTN_DOWNLOAD_WINDOWS_OS_ARIA_LABEL';
export const APP_INTRO_PAGE_BTN_DOWNLOAD_ANDROID_OS_ARIA_LABEL = 'APP_INTRO_PAGE_BTN_DOWNLOAD_ANDROID_OS_ARIA_LABEL';
export const APP_INTRO_PAGE_BTN_DOWNLOAD_CHROME_DESKTOP_ARIA_LABEL =
    'APP_INTRO_PAGE_BTN_DOWNLOAD_CHROME_DESKTOP_ARIA_LABEL';
export const APP_INTRO_PAGE_BTN_DOWNLOAD_CHROME_MOBILE_ARIA_LABEL =
    'APP_INTRO_PAGE_BTN_DOWNLOAD_CHROME_MOBILE_ARIA_LABEL';
export const APP_INTRO_PAGE_BTN_DOWNLOAD_IOS_OS_ARIA_LABEL = 'APP_INTRO_PAGE_BTN_DOWNLOAD_IOS_OS_ARIA_LABEL';
export const NOT_COMPATIBLE_BROWSER_PAGE_TITLE = 'NOT_COMPATIBLE_BROWSER_PAGE_TITLE';
export const NOT_COMPATIBLE_BROWSER_PAGE_P1_AVAILABLE_FEATURES_ALT =
    'NOT_COMPATIBLE_BROWSER_PAGE_P1_AVAILABLE_FEATURES_ALT';
export const NOT_COMPATIBLE_BROWSER_PAGE_P4_AVAILABLE_FEATURES_ALT =
    'NOT_COMPATIBLE_BROWSER_PAGE_P4_AVAILABLE_FEATURES_ALT';
export const NOT_COMPATIBLE_BROWSER_PAGE_IMG_BLUETOOTH_ALT = 'NOT_COMPATIBLE_BROWSER_PAGE_IMG_BLUETOOTH_ALT';
export const AGE_VERIFICATION_FAILED_TITLE = 'AGE_VERIFICATION_FAILED_TITLE';
export const AGE_VERIFICATION_FAILED_IMG_ERROR_LOGO_ALT = 'AGE_VERIFICATION_FAILED_IMG_ERROR_LOGO_ALT';
export const TERMS_OF_USE_PAGE_TITLE = 'TERMS_OF_USE_PAGE_TITLE';
export const TERMS_OF_USE_PAGE_BTN_USER_RETURN_TCP_ARIA_LABEL = 'TERMS_OF_USE_PAGE_BTN_USER_RETURN_TCP_ARIA_LABEL';
export const TERMS_OF_USE_PAGE_BTN_USER_RETURN_TUD_ARIA_LABEL = 'TERMS_OF_USE_PAGE_BTN_USER_RETURN_TUD_ARIA_LABEL';
export const DATA_COLLECTION_PAGE_TITLE = 'DATA_COLLECTION_PAGE_TITLE';
export const DATA_COLLECTION_PAGE_BTN_RETURNS_USER_ARIA_LABEL = 'DATA_COLLECTION_PAGE_BTN_RETURNS_USER_ARIA_LABEL';
export const PREFERENCE_CENTER_PAGE_TITLE = 'PREFERENCE_CENTER_PAGE_TITLE';
export const PREFERENCE_CENTER_PAGE_BTN_RETURNS_USER_ARIA_LABEL = 'PREFERENCE_CENTER_PAGE_BTN_RETURNS_USER_ARIA_LABEL';
export const PREFERENCE_CENTER_PAGE_BTN_SWITCH_TOGGLE_ON_ARIA_LABEL =
    'PREFERENCE_CENTER_PAGE_BTN_SWITCH_TOGGLE_ON_ARIA_LABEL';
export const PREFERENCE_CENTER_PAGE_BTN_SWITCH_TOGGLE_OFF_ARIA_LABEL =
    'PREFERENCE_CENTER_PAGE_BTN_SWITCH_TOGGLE_OFF_ARIA_LABEL';
export const NO_DEVICES_PAGE_TITLE = 'NO_DEVICES_PAGE_TITLE';
export const BENEFIT_PAGE_TITLE = 'BENEFIT_PAGE_TITLE';
export const MY_DEVICES_PAGE_TITLE = 'MY_DEVICES_PAGE_TITLE';
export const MY_DEVICES_PAGE_PAIRING_ALERT_ARIA_LABEL = 'MY_DEVICES_PAGE_PAIRING_ALERT_ARIA_LABEL';
export const MY_DEVICES_PAGE_DEFAULT_DEVICE_NAME_ALT = 'MY_DEVICES_PAGE_DEFAULT_DEVICE_NAME_ALT';
export const MY_DEVICES_PAGE_DEFAULT_DEVICE_NAME_ARIA_LABEL = 'MY_DEVICES_PAGE_DEFAULT_DEVICE_NAME_ARIA_LABEL';
export const MY_DEVICES_PAGE_BTN_SCROLL_LEFT_ARIA_LABEL = 'MY_DEVICES_PAGE_BTN_SCROLL_LEFT_ARIA_LABEL';
export const MY_DEVICES_PAGE_BTN_SCROLL_RIGHT_ARIA_LABEL = 'MY_DEVICES_PAGE_BTN_SCROLL_RIGHT_ARIA_LABEL';
export const MY_DEVICES_PAGE_TEXT_BATTERY_LEVEL_ARIA_LABEL = 'MY_DEVICES_PAGE_TEXT_BATTERY_LEVEL_ARIA_LABEL';
export const MY_DEVICES_PAGE_IMG_HOLDER_ALT = 'MY_DEVICES_PAGE_IMG_HOLDER_ALT';
export const PAIR_ALERT_PAGE_TITLE = 'PAIR_ALERT_PAGE_TITLE';
export const PAIR_ALERT_PAGE_IMG_LOGO_WARNING_ALT = 'PAIR_ALERT_PAGE_IMG_LOGO_WARNING_ALT';
export const PAIR_ALERT_PAGE_BTN_DEVICE_LIST_PAGE_ARIA_LABEL = 'PAIR_ALERT_PAGE_BTN_DEVICE_LIST_PAGE_ARIA_LABEL';
export const SELECT_DEVICE_PAGE_TITLE = 'SELECT_DEVICE_PAGE_TITLE';
export const SELECT_DEVICE_PAGE_BTN_SKIP_PAIRING_ARIA_LABEL = 'SELECT_DEVICE_PAGE_BTN_SKIP_PAIRING_ARIA_LABEL';
export const QUIT_DEVICE_SET_UP_TITLE = 'QUIT_DEVICE_SET_UP_TITLE';
export const QUIT_DEVICE_SET_UP_BTN_DEVICE_LIST_PAGE_ARIA_LABEL = 'QUIT_DEVICE_SET_UP_BTN_DEVICE_LIST_PAGE_ARIA_LABEL';
export const DEVICE_PAIRING_PAGE_TITLE = 'DEVICE_PAIRING_PAGE_TITLE';
export const DEVICE_PAIRING_PAGE_BTN_SKIP_PAIRING_POPUP_ARIA_LABEL =
    'DEVICE_PAIRING_PAGE_BTN_SKIP_PAIRING_POPUP_ARIA_LABEL';
export const DEVICE_PAIRING_PAGE_BTN_NEXT_PAIRING_GUIDE_PAGE_ARIA_LABEL =
    'DEVICE_PAIRING_PAGE_BTN_NEXT_PAIRING_GUIDE_PAGE_ARIA_LABEL';
export const DEVICE_PAIRING_PAGE_BTN_BACK_TO_PREV_GUIDE_PAGE_ARIA_LABEL =
    'DEVICE_PAIRING_PAGE_BTN_BACK_TO_PREV_GUIDE_PAGE_ARIA_LABEL';
export const DEVICE_PAIRING_ERROR_IMG_LOGO_ALT = 'DEVICE_PAIRING_ERROR_IMG_LOGO_ALT';
export const DEVICE_PAIRING_ERROR_BTN_SKIP_ARIA_LABEL = 'DEVICE_PAIRING_ERROR_BTN_SKIP_ARIA_LABEL';
export const DEVICE_REGISTRATION_TITLE = 'DEVICE_REGISTRATION_TITLE';
export const DEVICE_REGISTRATION_IMG_WARNING_ALT = 'DEVICE_REGISTRATION_IMG_WARNING_ALT';
export const DEVICE_REGISTRATION_POPUP_BTN_CLOSE_ARIA_LABEL = 'DEVICE_REGISTRATION_POPUP_BTN_CLOSE_ARIA_LABEL';
export const DEVICE_REGISTRATION_POPUP_BTN_CANCEL_ARIA_LABEL = 'DEVICE_REGISTRATION_POPUP_BTN_CANCEL_ARIA_LABEL';
export const DEVICE_UNLOCKING_TITLE = 'DEVICE_UNLOCKING_TITLE';
export const DEVICE_UNLOCKING_ERROR_NOTIFICATION_IMG_WARNING_ALT =
    'DEVICE_UNLOCKING_ERROR_NOTIFICATION_IMG_WARNING_ALT';
export const DEVICE_UNLOCKING_ERROR_NOTIFICATION_BTN_CLOSE_ARIA_LABEL =
    'DEVICE_UNLOCKING_ERROR_NOTIFICATION_BTN_CLOSE_ARIA_LABEL';
export const QUICK_START_GUIDE_TITLE = 'QUICK_START_GUIDE_TITLE';
export const QUICK_START_GUIDE_BTN_SKIP_ARIA_LABEL = 'QUICK_START_GUIDE_BTN_SKIP_ARIA_LABEL';
export const QUICK_START_GUIDE_BTN_NEXT_ARIA_LABEL = 'QUICK_START_GUIDE_BTN_NEXT_ARIA_LABEL';
export const QUICK_START_GUIDE_BTN_PREV_ARIA_LABEL = 'QUICK_START_GUIDE_BTN_PREV_ARIA_LABEL';
export const QUICK_START_GUIDE_BTN_ONE_OF_FIVE_ARIA_LABEL = 'QUICK_START_GUIDE_BTN_ONE_OF_FIVE_ARIA_LABEL';
export const QUICK_START_GUIDE_BTN_TWO_OF_FIVE_ARIA_LABEL = 'QUICK_START_GUIDE_BTN_TWO_OF_FIVE_ARIA_LABEL';
export const QUICK_START_GUIDE_BTN_THIRD_OF_FIVE_ARIA_LABEL = 'QUICK_START_GUIDE_BTN_THIRD_OF_FIVE_ARIA_LABEL';
export const QUICK_START_GUIDE_BTN_FOUR_OF_FIVE_ARIA_LABEL = 'QUICK_START_GUIDE_BTN_FOUR_OF_FIVE_ARIA_LABEL';
export const QUICK_START_GUIDE_BTN_FIVE_OF_FIVE_ARIA_LABEL = 'QUICK_START_GUIDE_BTN_FIVE_OF_FIVE_ARIA_LABEL';
export const QUICK_START_GUIDE_BTN_LAST_SLIDE_ARIA_LABEL = 'QUICK_START_GUIDE_BTN_LAST_SLIDE_ARIA_LABEL';
export const TIPS_AND_TUTORIALS_TITLE = 'TIPS_AND_TUTORIALS_TITLE';
export const TIPS_AND_TUTORIALS_BTN_BACK_ARIA_LABEL = 'TIPS_AND_TUTORIALS_BTN_BACK_ARIA_LABEL';
export const TIPS_AND_TUTORIALS_IMG_LOGO_ASSES_ALT = 'TIPS_AND_TUTORIALS_IMG_LOGO_ASSES_ALT';
export const TIPS_AND_TUTORIALS_INTRO_BTN_BACK_TO_TIPS_ARIA_LABEL =
    'TIPS_AND_TUTORIALS_INTRO_BTN_BACK_TO_TIPS_ARIA_LABEL';
export const TIPS_AND_TURORIALS_INTRO_BTN_BACK_ARIA_LABEL = 'TIPS_AND_TURORIALS_INTRO_BTN_BACK_ARIA_LABEL';
export const TIPS_AND_TURORIALS_INTRO_BTN_CONTINUE_ARIA_LABEL = 'TIPS_AND_TURORIALS_INTRO_BTN_CONTINUE_ARIA_LABEL';
export const DEVICE_SETTINGS_TITLE = 'DEVICE_SETTINGS_TITLE';
export const DEVICE_SETTINGS_BTN_DEVICE_LIST_ARIA_LABEL = 'DEVICE_SETTINGS_BTN_DEVICE_LIST_ARIA_LABEL';
export const DEVICE_SETTINGS_BTN_LOCK_POPUP_ARIA_LABEL = 'DEVICE_SETTINGS_BTN_LOCK_POPUP_ARIA_LABEL';
export const DEVICE_SETTINGS_BTN_BATTERY_LIFE_ARIA_LABEL = 'DEVICE_SETTINGS_BTN_BATTERY_LIFE_ARIA_LABEL';
export const DEVICE_SETTINGS_BTN_ILLUM_MODE_ARIA_LABEL = 'DEVICE_SETTINGS_BTN_ILLUM_MODE_ARIA_LABEL';
export const DEVICE_SETTINGS_BTN_PESPONSIVE_DRAW_ARIA_LABEL = 'DEVICE_SETTINGS_BTN_PESPONSIVE_DRAW_ARIA_LABEL';
export const DEVICE_SETTINGS_BTN_VAPE_CLOUD_SIZE_ARIA_LABEL = 'DEVICE_SETTINGS_BTN_VAPE_CLOUD_SIZE_ARIA_LABEL';
export const DEVICE_SETTINGS_BTN_VIBRATIONS_MODE_ARIA_LABEL = 'DEVICE_SETTINGS_BTN_VIBRATIONS_MODE_ARIA_LABEL';
export const DEVICE_SETTINGS_BTN_MOBILE_TIPS_ARROW_ARIA_LABEL = 'DEVICE_SETTINGS_BTN_MOBILE_TIPS_ARROW_ARIA_LABEL';
export const LOCK_FEATURE_IMG_WARNING_ALT = 'LOCK_FEATURE_IMG_WARNING_ALT';
export const LOCK_FEATURE_BTN_CLOSE_ARIA_LABEL = 'LOCK_FEATURE_BTN_CLOSE_ARIA_LABEL';
export const LOCK_FEATURE_BTN_LOCK_ARIA_LABEL = 'LOCK_FEATURE_BTN_LOCK_ARIA_LABEL';
export const LOCK_FEATURE_BTN_CANCEL_ARIA_LABEL = 'LOCK_FEATURE_BTN_CANCEL_ARIA_LABEL';
export const LOCK_FEATURE_BTN_UNLOCK_ARIA_LABEL = 'LOCK_FEATURE_BTN_UNLOCK_ARIA_LABEL';
export const ILLUMINATION_MODE_BTN_CLOSE_ARIA_LABEL = 'ILLUMINATION_MODE_BTN_CLOSE_ARIA_LABEL';
export const RESPONSIVE_DRAW_BTN_CLOSE_ARIA_LABEL = 'RESPONSIVE_DRAW_BTN_CLOSE_ARIA_LABEL';
export const VAPE_CLOUD_SIZE_BTN_CLOSE_ARIA_LABEL = 'VAPE_CLOUD_SIZE_BTN_CLOSE_ARIA_LABEL';
export const LOCATE_MY_DEVICE_IMG_DEVICE_NAME_ALT = 'LOCATE_MY_DEVICE_IMG_DEVICE_NAME_ALT';
export const LOCATE_MY_DEVICE_IMG_HOLDER_NAME_ALT = 'LOCATE_MY_DEVICE_IMG_HOLDER_NAME_ALT';
export const LOCATE_MY_DEVICE_BTN_CLOSE_ARIA_LABEL = 'LOCATE_MY_DEVICE_BTN_CLOSE_ARIA_LABEL';
export const LOCATE_MY_DEVICE_BTN_START_VIBRATE_ARIA_LABEL = 'LOCATE_MY_DEVICE_BTN_START_VIBRATE_ARIA_LABEL';
export const AUTO_CLEANING_BTN_CLOSE_ARIA_LABEL = 'AUTO_CLEANING_BTN_CLOSE_ARIA_LABEL';
export const AUTO_CLEANING_IMG_DEVICE_MAIN_ALT = 'AUTO_CLEANING_IMG_DEVICE_MAIN_ALT';
export const AUTO_CLEANING_IMG_DEVICE_HOLDER_ALT = 'AUTO_CLEANING_IMG_DEVICE_HOLDER_ALT';
export const VIBRATIONS_FEATURE_BTN_CLOSE_ARIA_LABEL = 'VIBRATIONS_FEATURE_BTN_CLOSE_ARIA_LABEL';
export const VIBRATIONS_FEATURE_BTN_VALUE_OF_BUTTON_ARIA_LABEL = 'VIBRATIONS_FEATURE_BTN_VALUE_OF_BUTTON_ARIA_LABEL';
export const RENAME_MY_DEVICE_FEATURE_BTN_CLOSE_ARIA_LABEL = 'RENAME_MY_DEVICE_FEATURE_BTN_CLOSE_ARIA_LABEL';
export const DEVICE_RENAME_NOTIFICATION_SUCCESS_TEXT = 'DEVICE_RENAME_NOTIFICATION_SUCCESS_TEXT';
export const RENAME_MY_DEVICE_FEATURE_BTN_DELETE_VALUE_ARIA_LABEL =
    'RENAME_MY_DEVICE_FEATURE_BTN_DELETE_VALUE_ARIA_LABEL';
export const RENAME_MY_DEVICE_FEATURE_BTN_SAVE_CHANGES_ARIA_LABEL =
    'RENAME_MY_DEVICE_FEATURE_BTN_SAVE_CHANGES_ARIA_LABEL';
export const RENAME_MY_DEVICE_FEATURE_BTN_CANCEL_ARIA_LABEL = 'RENAME_MY_DEVICE_FEATURE_BTN_CANCEL_ARIA_LABEL';
export const PREF_CENTER_BACK_TO_DEVICE_SETTINGS_ARIA_LABEL = 'PREF_CENTER_BACK_TO_DEVICE_SETTINGS_ARIA_LABEL';
export const LEGAL_AND_POLICY_PAGE_TITLE = 'LEGAL_AND_POLICY_PAGE_TITLE';
export const LEGAL_AND_POLICY_PAGE_BTN_BACK_ARIA_LABEL = 'LEGAL_AND_POLICY_PAGE_BTN_BACK_ARIA_LABEL';
export const DEVICE_TROUBLESHOOTING_NOTIFICATION_IMG_WARNING_ALT =
    'DEVICE_TROUBLESHOOTING_NOTIFICATION_IMG_WARNING_ALT';
export const DEVICE_TROUBLESHOOTING_NOTIFICATION_BTN_CLOSE_ARIA_LABEL =
    'DEVICE_TROUBLESHOOTING_NOTIFICATION_BTN_CLOSE_ARIA_LABEL';
export const ABOUT_MY_DEVICE_FEATURE_TITLE = 'ABOUT_MY_DEVICE_FEATURE_TITLE';
export const ABOUT_MY_DEVICE_FEATURE_BTN_BACK_TO_DEVICE_SETTINGS_ARIA_LABEL =
    'ABOUT_MY_DEVICE_FEATURE_BTN_BACK_TO_DEVICE_SETTINGS_ARIA_LABEL';
export const ABOUT_MY_DEVICE_FEATURE_IMG_P1_ALT = 'ABOUT_MY_DEVICE_FEATURE_IMG_P1_ALT';
export const ABOUT_MY_DEVICE_FEATURE_BTN_EXPAND_ARIA_LABEL = 'ABOUT_MY_DEVICE_FEATURE_BTN_EXPAND_ARIA_LABEL';
export const ABOUT_MY_DEVICE_FEATURE_BTN_HIDE_ARIA_LABEL = 'ABOUT_MY_DEVICE_FEATURE_BTN_HIDE_ARIA_LABEL';
export const ABOUT_MY_DEVICE_FEATURE_IMG_HOLDER_P1_ALT = 'ABOUT_MY_DEVICE_FEATURE_IMG_HOLDER_P1_ALT';
export const UNREGISTRED_DEVICE_NOTIFICATION_IMG_WARNING_ALT = 'UNREGISTRED_DEVICE_NOTIFICATION_IMG_WARNING_ALT';
export const UNREGISTRED_DEVICE_NOTIFICATION_BTN_CLOSE_ARIA_LABEL =
    'UNREGISTRED_DEVICE_NOTIFICATION_BTN_CLOSE_ARIA_LABEL';
export const UNREGISTRED_DEVICE_NOTIFICATION_BTN_CANCEL_ARIA_LABEL =
    'UNREGISTRED_DEVICE_NOTIFICATION_BTN_CANCEL_ARIA_LABEL';
export const FIRMWARE_UPDATE_NOTIFICATION_IMG_WARNING_ALT = 'FIRMWARE_UPDATE_NOTIFICATION_IMG_WARNING_ALT';
export const FIRMWARE_UPDATE_NOTIFICATION_BTN_CLOSE_ARIA_LABEL = 'FIRMWARE_UPDATE_NOTIFICATION_BTN_CLOSE_ARIA_LABEL';
export const FIRMWARE_UPDATE_NOTIFICATION_BTN_CANCEL_ARIA_LABEL = 'FIRMWARE_UPDATE_NOTIFICATION_BTN_CANCEL_ARIA_LABEL';
export const FIRMWARE_UPDATE_TITLE = 'FIRMWARE_UPDATE_TITLE';
export const FIRMWARE_UPDATE_IMG_DEVICE_ALT = 'FIRMWARE_UPDATE_IMG_DEVICE_ALT';
export const FIRMWARE_UPDATE_IMG_HOLDER_ALT = 'FIRMWARE_UPDATE_IMG_HOLDER_ALT';
export const FIRMWARE_UPDATE_IMG_WARNING_ALT = 'FIRMWARE_UPDATE_IMG_WARNING_ALT';
export const FIRMWARE_UPDATE_BTN_CLOSE_ARIA_LABEL = 'FIRMWARE_UPDATE_BTN_CLOSE_ARIA_LABEL';
export const FIRMWARE_UPDATE_BTN_CANCEL_ARIA_LABEL = 'FIRMWARE_UPDATE_BTN_CANCEL_ARIA_LABEL';
export const DEVICE_REGISTRATION_ERROR_NOTIFICATION_BTN_CANCEL_ARIA_LABEL =
    'DEVICE_REGISTRATION_ERROR_NOTIFICATION_BTN_CANCEL_ARIA_LABEL';
export const DEVICE_REGISTRATION_ERROR_NOTIFICATION_IMG_ERROR_ALT =
    'DEVICE_REGISTRATION_ERROR_NOTIFICATION_IMG_ERROR_ALT';
export const DEVICE_REGISTRATION_ERROR_NOTIFICATION_IMG_WARNING_ALT =
    'DEVICE_REGISTRATION_ERROR_NOTIFICATION_IMG_WARNING_ALT';
export const UNPAIR_DEVICE_NOTIFICATION_IMG_WARNING_ALT = 'UNPAIR_DEVICE_NOTIFICATION_IMG_WARNING_ALT';
export const UNPAIR_DEVICE_NOTIFICATION_BTN_CLOSE_ARIA_LABEL = 'UNPAIR_DEVICE_NOTIFICATION_BTN_CLOSE_ARIA_LABEL';
export const CAROUSEL_DOTS_BTN_ARIA_LABEL = 'CAROUSEL_DOTS_BTN_ARIA_LABEL';
export const DEVICE_SETTINGS_PREMIUM_FEATURES_HEADER_TEXT = 'DEVICE_SETTINGS_PREMIUM_FEATURES_HEADER_TEXT';
export const AUTO_START_MODE_TITLE = 'AUTO_START_MODE_TITLE';
export const SMART_GESTURES_TITLE = 'SMART_GESTURES_TITLE';
export const SMART_GESTURES_DESCRIPTION = 'SMART_GESTURES_DESCRIPTION';
export const AUTO_START_MODE_DESCRIPTION = 'AUTO_START_MODE_DESCRIPTION';
export const AUTO_START_MODE_BUTTON_TEXT = 'AUTO_START_MODE_BUTTON_TEXT';
export const SMART_GESTURES_DOUBLE_TAP_TEXT = 'SMART_GESTURES_DOUBLE_TAP_TEXT';
export const SMART_GESTURES_LIFT_UP_TEXT = 'SMART_GESTURES_LIFT_UP_TEXT';
export const P1V40_FIRST_ITEM_TITLE = 'P1V40_FIRST_ITEM_TITLE';
export const TIPS_QUICK_START_GUIDE_P1V40_TITLE_1 = 'TIPS_QUICK_START_GUIDE_P1V40_TITLE_1';
export const TIPS_QUICK_START_GUIDE_P1V40_DESCRIPTION_1 = 'TIPS_QUICK_START_GUIDE_P1V40_DESCRIPTION_1';
export const TIPS_QUICK_START_GUIDE_P1V40_TITLE_2 = 'TIPS_QUICK_START_GUIDE_P1V40_TITLE_2';
export const TIPS_QUICK_START_GUIDE_P1V40_DESCRIPTION_2 = 'TIPS_QUICK_START_GUIDE_P1V40_DESCRIPTION_2';
export const TIPS_QUICK_START_GUIDE_P1V40_TITLE_3 = 'TIPS_QUICK_START_GUIDE_P1V40_TITLE_3';
export const TIPS_QUICK_START_GUIDE_P1V40_DESCRIPTION_3 = 'TIPS_QUICK_START_GUIDE_P1V40_DESCRIPTION_3';
export const TIPS_QUICK_START_GUIDE_P1V40_TITLE_4 = 'TIPS_QUICK_START_GUIDE_P1V40_TITLE_4';
export const TIPS_QUICK_START_GUIDE_P1V40_DESCRIPTION_4 = 'TIPS_QUICK_START_GUIDE_P1V40_DESCRIPTION_4';
export const TIPS_QUICK_START_GUIDE_P1V40_TITLE_5 = 'TIPS_QUICK_START_GUIDE_P1V40_TITLE_5';
export const TIPS_QUICK_START_GUIDE_P1V40_DESCRIPTION_5 = 'TIPS_QUICK_START_GUIDE_P1V40_DESCRIPTION_5';
export const TIPS_QUICK_START_GUIDE_P1V40_TITLE_6 = 'TIPS_QUICK_START_GUIDE_P1V40_TITLE_6';
export const TIPS_QUICK_START_GUIDE_P1V40_DESCRIPTION_6 = 'TIPS_QUICK_START_GUIDE_P1V40_DESCRIPTION_6';
export const TIPS_QUICK_START_GUIDE_P1V40_TITLE_7 = 'TIPS_QUICK_START_GUIDE_P1V40_TITLE_7';
export const TIPS_QUICK_START_GUIDE_P1V40_DESCRIPTION_7 = 'TIPS_QUICK_START_GUIDE_P1V40_DESCRIPTION_7';
export const TIPS_QUICK_START_GUIDE_P1V40_TITLE_8 = 'TIPS_QUICK_START_GUIDE_P1V40_TITLE_8';
export const TIPS_QUICK_START_GUIDE_P1V40_DESCRIPTION_8 = 'TIPS_QUICK_START_GUIDE_P1V40_DESCRIPTION_8';
export const P1V40_FOURTH_ITEM_TITLE = 'P1V40_FOURTH_ITEM_TITLE';
export const P1V40_SECOND_ITEM_TITLE = 'P1V40_SECOND_ITEM_TITLE';
export const P1V40_THIRD_ITEM_TITLE = 'P1V40_THIRD_ITEM_TITLE';
export const TIPS_P1V40_TITLE = 'TIPS_P1V40_TITLE';
export const TIPS_P1V40_DESCRIPTION = 'TIPS_P1V40_DESCRIPTION';
export const AUTO_START_MODE_BUTTON_ACTION_TEXT_ON = 'AUTO_START_MODE_BUTTON_ACTION_TEXT_ON';
export const SMART_GESTURES_STATE_ON = 'SMART_GESTURES_STATE_ON';
export const AUTO_START_MODE_BUTTON_ACTION_TEXT_OFF = 'AUTO_START_MODE_BUTTON_ACTION_TEXT_OFF';
export const SMART_GESTURES_STATE_OFF = 'SMART_GESTURES_STATE_OFF';
export const QUICK_START_GUIDE_P1V40_TITLE_1 = 'QUICK_START_GUIDE_P1V40_TITLE_1';
export const QUICK_START_GUIDE_P1V40_DESCRIPTION_1 = 'QUICK_START_GUIDE_P1V40_DESCRIPTION_1';
export const QUICK_START_GUIDE_P1V40_TITLE_2 = 'QUICK_START_GUIDE_P1V40_TITLE_2';
export const QUICK_START_GUIDE_P1V40_DESCRIPTION_2 = 'QUICK_START_GUIDE_P1V40_DESCRIPTION_2';
export const QUICK_START_GUIDE_P1V40_TITLE_3 = 'QUICK_START_GUIDE_P1V40_TITLE_3';
export const QUICK_START_GUIDE_P1V40_DESCRIPTION_3 = 'QUICK_START_GUIDE_P1V40_DESCRIPTION_3';
export const QUICK_START_GUIDE_P1V40_TITLE_4 = 'QUICK_START_GUIDE_P1V40_TITLE_4';
export const QUICK_START_GUIDE_P1V40_DESCRIPTION_4 = 'QUICK_START_GUIDE_P1V40_DESCRIPTION_4';
export const QUICK_START_GUIDE_P1V40_TITLE_5 = 'QUICK_START_GUIDE_P1V40_TITLE_5';
export const QUICK_START_GUIDE_P1V40_DESCRIPTION_5 = 'QUICK_START_GUIDE_P1V40_DESCRIPTION_5';
export const QUICK_START_GUIDE_P1V40_TITLE_6 = 'QUICK_START_GUIDE_P1V40_TITLE_6';
export const QUICK_START_GUIDE_P1V40_DESCRIPTION_6 = 'QUICK_START_GUIDE_P1V40_DESCRIPTION_6';
export const QUICK_START_GUIDE_P1V40_TITLE_7 = 'QUICK_START_GUIDE_P1V40_TITLE_7';
export const QUICK_START_GUIDE_P1V40_DESCRIPTION_7 = 'QUICK_START_GUIDE_P1V40_DESCRIPTION_7';
export const TIPS_P1V40_ADVANCED_TITLE_1 = 'TIPS_P1V40_ADVANCED_TITLE_1';
export const TIPS_P1V40_ADVANCED_DESCRIPTION_1 = 'TIPS_P1V40_ADVANCED_DESCRIPTION_1';
export const TIPS_P1V40_ADVANCED_TITLE_2 = 'TIPS_P1V40_ADVANCED_TITLE_2';
export const TIPS_P1V40_ADVANCED_DESCRIPTION_2 = 'TIPS_P1V40_ADVANCED_DESCRIPTION_2';
export const TIPS_P1V40_ADVANCED_TITLE_3 = 'TIPS_P1V40_ADVANCED_TITLE_3';
export const TIPS_P1V40_ADVANCED_DESCRIPTION_3 = 'TIPS_P1V40_ADVANCED_DESCRIPTION_3';
export const TIPS_P1V40_ADVANCED_TITLE_4 = 'TIPS_P1V40_ADVANCED_TITLE_4';
export const TIPS_P1V40_ADVANCED_DESCRIPTION_4 = 'TIPS_P1V40_ADVANCED_DESCRIPTION_4';
export const TIPS_P1V40_ADVANCED_TITLE_5 = 'TIPS_P1V40_ADVANCED_TITLE_5';
export const TIPS_P1V40_ADVANCED_DESCRIPTION_5 = 'TIPS_P1V40_ADVANCED_DESCRIPTION_5';
export const TIPS_P1V40_ADVANCED_TITLE_6 = 'TIPS_P1V40_ADVANCED_TITLE_6';
export const TIPS_P1V40_ADVANCED_DESCRIPTION_6 = 'TIPS_P1V40_ADVANCED_DESCRIPTION_6';
export const USB_RECONNECT_POPUP_TITLE_TEXT = 'USB_RECONNECT_POPUP_TITLE_TEXT';
export const USB_RECONNECT_POPUP_DESCRIPTION_TEXT = 'USB_RECONNECT_POPUP_DESCRIPTION_TEXT';
export const USB_POPUP_BTN_RECONNECT = 'USB_POPUP_BTN_RECONNECT';
export const USB_FWU_START_RECONNECT_POPUP_DESCRIPTION_TEXT = 'USB_FWU_START_RECONNECT_POPUP_DESCRIPTION_TEXT';
export const USB_FWU_FINISHED_RECONNECT_POPUP_DESCRIPTION_TEXT = 'USB_FWU_FINISHED_RECONNECT_POPUP_DESCRIPTION_TEXT';
export const USB_FWU_FINISHED_RECONNECT_POPUP_DESCRIPTION_TEXT_ILUMA =
    'USB_FWU_FINISHED_RECONNECT_POPUP_DESCRIPTION_TEXT_ILUMA';
export const DEVICE_SETTINGS_USB_RECONNECT = 'DEVICE_SETTINGS_USB_RECONNECT';
export const QUICK_START_GUIDE_P1V40M_TITLE_1 = 'QUICK_START_GUIDE_P1V40M_TITLE_1';
export const QUICK_START_GUIDE_P1V40M_DESCRIPTION_1 = 'QUICK_START_GUIDE_P1V40M_DESCRIPTION_1';
export const QUICK_START_GUIDE_P1V40M_TITLE_2 = 'QUICK_START_GUIDE_P1V40M_TITLE_2';
export const QUICK_START_GUIDE_P1V40M_DESCRIPTION_2 = 'QUICK_START_GUIDE_P1V40M_DESCRIPTION_2';
export const QUICK_START_GUIDE_P1V40M_TITLE_3 = 'QUICK_START_GUIDE_P1V40M_TITLE_3';
export const QUICK_START_GUIDE_P1V40M_DESCRIPTION_3 = 'QUICK_START_GUIDE_P1V40M_DESCRIPTION_3';
export const QUICK_START_GUIDE_P1V40M_TITLE_4 = 'QUICK_START_GUIDE_P1V40M_TITLE_4';
export const QUICK_START_GUIDE_P1V40M_DESCRIPTION_4 = 'QUICK_START_GUIDE_P1V40M_DESCRIPTION_4';
export const QUICK_START_GUIDE_P1V40M_TITLE_5 = 'QUICK_START_GUIDE_P1V40M_TITLE_5';
export const QUICK_START_GUIDE_P1V40M_DESCRIPTION_5 = 'QUICK_START_GUIDE_P1V40M_DESCRIPTION_5';
export const QUICK_START_GUIDE_P1V40M_TITLE_6 = 'QUICK_START_GUIDE_P1V40M_TITLE_6';
export const QUICK_START_GUIDE_P1V40M_DESCRIPTION_6 = 'QUICK_START_GUIDE_P1V40M_DESCRIPTION_6';
export const QUICK_START_GUIDE_P1V40M_TITLE_7 = 'QUICK_START_GUIDE_P1V40M_TITLE_7';
export const QUICK_START_GUIDE_P1V40M_DESCRIPTION_7 = 'QUICK_START_GUIDE_P1V40M_DESCRIPTION_7';
export const P1V40M_FIRST_ITEM_TITLE = 'P1V40M_FIRST_ITEM_TITLE';
export const TIPS_QUICK_START_GUIDE_P1V40M_TITLE_1 = 'TIPS_QUICK_START_GUIDE_P1V40M_TITLE_1';
export const TIPS_QUICK_START_GUIDE_P1V40M_DESCRIPTION_1 = 'TIPS_QUICK_START_GUIDE_P1V40M_DESCRIPTION_1';
export const TIPS_QUICK_START_GUIDE_P1V40M_TITLE_2 = 'TIPS_QUICK_START_GUIDE_P1V40M_TITLE_2';
export const TIPS_QUICK_START_GUIDE_P1V40M_DESCRIPTION_2 = 'TIPS_QUICK_START_GUIDE_P1V40M_DESCRIPTION_2';
export const TIPS_QUICK_START_GUIDE_P1V40M_TITLE_3 = 'TIPS_QUICK_START_GUIDE_P1V40M_TITLE_3';
export const TIPS_QUICK_START_GUIDE_P1V40M_DESCRIPTION_3 = 'TIPS_QUICK_START_GUIDE_P1V40M_DESCRIPTION_3';
export const TIPS_QUICK_START_GUIDE_P1V40M_TITLE_4 = 'TIPS_QUICK_START_GUIDE_P1V40M_TITLE_4';
export const TIPS_QUICK_START_GUIDE_P1V40M_DESCRIPTION_4 = 'TIPS_QUICK_START_GUIDE_P1V40M_DESCRIPTION_4';
export const TIPS_QUICK_START_GUIDE_P1V40M_TITLE_5 = 'TIPS_QUICK_START_GUIDE_P1V40M_TITLE_5';
export const TIPS_QUICK_START_GUIDE_P1V40M_DESCRIPTION_5 = 'TIPS_QUICK_START_GUIDE_P1V40M_DESCRIPTION_5';
export const TIPS_QUICK_START_GUIDE_P1V40M_TITLE_6 = 'TIPS_QUICK_START_GUIDE_P1V40M_TITLE_6';
export const TIPS_QUICK_START_GUIDE_P1V40M_DESCRIPTION_6 = 'TIPS_QUICK_START_GUIDE_P1V40M_DESCRIPTION_6';
export const TIPS_QUICK_START_GUIDE_P1V40M_TITLE_7 = 'TIPS_QUICK_START_GUIDE_P1V40M_TITLE_7';
export const TIPS_QUICK_START_GUIDE_P1V40M_DESCRIPTION_7 = 'TIPS_QUICK_START_GUIDE_P1V40M_DESCRIPTION_7';
export const TIPS_QUICK_START_GUIDE_P1V40M_TITLE_8 = 'TIPS_QUICK_START_GUIDE_P1V40M_TITLE_8';
export const TIPS_QUICK_START_GUIDE_P1V40M_DESCRIPTION_8 = 'TIPS_QUICK_START_GUIDE_P1V40M_DESCRIPTION_8';
export const P1V40M_SECOND_ITEM_TITLE = 'P1V40M_SECOND_ITEM_TITLE';
export const TIPS_P1V40M_PREMIUM_FEATURES_TITLE_1 = 'TIPS_P1V40M_PREMIUM_FEATURES_TITLE_1';
export const TIPS_P1V40M_PREMIUM_FEATURES_DESCRIPTION_1 = 'TIPS_P1V40M_PREMIUM_FEATURES_DESCRIPTION_1';
export const TIPS_P1V40M_PREMIUM_FEATURES_TITLE_2 = 'TIPS_P1V40M_PREMIUM_FEATURES_TITLE_2';
export const TIPS_P1V40M_PREMIUM_FEATURES_DESCRIPTION_2 = 'TIPS_P1V40M_PREMIUM_FEATURES_DESCRIPTION_2';
export const TIPS_P1V40M_PREMIUM_FEATURES_TITLE_3 = 'TIPS_P1V40M_PREMIUM_FEATURES_TITLE_3';
export const TIPS_P1V40M_PREMIUM_FEATURES_DESCRIPTION_3 = 'TIPS_P1V40M_PREMIUM_FEATURES_DESCRIPTION_3';
export const TIPS_P1V40M_PREMIUM_FEATURES_TITLE_4 = 'TIPS_P1V40M_PREMIUM_FEATURES_TITLE_4';
export const TIPS_P1V40M_PREMIUM_FEATURES_DESCRIPTION_4 = 'TIPS_P1V40M_PREMIUM_FEATURES_DESCRIPTION_4';
export const TIPS_P1V40M_PREMIUM_FEATURES_TITLE_5 = 'TIPS_P1V40M_PREMIUM_FEATURES_TITLE_5';
export const TIPS_P1V40M_PREMIUM_FEATURES_DESCRIPTION_5 = 'TIPS_P1V40M_PREMIUM_FEATURES_DESCRIPTION_5';
export const TIPS_P1V40M_PREMIUM_FEATURES_TITLE_6 = 'TIPS_P1V40M_PREMIUM_FEATURES_TITLE_6';
export const TIPS_P1V40M_PREMIUM_FEATURES_DESCRIPTION_6 = 'TIPS_P1V40M_PREMIUM_FEATURES_DESCRIPTION_6';
export const P1V40M_THIRD_ITEM_TITLE = 'P1V40M_THIRD_ITEM_TITLE';
export const TIPS_P1V40M_BATTERY_TITLE_1 = 'TIPS_P1V40M_BATTERY_TITLE_1';
export const TIPS_P1V40M_BATTERY_DESCRIPTION_1 = 'TIPS_P1V40M_BATTERY_DESCRIPTION_1';
export const TIPS_P1V40M_BATTERY_TITLE_2 = 'TIPS_P1V40M_BATTERY_TITLE_2';
export const TIPS_P1V40M_BATTERY_DESCRIPTION_2 = 'TIPS_P1V40M_BATTERY_DESCRIPTION_2';
export const TIPS_P1V40M_BATTERY_TITLE_3 = 'TIPS_P1V40M_BATTERY_TITLE_3';
export const TIPS_P1V40M_BATTERY_DESCRIPTION_3 = 'TIPS_P1V40M_BATTERY_DESCRIPTION_3';
export const TIPS_P1V40M_BATTERY_TITLE_4 = 'TIPS_P1V40M_BATTERY_TITLE_4';
export const TIPS_P1V40M_BATTERY_DESCRIPTION_4 = 'TIPS_P1V40M_BATTERY_DESCRIPTION_4';
export const TIPS_P1V40M_BATTERY_TITLE_5 = 'TIPS_P1V40M_BATTERY_TITLE_5';
export const TIPS_P1V40M_BATTERY_DESCRIPTION_5 = 'TIPS_P1V40M_BATTERY_DESCRIPTION_5';
export const TIPS_P1V40M_BATTERY_TITLE_6 = 'TIPS_P1V40M_BATTERY_TITLE_6';
export const TIPS_P1V40M_BATTERY_DESCRIPTION_6 = 'TIPS_P1V40M_BATTERY_DESCRIPTION_6';
export const P1V40M_FOURTH_ITEM_TITLE = 'P1V40M_FOURTH_ITEM_TITLE';
export const TIPS_P1V40M_DOS_TITLE_1 = 'TIPS_P1V40M_DOS_TITLE_1';
export const TIPS_P1V40M_DOS_DESCRIPTION_1 = 'TIPS_P1V40M_DOS_DESCRIPTION_1';
export const TIPS_P1V40M_DOS_TITLE_2 = 'TIPS_P1V40M_DOS_TITLE_2';
export const TIPS_P1V40M_DOS_DESCRIPTION_2 = 'TIPS_P1V40M_DOS_DESCRIPTION_2';
export const TIPS_P1V40M_DOS_TITLE_3 = 'TIPS_P1V40M_DOS_TITLE_3';
export const TIPS_P1V40M_DOS_DESCRIPTION_3 = 'TIPS_P1V40M_DOS_DESCRIPTION_3';
export const TIPS_P1V40M_DOS_TITLE_4 = 'TIPS_P1V40M_DOS_TITLE_4';
export const TIPS_P1V40M_DOS_DESCRIPTION_4 = 'TIPS_P1V40M_DOS_DESCRIPTION_4';
export const TIPS_P1V40M_DOS_TITLE_5 = 'TIPS_P1V40M_DOS_TITLE_5';
export const TIPS_P1V40M_DOS_DESCRIPTION_5 = 'TIPS_P1V40M_DOS_DESCRIPTION_5';
export const TIPS_P1V40M_DOS_TITLE_6 = 'TIPS_P1V40M_DOS_TITLE_6';
export const TIPS_P1V40M_DOS_DESCRIPTION_6 = 'TIPS_P1V40M_DOS_DESCRIPTION_6';
export const TIPS_P1V40M_DOS_TITLE_7 = 'TIPS_P1V40M_DOS_TITLE_7';
export const TIPS_P1V40M_DOS_DESCRIPTION_7 = 'TIPS_P1V40M_DOS_DESCRIPTION_7';
export const TIPS_P1V40M_TITLE = 'TIPS_P1V40M_TITLE';
export const TIPS_P1V40M_DESCRIPTION = 'TIPS_P1V40M_DESCRIPTION';
export const DEVICE_SETTINGS_UPDATE_HOLDER_NOTIFICATION_TEXT = 'DEVICE_SETTINGS_UPDATE_HOLDER_NOTIFICATION_TEXT';
export const DEVICE_SETTINGS_BTN_AUTOSTART_MODE_ARIA_LABEL = 'DEVICE_SETTINGS_BTN_AUTOSTART_MODE_ARIA_LABEL';
export const DEVICE_SETTINGS_BTN_GESTURE_MODE_ARIA_LABEL = 'DEVICE_SETTINGS_BTN_GESTURE_MODE_ARIA_LABEL';
export const DEVICE_SETTINGS_AUTO_START = 'DEVICE_SETTINGS_AUTO_START';
export const DEVICE_SETTINGS_SMART_GESTURES = 'DEVICE_SETTINGS_SMART_GESTURES';
export const DEVICE_CLEANING_MODE_LOAD_TEXT = 'DEVICE_CLEANING_MODE_LOAD_TEXT';
export const CUSTOM_BENEFIT_PAGE_TITLE_1 = 'CUSTOM_BENEFIT_PAGE_TITLE_1';
export const CUSTOM_BENEFIT_PAGE_DESCRIPTION_1 = 'CUSTOM_BENEFIT_PAGE_DESCRIPTION_1';
export const CUSTOM_BENEFIT_PAGE_TITLE_2 = 'CUSTOM_BENEFIT_PAGE_TITLE_2';
export const CUSTOM_BENEFIT_PAGE_DESCRIPTION_2 = 'CUSTOM_BENEFIT_PAGE_DESCRIPTION_2';
export const CUSTOM_BENEFIT_PAGE_TITLE_3 = 'CUSTOM_BENEFIT_PAGE_TITLE_3';
export const CUSTOM_BENEFIT_PAGE_DESCRIPTION_3 = 'CUSTOM_BENEFIT_PAGE_DESCRIPTION_3';
export const CUSTOM_BENEFIT_PAGE_TITLE_4 = 'CUSTOM_BENEFIT_PAGE_TITLE_4';
export const CUSTOM_BENEFIT_PAGE_DESCRIPTION_4 = 'CUSTOM_BENEFIT_PAGE_DESCRIPTION_4';
export const CUSTOM_BENEFIT_PAGE_TITLE_5 = 'CUSTOM_BENEFIT_PAGE_TITLE_5';
export const CUSTOM_BENEFIT_PAGE_DESCRIPTION_5 = 'CUSTOM_BENEFIT_PAGE_DESCRIPTION_5';
export const PAIR_DEVICE_CORTEZ_PAIRING_IN_PROCESS_DESCRIPTION = 'PAIR_DEVICE_CORTEZ_PAIRING_IN_PROCESS_DESCRIPTION';
export const PAIR_DEVICE_SOYUZ_PAIRING_IN_PROCESS_DESCRIPTION = 'PAIR_DEVICE_SOYUZ_PAIRING_IN_PROCESS_DESCRIPTION';
export const PAIR_DEVICE_VESPUCCI_PAIRING_IN_PROCESS_DESCRIPTION =
    'PAIR_DEVICE_VESPUCCI_PAIRING_IN_PROCESS_DESCRIPTION';
export const PAIR_DEVICE_ORDINARY_PAIRING_IN_PROCESS_DESCRIPTION =
    'PAIR_DEVICE_ORDINARY_PAIRING_IN_PROCESS_DESCRIPTION';
export const PAIR_DEVICE_P1V40_PAIRING_IN_PROCESS_DESCRIPTION = 'PAIR_DEVICE_P1V40_PAIRING_IN_PROCESS_DESCRIPTION';
export const PAIR_DEVICE_P1V40M_PAIRING_IN_PROCESS_DESCRIPTION = 'PAIR_DEVICE_P1V40M_PAIRING_IN_PROCESS_DESCRIPTION';
export const DEVICE_LOST_CONNECTION_NOTIFICATION_PART_1 = 'DEVICE_LOST_CONNECTION_NOTIFICATION_PART_1';
export const DEVICE_LOST_CONNECTION_NOTIFICATION_PART_2 = 'DEVICE_LOST_CONNECTION_NOTIFICATION_PART_2';
export const DEVICE_RECONNECT_NOTIFICATION = 'DEVICE_RECONNECT_NOTIFICATION';
export const RECONNECT_DEVICE_GUIDE_ORDINARY_TITLE = 'RECONNECT_DEVICE_GUIDE_ORDINARY_TITLE';
export const RECONNECT_DEVICE_GUIDE_ORDINARY_STEP1 = 'RECONNECT_DEVICE_GUIDE_ORDINARY_STEP1';
export const RECONNECT_DEVICE_GUIDE_ORDINARY_STEP2 = 'RECONNECT_DEVICE_GUIDE_ORDINARY_STEP2';
export const RECONNECT_DEVICE_GUIDE_ORDINARY_STEP3 = 'RECONNECT_DEVICE_GUIDE_ORDINARY_STEP3';
export const RECONNECT_DEVICE_GUIDE_ORDINARY_STEP4 = 'RECONNECT_DEVICE_GUIDE_ORDINARY_STEP4';
export const RECONNECT_DEVICE_GUIDE_DUO_TITLE = 'RECONNECT_DEVICE_GUIDE_DUO_TITLE';
export const RECONNECT_DEVICE_GUIDE_DUO_STEP1 = 'RECONNECT_DEVICE_GUIDE_DUO_STEP1';
export const RECONNECT_DEVICE_GUIDE_DUO_STEP2 = 'RECONNECT_DEVICE_GUIDE_DUO_STEP2';
export const RECONNECT_DEVICE_GUIDE_DUO_STEP3 = 'RECONNECT_DEVICE_GUIDE_DUO_STEP3';
export const RECONNECT_DEVICE_GUIDE_DUO_STEP4 = 'RECONNECT_DEVICE_GUIDE_DUO_STEP4';
export const RECONNECT_DEVICE_GUIDE_MULTI_TITLE = 'RECONNECT_DEVICE_GUIDE_MULTI_TITLE';
export const RECONNECT_DEVICE_GUIDE_MULTI_STEP1 = 'RECONNECT_DEVICE_GUIDE_MULTI_STEP1';
export const RECONNECT_DEVICE_GUIDE_MULTI_STEP2 = 'RECONNECT_DEVICE_GUIDE_MULTI_STEP2';
export const RECONNECT_DEVICE_GUIDE_MULTI_STEP3 = 'RECONNECT_DEVICE_GUIDE_MULTI_STEP3';
export const RECONNECT_DEVICE_GUIDE_MULTI_STEP4 = 'RECONNECT_DEVICE_GUIDE_MULTI_STEP4';
export const RECONNECT_DEVICE_GUIDE_CORTEZ_TITLE = 'RECONNECT_DEVICE_GUIDE_CORTEZ_TITLE';
export const RECONNECT_DEVICE_GUIDE_CORTEZ_STEP1 = 'RECONNECT_DEVICE_GUIDE_CORTEZ_STEP1';
export const RECONNECT_DEVICE_GUIDE_CORTEZ_STEP2 = 'RECONNECT_DEVICE_GUIDE_CORTEZ_STEP2';
export const RECONNECT_DEVICE_GUIDE_CORTEZ_STEP3 = 'RECONNECT_DEVICE_GUIDE_CORTEZ_STEP3';
export const RECONNECT_DEVICE_GUIDE_CORTEZ_STEP4 = 'RECONNECT_DEVICE_GUIDE_CORTEZ_STEP4';
export const RECONNECT_DEVICE_GUIDE_VEEV_TITLE = 'RECONNECT_DEVICE_GUIDE_VEEV_TITLE';
export const RECONNECT_DEVICE_GUIDE_VEEV_STEP1 = 'RECONNECT_DEVICE_GUIDE_VEEV_STEP1';
export const RECONNECT_DEVICE_GUIDE_VEEV_STEP2 = 'RECONNECT_DEVICE_GUIDE_VEEV_STEP2';
export const RECONNECT_DEVICE_GUIDE_VEEV_STEP3 = 'RECONNECT_DEVICE_GUIDE_VEEV_STEP3';
export const RECONNECT_DEVICE_GUIDE_VEEV_STEP4 = 'RECONNECT_DEVICE_GUIDE_VEEV_STEP4';
export const RECONNECT_DEVICE_GUIDE_P1V40_TITLE = 'RECONNECT_DEVICE_GUIDE_P1V40_TITLE';
export const RECONNECT_DEVICE_GUIDE_P1V40_STEP1 = 'RECONNECT_DEVICE_GUIDE_P1V40_STEP1';
export const RECONNECT_DEVICE_GUIDE_P1V40_STEP2 = 'RECONNECT_DEVICE_GUIDE_P1V40_STEP2';
export const RECONNECT_DEVICE_GUIDE_P1V40_STEP3 = 'RECONNECT_DEVICE_GUIDE_P1V40_STEP3';
export const RECONNECT_DEVICE_GUIDE_P1V40_STEP4 = 'RECONNECT_DEVICE_GUIDE_P1V40_STEP4';
export const RECONNECT_DEVICE_GUIDE_P1V40M_TITLE = 'RECONNECT_DEVICE_GUIDE_P1V40M_TITLE';
export const RECONNECT_DEVICE_GUIDE_P1V40M_STEP1 = 'RECONNECT_DEVICE_GUIDE_P1V40M_STEP1';
export const RECONNECT_DEVICE_GUIDE_P1V40M_STEP2 = 'RECONNECT_DEVICE_GUIDE_P1V40M_STEP2';
export const RECONNECT_DEVICE_GUIDE_P1V40M_STEP3 = 'RECONNECT_DEVICE_GUIDE_P1V40M_STEP3';
export const RECONNECT_DEVICE_GUIDE_P1V40M_STEP4 = 'RECONNECT_DEVICE_GUIDE_P1V40M_STEP4';
export const RECONNECT_DEVICE_GUIDE_ORDINARY_FAIL_TITLE = 'RECONNECT_DEVICE_GUIDE_ORDINARY_FAIL_TITLE';
export const RECONNECT_DEVICE_GUIDE_ORDINARY_FAIL_STEP1 = 'RECONNECT_DEVICE_GUIDE_ORDINARY_FAIL_STEP1';
export const RECONNECT_DEVICE_GUIDE_ORDINARY_FAIL_STEP2 = 'RECONNECT_DEVICE_GUIDE_ORDINARY_FAIL_STEP2';
export const RECONNECT_DEVICE_GUIDE_ORDINARY_FAIL_STEP3 = 'RECONNECT_DEVICE_GUIDE_ORDINARY_FAIL_STEP3';
export const RECONNECT_DEVICE_GUIDE_DUO_FAIL_TITLE = 'RECONNECT_DEVICE_GUIDE_DUO_FAIL_TITLE';
export const RECONNECT_DEVICE_GUIDE_DUO_FAIL_STEP1 = 'RECONNECT_DEVICE_GUIDE_DUO_FAIL_STEP1';
export const RECONNECT_DEVICE_GUIDE_DUO_FAIL_STEP2 = 'RECONNECT_DEVICE_GUIDE_DUO_FAIL_STEP2';
export const RECONNECT_DEVICE_GUIDE_DUO_FAIL_STEP3 = 'RECONNECT_DEVICE_GUIDE_DUO_FAIL_STEP3';
export const RECONNECT_DEVICE_GUIDE_MULTI_FAIL_TITLE = 'RECONNECT_DEVICE_GUIDE_MULTI_FAIL_TITLE';
export const RECONNECT_DEVICE_GUIDE_MULTI_FAIL_STEP1 = 'RECONNECT_DEVICE_GUIDE_MULTI_FAIL_STEP1';
export const RECONNECT_DEVICE_GUIDE_MULTI_FAIL_STEP2 = 'RECONNECT_DEVICE_GUIDE_MULTI_FAIL_STEP2';
export const RECONNECT_DEVICE_GUIDE_MULTI_FAIL_STEP3 = 'RECONNECT_DEVICE_GUIDE_MULTI_FAIL_STEP3';
export const RECONNECT_DEVICE_GUIDE_CORTEZ_FAIL_TITLE = 'RECONNECT_DEVICE_GUIDE_CORTEZ_FAIL_TITLE';
export const RECONNECT_DEVICE_GUIDE_CORTEZ_FAIL_STEP1 = 'RECONNECT_DEVICE_GUIDE_CORTEZ_FAIL_STEP1';
export const RECONNECT_DEVICE_GUIDE_CORTEZ_FAIL_STEP2 = 'RECONNECT_DEVICE_GUIDE_CORTEZ_FAIL_STEP2';
export const RECONNECT_DEVICE_GUIDE_CORTEZ_FAIL_STEP3 = 'RECONNECT_DEVICE_GUIDE_CORTEZ_FAIL_STEP3';
export const RECONNECT_DEVICE_GUIDE_VEEV_FAIL_TITLE = 'RECONNECT_DEVICE_GUIDE_VEEV_FAIL_TITLE';
export const RECONNECT_DEVICE_GUIDE_VEEV_FAIL_STEP1 = 'RECONNECT_DEVICE_GUIDE_VEEV_FAIL_STEP1';
export const RECONNECT_DEVICE_GUIDE_VEEV_FAIL_STEP2 = 'RECONNECT_DEVICE_GUIDE_VEEV_FAIL_STEP2';
export const RECONNECT_DEVICE_GUIDE_VEEV_FAIL_STEP3 = 'RECONNECT_DEVICE_GUIDE_VEEV_FAIL_STEP3';
export const RECONNECT_DEVICE_GUIDE_P1V40_FAIL_TITLE = 'RECONNECT_DEVICE_GUIDE_P1V40_FAIL_TITLE';
export const RECONNECT_DEVICE_GUIDE_P1V40_FAIL_STEP1 = 'RECONNECT_DEVICE_GUIDE_P1V40_FAIL_STEP1';
export const RECONNECT_DEVICE_GUIDE_P1V40_FAIL_STEP2 = 'RECONNECT_DEVICE_GUIDE_P1V40_FAIL_STEP2';
export const RECONNECT_DEVICE_GUIDE_P1V40_FAIL_STEP3 = 'RECONNECT_DEVICE_GUIDE_P1V40_FAIL_STEP3';
export const RECONNECT_DEVICE_GUIDE_P1V40M_FAIL_TITLE = 'RECONNECT_DEVICE_GUIDE_P1V40M_FAIL_TITLE';
export const RECONNECT_DEVICE_GUIDE_P1V40M_FAIL_STEP1 = 'RECONNECT_DEVICE_GUIDE_P1V40M_FAIL_STEP1';
export const RECONNECT_DEVICE_GUIDE_P1V40M_FAIL_STEP2 = 'RECONNECT_DEVICE_GUIDE_P1V40M_FAIL_STEP2';
export const RECONNECT_DEVICE_GUIDE_P1V40M_FAIL_STEP3 = 'RECONNECT_DEVICE_GUIDE_P1V40M_FAIL_STEP3';
export const CLOSE_NOTIFICATION_ARIA_LABEL = 'CLOSE_NOTIFICATION_ARIA_LABEL';
export const CLEAR_INPUT_FIELD_ARIA_LABEL = 'CLEAR_INPUT_FIELD_ARIA_LABEL';
export const SETTINGS_SELECTED_ARIA_LABEL = 'SETTINGS_SELECTED_ARIA_LABEL';
export const SETTINGS_NOT_SELECTED_ARIA_LABEL = 'SETTINGS_NOT_SELECTED_ARIA_LABEL';
export const INPUT_RENAME_ARIA_LABEL = 'INPUT_RENAME_ARIA_LABEL';
export const CHARGER_IN_SBL_USB_UPDATE_POPUP_MESSAGE_TEXT = 'CHARGER_IN_SBL_USB_UPDATE_POPUP_MESSAGE_TEXT';
export const CHARGER_IN_SBL_USB_UPDATE_POPUP_TITLE_TEXT = 'CHARGER_IN_SBL_USB_UPDATE_POPUP_TITLE_TEXT';
export const CHARGER_IN_SBL_USB_UPDATE_POPUP_UPDATE_BUTTON_TEXT = 'CHARGER_IN_SBL_USB_UPDATE_POPUP_UPDATE_BUTTON_TEXT';
export const HOLDER_IN_SBL_BLE_UPDATE_POPUP_OK_BUTTON_TEXT = 'HOLDER_IN_SBL_BLE_UPDATE_POPUP_OK_BUTTON_TEXT';
export const HOLDER_IN_SBL_USB_UPDATE_POPUP_MESSAGE_TEXT = 'HOLDER_IN_SBL_USB_UPDATE_POPUP_MESSAGE_TEXT';
export const HOLDER_IN_SBL_USB_UPDATE_POPUP_TITLE_TEXT = 'HOLDER_IN_SBL_USB_UPDATE_POPUP_TITLE_TEXT';
export const HOLDER_IN_SBL_USB_UPDATE_POPUP_UPDATE_BUTTON_TEXT = 'HOLDER_IN_SBL_USB_UPDATE_POPUP_UPDATE_BUTTON_TEXT';
export const FIRMWARE_UPDATE_DISCONNECT_FAIL_DESCRIPTION = 'FIRMWARE_UPDATE_DISCONNECT_FAIL_DESCRIPTION';
export const DEVICE_STATUS_LOCKED = 'DEVICE_STATUS_LOCKED';
export const DEVICE_STATUS_UNLOCKED = 'DEVICE_STATUS_UNLOCKED';
export const P4V40_DEVICE_STATUS_LOCKED = 'P4V40_DEVICE_STATUS_LOCKED';
export const P4V40_DEVICE_STATUS_UNLOCKED = 'P4V40_DEVICE_STATUS_UNLOCKED';
export const SELECT_LED_TITLE = 'SELECT_LED_TITLE';
export const ONE_LED_TEXT = 'ONE_LED_TEXT';
export const TWO_LED_TEXT = 'TWO_LED_TEXT';
export const IQOS_DUO_STEP1_TITLE = 'IQOS_DUO_STEP1_TITLE';
export const IQOS_DUO_STEP1_DESCRIPTION = 'IQOS_DUO_STEP1_DESCRIPTION';
export const IQOS_DUO_STEP1_BUTTON_TEXT = 'IQOS_DUO_STEP1_BUTTON_TEXT';
export const IQOS_DUO_STEP2_TITLE = 'IQOS_DUO_STEP2_TITLE';
export const IQOS_DUO_STEP3_TITLE = 'IQOS_DUO_STEP3_TITLE';
export const IQOS_DUO_STEP4_TITLE = 'IQOS_DUO_STEP4_TITLE';
export const IQOS_DUO_STEP5_TITLE = 'IQOS_DUO_STEP5_TITLE';
export const IQOS_DUO_STEP6_TITLE = 'IQOS_DUO_STEP6_TITLE';
export const IQOS_DUO_STEP6_DESCRIPTION = 'IQOS_DUO_STEP6_DESCRIPTION';
export const IQOS_DUO_STEP6_BUTTON_TEXT = 'IQOS_DUO_STEP6_BUTTON_TEXT';
export const IQOS_DUO_FIRMWARE_UPDATE_SUCCESS = 'IQOS_DUO_FIRMWARE_UPDATE_SUCCESS';
export const IQOS_DUO_CONTACT_US_POPUP_TITLE = 'IQOS_DUO_CONTACT_US_POPUP_TITLE';
export const IQOS_DUO_CONTACT_US_POPUP_DESCRIPTION = 'IQOS_DUO_CONTACT_US_POPUP_DESCRIPTION';
export const IQOS_DUO_CONTACT_US_POPUP_CONTACT_US_BUTTON_TEXT = 'IQOS_DUO_CONTACT_US_POPUP_CONTACT_US_BUTTON_TEXT';
export const IQOS_DUO_CONTACT_US_POPUP_NOT_NOW_BUTTON_TEXT = 'IQOS_DUO_CONTACT_US_POPUP_NOT_NOW_BUTTON_TEXT';
export const FWU_ADDITIONAL_STEPS_GUIDE_BTN_BACK_TO_ABOUT_DEVICE_ARIA_LABEL =
    'FWU_ADDITIONAL_STEPS_GUIDE_BTN_BACK_TO_ABOUT_DEVICE_ARIA_LABEL';
export const RESPONSIVE_DRAW_VIVACE_DESCRIPTION = 'RESPONSIVE_DRAW_VIVACE_DESCRIPTION';
export const RESPONSIVE_DRAW_BUBBLY_DESCRIPTION = 'RESPONSIVE_DRAW_BUBBLY_DESCRIPTION';
export const RESPONSIVE_DRAW_CRESCENDO_DESCRIPTION = 'RESPONSIVE_DRAW_CRESCENDO_DESCRIPTION';
export const RESPONSIVE_DRAW_STACATO_DESCRIPTION = 'RESPONSIVE_DRAW_STACATO_DESCRIPTION';
export const RESPONSIVE_DRAW_SIREN_DESCRIPTION = 'RESPONSIVE_DRAW_SIREN_DESCRIPTION';
export const RESPONSIVE_DRAW_NONE_DESCRIPTION = 'RESPONSIVE_DRAW_NONE_DESCRIPTION';
export const DEVICE_SETTINGS_BTN_COUNTDOWN_MODE_ARIA_LABEL = 'DEVICE_SETTINGS_BTN_COUNTDOWN_MODE_ARIA_LABEL';
export const EXPERIENCE_COUNTDOWN_MODE_TITLE = 'EXPERIENCE_COUNTDOWN_MODE_TITLE';
export const AUTOSTART_FEATURE_BTN_CLOSE_ARIA_LABEL = 'AUTOSTART_FEATURE_BTN_CLOSE_ARIA_LABEL';
export const GESTURES_FEATURE_BTN_CLOSE_ARIA_LABEL = 'GESTURES_FEATURE_BTN_CLOSE_ARIA_LABEL';
export const COUNTDOWN_FEATURE_BTN_CLOSE_ARIA_LABEL = 'COUNTDOWN_FEATURE_BTN_CLOSE_ARIA_LABEL';
export const DEVICE_SETTINGS_EXPERIENCE_COUNTDOWN = 'DEVICE_SETTINGS_EXPERIENCE_COUNTDOWN';
export const EXPERIENCE_COUNTDOWN_MODE_DESCRIPTION = 'EXPERIENCE_COUNTDOWN_MODE_DESCRIPTION';
export const EXPERIENCE_COUNTDOWN_MODE_BUTTON_ACTION_TEXT_ON = 'EXPERIENCE_COUNTDOWN_MODE_BUTTON_ACTION_TEXT_ON';
export const EXPERIENCE_COUNTDOWN_MODE_BUTTON_ACTION_TEXT_OFF = 'EXPERIENCE_COUNTDOWN_MODE_BUTTON_ACTION_TEXT_OFF';
export const EXPERIENCE_COUNTDOWN_MODE_BUTTON_TEXT = 'EXPERIENCE_COUNTDOWN_MODE_BUTTON_TEXT';
export const FWU_INSERT_HOLDER_NOTIFICATION_TEXT = 'FWU_INSERT_HOLDER_NOTIFICATION_TEXT';
export const FWU_HOLDER_BATTERY_LOW_NOTIFICATION_TEXT = 'FWU_HOLDER_BATTERY_LOW_NOTIFICATION_TEXT';
export const DEVICE_SETTINGS_GRID_ITEM_TRY_FEATURE_TEXT = 'DEVICE_SETTINGS_GRID_ITEM_TRY_FEATURE_TEXT';
export const DEVICE_SETTINGS_GRID_ITEM_GET_FEATURE_TEXT = 'DEVICE_SETTINGS_GRID_ITEM_GET_FEATURE_TEXT';
export const DEVICE_SETTINGS_TRY_FEATURE_NOTIFICATION_TEXT = 'DEVICE_SETTINGS_TRY_FEATURE_NOTIFICATION_TEXT';
export const DEVICE_SETTINGS_ONE_TRY_FEATURE_NOTIFICATION_TEXT = 'DEVICE_SETTINGS_ONE_TRY_FEATURE_NOTIFICATION_TEXT';
export const QUIT_SET_UP_TRACK_USAGE_DESCRIPTION_TEXT = 'QUIT_SET_UP_TRACK_USAGE_DESCRIPTION_TEXT';
export const QUIT_SET_UP_PERSONAL_GOALS_DESCRIPTION_TEXT = 'QUIT_SET_UP_PERSONAL_GOALS_DESCRIPTION_TEXT';
export const GET_FEATURE_POPUP_BUTTON_TEXT = 'GET_FEATURE_POPUP_BUTTON_TEXT';
export const NO_INTERNET_CONNECTION_SCENARIO_2_NOTIFICATION_TEXT =
    'NO_INTERNET_CONNECTION_SCENARIO_2_NOTIFICATION_TEXT';
export const DEVICE_RENAME_NOTIFICATION_FAILED_TEXT = 'DEVICE_RENAME_NOTIFICATION_FAILED_TEXT';
export const QUIT_SET_UP_USB_DESCRIPTION_TEXT = 'QUIT_SET_UP_USB_DESCRIPTION_TEXT';
export const USB_PAIRING_FAIL_MESSAGE = 'USB_PAIRING_FAIL_MESSAGE';
export const SETTINGS_SELECTED_WITH_DESCRIPTION_ARIA_LABEL = 'SETTINGS_SELECTED_WITH_DESCRIPTION_ARIA_LABEL';
export const SETTINGS_NOT_SELECTED_WITH_DESCRIPTION_ARIA_LABEL = 'SETTINGS_NOT_SELECTED_WITH_DESCRIPTION_ARIA_LABEL';
export const PAIR_DEVICE_P1V40D_TURN_OFF_TITLE_1 = 'PAIR_DEVICE_P1V40D_TURN_OFF_TITLE_1';
export const PAIR_DEVICE_P1V40D_TURN_OFF_DESCRIPTION_1 = 'PAIR_DEVICE_P1V40D_TURN_OFF_DESCRIPTION_1';
export const PAIR_DEVICE_P1V40D_ENABLE_PAIRING_TITLE = 'PAIR_DEVICE_P1V40D_ENABLE_PAIRING_TITLE';
export const PAIR_DEVICE_P1V40D_ENABLE_PAIRING_DESCRIPTION = 'PAIR_DEVICE_P1V40D_ENABLE_PAIRING_DESCRIPTION';
export const PAIR_DEVICE_P1V40D_PAIRING_IN_PROCESS_TITLE = 'PAIR_DEVICE_P1V40D_PAIRING_IN_PROCESS_TITLE';
export const PAIR_DEVICE_P1V40D_SUCCESSFULLY_PAIRED_TITLE = 'PAIR_DEVICE_P1V40D_SUCCESSFULLY_PAIRED_TITLE';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_1_1 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_1_1';
export const IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_1_1 = 'IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_1_1';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_1_2 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_1_2';
export const IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_1_2 = 'IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_1_2';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_1_3 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_1_3';
export const IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_1_3 = 'IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_1_3';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_1_4 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_1_4';
export const IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_1_4 = 'IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_1_4';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_1_5 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_1_5';
export const IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_1_5 = 'IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_1_5';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_1_6 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_1_6';
export const IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_1_6 = 'IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_1_6';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_1_7 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_1_7';
export const IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_1_7 = 'IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_1_7';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_1_9 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_1_9';
export const IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_1_9 = 'IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_1_9';
export const TIPS_P1V40D_TITLE = 'TIPS_P1V40D_TITLE';
export const TIPS_P1V40D_DESCRIPTION = 'TIPS_P1V40D_DESCRIPTION';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_1 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_1';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_2 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_2';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_3 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_3';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_4 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_4';
export const RECONNECT_DEVICE_GUIDE_P1V40D_TITLE = 'RECONNECT_DEVICE_GUIDE_P1V40D_TITLE';
export const RECONNECT_DEVICE_GUIDE_P1V40D_STEP1 = 'RECONNECT_DEVICE_GUIDE_P1V40D_STEP1';
export const RECONNECT_DEVICE_GUIDE_P1V40D_STEP2 = 'RECONNECT_DEVICE_GUIDE_P1V40D_STEP2';
export const RECONNECT_DEVICE_GUIDE_P1V40D_STEP3 = 'RECONNECT_DEVICE_GUIDE_P1V40D_STEP3';
export const RECONNECT_DEVICE_GUIDE_P1V40D_STEP4 = 'RECONNECT_DEVICE_GUIDE_P1V40D_STEP4';
export const RECONNECT_DEVICE_GUIDE_P1V40D_FAIL_TITLE = 'RECONNECT_DEVICE_GUIDE_P1V40D_FAIL_TITLE';
export const RECONNECT_DEVICE_GUIDE_P1V40D_FAIL_STEP1 = 'RECONNECT_DEVICE_GUIDE_P1V40D_FAIL_STEP1';
export const RECONNECT_DEVICE_GUIDE_P1V40D_FAIL_STEP2 = 'RECONNECT_DEVICE_GUIDE_P1V40D_FAIL_STEP2';
export const RECONNECT_DEVICE_GUIDE_P1V40D_FAIL_STEP3 = 'RECONNECT_DEVICE_GUIDE_P1V40D_FAIL_STEP3';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_1_8 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_1_8';
export const IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_1_8 = 'IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_1_8';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_1_10 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_1_10';
export const IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_1_10 = 'IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_1_10';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_2_1 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_2_1';
export const IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_2_1 = 'IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_2_1';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_2_2 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_2_2';
export const IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_2_2 = 'IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_2_2';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_2_3 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_2_3';
export const IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_2_3 = 'IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_2_3';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_3_1 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_3_1';
export const IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_3_1 = 'IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_3_1';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_3_2 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_3_2';
export const IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_3_2 = 'IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_3_2';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_4_1 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_4_1';
export const IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_4_1 = 'IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_4_1';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_4_2 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_4_2';
export const IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_4_2 = 'IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_4_2';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_4_3 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_4_3';
export const IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_4_3 = 'IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_4_3';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_4_4 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_4_4';
export const IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_4_4 = 'IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_4_4';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_4_5 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_4_5';
export const IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_4_5 = 'IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_4_5';
export const IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_4_6 = 'IQOS_4_DELTA_TUTORIAL_TITLE_TEXT_4_6';
export const IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_4_6 = 'IQOS_4_DELTA_DESCRIPTION_TITLE_TEXT_4_6';
export const MY_DEVICES_PAGE_TEXT_BATTERY_LEVEL_NO_INFO_ARIA_LABEL =
    'MY_DEVICES_PAGE_TEXT_BATTERY_LEVEL_NO_INFO_ARIA_LABEL';
export const DEVICE_SETTINGS_RESTART_DEVICE = 'DEVICE_SETTINGS_RESTART_DEVICE';
export const RESTART_DEVICE_NOTIFICATION_BTN_CLOSE_ARIA_LABEL = 'RESTART_DEVICE_NOTIFICATION_BTN_CLOSE_ARIA_LABEL';
export const RESTART_DEVICE_NOTIFICATION_IMG_WARNING_ALT = 'RESTART_DEVICE_NOTIFICATION_IMG_WARNING_ALT';
export const RESTART_DEVICE_CONFIRM_POPUP_TITLE_TEXT = 'RESTART_DEVICE_CONFIRM_POPUP_TITLE_TEXT';
export const RESTART_DEVICE_CONFIRM_POPUP_DESCRIPTION_TEXT = 'RESTART_DEVICE_CONFIRM_POPUP_DESCRIPTION_TEXT';
export const RESTART_DEVICE_CONFIRM_POPUP_RESTART_DEVICE_TEXT = 'RESTART_DEVICE_CONFIRM_POPUP_RESTART_DEVICE_TEXT';
export const RESTART_DEVICE_CONFIRM_POPUP_CANCEL_TEXT = 'RESTART_DEVICE_CONFIRM_POPUP_CANCEL_TEXT';
export const UNREGISTERED_HOLDER_INSERTED_POPUP_DESCRIPTION = 'UNREGISTERED_HOLDER_INSERTED_POPUP_DESCRIPTION';
export const HOLDER_REGISTRATION_TITLE = 'HOLDER_REGISTRATION_TITLE';
export const UNREGISTERED_HOLDER_INSERTED_POPUP_REGISTER_BUTTON = 'UNREGISTERED_HOLDER_INSERTED_POPUP_REGISTER_BUTTON';
export const CONNECTION_UNREGISTERED_HOLDER_INSERTED_POPUP_REGISTER_BUTTON =
    'CONNECTION_UNREGISTERED_HOLDER_INSERTED_POPUP_REGISTER_BUTTON';
export const UNREGISTERED_HOLDER_INSERTED_POPUP_CANCEL_BUTTON = 'UNREGISTERED_HOLDER_INSERTED_POPUP_CANCEL_BUTTON';
export const DEVICE_CONNECTION_TO_IOT_DEVICE_REGISTRATION_TITLE = 'DEVICE_CONNECTION_TO_IOT_DEVICE_REGISTRATION_TITLE';
export const DEVICE_CONNECTION_TO_IOT_HOLDER_REGISTRATION_TITLE = 'DEVICE_CONNECTION_TO_IOT_HOLDER_REGISTRATION_TITLE';
export const DEVICE_CONNECTION_TO_IOT_DEVICE_SYNCHRONIZATION_TITLE =
    'DEVICE_CONNECTION_TO_IOT_DEVICE_SYNCHRONIZATION_TITLE';
export const DEVICE_CONNECTION_TO_IOT_DEVICE_SYNCHRONIZATION_FAILED_POPUP_TITLE =
    'DEVICE_CONNECTION_TO_IOT_DEVICE_SYNCHRONIZATION_FAILED_POPUP_TITLE';
export const DEVICE_CONNECTION_TO_IOT_DEVICE_SYNCHRONIZATION_FAILED_POPUP_MESSAGE =
    'DEVICE_CONNECTION_TO_IOT_DEVICE_SYNCHRONIZATION_FAILED_POPUP_MESSAGE';
export const DEVICE_CONNECTION_TO_IOT_DEVICE_SYNCHRONIZATION_FAILED_POPUP_TRY_AGAIN_BUTTON =
    'DEVICE_CONNECTION_TO_IOT_DEVICE_SYNCHRONIZATION_FAILED_POPUP_TRY_AGAIN_BUTTON';
export const DEVICE_CONNECTION_TO_IOT_DEVICE_SYNCHRONIZATION_FAILED_POPUP_DO_IT_LATER_BUTTON =
    'DEVICE_CONNECTION_TO_IOT_DEVICE_SYNCHRONIZATION_FAILED_POPUP_DO_IT_LATER_BUTTON';
export const DEVICE_CONNECTION_TO_IOT_DEVICE_SYNCHRONIZATION_FAILED_POPUP_WRONG_DEVICE_USB_MESSAGE =
    'DEVICE_CONNECTION_TO_IOT_DEVICE_SYNCHRONIZATION_FAILED_POPUP_WRONG_DEVICE_USB_MESSAGE';
export const DEVICE_CONNECTION_TO_IOT_DEVICE_SYNCHRONIZATION_FAILED_POPUP_WRONG_DEVICE_BLE_MESSAGE =
    'DEVICE_CONNECTION_TO_IOT_DEVICE_SYNCHRONIZATION_FAILED_POPUP_WRONG_DEVICE_BLE_MESSAGE';
export const DEVICE_CONNECTION_TO_IOT_DEVICE_SYNCHRONIZATION_FAILED_POPUP_WRONG_DEVICE_GAM_MESSAGE =
    'DEVICE_CONNECTION_TO_IOT_DEVICE_SYNCHRONIZATION_FAILED_POPUP_WRONG_DEVICE_GAM_MESSAGE';
export const HOLDER_REGISTERED_NOTIFICATION_TEXT = 'HOLDER_REGISTERED_NOTIFICATION_TEXT';
export const NOTIFICATION_FIRMWARE_UPDATE_AVAILABLE_HEADLINE = 'NOTIFICATION_FIRMWARE_UPDATE_AVAILABLE_HEADLINE';
export const DEVICE_CONNECTION_TO_IOT_DESCRIPTION = 'DEVICE_CONNECTION_TO_IOT_DESCRIPTION';
export const NOTIFICATION_FIRMWARE_UPDATE_AVAILABLE_CLOSE_ICON_ARIA_LABEL =
    'NOTIFICATION_FIRMWARE_UPDATE_AVAILABLE_CLOSE_ICON_ARIA_LABEL';
export const FIRMWARE_UPDATE_LATEST_TITLE = 'FIRMWARE_UPDATE_LATEST_TITLE';
export const ABOUT_DEVICE_INSTALL_FIRMWARE_UPDATE_BUTTON_TEXT = 'ABOUT_DEVICE_INSTALL_FIRMWARE_UPDATE_BUTTON_TEXT';
export const ABOUT_DEVICE_INSTALL_FIRMWARE_UPDATE_DESCRIPTION_TEXT =
    'ABOUT_DEVICE_INSTALL_FIRMWARE_UPDATE_DESCRIPTION_TEXT';
export const ABOUT_DEVICE_ORDINARY_HOLDER_NAME = 'ABOUT_DEVICE_ORDINARY_HOLDER_NAME';
export const ABOUT_DEVICE_CORTEZ_HOLDER_NAME = 'ABOUT_DEVICE_CORTEZ_HOLDER_NAME';
export const ABOUT_DEVICE_SOYUZ_HOLDER_NAME = 'ABOUT_DEVICE_SOYUZ_HOLDER_NAME';
export const ABOUT_DEVICE_P1V40_HOLDER_NAME = 'ABOUT_DEVICE_P1V40_HOLDER_NAME';
export const ABOUT_DEVICE_P1V40M_HOLDER_NAME = 'ABOUT_DEVICE_P1V40M_HOLDER_NAME';
export const PURCHASE_DATE_ENTRY_REGISTRATION_TITLE = 'PURCHASE_DATE_ENTRY_REGISTRATION_TITLE';
export const PURCHASE_DATE_ENTRY_DESCRIPTION = 'PURCHASE_DATE_ENTRY_DESCRIPTION';
export const PURCHASE_DATE_ENTRY_INVALID_DATE_ERROR_TEXT = 'PURCHASE_DATE_ENTRY_INVALID_DATE_ERROR_TEXT';
export const PURCHASE_DATE_ENTRY_TEXTBOX_TITLE = 'PURCHASE_DATE_ENTRY_TEXTBOX_TITLE';
export const PURCHASE_DATE_ENTRY_CONTINUE_BUTTON = 'PURCHASE_DATE_ENTRY_CONTINUE_BUTTON';
export const PURCHASE_DATE_ENTRY_CANCEL_BUTTON = 'PURCHASE_DATE_ENTRY_CANCEL_BUTTON';
export const PURCHASE_DATE_LATER_POPUP_TITLE = 'PURCHASE_DATE_LATER_POPUP_TITLE';
export const PURCHASE_DATE_LATER_POPUP_DESCRIPTION = 'PURCHASE_DATE_LATER_POPUP_DESCRIPTION';
export const PURCHASE_DATE_LATER_POPUP_BTN = 'PURCHASE_DATE_LATER_POPUP_BTN';
export const P1v4_HAPTIC_MANAGEMENT_ENDED_TITLE_TEXT_KEY = 'P1v4_HAPTIC_MANAGEMENT_ENDED_TITLE_TEXT_KEY';
