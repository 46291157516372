import * as iotMessageErrorCodes from '../../consts/iot/iotMessageErrorCodes';
import * as iotMessageStatusTypes from '../../consts/iot/iotMessageStatusTypes';
import * as iotMessageTypes from '../../consts/iot/iotMessageTypes';
import arrayUtils from '../../utils/arrayUtils';
import helpers from '../../utils/helpers';
import backendService from '../app/backendService';
import mwMessageRequestService from '../iot/mwMessageRequestService';
import connectivityService from './connectivityService';
import IqosCommunicatorClient from './iqosCommunicatorClient';

let cyclicFramesData = {};
const CYCLIC_FRAME_TIMEOUT = 5000;
let resetFramesData;

const resetCyclicFrames = () => {
    cyclicFramesData = {};
};

const processFrames = (props) => {
    const {status, type} = props;
    const isUamBackend = backendService.isUamBackend();
    const isGamBackend = backendService.isGamBackend();
    const isCyclicFrame =
        !isUamBackend &&
        !isGamBackend &&
        connectivityService.isUsb() &&
        type === iotMessageTypes.DEVICE_STATUS_CHARACTERISTIC_USB &&
        status === iotMessageStatusTypes.STARTED;

    if (isCyclicFrame) {
        writeFrameCyclic(props);
    } else {
        writeFrameAndPublishResponse(props);
    }
};

const writeFrameCyclic = ({
    type,
    frames,
    request_id,
    processImmediately,
    transfer_data,
    cyclicTimeout = CYCLIC_FRAME_TIMEOUT,
    cyclicId,
}) => {
    const id = cyclicId ? cyclicId : type;
    const iqosCommunicatorClient = new IqosCommunicatorClient();
    const addFramesToQueue = () =>
        iqosCommunicatorClient.addFramesToQueue({
            frames,
            processImmediately,
            onSuccess: onSuccessResponse,
            onError: () => {
                resetCyclicFrames();
                mwMessageRequestService.publishErrorEvent({
                    type,
                    request_id,
                    errorCode: iotMessageErrorCodes.IO_ERROR_IO_GENERAL,
                    transfer_data,
                });
            },
        });

    if (cyclicFramesData[id]) {
        clearTimeout(cyclicFramesData[id].timeout);
    }

    cyclicFramesData[id] = {};

    const onSuccessResponse = (responses) => {
        if (!cyclicFramesData[id]) return;

        const isCurrentEqualToPreviousResponse = arrayUtils.equals(cyclicFramesData[id].currentResponse, responses);

        if (!isCurrentEqualToPreviousResponse) {
            cyclicFramesData[id].currentResponse = responses;
            mwMessageRequestService.publishResponseEvent({type, request_id, frames: responses, cyclicId});
        }

        cyclicFramesData[id].timeout = setTimeout(() => {
            if (cyclicFramesData[id]) {
                addFramesToQueue();
            }
        }, cyclicTimeout);
    };

    addFramesToQueue();
};

const writeFrameAndPublishResponse = ({
    frames,
    onSuccess,
    onError,
    timeout,
    processImmediately,
    skipErrorOnTimeout,
}) => {
    const iqosCommunicatorClient = new IqosCommunicatorClient();

    iqosCommunicatorClient.addFramesToQueue({
        frames,
        processImmediately,
        timeout,
        onSuccess: (responses) => {
            helpers.runFunction(onSuccess, responses);
        },
        onError,
        skipErrorOnTimeout,
    });
};

const setResetFramesData = (props) => (resetFramesData = props);

const clearResetFramesData = () => setResetFramesData(undefined);

const runResetFramesData = () => {
    if (resetFramesData) {
        resetCyclicFrames();
        processFrames(resetFramesData);
        clearResetFramesData();
    }
};

const isResetFramesDataExists = () => !!resetFramesData?.frames?.length;

const createNew = () => {
    new IqosCommunicatorClient(true);
    resetCyclicFrames();
};

const processQueue = () => {
    const iqosCommunicatorClient = new IqosCommunicatorClient();

    iqosCommunicatorClient.processQueue();
};

const disable = () => {
    const iqosCommunicatorClient = new IqosCommunicatorClient();

    iqosCommunicatorClient.disable();
    resetCyclicFrames();
};

const restart = () => {
    const iqosCommunicatorClient = new IqosCommunicatorClient();

    iqosCommunicatorClient.restartListeners();
};

export default {
    clearResetFramesData,
    createNew,
    disable,
    isResetFramesDataExists,
    processFrames,
    processQueue,
    restart,
    runResetFramesData,
    setResetFramesData,
    writeFrameAndPublishResponse,
    writeFrameCyclic,
};
