import React from 'react';

import Icon from './Icon';

const IconPod = (props) => {
    return (
        <Icon width='20' height='20' viewBox='0 0 20 20' {...props}>
            <g fill='none' fillRule='evenodd'>
                <path
                    stroke='#272A33'
                    strokeLinecap='round'
                    strokeWidth='1'
                    d='M6.947 13.53l.11 4.2c.006.31.26.559.57.559h2.45c.306 0 .557-.24.57-.545l.247-4.214'
                    transform='translate(-2175 -1464) translate(81 1318) translate(2060 146) translate(34) translate(1 1)'
                />
                <path
                    stroke='#272A33'
                    strokeWidth='1'
                    d='M7.102.842c-.227 1.414-.448 3.157-.664 5.229-.218 2.094-.356 3.722-.413 4.884-.01.188.135.349.324.358.066.003.132-.013.19-.046l.304-.18c.154-.09.329-.137.507-.137h3.186c.186 0 .369.052.527.15l.267.167c.16.099.37.05.47-.11.034-.055.051-.117.051-.181-.003-1.122-.116-2.739-.34-4.852-.22-2.079-.415-3.826-.586-5.244-.06-.502-.487-.88-.993-.88H8.09c-.491 0-.91.357-.987.842z'
                    transform='translate(-2175 -1464) translate(81 1318) translate(2060 146) translate(34) translate(1 1)'
                />
            </g>
        </Icon>
    );
};

export default IconPod;
