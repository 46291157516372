import React from 'react';

import Icon from './Icon';

const IconVeevPulseLogo = (props) => {
    return (
        <Icon width='38' height='40' viewBox='0 0 38 40' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g fill='#272A33'>
                    <path
                        d='M203.673 251l-7.788 17.817-7.788 17.819-7.845-17.819L172.408 251H169.6l8.672 19.64 8.672 19.64c.094.218.254.398.455.524.2.126.442.196.7.196.255 0 .496-.07.697-.195.2-.124.36-.303.453-.52l8.617-19.642L206.483 251h-2.81z'
                        transform='translate(-169 -251)'
                    />
                </g>
            </g>
        </Icon>
    );
};

export default IconVeevPulseLogo;
