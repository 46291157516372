import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse, Method} from 'axios';

import * as logMessageTypes from '../../consts/app/logMessageTypes';
import log from '../logger/log';
import httpStatusTypesService from './httpStatusTypesService';

const get: AxiosInstance['get'] = (url, requestConfig) => requestApi('get', url, requestConfig);
const post: AxiosInstance['post'] = (url, requestConfig = {}) => requestApi('post', url, requestConfig);
const put: AxiosInstance['put'] = (url, requestConfig = {}) => requestApi('put', url, requestConfig);
const requestDelete: AxiosInstance['delete'] = (url, requestConfig = {}) => requestApi('delete', url, requestConfig);

const requestApi = async <T = any, R = AxiosResponse<T>>(
    method: Method,
    url: string,
    requestConfig: AxiosRequestConfig = {}
): Promise<R> => {
    try {
        let config = {method, url};

        config = {...config, ...requestConfig};

        return await request(config);
    } catch (e: any) {
        const LOG_LENGTH_LIMIT = 10 * 1024;
        const requestConfigCopy = {...requestConfig};

        if (requestConfig?.headers) {
            const trimRequestConfigHeader = (headerName: string) => {
                const header = requestConfig.headers[headerName];

                if (header) {
                    const NUMBER_OF_VISIBLE_CHARS = 10;
                    const headerTrimmed = `${header.substring(0, NUMBER_OF_VISIBLE_CHARS)}*****${header.substring(
                        header.length - NUMBER_OF_VISIBLE_CHARS
                    )}`;

                    requestConfigCopy.headers[headerName] = headerTrimmed;
                }
            };

            trimRequestConfigHeader('Authorization');
            trimRequestConfigHeader('Token');
        }

        const logMessage = `requestApi: url: ${url}; requestConfig: ${JSON.stringify(
            requestConfigCopy
        )}, method: ${method}; error: ${e}; response data: ${JSON.stringify(e.response?.data)?.substring(
            0,
            LOG_LENGTH_LIMIT
        )}`;

        const statusCode = e?.response?.status;
        const isInfoLevel = !statusCode || httpStatusTypesService.isNotAuthorized(statusCode);

        if (isInfoLevel) {
            log.info(logMessage);
        } else {
            log.error(logMessage, logMessageTypes.EXTERNAL);
        }

        throw e;
    }
};

const request: AxiosInstance['request'] = (requestConfig) => axios.request(requestConfig);

export default {
    get,
    post,
    put,
    requestDelete,
};
