import React from 'react';

import styles from './PopupHeaderIcon.module.scss';

const PopupHeaderIcon = (props) => {
    const {icon: IconComponent, ariaLabel} = props;

    return (
        <div className={styles.HeaderIcon}>
            <IconComponent ariaLabel={ariaLabel} />
        </div>
    );
};

export default PopupHeaderIcon;
