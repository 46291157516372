import configAccessibilityGetters from './configGetters/configAccessibilityGetters';
import configAppGetters from './configGetters/configAppGetters';
import configAppLinksGetters from './configGetters/configAppLinksGetters';
import configAwsGetters from './configGetters/configAwsGetters';
import configBenefitPageGetters from './configGetters/configBenefitPageGetters';
import configCmGetters from './configGetters/configCmGetters';
import configConnectivityGetters from './configGetters/configConnectivityGetters';
import configDeviceFeaturesGetters from './configGetters/configDeviceFeaturesGetters';
import configDeviceSettingsPageGetters from './configGetters/configDeviceSettingsPageGetters';
import configDeviceSupportGetters from './configGetters/configDeviceSupportGetters';
import configExternalLinksGetters from './configGetters/configExternalLinksGetters';
import configFwGetters from './configGetters/configFwGetters';
import configGamGetters from './configGetters/configGamGetters';
import configHavGetters from './configGetters/configHavGetters';
import configIccConsumerServicesGetter from './configGetters/configIccConsumerServicesGetter';
import configIccGetters from './configGetters/configIccGetters';
import configLifestyleImagesGetter from './configGetters/configLifestyleImagesGetter';
import configLocalizationGetters from './configGetters/configLocalizationGetters';
import configLoginGetters from './configGetters/configLoginGetters';
import configLogsGetters from './configGetters/configLogsGetters';
import configMarketGetters from './configGetters/configMarketGetters';
import configPromoGetters from './configGetters/configPromoGetters';
import configPurchaseDateGetters from './configGetters/configPurchaseDateGetters';
import configScpGetters from './configGetters/configScpGetters';
import configSkipPageGetters from './configGetters/configSkipPageGetters';
import configStorageGetters from './configGetters/configStorageGetters';
import configUamGetters from './configGetters/configUamGetters';
import configUnmarkedGetters from './configGetters/configUnmarkedGetters';

export default {
    ...configAccessibilityGetters,
    ...configAppGetters,
    ...configAppLinksGetters,
    ...configAwsGetters,
    ...configBenefitPageGetters,
    ...configCmGetters,
    ...configConnectivityGetters,
    ...configDeviceFeaturesGetters,
    ...configDeviceSettingsPageGetters,
    ...configDeviceSupportGetters,
    ...configExternalLinksGetters,
    ...configFwGetters,
    ...configGamGetters,
    ...configHavGetters,
    ...configIccConsumerServicesGetter,
    ...configIccGetters,
    ...configLifestyleImagesGetter,
    ...configLocalizationGetters,
    ...configLoginGetters,
    ...configLogsGetters,
    ...configMarketGetters,
    ...configPromoGetters,
    ...configPurchaseDateGetters,
    ...configScpGetters,
    ...configSkipPageGetters,
    ...configStorageGetters,
    ...configUamGetters,
    ...configUnmarkedGetters,
};
