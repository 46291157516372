import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {Button, ButtonBorder} from '../../../components/Button';
import ConnectionTrackButton from '../../../components/Button/ConnectionTrackButton/ConnectionTrackButton';
import {IconDarkDataCollection} from '../../../components/Icons';
import Container from '../../../components/Layout/Container/Container';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import * as tncTypes from '../../../consts/tnc/tncTypes';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import analyticsService from '../../../services/analyticsService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import appRouterService from '../../../services/route/appRouterService';
import tncService from '../../../services/tncService';
import {hideLoader, showLoader} from '../../../state/ducks/global';
import {makeSelectAcceptedTncByType, makeSelectTncByType} from '../../../state/selectors/consumer';
import {dispatch} from '../../../state/store';
import elementsTesIds from '../../../test/consts/elementsTesIds';
import TermsOfUseDescription from '../TermsOfUseDescription/TermsOfUseDescription';
import styles from './DataCollectionPage.module.scss';

const mapStateToProps = createStructuredSelector({
    dataCollectionTnc: makeSelectTncByType(tncTypes.WEB_PREFERENCE_CENTER),
    acceptedTermsOfUse: makeSelectAcceptedTncByType(tncTypes.WEB_PREFERENCE_CENTER),
});

const DataCollectionPage = (props) => {
    const {dataCollectionTnc, acceptedTermsOfUse} = props;

    if (!dataCollectionTnc) return null;

    const {description, legals, updateDescription} = dataCollectionTnc;

    if (!legals) return null;

    const setTnc = async (isAccepted) => {
        dispatch(showLoader());

        analyticsService.pushTNCDataCollectionInteractionEvent(isAccepted);

        await tncService.setDataCollectionTnc(legals, isAccepted);

        dispatch(hideLoader());
    };

    const onAcceptButtonClick = () => setTnc(true);
    const onDeclineButtonClick = () => setTnc(false);

    const isUpdatedTnc = acceptedTermsOfUse != null;
    const summaryTitleText = isUpdatedTnc
        ? localizationKeys.DATA_COLLECTION_UPDATE_TITLE_TEXT
        : localizationKeys.DATA_COLLECTION_TITLE_TEXT;
    const descriptionText = isUpdatedTnc ? updateDescription : description;
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[localizationKeys.DATA_COLLECTION_PAGE_TITLE]);

    return (
        <div className={styles.Page} data-testid={elementsTesIds.DATA_COLLECTION_PAGE}>
            <Container className={styles.Container}>
                <div className={styles.Header}>
                    <IconDarkDataCollection className={styles.Image} />
                </div>
                <div>
                    <h1 className={styles.Title}>{localizedStrings[summaryTitleText]}</h1>
                    <TermsOfUseDescription
                        innerHtml={descriptionText}
                        className={styles.Description}
                        isDarkBackground
                    />
                </div>
                <div className={styles.Footer}>
                    <div className={styles.ButtonBlock}>
                        <ConnectionTrackButton
                            button={ButtonBorder}
                            className={styles.ButtonAgree}
                            onClick={onAcceptButtonClick}
                            testId={elementsTesIds.TNC_DC_AGREE}
                        >
                            {localizedStrings[localizationKeys.DATA_COLLECTION_CONFIRM_BUTTON_TEXT]}
                        </ConnectionTrackButton>
                        <ConnectionTrackButton
                            button={ButtonBorder}
                            className={styles.ButtonAgree}
                            onClick={onDeclineButtonClick}
                            testId={elementsTesIds.TNC_DC_DECLINE}
                        >
                            {localizedStrings[localizationKeys.DATA_COLLECTION_DECLINE_BUTTON_TEXT]}
                        </ConnectionTrackButton>
                    </div>
                    <Button
                        className={styles.PreferenceCenter}
                        onClick={appRouterService.forwardToTNCPreferenceCenterPage}
                        showUnderline
                        testId={elementsTesIds.TNC_DC_PREF_CENTER}
                    >
                        {localizedStrings[localizationKeys.DATA_COLLECTION_PREFERENCE_CENTER_BUTTON_TEXT]}
                    </Button>
                </div>
            </Container>
        </div>
    );
};

export default connect(mapStateToProps)(DataCollectionPage);
