export default {
    ERROR_CONNECTION_NOT_FOUND: {
        type: 'ERROR_CONNECTION_NOT_FOUND',
        code: 'pairing-error.device-not-found',
    },
    ERROR_PAIRING: {
        type: 'ERROR_PAIRING',
        code: 'pairing-error.pairing-error',
    },
    ERROR_NOT_SUPPORTED_DEVICE: {
        type: 'ERROR_NOT_SUPPORTED_DEVICE',
        code: 'pairing-error.error-not-supported-device',
    },
    ERROR_WRONG_DEVICE: {
        type: 'ERROR_WRONG_DEVICE',
        code: 'pairing-error.error-wrong-device',
    },
};
