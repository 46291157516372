import dateTimeUtils from '../../utils/dateTimeUtils';
import mwMessageRequestService from '../iot/mwMessageRequestService';
import userBrowserService from '../user/userBrowserService';
import fwuTargetService from './fwuTargetService';

// TODO: add for holder fwu
export default class BaseFwuClient {
    constructor({fwuPackageData, fwu_target}) {
        this.fwuPackageData = fwuPackageData;
        this.fwu_target = fwu_target;
    }

    startTs;

    setStartTs = () => {
        this.startTs = dateTimeUtils.getNowUtcTsUnix();
    };

    publishFwuError = ({errorCode, lastRequestFrames, lastResponseFrames, errorFrame}) => {
        mwMessageRequestService.onFwuErrorHandler({
            errorCode,
            errorFrame,
            fwu_target: this.fwu_target,
            lastRequestFrames,
            lastResponseFrames,
            onError: this.onFwuError,
            startTs: this.startTs,
            userAgent: userBrowserService.getUserAgent(),
        });
    };

    publishFwuFinish = () => {
        mwMessageRequestService.publishFwuFinishEvent({
            fwu_target: this.fwu_target,
            onSuccess: this.onFwuFinish,
            startTs: this.startTs,
        });
    };

    isFwuHolderRelatedTarget = () => fwuTargetService.isFwuTargetHolderRelated(this.fwu_target);
}
