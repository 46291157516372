export const MANAGE_DEVICE = '/gammd/v1/api/v1/identity/manage-device/{0}';
export const REFRESH_TOKEN_PATH = '/gammd/v1/api/v1/identity/manage-device';
export const CONNECT_DEVICE = '/gam/v1/api/v1/asset/{0}/device/connectivity/connect';
export const GET_ASSETS = '/gam/v1/api/v1/asset/device/getasset';
export const ACTIVATE_DEVICE = '/gam/v1/api/v1/asset/{0}/device/actions/activate';
export const DEACTIVATE_DEVICE = '/gam/v1/api/v1/asset/{0}/device/actions/deactivate';
export const UPDATE_SETTINGS = '/gam/v1/api/v1/asset/{0}/device/actions/updateSetting';
export const SYNCHRONIZE_DATA = '/gam/v1/api/v1/asset/{0}/device/actions/synchronize-data';
export const GET_DEVICE_INFO = '/gam/v1/api/v1/asset/{0}/device/device-Info';
export const GET_FIRMWARE_AVAILABILITY = '/gam/v1/api/v1/asset/{0}/device/firmware-availability';
export const UPDATE_FIRMWARE = '/gam/v1/api/v1/asset/{0}/device/actions/updateFirmware';
export const CHECK_CONNECTIVITY_STATUS = '/gam/v1/api/v1/asset/{0}/device/connectivity/status';
export const CHECK_REQUEST_STATUS = '/gam/v1/api/v1/asset/{0}/device/request-status/{1}';
