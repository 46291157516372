import cn from 'classnames';
import React, {FC, useRef} from 'react';

import oneLedImg2 from '../../../../assets/images/devices/FWUPopup/1led@2x.jpg';
import oneLedImg3 from '../../../../assets/images/devices/FWUPopup/1led@3x.jpg';
import twoLedImg2 from '../../../../assets/images/devices/FWUPopup/2led@2x.jpg';
import twoLedImg3 from '../../../../assets/images/devices/FWUPopup/2led@3x.jpg';
import Button from '../../../components/Button/Button';
import {IconTickSvg} from '../../../components/Icons';
import Popup from '../../../components/Popup/Popup';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import ModalTypes from '../../../enums/app/modalTypes';
import useHideModalIfNoDevice from '../../../hooks/useHideModalIfNoDevice';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {hideModalAction, showFWUAdditionalStepsGuidelAction, showFWUErrorModalAction} from '../../../state/ducks/modal';
import {dispatch} from '../../../state/store';
import SignedImage from '../../System/SignedImage';
import styles from '../FWULedCheckPopup/FWULedCheckPopup.module.scss';

const FWULedCheckPopup: FC<{modalType: ModalTypes}> = (props) => {
    const {modalType} = props;
    const localizedStrings = getLocalizedStrings();
    const popupRef: any = useRef();
    const oneLedSrcSet = [oneLedImg2, oneLedImg3];
    const twoLedSrcSet = [twoLedImg2, twoLedImg3];

    const onClose = (isCloseBtnClick?: boolean) => {
        if (isCloseBtnClick) {
            dispatch(hideModalAction(modalType));
        } else {
            const dispatchAction = () => dispatch(hideModalAction(modalType));

            popupRef.current.onPopupCloseWithAnimation(dispatchAction);
        }
    };

    useHideModalIfNoDevice(modalType);

    const onOneLedClick = () => {
        onClose();
        dispatch(showFWUErrorModalAction());
    };

    const onTwoLedClick = () => {
        onClose();
        dispatch(showFWUAdditionalStepsGuidelAction());
    };

    return (
        <Popup ref={popupRef} isCloseByOverlay={false} onClose={() => onClose(true)}>
            <h2 className={styles.Title}>{localizedStrings[localizationKeys.SELECT_LED_TITLE]}</h2>
            <div className={styles.ChoicesContainer}>
                <ChoiceItemTemplate
                    text={localizedStrings[localizationKeys.ONE_LED_TEXT]}
                    imgSrc={oneLedSrcSet}
                    onClickAction={onOneLedClick}
                />
                <ChoiceItemTemplate
                    text={localizedStrings[localizationKeys.TWO_LED_TEXT]}
                    imgSrc={twoLedSrcSet}
                    onClickAction={onTwoLedClick}
                />
            </div>
        </Popup>
    );
};

interface IChoiceItemTemplateType {
    text: string;
    imgSrc: string | Array<string>;
    onClickAction: () => void;
}

const ChoiceItemTemplate: FC<IChoiceItemTemplateType> = ({text, imgSrc, onClickAction}) => {
    return (
        <div className={styles.ChoiceItemContainer}>
            <Button ariaLabel={text} className={styles.ChoiceItem} onClick={onClickAction}>
                <SignedImage defaultImage={imgSrc} imgClassName={cn(styles.Image)} />
                <div className={styles.RoundConfirmMark}>
                    <IconTickSvg className={styles.SvgContainer} />
                </div>
            </Button>
            <p className='ica--txt-regular'>{text}</p>
        </div>
    );
};

export default FWULedCheckPopup;
