import appConfig from '../../config/appConfig';
import * as firmwareErrorCodes from '../../consts/iot/firmwareErrorCodes';
import * as localizationKeys from '../../consts/localization/localizationKeys';
import {ENABLE_FW_UPDATES_CHECK} from '../../consts/tnc/aggregatedSettingTypes';
import {setNotificationSuccess} from '../../state/ducks/global';
import {setFWUSuccess} from '../../state/ducks/iotDevice/actions';
import {selectMwDeviceFirmwareData} from '../../state/selectors/mwDevice';
import {dispatch, getState} from '../../state/store';
import converter from '../../utils/converter';
import connectivityService from '../device/connectivityService';
import deviceTypesService from '../device/deviceTypesService';
import fwuTargetService from '../device/fwuTargetService';
import IqosDeviceFwuClient from '../device/iqosDeviceFwuClient';
import IqosHolderFwuClient from '../device/iqosHolderFwuClient';
import FwuDownloadOrParseError from '../errors/firmware/fwuDownloadOrParseError';
import FwuDownloadUrlIsEmptyError from '../errors/firmware/fwuDownloadUrlIsEmptyError';
import mwMessageRequestService from '../iot/mwMessageRequestService';
import uiMessageRequestService from '../iot/uiMessageRequestService';
import {getLocalizedStrings} from '../localization/localizationService';
import log from '../logger/log';
import pairingService from '../pairingService';
import appRouterService from '../route/appRouterService';
import server from '../server/server';
import tncService from '../tncService';

let fwuPackage = undefined;

const clearPackageData = () => {
    fwuPackage = null;
};

const downloadFwuPackage = async (fwu_target, onSuccess, onFwuError) => {
    try {
        if (fwuPackage) return true;

        await downloadFirmware(fwu_target, onSuccess);

        return true;
    } catch (e) {
        log.error(`FWUService: FWU package downloading is failed, ${e}`);

        mwMessageRequestService.onFwuErrorHandler({errorCode: e.code, onError: onFwuError, fwu_target});

        return false;
    }
};

const downloadFirmware = async (fwu_target, onSuccess) => {
    const firmwareData = selectMwDeviceFirmwareData(getState());
    const firmwareTarget = fwuTargetService.getIotTargetByFwuTarget(firmwareData, fwu_target);
    const {download_url} = firmwareTarget || {};

    if (!download_url) throw new FwuDownloadUrlIsEmptyError();

    try {
        const res = await server.get(download_url);

        fwuPackage = res.data;

        log.debug(`FWUService: FWU package for ${fwu_target} has been downloaded successfully`);

        mwMessageRequestService.onPackageLoaded(onSuccess, fwu_target);
    } catch (e) {
        throw new FwuDownloadOrParseError(e);
    }
};

const startFwUpdate = async ({fwu_target, onFwuError, onFwuStatusEvent, onFwuFinish, package_index}) => {
    if (fwuPackage) {
        log.info(`FWUService: start FWU update process`);

        const FwuClient = fwuTargetService.isFwuTargetDevice(fwu_target) ? IqosDeviceFwuClient : IqosHolderFwuClient;
        const options = {
            fwu_target,
            fwuPackageData: fwuPackage,
            onFwuError,
            onFwuFinish,
            onFwuStatusEvent,
        };
        const fwuClient = new FwuClient(options);

        pairingService.stopDeviceSchedulers();
        fwuClient.startFwUpdate(package_index);
    } else {
        mwMessageRequestService.onFwuErrorHandler({
            errorCode: firmwareErrorCodes.START_NOT_FOUND_DATA,
            fwu_target,
        });

        log.info(`FWUService: startFwUpdate error, package is not loaded`);
    }
};

const getFwUpdateFinishAfterRebootEvent = () => fwuPackage?.reboot_after_fwu_message;

const convertHexIndexToInt = (hex) => {
    const hexIndexReversed = hex
        .match(/.{1,2}/g)
        .reverse()
        .join('');
    const index = converter.hex2dec(hexIndexReversed);

    return parseInt(index);
};

const isFWUAvailable = (type) => {
    const isUsb = connectivityService.isUsb();
    const isP1Device = deviceTypesService.isP1(type);
    const isP4Device = deviceTypesService.isP4(type);
    const isP1V4Device = deviceTypesService.isP1V4(type);
    let result;

    if (isP1Device) {
        result = isUsb ? appConfig.getIsUsbFWAvailableP1() : appConfig.getIsBleFWAvailableP1();
    } else if (isP4Device) {
        result = isUsb ? appConfig.getIsUsbFWAvailableP4() : appConfig.getIsBleFWAvailableP4();
    } else if (isP1V4Device) {
        result = isUsb ? appConfig.getIsUsbFWAvailableP1V4() : appConfig.getIsBleFWAvailableP1V4();
    }

    return result;
};

const initiateUpdateFirmwareMessage = (fwu_target, returnUrl) => {
    appRouterService.forwardToUpdateFWPage(fwu_target, returnUrl);
    uiMessageRequestService().publishInitiateUpdateFirmwareMessage(fwu_target);
};

const getIsBackgroundFwuCheckEnabled = () => {
    const isBackgroundFwuCheckEnabled =
        appConfig.getIsBackgroundFwuCheckEnabled() && tncService.isAggregatedSettingEnabled(ENABLE_FW_UPDATES_CHECK);

    return isBackgroundFwuCheckEnabled;
};

const onFwuSuccess = () => {
    log.info('End firmware process. Success notification is displayed.');
    dispatch(setNotificationSuccess(getLocalizedStrings()[localizationKeys.FIRMWARE_UPDATE_SUCCESS]));
    dispatch(setFWUSuccess());
};

export default {
    clearPackageData,
    convertHexIndexToInt,
    downloadFirmware,
    downloadFwuPackage,
    getFwUpdateFinishAfterRebootEvent,
    getIsBackgroundFwuCheckEnabled,
    initiateUpdateFirmwareMessage,
    isFWUAvailable,
    onFwuSuccess,
    startFwUpdate,
};
