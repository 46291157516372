import {createSelector} from 'reselect';

const selectMedia = (state) => state.media;

const makeSelectSignedMediaList = () =>
    createSelector(selectMedia, (state) => {
        return state.signedMediaList;
    });

const makeSelectSignedMediaListItem = (mediaIdList) =>
    createSelector(makeSelectSignedMediaList(), (signedMediaList) => {
        const signedDataSet = signedMediaList.filter((signedItem) =>
            mediaIdList.find((mediaItem) => signedItem.mediaId === mediaItem)
        );

        return signedDataSet.length > 0 ? signedDataSet : undefined;
    });

export {makeSelectSignedMediaList, makeSelectSignedMediaListItem};
