import stringFormatService from '../../services/stringFormatService';
import {config} from '../config';
import {TConfigGetterReturn} from './types';

const getBaseName = (): TConfigGetterReturn<number> => config.data.app.baseName;

export default {
    getAppVersion: (): TConfigGetterReturn<string> => config.data.app.appVersion,
    getBackendType: (): TConfigGetterReturn<number> => config.data.app.backendType,
    getBaseNameFormatted: (): TConfigGetterReturn<string> =>
        stringFormatService.getLanguageFormattedString(getBaseName()),
    getBrowserCheckPageLogoType: (): TConfigGetterReturn<string> => config.data.app.browserCheckPageLogoType,
    getDefaultVeevImage: (): TConfigGetterReturn<string> => config.data.app.defaultVeevImage,
    getDeviceListOrder: (): TConfigGetterReturn<string[]> => config.data.app.deviceListOrder,
    getEnv: (): TConfigGetterReturn<string> => config.data.app.env,
    getIsAccessProtected: (): TConfigGetterReturn<boolean> => config.data.app.isAccessProtected,
    getIsDeviceDiagnosticEnabled: (): TConfigGetterReturn<boolean> => config.data.app.isDeviceDiagnosticEnabled,
    getIsDeviceSettingAvailable: (): TConfigGetterReturn<boolean> => config.data.app.isDeviceSettingAvailable,
    getIsQualtricsTrackingEnabled: (): TConfigGetterReturn<boolean> => config.data.app.isQualtricsTrackingEnabled,
    getIsReconnectDeviceGuideEnabled: (): TConfigGetterReturn<boolean> => config.data.app.isReconnectDeviceGuideEnabled,
    getIsRememberMeEnabled: (): TConfigGetterReturn<boolean> => config.data.app.isRememberMeEnabled,
    getIsTestLoginEnabled: (): TConfigGetterReturn<boolean> => config.data.app.isTestLoginEnabled,
    getLegalDisclaimerPdfName: (): TConfigGetterReturn<string> => config.data.app.legalDisclaimerPdfName,
    getPublicUrl: (): TConfigGetterReturn<string> => config.data.app.publicUrl,
    getIsDataCollectionEnabled: (): TConfigGetterReturn<boolean> => config.data.app.isDataCollectionEnabled,
    getTipsAndTricksAllowedSteps: (): TConfigGetterReturn<[boolean, boolean, boolean]> =>
        config.data.app.tipsAndTricksAllowedSteps,
    getWelcomePageImagePath: (): TConfigGetterReturn<string> => config.data.app.welcomePageImagePath,
    getYapMode: (): TConfigGetterReturn<number> => config.data.app.yapMode,
};
