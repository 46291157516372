import {createSelector} from 'reselect';

import firmwareStatusTypes from '../../consts/device/firmwareStatusTypes';
import codentifyTransformService from '../../services/codentifyTransformService';
import deviceTypesService from '../../services/device/deviceTypesService';
import fwuTargetService from '../../services/device/fwuTargetService';
import iotDeviceConnectionStatusService from '../../services/iotDevice/iotDeviceConnectionStatusService';
import objUtils from '../../utils/objUtils';

const selectState = (state) => state.iotDevice;

const makeSelectIotDevice = () => createSelector(selectState, (state) => state);

const selectIsIotDeviceDataExists = createSelector(makeSelectIotDevice(), (iotDevice) => !!iotDevice.data);

const makeSelectDeviceConnectionStatus = () =>
    createSelector(makeSelectIotDevice(), (iotDevice) => iotDevice.deviceConnectionStatus);

const makeSelectDeviceActivationStatus = () =>
    createSelector(makeSelectIotDevice(), (iotDevice) => iotDevice.isDeviceActivated);

const makeSelectIsUpdateSettingsNeeded = () =>
    createSelector(makeSelectIotDevice(), (iotDevice) => iotDevice.isUpdateSettingsNeeded);

const makeSelectIsDeviceReconnected = () =>
    createSelector(makeSelectIotDevice(), (iotDevice) => iotDevice.isReconnected);

const selectIsDeviceDisconnected = createSelector(makeSelectDeviceConnectionStatus(), (status) =>
    iotDeviceConnectionStatusService.isDeviceDisconnected(status)
);

const makeSelectIsDeviceConnectedStatus = () =>
    createSelector(makeSelectDeviceConnectionStatus(), (status) =>
        iotDeviceConnectionStatusService.isDeviceConnectedStatus(status)
    );

const makeSelectIsDeviceConnected = () =>
    createSelector(makeSelectDeviceConnectionStatus(), (status) =>
        iotDeviceConnectionStatusService.isDeviceConnected(status)
    );

const makeSelectIsPairingInProgress = () =>
    createSelector(makeSelectIotDevice(), (iotDevice) => iotDevice.isPairingInProgress);

const makeSelectIsDeviceReady = () =>
    createSelector(makeSelectDeviceConnectionStatus(), (status) =>
        iotDeviceConnectionStatusService.isDeviceReady(status)
    );

const makeSelectIsWrongDevice = () =>
    createSelector(makeSelectDeviceConnectionStatus(), (status) =>
        iotDeviceConnectionStatusService.isWrongDevice(status)
    );

const makeSelectIotDeviceIsVibrationAlarmActive = () =>
    createSelector(makeSelectIotDevice(), (iotDevice) => iotDevice.isVibrationAlarmActive);

const makeSelectIotDeviceData = () =>
    createSelector(makeSelectIotDevice(), (iotDevice) => {
        if (!iotDevice?.data) return objUtils.returnEmptyObjectIfUndefined(iotDevice?.data);

        const {device} = iotDevice?.data;
        const {type, codentify, parent_protection_mode, isHolderConnected} = device || {};
        const isP4 = deviceTypesService.isP4(type);
        const isDelta = deviceTypesService.isDelta(type);
        const isP1V4 = deviceTypesService.isVoyager(type) || deviceTypesService.isCosmos(type) || isDelta;
        const parentProtectionMode = isP1V4 && !isHolderConnected && !isDelta ? false : parent_protection_mode;
        const preparedCodentify = codentifyTransformService.transform(isP4, codentify); //transform modify codentify only for P4 device and on DCS market
        const preparedDevice = {
            ...device,
            codentify: preparedCodentify,
            codentifyOriginal: codentify,
            parentProtectionMode,
            isP4,
            isP1V4,
        };
        const iotDeviceData = {...iotDevice?.data, device: preparedDevice};

        return iotDeviceData;
    });

const makeSelectIsReset = () => createSelector(makeSelectIotDevice(), (iotDevice) => iotDevice?.reset?.isReset);

const selectFirmwareState = createSelector(makeSelectIotDevice(), (iotDevice) =>
    objUtils.returnEmptyObjectIfUndefined(iotDevice?.firmware)
);

const selectIsFirmwareError = createSelector(selectFirmwareState, (state) =>
    firmwareStatusTypes.isError(state.firmwareStatus)
);

const selectIsFirmwareStarted = createSelector(selectFirmwareState, (state) =>
    firmwareStatusTypes.isStarted(state.firmwareStatus)
);

const selectIsFirmwareFinished = createSelector(selectFirmwareState, (state) =>
    firmwareStatusTypes.isFinished(state.firmwareStatus)
);

const selectIsFirmwareSuccess = createSelector(selectFirmwareState, (state) =>
    firmwareStatusTypes.isSuccess(state.firmwareStatus)
);

const selectIsFirmwareChecking = createSelector(selectFirmwareState, (state) =>
    firmwareStatusTypes.isChecking(state.firmwareStatus)
);

const selectIsFirmwareInProgress = createSelector(selectFirmwareState, (state) => state.firmwareStatus !== undefined);

const selectIsFirmwareTargetDevice = createSelector(selectFirmwareState, (state) =>
    fwuTargetService.isFwuTargetDevice(state.fwuTarget)
);

const selectIsIotDeviceP4 = createSelector(makeSelectIotDevice(), (iotDevice) =>
    deviceTypesService.isP4(iotDevice?.data?.device?.type)
);

const selectIsIotDeviceDelta = createSelector(makeSelectIotDevice(), (iotDevice) =>
    deviceTypesService.isDelta(iotDevice?.data?.device?.type)
);

const selectIotDeviceType = createSelector(makeSelectIotDevice(), (iotDevice) => iotDevice?.data?.device?.type);

const makeSelectGetFeatureTypesList = () => createSelector(selectState, (state) => state.getFeatureKeysList);

const makeSelectGetFeature = (type) =>
    createSelector(makeSelectGetFeatureTypesList(), (list) => (list ? list[type] : null));

const makeSelectIsGetFeatureInitialized = () =>
    createSelector(makeSelectGetFeatureTypesList(), (list) => list !== undefined);

const selectIsAvailableFwuMessageClosed = createSelector(selectState, (state) => state.isAvailableFwuMessageClosed);

export {
    makeSelectDeviceActivationStatus,
    makeSelectDeviceConnectionStatus,
    makeSelectGetFeature,
    makeSelectGetFeatureTypesList,
    makeSelectIotDeviceData,
    makeSelectIotDeviceIsVibrationAlarmActive,
    makeSelectIsDeviceConnected,
    makeSelectIsDeviceConnectedStatus,
    makeSelectIsDeviceReady,
    makeSelectIsDeviceReconnected,
    makeSelectIsGetFeatureInitialized,
    makeSelectIsPairingInProgress,
    makeSelectIsReset,
    makeSelectIsUpdateSettingsNeeded,
    makeSelectIsWrongDevice,
    selectIotDeviceType,
    selectIsAvailableFwuMessageClosed,
    selectIsDeviceDisconnected,
    selectIsFirmwareChecking,
    selectIsFirmwareError,
    selectIsFirmwareFinished,
    selectIsFirmwareInProgress,
    selectIsFirmwareStarted,
    selectIsFirmwareSuccess,
    selectIsFirmwareTargetDevice,
    selectIsIotDeviceDataExists,
    selectIsIotDeviceDelta,
    selectIsIotDeviceP4,
};
