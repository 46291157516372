import React from 'react';
import {useSelector} from 'react-redux';

import {Button, ButtonDark} from '../../../components/Button';
import PopupInfoStyled from '../../../components/Popup/PopupStyled/PopupInfoStyled';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import connectivityService from '../../../services/device/connectivityService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import marketTypeService from '../../../services/marketTypeService';
import modalService from '../../../services/modalService';
import pairingService from '../../../services/pairingService';
import appRouterService from '../../../services/route/appRouterService';
import {selectIsDeviceDisconnected} from '../../../state/selectors/iotDevice';
import elementsTesIds from '../../../test/consts/elementsTesIds';

const ConnectivitySelectPopup = (props) => {
    const {deviceType, modalType, isRetry} = props;
    const isDeviceDisconnected = useSelector(selectIsDeviceDisconnected);

    const localizedStrings = getLocalizedStrings();
    const titleKey = deviceType
        ? localizationKeys.SET_UP_POPUP_TITLE_TEXT
        : localizationKeys.SET_UP_POPUP_NEW_DEVICE_TITLE_TEXT;

    const disconnectPreviousConnection = () => {
        if (!isDeviceDisconnected) {
            pairingService.disconnectAndClearState();
        }
    };

    const onSelect = (isBle) => {
        const isDCE2 = marketTypeService.isDCE2();

        if (deviceType && !(isDCE2 && isBle)) {
            appRouterService.forwardToPairingGuidePage({deviceType});
        } else if (isRetry) {
            appRouterService.forwardToSelectDevice();
        } else {
            if (isBle) {
                appRouterService.forwardToPairingAlertPage();
            } else {
                appRouterService.forwardToSelectDevice();
            }
        }
    };

    const onUsbClick = () => {
        disconnectPreviousConnection();
        connectivityService.setIsUsb();
        onSelect();
    };

    const onBleClick = () => {
        disconnectPreviousConnection();
        connectivityService.setIsBle();
        onSelect(true);
    };

    const onClose = () => {
        isRetry ? appRouterService.forwardToMyDevicesPage() : modalService.hideModal(modalType);
    };

    return (
        <PopupInfoStyled
            title={localizedStrings[titleKey]}
            description={localizedStrings[localizationKeys.SET_UP_POPUP_DESCRIPTION_TEXT]}
            onClose={onClose}
        >
            <ButtonDark onClick={onUsbClick} showArrow testId={elementsTesIds.DEVICE_LIST_BTN_SET_UP_DEVICE_POPUP_USB}>
                {localizedStrings[localizationKeys.SET_UP_POPUP_CONTINUE_VIA_USB_BUTTON_TEXT]}
            </ButtonDark>
            <Button onClick={onBleClick} showUnderline testId={elementsTesIds.DEVICE_LIST_BTN_SET_UP_DEVICE_POPUP_BLE}>
                {localizedStrings[localizationKeys.SET_UP_POPUP_SET_UP_WITH_BLUETOOTH_BUTTON_TEXT]}
            </Button>
        </PopupInfoStyled>
    );
};

export default ConnectivitySelectPopup;
