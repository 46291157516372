import React, {useMemo} from 'react';

import appConfig from '../../../../../config/appConfig';
import {getIconTextRelatedData} from '../../iconTextDataService';
import IconTextItem from '../IconTextItem/IconTextItem';

const IconTextList: React.FC = () => {
    const isCustomSkipPage = !!appConfig.getSkipPageSections()?.length;
    const iconTextData = useMemo(() => getIconTextRelatedData(isCustomSkipPage), [isCustomSkipPage]);

    if (!iconTextData) {
        return null;
    }

    return (
        <>
            {iconTextData.map((item, index) => {
                const {svgIcon, text, iconPath} = item;

                return (
                    <IconTextItem
                        svgIcon={svgIcon}
                        text={text}
                        key={index}
                        isCustomSkipPage={isCustomSkipPage}
                        iconPath={iconPath}
                    />
                );
            })}
        </>
    );
};

export default IconTextList;
