import React, {FC} from 'react';

import {Button, ButtonDark} from '../../../components/Button';
import PopupErrorStyled from '../../../components/Popup/PopupStyled/PopupErrorStyled';
import appConfig from '../../../config/appConfig';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import ModalTypes from '../../../enums/app/modalTypes';
import useHideModalIfNoDevice from '../../../hooks/useHideModalIfNoDevice';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import routerService from '../../../services/routerService';
import {hideModalAction} from '../../../state/ducks/modal';
import {dispatch} from '../../../state/store';
import elementsTesIds from '../../../test/consts/elementsTesIds';

const FWUErrorPopup: FC<{modalType: ModalTypes}> = (props) => {
    const {modalType} = props;
    const localizedStrings = getLocalizedStrings();

    useHideModalIfNoDevice(modalType);

    const onClose = () => {
        dispatch(hideModalAction(modalType));
    };

    const onContactSupportClick = () => {
        const supportUrl = appConfig.getDcsSupportUrlFormatted();

        routerService.blankLinkOpen(supportUrl);
    };

    const ButtonDarkTex: string = localizedStrings[localizationKeys.IQOS_DUO_CONTACT_US_POPUP_CONTACT_US_BUTTON_TEXT];
    const BrightButton: string = localizedStrings[localizationKeys.IQOS_DUO_CONTACT_US_POPUP_NOT_NOW_BUTTON_TEXT];

    return (
        <PopupErrorStyled
            alt={localizedStrings[localizationKeys.IQOS_DUO_CONTACT_US_POPUP_TITLE]}
            title={localizedStrings[localizationKeys.IQOS_DUO_CONTACT_US_POPUP_TITLE]}
            onClose={onClose}
        >
            <p
                className='ica--txt-regular'
                dangerouslySetInnerHTML={{
                    __html: localizedStrings[localizationKeys.IQOS_DUO_CONTACT_US_POPUP_DESCRIPTION],
                }}
            />
            <ButtonDark
                ariaLabel={ButtonDarkTex}
                onClick={onContactSupportClick}
                showArrow
                testId={elementsTesIds.PAIRING_SEQUENCE_ERROR_TRY_AGAIN_BTN}
            >
                {ButtonDarkTex}
            </ButtonDark>
            <Button
                ariaLabel={BrightButton}
                onClick={onClose}
                showUnderline
                testId={elementsTesIds.PAIRING_SEQUENCE_ERROR_SKIP_BTN}
            >
                {BrightButton}
            </Button>
        </PopupErrorStyled>
    );
};

export default FWUErrorPopup;
