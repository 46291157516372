import * as tncTypes from '../consts/tnc/tncTypes';
import {hideLoader, showLoader} from '../state/ducks/global';
import {setTncPopupCode} from '../state/ducks/tnc';
import {
    makeSelectAcceptedTncByType,
    makeSelectAggregatedSettings,
    makeSelectTncByType,
} from '../state/selectors/consumer';
import {dispatch, getState} from '../state/store';
import helpers from '../utils/helpers';
import appErrorService from './app/appErrorService';
import iccMarketService from './icc/iccMarketService';
import appRouterService from './route/appRouterService';

const acceptTnc = (tncList) => iccMarketService.setTnc({data: tncList});
const declineTnc = (tncList) => iccMarketService.declineTnc({data: tncList});

const PATTERN_LINK = '@link';
const PATTERN_WEB_LINK = '@weblink';
const PATTERN_TNC_CODE = '@termsCode';
const PATTERN_CHANGE_LIST = '@changelist';
const PATTERN_TEXT = '@TEXT';
const ATTRIBUTE_DATA_TNC_CODE = 'data-tnc-code';

const getStringsBetweenPattern = (str, pattern) => str.match(new RegExp(pattern + '(.*?)' + pattern, 'ig'));
const getStringBetweenPattern = (str, pattern) => {
    const match = str.match(new RegExp(pattern + '(.*?)' + pattern, 'i'));

    if (match) {
        return {
            wholeText: match[0],
            capturedText: match[1],
        };
    }
    return {};
};

const getParsedDescription = (stringFull, className, legals) => {
    if (!stringFull) return '';

    const getWebLinkStr = (str, wholeWebLink, capturedCode, capturedText) => {
        const legalData = getTncLegalByTermsCode(legals, capturedCode);

        if (legalData) {
            const testId = `term${capturedCode}Hyperlink`;
            const linkToReplace = `<a class="${className}" target="_blank" href="${legalData.webLink}" data-testid="${testId}">${capturedText}</a>`;

            return str.replace(wholeWebLink, linkToReplace);
        }
    };

    const getLinkStr = (str, wholeLink, capturedCode, capturedText) => {
        const testId = `term${capturedCode}Hyperlink`;
        const linkToReplace = `<span class="${className}" ${ATTRIBUTE_DATA_TNC_CODE}="${capturedCode}" role="link" tabindex="0" data-testid="${testId}">${capturedText}</span>`;

        return str.replace(wholeLink, linkToReplace);
    };

    let stringParsed = getParsedDescriptionPart(stringFull, PATTERN_WEB_LINK, getWebLinkStr);

    if (stringParsed) {
        stringParsed = getParsedDescriptionPart(stringParsed, PATTERN_LINK, getLinkStr);
        stringParsed = getChangeListDescription(stringParsed, legals);
    }

    return stringParsed;
};

const getChangeListDescription = (str, legals) => {
    const {wholeText} = getStringBetweenPattern(str, PATTERN_CHANGE_LIST);

    if (!wholeText) return str;
    const changeText = legals.length
        ? legals
              .sort((a, b) => a.position < b.position)
              .filter((legal) => !legal.isInformational)
              .map(({changeList}) => changeList)
              .join('<br>')
        : '';

    return str.replace(wholeText, changeText);
};

const getParsedDescriptionPart = (str, pattern, afterParseFunction) => {
    const matches = getStringsBetweenPattern(str, pattern);

    if (matches?.length) {
        matches.forEach((match) => {
            const capturedWebLink = match.replace(pattern, '');
            const {capturedText: capturedCode} = getStringBetweenPattern(capturedWebLink, PATTERN_TNC_CODE);
            const {capturedText} = getStringBetweenPattern(capturedWebLink, PATTERN_TEXT);

            str = afterParseFunction(str, match, capturedCode, capturedText);
        });
    }
    return str;
};

const getTncCodeFromTarget = (target, className) => {
    const tncLinkElement = target.closest(`.${className}`);

    return tncLinkElement?.getAttribute(ATTRIBUTE_DATA_TNC_CODE);
};

const getTncLegalByTermsCode = (legals, termsCode) => {
    if (!legals?.length) return null;

    legals.sort((a, b) => a.position < b.position);

    return termsCode ? legals.find((legal) => legal.code === termsCode) : legals[0];
};

const tncDataMapper = ({code, versionNumber}) => ({code, versionNumber});

const setPreferenceCenterSettings = async (legals, legalsSettingsNotNull, acceptedSettingList, onApproved) => {
    try {
        const legalsSettingsNull = legals.filter((l) => l.settings == null && !l.isInformational).map(tncDataMapper);
        const legalsSettingsNotNullAccepted = legalsSettingsNotNull
            .filter((l) => acceptedSettingList.includes(l.code) && !l.isInformational)
            .map(tncDataMapper);
        const acceptedTncData = [...legalsSettingsNull, ...legalsSettingsNotNullAccepted];

        if (acceptedTncData.length) {
            await acceptTnc(acceptedTncData);
        }

        const declinedTncData = legalsSettingsNotNull
            .filter((l) => !acceptedSettingList.includes(l.code) && !l.isInformational)
            .map(tncDataMapper);

        if (declinedTncData.length) {
            await declineTnc(declinedTncData);
        }

        const settings = await iccMarketService.getConsumerAggregatedSettings();

        if (settings.allNonMandatoryTermsReviewed) {
            onApproved();
        }
    } catch (e) {
        appErrorService.showGlobalError();
    }
};

const setTnc = async (legals) => {
    const acceptedTncData = legals.filter((l) => !l.isInformational).map(tncDataMapper);

    await acceptTnc(acceptedTncData);

    const settings = await iccMarketService.getConsumerAggregatedSettings();

    if (settings.allMandatoryTermsApproved) {
        if (settings.allNonMandatoryTermsReviewed) {
            appRouterService.forwardToMyDevicesPage();
        } else {
            appRouterService.forwardToTNCDataCollectionPage();
        }
    }
};

const setDataCollectionTnc = async (legals, isAccepted) => {
    const tncData = legals.map(tncDataMapper);

    if (isAccepted) {
        await acceptTnc(tncData);
    } else {
        await declineTnc(tncData);
    }

    const settings = await iccMarketService.getConsumerAggregatedSettings();

    if (settings.allNonMandatoryTermsReviewed) {
        appRouterService.forwardToMyDevicesPage();
    }
};

const isAggregatedSettingEnabled = (type) => {
    const aggregatedSettings = makeSelectAggregatedSettings()(getState());

    if (!aggregatedSettings) return false;

    const {consumerAggregatedSettings} = aggregatedSettings;

    if (consumerAggregatedSettings?.length) {
        return consumerAggregatedSettings.some((item) => item.key.toLowerCase() === type.toLowerCase() && item.value);
    }

    return false;
};

const openLegalSettings = () => {
    const onFinally = () => {
        const termsOfUse = makeSelectTncByType(tncTypes.TERMS_OF_USE)(getState());
        const acceptedTermsOfUse = makeSelectAcceptedTncByType(tncTypes.TERMS_OF_USE)(getState());
        const legals = acceptedTermsOfUse?.legals?.length ? acceptedTermsOfUse.legals : termsOfUse?.legals;
        const legalData = getTncLegalByTermsCode(legals);

        if (legalData) {
            dispatch(setTncPopupCode(legalData.code));
        }
    };

    getTncData(onFinally);
};

const getTncData = (onFinally) => {
    dispatch(showLoader());

    Promise.all([iccMarketService.getAcceptedTnc(), iccMarketService.getTnc()]).finally(() => {
        dispatch(hideLoader());
        helpers.runFunction(onFinally);
    });
};

const getIntToken = () => {
    const {intToken} = makeSelectAggregatedSettings()(getState()) || {};

    return intToken;
};

export default {
    getIntToken,
    getParsedDescription,
    getTncCodeFromTarget,
    getTncData,
    getTncLegalByTermsCode,
    isAggregatedSettingEnabled,
    openLegalSettings,
    setDataCollectionTnc,
    setPreferenceCenterSettings,
    setTnc,
};
