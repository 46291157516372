import React from 'react';

import Icon from './Icon';

const StatusGestureNotActiveIcon = (props) => {
    return (
        <Icon width='23' height='31' viewBox='0 0 23 31' {...props}>
            <g fill='none' fillRule='evenodd' strokeLinejoin='round' strokeWidth='1.5'>
                <path
                    d='M18.734 13.116c-.383 0-.747.103-1.067.287v-.052c0-1.341-1.017-2.433-2.266-2.433-.386 0-.75.104-1.068.288v-.055c0-1.342-1.016-2.433-2.266-2.433-.385 0-.748.102-1.065.282V2.47c0-1.325-.988-2.433-2.202-2.469-.598-.018-1.168.212-1.606.648-.46.459-.724 1.11-.724 1.784v14.536l-1.78-2.463c-.972-1.344-2.684-1.729-4.073-.916-.577.338-.787 1.11-.468 1.722 1.072 2.056 3.739 7.063 5.366 9.23.22.328 2.752 3.958 7.14 3.958 2.43 0 4.474-.891 5.912-2.577 1.054-1.235 1.724-2.865 1.888-4.59l.542-5.725.003-.059c0-1.342-1.017-2.433-2.266-2.433h0z'
                    transform='translate(-407 -653) translate(388 634) translate(20 20)'
                />
            </g>
        </Icon>
    );
};

export default StatusGestureNotActiveIcon;
