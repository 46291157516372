import {createSelector} from 'reselect';

import {makeSelectConsumerProducts} from './consumer';

const selectState = (state) => state.yapEncrypted;

const makeSelectAsset = (deviceSerialNumber) =>
    createSelector(selectState, (state) => state.yapAssets?.find((asset) => asset.serialNumber === deviceSerialNumber));

const makeSelectAssetByAssetId = (assetId) =>
    createSelector(selectState, (state) => state.yapAssets?.find((asset) => asset.assetId === assetId));

const makeSelectFirstAsset = () =>
    createSelector(selectState, (state) => (state.yapAssets ? state.yapAssets[0] : null));

const makeSelectIsYapActivationInProgress = () =>
    createSelector(selectState, (state) => state.isYapActivationInProgress);

const makeSelectIsYapSynchronizeDataCollectionInProgress = () =>
    createSelector(selectState, (state) => state.isYapSynchronizeDataCollectionInProgress);

const makeSelectIsYapDataCollectionAllowed = () =>
    createSelector(selectState, (state) => state.isYapDataCollectionAllowed);

const makeSelectIsYapSynchronizeDeviceDataCollectionReceived = () =>
    createSelector(selectState, (state) => state.isYapSynchronizeDeviceDataCollectionReceived);

const makeSelectIsYapSynchronizeHolderDataCollectionReceived = () =>
    createSelector(selectState, (state) => state.isYapSynchronizeHolderDataCollectionReceived);

const makeSelectIsDeviceDataChangeReceived = () =>
    createSelector(selectState, (state) => state.isDeviceDataChangeReceived);

const makeSelectIsYapDeviceInfoReceived = () => createSelector(selectState, (state) => state.isYapDeviceInfoReceived);

const makeSelectIsYapHolderInfoReceived = () => createSelector(selectState, (state) => state.isYapHolderInfoReceived);

const makeSelectIsFindDeviceReceived = () => createSelector(selectState, (state) => state.isYapFindDeviceReceived);

const makeSelectIsYapUpdateSettingsInProgress = (isHolder = false) =>
    createSelector(selectState, (state) =>
        isHolder ? state.isYapHolderUpdateSettingsInProgress : state.isYapUpdateSettingsInProgress
    );

const makeSelectYapCancelDataCollectionInProgress = () =>
    createSelector(selectState, (state) => state.isCancelDataCollectionInProgress);

const makeSelectIsYapFirmwareInProgress = () => createSelector(selectState, (state) => state.isYapFirmwareInProgress);

const makeSelectIsDeviceSyncInProgress = (isHolder) =>
    createSelector(selectState, (state) => (isHolder ? state.isYapHolderSyncInProgress : state.isYapSyncInProgress));

const makeSelectIsYapDeviceConnected = () => createSelector(selectState, (state) => state.isYapDeviceConnected);

const makeSelectIsYapHolderConnected = () => createSelector(selectState, (state) => state.isYapHolderConnected);

const makeSelectYapLastRequestId = (isHolder = false) =>
    createSelector(selectState, (state) => (isHolder ? state.lastHolderRequestId : state.lastRequestId));

const makeSelectSyncLastRequestId = () => createSelector(selectState, (state) => state.syncLastRequestId);

const makeSelectYapLastCheckRequestDate = () => createSelector(selectState, (state) => state.lastCheckRequestDate || 0);

const makeSelectCmMessageData = () =>
    createSelector(selectState, (state) => {
        return {cmToken: state.cmToken, cmRequestId: state.cmRequestId};
    });

const makeSelectIsHolderByAssetId = (assetId) =>
    createSelector(makeSelectConsumerProducts(), makeSelectAssetByAssetId(assetId), (products, asset) => {
        const {serialNumber} = asset || {};
        const device = products?.find((product) => product.deviceSerialNumber === serialNumber);
        const {isHolder} = device || {};

        return isHolder;
    });

export {
    makeSelectAsset,
    makeSelectAssetByAssetId,
    makeSelectCmMessageData,
    makeSelectFirstAsset,
    makeSelectIsDeviceDataChangeReceived,
    makeSelectIsDeviceSyncInProgress,
    makeSelectIsFindDeviceReceived,
    makeSelectIsHolderByAssetId,
    makeSelectIsYapActivationInProgress,
    makeSelectIsYapDeviceConnected,
    makeSelectIsYapDeviceInfoReceived,
    makeSelectIsYapFirmwareInProgress,
    makeSelectIsYapHolderConnected,
    makeSelectIsYapHolderInfoReceived,
    makeSelectIsYapSynchronizeDataCollectionInProgress,
    makeSelectIsYapSynchronizeDeviceDataCollectionReceived,
    makeSelectIsYapSynchronizeHolderDataCollectionReceived,
    makeSelectIsYapUpdateSettingsInProgress,
    makeSelectYapCancelDataCollectionInProgress,
    makeSelectIsYapDataCollectionAllowed,
    makeSelectSyncLastRequestId,
    makeSelectYapLastCheckRequestDate,
    makeSelectYapLastRequestId,
};
