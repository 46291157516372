import appConfig from '../../config/appConfig';
import queryParameters from '../../consts/route/queryParameters';
import stringUtils from '../../utils/stringUtils';
import urlUtils from '../../utils/urlUtils';
import iccMarketService from '../icc/iccMarketService';
import log from '../logger/log';
import server from '../server/server';
import defaultLocalizations from './default';
import languageDataService from './languageDataService';
import LocalizedStrings from './LocalizedStrings';

const fetchLocalizations = async (languageCode) => {
    const localizationKey = appConfig.getLocalizationKey();
    const scpCloudTopicMarket = appConfig.getScpCloudTopicMarket();
    const currentLanguageCode = getMappedInitialLanguageCode(languageCode);

    try {
        return await fetchLocalization(currentLanguageCode);
    } catch (e) {
        try {
            const isDifferentLanguageCode = currentLanguageCode.toUpperCase() !== localizationKey.toUpperCase();

            if (isDifferentLanguageCode) {
                return await fetchLocalization(localizationKey);
            }
        } catch (e) {
            const isTestMarket = scpCloudTopicMarket === 'pmi' || scpCloudTopicMarket === 'uat';

            if (!isTestMarket) {
                log.error(`fetchLocalizations: error: ${JSON.stringify(e)}`);
            }
        }
    }

    return {};
};

let initialLanguageCode;

const getMappedLanguageCode = (language) => {
    const localizationMapping = appConfig.getLocalizationMapping();

    if (localizationMapping?.length && language) {
        const mappedLanguage = localizationMapping.find((languageItem) => languageItem[language]);

        if (mappedLanguage) {
            return mappedLanguage[language];
        } else {
            return language;
        }
    }

    return language;
};

const getInitialLanguageCode = (languageCode) => {
    if (!initialLanguageCode || languageCode) {
        let initialDefaultLanguageCode = appConfig.getLocalizationKey();

        initialLanguageCode = languageDataService.getInitialLanguageData();

        if (initialLanguageCode) {
            initialDefaultLanguageCode = initialLanguageCode;
        }

        const [currentLanguageCode = initialDefaultLanguageCode] = languageCode
            ? [languageCode]
            : urlUtils.getDataFromUrl(queryParameters.LANG);

        initialLanguageCode = currentLanguageCode;
    }

    return initialLanguageCode;
};

const getMappedInitialLanguageCode = (languageCode) => {
    const initialLanguageCode = getInitialLanguageCode(languageCode);

    return getMappedLanguageCode(initialLanguageCode);
};

const clearInitialLanguageCode = () => (initialLanguageCode = null);

const fetchLocalization = async (currentLanguageCode) => {
    const formattedStrings = stringUtils.formatString(
        appConfig.getLocalizationFileName(),
        currentLanguageCode.toLowerCase()
    );
    const localizationAsset = await iccMarketService.getMediaUrl({
        mediaNameList: formattedStrings,
        throwException: true,
    });
    const localizationPath = localizationAsset[0].url;
    const {data} = await server.get(localizationPath);

    return {currentLanguageCode, data};
};

let localizedStrings;

const initLocalizations = async (languageCode) => {
    const {currentLanguageCode, data} = await fetchLocalizations(languageCode);
    const localizationsData = {
        default: defaultLocalizations,
        [currentLanguageCode]: data,
    };

    localizedStrings = new LocalizedStrings(localizationsData, {logsEnabled: false});

    if (currentLanguageCode) {
        localizedStrings.setLanguage(currentLanguageCode);
    }
};

const getLocalizedLanguage = () => {
    return localizedStrings.getLanguage();
};

const getLocalizedStrings = () => localizedStrings;

export {
    initLocalizations,
    getLocalizedStrings,
    getLocalizedLanguage,
    getInitialLanguageCode,
    getMappedInitialLanguageCode,
    clearInitialLanguageCode,
};
