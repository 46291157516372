import appConfig from '../../config/appConfig';
import * as errorPageTypes from '../../consts/app/errorPageTypes';
import * as logMessageTypes from '../../consts/app/logMessageTypes';
import * as iccMethods from '../../consts/icc/iccMethods';
import httpStatusTypesService from '../../services/server/httpStatusTypesService';
import {makeSelectHolderUamAuthToken, makeSelectUamAuthToken} from '../../state/selectors/auth';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../state/selectors/consumer';
import {getState} from '../../state/store';
import urlUtils from '../../utils/urlUtils';
import backendService from '../app/backendService';
import authDataService from '../auth/authDataService';
import authDataStoreService from '../auth/authDataStoreService';
import HttpErrorHandlerService from '../httpErrorHandlerService';
import {getMappedInitialLanguageCode} from '../localization/localizationService';
import log from '../logger/log';
import {authMapping, authUamMapping, loginTokenMapping} from '../mapping/iccMappings';
import appRouterService from '../route/appRouterService';
import server from '../server/server';
import storageService from '../storage/storageService';
import iccResponseErrorCheckService from './iccResponseErrorCheckService';

let isTokenRefreshInProgress = false;
const getIccApiUrl = (methodName) => urlUtils.join(appConfig.getIccApiUrl(), methodName);

const getHeaders = () => {
    return {
        a: appConfig.getIccApiHeader_a(),
        Accept: 'application/json',
        Authorization: appConfig.getIccApiHeaderAuthorization(),
        'Content-Type': 'application/x-www-form-urlencoded',
        l: getMappedInitialLanguageCode()?.toUpperCase(),
        m: appConfig.getIccApiHeader_m(),
    };
};

const getBody = (isAnonymous) => {
    const authUsername = authDataStoreService.getAuthUsername();

    return {
        grant_type: 'password',
        password: '',
        scope: appConfig.getIccApiBodyScope(),
        username: isAnonymous ? 'anonymous' : authUsername ? authUsername : appConfig.getIccApiHeaderUsername(),
    };
};

const onIccApiMethodResponse = (response, isAnonymous) => {
    const responseData = authMapping(response.data);

    authDataStoreService.setIccTokenData({...responseData, isAnonymous});

    return responseData;
};

const refreshIccTokenIfAllowed = () => {
    if (!isTokenRefreshInProgress) {
        isTokenRefreshInProgress = true;

        return refreshIccToken();
    }
};

const fetchIccToken = async (props) => {
    const {isAnonymous, isStandaloneLoginPage} = props;
    let spiceToken;

    if (isAnonymous) {
        spiceToken = appConfig.getAnonymousToken();
    } else {
        spiceToken = authDataStoreService.getSpiceToken();
    }

    const data = urlUtils.stringify({...getBody(isAnonymous), password: spiceToken});

    try {
        const response = await getToken(data, getHeaders());

        onIccApiMethodResponse(response, isAnonymous);

        log.debug(
            `iccService: fetchIccToken, request: "${iccMethods.GET_TOKEN}" has successful response`,
            logMessageTypes.ICC
        );
    } catch (e) {
        const errorStatus = e?.response?.status;
        const isTokenNotValid = httpStatusTypesService.isBadRequest(errorStatus);

        log.error(`iccService: fetchIccToken, request: "${iccMethods.GET_TOKEN}", error: ${e}`, logMessageTypes.ICC);

        if (isStandaloneLoginPage && isTokenNotValid) {
            throw e;
        } else {
            new HttpErrorHandlerService().handleError(e);
        }
    }
};

const refreshIccToken = async () => {
    const spiceToken = authDataStoreService.getSpiceToken();
    const refreshToken = authDataStoreService.getRefreshToken();

    if (spiceToken && refreshToken) {
        const data = urlUtils.stringify({
            ...getBody(),
            password: spiceToken,
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
        });

        try {
            const response = await getToken(data, getHeaders());

            const responseMapped = onIccApiMethodResponse(response);

            log.debug(
                `iccService: refreshIccToken, request: "${iccMethods.GET_TOKEN}" has successful response`,
                logMessageTypes.ICC
            );

            isTokenRefreshInProgress = false;
            return responseMapped;
        } catch (e) {
            isTokenRefreshInProgress = false;

            const isRememberMeEnabled = appConfig.getIsRememberMeEnabled();

            if (isRememberMeEnabled) {
                storageService.removeUserSessionDataFromStorage();
                appRouterService.forwardToSelectMarketPage();
            }

            log.info(
                `iccService: refreshIccToken, request: "${iccMethods.GET_TOKEN}", error: ${e}`,
                logMessageTypes.ICC
            );

            throw e;
        }
    } else {
        log.error(
            `iccService: refreshIccToken, required auth data is empty, spiceToken: ${spiceToken}, refreshToken: ${refreshToken}`,
            logMessageTypes.ICC
        );
        new HttpErrorHandlerService().handleError();
    }
};

const getDeviceCodentify = () => {
    const iotDevice = makeSelectIotDeviceMergedWithIccProduct()(getState());

    return iotDevice?.device?.codentifyOriginal;
};

const getHolderCodentify = () => {
    const iotDevice = makeSelectIotDeviceMergedWithIccProduct()(getState());

    return iotDevice?.holder?.codentifyOriginal;
};

const isCodentifyNotValidForUam = (codentifyID) => {
    return !codentifyID || codentifyID.includes('*');
};

const fetchUamToken = async (isRetry, isHolder) => {
    const codentifyID = isHolder ? getHolderCodentify() : getDeviceCodentify();

    if (isCodentifyNotValidForUam(codentifyID)) {
        const state = getState();
        const idToken = isHolder ? makeSelectHolderUamAuthToken()(state) : makeSelectUamAuthToken()(state);

        if (idToken) return false;

        //holder was inserted after cloud pairing and codentify is not defined. Pairing must be started from the beginning
        // or codentify is encrypted with * by market
        log.info(
            `Bad Codentify: ${codentifyID} and token are not defined (isHolder: ${isHolder}).`,
            logMessageTypes.ICC
        );

        if (backendService.isUamBackend()) {
            appRouterService.forwardToErrorHandlingPage(errorPageTypes.ICC_TEMPORARY_ERROR);
        }
        return false;
    }

    const iccMethod = iccMethods.GET_UAM_TOKEN_V2;
    const message = `iccService: fetchUamToken, request: "${iccMethod}" for ${codentifyID}`;
    const authToken = await authDataService.getAuthToken();
    const requestConfig = {
        headers: {Authorization: 'Bearer ' + authToken},
        params: {
            codentifyID,
        },
    };

    try {
        const response = await server.get(getIccApiUrl(iccMethod), {...requestConfig});
        const responseData = authUamMapping(response.data);

        authDataStoreService.setUamApiData({...responseData, isHolder});

        log.debug(`${message} has successful response`, logMessageTypes.ICC);
        return true;
    } catch (e) {
        log.error(`${message}, error: ${e}`, logMessageTypes.ICC);
        if (isRetry) return false;

        await iccResponseErrorCheckService(e);
        return await fetchUamToken(true);
    }
};

const fetchSSOLink = async (deeplinkId) => {
    const authToken = await authDataService.getAuthToken();
    const headers = {Authorization: 'Bearer ' + authToken};
    const login = authDataStoreService.getAuthUsername();
    const password = authDataStoreService.getSpiceToken();

    if (login) {
        const data = {
            Login: login,
            Password: password,
            AutoLoginLinkName: deeplinkId,
        };

        try {
            const response = await getLoginToken(data, headers);

            const {campaignUrlPersonal} = loginTokenMapping(response.data);

            log.debug(
                `iccService: fetchSSOLink, request: "${iccMethods.GET_CONSUMER_LOGIN_TOKEN}" has successful response`,
                logMessageTypes.ICC
            );

            return campaignUrlPersonal;
        } catch (e) {
            log.error(
                `iccService: fetchSSOLink, request: "${iccMethods.GET_CONSUMER_LOGIN_TOKEN}", error: ${e}`,
                logMessageTypes.ICC
            );
            new HttpErrorHandlerService().handleError(e);
        }
    } else {
        log.debug(`iccService: fetchSSOLink is canceled, login is empty`, logMessageTypes.ICC);
    }
};

const getToken = (data, headers) => server.post(getIccApiUrl(iccMethods.GET_TOKEN), {data, headers});

const getLoginToken = (data, headers) =>
    server.post(getIccApiUrl(iccMethods.GET_CONSUMER_LOGIN_TOKEN), {data, headers});

export default {
    isCodentifyNotValidForUam,
    refreshIccTokenIfAllowed,
    fetchIccToken,
    fetchSSOLink,
    fetchUamToken,
    refreshIccToken,
};
