import appConfig from '../../../config/appConfig';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import * as maskedRoutes from '../../../consts/route/maskedRoutes';
import connectivityService from '../../../services/device/connectivityService';
import deviceTypesService from '../../../services/device/deviceTypesService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import pairingService from '../../../services/pairingService';
import appRouterService from '../../../services/route/appRouterService';
import routerService from '../../../services/routerService';
import userDeviceService from '../../../services/user/userDeviceService';
import browserCompatibilityService from '../../BrowserCompatibility/browserCompatibilityService';

const getSBLPopupProps = (deviceType, isDeviceDisconnected, isDesktopLayout, usbReconnectRedirectUrl) => {
    const localizedStrings = getLocalizedStrings();
    const isUsbConnectivitySupported = userDeviceService.isUsbSupported();
    const isUsbConnectivityAvailableForMarket = appConfig.getConnectivityIsUsb();
    const desktopWidgetLink = getDesktopWidgetLink();
    const isUSBFWUAvailableForCurrentDevice = isUSBFWUAvailableForCurrentDeviceType(deviceType);
    const isReconnectViaUsbFlowAvailable =
        isUsbConnectivitySupported &&
        isUsbConnectivityAvailableForMarket &&
        isDesktopLayout &&
        isUSBFWUAvailableForCurrentDevice;
    const isDownloadWidgetFlowAvailable = desktopWidgetLink && isDesktopLayout;
    const isReconnectOrWidgetMobileFlowAvailable =
        !isDesktopLayout &&
        ((isUsbConnectivityAvailableForMarket && isUSBFWUAvailableForCurrentDevice) || desktopWidgetLink);

    if (isReconnectViaUsbFlowAvailable) {
        return getUsbReconnectFlowProps(localizedStrings, deviceType, isDeviceDisconnected, usbReconnectRedirectUrl);
    } else if (isDownloadWidgetFlowAvailable) {
        return getDownloadWidgetFlowProps(localizedStrings, desktopWidgetLink);
    } else if (isReconnectOrWidgetMobileFlowAvailable) {
        return getReconnectOrWidgetMobileFlowProps(localizedStrings);
    } else {
        return getContactSupportFlowProps(localizedStrings);
    }
};

const getUsbReconnectFlowProps = (
    localizedStrings,
    deviceType,
    isDeviceDisconnected,
    usbReconnectRedirectUrl = maskedRoutes.ABOUT_DEVICE
) => ({
    primaryBtnText: localizedStrings[localizationKeys.FIRMWARE_SBL_FOUND_POPUP_RECONNECT_BUTTON],
    descriptionPart1: localizedStrings[localizationKeys.FIRMWARE_SBL_FOUND_POPUP_MESSAGE_USB_CONNECT],
    primaryBtnAction: () => {
        if (!isDeviceDisconnected) {
            pairingService.disconnectAndClearState();
        }

        connectivityService.setIsUsb();

        appRouterService.forwardToPairingGuidePage({
            deviceType,
            redirectToAfterConnect: usbReconnectRedirectUrl,
        });
    },
});

const getDownloadWidgetFlowProps = (localizedStrings, link) => ({
    primaryBtnText: localizedStrings[localizationKeys.FIRMWARE_SBL_FOUND_POPUP_DOWNLOAD_APP_BUTTON],
    descriptionPart1: localizedStrings[localizationKeys.FIRMWARE_SBL_FOUND_POPUP_MESSAGE_DOWNLOAD_APP],
    primaryBtnAction: () => routerService.blankLinkOpen(link),
});

const getReconnectOrWidgetMobileFlowProps = (localizedStrings) => ({
    primaryBtnText: null,
    descriptionPart1: localizedStrings[localizationKeys.FIRMWARE_SBL_FOUND_POPUP_MESSAGE_MOBILE],
    primaryBtnAction: null,
});

const getContactSupportFlowProps = (localizedStrings) => ({
    primaryBtnText: null,
    descriptionPart1: localizedStrings[localizationKeys.FIRMWARE_SBL_FOUND_POPUP_MESSAGE_CONTACT_US],
    primaryBtnAction: null,
});

const getDesktopWidgetLink = () => {
    const isMac = userDeviceService.isDesktopMac();
    const isWindows = userDeviceService.isDesktopWindows();

    if (isMac) {
        return browserCompatibilityService.getBrowserCompatibleMacOSButtonData().link;
    } else if (isWindows) {
        return browserCompatibilityService.getBrowserCompatibleWindowsButtonData().link;
    }
};

const isUSBFWUAvailableForCurrentDeviceType = (deviceType) => {
    if (deviceTypesService.isP1(deviceType)) {
        return appConfig.getIsUsbFWAvailableP1();
    } else if (deviceTypesService.isP4(deviceType)) {
        return appConfig.getIsUsbFWAvailableP4();
    } else if (deviceTypesService.isP1V4(deviceType)) {
        return appConfig.getIsUsbFWAvailableP1V4();
    }
};

export default {
    getSBLPopupProps,
};
