import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {Button, ButtonBorder} from '../../../components/Button';
import ConnectionTrackButton from '../../../components/Button/ConnectionTrackButton/ConnectionTrackButton';
import {IconDarkBook} from '../../../components/Icons';
import Container from '../../../components/Layout/Container/Container';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import * as tncTypes from '../../../consts/tnc/tncTypes';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import analyticsService from '../../../services/analyticsService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import tncService from '../../../services/tncService';
import {hideLoader, setNotificationInfo, showLoader} from '../../../state/ducks/global';
import {makeSelectAcceptedTncByType, makeSelectTncByType} from '../../../state/selectors/consumer';
import {dispatch} from '../../../state/store';
import elementsTesIds from '../../../test/consts/elementsTesIds';
import TermsOfUseDescription from '../TermsOfUseDescription/TermsOfUseDescription';
import styles from './TermsOfUsePage.module.scss';

const mapStateToProps = createStructuredSelector({
    termsOfUse: makeSelectTncByType(tncTypes.TERMS_OF_USE),
    acceptedTermsOfUse: makeSelectAcceptedTncByType(tncTypes.TERMS_OF_USE),
});

const TermsOfUsePage = (props) => {
    const {termsOfUse, acceptedTermsOfUse} = props;

    if (!termsOfUse) return null;

    const {description, legals, updateDescription} = termsOfUse;

    if (!legals) return null;

    const onAcceptButtonClick = async () => {
        dispatch(showLoader());

        analyticsService.pushTNCTermsOfUseInteractionEvent(true);
        await tncService.setTnc(legals);

        dispatch(hideLoader());
    };

    const localizedStrings = getLocalizedStrings();

    const onDeclineButtonClick = () => {
        analyticsService.pushTNCTermsOfUseInteractionEvent(false);

        dispatch(
            setNotificationInfo(
                localizedStrings[localizationKeys.TERMS_SUMMARY_ERROR_NOTIFICATION_TEXT],
                elementsTesIds.TNC_TOU_NOTIFICATION
            )
        );
    };

    const isUpdatedTnc = acceptedTermsOfUse != null;
    const summaryTitleText = isUpdatedTnc
        ? localizationKeys.TERMS_SUMMARY_UPDATE_TITLE_TEXT
        : localizationKeys.TERMS_SUMMARY_TITLE_TEXT;
    const descriptionText = isUpdatedTnc ? updateDescription : description;

    useDocumentTitle(localizedStrings[localizationKeys.TERMS_OF_USE_PAGE_TITLE]);

    return (
        <div className={styles.Page} data-testid={elementsTesIds.TERMS_OF_USE_PAGE}>
            <Container>
                <div className={styles.Header}>
                    <IconDarkBook className={styles.HeaderImage} />
                </div>
                <div>
                    <h1 className={styles.Title}>{localizedStrings[summaryTitleText]}</h1>
                    <TermsOfUseDescription
                        innerHtml={descriptionText}
                        className={styles.TermsOfUsePageDescription}
                        isDarkBackground
                    />
                </div>
                <div className={styles.Footer}>
                    <ConnectionTrackButton
                        arrowClassName={styles.ButtonArrow}
                        button={ButtonBorder}
                        className={styles.ButtonAgree}
                        onClick={onAcceptButtonClick}
                        showArrow
                        testId={elementsTesIds.TNC_TOU_BTN_AGREE}
                    >
                        {localizedStrings[localizationKeys.TERMS_SUMMARY_CONFIRM_BUTTON_TEXT]}
                    </ConnectionTrackButton>
                    <Button
                        className={styles.ButtonDecline}
                        onClick={onDeclineButtonClick}
                        showUnderline
                        testId={elementsTesIds.TNC_TOU_BTN_DECLINE}
                    >
                        {localizedStrings[localizationKeys.TERMS_SUMMARY_DECLINE_BUTTON_TEXT]}
                    </Button>
                </div>
            </Container>
        </div>
    );
};

export default connect(mapStateToProps)(TermsOfUsePage);
