import * as loglevel from 'loglevel';

import logState from './logState';

const consoleLog = {
    setLevel(level) {
        loglevel.setLevel(level);
    },
    debug(msg) {
        const msgFormatted = formatMessage(msg);

        logState.setToLogSate(msgFormatted);
        loglevel.debug(msgFormatted);
    },
    info(msg) {
        const msgFormatted = formatMessage(msg);

        logState.setToLogSate(msgFormatted);
        loglevel.info(msgFormatted);
    },
    error(msg) {
        const msgFormatted = formatMessage(msg);

        logState.setToLogSate(msgFormatted);
        loglevel.error(msgFormatted);
    },
};

const formatMessage = (msg) => {
    const date = new Date().toISOString();
    const dateFormatted = date.substr(11, 12);

    return `[${dateFormatted}] [${msg}]`;
};

export default consoleLog;
