import appConfig from '../config/appConfig';
import {
    hideAllLoaders,
    setHolderRegisterFailed,
    setIsHolderConnectAvailable,
    setIsSkipRegAndSyncFlow,
    setScpHolderCheckInProgress,
} from '../state/ducks/global';
import {setMessage} from '../state/ducks/iot';
import {
    clearIotDevice,
    setDeviceActivationStatus,
    setIsDeviceReconnected,
    setIsPairingInProgress,
} from '../state/ducks/iotDevice/actions';
import {clearMwDevice} from '../state/ducks/mwDevice';
import {clearProductsToChangeRegistrationStatus} from '../state/ducks/productSettings';
import {
    clearYapAssets,
    setDeviceDataChangeReceived,
    setDeviceInfoReceived,
    setHolderInfoReceived,
    setYapActivationInProgress,
    setYapFirmwareInProgress,
    setYapSynchronizeDataCollectionInProgress,
    setYapSynchronizeDeviceDataCollectionReceived,
    setYapSynchronizeHolderDataCollectionReceived,
    setYapSyncInProgress,
    setYapUpdateSettingsInProgress,
} from '../state/ducks/yapEncrypted';
import {dispatch} from '../state/store';
import backendService from './app/backendService';
import devicePingService from './ble/devicePingService';
import cmClientService from './communicationLayer/cmClientService';
import framesProcessingService from './device/framesProcessingService';
import IqosServiceClient from './device/iqosServiceClient';
import gamWebSocketService from './gam/gamWebSocketService';
import hidReconnectHandlerService from './hid/hidReconnectHandlerService';
import log from './logger/log';
import appRouterService from './route/appRouterService';
import scpCloudService from './scpCloud/scpCloudService';
import uiIotMessageResponseChecker from './scpCloud/uiIotMessageResponseChecker';
import uamWebSocketService from './uam/uamWebSocketService';
import userDeviceService from './user/userDeviceService';
import yapService from './yap/yapService';

const onAvailabilityChanged = (e) => {
    const isEnabled = e.target.value;

    log.info(`iqosBleService: onAvailabilityChanged, isEnabled: ${isEnabled}`);

    if (!isEnabled) {
        appRouterService.forwardToMyDevicesPage();
        //IA: exactly order that is needed
        //    first forward
        //    than clearDevice
        disconnectAndClearState();
        dispatch(hideAllLoaders());
    }
};

const addBleAvailabilityListener = () => {
    if (userDeviceService.isBluetoothEventListenersSupported()) {
        navigator.bluetooth.removeEventListener('availabilitychanged', onAvailabilityChanged);
        navigator.bluetooth.addEventListener('availabilitychanged', onAvailabilityChanged);
    }
};

const disconnectAndClearState = () => {
    const iqosService = new IqosServiceClient();

    if (iqosService.isClientInitialized()) {
        iqosService.disconnect();
    }
    scpCloudService.disconnectIoT();
    uiIotMessageResponseChecker.clearResponsesTimeouts();
    hidReconnectHandlerService.clearHidReconnectHandlerState();

    const isGamBackend = backendService.isGamBackend();
    const isUamBackend = backendService.isUamBackend();
    const isDataCollectionEnabled = appConfig.getIsDataCollectionEnabled();
    const isYapEncryptedMode = yapService.isYapEncryptedMode();
    const isUamEnabled = isUamBackend || isDataCollectionEnabled || isYapEncryptedMode;

    if (isGamBackend) {
        disconnectAndClearGamState();
    } else if (isUamEnabled) {
        disconnectAndClearUamState();
    }

    if (isGamBackend || isUamEnabled) {
        disconnectAndClearYapState();
    }

    dispatch(setDeviceActivationStatus(false));
    dispatch(clearProductsToChangeRegistrationStatus());
    dispatch(setIsDeviceReconnected(false));
    dispatch(setIsPairingInProgress(false));
    dispatch(clearMwDevice());
    dispatch(clearIotDevice());
    dispatch(setMessage(null));
};

const disconnectAndClearHolderState = () => {
    disconnectAndClearYapHolderState();
    disconnectAndClearUamHolderState();
};

const disconnectAndClearYapState = () => {
    cmClientService.disconnectCmClient();
    dispatch(setYapActivationInProgress(false));
    dispatch(setYapSyncInProgress(false));
    dispatch(setYapUpdateSettingsInProgress(false));
    dispatch(setYapFirmwareInProgress(false));
    dispatch(setDeviceDataChangeReceived(false));
    dispatch(setIsSkipRegAndSyncFlow(false));
    disconnectAndClearYapHolderState();
};

const disconnectAndClearYapHolderState = () => {
    cmClientService.disconnectCmClient(true);
    dispatch(setYapSyncInProgress(false, true));
    dispatch(setYapUpdateSettingsInProgress(false, true));
    dispatch(setIsHolderConnectAvailable(true));
};

const disconnectAndClearUamState = () => {
    uamWebSocketService.disconnectUamWebSocket(true);
    dispatch(setDeviceInfoReceived(false));
    dispatch(setYapSynchronizeDataCollectionInProgress(false));
    dispatch(setYapSynchronizeDeviceDataCollectionReceived(false));
    disconnectAndClearUamHolderState();
    dispatch(clearYapAssets());
};

const disconnectAndClearUamHolderState = () => {
    uamWebSocketService.disconnectUamWebSocket(true, true);
    dispatch(setHolderInfoReceived(false));
    dispatch(setYapSynchronizeHolderDataCollectionReceived(false));
    dispatch(setScpHolderCheckInProgress(false));
    dispatch(setHolderRegisterFailed(false));
};

const disconnectAndClearGamState = () => {
    gamWebSocketService.disconnectGamWebSocket();
};

const disconnectAndForwardToMyDevicesPage = (isDeviceDisconnected) => {
    if (!isDeviceDisconnected) {
        disconnectAndClearState();
    }

    appRouterService.forwardToMyDevicesPage();
};

const onPairingError = () => disconnectAndClearState();

const stopDeviceSchedulers = () => {
    devicePingService.stopDevicePing();
    framesProcessingService.disable();
};

const restartDeviceSchedulers = () => {
    framesProcessingService.restart();
};

export default {
    addBleAvailabilityListener,
    disconnectAndClearState,
    disconnectAndClearHolderState,
    disconnectAndForwardToMyDevicesPage,
    onPairingError,
    stopDeviceSchedulers,
    restartDeviceSchedulers,
};
