import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';

export const useScrollTop = () => {
    const history = useHistory();

    useEffect(() => {
        return history.listen(() => {
            window.scrollTo(0, 0);
        });
    }, [history]);
};

export default useScrollTop;
