import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Router} from 'react-router-dom';
import {createStructuredSelector} from 'reselect';

import AppLoadeRaw from '../../components/Loader/AppLoader/AppLoadeRaw';
import appConfig from '../../config/appConfig';
import analyticsService from '../../services/analyticsService';
import appService from '../../services/app/appService';
import history from '../../services/history';
import log from '../../services/logger/log';
import userBrowserService from '../../services/user/userBrowserService';
import userDeviceService from '../../services/user/userDeviceService';
import {hideLoader, setIsAppLoaded} from '../../state/ducks/global';
import {makeIsAppLoading} from '../../state/selectors/global';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Routes from '../Routes/Routes';

const mapStateToProps = createStructuredSelector({
    isAppLoading: makeIsAppLoading(),
});

const App = (props) => {
    const {isAppLoading, dispatch} = props;
    const onInit = async () => {
        const isBleSupported = userDeviceService.isBluetoothSupported();
        const isBluefyBrowser = userBrowserService.isBluefyBrowser();

        if (process.env.REACT_APP_IS_BUILD_REF === 'true') {
            await appService.initializeStartActions();
        } else {
            if (appConfig.getIsMarketInitialized()) {
                await appService.initializeStartActions();
            } else {
                await appService.initializeUserSessionActions();
            }
        }

        analyticsService.pushAppLoadedEvent();
        analyticsService.pushBrowserCompatibilityEvent(isBleSupported, isBluefyBrowser);

        dispatch(setIsAppLoaded());

        log.info(`App: app is started successfully, isBleSupported: ${isBleSupported}`);

        if (!isBleSupported) {
            dispatch(hideLoader());
        }
    };

    useEffect(() => {
        onInit();
    }, []);

    return (
        <ErrorBoundary>
            {isAppLoading ? (
                <AppLoadeRaw />
            ) : (
                <Router history={history.getHistory()}>
                    <Routes />
                </Router>
            )}
        </ErrorBoundary>
    );
};

export default connect(mapStateToProps)(App);
