import {useEffect} from 'react';

import userDeviceService from '../services/user/userDeviceService';

const useListenBluetoothAvailabilityChange = () => {
    useEffect(() => {
        if (userDeviceService.isBluetoothSupported() && userDeviceService.isBluetoothEventListenersSupported()) {
            navigator.bluetooth.addEventListener('availabilitychanged', userDeviceService.checkBluetoothAvailability);
            return () =>
                navigator.bluetooth.removeEventListener(
                    'availabilitychanged',
                    userDeviceService.checkBluetoothAvailability
                );
        }
    }, []);
};

export default useListenBluetoothAvailabilityChange;
