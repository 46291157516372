import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './assets/scss/styles.scss';
import './assets/css/index.scss';
import './assets/css/layout.css';
import './assets/css/objects.scss';
import './assets/css/spacing.css';
import './assets/css/typography.scss';
import 'slick-carousel/slick/slick.css';
import './assets/css/variables/main.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

import appConfig from './app/config/appConfig';
import {initConfig} from './app/config/config';
import App from './app/containers/App/App';
import log from './app/services/logger/log';
import {addReduxLoggerIfEnabled, configureStore} from './app/state/store';

const render = async () => {
    await initConfig();

    if (appConfig.getIsMarketInitialized()) {
        log.setLevel(appConfig.getLogLevel(), appConfig.getAwsLogLevel());
    }

    const store = configureStore();

    await addReduxLoggerIfEnabled();

    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById('root')
    );
};

const initAxe = async () => {
    if (process.env.REACT_APP_IS_AXE_ENABLED === 'true') {
        const {default: axe} = await import('@axe-core/react');

        const config = {
            rules: [
                {
                    id: 'color-contrast',
                    selector: ':not(#logs)',
                },
            ],
        };

        axe(React, ReactDOM, 1000, config);
    }
};

render();

initAxe();
