enum ModalTypes {
    CONNECTIVITY_SELECT,
    PAIRING_GUIDE_ERROR,
    PAIRING_SKIP,
    REGISTRATION_FAILED,
    HID_RECONNECT,
    DEVICE_IS_IN_SBL,
    RECONNECT_DEVICE_GUIDE,
    FWU_LED_CHECK,
    FWU_ADDITIONAL_STEPS_GUIDE,
    FWU_ERROR,
    UPDATE_FW,
    REGISTRATION_HOLDER,
    PURCHASE_DATE,
}

export default ModalTypes;
