import * as cmMessageErrorCodes from '../../consts/communicationLayer/cmMessageErrorCodes';
import * as cmMessageFakeTypes from '../../consts/communicationLayer/cmMessageFakeTypes';
import * as cmMessageTypes from '../../consts/communicationLayer/cmMessageTypes';
import FWU_TARGET_TYPES from '../../enums/iot/fwuTargetTypes';
import {setMwDeviceFirmwareData} from '../../state/ducks/mwDevice';
import {setCmMessageData} from '../../state/ducks/yapEncrypted';
import {dispatch} from '../../state/store';
import backendService from '../app/backendService';
import fwuService from '../ble/fwuService';
import framesProcessingService from '../device/framesProcessingService';
import gamClientService from '../gam/gamClientService';
import cmMessageRequestService from './cmMessageRequestService';

const onCmMessage = (message, isHolder) => {
    const {type, token, request_id, frames, data} = message;
    const fwu_target = isHolder ? FWU_TARGET_TYPES.HOLDER_DEVICE : FWU_TARGET_TYPES.CHARGER_OR_MONO;

    const onFwuError = (errorCode, errorFrame) => {
        gamClientService.waitUntilFirmwareEndsOnGam();
        cmMessageRequestService.publishFwuErrorEvent(type, request_id, token, errorCode, errorFrame);
    };

    const onSuccessPackageLoad = (isHolder) => {
        cmMessageRequestService.publishFwuPackageLoadedEvent(request_id, token, isHolder);
    };

    switch (type) {
        case cmMessageTypes.STATUS_CHARACTERISTICS:
            const isUamBackend = backendService.isUamBackend();

            if (!isUamBackend) return;

            const {system_status, battery_status} = data || {};
            const {iteration_delay: system_iteration_delay, frame: system_frame} = system_status || {};
            const {iteration_delay: battery_iteration_delay, frame: battery_frame} = battery_status || {};
            const writeFrameCyclic = (cyclicType, frame, iteration_delay) => {
                if (!frame || !iteration_delay) return;

                const props = {
                    type,
                    cyclicId: cyclicType,
                    frames: [frame],
                    request_id,
                    cyclicTimeout: iteration_delay,
                };

                framesProcessingService.writeFrameCyclic(props);
            };

            writeFrameCyclic(cmMessageFakeTypes.SYSTEM_STATUS, system_frame, system_iteration_delay);
            writeFrameCyclic(cmMessageFakeTypes.BATTERY_STATUS, battery_frame, battery_iteration_delay);

            break;
        case cmMessageTypes.FIRMWARE_UPDATE_PACKAGE_LOAD:
            dispatch(setMwDeviceFirmwareData({device: data}));
            fwuService.clearPackageData();
            fwuService.downloadFwuPackage(fwu_target, onSuccessPackageLoad, onFwuError);

            break;
        case cmMessageTypes.FIRMWARE_UPDATE_START:
            const onFwuStatusEvent = (n) => {
                cmMessageRequestService.publishFwuStatusEvent(type, request_id, token, n, isHolder);
            };

            const onFwuFinish = (isHolder) => {
                dispatch(setCmMessageData(token, request_id));
                cmMessageRequestService.publishFwuFinishEvent(type, request_id, token, isHolder);
            };

            fwuService.startFwUpdate({fwu_target, onFwuError, onFwuStatusEvent, onFwuFinish});
            break;
        default:
            if (frames?.length) {
                framesProcessingService.writeFrameAndPublishResponse({
                    frames,
                    skipErrorOnTimeout: type === cmMessageTypes.DEVICE_USAGE_DATA_CAPABILITIES,
                    onSuccess: (...frames) => {
                        cmMessageRequestService.publishResponseEvent({type, request_id, token, frames, isHolder});
                    },
                    onError: (isDeviceDisconnect) => {
                        cmMessageRequestService.publishErrorEvent({
                            type,
                            request_id,
                            token,
                            errorCode: isDeviceDisconnect
                                ? cmMessageErrorCodes.DEVICE_DISCONNECTED
                                : cmMessageErrorCodes.UNKNOWN_ERROR,
                            isHolder,
                        });
                    },
                });
            }
    }
};

export default {
    onCmMessage,
};
