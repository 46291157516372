import cn from 'classnames';
import ResizeObserver from 'rc-resize-observer';
import React, {useLayoutEffect, useRef, useState} from 'react';

import useHidePage from '../../../hooks/useHidePage';
import domService from '../../../services/domService';
import AppPopup from '../AppPopup';
import styles from './PopupFullPage.module.scss';

const setAppLayoutHeight = (height) => {
    const wrapper = domService.getComponentsWrapperElement();

    wrapper.style.minHeight = height ? `${height}px` : null;
};

const PopupFullPage = (props) => {
    const {children, containerClassName, className, testId, ...restProps} = props;
    const [myRef, setMyRef] = useState(null);
    const previousHeightRef = useRef(0);
    const onResize = ({height}) => {
        if (previousHeightRef.current !== height) {
            previousHeightRef.current = height;
            setAppLayoutHeight(height);
        }
    };

    useHidePage();

    useLayoutEffect(() => {
        if (myRef) {
            const rootEl = domService.getRoot();
            const heightValue = myRef.offsetTop - rootEl.offsetTop;

            window.scrollTo(0, heightValue);
        }

        return () => setAppLayoutHeight(null);
    }, [myRef]);

    const onRefChange = (node) => setMyRef(node);

    return (
        <AppPopup
            {...restProps}
            isOpen
            className={cn(styles.PopupContainer, containerClassName)}
            overlayClassName={styles.Overlay}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <ResizeObserver onResize={onResize}>
                <div ref={onRefChange} className={className} data-testid={testId}>
                    {children}
                </div>
            </ResizeObserver>
        </AppPopup>
    );
};

export default PopupFullPage;
