import appConfig from '../../config/appConfig';
import {makeSelectGamAuthToken} from '../../state/selectors/auth';
import {getState} from '../../state/store';
import yapCheckStatusService from '../yap/yapCheckStatusService';
import YapWebSocketClient from '../yap/yapWebSocketClient';
import gamClient from './gamClient';
import gamWebSocketResponseService from './gamWebSocketResponseService';

let gamReconnectTimeout;
const clearReconnectTimeout = () => clearTimeout(gamReconnectTimeout);

let isGamSocketClosed = true;

const initGamWebSocket = async (assetId, isNewConnection = true) => {
    if (!isNewConnection && isGamSocketClosed) {
        return;
    }

    isGamSocketClosed = false;

    clearReconnectTimeout();

    if (isGamWebSocketConnected(assetId)) {
        return;
    }

    const onError = () => {
        if (!isNewConnection && isGamSocketClosed) {
            return;
        }

        yapCheckStatusService.createCheckStatusScheduler(assetId);

        const RECONNECT_TIMEOUT_MS = 5000;

        disconnectGamWebSocket(false);
        gamReconnectTimeout = setTimeout(() => initGamWebSocket(assetId, false), RECONNECT_TIMEOUT_MS);
    };

    const gamToken = makeSelectGamAuthToken()(getState());
    const wssUrl = `${appConfig.getGamWssUrl()}?token=${gamToken}`;
    const gamTopicSubscribeSettings = {url: wssUrl};

    if (gamTopicSubscribeSettings) {
        if (!isNewConnection && isGamSocketClosed) {
            return;
        }

        const yapWebSocketClient = new YapWebSocketClient(assetId, true, gamTopicSubscribeSettings);

        yapWebSocketClient.attachCloseHandler(async (message) => {
            switch (message?.code) {
                case 1000:
                    //normal close from code
                    break;
                case 1003:
                    //token is expired
                    await gamClient.refreshToken();
                    onError();
                    break;
                default:
                    onError();
                    break;
            }
        });

        yapWebSocketClient.attachConnectHandler(() => {
            yapCheckStatusService.setIsOneMoreCheckStatusNeeded(true);
            subscribeOnMessage();
        });
    } else {
        onError();
    }
};

const disconnectGamWebSocket = (isForceDisconnect = true) => {
    if (isForceDisconnect) {
        isGamSocketClosed = true;
        yapCheckStatusService.stopCheckStatusScheduler();
    }

    clearReconnectTimeout();

    const yapWebSocketClient = new YapWebSocketClient();

    yapWebSocketClient.disconnectClient();
};

const subscribeOnMessage = () => {
    new YapWebSocketClient().attachMessageHandler((message) => {
        gamWebSocketResponseService.proceedMessage(message);
    });
};

const isGamWebSocketConnected = (assetId) => new YapWebSocketClient().isConnectedCheck(assetId);

export default {
    initGamWebSocket,
    disconnectGamWebSocket,
    isGamWebSocketConnected,
};
