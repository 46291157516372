import cn from 'classnames';
import React from 'react';

import * as localizationKeys from '../../consts/localization/localizationKeys';
import domService from '../../services/domService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import helpers from '../../utils/helpers';
import styles from './SwitchToggle.module.scss';

const SwitchToggle = (props) => {
    const {index, onChange, className, isActive, testId} = props;
    const switchToggleClassName = cn(styles.SwitchToggle, className);
    const toggleClassName = cn(styles.Toggle, {[styles.ToggleActive]: isActive});

    const localizedStrings = getLocalizedStrings();
    const ariaLabel =
        localizedStrings[
            isActive
                ? localizationKeys.PREFERENCE_CENTER_PAGE_BTN_SWITCH_TOGGLE_ON_ARIA_LABEL
                : localizationKeys.PREFERENCE_CENTER_PAGE_BTN_SWITCH_TOGGLE_OFF_ARIA_LABEL
        ];

    const onSwitchToggleClick = () => {
        if (onChange) {
            helpers.runFunction(onChange, [index, !isActive]);
        }
    };

    const onKeyPress = (e) => domService.handleChekboxKeyPress(e, onSwitchToggleClick);

    return (
        <div
            aria-describedby={index}
            aria-label={ariaLabel}
            aria-pressed={isActive}
            className={switchToggleClassName}
            onClick={onSwitchToggleClick}
            data-testid={testId}
            onKeyPress={onKeyPress}
            role='button'
            tabIndex='0'
        >
            <div className={toggleClassName} />
        </div>
    );
};

export default SwitchToggle;
