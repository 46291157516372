import appConfig from '../../config/appConfig';
import userDeviceService from '../user/userDeviceService';

const CONNECTION_TYPES = {
    BLE: 0,
    USB: 1,
};

let connectionType = CONNECTION_TYPES.BLE;

const setIsUsb = () => (connectionType = CONNECTION_TYPES.USB);
const setIsBle = () => (connectionType = CONNECTION_TYPES.BLE);
const resetConnectionType = () => (connectionType = CONNECTION_TYPES.BLE);

const getConnectionType = (type) => {
    const isValidConnectionType = connectionType === CONNECTION_TYPES.BLE || connectionType === CONNECTION_TYPES.USB;

    return isValidConnectionType ? connectionType === type : type === CONNECTION_TYPES.BLE; //if conType is not valid - default is BLE
};

const isBleAndUsbEnabled = () => appConfig.getConnectivityIsUsb() && appConfig.getConnectivityIsBle();

const isUsb = () => (isBleAndUsbEnabled ? getConnectionType(CONNECTION_TYPES.USB) : appConfig.getConnectivityIsUsb());
const isBle = () => (isBleAndUsbEnabled ? getConnectionType(CONNECTION_TYPES.BLE) : appConfig.getConnectivityIsBle());

const isBleSupported = () => appConfig.getConnectivityIsBle() && userDeviceService.isBluetoothSupported();
const isUsbSupported = () => appConfig.getConnectivityIsUsb() && userDeviceService.isUsbSupported();
const isBleAndUsbSupported = () => isUsbSupported() && isBleSupported();
const getConnectionTypeName = () => (isBle() ? 'BLE' : 'USBHID');

export default {
    getConnectionTypeName,
    isBle,
    isBleAndUsbEnabled,
    isBleAndUsbSupported,
    isUsb,
    isUsbSupported,
    resetConnectionType,
    setIsBle,
    setIsUsb,
};
