import FWU_TARGET_TYPES from '../../enums/iot/fwuTargetTypes';
import helpers from '../../utils/helpers';
import mwMessageRequestService from '../iot/mwMessageRequestService';
import log from '../logger/log';
import IqosBLEClient from './iqosBleClient';

export default class FwuDataClient {
    constructor({program_blocks, onLastFrame, onFail, onFwuStatusEvent}) {
        this.program_blocks = program_blocks;
        this.iqosBLEClient = new IqosBLEClient();
        this.isStopped = true;
        this.onFail = onFail;
        this.onLastFrame = onLastFrame;
        this.previousWriteTS = Date.now();
        this.onFwuStatusEvent = onFwuStatusEvent;
        this._currentFrameIndex = 0;
    }

    set currentFrameIndex(val) {
        this._currentFrameIndex = val;
    }

    get currentFrameIndex() {
        return this._currentFrameIndex;
    }

    writeFwuFrames = async () => {
        if (!this.iqosBLEClient.isDeviceConnected() || this.isStopped) return;

        const {iqosBLEClient, program_blocks} = this;
        const frame = program_blocks[this.currentFrameIndex];
        const {f, n} = frame;

        try {
            // log.debug(`FwuDataClient: FWU Frame - ${f}: try to writeValue`);
            const WRITE_INTERVAL_MS = 89;
            const interval = Date.now() - this.previousWriteTS;
            const delay = WRITE_INTERVAL_MS - interval;

            if (delay > 0 && delay < WRITE_INTERVAL_MS) {
                await helpers.timeout(delay);
            }
            if (this.isStopped) return;

            this.previousWriteTS = Date.now();
            await iqosBLEClient.writeValueToFwuDataCharacteristic(f, true);

            if (this.isStopped) return;

            log.debug(`FwuDataClient: FWU Frame - ${f}: writeValue success`);

            if (n) {
                mwMessageRequestService.onFwuStatusEvent({
                    onSuccess: this.onFwuStatusEvent,
                    status: n,
                    fwu_target: FWU_TARGET_TYPES.CHARGER_OR_MONO,
                });
            }

            const isLastFrame = this.isLastFrame();

            if (isLastFrame) {
                helpers.runFunction(this.onLastFrame);
            } else {
                this.currentFrameIndex = this.currentFrameIndex + 1;
                this.writeFwuFrames();
            }
        } catch (e) {
            if (this.isStopped) return;
            log.debug(`FwuDataClient: FWU Frame - ${f}: writeValue error: ${e}`);
            this.onFail();
        }
    };

    isLastFrame = () => {
        return this.currentFrameIndex >= this.program_blocks.length - 1;
    };

    start = (frameIndex) => {
        this.isStopped = false;
        this.currentFrameIndex = frameIndex;
        this.writeFwuFrames();
    };

    stop = () => {
        this.isStopped = true;
    };
}
