import React from 'react';

import Icon from './Icon';

const IconDarkBook = (props) => {
    return (
        <Icon width='50' height='60' viewBox='0 0 50 60' {...props}>
            <g fill='none' fillRule='evenodd' stroke='#FFF' strokeWidth='2' opacity='.4'>
                <path strokeLinejoin='round' d='M9.217 51.428C10.151 56.678 14.02 59 19.35 59H49l-.003-50h-7.562' />
                <path strokeLinejoin='round' d='M1 1v39.807C1 47.912 5.203 51 11.35 51H41l-.003-50H1z' />
                <path strokeLinecap='round' d='M10 11h20M10 20h12M10 29h20' />
            </g>
        </Icon>
    );
};

export default IconDarkBook;
