import cn from 'classnames';
import React, {FC, useEffect} from 'react';
import {useSelector} from 'react-redux';

import Button from '../../../../../components/Button/Button';
import {getReconnectDeviceGuideData} from '../../../../../config/dataConfig/dataConfig';
import * as localizationKeys from '../../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import {showReconnectDeviceGuideModalAction} from '../../../../../state/ducks/modal';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../../../../state/selectors/consumer';
import {dispatch} from '../../../../../state/store';
import styles from './ReconnectNotification.module.scss';

interface IProps {
    onClick: () => void;
    hideNotification: () => void;
}

const ReconnectNotification: FC<IProps> = (props) => {
    const {onClick, hideNotification} = props;
    const iotProduct = useSelector(makeSelectIotDeviceMergedWithIccProduct());
    const {device} = iotProduct || {};
    const {name = '', type} = device || {};

    useEffect(() => {
        if (!name) {
            hideNotification();
        }
    }, [name]);

    const reconnectDeviceGuideData = getReconnectDeviceGuideData(type);
    const localizedStrings = getLocalizedStrings();
    const notificationText = localizedStrings.formatString(
        localizedStrings[localizationKeys.DEVICE_LOST_CONNECTION_NOTIFICATION_PART_1],
        name
    );
    const buttonText = localizedStrings[localizationKeys.DEVICE_LOST_CONNECTION_NOTIFICATION_PART_2];

    const onButtonClick = () => {
        onClick();
        dispatch(showReconnectDeviceGuideModalAction());
    };

    return (
        <>
            {notificationText}
            {reconnectDeviceGuideData && (
                <Button className={cn(styles.NotificationText, 'ica--txt-regular')} onClick={onButtonClick}>
                    {buttonText}
                </Button>
            )}
        </>
    );
};

export default ReconnectNotification;
