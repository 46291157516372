export const authMapping = (responseData) => {
    const RESPONSE_DELAY_MS = 10 * 1000;
    const MS_IN_SECOND = 1000;
    const expirationDate = Date.now() + responseData.expires_in * MS_IN_SECOND - RESPONSE_DELAY_MS;

    return {
        accessToken: responseData.access_token,
        refreshToken: responseData.refresh_token,
        expirationDate,
    };
};

export const authUamMapping = (responseData) => {
    return {
        authToken: responseData.AuthToken,
        identityKey: responseData.IdentityKey,
        uamUrl: responseData.UamUrl,
        uamApiKey: responseData.UamApiKey,
    };
};

export const loginTokenMapping = (responseData) => {
    return {
        campaignUrlPersonal: responseData.CampaignUrlPersonal,
    };
};
