import stringFormatService from '../../services/stringFormatService';
import stringUtils from '../../utils/stringUtils';
import {config} from '../config';
import configGettersService from './configGettersService';

const getConnectionCareUrl = () =>
    configGettersService.getLocalizedPropertyOrDefault(config.data.externalLinks.connectingCareUrl);
const getDcsRegisterUrl = () =>
    configGettersService.getLocalizedPropertyOrDefault(config.data.externalLinks.dcsRegisterUrl);
const getDcsSupportUrl = () =>
    configGettersService.getLocalizedPropertyOrDefault(config.data.externalLinks.dcsSupportUrl);
const getIqosHomeUrl = () => configGettersService.getLocalizedPropertyOrDefault(config.data.externalLinks.iqosHomeUrl);
const getIqosHavUrl = () => configGettersService.getLocalizedPropertyOrDefault(config.data.externalLinks.iqosHavUrl);
const getIqosStoreUrl = () =>
    configGettersService.getLocalizedPropertyOrDefault(config.data.externalLinks.iqosStoreUrl);
const getLearnMoreUrl = () =>
    configGettersService.getLocalizedPropertyOrDefault(config.data.externalLinks.learnMoreUrl);
const getLoginUrl = () => configGettersService.getLocalizedPropertyOrDefault(config.data.externalLinks.loginUrl);
const getProductUnregistrationUrl = () =>
    configGettersService.getLocalizedPropertyOrDefault(config.data.externalLinks.productUnregistrationUrl);

export default {
    getDcsRegisterUrlFormatted: () => stringFormatService.getLanguageFormattedString(getDcsRegisterUrl()),
    getDcsSupportUrlFormatted: () => stringFormatService.getLanguageFormattedString(getDcsSupportUrl()),
    getIqosHomeUrlFormatted: () => stringFormatService.getLanguageFormattedString(getIqosHomeUrl()),
    getIqosHavUrlFormatted: () => stringFormatService.getLanguageFormattedString(getIqosHavUrl()),
    getIqosStoreUrlFormatted: () => stringFormatService.getLanguageFormattedString(getIqosStoreUrl()),
    getLearnMoreUrlFormatted: () => stringFormatService.getLanguageFormattedString(getLearnMoreUrl()),
    getLoginUrlFormatted: () => stringFormatService.getLanguageFormattedString(getLoginUrl()),
    getProductUnregistrationUrlFormatted: () =>
        stringFormatService.getLanguageFormattedString(getProductUnregistrationUrl()),
    getConnectingCareUrlFormatted: (urlCodentify, l1Code) => {
        const url = getConnectionCareUrl();
        const urlWithCodentify = stringUtils.formatString(url, urlCodentify, l1Code);
        const urlFormatted = stringFormatService.getLanguageFormattedString(urlWithCodentify);

        return urlFormatted;
    },
};
