import * as loaderNames from '../consts/loader/loaderNames';
import {hideLoader, showLoader} from '../state/ducks/global';
import {dispatch} from '../state/store';
import helpers from '../utils/helpers';

let instance = null;

export default class PairingHandlerService {
    constructor() {
        if (instance) {
            return instance;
        }

        instance = this;
        this.handler = null;
    }

    setPairingHandler(handler) {
        this.handler = handler;
    }

    removePairingHandler() {
        this.pairingIsStopped();
        this.handler = null;
    }

    pairingIsStarted() {
        if (this.handler) {
            helpers.runFunction(this.handler, {isStart: true});
        } else {
            dispatch(showLoader(loaderNames.PAIRING_IN_PROGRESS_LOADER));
        }
    }

    pairingIsStopped() {
        dispatch(hideLoader(loaderNames.PAIRING_IN_PROGRESS_LOADER));
        if (this.handler) {
            helpers.runFunction(this.handler, {isStart: false});
        }
    }
}
