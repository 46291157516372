import {useLayoutEffect} from 'react';

import domService from '../services/domService';

const useHidePage = () => {
    useLayoutEffect(() => {
        // IA: changed from hiding all .page elements to
        // only current .page element on the page
        // This fix prevents some layout bugs
        // (e.g. after hiding of SkipPopup, devices on MyDevicesPage are twitched)
        const page = domService.getElementBySelector('.page');

        if (page) {
            const hidePageClass = 'page-hide';

            page.classList.add(hidePageClass);

            return () => {
                page.classList.remove(hidePageClass);
            };
        }
    }, []);
};

export default useHidePage;
