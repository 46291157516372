import cn from 'classnames';
import React, {FC} from 'react';

import {getLocalizedStrings} from '../../services/localization/localizationService';
import Container from '../Layout/Container/Container';
import styles from './GuideSliderItem.module.scss';

interface IProps {
    header: string;
    description?: string;
    subTitle?: string;
    element: React.ReactNode;
}

const GuideSliderItem: FC<IProps> = (props) => {
    const {header, description, subTitle, element} = props;
    const subtitleClassName = cn(styles.SubTitle, 'txt--11');
    const descriptionClassName = cn(styles.Description, 'ica--txt-regular ica--txt-regular-fixed');
    const localizedStrings = getLocalizedStrings();
    const headerHtml = localizedStrings[header];
    const descriptionHtml = description ? localizedStrings[description] : null;

    return (
        <div className={styles.GuideItem}>
            <Container className={styles.Container}>
                <div className={styles.BackgroundContainer}>
                    <div className={styles.MediaContainer}>{element}</div>
                    <div className={styles.TextContainer}>
                        <span className={subtitleClassName}>{subTitle}</span>
                        <h1 className={styles.Title} dangerouslySetInnerHTML={{__html: headerHtml}} />
                        <div className={descriptionClassName} dangerouslySetInnerHTML={{__html: descriptionHtml}} />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default GuideSliderItem;
