export const IOT_DEVICE_CLEAR = 'app/iotDevice/IOT_DEVICE_CLEAR';
export const IOT_DEVICE_CLEAR_DATA = 'app/iotDevice/IOT_DEVICE_CLEAR_DATA';
export const IOT_DEVICE_CLEAR_HOLDER_DATA = 'app/iotDevice/IOT_DEVICE_CLEAR_HOLDER_DATA';
export const IOT_DEVICE_SET_DATA_FACTORY = 'app/iotDevice/IOT_DEVICE_SET_DATA_FACTORY';
export const IOT_DEVICE_SET_DEVICE_ACTIVATION_STATUS = 'app/iotDevice/IOT_DEVICE_SET_DEVICE_ACTIVATION_STATUS';
export const IOT_DEVICE_SET_DEVICE_CONNECTION_STATUS = 'app/iotDevice/IOT_DEVICE_SET_DEVICE_CONNECTION_STATUS';
export const IOT_DEVICE_SET_FIRMWARE_DATA = 'app/iotDevice/IOT_DEVICE_SET_FIRMWARE_DATA';
export const IOT_DEVICE_SET_GET_FEATURE_KEYS_LIST = 'app/iotDevice/SET_GET_FEATURE_KEYS_LIST';
export const IOT_DEVICE_SET_IS_PAIRING_IN_PROGRESS = 'app/iotDevice/IOT_DEVICE_SET_IS_PAIRING_IN_PROGRESS';
export const IOT_DEVICE_SET_IS_RESET = 'app/iotDevice/IOT_DEVICE_SET_IS_RESET';
export const IOT_DEVICE_SET_IS_UPDATE_SETTINGS_NEEDED = 'app/iotDevice/IOT_DEVICE_SET_IS_UPDATE_SETTINGS_NEEDED';
export const IOT_DEVICE_SET_IS_VIBRATION_ALARM_ACTIVE = 'app/iotDevice/IOT_DEVICE_SET_IS_VIBRATION_ALARM_ACTIVE';
export const IOT_DEVICE_UPDATE_DATA = 'app/iotDevice/IOT_DEVICE_UPDATE_DATA';
export const IOT_DEVICE_SET_IS_RECONNECTED = 'app/iotDevice/IOT_DEVICE_SET_IS_RECONNECTED';
export const IOT_DEVICE_SET_IS_AVAILABLE_FWU_MESSAGE_CLOSED =
    'app/iotDevice/IOT_DEVICE_SET_IS_AVAILABLE_FWU_MESSAGE_CLOSED';
