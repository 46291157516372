import React from 'react';

import Icon from './Icon';

const IconGear = (props) => {
    return (
        <Icon width='32' height='32' viewBox='0 0 32 32' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g strokeWidth='1.5'>
                    <g transform='translate(-610.000000, -374.000000) translate(72.000000, 354.000000) translate(496.000000, 20.000000) translate(42.000000, 0.000000) translate(1.000000, 1.000000)'>
                        <circle cx='14.521' cy='14.521' r='13.625' />
                        <path strokeLinecap='round' strokeLinejoin='round' d='M24.413 24.413L28.832 28.832' />
                    </g>
                    <g transform='translate(-610.000000, -374.000000) translate(72.000000, 354.000000) translate(496.000000, 20.000000) translate(42.000000, 0.000000) translate(1.000000, 1.000000) translate(6.400000, 6.400000)'>
                        <path d='M9.593.75l.488 2.31c.412.175.799.4 1.153.665h0l1.928-.847 1.988 2.998-2.063 1.804L15.038 10l-1.549 3.25-2.246-.732c-.353.266-.74.491-1.152.667h0l-.23 2.092-3.59.223-.488-2.31c-.412-.176-.798-.4-1.151-.665h0l-1.929.847-1.988-2.998L2.778 8.57.827 6.25 2.376 3l2.245.733c.353-.267.74-.492 1.151-.668h0L6.003.973 9.593.75z' />
                        <circle cx='7.938' cy='8.125' r='1.75' />
                    </g>
                </g>
            </g>
        </Icon>
    );
};

export default IconGear;
