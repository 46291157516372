import cn from 'classnames';
import React, {FC} from 'react';

import AppLoaderMedia from '../../../../Loader/AppLoader/AppLoaderMedia';
import styles from '../Loader/Loader.module.scss';

interface IProps {
    imgClassName: string;
    isLoader: boolean;
}

const Loader: FC<IProps> = ({imgClassName, isLoader}) => (
    <div className={cn(styles.LoaderWrapper, imgClassName)}>{isLoader && <AppLoaderMedia />}</div>
);

export default Loader;
