import firstSliderStep2 from '../../../../../../assets/images/devices/FWUPopup/firstSliderStep@2x.jpg';
import firstSliderStep3 from '../../../../../../assets/images/devices/FWUPopup/firstSliderStep@3x.jpg';
import fourthSliderStep2 from '../../../../../../assets/images/devices/FWUPopup/fourthSliderStep@2x.jpg';
import fourthSliderStep3 from '../../../../../../assets/images/devices/FWUPopup/fourthSliderStep@3x.jpg';
import secondSliderStep2 from '../../../../../../assets/images/devices/FWUPopup/secondSliderStep@2x.jpg';
import secondSliderStep3 from '../../../../../../assets/images/devices/FWUPopup/secondSliderStep@3x.jpg';
import thirdSliderStep2 from '../../../../../../assets/images/devices/FWUPopup/thirdSliderStep@2x.jpg';
import thirdSliderStep3 from '../../../../../../assets/images/devices/FWUPopup/thirdSliderStep@3x.jpg';

export const fwuAdditionalStepsGuideData = [
    {
        images: [firstSliderStep2, firstSliderStep3],
        header: 'IQOS_DUO_STEP2_TITLE',
    },
    {
        images: [secondSliderStep2, secondSliderStep3],
        header: 'IQOS_DUO_STEP3_TITLE',
    },
    {
        images: [thirdSliderStep2, thirdSliderStep3],
        header: 'IQOS_DUO_STEP4_TITLE',
    },
    {
        images: [fourthSliderStep2, fourthSliderStep3],
        header: 'IQOS_DUO_STEP5_TITLE',
    },
];
