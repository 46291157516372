import * as iotMessageErrorCodes from '../../consts/iot/iotMessageErrorCodes';
import * as iotMessageStatusTypes from '../../consts/iot/iotMessageStatusTypes';
import * as iotMessageTypes from '../../consts/iot/iotMessageTypes';
import {showDeviceIsInSblModalAction} from '../../state/ducks/modal';
import {clearMwDevice, setMwDeviceFirmwareData, setMwDeviceInternalId} from '../../state/ducks/mwDevice';
import {
    makeSelectIsDeviceReady,
    selectIsFirmwareInProgress,
    selectIsFirmwareTargetDevice,
} from '../../state/selectors/iotDevice';
import {selectMwDeviceIsIdentified} from '../../state/selectors/mwDevice';
import {dispatch, getState} from '../../state/store';
import DeviceCharacteristicClient from '../ble/deviceCharacteristicClient';
import devicePingService from '../ble/devicePingService';
import fwuService from '../ble/fwuService';
import connectivityService from '../device/connectivityService';
import framesProcessingService from '../device/framesProcessingService';
import fwuTargetService from '../device/fwuTargetService';
import {mwResponseBaseMapping} from '../mapping/iotMappings';
import storageService from '../storage/storageService';
import mwIotMessageRequestService from './mwIotMessageRequestService';
import scpCloudService from './scpCloudService';
import uiIotMessageResponseChecker from './uiIotMessageResponseChecker';

const onIotMessage = (topic, message) => {
    const isIdentified = selectMwDeviceIsIdentified(getState());
    const {type, status, body, request_id} = message;
    const frames = body?.frames;
    const transferData = body?.transfer_data;
    const data = mwResponseBaseMapping(body?.data);
    const deviceSerialNumber = data?.deviceSerialNumber;
    const isIdentificationFlow = !isIdentified && deviceSerialNumber;
    const isPingMessage = type === iotMessageTypes.DEVICE_PING;

    if (request_id) {
        const isRequestExists = uiIotMessageResponseChecker.some(request_id);

        // If request doesn't exist -> skip processing.
        // Check uiIotMessageResponseService.onMessage() for details.

        if (!isRequestExists) {
            return;
        }
    }

    if (isIdentificationFlow) {
        onIdentifyDevice(topic, deviceSerialNumber);
    }

    switch (type) {
        case iotMessageTypes.CONNECTION:
            onConnectionMessage(topic, status);
            break;
        case iotMessageTypes.FIRMWARE_UPDATE:
            onFWUMessage(message);
            break;
        case iotMessageTypes.DEVICE_PING:
            onDevicePingMessage(message);
            break;
        default:
            break;
    }

    const isFwuFinished = type === iotMessageTypes.FIRMWARE_UPDATE && status === iotMessageStatusTypes.FINISHED;
    const isRestartFlow = type === iotMessageTypes.RESTART;
    const isResetFrames = frames?.length && (isFwuFinished || isRestartFlow);
    const RESET_TIMEOUT = 3000;
    const timeout = isResetFrames ? RESET_TIMEOUT : 0;
    const processFramesProps = {
        frames,
        processImmediately: !isIdentificationFlow,
        request_id,
        status,
        timeout,
        type,
        onSuccess: (...responses) => {
            mwIotMessageRequestService.publishResponseEvent({
                status,
                type,
                request_id,
                frames: responses,
                transfer_data: transferData,
            });
        },
        onError: (isDeviceDisconnect) => {
            if (isDeviceDisconnect) return;

            mwIotMessageRequestService.publishErrorEvent({
                type,
                request_id,
                errorCode: iotMessageErrorCodes.IO_ERROR_IO_GENERAL,
                transfer_data: transferData,
            });
        },
    };

    if (frames?.length) {
        if (isFwuFinished) {
            const isFwuTargetDevice = selectIsFirmwareTargetDevice(getState());

            if (isFwuTargetDevice) {
                //Charger resets automatically after fwu.
                //Reset frames should be processed after DEVICE_INFO - FINISHED message.
                framesProcessingService.setResetFramesData(processFramesProps);
                return;
            }
        }
        if (!isPingMessage) {
            framesProcessingService.processFrames(processFramesProps);
        }
    }
};

const onConnectionMessage = (topic, status) => {
    switch (status) {
        case iotMessageStatusTypes.DISCONNECTED:
            scpCloudService.unsubscribeFromTopic(topic);
            dispatch(clearMwDevice());
            break;
        default:
            break;
    }
};

const onFWUMessage = ({status, body}) => {
    const {data} = body;

    switch (status) {
        case iotMessageStatusTypes.GET_PACKAGE:
            const fwuTarget = fwuTargetService.getFwuTargetByIotTarget(data);

            if (fwuTarget) {
                dispatch(setMwDeviceFirmwareData(data));
                fwuService.clearPackageData();
                fwuService.downloadFwuPackage(fwuTarget);
            }

            break;
        case iotMessageStatusTypes.STARTED:
            const {fwu_target, package_index} = data || {};
            const isUsb = connectivityService.isUsb();

            if (isUsb) {
                const state = getState();
                const isDeviceReady = makeSelectIsDeviceReady()(state);
                const isFirmwareInProgress = selectIsFirmwareInProgress(state);
                const isInitialConnection = !isDeviceReady && !isFirmwareInProgress;

                if (isInitialConnection) {
                    dispatch(showDeviceIsInSblModalAction({fwu_target}));
                    return;
                }
            }

            fwuService.startFwUpdate({fwu_target, package_index});

            break;
        case iotMessageStatusTypes.FINISHED:
            framesProcessingService.restart();
            break;
        default:
            break;
    }
};

const onDevicePingMessage = ({body}) => {
    const frames = body?.frames;

    devicePingService.startDevicePing(frames);
};

const onIdentifyDevice = (topic, deviceSerialNumber) => {
    subscribeOnIdentifiedId(topic, deviceSerialNumber);

    if (connectivityService.isBle()) {
        new DeviceCharacteristicClient(true);
    }
};

const subscribeOnIdentifiedId = (topic, deviceSerialNumber) => {
    scpCloudService.unsubscribeFromTopic(topic);
    dispatch(setMwDeviceInternalId({deviceSerialNumber, isIdentified: true}));

    const storageDeviceData = storageService.getDeviceDataFromStorage();

    if (storageDeviceData) {
        const storagePreviousInternalId = storageDeviceData.deviceSerialNumber;

        if (deviceSerialNumber !== storagePreviousInternalId) {
            storageService.removeDeviceDataFromStorage();
        }
    }
};

export default {
    onIotMessage,
};
