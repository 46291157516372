import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import BackButton from '../../../components/BackButton/BackButton';
import Container from '../../../components/Layout/Container/Container';
import PopupFullPage from '../../../components/Popup/PopupFullPage/PopupFullPage';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import * as routePaths from '../../../consts/route/routePaths';
import * as tncTypes from '../../../consts/tnc/tncTypes';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import tncService from '../../../services/tncService';
import {removeLastTncPopupCode} from '../../../state/ducks/tnc';
import {makeSelectTncLegals} from '../../../state/selectors/consumer';
import {makeSelectTncPopupCodesList} from '../../../state/selectors/tnc';
import elementsTesIds from '../../../test/consts/elementsTesIds';
import TermsOfUseDescription from '../TermsOfUseDescription/TermsOfUseDescription';
import styles from '../TermsOfUsePage/TermsOfUsePage.module.scss';

const mapStateToProps = createStructuredSelector({
    legals: makeSelectTncLegals(),
    tncPopupCodesList: makeSelectTncPopupCodesList(),
});

const TermsOfUsePopup = (props) => {
    const {dispatch, tncPopupCodesList, legals, matchProps} = props;
    const {pathname} = matchProps.location;

    const tncPopupCode = tncPopupCodesList.slice(-1)[0];
    let legalData = tncService.getTncLegalByTermsCode(legals, tncPopupCode);

    if (!legalData) {
        dispatch(removeLastTncPopupCode());
        legalData = {};
    }
    const {title, text} = legalData;

    const onClose = () => {
        dispatch(removeLastTncPopupCode());
    };

    const [backBtnAriaLabel, titleKey] = getPathRelatedData(pathname, tncPopupCode);

    useDocumentTitle(titleKey, true);

    return (
        <PopupFullPage
            testId={elementsTesIds.LEGAL_AND_POLICY_POPUP}
            containerClassName={styles.Popup}
            key={tncPopupCode}
        >
            <Container isFloatHeight>
                <BackButton
                    ariaLabel={backBtnAriaLabel}
                    onClick={onClose}
                    testId={elementsTesIds.LEGAL_AND_POLICY_BTN_BACK}
                />
            </Container>
            <section className={styles.PopupContent}>
                <section>
                    <h1 className={styles.LineTitle}>{title}</h1>
                </section>
                <section>
                    <TermsOfUseDescription innerHtml={text} className={styles.DescriptionContainer} />
                </section>
            </section>
        </PopupFullPage>
    );
};

export default connect(mapStateToProps)(TermsOfUsePopup);

const getPathRelatedData = (pathname, tncPopUpCode) => {
    const localizedStrings = getLocalizedStrings();

    if (routePaths.DEVICE_SETTINGS === pathname && tncPopUpCode === tncTypes.WEB_TERMS_OF_USE) {
        return [
            localizedStrings[localizationKeys.LEGAL_AND_POLICY_PAGE_BTN_BACK_ARIA_LABEL],
            localizedStrings[localizationKeys.LEGAL_AND_POLICY_PAGE_TITLE],
        ];
    } else if (routePaths.TNC_TERMS_OF_USE === pathname && tncPopUpCode === tncTypes.WEB_TERMS_OF_USE) {
        return [
            localizedStrings[localizationKeys.TERMS_OF_USE_PAGE_BTN_USER_RETURN_TCP_ARIA_LABEL],
            localizedStrings[localizationKeys.TERMS_OF_USE_PAGE_TITLE],
        ];
    } else if (routePaths.TNC_TERMS_OF_USE === pathname && tncPopUpCode === tncTypes.WEB_A_NOTICES) {
        return [
            localizedStrings[localizationKeys.TERMS_OF_USE_PAGE_BTN_USER_RETURN_TUD_ARIA_LABEL],
            localizedStrings[localizationKeys.TERMS_OF_USE_PAGE_TITLE],
        ];
    } else if (routePaths.TNC_DATA_COLLECTION === pathname && tncPopUpCode === tncTypes.WEB_INFORMATIONDATA) {
        return [
            localizedStrings[localizationKeys.DATA_COLLECTION_PAGE_BTN_RETURNS_USER_ARIA_LABEL],
            localizedStrings[localizationKeys.DATA_COLLECTION_PAGE_TITLE],
        ];
    } else {
        return [null, null];
    }
};
