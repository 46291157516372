export const validateTokenMapping = (response) => {
    const {cloudwatch_settings} = response;

    const data = {
        accessKeyId: response.access_key_id,
        dusn: response.connected_device_id,
        secretAccessKey: response.secret_access_key,
        sessionToken: response.session_token,
    };

    if (cloudwatch_settings) {
        const {stream_name, group_name, sequence_token} = cloudwatch_settings;

        data.cloudWatchSettings = {
            streamName: stream_name,
            groupName: group_name,
            sequenceToken: sequence_token,
        };
    }

    return data;
};
