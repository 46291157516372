import cn from 'classnames';
import React, {FC, forwardRef, ReactNode, useEffect, useImperativeHandle, useState} from 'react';

import userDeviceService from '../../services/user/userDeviceService';
import elementsTesIds from '../../test/consts/elementsTesIds';
import helpers from '../../utils/helpers';
import AppPopup from './AppPopup';
import PopupCloseButton from './components/PopupCloseButton/PopupCloseButton';
import styles from './Popup.module.scss';

interface IProps {
    children?: Array<ReactNode>;
    className?: string;
    containerClassName?: string;
    onClose?: () => void;
    isClosingBySwipeEnabled?: boolean;
    testId?: string;
    closeButtonAriaLabel?: string;
    overlayClassName?: string;
    ref?: any;
    isCloseByOverlay?: boolean;
}

// eslint-disable-next-line react/display-name
const Popup: FC<IProps> = forwardRef((props, popupRef) => {
    const {
        children,
        className,
        containerClassName,
        onClose,
        isClosingBySwipeEnabled,
        testId,
        closeButtonAriaLabel,
        overlayClassName,
        isCloseByOverlay = true,
        ...restProps
    } = props;
    const [isPopupClosing, setIsPopupClosing] = useState(false);

    const SWIPE_DELTA_TO_START_CLOSING = 30;
    const isTouchSupported = userDeviceService.isTouchSupported();
    const isClosingByTouch = isTouchSupported && isClosingBySwipeEnabled;
    const overlayClassNames = cn(styles.Overlay, overlayClassName, {
        [styles.OverlayOnPopupClose]: isPopupClosing,
    });
    const isCloseFunction = typeof onClose === 'function';
    const timeoutRef: any = React.useRef();
    let touchYStart = 0;
    let touchYEnd = 0;

    const popupClassName = cn(styles.Popup, className, {[styles.PopupTouchCloseLine]: isClosingByTouch});
    const popupContainerClassName = cn(styles.PopupContainer, containerClassName, {
        [styles.PopupOnClose]: isPopupClosing,
    });

    useEffect(() => () => clearTimeout(timeoutRef.current), []);

    const onTouchStart = (e: any) => {
        if (isClosingByTouch) {
            touchYStart = e.changedTouches[0].pageY;
        }
    };

    const onTouchMove = (e: any) => {
        if (isClosingByTouch) {
            touchYEnd = e.changedTouches[0].pageY;

            const delta = touchYEnd - touchYStart;

            if (delta >= SWIPE_DELTA_TO_START_CLOSING && onClose) {
                onPopupClose();
            }
        }
    };

    const onPopupClose = (action?: any) => {
        if (isCloseFunction && !timeoutRef.current) {
            setIsPopupClosing(true);
            timeoutRef.current = setTimeout(() => {
                helpers.runFunction(onClose);

                if (action) {
                    helpers.runFunction(action);
                }
            }, 700);
        }
    };

    const helpButtonStyle = `
       @media only screen and (max-width: 420px) {
            .helpButton{
                display: none !important;
            }
        }
`;

    useImperativeHandle(popupRef, () => ({
        onPopupCloseWithAnimation: (action?: () => void) => onPopupClose(action),
    }));

    // set aria-hidden=true during popup close animation
    // this prevents reading of status/alert(that are a part of popup content) by screen reader after popup close
    const popupContentAriaHidden = isPopupClosing ? 'true' : undefined;

    return (
        <AppPopup
            {...restProps}
            isOpen
            onRequestClose={onPopupClose}
            className={popupContainerClassName}
            overlayClassName={overlayClassNames}
            shouldCloseOnOverlayClick={isCloseFunction && isCloseByOverlay}
            shouldCloseOnEsc={isCloseFunction}
        >
            <style>{helpButtonStyle}</style>
            <div
                className={styles.PopupTopMargin}
                data-testid={elementsTesIds.POPUP_COMMON_BTN_CLOSE}
                onClick={isCloseByOverlay ? onPopupClose : undefined}
            />
            <div
                className={popupClassName}
                data-testid={testId}
                onTouchMove={(e) => onTouchMove(e)}
                onTouchStart={(e) => onTouchStart(e)}
                aria-hidden={popupContentAriaHidden}
            >
                {isCloseFunction && <PopupCloseButton ariaLabel={closeButtonAriaLabel} onClick={onPopupClose} />}
                {children}
            </div>
        </AppPopup>
    );
});

export default Popup;
