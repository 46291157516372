import cn from 'classnames';
import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect/lib/index';

import domService from '../../../services/domService';
import tncService from '../../../services/tncService';
import {setTncPopupCode} from '../../../state/ducks/tnc';
import {makeSelectTncLegals} from '../../../state/selectors/consumer';
import styles from './TermsOfUseDescription.module.css';

const mapStateToProps = createStructuredSelector({
    legals: makeSelectTncLegals(),
});

const TermsOfUseDescription = (props) => {
    const {dispatch, innerHtml, className, legals, isDarkBackground} = props;

    const textClassName = cn(className, 'ica--txt-regular');

    const onReadButtonClick = (e) => {
        const {target} = e;
        const tncCode = tncService.getTncCodeFromTarget(target, styles.TncLink);

        if (tncCode) {
            dispatch(setTncPopupCode(tncCode));
        }
    };

    const tncLinkClass = cn(styles.TncLink, isDarkBackground ? styles.BrightTncLink : styles.DarkTncLink);
    const textParsed = tncService.getParsedDescription(innerHtml, tncLinkClass, legals);

    const onKeyPress = (e) => domService.handleBtnKeyPress(e, onReadButtonClick);

    return (
        <p
            className={textClassName}
            dangerouslySetInnerHTML={{__html: textParsed}}
            onClick={onReadButtonClick}
            onKeyPress={onKeyPress}
        />
    );
};

export default connect(mapStateToProps)(TermsOfUseDescription);
