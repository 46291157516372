import {RECHECK_DELAY_IN_MILISECONDS} from '../../consts/app/dateTimeKeys';
import {FW_GENERAL} from '../../consts/iot/iotMessageErrorCodes';
import * as iotMessageStatusTypes from '../../consts/iot/iotMessageStatusTypes';
import * as iotMessageTypes from '../../consts/iot/iotMessageTypes';
import * as iotParentProtectionStatus from '../../consts/iot/iotParentProtectionStatus';
import {setCurrentFlowType, setMessage} from '../../state/ducks/iot';
import {clearDeviceConnectionCareState, updateIotDeviceData} from '../../state/ducks/iotDevice/actions';
import {dispatch} from '../../state/store';
import fwuTargetService from '../device/fwuTargetService';
import ErrorHandlerService from '../errorHandlerService';
import iotDeviceFWUStateService from '../iotDevice/iotDeviceFWUStateService';
import log from '../logger/log';
import storageService from '../storage/storageService';
import uamClientService from '../uam/uamClientService';
import scpCloudService from './scpCloudService';
import uiIotMessageResponseChecker from './uiIotMessageResponseChecker';

let requestId = 0;

const getRetRequestId = () => {
    requestId++;

    return requestId;
};

const publishConnectionCareMessage = () => {
    const message = {
        type: iotMessageTypes.CONNECTION_CARE,
        status: iotMessageStatusTypes.STARTED,
    };

    const diagnosticData = storageService.getDeviceDataFromStorage();

    if (diagnosticData) {
        const diagnosticTimeDelta = Date.now() - diagnosticData.diagnosticTime;

        if (diagnosticTimeDelta < RECHECK_DELAY_IN_MILISECONDS) {
            message.body = {
                data: {
                    recheck: 'true',
                },
            };
        }
    }

    publishUiMessage(message);
    dispatch(clearDeviceConnectionCareState());
};

const publishVibrationAlarmMessage = () => {
    const message = {
        type: iotMessageTypes.VIBRATION_ALARM,
    };

    publishUiMessage(message);
};

const publishStopVibrationAlarmMessage = () => {
    const message = {
        type: iotMessageTypes.VIBRATION_ALARM,
        status: iotMessageStatusTypes.FINISHED,
    };

    publishUiMessage(message);
};

const publishParentProtectionEnableMessage = () => {
    const message = {
        type: iotMessageTypes.PARENT_PROTECTION,
        body: {
            data: {
                device: {
                    parent_protection_mode: iotParentProtectionStatus.PARENT_PROTECTION_ENABLED,
                },
            },
        },
    };

    publishUiMessage(message);
};

const publishParentProtectionDisableMessage = () => {
    const message = {
        type: iotMessageTypes.PARENT_PROTECTION,
        body: {
            data: {
                device: {
                    parent_protection_mode: iotParentProtectionStatus.PARENT_PROTECTION_DISABLED,
                },
            },
        },
    };

    publishUiMessage(message);
};

const publishParentProtectionStatusMessage = () => {
    const message = {
        type: iotMessageTypes.PARENT_PROTECTION,
    };

    publishUiMessage(message);
};

const publishResetMessage = () => {
    const message = {
        type: iotMessageTypes.RESET,
    };

    publishUiMessage(message);
};

const publishCheckFirmwareMessage = async (fwu_target) => {
    const message = {
        type: iotMessageTypes.FIRMWARE_UPDATE,
        status: iotMessageStatusTypes.CHECK,
        body: {
            data: {
                fwu_target,
            },
        },
    };

    iotDeviceFWUStateService.setIotTargetFwuStateOnFwuCheckRequest(fwu_target);

    publishUiMessage(message);
    return true;
};

const publishInitiateUpdateFirmwareMessage = async (fwu_target) => {
    const isSyncronizeDataStopped = await uamClientService.waitUntilDataCollectionCanceled();

    if (!isSyncronizeDataStopped) {
        new ErrorHandlerService().raiseError(FW_GENERAL);
        return;
    }

    const message = {
        type: iotMessageTypes.FIRMWARE_UPDATE,
        status: iotMessageStatusTypes.INITIATE,
        body: {
            data: {
                fwu_target,
            },
        },
    };
    const iotTargetName = fwuTargetService.getIotTargetNameByFwuTarget(fwu_target);
    const newState = {[iotTargetName]: {firmware: {progress: 0}}};

    publishUiMessage(message, false);
    dispatch(updateIotDeviceData(newState));
};

const publishGetLedSettingsMessage = () => publishLedSettingsMessage();

const publishGetVibrationSettingsMessage = () => publishVibrationSettingsMessage();

const publishGetResponsiveDrawSettingsMessage = () => publishResponsiveDrawSettingsMessage();

const publishGetVapeCloudSizeSettingsMessage = () => publishVapeCloudSizeSettingsMessage();

const publishGetAutoStartSettingsMessage = () => publishAutoStartSettingsMessage();

const publishGetGestureSettingsMessage = () => publishGesturetSettingsMessage();

const publishGetCountdownSettingsMessage = () => publishCountdownSettingsMessage();

const publishSetLedSettingsMessage = (modeType, isDeviceLedModeEnabled, isHolderLedModeEnabled) => {
    const ledData = {};

    if (isDeviceLedModeEnabled) {
        ledData.device = {intensity: modeType};
    }

    if (isHolderLedModeEnabled) {
        ledData.holder = {intensity: modeType};
    }

    if (ledData) {
        publishLedSettingsMessage(ledData);
    }
};

const publishSetResponsiveDrawSettingsMessage = (modeType) => {
    const data = {
        responsive_draw_profile: modeType,
    };

    publishResponsiveDrawSettingsMessage(data);
};

const publishSetVapeCloudSizeSettingsMessage = (modeType) => {
    const data = {
        vape_cloud_size: modeType,
    };

    publishVapeCloudSizeSettingsMessage(data);
};

const publishSetVibrationSettingsMessage = (vibrationData) => {
    publishVibrationSettingsMessage(vibrationData);
};

const publishSetAutoStartSettingsMessage = (autoStartData) => publishAutoStartSettingsMessage(autoStartData);

const publishSetGestureSettingsMessage = (gestureData) => publishGesturetSettingsMessage(gestureData);

const publishSetCountdownSettingsMessage = (countdownData) => publishCountdownSettingsMessage(countdownData);

const publishResetVibrationSettingsMessage = () => {
    const data = 'DEFAULT';

    publishVibrationSettingsMessage(data);
};

const publishResponsiveDrawSettingsMessage = (data) =>
    publishSettingsMessage(iotMessageTypes.RESPONSIVE_DRAW_PROFILES, data);

const publishVapeCloudSizeSettingsMessage = (data) => publishSettingsMessage(iotMessageTypes.VAPE_CLOUD_SIZE, data);

const publishLedSettingsMessage = (data) => publishSettingsMessage(iotMessageTypes.LED, data);

const publishVibrationSettingsMessage = (data) => publishSettingsMessage(iotMessageTypes.VIBRATION, data);

const publishAutoStartSettingsMessage = (data) => publishSettingsMessage(iotMessageTypes.AUTO_START, data);

const publishGesturetSettingsMessage = (data) => publishSettingsMessage(iotMessageTypes.GESTURE_MODE, data);

const publishCountdownSettingsMessage = (data) => publishSettingsMessage(iotMessageTypes.COUNTDOWN_MODE, data);

const publishSettingsMessage = (type, data) => {
    const message = {
        type,
    };

    if (data) {
        message.body = {data};
    }

    publishUiMessage(message);
};

const publishCleaningMessage = () => {
    const message = {
        type: iotMessageTypes.CLEANING,
        status: iotMessageStatusTypes.STARTED,
    };

    publishUiMessage(message);
};

const publishHolderStatusMessage = () => {
    const message = {
        type: iotMessageTypes.HOLDER_STATUS,
        status: iotMessageStatusTypes.STARTED,
    };

    publishUiMessage(message);
};

const publishDeviceStatusCharacteristicUsbMessage = () => {
    const message = {
        type: iotMessageTypes.DEVICE_STATUS_CHARACTERISTIC_USB,
        status: iotMessageStatusTypes.STARTED,
    };

    publishUiMessage(message);
};

const publishDevicePingMessage = () => {
    const message = {
        type: iotMessageTypes.DEVICE_PING,
    };

    publishUiMessage(message, false);
};

const publishDeviceRestartMessage = () => {
    const message = {
        type: iotMessageTypes.RESTART,
        status: iotMessageStatusTypes.STARTED,
    };

    publishUiMessage(message);
};

const publishUiMessage = async (message, isRequestIdNeeded = true) => {
    let request_id;

    if (isRequestIdNeeded) {
        request_id = getRetRequestId();

        message.request_id = request_id;
    }

    dispatch(setMessage(message));

    log.info(`uiIotMessageRequestService: publish UI message, message: ${JSON.stringify(message)}`);

    setCurrentFlow(message.type, message.status);

    try {
        await scpCloudService.publishUiMessage(message);

        if (isRequestIdNeeded) {
            uiIotMessageResponseChecker.setRequest(request_id);
        }
    } catch (e) {
        log.info(`uiIotMessageRequestService: publish UI message error: ${e}`);
        uiIotMessageResponseChecker.onRequestMessageError();
    }
};

const setCurrentFlow = (type, status) => {
    if (status === iotMessageStatusTypes.STARTED && type === iotMessageTypes.CLEANING) {
        dispatch(setCurrentFlowType(type));
    }
};

export default {
    publishCheckFirmwareMessage,
    publishCleaningMessage,
    publishConnectionCareMessage,
    publishDevicePingMessage,
    publishDeviceStatusCharacteristicUsbMessage,
    publishGetLedSettingsMessage,
    publishGetResponsiveDrawSettingsMessage,
    publishGetVapeCloudSizeSettingsMessage,
    publishGetVibrationSettingsMessage,
    publishGetAutoStartSettingsMessage,
    publishGetGestureSettingsMessage,
    publishGetCountdownSettingsMessage,
    publishHolderStatusMessage,
    publishParentProtectionDisableMessage,
    publishParentProtectionEnableMessage,
    publishParentProtectionStatusMessage,
    publishResetMessage,
    publishResetVibrationSettingsMessage,
    publishSetLedSettingsMessage,
    publishSetResponsiveDrawSettingsMessage,
    publishSetVapeCloudSizeSettingsMessage,
    publishSetVibrationSettingsMessage,
    publishSetAutoStartSettingsMessage,
    publishSetGestureSettingsMessage,
    publishSetCountdownSettingsMessage,
    publishInitiateUpdateFirmwareMessage,
    publishStopVibrationAlarmMessage,
    publishVibrationAlarmMessage,
    publishDeviceRestartMessage,
};
