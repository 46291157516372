import React, {FC, useState} from 'react';

import PopupFullPage from '../../../components/Popup/PopupFullPage/PopupFullPage';
import ModalTypes from '../../../enums/app/modalTypes';
import useHideModalIfNoDevice from '../../../hooks/useHideModalIfNoDevice';
import {hideModalAction, showFWULedCheckAction} from '../../../state/ducks/modal';
import {dispatch} from '../../../state/store';
import styles from '../FWUAdditionalStepsGuidePopup/FWUAdditionalStepsGuidePopup.module.scss';
import FWUAdditionalStepsGuide from './components/FWUAdditionalStepsGuide/FWUAdditionalStepsGuide';
import FWUStartFinishStep from './components/FWUStartFinishStep/FWUStartFinishStep';

const IS_FWU_START_STEP_VISIBLE = 'isFWUStartStepVisible';
const IS_FWU_ADDITIONAL_STEPS_GUIDE_VISIBLE = 'isFWUAdditionalStepsGuideVisible';
const IS_FWU_FINISH_STEP_VISIBLE = 'isFWUFinishStepVisible';

interface IDisplayedComponent {
    [IS_FWU_START_STEP_VISIBLE]?: boolean;
    [IS_FWU_ADDITIONAL_STEPS_GUIDE_VISIBLE]?: boolean;
    [IS_FWU_FINISH_STEP_VISIBLE]?: boolean;
}

const FWUAdditionalStepsGuidePopup: FC<{modalType: ModalTypes}> = (props) => {
    const {modalType} = props;
    const [displayedComponent, setDisplayedComponent] = useState<IDisplayedComponent>({
        [IS_FWU_START_STEP_VISIBLE]: true,
        [IS_FWU_ADDITIONAL_STEPS_GUIDE_VISIBLE]: false,
        [IS_FWU_FINISH_STEP_VISIBLE]: false,
    });
    const {isFWUStartStepVisible, isFWUAdditionalStepsGuideVisible, isFWUFinishStepVisible} = displayedComponent;

    const onClose = () => {
        if (isFWUStartStepVisible) {
            dispatch(showFWULedCheckAction());
        }
        dispatch(hideModalAction(modalType));
    };

    useHideModalIfNoDevice(modalType);

    const onChangeComponent = (displayComponent: string) => {
        setDisplayedComponent({[displayComponent]: true});
    };

    return (
        <PopupFullPage containerClassName={styles.Page}>
            {(isFWUStartStepVisible || isFWUFinishStepVisible) && (
                <FWUStartFinishStep
                    isFlowFinish={isFWUFinishStepVisible}
                    onClose={onClose}
                    onNextClick={() => onChangeComponent(IS_FWU_ADDITIONAL_STEPS_GUIDE_VISIBLE)}
                    onBackButtonClick={
                        isFWUFinishStepVisible
                            ? () => onChangeComponent(IS_FWU_ADDITIONAL_STEPS_GUIDE_VISIBLE)
                            : undefined
                    }
                />
            )}
            {isFWUAdditionalStepsGuideVisible && (
                <FWUAdditionalStepsGuide
                    onNextClick={() => onChangeComponent(IS_FWU_FINISH_STEP_VISIBLE)}
                    onBackButtonClick={() => onChangeComponent(IS_FWU_START_STEP_VISIBLE)}
                />
            )}
        </PopupFullPage>
    );
};

export default FWUAdditionalStepsGuidePopup;
