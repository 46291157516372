import appConfig from '../../config/appConfig';
import ACTIVATION_ERRORS_DATA from '../../consts/analytics/activationErrorsData';
import * as errorPageTypes from '../../consts/app/errorPageTypes';
import * as logMessageTypes from '../../consts/app/logMessageTypes';
import * as gamMethods from '../../consts/gam/gamMethods';
import * as yapActionStatusTypes from '../../consts/yap/yapActionStatusTypes';
import * as yapConnectionStatusTypes from '../../consts/yap/yapConnectionStatusTypes';
import * as yapDataChangeTypes from '../../consts/yap/yapDataChangePropertyTypes';
import {setFWUError, setFWUStarted, setIsDeviceReady, updateIotDeviceData} from '../../state/ducks/iotDevice/actions';
import {
    setDeviceDataChangeReceived,
    setSyncLastRequestId,
    setYapActivationInProgress,
    setYapAssets,
    setYapDeviceConnected,
    setYapFirmwareInProgress,
    setYapLastRequestId,
    setYapSyncInProgress,
    setYapUpdateSettingsInProgress,
} from '../../state/ducks/yapEncrypted';
import {makeSelectGamAuthToken} from '../../state/selectors/auth';
import {selectIsFirmwareChecking} from '../../state/selectors/iotDevice';
import {
    makeSelectFirstAsset,
    makeSelectIsDeviceSyncInProgress,
    makeSelectIsYapActivationInProgress,
    makeSelectIsYapFirmwareInProgress,
    makeSelectIsYapUpdateSettingsInProgress,
} from '../../state/selectors/yapEncrypted';
import {dispatch, getState} from '../../state/store';
import analyticsService from '../analyticsService';
import appErrorService from '../app/appErrorService';
import cmClientService from '../communicationLayer/cmClientService';
import IqosServiceClient from '../device/iqosServiceClient';
import iccConsumerProducts from '../icc/iccConsumerProducts';
import log from '../logger/log';
import {gamAssetMapping} from '../mapping/gamMappings';
import appRouterService from '../route/appRouterService';
import yapStateService from '../yap/yapStateService';
import gamClient from './gamClient';
import gamService from './gamService';
import gamWebSocketService from './gamWebSocketService';

const initGam = async () => {
    try {
        //TODO: improve after dce2 decision
        /*const deviceDataFromLocalStorage = storageService.getDeviceCodentifyFactoryFromLocalStorage();
        const {dataFactory} = deviceDataFromLocalStorage;

        if (dataFactory === undefined) {
            log.error(`gamService: initGam, error: dataFactory is undefined`, logMessageTypes.GAM);
            appRouterService.forwardToErrorHandlingPage(errorPageTypes.GAM_SYSTEM_ERROR);
            return;
        }*/
        const gamAuthToken = makeSelectGamAuthToken()(getState());

        if (gamAuthToken || (await gamClient.fetchGamToken())) {
            await getGamAssets();
        }
    } catch (e) {
        log.error(`gamService: initGam, error: ${e}`, logMessageTypes.GAM);
        appRouterService.forwardToErrorHandlingPage(errorPageTypes.GAM_SYSTEM_ERROR);
    }
};

const getGamAssets = async (isActivationProcess = false) => {
    const options = {
        methodName: gamMethods.GET_ASSETS,
        action: setYapAssets,
        mapper: gamAssetMapping,
        isNeedRedirectForOtherErrors: !isActivationProcess,
    };

    await gamClient.callPost(options);
    const gamAsset = makeSelectFirstAsset()(getState());

    if (gamAsset?.materialId) {
        await iccConsumerProducts.getProductsAssets([gamAsset.materialId]);
    }
};

const connectGamDevice = (assetId) =>
    gamClient.callGet({
        methodName: gamMethods.CONNECT_DEVICE,
        requestConfig: {args: assetId},
        isNeedRedirectForOtherErrors: false,
    });

let syncProcessTimeout;
const synchronizeGamDevice = async () => {
    const state = getState();
    const gamAsset = makeSelectFirstAsset()(state);

    if (!gamAsset?.assetId) return;

    const isDeviceSyncInProgress = makeSelectIsDeviceSyncInProgress()(state);

    if (isDeviceSyncInProgress) return;

    clearTimeout(syncProcessTimeout);
    dispatch(setYapSyncInProgress(true));

    const isFirmwareChecking = selectIsFirmwareChecking(state);

    if (!isFirmwareChecking) {
        const newFwuState = {device: {firmware: undefined}, holder: {firmware: undefined}};

        dispatch(updateIotDeviceData(newFwuState));
    }

    const isYapActivationInProgress = makeSelectIsYapActivationInProgress()(state);
    const isYapUpdateSettingsInProgress = makeSelectIsYapUpdateSettingsInProgress()(state);
    const isYapFirmwareInProgress = makeSelectIsYapFirmwareInProgress()(state);
    const isSomeFlowInProgress = isYapActivationInProgress || isYapUpdateSettingsInProgress || isYapFirmwareInProgress;

    if (!isSomeFlowInProgress) {
        //forced reconnect to CM before sync because of 500 error code for sync (busy for new flow)
        dispatch(setYapDeviceConnected(false));
    }

    await syncAndSubscribeOnGamDevice();
    const isConnected = await cmClientService.waitUntilConnected();

    if (isConnected) {
        const iqosService = new IqosServiceClient();
        let isDeviceConnected = iqosService.isDeviceConnected();

        if (isDeviceConnected) {
            if (isSomeFlowInProgress) {
                const isDeviceInfoValid = await getGamDeviceInfo();

                if (isDeviceInfoValid) {
                    isDeviceConnected = iqosService.isDeviceConnected();
                    if (isDeviceConnected) {
                        dispatch(setIsDeviceReady());
                    }
                }
                dispatch(setYapSyncInProgress(false));
                return;
            }

            log.info('Start synchronization process.');
            const {requestId} =
                (await gamClient.callPost({
                    methodName: gamMethods.SYNCHRONIZE_DATA,
                    requestConfig: {args: gamAsset.assetId},
                    isNeedRedirectForOtherErrors: false,
                })) || {};

            if (requestId) {
                dispatch(setSyncLastRequestId(requestId));

                syncProcessTimeout = setTimeout(async () => {
                    const isDeviceSyncInProgress = makeSelectIsDeviceSyncInProgress()(getState());

                    if (!isDeviceSyncInProgress) return;

                    log.info('End synchronization process. Timeout.');
                    dispatch(setYapSyncInProgress(false));
                }, appConfig.getGamUpdateSettingTimeout() * 1000);
                return;
            }
        }
    }

    dispatch(setYapSyncInProgress(false));
};

const getGamDeviceInfo = async () => {
    const gamAsset = makeSelectFirstAsset()(getState());
    const assetId = gamAsset?.assetId;

    const deviceInfo = await gamClient.callGet({
        methodName: gamMethods.GET_DEVICE_INFO,
        requestConfig: {args: assetId},
        isNeedRedirectForOtherErrors: false,
    });

    if (!deviceInfo) return false;

    const {deviceSpecific, firmwareVersion} = deviceInfo;

    const yapActivationStatus = deviceSpecific?.yapActivationStatus;

    if (!yapActivationStatus) {
        appRouterService.forwardToErrorHandlingPage(errorPageTypes.GAM_SYSTEM_ERROR);
        return false;
    }
    await gamService.updateGamActivationStatus(yapActivationStatus);

    const {responsiveDrawProfile, vapeCloudSize, parentProtection, ledIntensity, systemStatus, codentify} =
        deviceSpecific || {};

    yapStateService.updateDeviceSettings({
        type: yapDataChangeTypes.YAP_RESPONSIVE_DRAW_PROPERTY,
        newValue: responsiveDrawProfile?.hapticPattern,
    });
    yapStateService.updateDeviceSettings({
        type: yapDataChangeTypes.YAP_VAPE_CLOUD_SIZE_PROPERTY,
        newValue: vapeCloudSize?.heatingProfile,
    });
    yapStateService.updateDeviceSettings({
        type: yapDataChangeTypes.YAP_PARENT_PROTECTION_PROPERTY,
        newValue: parentProtection?.protectionType,
    });
    yapStateService.updateDeviceSettings({
        type: yapDataChangeTypes.YAP_LED_PROPERTY,
        newValue: ledIntensity?.intensity,
    });
    gamService.updateIotDevice(firmwareVersion, systemStatus?.batteryState, codentify?.codentify);
    gamService.updateGamDeviceConfig(firmwareVersion);
    return true;
};

const getGamFirmwareAvailability = async (targets_holder) => {
    const gamAsset = makeSelectFirstAsset()(getState());
    const assetId = gamAsset?.assetId;

    const firmwareResponse = await gamClient.callGet({
        methodName: gamMethods.GET_FIRMWARE_AVAILABILITY,
        requestConfig: {args: assetId},
        isNeedRedirectForOtherErrors: false,
    });

    if (!firmwareResponse) return false;

    yapStateService.updateDeviceSettings({
        type: yapDataChangeTypes.YAP_AVAILABLE_FIRMWARE_VERSION_PROPERTY,
        newValue: firmwareResponse,
        isHolder: targets_holder,
    });
    return true;
};

const activateDevice = async () => {
    await tryActivateDeactivateDevice(true);
};

const deactivateDevice = async () => {
    await tryActivateDeactivateDevice(false);
};

let activeProcessTimeout = null;
const tryActivateDeactivateDevice = async (isActivate) => {
    const isYapActivationInProgress = makeSelectIsYapActivationInProgress()(getState());

    if (isYapActivationInProgress) return;

    clearTimeout(activeProcessTimeout);

    dispatch(setYapActivationInProgress(true));
    dispatch(setDeviceDataChangeReceived(false));

    try {
        const gamAsset = makeSelectFirstAsset()(getState());

        if (gamAsset) {
            try {
                await syncAndSubscribeOnGamDevice();
                return await activateDeactivateDevice(gamAsset, isActivate);
            } catch (e) {
                log.error(`gamClientService: IsActivate: ${isActivate} failed. error: ${e}`, logMessageTypes.GAM);
            }
        }

        await getGamDeviceInfo();
        dispatch(setYapActivationInProgress(false));
    } catch (e) {
        log.error(`gamClientService: IsActivate: ${isActivate} failed. error: ${e}`, logMessageTypes.GAM);
        dispatch(setYapActivationInProgress(false));
    }
};

const syncAndSubscribeOnGamDevice = async () => {
    const state = getState();
    const gamAsset = makeSelectFirstAsset()(state);
    const assetId = gamAsset?.assetId;

    if (!assetId) throw new Error('Asset is not initialized');

    await gamWebSocketService.initGamWebSocket(assetId);

    await cmClientService.initCmClient(assetId);
};

const activateDeactivateDevice = async (gamAsset, isActivate) => {
    const isConnected = await cmClientService.waitUntilConnected();

    if (isConnected) {
        if (isActivate) {
            log.info('Start activation process.');
        } else {
            log.info('Start deactivation process.');
        }

        const methodName = isActivate ? gamMethods.ACTIVATE_DEVICE : gamMethods.DEACTIVATE_DEVICE;
        const requestConfig = {args: gamAsset.assetId};
        const {requestId} =
            (await gamClient.callPost({methodName, requestConfig, isNeedRedirectForOtherErrors: false}).catch(() => {
                log.error(`gamClientService: IsActivate: ${isActivate} failed. Action error`, logMessageTypes.GAM);
                dispatch(setYapActivationInProgress(false));
            })) || {};

        if (requestId) {
            dispatch(setYapLastRequestId(requestId));

            activeProcessTimeout = setTimeout(async () => {
                const isYapActivationInProgress = makeSelectIsYapActivationInProgress()(getState());

                if (!isYapActivationInProgress) return;

                analyticsService.pushActivationFailedError(ACTIVATION_ERRORS_DATA.ERROR_ACTIVATION_TIMEOUT_ERROR);

                await getGamDeviceInfo();
                dispatch(setYapActivationInProgress(false));
            }, appConfig.getGamTimeout() * 1000);
        } else {
            analyticsService.pushActivationFailedError(ACTIVATION_ERRORS_DATA.ERROR_IOT_SYSTEM_ERROR);
            dispatch(setYapActivationInProgress(false));
        }
    } else {
        dispatch(setYapActivationInProgress(false));
    }
};

const updateGamSettings = async (body) => {
    const isYapUpdateSettingsInProgress = makeSelectIsYapUpdateSettingsInProgress()(getState());

    if (isYapUpdateSettingsInProgress) return;

    clearTimeout(activeProcessTimeout);

    dispatch(setYapUpdateSettingsInProgress(true));
    dispatch(setDeviceDataChangeReceived(false));

    const gamAsset = makeSelectFirstAsset()(getState());

    if (gamAsset) {
        try {
            await syncAndSubscribeOnGamDevice();
            const isConnected = await cmClientService.waitUntilConnected();

            if (isConnected) {
                log.info('Start updateGamSettings process.');

                const {requestId} =
                    (await gamClient.callPost({
                        methodName: gamMethods.UPDATE_SETTINGS,
                        requestConfig: {args: gamAsset?.assetId, data: body},
                        isNeedRedirectForOtherErrors: false,
                    })) || {};

                if (requestId) {
                    dispatch(setYapLastRequestId(requestId));

                    activeProcessTimeout = setTimeout(() => {
                        const isYapUpdateSettingsInProgress = makeSelectIsYapUpdateSettingsInProgress()(getState());

                        if (!isYapUpdateSettingsInProgress) return;

                        dispatch(setYapUpdateSettingsInProgress(false));
                        appErrorService.showDeviceSettingsGlobalError();
                    }, appConfig.getGamUpdateSettingTimeout() * 1000);
                    return;
                }
            }
        } catch (e) {
            log.error(`gamClientService: updateGamSettings failed. error: ${e}`, logMessageTypes.GAM);
        }
    }

    appErrorService.showDeviceSettingsGlobalError();
    dispatch(setYapUpdateSettingsInProgress(false));
};

const updateGamFirmware = async (fwuTarget) => {
    const isYapFirmwareInProgress = makeSelectIsYapFirmwareInProgress()(getState());

    if (isYapFirmwareInProgress) return;

    clearTimeout(activeProcessTimeout);

    dispatch(setYapFirmwareInProgress(true));
    dispatch(setFWUStarted(fwuTarget));

    const gamAsset = makeSelectFirstAsset()(getState());

    if (gamAsset) {
        try {
            await syncAndSubscribeOnGamDevice();
            const isConnected = await cmClientService.waitUntilConnected();

            if (isConnected) {
                log.info('Start firmware process.');

                const {requestId} =
                    (await gamClient.callPost({
                        methodName: gamMethods.UPDATE_FIRMWARE,
                        requestConfig: {args: gamAsset?.assetId},
                        isNeedRedirectForOtherErrors: false,
                    })) || {};

                if (requestId) {
                    dispatch(setYapLastRequestId(requestId));
                    return;
                }
            }
        } catch (e) {
            log.error(`gamClientService: updateGamFirmware failed. error: ${e}`, logMessageTypes.GAM);
        }
    }

    dispatch(setYapFirmwareInProgress(false));
    dispatch(setFWUError());
};

const waitUntilFirmwareEndsOnGam = () => {
    activeProcessTimeout = setTimeout(() => {
        const isYapFirmwareInProgress = makeSelectIsYapFirmwareInProgress()(getState());

        if (!isYapFirmwareInProgress) return;

        dispatch(setYapFirmwareInProgress(false));
        dispatch(setFWUError());
    }, appConfig.getGamFirmwareTimeout() * 1000);
};

const checkGamConnectivityStatus = async (assetId) => {
    const isConnected = await cmClientService.isCmClientConnected(assetId);

    if (isConnected) {
        const data = await gamClient.callGet({
            methodName: gamMethods.CHECK_CONNECTIVITY_STATUS,
            requestConfig: {args: assetId},
            isNeedRedirectForOtherErrors: false,
        });

        if (data) {
            if (data.status === yapConnectionStatusTypes.CONNECTED) {
                dispatch(setYapDeviceConnected(true));
            } else if (data.status === yapConnectionStatusTypes.DISCONNECTED) {
                dispatch(setYapDeviceConnected(false));
            }
        }
    }
};

const checkGamRequestStatus = async (assetId, requestId, isSync = false) => {
    const data = await gamClient.callGet({
        methodName: gamMethods.CHECK_REQUEST_STATUS,
        requestConfig: {args: [assetId, requestId]},
        isNeedRedirectForOtherErrors: false,
    });

    const isCompleted = data?.status === yapActionStatusTypes.SUCCESS || data?.status === yapActionStatusTypes.FAILED;

    if (isCompleted) {
        if (!isSync) {
            await getGamDeviceInfo();

            const isYapUpdateSettingsInProgress = makeSelectIsYapUpdateSettingsInProgress()(getState());

            if (isYapUpdateSettingsInProgress && data?.status === yapActionStatusTypes.FAILED) {
                appErrorService.showDeviceSettingsGlobalError();
            }
        }
        return data?.status;
    }

    return yapActionStatusTypes.PENDING;
};

export default {
    initGam,
    connectGamDevice,
    activateDevice,
    deactivateDevice,
    updateGamSettings,
    updateGamFirmware,
    waitUntilFirmwareEndsOnGam,
    synchronizeGamDevice,
    getGamDeviceInfo,
    getGamFirmwareAvailability,
    checkGamConnectivityStatus,
    checkGamRequestStatus,
};
