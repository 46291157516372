import {useEffect} from 'react';

import hidReconnectHandlerService from '../../services/hid/hidReconnectHandlerService';

export const useHidReconnectHandler = (handler = () => {}) => {
    useEffect(() => {
        hidReconnectHandlerService.setCustomOHandler(handler);

        return () => {
            hidReconnectHandlerService.clearCustomHandlerAndShowReconnectModal();
        };
    }, []);
};

export default useHidReconnectHandler;
