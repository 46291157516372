export const SET_YAP_ASSETS = 'app/yapEncrypted/SET_YAP_ASSETS';
export const CLEAR_YAP_ASSETS = 'app/yapEncrypted/CLEAR_YAP_ASSETS';
export const SET_YAP_ACTIVATION_IN_PROGRESS = 'app/yapEncrypted/SET_YAP_ACTIVATION_IN_PROGRESS';
export const SET_YAP_DEVICE_CONNECTED = 'app/yapEncrypted/SET_YAP_DEVICE_CONNECTED';
export const SET_YAP_HOLDER_CONNECTED = 'app/yapEncrypted/SET_YAP_HOLDER_CONNECTED';
export const SET_YAP_LAST_REQUEST_ID = 'app/yapEncrypted/SET_YAP_LAST_REQUEST_ID';
export const SET_SYNC_LAST_REQUEST_ID = 'app/yapEncrypted/SET_SYNC_LAST_REQUEST_ID';
export const SET_YAP_LAST_CHECK_REQUEST_DATE = 'app/yapEncrypted/SET_YAP_LAST_CHECK_REQUEST_DATE';
export const SET_YAP_SYNC_IN_PROGRESS = 'app/yapEncrypted/SET_YAP_SYNC_IN_PROGRESS';
export const SET_DEVICE_DATA_CHANGE_RECEIVED = 'app/yapEncrypted/SET_DEVICE_DATA_CHANGE_RECEIVED';
export const SET_YAP_FIND_DEVICE_RECEIVED = 'app/yapEncrypted/SET_YAP_FIND_DEVICE_RECEIVED';
export const SET_YAP_DEVICE_INFO_RECEIVED = 'app/yapEncrypted/SET_YAP_DEVICE_INFO_RECEIVED';
export const SET_YAP_HOLDER_INFO_RECEIVED = 'app/yapEncrypted/SET_YAP_HOLDER_INFO_RECEIVED';
export const SET_YAP_UPDATE_SETTINGS_IN_PROGRESS = 'app/yapEncrypted/SET_YAP_UPDATE_SETTINGS_IN_PROGRESS';
export const SET_YAP_CANCEL_DATA_COLLECTION_IN_PROGRESS = 'app/yapEncrypted/SET_YAP_CANCEL_DATA_COLLECTION_IN_PROGRESS';
export const SET_YAP_DATA_COLLECTION_ALLOWED = 'app/yapEncrypted/SET_YAP_DATA_COLLECTION_ALLOWED';
export const SET_YAP_SYNCRONIZE_DATA_COLLECTION_IN_PROGRESS =
    'app/yapEncrypted/SET_YAP_SYNCRONIZE_DATA_COLLECTION_IN_PROGRESS';
export const SET_YAP_SYNCRONIZE_DEVICE_DATA_COLLECTION_RECEIVED =
    'app/yapEncrypted/SET_YAP_SYNCRONIZE_DEVICE_DATA_COLLECTION_RECEIVED';
export const SET_YAP_SYNCRONIZE_HOLDER_DATA_COLLECTION_RECEIVED =
    'app/yapEncrypted/SET_YAP_SYNCRONIZE_HOLDER_DATA_COLLECTION_RECEIVED';
export const SET_YAP_FIRMWARE_IN_PROGRESS = 'app/yapEncrypted/SET_YAP_FIRMWARE_IN_PROGRESS';
export const SET_CM_MESSAGE_DATA = 'app/yapEncrypted/SET_CM_MESSAGE_DATA';

const initialData = {
    yapAssets: undefined,
    isYapActivationInProgress: false,
    isDeviceDataChangeReceived: false,
    isYapFindDeviceReceived: false,
    isYapUpdateSettingsInProgress: false,
    isYapHolderUpdateSettingsInProgress: false,
    isYapDeviceInfoReceived: false,
    isYapHolderInfoReceived: false,
    isYapFirmwareInProgress: false,
    isCancelDataCollectionInProgress: false,
    isYapDeviceConnected: false,
    isYapHolderConnected: false,
    lastRequestId: undefined,
    lastHolderRequestId: undefined,
    syncLastRequestId: undefined,
    isYapSyncInProgress: false,
    isYapHolderSyncInProgress: false,
    isYapDataCollectionAllowed: false,
    isYapSynchronizeDataCollectionInProgress: false,
    isYapSynchronizeDeviceDataCollectionReceived: false,
    isYapSynchronizeHolderDataCollectionReceived: false,
    cmToken: undefined,
    cmRequestId: undefined,
};

export default function reducer(state = initialData, action) {
    switch (action.type) {
        case SET_YAP_ASSETS: {
            const stateYapAssets = state.yapAssets;
            const yapAssetsArray = stateYapAssets ? [...stateYapAssets] : [];
            const newAsset = action.payload[0] || {};
            const yapAsset = yapAssetsArray.find((asset) => asset?.serialNumber === newAsset.serialNumber);

            if (!yapAsset) {
                yapAssetsArray.push(newAsset);
            } else {
                yapAsset.yapActivated = newAsset.yapActivated;
            }

            return {
                ...state,
                yapAssets: yapAssetsArray,
            };
        }
        case CLEAR_YAP_ASSETS: {
            return {
                ...state,
                yapAssets: undefined,
            };
        }
        case SET_YAP_ACTIVATION_IN_PROGRESS: {
            let result = state;

            if (!action.payload) {
                //clear requestId if activation process is ended
                result = {
                    ...result,
                    lastRequestId: undefined,
                };
            }

            return {
                ...result,
                isYapActivationInProgress: action.payload,
            };
        }
        case SET_YAP_SYNC_IN_PROGRESS: {
            const {isHolder, data} = action.payload;
            const newData = isHolder ? {isYapHolderSyncInProgress: data} : {isYapSyncInProgress: data};

            return {
                ...state,
                ...newData,
            };
        }
        case SET_SYNC_LAST_REQUEST_ID: {
            return {
                ...state,
                syncLastRequestId: action.payload,
            };
        }
        case SET_YAP_UPDATE_SETTINGS_IN_PROGRESS: {
            const {isHolder, data} = action.payload;
            const newData = isHolder
                ? {isYapHolderUpdateSettingsInProgress: data}
                : {isYapUpdateSettingsInProgress: data};

            return {
                ...state,
                ...newData,
            };
        }
        case SET_YAP_CANCEL_DATA_COLLECTION_IN_PROGRESS: {
            return {
                ...state,
                isCancelDataCollectionInProgress: action.payload,
            };
        }
        case SET_YAP_SYNCRONIZE_DATA_COLLECTION_IN_PROGRESS: {
            return {
                ...state,
                isYapSynchronizeDataCollectionInProgress: action.payload,
            };
        }
        case SET_YAP_DATA_COLLECTION_ALLOWED: {
            return {
                ...state,
                isYapDataCollectionAllowed: action.payload,
            };
        }
        case SET_YAP_SYNCRONIZE_DEVICE_DATA_COLLECTION_RECEIVED: {
            return {
                ...state,
                isYapSynchronizeDeviceDataCollectionReceived: action.payload,
            };
        }
        case SET_YAP_SYNCRONIZE_HOLDER_DATA_COLLECTION_RECEIVED: {
            return {
                ...state,
                isYapSynchronizeHolderDataCollectionReceived: action.payload,
            };
        }
        case SET_YAP_FIRMWARE_IN_PROGRESS: {
            return {
                ...state,
                isYapFirmwareInProgress: action.payload,
            };
        }
        case SET_DEVICE_DATA_CHANGE_RECEIVED: {
            return {
                ...state,
                isDeviceDataChangeReceived: action.payload,
            };
        }
        case SET_YAP_FIND_DEVICE_RECEIVED: {
            return {
                ...state,
                isYapFindDeviceReceived: action.payload,
            };
        }
        case SET_YAP_DEVICE_INFO_RECEIVED: {
            return {
                ...state,
                isYapDeviceInfoReceived: action.payload,
            };
        }
        case SET_YAP_HOLDER_INFO_RECEIVED: {
            return {
                ...state,
                isYapHolderInfoReceived: action.payload,
            };
        }
        case SET_YAP_DEVICE_CONNECTED: {
            return {
                ...state,
                isYapDeviceConnected: action.payload,
            };
        }
        case SET_YAP_HOLDER_CONNECTED: {
            return {
                ...state,
                isYapHolderConnected: action.payload,
            };
        }
        case SET_YAP_LAST_REQUEST_ID: {
            const {isHolder, data} = action.payload;
            const newData = isHolder ? {lastHolderRequestId: data} : {lastRequestId: data};

            return {
                ...state,
                ...newData,
            };
        }
        case SET_YAP_LAST_CHECK_REQUEST_DATE: {
            return {
                ...state,
                lastCheckRequestDate: action.payload,
            };
        }
        case SET_CM_MESSAGE_DATA: {
            return {
                ...state,
                cmToken: action.payload.cmToken,
                cmRequestId: action.payload.cmRequestId,
            };
        }
        default:
            return state;
    }
}

export const setYapAssets = (data) => ({type: SET_YAP_ASSETS, payload: data});

export const clearYapAssets = () => ({type: CLEAR_YAP_ASSETS});

export const setYapActivationInProgress = (data) => ({type: SET_YAP_ACTIVATION_IN_PROGRESS, payload: data});

export const setDeviceDataChangeReceived = (data) => ({type: SET_DEVICE_DATA_CHANGE_RECEIVED, payload: data});

export const setDeviceInfoReceived = (data) => ({type: SET_YAP_DEVICE_INFO_RECEIVED, payload: data});

export const setHolderInfoReceived = (data) => ({type: SET_YAP_HOLDER_INFO_RECEIVED, payload: data});

export const setYapUpdateSettingsInProgress = (data, isHolder = false) => ({
    type: SET_YAP_UPDATE_SETTINGS_IN_PROGRESS,
    payload: {isHolder, data},
});

export const setYapCancelDataCollectionInProgress = (data) => ({
    type: SET_YAP_CANCEL_DATA_COLLECTION_IN_PROGRESS,
    payload: data,
});

export const setYapSynchronizeDataCollectionInProgress = (data) => ({
    type: SET_YAP_SYNCRONIZE_DATA_COLLECTION_IN_PROGRESS,
    payload: data,
});

export const setIsYapDataCollectionAllowed = (data) => ({
    type: SET_YAP_DATA_COLLECTION_ALLOWED,
    payload: data,
});

export const setYapSynchronizeDeviceDataCollectionReceived = (data) => ({
    type: SET_YAP_SYNCRONIZE_DEVICE_DATA_COLLECTION_RECEIVED,
    payload: data,
});

export const setYapSynchronizeHolderDataCollectionReceived = (data) => ({
    type: SET_YAP_SYNCRONIZE_HOLDER_DATA_COLLECTION_RECEIVED,
    payload: data,
});

export const setYapFindDeviceReceived = (data) => ({type: SET_YAP_FIND_DEVICE_RECEIVED, payload: data});

export const setYapFirmwareInProgress = (data) => ({type: SET_YAP_FIRMWARE_IN_PROGRESS, payload: data});

export const setYapSyncInProgress = (data, isHolder = false) => ({
    type: SET_YAP_SYNC_IN_PROGRESS,
    payload: {isHolder, data},
});

export const setYapDeviceConnected = (data) => ({type: SET_YAP_DEVICE_CONNECTED, payload: data});

export const setYapHolderConnected = (data) => ({type: SET_YAP_HOLDER_CONNECTED, payload: data});

export const setYapLastRequestId = (data, isHolder = false) => ({
    type: SET_YAP_LAST_REQUEST_ID,
    payload: {isHolder, data},
});

export const setSyncLastRequestId = (data) => ({type: SET_SYNC_LAST_REQUEST_ID, payload: data});

export const setYapLastCheckRequestDate = (data) => ({type: SET_YAP_LAST_CHECK_REQUEST_DATE, payload: data});

export const setCmMessageData = (cmToken, cmRequestId) => ({
    type: SET_CM_MESSAGE_DATA,
    payload: {cmToken, cmRequestId},
});
