import marketTypeService from './marketTypeService';

const transform = (isP4: boolean, codentify?: string): string | undefined => {
    //UA market is DCS type market but as a backend it use DCE backend
    const isTransformNeeded = codentify && isP4 && marketTypeService.isDCS() && !marketTypeService.isUaMarket();

    if (isTransformNeeded) {
        let transformedCodentify = '';

        for (let i = 0; i < codentify!.length; i++) {
            if (i > 6 && i <= 9) {
                transformedCodentify = transformedCodentify + '*';
            } else {
                transformedCodentify = transformedCodentify + codentify![i];
            }
        }

        return transformedCodentify;
    }
    return codentify;
};

export default {
    transform,
};
