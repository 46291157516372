import cn from 'classnames';
import React, {FC} from 'react';

import Button from '../Button';
import {IButton} from '../ButtonTypes';
import styles from './ButtonDark.module.scss';

interface IProps extends IButton {
    isArrowAnimation?: boolean;
}

const ButtonDark: FC<IProps> = (props) => {
    const {arrowClassName, className, children, isArrowAnimation = true, ...restProps} = props;

    return (
        <Button
            {...restProps}
            className={cn(styles.ButtonDark, {'button-arrow__animation': isArrowAnimation}, className)}
            arrowClassName={cn(styles.ButtonDarkArrow, arrowClassName)}
        >
            <span>{children}</span>
        </Button>
    );
};

export default ButtonDark;
