import React from 'react';

import ExpansionPanel from '../../../../../components/ExpansionPanel/ExpansionPanel';
import SwitchToggle from '../../../../../components/SwitchToggle/SwitchToggle';
import styles from './DCExpansionPanel.module.css';

const DCExpansionPanel = (props) => {
    const {index, title, innerText, onChange, isActive, testId} = props;

    return (
        <ExpansionPanel innerText={innerText} title={title} index={index}>
            <SwitchToggle
                className={styles.Toggle}
                index={index}
                isActive={isActive}
                onChange={onChange}
                testId={testId}
            />
        </ExpansionPanel>
    );
};

export default DCExpansionPanel;
