const PRODUCT_COLORS = {
    MELLOW_GOLD: 'EFE9E3',
    FANTASY: 'F9ECED',
    PINK: 'F3E9EB',
    LINEN: 'FCEFED',
    CONCRETE: 'F2F2F2',
    QUILL_GRAY: 'E6E6E5',
    CATSKILL_WHITE: 'E9EFF6',
    MYSTIC: 'E1ECED',
    GRAY_NURSE: 'E8EFEA',
    BIZARRE: 'F2E2E4',
    IRON: 'E2E5E6',
    WARM_WHITE: 'F1F2F1',
    ROSE_WHITE: 'FFF9F8',
    AQUA_HAZE: 'E2EEEF',
    PEARL_BUSH: 'EFEAE0',
    PAMPAS: 'EDE6E6',
    GREEN_WHITE: 'E8EEE5',
    POLAR: 'E4F2F9',
    SOFT_PEACH: 'F8F0F1',
    BEIGE: 'F2EEEB',
    DEFAULT: 'F1F2F1',
};

export default PRODUCT_COLORS;
