import deviceConnectionStatusTypes from '../../../consts/device/deviceConnectionStatusTypes';
import helpers from '../../../utils/helpers';
import * as types from './actionTypes';

const initialData = {
    data: undefined,
    isVibrationAlarmActive: false,
    deviceConnectionStatus: deviceConnectionStatusTypes.DISCONNECTED,
    isDeviceActivated: false,
    isUpdateSettingsNeeded: false,
    isPairingInProgress: false,
    isReconnected: false,
    firmware: {},
    getFeatureKeysList: undefined,
    isAvailableFwuMessageClosed: undefined,
};

export default function reducer(state = initialData, action) {
    switch (action.type) {
        case types.IOT_DEVICE_CLEAR: {
            return {
                ...state,
                data: undefined,
                deviceConnectionStatus: deviceConnectionStatusTypes.DISCONNECTED,
                firmware: undefined,
                isUpdateSettingsNeeded: false,
                getFeatureKeysList: undefined,
                isAvailableFwuMessageClosed: undefined,
            };
        }
        case types.IOT_DEVICE_SET_DEVICE_CONNECTION_STATUS: {
            const {status} = action.payload;

            return {
                ...state,
                deviceConnectionStatus: status,
            };
        }
        case types.IOT_DEVICE_SET_DEVICE_ACTIVATION_STATUS: {
            const {status} = action.payload;

            return {
                ...state,
                isDeviceActivated: status,
            };
        }

        case types.IOT_DEVICE_UPDATE_DATA: {
            const {data} = action.payload;
            const currentData = state.data;

            if (!data) return state;

            if (data.device?.exp_read) {
                const isDeviceConnected = currentData?.device;

                if (isDeviceConnected) {
                    if (currentData.device?.exp_read) {
                        data.device.exp_read += currentData.device.exp_read;
                    }
                } else {
                    //skip collecting data messages when device is disconnected but collecting data flow is still in progress
                    return state;
                }
            }

            const newData = currentData ? helpers.mergeDeep(currentData, data) : data;

            return {
                ...state,
                data: newData,
            };
        }
        case types.IOT_DEVICE_CLEAR_DATA: {
            const {device} = state.data;
            const {name, type, parent_protection_mode, media_id, mediaId} = device || {};

            const data = {
                device: {
                    name,
                    type,
                    media_id,
                    mediaId,
                },
                holder: null,
                device_management_features_config: null,
                device_global_features_config: null,
            };

            if (parent_protection_mode !== undefined) {
                data.device.parent_protection_mode = parent_protection_mode;
            }

            return {
                ...state,
                data,
            };
        }
        case types.IOT_DEVICE_CLEAR_HOLDER_DATA: {
            const data = state.data;

            data.holder = null;
            data.device.holder_connected = false;
            data.device.holder_defect = false;
            data.device.isHolderConnected = false;
            if (data.ledData) {
                data.ledData = {device: data.ledData.device};
            }

            return {
                ...state,
                data,
            };
        }
        case types.IOT_DEVICE_SET_IS_RESET: {
            const {isReset} = action.payload;

            return {
                ...state,
                reset: {
                    ...state.reset,
                    isReset,
                },
            };
        }
        case types.IOT_DEVICE_SET_IS_PAIRING_IN_PROGRESS: {
            const {isPairingInProgress} = action.payload;

            return {
                ...state,
                isPairingInProgress,
            };
        }
        case types.IOT_DEVICE_SET_FIRMWARE_DATA: {
            const {data} = action.payload;

            return {
                ...state,
                firmware: {
                    ...state.firmware,
                    ...data,
                },
            };
        }

        case types.IOT_DEVICE_SET_IS_VIBRATION_ALARM_ACTIVE: {
            const {data} = action.payload;

            return {
                ...state,
                isVibrationAlarmActive: data,
            };
        }

        case types.IOT_DEVICE_SET_IS_UPDATE_SETTINGS_NEEDED: {
            const {isUpdateSettingsNeeded} = action.payload;

            return {
                ...state,
                isUpdateSettingsNeeded,
            };
        }
        case types.IOT_DEVICE_SET_GET_FEATURE_KEYS_LIST: {
            return {
                ...state,
                getFeatureKeysList: action.payload,
            };
        }
        case types.IOT_DEVICE_SET_IS_RECONNECTED: {
            const {isReconnected} = action.payload;

            return {
                ...state,
                isReconnected,
            };
        }
        case types.IOT_DEVICE_SET_IS_AVAILABLE_FWU_MESSAGE_CLOSED: {
            return {
                ...state,
                isAvailableFwuMessageClosed: action.payload,
            };
        }

        default:
            return state;
    }
}
