import React from 'react';

import Icon from './Icon';

const WarningIcon = (props) => {
    return (
        <Icon width='20' height='20' viewBox='0 0 20 20' {...props}>
            <g>
                <circle cx='10' cy='10' r='10' fill='#E5B052' />
                <ellipse cx='10.05' cy='15.186' fill='#FFF' rx='1.25' ry='1.314' />
                <path stroke='#FFF' strokeLinecap='round' strokeWidth='1.8' d='M10 4.313L10 11.114' />
            </g>
        </Icon>
    );
};

export default WarningIcon;
