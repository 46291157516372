import React from 'react';

import Icon from './Icon';

const IconReplacements = (props) => {
    return (
        <Icon width='30' height='30' viewBox='0 0 30 30' {...props}>
            <g fill='none' fillRule='evenodd' strokeLinecap='round'>
                <g stroke='#272A33' strokeWidth='1.5'>
                    <g>
                        <path
                            strokeLinejoin='round'
                            d='M11.826 0L18.087 6.261 11.826 12.522'
                            transform='translate(-763 -3352) translate(81 3215) translate(660 137) translate(24 1) translate(7.391 14.435)'
                        />
                        <path
                            d='M17.391 6.261L0 6.261'
                            transform='translate(-763 -3352) translate(81 3215) translate(660 137) translate(24 1) translate(7.391 14.435)'
                        />
                    </g>
                    <g>
                        <path
                            strokeLinejoin='round'
                            d='M11.826 0L18.087 6.261 11.826 12.522'
                            transform='translate(-763 -3352) translate(81 3215) translate(660 137) translate(24 1) rotate(-180 9.543 6.76)'
                        />
                        <path
                            d='M17.391 6.261L0 6.261'
                            transform='translate(-763 -3352) translate(81 3215) translate(660 137) translate(24 1) rotate(-180 9.543 6.76)'
                        />
                    </g>
                </g>
            </g>
        </Icon>
    );
};

export default IconReplacements;
