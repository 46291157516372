import React from 'react';

import FailedPopup from '../../../components/FailedPopup/FailedPopup';
import * as iccResponseErrorTypes from '../../../consts/icc/iccResponseErrorTypes';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import modalService from '../../../services/modalService';
import helpers from '../../../utils/helpers';

const RegistrationFailedPopup = (props) => {
    const {httpErrorType, onClose, modalType, ...rest} = props;

    const onCloseModal = () => {
        helpers.runFunction(onClose);
        modalService.hideModal(modalType);
    };

    let headerKey;
    let descriptionKey;
    let cancelBtnKey;

    switch (httpErrorType) {
        case iccResponseErrorTypes.REGISTERED_PRODUCT_LIMIT: {
            headerKey = localizationKeys.MANY_REGISTER_DEVICE_REQUEST_ERROR_POPUP_TITLE_TEXT;
            descriptionKey = localizationKeys.MANY_REGISTER_DEVICE_REQUEST_ERROR_POPUP_DESCRIPTION_TEXT;
            cancelBtnKey = localizationKeys.MANY_REGISTER_DEVICE_REQUEST_ERROR_POPUP_OK_GOT_IT_BUTTON_TEXT;
            break;
        }
        case iccResponseErrorTypes.TOO_MANY_REGISTRATION_REQUESTS: {
            headerKey = localizationKeys.REACHED_LIMIT_REGISTERED_DEVICE_ERROR_POPUP_TITLE_TEXT;
            descriptionKey = localizationKeys.REACHED_LIMIT_REGISTERED_DEVICE_ERROR_POPUP_DESCRIPTION_TEXT;
            cancelBtnKey = localizationKeys.REACHED_LIMIT_REGISTERED_DEVICE_ERROR_POPUP_OK_GOT_IT_BUTTON_TEXT;
            break;
        }
    }

    return (
        <FailedPopup
            altKey={localizationKeys.DEVICE_REGISTRATION_ERROR_NOTIFICATION_IMG_ERROR_ALT}
            closeButtonAriaLabelKey={localizationKeys.DEVICE_REGISTRATION_ERROR_NOTIFICATION_BTN_CANCEL_ARIA_LABEL}
            cancelBtnKey={cancelBtnKey}
            descriptionKey={descriptionKey}
            headerKey={headerKey}
            isClosePopupFeaturesDisable
            onClose={onCloseModal}
            {...rest}
        />
    );
};

export default RegistrationFailedPopup;
