import React from 'react';

import Icon from './Icon';

const IconClose = (props) => {
    return (
        <Icon width='32' height='32' viewBox='0 0 32 32' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g fill='#4A4A4A'>
                    <path
                        d='M20.4 10l1.6 1.6-4.401 4.4L22 20.4 20.4 22l-4.401-4.4L11.6 22 10 20.4l4.4-4.4-4.4-4.4 1.6-1.6 4.4 4.4 4.4-4.4z'
                        transform='translate(-317 -713) translate(11 701) translate(306 12)'
                    />
                </g>
            </g>
        </Icon>
    );
};

export default IconClose;
