import defaultImage_DEV_TYPE_P1V30_CHARGER from '../../../assets/images/devices/default/DEV_TYPE_P1V30_CHARGER.png';
import defaultImage_DEV_TYPE_P1V30_HOLDER from '../../../assets/images/devices/default/DEV_TYPE_P1V30_HOLDER.png';
import defaultImage_DEV_TYPE_P1V30M from '../../../assets/images/devices/default/DEV_TYPE_P1V30M.png';
import defaultImage_DEV_TYPE_P1V31_CHARGER from '../../../assets/images/devices/default/DEV_TYPE_P1V31_CHARGER.png';
import defaultImage_DEV_TYPE_P1V31_HOLDER from '../../../assets/images/devices/default/DEV_TYPE_P1V31_HOLDER.png';
import defaultImage_DEV_TYPE_P1V40C_CHARGER from '../../../assets/images/devices/default/DEV_TYPE_P1V40C_CHARGER.png';
import defaultImage_DEV_TYPE_P1V40C_HOLDER_CONTROL from '../../../assets/images/devices/default/DEV_TYPE_P1V40C_HOLDER_CONTROL.png';
import defaultImage_DEV_TYPE_P1V40D_CONTROL from '../../../assets/images/devices/default/DEV_TYPE_P1V40D_CONTROL.png';
import defaultImage_DEV_TYPE_P1V40V_CHARGER from '../../../assets/images/devices/default/DEV_TYPE_P1V40V_CHARGER.png';
import defaultImage_DEV_TYPE_P1V40V_HOLDER_CONTROL from '../../../assets/images/devices/default/DEV_TYPE_P1V40V_HOLDER_CONTROL.png';
import defaultImage_DEV_TYPE_P4_M3_G2_iqos_title from '../../../assets/images/devices/default/DEV_TYPE_P4_M3_G2_IQOS_TITLE.png';
import defaultImage_DEV_TYPE_P4_M3_G2_veev_title from '../../../assets/images/devices/default/DEV_TYPE_P4_M3_G2_VEEV_TITLE.png';
import defaultImage_DEV_TYPE_V24_CHARGER from '../../../assets/images/devices/default/DEV_TYPE_V24_CHARGER.png';
import defaultImage_DEV_TYPE_V24_HOLDER from '../../../assets/images/devices/default/DEV_TYPE_V24_HOLDER.png';
import defaultImage_DEV_TYPE_V24P_CHARGER from '../../../assets/images/devices/default/DEV_TYPE_V24P_CHARGER.png';
import defaultImage_DEV_TYPE_V24P_HOLDER from '../../../assets/images/devices/default/DEV_TYPE_V24P_HOLDER.png';
import defaultSingleHolder_DEV_TYPE_P1V30_HOLDER from '../../../assets/images/devices/singleHolder/DEV_TYPE_P1V30_HOLDER.png';
import defaultSingleHolder_DEV_TYPE_P1V30M_HOLDER from '../../../assets/images/devices/singleHolder/DEV_TYPE_P1V30M_HOLDER.png';
import defaultSingleHolder_DEV_TYPE_P1V31_HOLDER from '../../../assets/images/devices/singleHolder/DEV_TYPE_P1V31_HOLDER.png';
import defaultSingleHolder_DEV_TYPE_P1V40D_CONTROL from '../../../assets/images/devices/singleHolder/DEV_TYPE_P1V40D_CONTROL.png';
import defaultSingleHolder_DEV_TYPE_V24_HOLDER from '../../../assets/images/devices/singleHolder/DEV_TYPE_V24_HOLDER.png';
import defaultSingleHolder_DEV_TYPE_V24P_HOLDER from '../../../assets/images/devices/singleHolder/DEV_TYPE_V24P_HOLDER.png';
import DEVICE_TYPES from '../../enums/device/deviceTypes';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import marketTypeService from '../../services/marketTypeService';
import * as localizationKeys from '../localization/localizationKeys';

type TData = {
    [key in DEVICE_TYPES]?: IDeviceDataTypeOptions | null;
};

export interface IDeviceDataTypeOptions {
    defaultImageSrc?: string;
    defaultSingleHolder?: string;
    description: string;
    isHolder?: boolean;
    name: string;
    typeId: string;
    type: string;
    fullType: string;
    isMono?: boolean;
    deviceModel?: string;
}

let data: TData;

const getDeviceTypesData = (): TData => {
    if (!data) {
        const localizedStrings = getLocalizedStrings();

        data = {
            [DEVICE_TYPES.DEV_TYPE_UNKNOWN]: null,
            [DEVICE_TYPES.DEV_TYPE_V24_CHARGER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_V24_CHARGER,
                description: 'IQOS 2.4 Charger',
                name: 'IQOS 2.4 Charger',
                type: DEVICE_TYPES.DEV_TYPE_P1,
                fullType: DEVICE_TYPES.DEV_TYPE_V24_CHARGER,
                typeId: '0x01',
            },
            [DEVICE_TYPES.DEV_TYPE_V24_HOLDER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_V24_HOLDER,
                defaultSingleHolder: defaultSingleHolder_DEV_TYPE_V24_HOLDER,
                description: 'IQOS 2.4 Holder',
                isHolder: true,
                name: 'IQOS 2.4 Holder',
                type: DEVICE_TYPES.DEV_TYPE_P1,
                fullType: DEVICE_TYPES.DEV_TYPE_V24P_HOLDER,
                typeId: '0x02',
            },
            [DEVICE_TYPES.DEV_TYPE_V24P_CHARGER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_V24P_CHARGER,
                description: 'IQOS 2.4 Plus Charger',
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_ORDINARY_NAME],
                deviceModel: 'IQOS 2.4 Plus',
                type: DEVICE_TYPES.DEV_TYPE_P1,
                fullType: DEVICE_TYPES.DEV_TYPE_V24P_CHARGER,
                typeId: '0x11',
            },
            [DEVICE_TYPES.DEV_TYPE_V24P_HOLDER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_V24P_HOLDER,
                defaultSingleHolder: defaultSingleHolder_DEV_TYPE_V24P_HOLDER,
                description: 'IQOS 2.4 Plus Holder',
                isHolder: true,
                name: localizedStrings[localizationKeys.ABOUT_DEVICE_ORDINARY_HOLDER_NAME],
                type: DEVICE_TYPES.DEV_TYPE_P1,
                fullType: DEVICE_TYPES.DEV_TYPE_V24P_HOLDER,
                typeId: '0x12',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V30_CHARGER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V30_CHARGER,
                description: 'IQOS 3.0 Charger (Cortez)',
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_CORTEZ_NAME],
                deviceModel: 'IQOS 3.0',
                type: DEVICE_TYPES.DEV_TYPE_P1,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V30_CHARGER,
                typeId: '0x21',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V30_HOLDER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V30_HOLDER,
                defaultSingleHolder: defaultSingleHolder_DEV_TYPE_P1V30_HOLDER,
                description: 'IQOS 3.0 Holder (Cortez)',
                isHolder: true,
                name: localizedStrings[localizationKeys.ABOUT_DEVICE_CORTEZ_HOLDER_NAME],
                type: DEVICE_TYPES.DEV_TYPE_P1,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V30_HOLDER,
                typeId: '0x22',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V30M]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V30M,
                defaultSingleHolder: defaultSingleHolder_DEV_TYPE_P1V30M_HOLDER,
                description: 'IQOS 3.0 Multi',
                isMono: true,
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_VESPUCCI_NAME],
                deviceModel: 'IQOS 3.0 Multi',
                type: DEVICE_TYPES.DEV_TYPE_P1,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V30M,
                typeId: '0x31',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V31_CHARGER,
                description: 'IQOS 3.1 Charger (Soyuz)',
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_SOYUZ_NAME],
                deviceModel: 'IQOS 3.1',
                type: DEVICE_TYPES.DEV_TYPE_P1,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER,
                typeId: '0x33',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V31_HOLDER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V31_HOLDER,
                defaultSingleHolder: defaultSingleHolder_DEV_TYPE_P1V31_HOLDER,
                description: 'IQOS 3.1 Holder (Soyuz)',
                isHolder: true,
                name: localizedStrings[localizationKeys.ABOUT_DEVICE_SOYUZ_HOLDER_NAME],
                type: DEVICE_TYPES.DEV_TYPE_P1,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V31_HOLDER,
                typeId: '0x34',
            },
            [DEVICE_TYPES.DEV_TYPE_P4_M3_G1]: {
                description: 'IQOS P4 gen. 1 (Mesh)',
                isMono: true,
                name: 'Mesh',
                deviceModel: 'IQOS VEEV',
                type: DEVICE_TYPES.DEV_TYPE_P4,
                fullType: DEVICE_TYPES.DEV_TYPE_P4_M3_G1,
                typeId: '0x41',
            },
            [DEVICE_TYPES.DEV_TYPE_P4_M3_G2]: {
                defaultImageSrc: marketTypeService.getP4DefaultImageByMarket(
                    defaultImage_DEV_TYPE_P4_M3_G2_iqos_title,
                    defaultImage_DEV_TYPE_P4_M3_G2_veev_title
                ),
                description: 'IQOS P4 gen. 2 (Titan)/(VEEV)',
                isHolder: false,
                isMono: true,
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_TITAN_NAME],
                deviceModel: 'IQOS VEEV',
                type: DEVICE_TYPES.DEV_TYPE_P4,
                fullType: DEVICE_TYPES.DEV_TYPE_P4_M3_G2,
                typeId: '0x42',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V40V_CHARGER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V40V_CHARGER,
                description: 'IQOS P1V40 Charger',
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_P1V40_NAME],
                deviceModel: 'IQOS Iluma Prime',
                type: DEVICE_TYPES.DEV_TYPE_P1_V4,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V40V_CHARGER,
                typeId: '0x61',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V40V_HOLDER_CONTROL]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V40V_HOLDER_CONTROL,
                description: 'IQOS P1V40 Holder',
                isHolder: true,
                name: localizedStrings[localizationKeys.ABOUT_DEVICE_P1V40_HOLDER_NAME],
                type: DEVICE_TYPES.DEV_TYPE_P1_V4,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V40V_HOLDER_CONTROL,
                typeId: '0x63',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V40C_CHARGER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V40C_CHARGER,
                description: 'IQOS P1V40C Charger',
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_P1V40M_NAME],
                deviceModel: 'IQOS Iluma Mid',
                type: DEVICE_TYPES.DEV_TYPE_P1_V4,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V40C_CHARGER,
                typeId: '0x61',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V40C_HOLDER_CONTROL]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V40C_HOLDER_CONTROL,
                description: 'IQOS P1V40C Holder',
                isHolder: true,
                name: localizedStrings[localizationKeys.ABOUT_DEVICE_P1V40M_HOLDER_NAME],
                type: DEVICE_TYPES.DEV_TYPE_P1_V4,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V40C_HOLDER_CONTROL,
                typeId: '0x63',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V40D_CONTROL]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V40D_CONTROL,
                defaultSingleHolder: defaultSingleHolder_DEV_TYPE_P1V40D_CONTROL,
                description: 'IQOS P1V40 (Delta)',
                isMono: true,
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_P1V40D_NAME],
                deviceModel: 'IQOS Iluma One',
                type: DEVICE_TYPES.DEV_TYPE_P1_V4,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V40D_CONTROL,
                typeId: '0x71',
            },
        };
    }

    return data;
};

export {getDeviceTypesData};
