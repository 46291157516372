import 'react-datepicker/dist/react-datepicker.css';

import cn from 'classnames';
import * as locale from 'date-fns/locale';
import React, {FC} from 'react';
import {useState} from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import {useSelector} from 'react-redux';

import {ButtonDark, ButtonText} from '../../../components/Button';
import ConnectionTrackButton from '../../../components/Button/ConnectionTrackButton/ConnectionTrackButton';
import PopupFullPage from '../../../components/Popup/PopupFullPage/PopupFullPage';
import appConfig from '../../../config/appConfig';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import layoutService from '../../../services/layoutService';
import {getLocalizedLanguage, getLocalizedStrings} from '../../../services/localization/localizationService';
import modalService from '../../../services/modalService';
import {makeSelectLayoutType} from '../../../state/selectors/global';
import elementsTesIds from '../../../test/consts/elementsTesIds';
import helpers from '../../../utils/helpers';
import CustomInput from './components/CustomInput/CustomInput';
import PurchaseLaterPopup from './components/PurchaseLaterPopup/PurchaseLaterPopup';
import styles from './PurchaseDateFullPagePopup.module.scss';
import {IPurchaseDateFullPagePopup} from './PurchaseDateFullPagePopupTypes';

const CUSTOM_LOCALE_NAME = 'customLocale';

const PurchaseDateFullPagePopup: FC<IPurchaseDateFullPagePopup> = ({
    modalType,
    registrationAction,
    registrationCancelAction,
}) => {
    const layout = useSelector(makeSelectLayoutType());
    const isMobileLayout = layoutService.isMobileLayout(layout);
    const [selectedDate, setSelectedDate] = useState<undefined | null | Date>(undefined);
    const isEmptyDate = selectedDate === null;
    const minDateYearLimitation = appConfig.getMinDateYearLimitation() || 0;
    const calendarStartDay = appConfig.getCalendarStartDay();
    const purchaseDateLocalizations = appConfig.getPurchaseDateLocalizations() || [];
    const currentLocalizedLanguage = getLocalizedLanguage().toUpperCase();
    const purchaseLocalization = purchaseDateLocalizations.find(
        (langItem) => langItem.language === currentLocalizedLanguage
    );
    const {datePickerLanguage, inputMask, dateFormat} = purchaseLocalization || {};
    const now = new Date();
    const minCalendarData = new Date(now.setFullYear(now.getFullYear() - minDateYearLimitation));
    const maxCalendarData = new Date();
    const localizedStrings = getLocalizedStrings();
    const [isPurchaseLaterPopup, setIsPurchaseLaterPopup] = useState(false);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    registerLocale(CUSTOM_LOCALE_NAME, locale[datePickerLanguage]);

    const onChange = (value: any) => {
        setSelectedDate(value);
    };

    const onContinueClick = () => {
        if (selectedDate) {
            const preparedPurchaseDate = selectedDate?.toISOString();

            registrationAction(preparedPurchaseDate);
            modalService.hideModal(modalType);
        } else {
            setSelectedDate(null);
        }
    };

    const onCancelClick = () => {
        helpers.runFunction(registrationCancelAction);
        modalService.hideModal(modalType);
    };

    return (
        <PopupFullPage className={styles.Container}>
            <h2 className={styles.Title}>
                {localizedStrings[localizationKeys.PURCHASE_DATE_ENTRY_REGISTRATION_TITLE]}
            </h2>
            <p className={styles.Description}>{localizedStrings[localizationKeys.PURCHASE_DATE_ENTRY_DESCRIPTION]}</p>
            <div className={styles.InputContainer}>
                <DatePicker
                    locale={CUSTOM_LOCALE_NAME}
                    dateFormat={dateFormat}
                    onChange={(date) => onChange(date)}
                    calendarClassName={styles.Calendar}
                    placeholderText={' '}
                    calendarStartDay={calendarStartDay}
                    selected={selectedDate}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    fixedHeight
                    minDate={minCalendarData}
                    maxDate={maxCalendarData}
                    popperPlacement='bottom-end'
                    customInput={
                        <CustomInput
                            isEmptyDate={isEmptyDate}
                            inputMask={inputMask}
                            inputLabelText={localizedStrings[localizationKeys.PURCHASE_DATE_ENTRY_TEXTBOX_TITLE]}
                            incorrectDateErrorText={
                                localizedStrings[localizationKeys.PURCHASE_DATE_ENTRY_INVALID_DATE_ERROR_TEXT]
                            }
                            testId={elementsTesIds.PURCHASE_DATE_PICKER}
                        />
                    }
                />

                <div className={cn(styles.ButtonContiner, {[styles.ButtonContinerDesktop]: !isMobileLayout})}>
                    <ConnectionTrackButton
                        button={ButtonDark}
                        className={styles.ContinueBtn}
                        showArrow
                        onClick={onContinueClick}
                        testId={elementsTesIds.PURCHASE_DATE_BTN_CONTINUE}
                    >
                        {localizedStrings[localizationKeys.PURCHASE_DATE_ENTRY_CONTINUE_BUTTON]}
                    </ConnectionTrackButton>
                    <ButtonText
                        className={styles.DoItLaterBtn}
                        onClick={() => setIsPurchaseLaterPopup(true)}
                        testId={elementsTesIds.PURCHASE_DATE_BTN_LATER}
                    >
                        {localizedStrings[localizationKeys.PURCHASE_DATE_ENTRY_CANCEL_BUTTON]}
                    </ButtonText>
                </div>
            </div>
            {isPurchaseLaterPopup && (
                <PurchaseLaterPopup
                    onClose={() => setIsPurchaseLaterPopup(false)}
                    title={localizedStrings[localizationKeys.PURCHASE_DATE_LATER_POPUP_TITLE]}
                    description={localizedStrings[localizationKeys.PURCHASE_DATE_LATER_POPUP_DESCRIPTION]}
                    buttonText={localizedStrings[localizationKeys.PURCHASE_DATE_LATER_POPUP_BTN]}
                    onCancelClick={onCancelClick}
                    testId={elementsTesIds.PURCHASE_DATE_LATER_POPUP_BTN_OK}
                />
            )}
        </PopupFullPage>
    );
};

export default PurchaseDateFullPagePopup;
