import React from 'react';

import Icon from './Icon';

const IconLegal = (props) => {
    return (
        <Icon width='33' height='37' viewBox='0 0 33 37' {...props}>
            <g fill='none' fillRule='evenodd' strokeWidth='1.6'>
                <path
                    strokeLinejoin='round'
                    d='M30.4 0v27.657c0 4.166-3.403 7.543-7.6 7.543H0V0h30.4z'
                    transform='translate(1.5 1)'
                />
                <path
                    strokeLinecap='round'
                    d='M11.2 25.926H16m-4.8-7.406h9.6m-9.6-7.646h9.6'
                    transform='translate(1.5 1)'
                />
            </g>
        </Icon>
    );
};

export default IconLegal;
