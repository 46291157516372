import appConfig from '../../config/appConfig';
import * as backendTypes from '../../consts/app/backendTypes';

const isScpCloudBackend = () => appConfig.getBackendType() === backendTypes.SCP_CLOUD_BACKEND;
const isGamBackend = () => appConfig.getBackendType() === backendTypes.GAM_BACKEND;
const isUamBackend = () => appConfig.getBackendType() === backendTypes.UAM_BACKEND;

export default {
    isScpCloudBackend,
    isGamBackend,
    isUamBackend,
};
