import * as httpErrorsTypes from '../../consts/app/httpErrorsTypes';
import HttpErrorHandlerService from '../httpErrorHandlerService';
import iccService from './iccService';

const iccResponseErrorCheckService = (error, isHandleError = true) => {
    const isAuthError = error?.response?.status === httpErrorsTypes.NOT_AUTHORIZED;

    if (isAuthError) {
        iccService.refreshIccTokenIfAllowed();
    } else {
        if (isHandleError) {
            new HttpErrorHandlerService().handleError(error);
        }
        throw error;
    }
};

export default iccResponseErrorCheckService;
