import appConfig from '../../config/appConfig';

const isP1DevicesSupported = () =>
    appConfig.getEnableOrdinarySupport() ||
    appConfig.getEnableCortezSupport() ||
    appConfig.getEnableSoyuzSupport() ||
    appConfig.getEnableVespucciSupport();

const isP1V4DevicesSupported = () =>
    appConfig.getEnableCosmosSupport() || appConfig.getEnableVoyagerSupport() || appConfig.getEnableDeltaSupport();

export default {
    isP1DevicesSupported,
    isP1V4DevicesSupported,
};
