import * as iotCITypes from '../../consts/iot/iotCITypes';
import * as iotMessageStatusTypes from '../../consts/iot/iotMessageStatusTypes';
import * as iotMessageTypes from '../../consts/iot/iotMessageTypes';
import {selectMwDeviceIsIdentified} from '../../state/selectors/mwDevice';
import {getState} from '../../state/store';
import IoT from '../aws/awsIot';
import connectivityService from '../device/connectivityService';
import log from '../logger/log';
import scpCloudService from './scpCloudService';

let messageQueue = [];
const getCiType = () => (connectivityService.isUsb() ? iotCITypes.CI_TYPE_USB : iotCITypes.CI_TYPE_BLE);

const publishInitializationEvent = () => {
    const message = {
        type: iotMessageTypes.CONNECTION,
        status: iotMessageStatusTypes.CONNECTED,
        body: {
            data: {ci_type: getCiType()},
        },
    };

    publishMWMessage(message);
};

const publishDisconnectedEvent = () => {
    const message = {
        type: iotMessageTypes.CONNECTION,
        status: iotMessageStatusTypes.DISCONNECTED,
    };

    publishMWMessage(message);
};

const publishResponseEvent = ({status, type, request_id, frames, transfer_data}) => {
    const isIdentified = selectMwDeviceIsIdentified(getState());
    const body = {frames, transfer_data};

    if (!isIdentified) {
        body.data = {ci_type: getCiType()};
    }

    const message = {
        status,
        type,
        request_id,
        body,
    };

    publishMWMessage(message);
};

const publishFwuPackageLoadedEvent = (fwu_target) => {
    const message = {
        type: iotMessageTypes.FIRMWARE_UPDATE,
        status: iotMessageStatusTypes.PACKAGE_LOADED,
        body: {
            data: {
                fwu_target,
            },
        },
    };

    publishMWMessage(message);
};

const publishFwuStatusEvent = ({status, fwu_target}) => {
    const message = {
        type: iotMessageTypes.FIRMWARE_UPDATE,
        status: iotMessageStatusTypes.IN_PROGRESS,
        body: {
            data: {
                progress: status,
                fwu_target,
            },
        },
    };

    publishMWMessage(message);
};

const publishFwuFinishEvent = ({fwu_target, startTs: start_ts}) => {
    const message = {
        type: iotMessageTypes.FIRMWARE_UPDATE,
        status: iotMessageStatusTypes.FINISHED,
        body: {
            data: {
                fwu_target,
                start_ts,
            },
        },
    };

    publishMWMessage(message);
};

const publishDeviceCharacteristicEvent = (characteristic_frame) => {
    const message = {
        type: iotMessageTypes.DEVICE_STATUS_CHARACTERISTIC,
        body: {
            data: {
                characteristic_frame,
            },
        },
    };

    publishMWMessage(message);
};

const publishBatteryCharacteristicEvent = (characteristic_frame) => {
    const message = {
        type: iotMessageTypes.BATTERY_INFORMATION_CHARACTERISTIC,
        body: {
            data: {
                characteristic_frame,
            },
        },
    };

    publishMWMessage(message);
};

const publishFwuErrorEvent = ({
    errorCode,
    errorFrame: error_frame,
    lastRequestFrames: last_request_frames,
    lastResponseFrames: last_response_frames,
    userAgent: user_agent,
    startTs: start_ts,
    fwu_target,
}) => {
    const data = {
        error_frame,
        last_request_frames,
        last_response_frames,
        user_agent,
        start_ts,
        fwu_target,
    };

    publishErrorEvent({
        type: iotMessageTypes.FIRMWARE_UPDATE,
        errorCode,
        data,
    });
};

const publishErrorEvent = ({type, request_id, frames, data, errorCode, transfer_data}) => {
    const body = {transfer_data};

    if (frames) body.frames = frames;
    if (errorCode) body.error = {code: errorCode};
    if (data) body.data = data;

    const message = {
        type,
        status: iotMessageStatusTypes.ERROR,
        request_id,
        body,
    };

    publishMWMessage(message);
};

const publishMWMessage = (message) => {
    processMessageQueue(message);
};

const processMessageQueue = (message) => {
    if (message) {
        messageQueue.push(message);
    }

    const isClientInitialized = IoT.isIotClientInitialized();

    if (isClientInitialized && messageQueue.length) {
        messageQueue.forEach((message) => {
            try {
                scpCloudService.publishMwMessage(message);
            } catch (e) {
                log.info(`mwIotMessageRequestService: publish MW message error: ${e}`);
            }
        });
        clearMessageQueue();
    }
};

const clearMessageQueue = () => (messageQueue = []);

export default {
    clearMessageQueue,
    processMessageQueue,
    publishDeviceCharacteristicEvent,
    publishDisconnectedEvent,
    publishErrorEvent,
    publishFwuErrorEvent,
    publishFwuFinishEvent,
    publishFwuPackageLoadedEvent,
    publishFwuStatusEvent,
    publishInitializationEvent,
    publishResponseEvent,
    publishBatteryCharacteristicEvent,
};
