import React from 'react';

import Icon from './Icon';

const IconDoDont = (props) => {
    return (
        <Icon width='38' height='38' viewBox='0 0 38 38' {...props}>
            <g fill='none' fillRule='evenodd'>
                <path
                    fillRule='nonzero'
                    strokeLinecap='round'
                    strokeWidth='1.708'
                    d='M25.545 2.333C23.026.85 20.09 0 16.956 0 7.592 0 0 7.592 0 16.956c0 9.365 7.592 16.956 16.956 16.956 9.365 0 16.956-7.591 16.956-16.956'
                    transform='translate(1.879 1.879)'
                />
                <path
                    fillRule='nonzero'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='1.708'
                    d='M8.924 16.034L15.99 23.203 33.912 5.355'
                    transform='translate(1.879 1.879)'
                />
            </g>
        </Icon>
    );
};

export default IconDoDont;
