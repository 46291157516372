import {useEffect} from 'react';

import uiMessageRequestService from '../../services/iot/uiMessageRequestService';
import {IFwuInfo} from './useFWUInfoLoaded';

const usePublishCheckFirmware = (fwuInfo?: IFwuInfo, forceCheck = false): void => {
    const deps = forceCheck ? [] : [fwuInfo?.isLoaded];

    useEffect(() => {
        const isCheckingNeeded =
            fwuInfo && !fwuInfo.isAvailable && fwuInfo.fwuTarget && (!fwuInfo.isLoaded || forceCheck);

        if (isCheckingNeeded) {
            uiMessageRequestService().publishCheckFirmwareMessage(fwuInfo!.fwuTarget);
        }
    }, deps);
};

export default usePublishCheckFirmware;
