export const REGISTERING_LOADER = 'REGISTERING_LOADER';
export const DEVICE_SETTINGS_DEVICE_CONNECTED_LOADER = 'DEVICE_SETTINGS_DEVICE_CONNECTED_LOADER';
export const DEVICE_SETTINGS_DEVICE_SETTINGS_CHANGE_LOADER = 'DEVICE_SETTINGS_DEVICE_SETTINGS_CHANGE_LOADER';
export const AUTO_START_PAGE_LOADER = 'AUTO_START_PAGE_LOADER';
export const GESTURE_PAGE_LOADER = 'GESTURE_PAGE_LOADER';
export const CLEANING_MODE_PAGE_LOADER = 'CLEANING_MODE_PAGE_LOADER';
export const DEVICE_DIAGNOSTIC_PAGE = 'DEVICE_DIAGNOSTIC_PAGE';
export const REDIRECT_LOADER_NAME = 'REDIRECT_LOADER_NAME';
export const LED_MODE_PAGE_LOADER = 'LED_MODE_PAGE_LOADER';
export const RESPONSIVE_DRAW_PAGE_LOADER = 'RESPONSIVE_DRAW_PAGE_LOADER';
export const VAPE_CLOUD_SIZE_MODE_PAGE_LOADER = 'VAPE_CLOUD_SIZE_MODE_PAGE_LOADER';
export const VIBRATION_PAGE_LOADER = 'VIBRATION_PAGE_LOADER';
export const LOCK_LOADER = 'LOCK_LOADER';
export const PAIRING_IN_PROGRESS_LOADER = 'PAIRING_IN_PROGRESS_LOADER';
export const COUNTDOWN_PAGE_LOADER = 'COUNTDOWN_PAGE_LOADER';
export const TNC_PAGE_LOADER = 'TNC_PAGE_LOADER';
export const RESET_PAGE_LOADER = 'RESET_PAGE_LOADER';
export const CHECK_HOLDER_LOADER = 'CHECK_HOLDER_LOADER';
export const DEVICE_LIST_LOADER = 'DEVICE_LIST_LOADER';
export const LOADER_RESET = 'LOADER_RESET';
