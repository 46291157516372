import appConfig from '../config/appConfig';
import ModalTypes from '../enums/app/modalTypes';
import {showReconnectDeviceGuideModalAction} from '../state/ducks/modal';
import {makeSelectIsReconnectGuideEnabled} from '../state/selectors/global';
import {makeSelectDeviceConnectionStatus} from '../state/selectors/iotDevice';
import {dispatch, getState} from '../state/store';
import connectivityService from './device/connectivityService';
import iotDeviceConnectionStatusService from './iotDevice/iotDeviceConnectionStatusService';
import modalService from './modalService';

const showFailedReconnectDeviceGuide = () => {
    const isReconnectGuideEnabled = makeSelectIsReconnectGuideEnabled()(getState());
    const isReconnectDeviceGuideEnabled = appConfig.getIsReconnectDeviceGuideEnabled();
    const isBle = connectivityService.isBle();
    const isVisibleDeviceConnectionDetector = isReconnectDeviceGuideEnabled && isBle && isReconnectGuideEnabled;

    if (isVisibleDeviceConnectionDetector) {
        const connectionStatus = makeSelectDeviceConnectionStatus()(getState());
        const isDeviceReady = iotDeviceConnectionStatusService.isDeviceReady(connectionStatus);

        if (!isDeviceReady) {
            modalService.hideModal(ModalTypes.RECONNECT_DEVICE_GUIDE);
            dispatch(showReconnectDeviceGuideModalAction({isReconnectFailed: true}));
        }
    }
};

export default {showFailedReconnectDeviceGuide};
