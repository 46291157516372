import React, {FC, useEffect, useRef} from 'react';

import * as domElementIds from '../../../../../consts/app/domElementIds';
import * as localizationKeys from '../../../../../consts/localization/localizationKeys';
import domService from '../../../../../services/domService';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';

const RenameNotification: FC = () => {
    const localizedStrings = getLocalizedStrings();
    const myRef = useRef(null);

    useEffect(() => {
        if (myRef.current) {
            domService.setFocusToDomElement(myRef.current!);
        }

        return () => {
            const renameEl = document.getElementById(domElementIds.DEVICE_SETTINGS_RENAME);

            if (renameEl) {
                domService.setFocusToDomElement(renameEl);
            }
        };
    }, []);

    return (
        <span ref={myRef} tabIndex={-1}>
            {localizedStrings[localizationKeys.DEVICE_RENAME_NOTIFICATION_SUCCESS_TEXT]}
        </span>
    );
};

export default RenameNotification;
