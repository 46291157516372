import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Route} from 'react-router-dom';
import {createStructuredSelector} from 'reselect';

import * as loaderNames from '../../consts/loader/loaderNames';
import * as routePaths from '../../consts/route/routePaths';
import useHideLoader from '../../hooks/useHideLoader';
import appRouterService from '../../services/route/appRouterService';
import tncService from '../../services/tncService';
import {showLoader} from '../../state/ducks/global';
import {
    makeSelectAggregatedSettings,
    makeSelectConsumerAcceptedTnc,
    makeSelectConsumerTnc,
} from '../../state/selectors/consumer';
import {makeSelectTncPopupCodesList} from '../../state/selectors/tnc';
import {dispatch} from '../../state/store';
import DataCollectionPage from './DataCollectionPage/DataCollectionPage';
import PreferenceCenterPage from './DataCollectionPage/PreferenceCenter/PreferenceCenterPage';
import TermsOfUsePage from './TermsOfUsePage/TermsOfUsePage';

const mapStateToProps = createStructuredSelector({
    tncPopupCodesList: makeSelectTncPopupCodesList(),
    aggregatedSettings: makeSelectAggregatedSettings(),
    termsOfUse: makeSelectConsumerTnc(),
    acceptedTermsOfUse: makeSelectConsumerAcceptedTnc(),
});

const TNC = (props) => {
    const {tncPopupCodesList, aggregatedSettings, termsOfUse, acceptedTermsOfUse} = props;
    const isAggregatedSettingsLoaded = !!aggregatedSettings;

    useEffect(() => {
        if (!isAggregatedSettingsLoaded) {
            dispatch(showLoader(loaderNames.TNC_PAGE_LOADER));
        }
    }, [isAggregatedSettingsLoaded]);

    const {allNonMandatoryTermsReviewed, allMandatoryTermsApproved} = aggregatedSettings || {};

    useEffect(() => {
        if (!termsOfUse || !acceptedTermsOfUse) return;

        if (!allMandatoryTermsApproved) {
            appRouterService.forwardToTNCTermsOfUsePage();
        } else if (!allNonMandatoryTermsReviewed) {
            appRouterService.forwardToTNCDataCollectionPage();
        }
    }, [termsOfUse, acceptedTermsOfUse]);

    useEffect(() => {
        if (!isAggregatedSettingsLoaded) return;

        if (!allMandatoryTermsApproved || !allNonMandatoryTermsReviewed) {
            tncService.getTncData();
        } else {
            appRouterService.forwardToMyDevicesPage();
        }
    }, [isAggregatedSettingsLoaded, allMandatoryTermsApproved, allNonMandatoryTermsReviewed]);

    useHideLoader(isAggregatedSettingsLoaded, loaderNames.TNC_PAGE_LOADER);

    return (
        !tncPopupCodesList?.length && (
            <>
                <Route path={routePaths.TNC_TERMS_OF_USE} component={TermsOfUsePage} />
                <Route path={routePaths.TNC_DATA_COLLECTION} component={DataCollectionPage} />
                <Route path={routePaths.TNC_DATA_PREFERENCE} component={PreferenceCenterPage} />
            </>
        )
    );
};

export default connect(mapStateToProps)(TNC);
