import urlUtils from '../../utils/urlUtils';

export default class ConfigClient {
    constructor(marketConfigsList) {
        this.marketConfigsList = marketConfigsList;
    }

    getMarketConfigImport(market) {
        const currentUrl = urlUtils.getOriginLocation();
        const href = market ? urlUtils.join(currentUrl, market.toLowerCase()) : urlUtils.getCurrentLocation();
        const marketConfigImport = this.marketConfigsList.find(({urls}) => urls.some((url) => href.startsWith(url)));

        return marketConfigImport;
    }

    async getMarketConfig(market) {
        const marketConfigImport = this.getMarketConfigImport(market);
        const configResponse = await marketConfigImport?.configImport();

        return configResponse?.default;
    }

    async getConfig(market) {
        const marketConfig = await this.getMarketConfig(market);

        return {...marketConfig, isMarketInitialized: !!marketConfig};
    }
}
