import * as errorPageTypes from '../../consts/app/errorPageTypes';
import * as httpErrorsTypes from '../../consts/app/httpErrorsTypes';
import * as logMessageTypes from '../../consts/app/logMessageTypes';
import * as uamMethods from '../../consts/uam/uamMethods';
import {
    makeSelectHolderUamAuthToken,
    makeSelectUamApiKey,
    makeSelectUamApiUrl,
    makeSelectUamAuthToken,
    makeSelectUamIdentityKey,
} from '../../state/selectors/auth';
import {makeSelectIsHolderByAssetId} from '../../state/selectors/yapEncrypted';
import {dispatch, getState} from '../../state/store';
import arrayUtils from '../../utils/arrayUtils';
import stringUtils from '../../utils/stringUtils';
import urlUtils from '../../utils/urlUtils';
import appErrorService from '../app/appErrorService';
import authDataStoreService from '../auth/authDataStoreService';
import iccService from '../icc/iccService';
import log from '../logger/log';
import appRouterService from '../route/appRouterService';
import server from '../server/server';

let isTokenRefreshInProgress = false;

const getUamApiUrl = (methodName) => {
    const uamApiUrl = makeSelectUamApiUrl()(getState());

    return urlUtils.join(uamApiUrl, methodName);
};

const getHeaders = (isHolder) => {
    const headers = {
        Accept: 'application/json',
    };

    const state = getState();
    const xApiKey = makeSelectUamApiKey()(state);

    if (xApiKey) {
        headers['X-API-Key'] = xApiKey;
    }

    const idToken = isHolder ? makeSelectHolderUamAuthToken()(state) : makeSelectUamAuthToken()(state);

    if (idToken) {
        headers['id_token'] = idToken;
    } else {
        appErrorService.showGlobalError();
    }

    return headers;
};

const errorCheck = async (
    error,
    onSuccess,
    ignoreErrorCodes = [],
    isNeedRedirectForOtherErrors = true,
    isHolderCall
) => {
    const status = error?.response?.status;

    ignoreErrorCodes = arrayUtils.toArray(ignoreErrorCodes);
    if (ignoreErrorCodes.includes(status)) return null;

    switch (status) {
        case httpErrorsTypes.NOT_AUTHORIZED: {
            if (!isTokenRefreshInProgress) {
                isTokenRefreshInProgress = true;
                try {
                    //during the e2e Tap Testing call we agreed not to integrate with UAM Refresh-Token
                    //await refreshUamToken();
                    await iccService.fetchUamToken(false, isHolderCall);
                    isTokenRefreshInProgress = false;
                    return onSuccess ? onSuccess(isHolderCall) : null;
                } catch (e) {
                    isTokenRefreshInProgress = false;
                }
            }
            break;
        }
        default:
            if (isNeedRedirectForOtherErrors) {
                appRouterService.forwardToErrorHandlingPage(errorPageTypes.UAM_TEMPORARY_ERROR);
            }
            return null;
    }
};

//during the e2e Tap Testing call we agreed not to integrate with UAM Refresh-Token
// eslint-disable-next-line no-unused-vars
const refreshUamToken = async () => {
    const uamIdentityKey = makeSelectUamIdentityKey()(getState());
    const url = stringUtils.formatString(getUamApiUrl(uamMethods.REFRESH_TOKEN), uamIdentityKey);

    try {
        const response = await server.get(url, {headers: getHeaders()});

        isTokenRefreshInProgress = false;

        if (response.data) {
            await authDataStoreService.setUamApiData(response.data);
        }

        log.debug(
            `uamClient: refreshUamToken, request: "${uamMethods.REFRESH_TOKEN}" has successful response`,
            logMessageTypes.UAM
        );
    } catch (e) {
        log.error(
            `uamClient: refreshUamToken, request: "${uamMethods.REFRESH_TOKEN}", error: ${e}`,
            logMessageTypes.UAM
        );
        throw e;
    }
};

const getMethodUrl = ({methodName, requestConfig}) => {
    const args = requestConfig?.args;
    let methodUrl = methodName;

    if (args) {
        const params = arrayUtils.toArray(args);

        methodUrl = stringUtils.formatString(methodName, ...params);
    }
    return methodUrl;
};

const callGet = (options) => {
    options.methodUrl = getMethodUrl(options);
    options.httpMethod = server.get;
    return callRequest(options);
};

const callPost = (options) => {
    options.methodUrl = getMethodUrl(options);
    options.httpMethod = server.post;
    return callRequest(options);
};

const callRequest = async (params) => {
    try {
        const {action, mapper} = params;
        const response = await callUamMethod(params);

        if (!response) return null;

        let {data} = response;

        if (mapper) {
            data = mapper(data);
        }

        if (action) {
            dispatch(action(data));
        } else {
            return data;
        }
    } catch (e) {
        return null;
    }
};

const callUamMethod = async ({
    httpMethod,
    methodUrl,
    requestConfig,
    ignoreErrorCodes,
    isNeedRedirectForOtherErrors,
    isHolder,
}) => {
    const url = getUamApiUrl(methodUrl);
    const args = requestConfig?.args;
    const getAssetId = () => (Array.isArray(args) ? args[0] : args);
    const isHolderAssetId = makeSelectIsHolderByAssetId(getAssetId())(getState());
    const isHolderCall = isHolder || isHolderAssetId;
    const headers = requestConfig?.headers ? requestConfig.headers : getHeaders(isHolderCall);
    const params = requestConfig?.params ? requestConfig?.params : {};
    const data = requestConfig?.data ? requestConfig.data : {};

    try {
        const response = await httpMethod(url, {headers, params, data});

        log.debug(`uamClientService: request: '${methodUrl}' has successful response`);

        return response;
    } catch (e) {
        return await errorCheck(
            e,
            async (isHolderCall) => {
                return await httpMethod(url, getUamRequestParams(headers, params, data, isHolderCall));
            },
            ignoreErrorCodes,
            isNeedRedirectForOtherErrors,
            isHolderCall
        );
    }
};

const getUamRequestParams = (headers, params, data, isHolder) => {
    const newHeaders = getHeaders(isHolder);

    headers = {...headers, ...newHeaders};
    return {headers, params, data};
};

export default {
    errorCheck,
    getUamApiUrl,
    getHeaders,
    callGet,
    callPost,
};
