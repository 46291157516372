import * as notificationTypes from '../../consts/app/notificationTypes';
import helpers from '../../utils/helpers';

export const SET_IS_APP_LOADING = 'app/global/SET_IS_APP_LOADING';
export const SET_IS_LOADER_VISIBLE = 'app/global/SET_IS_LOADER_VISIBLE';
export const SET_IS_BLUETOOTH_AVAILABLE = 'app/global/SET_IS_BLUETOOTH_AVAILABLE';
export const SET_NOTIFICATION = 'app/global/SET_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'app/global/REMOVE_NOTIFICATION';
export const SET_LAYOUT = 'app/global/layout/SET_LAYOUT';
export const HIDE_ALL_LOADERS = 'app/global/HIDE_ALL_LOADERS';
export const SET_IS_NETWORK_ONLINE = 'app/global/SET_IS_NETWORK_ONLINE';
export const LOGOUT_CLEAR_DATA = 'app/global/LOGOUT_CLEAR_DATA';
export const SET_IS_RECONNECT_GUIDE_AVAILABLE = 'app/global/SET_IS_RECONNECT_GUIDE_AVAILABLE';
export const SET_IS_HOLDER_CONNECT_AVAILABLE = 'app/global/SET_IS_HOLDER_CONNECT_AVAILABLE';
export const SET_IS_DOUBLE_REGISTRATION = 'app/global/SET_IS_DOUBLE_REGISTRATION';
export const SET_SCP_HOLDER_INFO_CHECK_IN_PROGRESS = 'app/global/SET_SCP_HOLDER_INFO_CHECK_IN_PROGRESS';
export const SET_IS_HOLDER_REGISTER_FAILED = 'app/global/SET_IS_HOLDER_REGISTER_FAILED';
export const SET_IS_SKIP_REG_AND_SYNC_FLOW = 'app/global/SET_IS_SKIP_REG_AND_SYNC_FLOW';

export default function reducer(
    state = {
        isAppLoading: true,
        loaders: [],
        notifications: [],
        isBluetoothAvailable: undefined,
        isReconnectGuideEnabled: true,
        isHolderConnectEnabled: true,
        isNetworkOnline: true,
        isScpHolderInfoCheckInProgress: false,
        isHolderRegisterFailed: false,
        isSkipRegAndSyncFlow: false,
        isDoubleRegistration: false,
    },
    action
) {
    switch (action.type) {
        case SET_IS_APP_LOADING: {
            const isAppLoading = action.payload;

            return {...state, isAppLoading};
        }
        case SET_IS_LOADER_VISIBLE: {
            const {name, text, isVisible, isOpaque} = action.payload;
            const {loaders} = state;
            let loadersNew = [...loaders];

            if (isVisible) {
                loadersNew.push({name, text, isOpaque});
            } else {
                loadersNew = loaders.filter((e) => e.name !== name);
            }

            return {...state, loaders: loadersNew};
        }
        case SET_IS_BLUETOOTH_AVAILABLE: {
            const isBluetoothAvailable = action.payload;

            return {...state, isBluetoothAvailable};
        }
        case SET_NOTIFICATION: {
            const {type, text, testId, notificationType, autoFocus, customId} = action.payload;
            const {notifications} = state;
            const notificationsNew = [...notifications];

            if (notificationsNew.some((el) => el.text === text)) return state;

            const notification = {
                notificationId: customId ? customId : helpers.guid(),
                type,
                text,
                testId,
                notificationType,
                autoFocus,
            };

            notificationsNew.push(notification);

            return {...state, notifications: notificationsNew};
        }
        case REMOVE_NOTIFICATION: {
            const {notificationId} = action.payload;
            const {notifications} = state;
            const notificationsNew = notifications.filter(
                (notification) => notification.notificationId !== notificationId
            );

            return {...state, notifications: notificationsNew};
        }
        case HIDE_ALL_LOADERS: {
            return {...state, loaders: []};
        }
        case SET_LAYOUT: {
            const layout = action.payload;

            return {...state, layout};
        }
        case SET_IS_NETWORK_ONLINE: {
            return {
                ...state,
                isNetworkOnline: action.payload,
            };
        }
        case LOGOUT_CLEAR_DATA: {
            return {
                ...state,
                isAppLoading: false,
            };
        }
        case SET_IS_RECONNECT_GUIDE_AVAILABLE: {
            return {
                ...state,
                isReconnectGuideEnabled: action.payload,
            };
        }
        case SET_IS_HOLDER_CONNECT_AVAILABLE: {
            return {
                ...state,
                isHolderConnectEnabled: action.payload,
            };
        }
        case SET_IS_DOUBLE_REGISTRATION: {
            return {
                ...state,
                isDoubleRegistration: action.payload,
            };
        }
        case SET_SCP_HOLDER_INFO_CHECK_IN_PROGRESS: {
            return {
                ...state,
                isScpHolderInfoCheckInProgress: action.payload,
            };
        }
        case SET_IS_HOLDER_REGISTER_FAILED: {
            return {
                ...state,
                isHolderRegisterFailed: action.payload,
            };
        }
        case SET_IS_SKIP_REG_AND_SYNC_FLOW: {
            return {
                ...state,
                isSkipRegAndSyncFlow: action.payload,
            };
        }
        default:
            return state;
    }
}

export const setIsAppLoaded = () => ({
    type: SET_IS_APP_LOADING,
    payload: false,
});

const setNotification = (props) => {
    const {text, type, testId, notificationType, autoFocus, customId} = props;

    return {
        type: SET_NOTIFICATION,
        payload: {text, type, testId, notificationType, autoFocus, customId},
    };
};

export const setLayout = (layout) => ({type: SET_LAYOUT, payload: layout});
export const setNotificationSuccess = (text, testId, notificationType, autoFocus, customId) =>
    setNotification({text, type: notificationTypes.SUCCESS, testId, notificationType, autoFocus, customId});
export const setNotificationFailed = (text, testId, customId) =>
    setNotification({
        text,
        type: notificationTypes.FAILED,
        testId,
        customId,
    });
export const setNotificationInfo = (text, testId, notificationType, customId) =>
    setNotification({text, type: notificationTypes.INFO, testId, notificationType, customId});

export const removeNotification = (notificationId) => ({
    type: REMOVE_NOTIFICATION,
    payload: {notificationId},
});
export const hideAllLoaders = () => ({type: HIDE_ALL_LOADERS});

export const logoutClearData = () => ({type: LOGOUT_CLEAR_DATA});
export const setIsReconnectGuideAvailable = (data) => ({type: SET_IS_RECONNECT_GUIDE_AVAILABLE, payload: data});
export const setIsHolderConnectAvailable = (data) => ({type: SET_IS_HOLDER_CONNECT_AVAILABLE, payload: data});
export const setScpHolderCheckInProgress = (data) => ({type: SET_SCP_HOLDER_INFO_CHECK_IN_PROGRESS, payload: data});
export const setHolderRegisterFailed = (data) => ({type: SET_IS_HOLDER_REGISTER_FAILED, payload: data});

export const setIsDoubleRegistration = (data) => ({
    type: SET_IS_DOUBLE_REGISTRATION,
    payload: data,
});

export const setIsSkipRegAndSyncFlow = (data) => ({type: SET_IS_SKIP_REG_AND_SYNC_FLOW, payload: data});

export const showLoader = (props) => {
    const name = typeof props === 'string' ? props : props?.name;
    const {isOpaque, text} = props || {};

    return setIsLoaderVisible({name, text, isVisible: true, isOpaque});
};

export const hideLoader = (name) => {
    return setIsLoaderVisible({name, isVisible: false});
};

const DEFAULT_LOADER_NAME = 'default';

export const setIsLoaderVisible = ({name = DEFAULT_LOADER_NAME, text, isVisible, isOpaque}) => {
    return {type: SET_IS_LOADER_VISIBLE, payload: {name, text, isVisible, isOpaque}};
};

export const setIsNetworkOnline = (data) => ({type: SET_IS_NETWORK_ONLINE, payload: data});
export const setIsBluetoothAvailable = (data) => ({type: SET_IS_BLUETOOTH_AVAILABLE, payload: data});
