import * as productRegistrationStatusTypes from '../../consts/product/productRegistrationStatusTypes';
import productHelpers from '../../services/product/productHelpers';

export const CLEAR_REGISTRATION_STATUS = 'app/deviceSettings/CLEAR_REGISTRATION_STATUS';
export const SET_DEVICE_PURCHASE_DATA = 'app/deviceSettings/SET_DEVICE_PURCHASE_DATA';
export const SET_HOLDER_PURCHASE_DATA = 'app/deviceSettings/SET_HOLDER_PURCHASE_DATA';
export const REMOVE_PRODUCT_TO_CHANGE_REGISTRATION_STATUS =
    'app/deviceSettings/REMOVE_PRODUCT_TO_CHANGE_REGISTRATION_STATUS';
export const REMOVE_SCHEDULER_FROM_PRODUCTS_TO_CHANGE_REGISTRATION_STATUS =
    'app/deviceSettings/REMOVE_SCHEDULER_FROM_PRODUCTS_TO_CHANGE_REGISTRATION_STATUS';
export const SET_PRODUCT_TO_CHANGE_REGISTRATION_STATUS = 'app/deviceSettings/SET_PRODUCT_TO_CHANGE_REGISTRATION_STATUS';

const initialData = {
    productsToChangeRegistrationStatus: [],
    devicePurchaseData: undefined,
    holderPurchaseData: undefined,
};

export default function reducer(state = initialData, action) {
    switch (action.type) {
        case SET_PRODUCT_TO_CHANGE_REGISTRATION_STATUS: {
            const {
                deviceSerialNumber,
                codentify,
                isP4,
                status,
                name,
                isHolder,
                isSchedulerRequired = true,
            } = action.payload;
            const {productsToChangeRegistrationStatus = []} = state;
            const createTs = Date.now();
            const isProductStatusAlreadyExist = productHelpers.someProduct({
                products: productsToChangeRegistrationStatus,
                isP4,
                deviceSerialNumber,
                codentify,
            });

            let newList = [];

            if (isProductStatusAlreadyExist) {
                newList = productsToChangeRegistrationStatus.map((product) => {
                    return isP4
                        ? product.codentify === codentify
                        : product.deviceSerialNumber === deviceSerialNumber
                        ? {
                              ...product,
                              status,
                              isSchedulerRequired,
                              createTs,
                              name,
                              isHolder,
                          }
                        : product;
                });
            } else {
                newList = [
                    ...productsToChangeRegistrationStatus,
                    {
                        deviceSerialNumber,
                        codentify,
                        status,
                        isSchedulerRequired,
                        createTs,
                        name,
                        isHolder,
                    },
                ];
            }

            return {
                ...state,
                productsToChangeRegistrationStatus: newList,
            };
        }
        case REMOVE_SCHEDULER_FROM_PRODUCTS_TO_CHANGE_REGISTRATION_STATUS: {
            const {productsToChangeRegistrationStatus} = state;
            // eslint-disable-next-line no-unused-vars
            const newList = productsToChangeRegistrationStatus.map(({isSchedulerRequired, ...rest}) => ({...rest}));

            return {
                ...state,
                productsToChangeRegistrationStatus: newList,
            };
        }
        case REMOVE_PRODUCT_TO_CHANGE_REGISTRATION_STATUS: {
            const {isP4, deviceSerialNumber, codentify} = action.payload;
            const {productsToChangeRegistrationStatus} = state;
            const newList = productHelpers.filterProduct({
                products: productsToChangeRegistrationStatus,
                isP4,
                deviceSerialNumber,
                codentify,
            });

            return {
                ...state,
                productsToChangeRegistrationStatus: newList,
            };
        }
        case CLEAR_REGISTRATION_STATUS: {
            return {
                ...state,
                productsToChangeRegistrationStatus: [],
            };
        }
        case SET_DEVICE_PURCHASE_DATA: {
            return {
                ...state,
                devicePurchaseData: action.payload,
            };
        }
        case SET_HOLDER_PURCHASE_DATA: {
            return {
                ...state,
                holderPurchaseData: action.payload,
            };
        }
        default:
            return state;
    }
}

export const setProductToChangeRegisteringStatus = (
    isP4,
    deviceSerialNumber,
    codentify,
    name,
    isHolder,
    isSchedulerRequired
) =>
    setProductToChangeRegistrationStatus({
        deviceSerialNumber,
        codentify,
        isHolder,
        isP4,
        name,
        status: productRegistrationStatusTypes.REGISTERING,
        isSchedulerRequired,
    });
export const setProductToChangeUnregisteringStatus = (isP4, deviceSerialNumber, codentify, isHolder) =>
    setProductToChangeRegistrationStatus({
        deviceSerialNumber,
        codentify,
        isHolder,
        isP4,
        status: productRegistrationStatusTypes.UNREGISTERING,
    });
export const clearProductsToChangeRegistrationStatus = () => ({
    type: CLEAR_REGISTRATION_STATUS,
});
export const removeProductToChangeRegistrationStatus = (data) => ({
    type: REMOVE_PRODUCT_TO_CHANGE_REGISTRATION_STATUS,
    payload: data,
});
export const removeSchedulerFromProductsToChangeRegistrationStatus = () => ({
    type: REMOVE_SCHEDULER_FROM_PRODUCTS_TO_CHANGE_REGISTRATION_STATUS,
});
export const setProductToChangeRegistrationStatus = (data) => ({
    type: SET_PRODUCT_TO_CHANGE_REGISTRATION_STATUS,
    payload: data,
});
export const setDevicePurchaseData = (data) => ({
    type: SET_DEVICE_PURCHASE_DATA,
    payload: data,
});
export const setHolderPurchaseData = (data) => ({
    type: SET_HOLDER_PURCHASE_DATA,
    payload: data,
});
