import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {showLoader} from '../../state/ducks/global';

const LoadableComponentLoader = (props) => {
    const {isOpaque, name, text, delay} = props;
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const timer = setTimeout(() => setIsLoading(true), delay);

        return () => clearTimeout(timer);
    });

    useEffect(() => {
        if (isLoading) {
            dispatch(showLoader({isOpaque, name, text}));
        }
    }, [isLoading]);

    return null;
};

export default LoadableComponentLoader;
