import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import useDidUpdate from '../../../hooks/useDidUpdate';
import ErrorHandlerService from '../../../services/errorHandlerService';
import PairingHandlerService from '../../../services/pairingHandlerService';
import {makeSelectIsDeviceSyncInProgress} from '../../../state/selectors/yapEncrypted';

const mapStateToProps = createStructuredSelector({
    isDeviceSyncInProgress: makeSelectIsDeviceSyncInProgress(),
});

const GamPairingChecker = ({isDeviceSyncInProgress}) => {
    useDidUpdate(() => {
        if (isDeviceSyncInProgress) {
            new PairingHandlerService().pairingIsStarted();
        } else {
            new ErrorHandlerService().checkGamError();
            new PairingHandlerService().pairingIsStopped();
        }
    }, [isDeviceSyncInProgress]);

    return null;
};

export default connect(mapStateToProps)(GamPairingChecker);
