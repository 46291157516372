import appConfig from '../config/configGetters/configAppLinksGetters';
import * as analyticsCategories from '../consts/analytics/analyticsCategories';
import * as browserCompatibleSelectedOptionsData from '../consts/analytics/browserCompatibleSelectedOptionsData';
import REGISTRATION_ERRORS_DATA from '../consts/analytics/registrationErrorsData';
import {makeSelectAggregatedSettings} from '../state/selectors/consumer';
import {makeSelectIotDeviceMergedWithIccProduct} from '../state/selectors/consumer';
import {getState} from '../state/store';
import urlUtils from '../utils/urlUtils';
import deviceTypesService from './device/deviceTypesService';
import consumerAggregatedSettingsService from './icc/consumerAggregatedSettingsService';

const pushToDataLayer = (message) => {
    const aggregatedSettings = makeSelectAggregatedSettings()(getState());
    const dataLayer = window.dataLayer;

    if (dataLayer) {
        if (aggregatedSettings) {
            const isGAEnabled = consumerAggregatedSettingsService.isEnabledGA();

            if (isGAEnabled) {
                dataLayer.push(message);
            }
        } else {
            dataLayer.push(message);
        }
    }
};

const getDataLayerObject = ({eventAction, eventNonInteraction = false, eventLabel, ...rest}) => {
    return {
        event: 'iqosConnectWeb',
        eventCategory: 'Iqos Connect Web',
        eventAction,
        eventLabel,
        eventNonInteraction,
        ...rest,
    };
};

const getDataLayerObjectWithDeviceNameAndPlatform = ({type, ...props}) => {
    const deviceModel = deviceTypesService.getDeviceModelByType(type);
    const devicePlatform = deviceTypesService.getDevicePlatform(type);

    return getDataLayerObject({deviceModel, devicePlatform, ...props});
};

const pushAppLoadedEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'web app loaded',
            eventNonInteraction: true,
        })
    );
};

const pushBrowserCompatibilityEvent = (isSuccess, isBluefy) => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'browser compatibility check',
            eventLabel: isSuccess ? 'success' : 'fail',
            browser: isBluefy ? 'bluefy' : 'other',
            eventNonInteraction: true,
        })
    );
};

const pushBrowserDownloadEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Click - CTA',
            eventLabel: 'download browser',
        })
    );
};

const pushTNCTermsOfUseInteractionEvent = (isSuccess) => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Click - CTA - terms of use',
            eventLabel: isSuccess ? 'agree' : 'decline',
        })
    );
};

const pushTNCDataCollectionInteractionEvent = (isSuccess) => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Click - CTA - data collection',
            eventLabel: isSuccess ? 'agree' : 'decline',
        })
    );
};

const pushPrePairingAddNewDeviceEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Click - CTA - pre-pairing',
            eventLabel: 'add new',
        })
    );
};

const pushPrePairingPairDeviceEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Click - CTA - pre-pairing',
            eventLabel: 'pair device',
        })
    );
};

const pushPrePairingPairRegisteredDeviceEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Click - CTA - pre-pairing',
            eventLabel: 'pair device - already registered',
            eventNonInteraction: false,
        })
    );
};

const pushPrePairingPairDisconnectedDeviceEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Click - CTA - pre-pairing',
            eventLabel: 'pair device - disconnected',
            eventNonInteraction: false,
        })
    );
};

const pushPrePairingSetupNewIqosEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Click - CTA - pre-pairing',
            eventLabel: 'set up a new iqos',
        })
    );
};

const pushPairingDeviceSelectionEvent = (type, isNewDevice) => {
    const deviceModel = deviceTypesService.getDeviceModelByType(type);

    pushToDataLayer(
        getDataLayerObjectWithDeviceNameAndPlatform({
            eventAction: 'Pairing flow - step 1',
            eventLabel: `selected device - ${deviceModel} - ${isNewDevice ? 'new' : 'already registered'}`,
            type,
        })
    );
};

const pushPairingTurnOnDeviceEvent = (type) => {
    pushToDataLayer(
        getDataLayerObjectWithDeviceNameAndPlatform({
            eventAction: 'Pairing flow - step 2',
            eventLabel: 'start pairing guide',
            type,
        })
    );
};

const pushPairingBluetoothEnabledEvent = (type) => {
    pushToDataLayer(
        getDataLayerObjectWithDeviceNameAndPlatform({
            eventAction: 'Pairing flow - step 3',
            eventLabel: 'request bluetooth system window',
            type,
        })
    );
};

const pushPairingDevicePairingEvent = (type) => {
    pushToDataLayer(
        getDataLayerObjectWithDeviceNameAndPlatform({
            eventAction: 'Pairing flow - step 4',
            eventLabel: 'pairing device',
            type,
        })
    );
};

const pushPairingSuccessfulEvent = (type) => {
    pushToDataLayer(
        getDataLayerObjectWithDeviceNameAndPlatform({
            eventAction: 'Pairing flow - step 5',
            eventLabel: 'successfully paired',
            type,
        })
    );
};

const pushPairingFailedEvent = (errorType, type) => {
    pushToDataLayer(
        getDataLayerObjectWithDeviceNameAndPlatform({
            eventAction: 'Pairing flow - step error',
            eventLabel: errorType,
            type,
        })
    );
};

const pushPairingQuitSetupEvent = (type) => {
    pushToDataLayer(
        getDataLayerObjectWithDeviceNameAndPlatform({
            eventAction: 'Pairing flow - step quit',
            eventLabel: 'quit set up',
            type,
        })
    );
};

const pushActivationDeviceActivatingEvent = (type) => {
    pushToDataLayer(
        getDataLayerObjectWithDeviceNameAndPlatform({
            eventAction: 'Activation flow - step 1',
            eventLabel: 'activating device',
            type,
        })
    );
};

const pushActivationDeviceActivatedSuccessEvent = (type) => {
    pushToDataLayer(
        getDataLayerObjectWithDeviceNameAndPlatform({
            eventAction: 'Activation flow - step 2',
            eventLabel: 'successfully activated',
            type,
        })
    );
};

const pushErrorEvent = (errorCode, errorType, isActivationEvent) => {
    const iotProduct = makeSelectIotDeviceMergedWithIccProduct()(getState());
    const {device} = iotProduct || {};
    const {type} = device || {};

    return pushToDataLayer(
        getDataLayerObjectWithDeviceNameAndPlatform({
            eventAction: `${isActivationEvent ? 'Activation' : 'Registration'} flow - step ${
                isActivationEvent ? '2 - ' : ''
            }error`,
            eventLabel: `${isActivationEvent ? errorType : errorCode + ' | ' + errorType}`,
            type,
        })
    );
};

const pushUsageMyDevicesScreenviewEvent = () => {
    const urlPath = urlUtils.getCurrentPathname();

    pushToDataLayer({
        event: 'iqosConnectWebScreenview',
        urlPath,
    });
};

const pushUsageSettingsInteractionEvent = (category) => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Click - CTA - settings update',
            eventLabel: category,
        })
    );
};

const pushUsageMyDevicesEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Device list',
            eventLabel: 'device list',
        })
    );
};

const pushUsageDeviceSettingsEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Device Settings',
            eventLabel: 'device settings',
        })
    );
};

const pushUsageSettingsRenameEvent = () => pushUsageSettingsInteractionEvent(analyticsCategories.AC_RENAME);
const pushUsageSettingsAutoCleaningEvent = () =>
    pushUsageSettingsInteractionEvent(analyticsCategories.AC_AUTO_CLEANING);
const pushUsageSettingsDiagnosticEvent = () => pushUsageSettingsInteractionEvent(analyticsCategories.AC_DIAGNOSTIC);
const pushUsageSettingsVibrationsEvent = () => pushUsageSettingsInteractionEvent(analyticsCategories.AC_VIBRATIONS);
const pushUsageSettingsLedModeEvent = () => pushUsageSettingsInteractionEvent(analyticsCategories.AC_LIGHT_MODE);
const pushUsageSettingsFindDeviceEvent = () => pushUsageSettingsInteractionEvent(analyticsCategories.AC_FIND_DEVICE);
const pushUsageSettingsFwUpdateEvent = () => pushUsageSettingsInteractionEvent(analyticsCategories.AC_FW_UPDATE);
const pushUsageSettingsResponsiveDrawModeEvent = () =>
    pushUsageSettingsInteractionEvent(analyticsCategories.AC_RESPONSIVE_DRAW);
const pushUsageSettingsVapeCloudSizeModeEvent = () =>
    pushUsageSettingsInteractionEvent(analyticsCategories.AC_VAPE_CLOUD_sIZE);
const pushUsageSettingsTipsAndTutorialsEvent = () =>
    pushUsageSettingsInteractionEvent(analyticsCategories.AC_TIPS_AND_TUTORIALS);
const pushUsageSettingsDeviceResetEvent = () => pushUsageSettingsInteractionEvent(analyticsCategories.AC_RESET);
const pushUsageSettingsUnpairDeviceEvent = () =>
    pushUsageSettingsInteractionEvent(analyticsCategories.AC_UNPAIR_DEVICE);
const pushUsageSettingsStoreLocatorEvent = () =>
    pushUsageSettingsInteractionEvent(analyticsCategories.AC_STORE_LOCATOR);
const pushUsageSettingsPreferenceCenterEvent = () =>
    pushUsageSettingsInteractionEvent(analyticsCategories.AC_PREFERENCE_CENTER);
const pushUsageSettingsLegalAndPolicyEvent = () =>
    pushUsageSettingsInteractionEvent(analyticsCategories.AC_LEGAL_AND_POLICY);
const pushUsageSettingsAboutMyDeviceEvent = () =>
    pushUsageSettingsInteractionEvent(analyticsCategories.AC_ABOUT_MY_DEVICE);
const pushUsageSettingsLockDeviceEvent = () => pushUsageSettingsInteractionEvent(analyticsCategories.AC_LOCK_MY_DEVICE);
const pushUsageSettingsUnclockDeviceEvent = () =>
    pushUsageSettingsInteractionEvent(analyticsCategories.AC_UNLOCK_MY_DEVICE);
const pushUsageSettingsAutoStartEvent = () => pushUsageSettingsInteractionEvent(analyticsCategories.AC_AUTOSTART);
const pushUsageSettingsSmartGesturesEvent = () =>
    pushUsageSettingsInteractionEvent(analyticsCategories.AC_SMART_GESTURES);
const pushUsageSettingsResetDeviceEvent = () => pushUsageSettingsInteractionEvent(analyticsCategories.AC_RESET_DEVICE);

const pushActivationFailedError = (errorData) => {
    const {type} = errorData;

    pushErrorEvent(null, type, true);
};

const pushRegistrationFailedError = (key) => {
    pushErrorEvent(REGISTRATION_ERRORS_DATA[key].code, REGISTRATION_ERRORS_DATA[key].type);
};

const pushRegistrationSuccessfullyEvent = (type) => {
    pushToDataLayer(
        getDataLayerObjectWithDeviceNameAndPlatform({
            eventAction: 'Registration flow - successfully',
            eventLabel: 'Registration flow - successfully',
            type,
        })
    );
};

const pushRegistrationFlowStartEvent = (type) => {
    pushToDataLayer(
        getDataLayerObjectWithDeviceNameAndPlatform({
            eventAction: 'Registration flow - start',
            eventLabel: 'Registration flow - start',
            type,
        })
    );
};

const pushDeviceDiagnosticContactUsEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Click - CTA - device diagnostic',
            eventLabel: 'contact us',
            eventNonInteraction: false,
        })
    );
};

const getCompatibleSelectedOption = (link) => {
    switch (link) {
        case appConfig.getLinkAndroidStoreIqos():
            return browserCompatibleSelectedOptionsData.ANDROID_APP;
        case appConfig.getLinkAndroidStoreChrome():
            return browserCompatibleSelectedOptionsData.CHROME_MOBILE;
        case appConfig.getLinkIosStoreIqos():
            return;
        case appConfig.getLinkIosStoreBluenet():
            return browserCompatibleSelectedOptionsData.BLUEFY;
        case appConfig.getLinkMacWidget():
            return browserCompatibleSelectedOptionsData.WIDGET_MAC_OS_APP;
        case appConfig.getLinkWindowsWidget():
            return browserCompatibleSelectedOptionsData.WIDGET_WINDOWS_APP;
        case appConfig.getLinkDesktopChrome():
            return browserCompatibleSelectedOptionsData.CHROME_DESKTOP;
        default:
            return link;
    }
};

const pushBrowserCompatibileEvent = (link, isCompatible) => {
    const selectedOption = getCompatibleSelectedOption(link);

    pushToDataLayer(
        getDataLayerObject({
            eventAction: `Click - CTA - browser is ${isCompatible ? '' : 'not '}compatible`,
            eventLabel: `selected ${isCompatible ? 'app' : 'option'} - ${selectedOption}`,
        })
    );
};

const pushNotCompatibleIosVersionEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'iOS version is not compatible',
            eventLabel: 'iOS version is not compatible',
        })
    );
};

const pushConnectIotPlatformEvent = (type) => {
    pushToDataLayer(
        getDataLayerObjectWithDeviceNameAndPlatform({
            eventAction: 'Connect to IoT platform',
            eventLabel: 'Connect to IoT platform',
            type,
        })
    );
};

const pushConnectIotPlatformErrorEvent = (type) => {
    pushToDataLayer(
        getDataLayerObjectWithDeviceNameAndPlatform({
            eventAction: 'Connect to IoT platform - error',
            eventLabel: 'ERROR_CONNECTION_FAILED',
            type,
        })
    );
};

export default {
    pushActivationDeviceActivatedSuccessEvent,
    pushActivationDeviceActivatingEvent,
    pushActivationFailedError,
    pushAppLoadedEvent,
    pushBrowserCompatibileEvent,
    pushBrowserCompatibilityEvent,
    pushBrowserDownloadEvent,
    pushConnectIotPlatformErrorEvent,
    pushConnectIotPlatformEvent,
    pushDeviceDiagnosticContactUsEvent,
    pushNotCompatibleIosVersionEvent,
    pushPairingBluetoothEnabledEvent,
    pushPairingDevicePairingEvent,
    pushPairingDeviceSelectionEvent,
    pushPairingFailedEvent,
    pushPairingQuitSetupEvent,
    pushPairingSuccessfulEvent,
    pushPairingTurnOnDeviceEvent,
    pushPrePairingAddNewDeviceEvent,
    pushPrePairingPairDeviceEvent,
    pushPrePairingPairDisconnectedDeviceEvent,
    pushPrePairingPairRegisteredDeviceEvent,
    pushPrePairingSetupNewIqosEvent,
    pushRegistrationFailedError,
    pushRegistrationFlowStartEvent,
    pushRegistrationSuccessfullyEvent,
    pushTNCDataCollectionInteractionEvent,
    pushTNCTermsOfUseInteractionEvent,
    pushUsageDeviceSettingsEvent,
    pushUsageMyDevicesEvent,
    pushUsageMyDevicesScreenviewEvent,
    pushUsageSettingsAboutMyDeviceEvent,
    pushUsageSettingsAutoCleaningEvent,
    pushUsageSettingsDeviceResetEvent,
    pushUsageSettingsDiagnosticEvent,
    pushUsageSettingsFindDeviceEvent,
    pushUsageSettingsFwUpdateEvent,
    pushUsageSettingsLedModeEvent,
    pushUsageSettingsLegalAndPolicyEvent,
    pushUsageSettingsLockDeviceEvent,
    pushUsageSettingsPreferenceCenterEvent,
    pushUsageSettingsRenameEvent,
    pushUsageSettingsResponsiveDrawModeEvent,
    pushUsageSettingsStoreLocatorEvent,
    pushUsageSettingsTipsAndTutorialsEvent,
    pushUsageSettingsUnclockDeviceEvent,
    pushUsageSettingsUnpairDeviceEvent,
    pushUsageSettingsVapeCloudSizeModeEvent,
    pushUsageSettingsVibrationsEvent,
    pushUsageSettingsAutoStartEvent,
    pushUsageSettingsSmartGesturesEvent,
    pushUsageSettingsResetDeviceEvent,
};
