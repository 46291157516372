import React, {FC} from 'react';

import AppImage from '../AppImage';
import Image from '../Image';
import Loader from './components/Loader/Loader';

interface IProps {
    srcSet: string[];
    placeholderSrc: string[];
    isMediaUrlFetched: boolean;
    imgClassName: string;
    alt?: string;
    defaultImageSrcSet?: string[] | string;
    isLoader?: boolean;
}

const ProgressiveImage: FC<IProps> = (props) => {
    const {srcSet, placeholderSrc, isMediaUrlFetched, imgClassName, alt, defaultImageSrcSet, isLoader = false} = props;

    const loader = <Loader imgClassName={imgClassName} isLoader={isLoader} />;
    const unloader = <Image alt={alt} src={defaultImageSrcSet} className={imgClassName} />;

    const getImage: (
        isSigned: boolean,
        src: string | string[],
        placeholder: string | React.ReactNode
    ) => JSX.Element = (isSigned, src, placeholder) =>
        isSigned ? (
            <AppImage alt={alt} className={imgClassName} src={src} loader={placeholder} unloader={unloader} />
        ) : (
            loader
        );

    const placeholderImage = getImage(isMediaUrlFetched, placeholderSrc, loader);

    return getImage(isMediaUrlFetched, srcSet, placeholderImage);
};

export default ProgressiveImage;
