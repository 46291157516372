import React from 'react';

import Icon from './Icon';

const IconWarranty = (props) => {
    return (
        <Icon width='30' height='30' viewBox='0 0 30 30' {...props}>
            <g fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
                <g fillRule='nonzero' stroke='#272A33' strokeWidth='1.5'>
                    <path
                        d='M12.422 0L25 2.438l-.232.129.232-.01v8.002c-.004 7.303-4.028 12.873-12.073 16.711l-.427.2C4.17 23.628.004 17.99 0 10.56h0V2.556l.285.012L0 2.438 12.422 0z'
                        transform='translate(-208 -3354) translate(81 3215) translate(118 139) translate(9) translate(2 1) translate(.5)'
                    />
                    <path
                        d='M7.824 12.89L10.892 15.706 18.118 8.353'
                        transform='translate(-208 -3354) translate(81 3215) translate(118 139) translate(9) translate(2 1) translate(.5)'
                    />
                </g>
            </g>
        </Icon>
    );
};

export default IconWarranty;
