import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import dynamicMiddlewares from 'redux-dynamic-middlewares';
import {addMiddleware} from 'redux-dynamic-middlewares';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';

import appConfig from '../config/appConfig';
import * as reducers from './ducks';
import * as global from './ducks/global';

let dispatch: any;
let getState: any;

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: string;
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const configureStore = (initialState = {}) => {
    const appReducer = combineReducers(reducers);

    const rootReducer = (state: any, action: any) => {
        if (action.type === global.LOGOUT_CLEAR_DATA) {
            state = undefined;
        }

        return appReducer(state, action);
    };

    const middlewares = [promise, thunk];
    const isDebug = appConfig.getIsDebug();
    const middleware = applyMiddleware(...middlewares, dynamicMiddlewares);
    const composeEnhancers = (isDebug && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

    const store = createStore(rootReducer, initialState, composeEnhancers(middleware));

    dispatch = store.dispatch;
    getState = store.getState;

    return store;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const addReduxLoggerIfEnabled = async () => {
    if (appConfig.getIsDebug() && process.env.NODE_ENV !== 'test') {
        const {logger} = await import(/* webpackChunkName: "lazy-chunk.logger" */ 'redux-logger');

        addMiddleware(logger);
    }
};

export {configureStore, dispatch, getState, addReduxLoggerIfEnabled};
