import cn from 'classnames';
import React from 'react';

import SignedImage from '../../../../System/SignedImage';
import {IconProps, IRowItem} from '../../IconTextTypes';
import styles from './IconTextItem.module.scss';

const IconTextItem: React.FC<IRowItem> = (props) => {
    const {text, svgIcon, isCustomSkipPage, iconPath} = props;
    const descriptionClassName = cn(styles.Text, 'ica--txt-regular');

    return (
        <div className={styles.Container}>
            <div className={styles.ImageContainer}>
                <IconTemplate isCustomSkipPage={isCustomSkipPage} icon={svgIcon} iconPath={iconPath} />
            </div>
            <p className={descriptionClassName}>{text}</p>
        </div>
    );
};

const IconTemplate: React.FC<IconProps> = (props) => {
    const {icon, isCustomSkipPage, iconPath} = props;
    const customOptions = isCustomSkipPage
        ? {mediaData: iconPath, imgClassName: styles.Image, icon: SignedImage, signIfNull: true}
        : {className: styles.Image, icon};
    const {icon: Icon, ...restProps} = customOptions;

    if (!Icon) {
        return null;
    }

    return <Icon {...restProps} />;
};

export default IconTextItem;
