import {config} from '../config';

export default {
    getAwsLogLevel: () => config.data.logs.awsLogLevel,
    getIsAppVersionVisible: () => config.data.logs.isAppVersionVisible,
    getIsAwsLoggingEnabled: () => config.data.logs.isAwsLoggingEnabled,
    getIsDebug: () => config.data.logs.isDebug,
    getIsLogsVisible: () => config.data.logs.isLogsVisible,
    getLogLevel: () => config.data.logs.logLevel,
    getMqttDebugLevel: () => config.data.logs.mqttDebugLevel,
};
