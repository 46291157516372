import cn from 'classnames';
import React, {FC, KeyboardEvent, useState} from 'react';
import {ReactNode} from 'react';

import TermsOfUseDescription from '../../containers/TNC/TermsOfUseDescription/TermsOfUseDescription';
import domService from '../../services/domService';
import ImgArrowIcon from '../Icons/IconExpansionPanelArrow';
import styles from './ExpansionPanel.module.scss';

interface IProps {
    title: string;
    innerText: string;
    children: ReactNode;
    index: number;
}

const ExpansionPanel: FC<IProps> = ({title, innerText, children, index}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const arrowClassName = cn(styles.Arrow, {[styles.ArrowExpanded]: isExpanded});

    const onExpansionPanelClick = () => {
        setIsExpanded(!isExpanded);
    };
    const onKeyPress = (e: KeyboardEvent) => domService.handleBtnKeyPress(e, onExpansionPanelClick);

    return (
        <div className={styles.ExpansionPanel}>
            <header className={styles.Main}>
                <div className={styles.ArrowWrapper} onClick={onExpansionPanelClick}>
                    <ImgArrowIcon className={arrowClassName} />
                </div>
                <div
                    className={styles.TitleWrapper}
                    onClick={onExpansionPanelClick}
                    tabIndex={0}
                    aria-expanded={isExpanded}
                    onKeyPress={onKeyPress}
                    aria-label={title}
                    role='button'
                >
                    <p className='ica--txt-regular' id={`${index}`}>
                        {title}
                    </p>
                </div>
                {children}
            </header>
            {isExpanded && innerText && <TermsOfUseDescription innerHtml={innerText} className={styles.Body} />}
        </div>
    );
};

export default ExpansionPanel;
