import React from 'react';

import Icon from './Icon';

const CountdownIconNotActive = (props) => {
    return (
        <Icon width='84' height='84' viewBox='0 0 84 84' {...props}>
            <g fill='none' fillRule='evenodd'>
                <path
                    fill='#383839'
                    d='M0 0H84V84H0z'
                    transform='translate(-435.000000, -308.000000) translate(375.000000, 251.000000) translate(60.000000, 57.000000)'
                />
                <g
                    stroke='#E2E2E3'
                    strokeWidth='2'
                    transform='translate(-435.000000, -308.000000) translate(375.000000, 251.000000) translate(60.000000, 57.000000) translate(34.650000, 18.000000)'
                >
                    <path
                        strokeLinecap='round'
                        d='M12.706 20.118v11.054L11.432 45.24c-.18 1.973-1.539 3.466-3.157 3.466H4.43c-1.618 0-2.978-1.493-3.157-3.466L0 31.172V3.896C0 1.745 1.422 0 3.176 0H9.53c1.755 0 3.177 1.745 3.177 3.896v1.46'
                    />
                    <circle cx='23.294' cy='12.706' r='12.706' strokeLinejoin='round' />
                    <path d='M0 32L12.706 32' />
                    <path strokeLinecap='round' d='M19.896 8.958L27.383 16.445' />
                    <path
                        strokeLinecap='round'
                        d='M19.896 8.958L27.383 16.445'
                        transform='translate(23.639389, 12.701154) scale(-1, 1) translate(-23.639389, -12.701154)'
                    />
                </g>
            </g>
        </Icon>
    );
};

export default CountdownIconNotActive;
