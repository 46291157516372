import React from 'react';

import Icon from './Icon';

const DeviceSupport = (props) => {
    return (
        <Icon width='30' height='30' viewBox='0 0 30 30' {...props}>
            <g fill='none' fillRule='evenodd'>
                <path
                    stroke='#FFF'
                    strokeLinecap='round'
                    d='M15.888 16.01l8.875-8.875c.127-.127.285-.217.46-.262l1.285-.329c.287-.074.527-.272.655-.54l1.785-3.756a.499.499 0 0 0-.098-.568l-.783-.784A.502.502 0 0 0 27.5.798l-3.757 1.786a.999.999 0 0 0-.539.655l-.329 1.286a1.008 1.008 0 0 1-.262.46l-8.875 8.874'
                />
                <path
                    fill='#FFF'
                    d='M10.433 14.382c-.57 0-1.132.308-1.412.879a1.567 1.567 0 0 1-1.288.873l-.06.005c-.374.029-.725.19-.99.455L.88 22.399a2.998 2.998 0 0 0 0 4.242l2.22 2.22a2.99 2.99 0 0 0 2.121.879 2.99 2.99 0 0 0 2.122-.878l5.805-5.805a1.57 1.57 0 0 0 .454-.99l.005-.06a1.57 1.57 0 0 1 .874-1.288 1.569 1.569 0 0 0 .418-2.518l-3.359-3.36a1.552 1.552 0 0 0-1.106-.46M10.397 14l.048 1.645 3.534 3.637c.01.011.026.027.02.065-.007.038-.026.048-.04.054-1.022.515-1.71 1.561-1.798 2.728l-.025.112-6.107 6.284a1.546 1.546 0 0 1-2.231 0l-2.336-2.403a1.66 1.66 0 0 1 0-2.297l6.152-6.305.063-.004c1.134-.09 2.15-.8 2.651-1.85.007-.013.02-.042.069-.042V14'
                />
                <path stroke='#FFF' strokeLinecap='round' d='M4.666 25.074l3.693-3.693' />
            </g>
        </Icon>
    );
};

export default DeviceSupport;
