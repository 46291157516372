import * as cmHapticProfileTypes from '../../consts/communicationLayer/cmHapticProfileTypes';
import * as cmOnOffTypes from '../../consts/communicationLayer/cmOnOffTypes';
import * as autoStartModeTypes from '../../consts/iot/autoStartModeTypes';
import * as iotParentProtectionStatus from '../../consts/iot/iotParentProtectionStatus';
import * as responsiveDrawModeTypes from '../../consts/iot/responsiveDrawModeTypes';
import * as uamResponsiveDrawModeTypes from '../../consts/uam/uamResponsiveDrawModeTypes';
import * as uamUpdateSettingsTypes from '../../consts/uam/uamUpdateSettingsTypes';
import {makeSelectIotDeviceData} from '../../state/selectors/iotDevice';
import {getState} from '../../state/store';
import deviceTypesService from '../device/deviceTypesService';
import fwuTargetService from '../device/fwuTargetService';
import iotDeviceFWUStateService from '../iotDevice/iotDeviceFWUStateService';
import uamClientService from './uamClientService';

const publishConnectionCareMessage = () => {};

const publishVibrationAlarmMessage = async () => {
    const message = {
        action: 'START',
        durationSeconds: 30,
    };

    await uamClientService.findMyDevice(message);
};

const publishStopVibrationAlarmMessage = async () => {
    const message = {
        action: 'STOP',
    };

    await uamClientService.findMyDevice(message);
};

const publishGetLedSettingsMessage = () => {};

const publishSetLedSettingsMessage = async (mode, isDeviceLedModeEnabled, isHolderLedModeEnabled) => {
    if (!mode) return;

    const message = {
        settingName: uamUpdateSettingsTypes.LED_INTENSITY,
        newValue: {intensity: mode},
    };
    const iotDevice = makeSelectIotDeviceData()(getState());
    const {holder, device} = iotDevice?.ledData || {};
    const holderLed = holder?.intensity;
    const deviceLed = device?.intensity;

    if (isDeviceLedModeEnabled && mode !== deviceLed) {
        await uamClientService.updateUamSettings(message);
    }

    if (isHolderLedModeEnabled && mode !== holderLed) {
        await uamClientService.updateUamSettings(message, true);
    }
};

const publishParentProtectionEnableMessage = async () => {
    await publishParentProtectionStatusMessage(iotParentProtectionStatus.PARENT_PROTECTION_ENABLED);
};

const publishParentProtectionDisableMessage = async () => {
    await publishParentProtectionStatusMessage(iotParentProtectionStatus.PARENT_PROTECTION_DISABLED);
};

const publishParentProtectionStatusMessage = async (protectionType) => {
    if (!protectionType) return;

    const iotDevice = makeSelectIotDeviceData()(getState());
    const type = iotDevice?.device?.type;
    const isMonoDevice = deviceTypesService.isMono(type);
    const message = {
        settingName: uamUpdateSettingsTypes.PARENT_PROTECTION,
        newValue: {protection_type: protectionType, timeout: null},
    };

    await uamClientService.updateUamSettings(message, !isMonoDevice);
};

const publishResetMessage = () => {};

const publishCheckFirmwareMessage = async (fwuTarget) => {
    const targets_holder = fwuTargetService.isFwuTargetHolderRelated(fwuTarget);

    iotDeviceFWUStateService.setIotTargetFwuStateOnFwuCheckRequest(fwuTarget);

    return await uamClientService.getUamFirmwareAvailability(targets_holder);
};

const publishInitiateUpdateFirmwareMessage = (fwuTarget) => uamClientService.updateUamFirmware(fwuTarget);

const publishGetVibrationSettingsMessage = () => publishVibrationSettingsMessage();

const publishGetAutoStartSettingsMessage = () => publishAutoStartSettingsMessage();

const publishGetGestureSettingsMessage = () => publishGesturetSettingsMessage();

const publishGetResponsiveDrawSettingsMessage = () => publishResponsiveDrawSettingsMessage();

const publishGetVapeCloudSizeSettingsMessage = () => publishVapeCloudSizeSettingsMessage();

const publishGetCountdownSettingsMessage = () => publishCountdownSettingsMessage();

const publishSetResponsiveDrawSettingsMessage = (mode) => publishResponsiveDrawSettingsMessage(mode);

const publishSetVapeCloudSizeSettingsMessage = (mode) => publishVapeCloudSizeSettingsMessage(mode);

const publishSetVibrationSettingsMessage = async (vibrationData, isMonoDevice) => {
    if (!vibrationData) return;

    let message = null;
    let isHolder = !isMonoDevice;

    if (vibrationData.all_holder_vibrations !== undefined) {
        message = {
            settingName: uamUpdateSettingsTypes.HEATING_VIBRATION,
            newValue: {
                hapticDelay: 1,
                hapticProfile: vibrationData.all_holder_vibrations
                    ? cmHapticProfileTypes.ENABLED
                    : cmHapticProfileTypes.DISABLED,
            },
        };
    } else if (vibrationData.start_heating !== undefined) {
        message = {
            settingName: uamUpdateSettingsTypes.HEATING_VIBRATION,
            newValue: {
                fullyHeated: vibrationData.fully_heated,
                endOfHeating: vibrationData.finished,
                nearEnd: vibrationData.almost_finished,
                startHeating: vibrationData.start_heating,
                hapticDelay: 0,
                hapticProfile: cmHapticProfileTypes.PROGRAMMABLE,
            },
        };
    } else if (vibrationData.battery_charged !== undefined) {
        isHolder = false;
        message = {
            settingName: uamUpdateSettingsTypes.BATTERY_CHARGED_VIBRATION,
            newValue: {batteryCharged: vibrationData.battery_charged},
        };
    } else if (vibrationData.start_charging !== undefined) {
        const onOffMessage = vibrationData.start_charging ? cmOnOffTypes.ON : cmOnOffTypes.OFF;

        message = {
            settingName: uamUpdateSettingsTypes.UI_BATTERY_CHARGED_VIBRATION,
            newValue: {batteryChargedOneExp: onOffMessage, batteryChargedTwoExp: onOffMessage},
        };
    } else if (vibrationData.extended_haptic_profile !== undefined) {
        const durations = [
            {
                durationIsOn: vibrationData.extended_haptic_profile,
                durationOfPhase: 10,
            },
        ];

        for (let i = 2; i < 14; i++) {
            durations.push({
                durationIsOn: false,
                durationOfPhase: 0,
            });
        }

        message = {
            settingName: uamUpdateSettingsTypes.EXT_HAPTIC_PROFILE,
            newValue: {
                feature: 0,
                durations,
            },
        };
    }

    if (message) {
        await uamClientService.updateUamSettings(message, isHolder);
    }
};

const publishSetAutoStartSettingsMessage = async (data) => {
    const autostart_feature_state = data?.holder?.autostart_feature_state;

    if (!autostart_feature_state) return;

    const onOffMessage =
        autostart_feature_state === autoStartModeTypes.AUTO_START_STATE_ENABLED ? cmOnOffTypes.ON : cmOnOffTypes.OFF;
    const message = {
        settingName: uamUpdateSettingsTypes.AUTO_START_HEATING,
        newValue: {featureState: onOffMessage},
    };

    await uamClientService.updateUamSettings(message, true);
};
const publishSetGestureSettingsMessage = async (data) => {
    const {status_gesture_pickup_enabled, status_gesture_double_tap_enabled} = data?.holder || {};

    let message = null;
    const getGestureState = (settingName, onOffMessage) => {
        return {settingName, newValue: {gestureState: onOffMessage}};
    };

    if (status_gesture_pickup_enabled !== undefined) {
        const onOffMessage = status_gesture_pickup_enabled ? cmOnOffTypes.ON : cmOnOffTypes.OFF;

        message = getGestureState(uamUpdateSettingsTypes.LIFT_UP_GESTURE, onOffMessage);
    }

    if (status_gesture_double_tap_enabled !== undefined) {
        const onOffMessage = status_gesture_double_tap_enabled ? cmOnOffTypes.ON : cmOnOffTypes.OFF;

        message = getGestureState(uamUpdateSettingsTypes.DOUBLE_TAP_GESTURE, onOffMessage);
    }

    if (message) {
        await uamClientService.updateUamSettings(message, true);
    }
};

const publishSetCountdownSettingsMessage = () => publishCountdownSettingsMessage();

const publishResetVibrationSettingsMessage = () => publishVibrationSettingsMessage();

const publishVibrationSettingsMessage = () => {};

const publishAutoStartSettingsMessage = () => {};

const publishGesturetSettingsMessage = () => {};

const publishCleaningMessage = () => {};

const publishHolderStatusMessage = () => {};

const publishCountdownSettingsMessage = () => {};

const publishDeviceRestartMessage = () => {};

const publishDeviceStatusCharacteristicUsbMessage = () => {};

const publishResponsiveDrawSettingsMessage = async (mode) => {
    const message = {
        settingName: uamUpdateSettingsTypes.RESPONSIVE_DRAW_PROFILE,
    };

    if (mode) {
        if (mode === responsiveDrawModeTypes.VIVACE) {
            mode = uamResponsiveDrawModeTypes.ALLEGRO;
        } else if (mode === responsiveDrawModeTypes.STACCATO) {
            mode = uamResponsiveDrawModeTypes.VIVALDI;
        }
        message.newValue = {haptic_pattern: mode};
    }

    await uamClientService.updateUamSettings(message);
};

const publishVapeCloudSizeSettingsMessage = async (mode) => {
    const message = {
        settingName: uamUpdateSettingsTypes.VAPE_CLOUD_SIZE,
    };

    if (mode) {
        message.newValue = {heating_profile: mode};
    }

    await uamClientService.updateUamSettings(message);
};

export default {
    publishCheckFirmwareMessage,
    publishCleaningMessage,
    publishConnectionCareMessage,
    publishGetAutoStartSettingsMessage,
    publishGetCountdownSettingsMessage,
    publishGetGestureSettingsMessage,
    publishGetLedSettingsMessage,
    publishGetResponsiveDrawSettingsMessage,
    publishGetVapeCloudSizeSettingsMessage,
    publishGetVibrationSettingsMessage,
    publishHolderStatusMessage,
    publishInitiateUpdateFirmwareMessage,
    publishParentProtectionDisableMessage,
    publishParentProtectionEnableMessage,
    publishParentProtectionStatusMessage,
    publishResetMessage,
    publishResetVibrationSettingsMessage,
    publishSetAutoStartSettingsMessage,
    publishSetCountdownSettingsMessage,
    publishSetGestureSettingsMessage,
    publishSetLedSettingsMessage,
    publishSetResponsiveDrawSettingsMessage,
    publishSetVapeCloudSizeSettingsMessage,
    publishSetVibrationSettingsMessage,
    publishStopVibrationAlarmMessage,
    publishVibrationAlarmMessage,
    publishDeviceRestartMessage,
    publishDeviceStatusCharacteristicUsbMessage,
};
