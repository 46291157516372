import * as iotHolderStatusTypes from '../iot/iotHolderStatusTypes';

export const UNPLUGGED = 'UNPLUGGED';
export const CHARGING = 'CHARGING';
export const CHARGED = 'CHARGED';
export const READY_TO_USE = 'READY_TO_USE';
export const CLEANING = 'CLEANING';
export const UNCHARGED = 'UNCHARGED';

export const getIotHolderStatus = (uamStatus) => {
    switch (uamStatus) {
        case UNPLUGGED:
            return iotHolderStatusTypes.UNPLUGGED;
        case CHARGING:
            return iotHolderStatusTypes.CHARGING;
        case CHARGED:
            return iotHolderStatusTypes.CHARGED;
        case READY_TO_USE:
            return iotHolderStatusTypes.READY_TO_USE;
        case CLEANING:
            return iotHolderStatusTypes.CLEANING;
        case UNCHARGED:
            return iotHolderStatusTypes.UNCHARGED;
        case null:
        case undefined:
            return undefined;
        default:
            throw new Error('Yap holder state mapping is not defined');
    }
};
