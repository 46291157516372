import appConfig from '../../config/appConfig';
import {customInitConfig} from '../../config/config';
import {initDataConfig} from '../../config/dataConfig/dataConfig';
import externalConfigService from '../../config/externalConfigService';
import urlUtils from '../../utils/urlUtils';
import authDataService from '../auth/authDataService';
import authService from '../auth/authService';
import gamService from '../gam/gamService';
import havService from '../havService';
import {initLocalizations} from '../localization/localizationService';
import log from '../logger/log';
import marketService from '../marketService';
import qualtricsService from '../qualtricsService';
import appRouterService from '../route/appRouterService';
import storageService from '../storage/storageService';
import userBrowserService from '../user/userBrowserService';
import userSessionService from '../user/userSessionService';

const onAppStart = async (isRememberMeEnabled, languageCode) => {
    try {
        if (!isRememberMeEnabled) {
            await authService.login();
        }
        await onAppInit();
        await initLocalizations(languageCode);

        const havVerificationResult = await havService.verifyHav();

        return {havVerificationResult};
    } catch (e) {
        log.error(`appService: onAppStart, error: ${e}`);

        return {havVerificationResult: false};
    }
};

const initializeUserSessionActions = async () => {
    const marketCodeFromLocalStorage = storageService.getMarketCodeFromLocalStorage();
    const accessTokenFromLocalStorage = storageService.getAccessTokenFromStorage();

    if (marketCodeFromLocalStorage && accessTokenFromLocalStorage) {
        await customInitConfig(marketCodeFromLocalStorage);
        await externalConfigService.initExternalConfig();

        const isRememberMeEnabled = appConfig.getIsRememberMeEnabled();

        if (isRememberMeEnabled) {
            const languageCodeFromLocalStorage = storageService.getLanguageCodeFromLocalStorage();

            const {havVerificationResult} = await onAppStart(isRememberMeEnabled, languageCodeFromLocalStorage);

            if (havVerificationResult) {
                appRouterService.forwardToBrowserVerificationPage();
            }
        } else {
            appRouterService.forwardToSelectMarketPage();
        }
    } else {
        appRouterService.forwardToSelectMarketPage();
    }
};

const initializeStartActions = async () => {
    const isRememberMeEnabled = appConfig.getIsRememberMeEnabled();

    if (isRememberMeEnabled) {
        const marketCodeFromLocalStorage = storageService.getMarketCodeFromLocalStorage();
        const accessTokenFromStorage = storageService.getAccessTokenFromStorage();

        const currentMarket = marketService.currentMarket();

        if (marketCodeFromLocalStorage?.toLowerCase() === currentMarket && accessTokenFromStorage) {
            await initializeUserSessionActions();
        } else {
            const {havVerificationResult} = await onAppStart();

            if (havVerificationResult) {
                const {spiceToken} = authDataService.getInitialCredentials();

                if (spiceToken) {
                    userSessionService.setUserSessionLocalization();
                }
                await externalConfigService.initExternalConfig();

                appRouterService.forwardToBrowserVerificationPage();
            } else {
                appRouterService.forwardToAppIntroPage();
            }
        }
    } else {
        const {havVerificationResult} = await onAppStart();

        await externalConfigService.initExternalConfig();

        if (havVerificationResult === undefined || havVerificationResult) {
            appRouterService.forwardToBrowserVerificationPage();
        }
    }
};

const onAppInit = async () => {
    qualtricsService.storeBrowserUserAgentCookie();
    qualtricsService.storeFrequencyUserAppRuns();
    qualtricsService.storeNumberOfDaysInApp();

    log.info(`appService: app is initialized, ua: ${userBrowserService.getUserAgent()}`);
    gamService.saveGamRootDataToLocalStorage();

    await initDataConfig();
};

const getAppBaseUrl = () => {
    const baseName = appConfig.getBaseNameFormatted();
    const originLocation = urlUtils.getOriginLocation();
    const baseUrl = urlUtils.join(originLocation, baseName);

    return baseUrl;
};

export default {
    getAppBaseUrl,
    initializeStartActions,
    onAppInit,
    initializeUserSessionActions,
};
