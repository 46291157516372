import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import useDidUpdate from '../../../hooks/useDidUpdate';
import ErrorHandlerService from '../../../services/errorHandlerService';
import PairingHandlerService from '../../../services/pairingHandlerService';
import {makeSelectIsDeviceSyncInProgress} from '../../../state/selectors/yapEncrypted';

const mapStateToProps = createStructuredSelector({
    isDeviceSyncInProgress: makeSelectIsDeviceSyncInProgress(),
    isHolderSyncInProgress: makeSelectIsDeviceSyncInProgress(true),
});

const UamPairingChecker = ({isDeviceSyncInProgress, isHolderSyncInProgress}) => {
    const onChange = (isInProgress, isHolder = false) => {
        if (isInProgress) {
            new PairingHandlerService().pairingIsStarted();
        } else {
            new ErrorHandlerService().checkUamError(isHolder);
            new PairingHandlerService().pairingIsStopped();
        }
    };

    useDidUpdate(() => {
        onChange(isDeviceSyncInProgress);
    }, [isDeviceSyncInProgress]);

    useDidUpdate(() => {
        onChange(isHolderSyncInProgress, true);
    }, [isHolderSyncInProgress]);

    return null;
};

export default connect(mapStateToProps)(UamPairingChecker);
