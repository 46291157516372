import * as logMessageTypes from '../../consts/app/logMessageTypes';
import appErrorService from '../app/appErrorService';
import log from '../logger/log';

const RESPONSE_TIMEOUT_MS = 40 * 1000;

interface IRequest {
    requestId: number;
    timeout: ReturnType<typeof setTimeout>;
}

let requests: IRequest[] = [];

const setRequest = (requestId: number): void => {
    const request = {
        requestId,
        timeout: setTimeout(() => {
            log.error(
                `uiIotMessageResponseChecker: onRequestTimeout, there is no response from SCP cloud for request, requestId: ${requestId}`,
                logMessageTypes.IOT
            );
            onRequestMessageError();
        }, RESPONSE_TIMEOUT_MS),
    };

    requests.push(request);
};

const clearResponseTimeout = (requestId: number): void => {
    const request = requests.find((r) => r.requestId === requestId);

    if (request) {
        clearTimeout(request.timeout);
    }
};

const removeRequestData = (requestId: number): void => {
    requests = requests.filter((r) => r.requestId !== requestId);
};

const clearResponsesTimeouts = (): void => {
    requests.forEach((request) => clearTimeout(request.timeout));
    requests = [];
};

const onRequestMessageError = (): void => appErrorService.showIoErrorWithAppReset();

const some = (requestId: number): boolean => requests.some((r) => r.requestId === requestId);

export default {
    clearResponsesTimeouts,
    clearResponseTimeout,
    onRequestMessageError,
    setRequest,
    removeRequestData,
    some,
};
