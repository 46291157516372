import appConfig from '../../config/appConfig';
import * as yapModeTypes from '../../consts/yap/yapModeTypes';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../state/selectors/consumer';
import {getState} from '../../state/store';
import backendService from '../app/backendService';
import cmClientService from '../communicationLayer/cmClientService';
import consumerAggregatedSettingsService from '../icc/consumerAggregatedSettingsService';
import productService from '../product/productService';
import uamWebSocketService from '../uam/uamWebSocketService';

const getYapMode = (type) => appConfig.getYapMode() === type;

const isYapEncryptedMode = () => getYapMode(yapModeTypes.YAP_ENCRYPTED_MODE);
const isYapMode = () => !getYapMode(yapModeTypes.YAP_DISABLED_MODE);
const isYapParentProtectionMode = () => getYapMode(yapModeTypes.YAP_PARENT_PROTECTION_MODE);

const disconnectIfNeeded = async () => {
    const isScpCloudBackend = backendService.isScpCloudBackend();
    const isDataCollectionOn = isDataCollectionEnabled();
    const iotDevice = makeSelectIotDeviceMergedWithIccProduct()(getState());
    const {device, holder} = iotDevice || {};
    const {status: deviceStatus} = device || {};
    const {status: holderStatus} = holder || {};
    const isCurrentDeviceRegistered = productService.getIsRegistered(deviceStatus);
    const isCurrentHolderRegistered = productService.getIsRegistered(holderStatus);
    const isCloseDeviceConnection = isScpCloudBackend && (!isCurrentDeviceRegistered || !isDataCollectionOn);
    const isCloseHolderConnection =
        isScpCloudBackend && (!isCurrentHolderRegistered || !isDataCollectionOn || !isCurrentDeviceRegistered);

    if (isCloseDeviceConnection) {
        uamWebSocketService.disconnectUamWebSocket(true);
        cmClientService.disconnectCmClient();
    }

    if (isCloseHolderConnection) {
        cmClientService.disconnectCmClient(true);
        uamWebSocketService.disconnectUamWebSocket(true, true);
    }
};

const isDataCollectionEnabled = () =>
    appConfig.getIsDataCollectionEnabled() && consumerAggregatedSettingsService.isEnabledDataCollection();

export default {
    disconnectIfNeeded,
    isDataCollectionEnabled,
    isYapEncryptedMode,
    isYapMode,
    isYapParentProtectionMode,
};
