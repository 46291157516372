import cn from 'classnames';
import React from 'react';
import ReactModal from 'react-modal';

import domService from '../../services/domService';
import styles from './Popup.module.scss';

const AppPopup = (props) => {
    //IA: closeTimeoutMS = 1 is needed to run popup 'close' function
    //    if Popup component is removed from DOM NOT by isOpen property
    const {children, className, closeTimeoutMS = 1, shouldReturnFocusAfterClose, ...restProps} = props;

    return (
        <ReactModal
            appElement={domService.getComponentsWrapperElement()}
            parentSelector={domService.getAppElement}
            shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
            className={cn(styles.AppPopup, className)}
            closeTimeoutMS={closeTimeoutMS}
            {...restProps}
        >
            {children}
        </ReactModal>
    );
};

export default AppPopup;
