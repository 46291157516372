import React from 'react';

import Icon from './Icon';

const IconEdit = (props) => {
    return (
        <Icon width='47' height='48' viewBox='0 0 47 48' {...props}>
            <g fill='none' fillRule='evenodd' strokeLinejoin='round' strokeWidth='2.261'>
                <path strokeLinecap='round' d='M33.657 6.522L4.467 35.166 2 44.956l9.068-3.467 29.367-29.178' />
                <path d='M43.787 8.783a3.953 3.953 0 0 0 1.17-2.812A3.976 3.976 0 0 0 40.975 2a3.975 3.975 0 0 0-2.801 1.148l5.613 5.635z' />
                <path strokeLinecap='round' d='M24.609 46.087H2' />
            </g>
        </Icon>
    );
};

export default IconEdit;
