import React from 'react';

import Icon from './Icon';

const CountDownIconActive = (props) => {
    return (
        <Icon width='48' height='48' viewBox='0 0 48 48' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g>
                    <g transform='translate(-935 -710) translate(858 710) translate(77) translate(19 5)'>
                        <path
                            strokeLinecap='round'
                            strokeWidth='1.6'
                            d='M9.882 15.647v8.598l-.99 10.941c-.14 1.535-1.197 2.696-2.456 2.696h-2.99c-1.258 0-2.316-1.16-2.455-2.696L0 24.245V3.03C0 1.357 1.106 0 2.47 0h4.942c1.364 0 2.47 1.357 2.47 3.03v1.136'
                        />
                        <circle cx='18.118' cy='9.882' r='9.882' strokeLinejoin='round' strokeWidth='1.5' />
                        <path strokeLinecap='round' strokeWidth='1.5' d='M18.118 5.765L18.118 9.882 22.235 9.882' />
                        <path strokeWidth='1.6' d='M0 23.5L9.882 23.5' />
                    </g>
                </g>
            </g>
        </Icon>
    );
};

export default CountDownIconActive;
