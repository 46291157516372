import React from 'react';

import Icon from './Icon';

const IconLocation = (props) => {
    return (
        <Icon width='17' height='24' viewBox='0 0 17 24' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g stroke='#383839'>
                    <g transform='translate(-205 -279) translate(205 279)'>
                        <path
                            d='M8.574 1.2l.705.608c4.776 6.215 7.221 10.748 7.221 13.62 0 2.23-.896 4.248-2.345 5.71C12.708 22.596 10.71 23.5 8.5 23.5s-4.208-.903-5.655-2.363C1.395 19.677.5 17.657.5 15.43c0-2.93 2.542-7.588 7.509-13.993.194-.153.386-.236.565-.236h0z'
                            transform='rotate(-180 8.5 12)'
                        />
                        <ellipse cx='8.5' cy='8.727' strokeWidth='1.728' rx='2.125' ry='2.182' />
                    </g>
                </g>
            </g>
        </Icon>
    );
};

export default IconLocation;
