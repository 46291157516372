import deviceConnectionStatusTypes from '../../../consts/device/deviceConnectionStatusTypes';
import firmwareStatusTypes from '../../../consts/device/firmwareStatusTypes';
import {selectIsDeviceDisconnected} from '../../selectors/iotDevice';
import {selectMwDeviceIsDisconnectedWithNoReconnect} from '../../selectors/mwDevice';
import {setMwDeviceIsDisconnectedWithNoReconnect} from '../mwDevice';
import * as types from './actionTypes';

export const clearIotDevice = () => ({type: types.IOT_DEVICE_CLEAR});

export const setIsDisconnected = () => {
    return (dispatch, getState) => {
        const state = getState();
        const isDeviceDisconnected = selectIsDeviceDisconnected(state);
        const isDisconnectedWithNoReconnect = selectMwDeviceIsDisconnectedWithNoReconnect(state);
        const statusToChange =
            isDisconnectedWithNoReconnect || isDeviceDisconnected
                ? deviceConnectionStatusTypes.DISCONNECTED
                : deviceConnectionStatusTypes.PAIRED;

        dispatch(setConnectedStatus(statusToChange, isDisconnectedWithNoReconnect));
    };
};

export const setIsPaired = () => setDeviceConnectionStatus(deviceConnectionStatusTypes.PAIRED);

const setConnectedStatus = (connectionStatus, isDisconnectedWithNoReconnect) => {
    return (dispatch) => {
        dispatch(setDeviceConnectionStatus(connectionStatus));

        if (isDisconnectedWithNoReconnect) {
            dispatch(setMwDeviceIsDisconnectedWithNoReconnect(false));
        }
    };
};

export const setIsConnected = () => {
    return (dispatch, getState) => {
        const state = getState();
        const isDisconnectedWithNoReconnect = selectMwDeviceIsDisconnectedWithNoReconnect(state);

        dispatch(setConnectedStatus(deviceConnectionStatusTypes.CONNECTED, isDisconnectedWithNoReconnect));
    };
};

export const setIsDeviceReady = () => setDeviceConnectionStatus(deviceConnectionStatusTypes.READY);

export const setIsWrongDevice = () => setDeviceConnectionStatus(deviceConnectionStatusTypes.WRONG_DEVICE);

export const setDeviceConnectionStatus = (status) => ({
    type: types.IOT_DEVICE_SET_DEVICE_CONNECTION_STATUS,
    payload: {status},
});

export const setDeviceActivationStatus = (status) => ({
    type: types.IOT_DEVICE_SET_DEVICE_ACTIVATION_STATUS,
    payload: {status},
});

export const clearIotDeviceData = () => ({type: types.IOT_DEVICE_CLEAR_DATA});

export const clearIotDeviceHolderData = () => ({type: types.IOT_DEVICE_CLEAR_HOLDER_DATA});

export const setIsReset = (isReset) => ({
    type: types.IOT_DEVICE_SET_IS_RESET,
    payload: {isReset},
});

export const setIsPairingInProgress = (isPairingInProgress) => ({
    type: types.IOT_DEVICE_SET_IS_PAIRING_IN_PROGRESS,
    payload: {isPairingInProgress},
});

export const setIsUpdateSettingsNeeded = (isUpdateSettingsNeeded) => ({
    type: types.IOT_DEVICE_SET_IS_UPDATE_SETTINGS_NEEDED,
    payload: {isUpdateSettingsNeeded},
});

export const setIsDeviceReconnected = (isReconnected) => ({
    type: types.IOT_DEVICE_SET_IS_RECONNECTED,
    payload: {isReconnected},
});

export const updateIotDeviceData = (data) => ({
    type: types.IOT_DEVICE_UPDATE_DATA,
    payload: {data},
});

export const setFWUStarted = (fwuTarget) => ({
    type: types.IOT_DEVICE_SET_FIRMWARE_DATA,
    payload: {data: {firmwareStatus: firmwareStatusTypes.STARTED, fwuTarget}},
});

export const setFWUFinished = () => ({
    type: types.IOT_DEVICE_SET_FIRMWARE_DATA,
    payload: {data: {firmwareStatus: firmwareStatusTypes.FINISHED}},
});

export const setFWUSuccess = () => ({
    type: types.IOT_DEVICE_SET_FIRMWARE_DATA,
    payload: {data: {firmwareStatus: firmwareStatusTypes.SUCCESS}},
});

export const setFWUError = () => ({
    type: types.IOT_DEVICE_SET_FIRMWARE_DATA,
    payload: {data: {firmwareStatus: firmwareStatusTypes.ERROR}},
});

export const setFWUCheking = () => ({
    type: types.IOT_DEVICE_SET_FIRMWARE_DATA,
    payload: {data: {firmwareStatus: firmwareStatusTypes.CHECKING}},
});

export const clearFWUStatus = () => ({
    type: types.IOT_DEVICE_SET_FIRMWARE_DATA,
    payload: {data: {firmwareStatus: undefined, fwuTarget: undefined}},
});

export const clearDeviceConnectionCareState = () =>
    updateIotDeviceData({
        errors: null,
    });

export const setIsVibrationAlarmActive = (state) => ({
    type: types.IOT_DEVICE_SET_IS_VIBRATION_ALARM_ACTIVE,
    payload: {data: state},
});

export const setGetFeatureKeysList = (data) => ({type: types.IOT_DEVICE_SET_GET_FEATURE_KEYS_LIST, payload: data});

export const hideAvailableFwuMessage = () => ({
    type: types.IOT_DEVICE_SET_IS_AVAILABLE_FWU_MESSAGE_CLOSED,
    payload: true,
});
