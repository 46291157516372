import FWU_TARGET_TYPES from '../../enums/iot/fwuTargetTypes';
import IOT_DEVICE_TARGET_NAMES from '../../enums/iot/iodDeviceTargetNames';
import {IDevice, IDeviceEngine, IHolder, IHolderEngine, IIotDevice} from '../../models/iotDevice';

const isFwuTargetDevice = (fwuTarget: FWU_TARGET_TYPES): boolean => fwuTarget === FWU_TARGET_TYPES.CHARGER_OR_MONO;
const isFwuTargetHolder = (fwuTarget: FWU_TARGET_TYPES): boolean => fwuTarget === FWU_TARGET_TYPES.HOLDER_DEVICE;
const isFwuTargetHolderEngine = (fwuTarget: FWU_TARGET_TYPES): boolean => fwuTarget === FWU_TARGET_TYPES.HOLDER_ENGINE;
const isFwuTargetDeviceEngine = (fwuTarget: FWU_TARGET_TYPES): boolean => fwuTarget === FWU_TARGET_TYPES.MONO_ENGINE;

const isFwuTargetHolderRelated = (fwuTarget: FWU_TARGET_TYPES): boolean =>
    fwuTarget === FWU_TARGET_TYPES.HOLDER_DEVICE || fwuTarget === FWU_TARGET_TYPES.HOLDER_ENGINE;

const getIotTargetByFwuTarget = (
    iotDevice: IIotDevice,
    fwu_target: FWU_TARGET_TYPES
): IDevice | IDeviceEngine | IHolder | IHolderEngine | undefined => {
    const iotTargetName = getIotTargetNameByFwuTarget(fwu_target);

    return iotDevice ? iotDevice[iotTargetName] : undefined;
};

const getFwuTargetByIotTarget = (iotDevice: IIotDevice): FWU_TARGET_TYPES | undefined => {
    const {device, device_engine, holder, holder_engine} = iotDevice || {};

    if (device) {
        return FWU_TARGET_TYPES.CHARGER_OR_MONO;
    } else if (device_engine) {
        return FWU_TARGET_TYPES.MONO_ENGINE;
    } else if (holder) {
        return FWU_TARGET_TYPES.HOLDER_DEVICE;
    } else if (holder_engine) {
        return FWU_TARGET_TYPES.HOLDER_ENGINE;
    }
};

const getIotTargetNameByFwuTarget = (fwu_target: FWU_TARGET_TYPES): IOT_DEVICE_TARGET_NAMES => {
    switch (fwu_target) {
        case FWU_TARGET_TYPES.MONO_ENGINE:
            return IOT_DEVICE_TARGET_NAMES.DEVICE_ENGINE;
        case FWU_TARGET_TYPES.HOLDER_DEVICE:
            return IOT_DEVICE_TARGET_NAMES.HOLDER;
        case FWU_TARGET_TYPES.HOLDER_ENGINE:
            return IOT_DEVICE_TARGET_NAMES.HOLDER_ENGINE;
        case FWU_TARGET_TYPES.CHARGER_OR_MONO:
        default:
            return IOT_DEVICE_TARGET_NAMES.DEVICE;
    }
};

export default {
    getFwuTargetByIotTarget,
    getIotTargetByFwuTarget,
    getIotTargetNameByFwuTarget,
    isFwuTargetDevice,
    isFwuTargetDeviceEngine,
    isFwuTargetHolder,
    isFwuTargetHolderEngine,
    isFwuTargetHolderRelated,
};
