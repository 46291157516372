import converter from '../../utils/converter';
import errorService from '../errors/errorService';
import NoDeviceIsSelectedError from '../errors/noDeviceIsSelectedError';
import log from '../logger/log';

let instance: HIDClient | null = null;
const VENDOR_ID = 0x2759;

interface IHidClientOptions {
    onDeviceConnect: () => void;
    onDisconnected: (isDisconnectedByUser?: boolean) => Promise<void>;
}

interface IConnectDeviceProps {
    isNewDevice: boolean;
    onConnect: () => void;
}

interface ISubscribeOnInputReportProps {
    onResponse: (response: string) => void;
    isDebug?: boolean;
}

interface ISendReportProps {
    command: string;
    isDebug?: boolean;
    responseTimeoutMs?: number;
    onResponseTimeoutError?: () => void;
}

export default class HIDClient {
    private responseTimeout?: ReturnType<typeof setTimeout>;
    private options?: IHidClientOptions;
    private device?: HIDDevice;

    constructor(createNew: boolean | undefined = false, options?: IHidClientOptions) {
        if (createNew && instance) {
            instance = null;
        }

        if (instance) {
            return instance;
        }

        this.options = options;

        instance = this;
    }

    connectDevice = async ({isNewDevice, onConnect}: IConnectDeviceProps): Promise<void> => {
        const device = await this.getOpenedDevice(isNewDevice);

        //TODO: check connect event
        navigator.hid.ondisconnect = () => {
            this.onDisconnected();
            log.info(`HID disconnected: ${device.productName}`);
        };

        this.device = device;

        log.info(`HIDClient: device is connected, product name: ${device.productName}`);

        this.options?.onDeviceConnect();
        onConnect();
    };

    subscribeOnInputReport = ({onResponse, isDebug = true}: ISubscribeOnInputReportProps): void => {
        const REPORT_ID = '3F';

        if (this.device) {
            this.device.oninputreport = (e) => {
                const KEEP_ALIVE_FRAME = '0100';
                const hexResponse = converter.buffer2hex(e.data);
                const isNotKeepAliveFrame = !hexResponse.startsWith(KEEP_ALIVE_FRAME);

                if (isDebug && isNotKeepAliveFrame) {
                    log.debug(`HIDClient: oninputreport: ${hexResponse}`);
                }

                if (isNotKeepAliveFrame) {
                    this.clearResponseTimeout();
                    const response = REPORT_ID + converter.buffer2hex(e.data);

                    onResponse(response);
                }
            };
        }
    };

    sendReport = ({command, isDebug = true, responseTimeoutMs, onResponseTimeoutError}: ISendReportProps): void => {
        try {
            this.clearResponseTimeout();

            const REPORT_SIZE = 31;
            const reportId = 63;
            const commandWithoutReportId = command.substr(2);
            const commandBin = converter.hex2binSized(commandWithoutReportId, REPORT_SIZE);

            if (isDebug) {
                log.debug(`Try to send command: ${command}`);
            }

            if (this.device) {
                this.device
                    .sendReport(reportId, commandBin)
                    .then(() => {
                        if (isDebug) {
                            log.debug(`HIDClient: sendReport success: ${command}`);
                        }
                        if (onResponseTimeoutError && responseTimeoutMs) {
                            this.clearResponseTimeout();
                            this.responseTimeout = setTimeout(onResponseTimeoutError, responseTimeoutMs);
                        }
                    })
                    .catch((e) => {
                        log.info(`HIDClient: sendReport failed, error: ${errorService.getErrorMessage(e)}`);
                    });
            }
        } catch (e) {
            log.info(`HIDClient: sendReport error: ${errorService.getErrorMessage(e)}`);
        }
    };

    disconnect = (): void => {
        this.clearResponseTimeout();

        if (this.device?.opened) {
            log.info(`HIDClient: disconnect device: ${this.device.productName}`);
            this.device.close();
            this.device = undefined;
            navigator.hid.ondisconnect = null;
            // IA: onDisconnected is not called after device.close()
            // so call it directly
            this.options?.onDisconnected(true);
        }
    };

    isDeviceConnected = (): boolean => !!this.device?.opened;

    private clearResponseTimeout = (): void => clearTimeout(this.responseTimeout!);

    private onDisconnected = (isDisconnectedByUser?: boolean): void => {
        log.debug(`HIDClient: device is disconnected`);
        this.clearResponseTimeout();
        this.options?.onDisconnected(isDisconnectedByUser);
    };

    private getOpenedDevice = async (isNewDevice: boolean): Promise<HIDDevice> => {
        let device;

        if (!isNewDevice) {
            device = await this.getPermittedDevice();
        }

        if (!device) {
            device = await this.requestDevice();
        }

        if (device && !device.opened) {
            await device.open();
        }

        if (!device || !device.opened) {
            throw new NoDeviceIsSelectedError();
        }

        return device;
    };

    private getPermittedDevice = async (): Promise<HIDDevice | undefined> => {
        const devices = await navigator.hid.getDevices();

        return devices.find((d) => d.vendorId === VENDOR_ID);
    };

    private requestDevice = async (): Promise<HIDDevice> => {
        const devices = await navigator.hid.requestDevice({
            filters: [{vendorId: VENDOR_ID}],
        });

        return devices[0];
    };
}
