export default {
    LANGUAGE_SELECTOR_TITLE_TEXT: 'Language',
    COUNTRY_SELECTOR_TITLE_TEXT: 'Country',
    PROVINCE_SELECTOR_TITLE_TEXT: 'Province',
    COUNTRY_AND_LANGUAGE_SELECTOR_TITLE: 'Select your country and preferred language',
    COUNTRY_AND_LANGUAGE_SELECTOR_CONFIRM_BUTTON_TEXT: 'Confirm',
    SELECTOR_COUNTRY_HEADER_TEXT: 'Select your country',
    SELECTOR_LANGUAGE_HEADER_TEXT: 'Select your language',
    SELECTOR_PROVINCE_HEADER_TEXT: 'Select your province',
    DETECTOR_ONLINE: 'You are online!',
    DETECTOR_OFFLINE: 'You are offline!',
    NOT_COMPATIBLE_OS_POPUP_IMG_FAILED_ICON_ALT: 'Warning',
    PAIRING_NOT_COMPATIBILITY_VERSION_TITLE:
        'Sorry, your iOS 13.4 version is not compatible for the IQOS Connect WEB solution',
    PAIRING_NOT_COMPATIBILITY_VERSION_MESSAGE:
        'Unfortunately, a new iOS 13.4 has major Bluetooth issues which can cause problems with IQOS device connectivity. Please, come back to us later',
    PAIRING_NOT_COMPATIBILITY_VERSION_BUTTON: 'GO TO IQOS SITE',
    PROVINCE_SELECTOR_TEXT: 'Please select',
    COUNTRY_SELECTOR_TEXT: 'Please select',
    LANGUAGE_SELECTOR_TEXT: 'Please select',
    CLOSE_NOTIFICATION_ARIA_LABEL: 'Close',
    NO_INTERNET_CONNECTION_SCENARIO_2_NOTIFICATION_TEXT:
        'Please make sure you are connected to the internet and try again',
};
