import appConfig from '../../config/appConfig';
import * as localizationKeys from '../../consts/localization/localizationKeys';
import NotificationModalTypes from '../../enums/app/notificationModalTypes';
import {hideAllLoaders, setNotificationFailed, setNotificationInfo} from '../../state/ducks/global';
import {dispatch} from '../../state/store';
import connectivityService from '../device/connectivityService';
import {getLocalizedStrings} from '../localization/localizationService';
import pairingService from '../pairingService';
import appRouterService from '../route/appRouterService';

//TODO: move to notificationService
const showDeviceSettingsGlobalError = () => {
    dispatch(setNotificationInfo(getLocalizedStrings()[localizationKeys.DEVICE_SETTINGS_GLOBAL_ERROR]));
};

const showError = (errorKey) => dispatch(setNotificationFailed(getLocalizedStrings()[errorKey]));

const showDeviceDisconnectNotification = (testId) => {
    prepareNotification(false, testId);
};

const showDeviceHolderDisonnectNotification = () => {
    prepareNotification(true);
};

const getNotificationMessageKey = (isHolder, isBle) => {
    if (isHolder) {
        return isBle
            ? localizationKeys.DEVICE_SETTINGS_INSERT_HOLDER_NOTIFICATION_TEXT
            : localizationKeys.USB_DEVICE_SETTINGS_INSERT_HOLDER_NOTIFICATION_TEXT;
    } else {
        return isBle
            ? localizationKeys.DEVICE_SETTINGS_CONNECT_NOTIFICATION_TEXT
            : localizationKeys.DEVICE_SETTINGS_CONNECT_VIA_USB_NOTIFICATION_TEXT;
    }
};

const prepareNotification = (isHolder, testId) => {
    const isReconnectDeviceGuideEnabled = appConfig.getIsReconnectDeviceGuideEnabled();
    const isBle = connectivityService.isBle();
    const messageKey = getNotificationMessageKey(isHolder, isBle);
    const isNotificationType = isReconnectDeviceGuideEnabled && isBle && !isHolder;
    const notificationType = isNotificationType ? NotificationModalTypes.RECONNECT_DEVICE : null;

    dispatch(setNotificationInfo(getLocalizedStrings()[messageKey], testId, notificationType));
};

const showGlobalError = () => {
    showError(localizationKeys.GLOBAL_ERROR_WITH_RETRY_DESCRIPTION_TEXT);
};

const showGlobalErrorWithAppHardReset = () => {
    showError(
        getBleOrUsbErrorKey(
            localizationKeys.GLOBAL_ERROR_NOTIFICATION_TEXT,
            localizationKeys.GLOBAL_ERROR_NOTIFICATION_CONNECT_TEXT
        )
    );
    pairingService.disconnectAndClearState();
    appRouterService.forwardToBrowserVerificationPage();
};

const showErrorWithAppReset = (errorKey) => {
    pairingService.disconnectAndForwardToMyDevicesPage();
    showError(errorKey);
    dispatch(hideAllLoaders());
};

const showGlobalErrorWithAppReset = () =>
    showErrorWithAppReset(
        getBleOrUsbErrorKey(
            localizationKeys.GLOBAL_ERROR_NOTIFICATION_TEXT,
            localizationKeys.GLOBAL_ERROR_NOTIFICATION_CONNECT_TEXT
        )
    );

const showIoErrorWithAppReset = () => {
    showErrorWithAppReset(
        getBleOrUsbErrorKey(
            localizationKeys.IO_ERROR_NOTIFICATION_TEXT,
            localizationKeys.IO_ERROR_USB_NOTIFICATION_TEXT
        )
    );
};

const getBleOrUsbErrorKey = (bleKey, usbKey) => (connectivityService.isBle() ? bleKey : usbKey);

export default {
    showDeviceDisconnectNotification,
    showDeviceHolderDisonnectNotification,
    showDeviceSettingsGlobalError,
    showGlobalError,
    showGlobalErrorWithAppHardReset,
    showGlobalErrorWithAppReset,
    showIoErrorWithAppReset,
};
