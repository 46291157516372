import React from 'react';

import Icon from './Icon';

const ValidateIcon = (props) => {
    return (
        <Icon width='22' height='22' viewBox='0 0 22 22' {...props}>
            <g fill='none' fillRule='evenodd'>
                <path
                    d='M0 0L19.683 0 19.683 19.608 0 19.608z'
                    transform='translate(-162 -348) translate(163 349) translate(.317)'
                />
                <path
                    fillRule='nonzero'
                    strokeLinecap='round'
                    d='M15.065 1.376C13.58.502 11.848 0 10 0 4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10'
                    transform='translate(-162 -348) translate(163 349)'
                />
                <path
                    fillRule='nonzero'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='1.5'
                    d='M5.472 9.105L9.148 13.042 18.472 3.238'
                    transform='translate(-162 -348) translate(163 349)'
                />
            </g>
        </Icon>
    );
};

export default ValidateIcon;
