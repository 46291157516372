import React from 'react';
import {useSelector} from 'react-redux';

import * as localizationKeys from '../../../consts/localization/localizationKeys';
import deviceTypesService from '../../../services/device/deviceTypesService';
import {
    selectIotDeviceType,
    selectIsFirmwareFinished,
    selectIsFirmwareInProgress,
    selectIsFirmwareStarted,
} from '../../../state/selectors/iotDevice';
import HidReconnectPopupTemplate from './HidReconnectPopupTemplate';

const HidReconnectPopup = (props) => {
    const isFirmwareStarted = useSelector(selectIsFirmwareStarted);
    const isFirmwareFinished = useSelector(selectIsFirmwareFinished);
    const isFirmwareInProgress = useSelector(selectIsFirmwareInProgress);
    const deviceType = useSelector(selectIotDeviceType);
    let customProps = {};

    if (isFirmwareStarted) {
        customProps = {
            descriptionKey: localizationKeys.USB_FWU_START_RECONNECT_POPUP_DESCRIPTION_TEXT,
        };
    } else if (isFirmwareFinished) {
        const isIluma = deviceTypesService.isIluma(deviceType);

        customProps = {
            descriptionKey: isIluma
                ? localizationKeys.USB_FWU_FINISHED_RECONNECT_POPUP_DESCRIPTION_TEXT_ILUMA
                : localizationKeys.USB_FWU_FINISHED_RECONNECT_POPUP_DESCRIPTION_TEXT,
        };
    } else {
        customProps = {
            descriptionKey: localizationKeys.USB_RECONNECT_POPUP_DESCRIPTION_TEXT,
            isCloseEnabled: !isFirmwareInProgress,
        };
    }

    if (isFirmwareInProgress) {
        customProps.showReconnectModalOnError = true;
    }

    return <HidReconnectPopupTemplate {...props} {...customProps} />;
};

export default HidReconnectPopup;
