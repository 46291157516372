import converter from '../../utils/converter';
import mwMessageRequestService from '../iot/mwMessageRequestService';
import IqosBleClient from './iqosBleClient';

let instance: DeviceCharacteristicClient | null = null;

export default class DeviceCharacteristicClient {
    private iqosBleClient?: IqosBleClient;

    constructor(createNew = false) {
        if (createNew && instance) {
            instance = null;
        }

        if (instance) {
            return instance;
        }

        instance = this;

        this.iqosBleClient = new IqosBleClient();
        this.iqosBleClient.addDeviceStatusCharacteristicListener((value: DataView) => {
            const frame = converter.buffer2hex(value);

            mwMessageRequestService.publishDeviceCharacteristicEvent(frame);
        });

        this.iqosBleClient.addBatteryInformationCharacteristicListener((value: DataView) => {
            const frame = converter.buffer2hex(value);

            mwMessageRequestService.publishBatteryCharacteristicEvent(frame);
        });
    }
}
