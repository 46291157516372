import React, {useEffect, useState} from 'react';

import {ButtonDark} from '../../../components/Button';
import PopupErrorStyled from '../../../components/Popup/PopupStyled/PopupErrorStyled';
import appConfig from '../../../config/appConfig';
import standaloneLocalization from '../../../config/standaloneConfig/standaloneLocalization';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import analyticsService from '../../../services/analyticsService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import routerService from '../../../services/routerService';

const NotCompatibleOSPopup = () => {
    const localizedStrings = getLocalizedStrings();
    const localizationSource = localizedStrings ? localizedStrings : standaloneLocalization;
    const iqosHomeUrl = appConfig.getIqosHomeUrlFormatted();
    const [isAnaliticServiceEventCalled, setIsAnaliticServiceEventCalled] = useState(false);

    useEffect(() => {
        if (!isAnaliticServiceEventCalled) {
            setIsAnaliticServiceEventCalled(true);
            analyticsService.pushNotCompatibleIosVersionEvent();
        }
    }, [isAnaliticServiceEventCalled]);

    return (
        <PopupErrorStyled
            alt={localizationSource[localizationKeys.NOT_COMPATIBLE_OS_POPUP_IMG_FAILED_ICON_ALT]}
            title={localizedStrings[localizationKeys.PAIRING_NOT_COMPATIBILITY_VERSION_TITLE]}
            descriptionHtml={localizedStrings[localizationKeys.PAIRING_NOT_COMPATIBILITY_VERSION_MESSAGE]}
        >
            <ButtonDark isExternalLink showArrow onClick={() => routerService.redirect(iqosHomeUrl)}>
                {localizationSource[localizationKeys.PAIRING_NOT_COMPATIBILITY_VERSION_BUTTON]}
            </ButtonDark>
        </PopupErrorStyled>
    );
};

export default NotCompatibleOSPopup;
