import React from 'react';

import Icon from './Icon';

const AutoStopIcon = (props) => {
    return (
        <Icon width='35' height='32' viewBox='0 0 35 32' {...props}>
            <g fill='none' fillRule='evenodd' strokeWidth='1.5'>
                <g transform='translate(-245 -650) translate(230 634) translate(16 17)'>
                    <rect width='12' height='12' x='12' y='9' rx='1.5' />
                    <g strokeLinecap='round'>
                        <path d='M4.98 22.454C7.568 26.964 12.43 30 18 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C9.716 0 3 6.716 3 15' />
                        <path strokeLinejoin='round' d='M0 16.352L3.076 13.5 6 16.5' transform='rotate(-180 3 15)' />
                    </g>
                </g>
            </g>
        </Icon>
    );
};

export default AutoStopIcon;
