import React from 'react';

import {IconIqosPulseLogo, IconVeevPulseLogo, QlabVeevPulseLogo} from '../../../components/Icons';
import imageSrcService from '../../../services/imageSrcService';

const PulseLogo = ({className}) => {
    const PulseLogo = imageSrcService.getImageSrcSetByMarket(IconIqosPulseLogo, IconVeevPulseLogo, QlabVeevPulseLogo);

    return <PulseLogo className={className} />;
};

export default PulseLogo;
