import * as cmHapticProfileTypes from '../../consts/communicationLayer/cmHapticProfileTypes';
import * as cmOnOffTypes from '../../consts/communicationLayer/cmOnOffTypes';
import * as autoStartModeTypes from '../../consts/iot/autoStartModeTypes';
import * as responsiveDrawModeTypes from '../../consts/iot/responsiveDrawModeTypes';
import * as localizationKeys from '../../consts/localization/localizationKeys';
import * as yapResponsiveDrawModeTypes from '../../consts/yap/responsiveDrawModeTypes';
import * as yapDataChangePropertyTypes from '../../consts/yap/yapDataChangePropertyTypes';
import * as yapDeviceEventChangeTypes from '../../consts/yap/yapDeviceEventChangeTypes';
import * as yapHolderStatusTypes from '../../consts/yap/yapHolderStatusTypes';
import {setNotificationSuccess} from '../../state/ducks/global';
import {clearIotDeviceHolderData, updateIotDeviceData} from '../../state/ducks/iotDevice/actions';
import {setMwDeviceFirmwareData} from '../../state/ducks/mwDevice';
import {
    setHolderInfoReceived,
    setYapHolderConnected,
    setYapSynchronizeHolderDataCollectionReceived,
} from '../../state/ducks/yapEncrypted';
import {makeSelectIotDeviceData} from '../../state/selectors/iotDevice';
import {makeSelectIsYapUpdateSettingsInProgress} from '../../state/selectors/yapEncrypted';
import {dispatch, getState} from '../../state/store';
import backendService from '../app/backendService';
import deviceConfigService from '../iotDevice/iotDeviceConfigService';
import {getLocalizedStrings} from '../localization/localizationService';
import {deviceParentModeMapping} from '../mapping/iotMappings';
import scpService from '../scp/scpService';

const updateDeviceCharacteristics = async (event) => {
    const isUamBackend = backendService.isUamBackend();

    if (!isUamBackend) return;

    const details = event?.details;

    if (!details) return;

    switch (event.eventType) {
        case yapDeviceEventChangeTypes.SYSTEM_STATUS:
            const holderPlugged = details.holderPlugged || details.holderConnection === 'CONNECTED';
            //for USB inside details itself
            let {holderState, holderDefect} = details;

            if (holderState === undefined) {
                //for BLE inside characteristics.chargerSystemStatus node
                holderState = details.characteristics?.chargerSystemStatus?.holderState;
                holderDefect = details.characteristics?.chargerSystemStatus?.holderDefect;
            }

            const isHolderConnected = holderPlugged && holderState !== yapHolderStatusTypes.UNPLUGGED;
            const {device} = makeSelectIotDeviceData()(getState()) || {};
            const {isHolderConnected: isHolderConnectedPrevState} = device || {};

            if (!isHolderConnectedPrevState && isHolderConnected) {
                const {deviceSerialNumber, codentify} = (await scpService.getHolderInfo()) || {};
                const device = {isHolderConnected, holder_connected: holderPlugged, holder_defect: holderDefect};
                const holderData = {
                    deviceSerialNumber,
                    codentify,
                    holder_state: yapHolderStatusTypes.getIotHolderStatus(holderState),
                    holder_connected: holderPlugged,
                    is_holder_manageable: isHolderConnected,
                };

                dispatch(updateIotDeviceData({device, holder: holderData}));
            } else if (!isHolderConnected) {
                dispatch(setYapHolderConnected(false));
                dispatch(setHolderInfoReceived(false));
                dispatch(setYapSynchronizeHolderDataCollectionReceived(false));
                dispatch(clearIotDeviceHolderData());
                return;
            }
            break;
        case yapDeviceEventChangeTypes.BATTERY_STATUS:
            const batteryCharge = details?.batteryChargeUnified;
            const deviceBattery = {battery_charge: batteryCharge};

            if (!batteryCharge) return;

            dispatch(updateIotDeviceData({device: deviceBattery}));
            break;
    }
};

const updateDeviceSettings = ({type, newValue, isHolder = false, isNeedInformUser = false}) => {
    if (newValue === undefined) return;

    let data;

    switch (type) {
        case yapDataChangePropertyTypes.IS_SBL_MODE:
            const sblMode = {is_device_in_sbl_mode: newValue};

            data = isHolder ? {holder: sblMode} : {device: sblMode};
            break;
        case yapDataChangePropertyTypes.YAP_AVAILABLE_FIRMWARE_VERSION_PROPERTY:
            const availableFirmwares = newValue?.availableFirmwares;
            const package_available = availableFirmwares && availableFirmwares.length;

            const firmware = {firmware: {package_available, availableFirmwares, isCheckInProgress: false}};
            const firmwareData = isHolder ? {holder: firmware} : {device: firmware};

            dispatch(updateIotDeviceData(firmwareData));

            const mwFirmwareData = isHolder ? {holder: {package_available}} : {device: {package_available}};

            dispatch(setMwDeviceFirmwareData(mwFirmwareData));
            break;
        case yapDataChangePropertyTypes.YAP_LED_PROPERTY:
            data = {ledData: {[isHolder ? 'holder' : 'device']: {intensity: newValue}}};
            break;
        case yapDataChangePropertyTypes.YAP_PARENT_PROTECTION_PROPERTY:
            data = deviceParentModeMapping({device: {parent_protection_mode: newValue}});

            if (!isNeedInformUser) break;

            const isUpdateSettingsInProgress = makeSelectIsYapUpdateSettingsInProgress(isHolder)(getState());

            if (!isUpdateSettingsInProgress) break;

            const message = data.device.parent_protection_mode
                ? getLocalizedStrings()[localizationKeys.PARENTAL_CONTROL_DEVICE_LOCKED_MESSAGE]
                : getLocalizedStrings()[localizationKeys.UNLOCK_SUCCESS_NOTIFICATION];

            dispatch(setNotificationSuccess(message));
            break;
        case yapDataChangePropertyTypes.YAP_VAPE_CLOUD_SIZE_PROPERTY:
            data = {vapeCloudSizeData: {vape_cloud_size: newValue}};
            break;
        case yapDataChangePropertyTypes.YAP_RESPONSIVE_DRAW_PROPERTY:
            if (newValue === yapResponsiveDrawModeTypes.ALLEGRO) {
                newValue = responsiveDrawModeTypes.VIVACE;
            } else if (newValue === yapResponsiveDrawModeTypes.VIVALDI) {
                newValue = responsiveDrawModeTypes.STACCATO;
            }

            data = {responsiveDrawData: {responsive_draw_profile: newValue}};
            break;
        case yapDataChangePropertyTypes.YAP_EXT_VIBRATION_PROPERTY:
            data = {vibrationData: {extended_haptic_profile: newValue}};
            break;
        case yapDataChangePropertyTypes.YAP_VIBRATION_FULLY_HEATED_PROPERTY:
            data = {vibrationData: {fully_heated: newValue}};
            break;
        case yapDataChangePropertyTypes.YAP_VIBRATION_START_HEATING_PROPERTY:
            data = {vibrationData: {start_heating: newValue}};
            break;
        case yapDataChangePropertyTypes.YAP_VIBRATION_NEAR_END_PROPERTY:
            data = {vibrationData: {almost_finished: newValue}};
            break;
        case yapDataChangePropertyTypes.YAP_VIBRATION_END_OF_HEATING_PROPERTY:
            data = {vibrationData: {finished: newValue}};
            break;
        case yapDataChangePropertyTypes.YAP_VIBRATION_HAPTIC_PROFILE_PROPERTY:
            const iotDevice = makeSelectIotDeviceData()(getState());
            const isCommonVibrationEnabled = deviceConfigService.isCommonVibrationEnabled(iotDevice);

            if (!isCommonVibrationEnabled) return;

            data = {vibrationData: {all_holder_vibrations: newValue === cmHapticProfileTypes.ENABLED}};
            break;
        case yapDataChangePropertyTypes.YAP_BATTERY_CHARGED_VIBRATION_PROPERTY:
            data = {vibrationData: {battery_charged: newValue}};
            break;
        case yapDataChangePropertyTypes.YAP_BATTERY_CHARGED_ONE_EXP_VIBRATION_PROPERTY:
        case yapDataChangePropertyTypes.YAP_BATTERY_CHARGED_TWO_EXP_VIBRATION_PROPERTY:
            data = {vibrationData: {start_charging: newValue === cmOnOffTypes.ON}};
            break;
        case yapDataChangePropertyTypes.YAP_LIFT_UP_GESTURE_PROPERTY:
            data = {gestureModeData: {holder: {status_gesture_pickup_enabled: newValue === cmOnOffTypes.ON}}};
            break;
        case yapDataChangePropertyTypes.YAP_DOUBLE_TAP_GESTURE_PROPERTY:
            data = {gestureModeData: {holder: {status_gesture_double_tap_enabled: newValue === cmOnOffTypes.ON}}};
            break;
        case yapDataChangePropertyTypes.YAP_AUTO_START_HEATING_PROPERTY:
            const value =
                newValue === cmOnOffTypes.ON
                    ? autoStartModeTypes.AUTO_START_STATE_ENABLED
                    : autoStartModeTypes.AUTO_START_STATE_DISABLE;

            data = {autoStartData: {holder: {autostart_feature_state: value}}};
            break;
    }

    if (data) {
        dispatch(updateIotDeviceData(data));
    }
};

export default {
    updateDeviceCharacteristics,
    updateDeviceSettings,
};
