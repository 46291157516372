import * as logMessageTypes from '../../consts/app/logMessageTypes';
import DEVICE_TYPES from '../../enums/device/deviceTypes';
import deviceSupportService from '../../services/device/deviceSupportService';
import iccMarketService from '../../services/icc/iccMarketService';
import log from '../../services/logger/log';
import server from '../../services/server/server';
import helpers from '../../utils/helpers';
import appConfig from '../appConfig';
import {
    IDataConfig,
    IReconnectDeviceGuideData,
    IVideoContentData,
    IVideoDescriptionData,
    IVideoDescriptionWithButtonData,
} from './types';

const fetchDataConfig = async () => {
    const enableP1Support = deviceSupportService.isP1DevicesSupported();
    const enableP1V4Support = deviceSupportService.isP1V4DevicesSupported();
    const enableP4Support = appConfig.getEnableP4Support();

    const p1Data = enableP1Support
        ? (await import(/* webpackChunkName: "lazy-chunk.data-p1" */ './dataConfigP1.json')).default
        : {};
    const p1v4Data = enableP1V4Support
        ? (await import(/* webpackChunkName: "lazy-chunk.data-p1v4" */ './dataConfigP1V4.json')).default
        : {};
    const p4Data = enableP4Support
        ? (await import(/* webpackChunkName: "lazy-chunk.data-p4" */ './dataConfigP4.json')).default
        : {};

    const data = helpers.mergeDeep.all([p4Data, p1Data, p1v4Data]);

    return data;
};

const fetchExternalDataConfig = async () => {
    const externalConfigUrl = appConfig.getExternalVideoGuidesConfigPath();

    if (!externalConfigUrl) {
        return null;
    }

    const externalConfigLink = await iccMarketService.getMediaUrl({
        mediaNameList: externalConfigUrl,
    });

    const externalConfigPath = externalConfigLink[0]?.url;
    const {data} = await server.get(externalConfigPath);

    return data;
};

const dataConfig = {} as IDataConfig;

const getIsDataFormatCorrect = (data: unknown) => {
    try {
        const parsedData = JSON.parse(JSON.stringify(data));

        return typeof parsedData === 'object';
    } catch (e) {
        log.error(`dataConfig: getIsDataFormatCorrect, error: ${e}`);
        return false;
    }
};

const initDataConfig = async (): Promise<void> => {
    let data: any;

    try {
        data = await fetchExternalDataConfig();
    } catch (e) {
        log.error(`dataConfig: fetchExternalDataConfig, error: ${e}`, logMessageTypes.ICC);
    }

    if (!data || !getIsDataFormatCorrect(data)) {
        data = await fetchDataConfig();
    }

    dataConfig.advancedFeaturesGuideData = getDevicesConfigData(data.AdvancedFeatures);
    dataConfig.batteryGuideData = getDevicesConfigData(data.BatteryGuide);
    dataConfig.cleaningGuideData = getDevicesConfigData(data.CleaningGuide);
    dataConfig.helpfulTipsData = getDevicesConfigData(data.HelpfulTips);
    dataConfig.howToUseGuideData = getDevicesConfigData(data.HowToUseGuide);
    dataConfig.pairingGuideData = getDevicesConfigData(data.PairingGuide);
    dataConfig.podGuideData = getDevicesConfigData(data.PodGuide);
    dataConfig.quickStartGuideData = getDevicesConfigData(data.QuickStartGuide);
    dataConfig.reconnectDeviceGuideData = getDevicesConfigData(data.ReconnectDeviceGuide);
    dataConfig.responsiveDrawGuideData = getDevicesConfigData(data.ResponsiveDrawGuide);
};

const getDevicesConfigData = <T>(data: T) => {
    const {Ordinary, Cortez, Duo, Multi, Veev, Voyager, Cosmos, Delta} = data || ({} as any);

    return {
        [DEVICE_TYPES.DEV_TYPE_P1V30_CHARGER]: Cortez,
        [DEVICE_TYPES.DEV_TYPE_P1V30M]: Multi,
        [DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER]: Duo,
        [DEVICE_TYPES.DEV_TYPE_P1V40C_CHARGER]: Cosmos,
        [DEVICE_TYPES.DEV_TYPE_P1V40V_CHARGER]: Voyager,
        [DEVICE_TYPES.DEV_TYPE_P4_M3_G2]: Veev,
        [DEVICE_TYPES.DEV_TYPE_V24P_CHARGER]: Ordinary,
        [DEVICE_TYPES.DEV_TYPE_P1V40D_CONTROL]: Delta,
    };
};

const getDataConfigData = <T>(data: any, deviceType?: string): T =>
    data && deviceType !== undefined ? data[deviceType] : undefined;

const getAdvancedFeaturesGuideData = (deviceType?: string): IVideoDescriptionWithButtonData =>
    getDataConfigData<IVideoDescriptionWithButtonData>(dataConfig?.advancedFeaturesGuideData, deviceType);

const getReconnectDeviceGuideData = (deviceType?: string): IReconnectDeviceGuideData =>
    getDataConfigData<IReconnectDeviceGuideData>(dataConfig?.reconnectDeviceGuideData, deviceType);

const getPairingGuideData = (deviceType?: string): IVideoContentData =>
    getDataConfigData<IVideoContentData>(dataConfig?.pairingGuideData, deviceType);

const getQuickStartGuideData = (deviceType?: string): IVideoDescriptionData =>
    getDataConfigData<IVideoDescriptionData>(dataConfig?.quickStartGuideData, deviceType);

const getCleaningGuideData = (deviceType?: string): IVideoDescriptionWithButtonData =>
    getDataConfigData<IVideoDescriptionWithButtonData>(dataConfig?.cleaningGuideData, deviceType);

const getBatteryGuideData = (deviceType?: string): IVideoDescriptionWithButtonData =>
    getDataConfigData<IVideoDescriptionWithButtonData>(dataConfig?.batteryGuideData, deviceType);

const getHelpfulTipsData = (deviceType?: string): IVideoDescriptionWithButtonData =>
    getDataConfigData<IVideoDescriptionWithButtonData>(dataConfig?.helpfulTipsData, deviceType);

const getResponsiveDrawGuideData = (deviceType?: string): IVideoDescriptionWithButtonData =>
    getDataConfigData<IVideoDescriptionWithButtonData>(dataConfig?.responsiveDrawGuideData, deviceType);

const getPodGuideData = (deviceType?: string): IVideoDescriptionWithButtonData =>
    getDataConfigData<IVideoDescriptionWithButtonData>(dataConfig?.podGuideData, deviceType);

const getHowToUseGuideData = (deviceType?: string): IVideoDescriptionWithButtonData =>
    getDataConfigData<IVideoDescriptionWithButtonData>(dataConfig?.howToUseGuideData, deviceType);

export {
    getAdvancedFeaturesGuideData,
    getBatteryGuideData,
    getCleaningGuideData,
    getHelpfulTipsData,
    getHowToUseGuideData,
    getPairingGuideData,
    getPodGuideData,
    getQuickStartGuideData,
    getReconnectDeviceGuideData,
    getResponsiveDrawGuideData,
    initDataConfig,
};
