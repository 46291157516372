import appConfig from '../../config/appConfig';
import urlUtils from '../../utils/urlUtils';
import domService from '../domService';

const getInitialLanguageData = () => {
    let language = null;

    if (appConfig.getIsDCE2()) {
        const LANGUAGE_PARAM_INDEX = 1;

        language = urlUtils.getParameterFromPath(LANGUAGE_PARAM_INDEX);
    } else {
        language = domService.getDataLanguageFromRoot();
    }

    return language;
};

export default {getInitialLanguageData};
