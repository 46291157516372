import {config} from '../config';
import {TConfigGetterReturn} from './types';

type TPurchaseLocalization = {
    language: TConfigGetterReturn<string>;
    datePickerLanguage: TConfigGetterReturn<string>;
    inputMask: TConfigGetterReturn<any>;
    dateFormat: TConfigGetterReturn<string>;
};

export default {
    getIsPurchaseDateEnabled: (): TConfigGetterReturn<boolean> => config.data.purchaseDate.isPurchaseDateEnabled,
    getPurchaseDateLocalizations: (): TConfigGetterReturn<TPurchaseLocalization[]> =>
        config.data.purchaseDate.localizations,
    getMinDateYearLimitation: (): TConfigGetterReturn<number> => config.data.purchaseDate.minDateYearLimitation,
    getCalendarStartDay: (): TConfigGetterReturn<number> => config.data.purchaseDate.calendarStartDay,
};
