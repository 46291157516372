import appConfig from '../../config/appConfig';
import ACTIVATION_ERRORS_DATA from '../../consts/analytics/activationErrorsData';
import * as yapActionStatusTypes from '../../consts/yap/yapActionStatusTypes';
import * as yapConnectionStatusTypes from '../../consts/yap/yapConnectionStatusTypes';
import * as yapDataChangePropertyTypes from '../../consts/yap/yapDataChangePropertyTypes';
import * as yapWebSocketActionTypes from '../../consts/yap/yapWebSocketActionTypes';
import * as yapWebSocketEventTypes from '../../consts/yap/yapWebSocketEventTypes';
import {
    setFWUError,
    setFWUSuccess,
    setIsDeviceReady,
    setIsWrongDevice,
    updateIotDeviceData,
} from '../../state/ducks/iotDevice/actions';
import {
    setDeviceDataChangeReceived,
    setSyncLastRequestId,
    setYapActivationInProgress,
    setYapDeviceConnected,
    setYapFirmwareInProgress,
    setYapLastRequestId,
    setYapSyncInProgress,
    setYapUpdateSettingsInProgress,
} from '../../state/ducks/yapEncrypted';
import {
    makeSelectIsDeviceDataChangeReceived,
    makeSelectIsYapActivationInProgress,
    makeSelectIsYapUpdateSettingsInProgress,
    makeSelectSyncLastRequestId,
    makeSelectYapLastRequestId,
} from '../../state/selectors/yapEncrypted';
import {dispatch, getState} from '../../state/store';
import analyticsService from '../analyticsService';
import appErrorService from '../app/appErrorService';
import cmClientService from '../communicationLayer/cmClientService';
import IqosServiceClient from '../device/iqosServiceClient';
import log from '../logger/log';
import yapStateService from '../yap/yapStateService';
import gamClientService from './gamClientService';
import gamService from './gamService';

const proceedMessage = (message) => {
    const data = JSON.parse(message.data) || {};

    switch (data?.eventType) {
        case yapWebSocketEventTypes.CONNECTION_STATUS_CHANGE:
            if (data.status === yapConnectionStatusTypes.CONNECTED) {
                if (cmClientService.isCmClientConnected(data.assetId)) {
                    dispatch(setYapDeviceConnected(true));
                }
            } else if (data.status === yapConnectionStatusTypes.DISCONNECTED) {
                dispatch(setYapDeviceConnected(false));
            } else if (data.status === yapConnectionStatusTypes.INCORRECT_DEVICE) {
                log.info('End synchronization process. Incorrect Device.');
                dispatch(setIsWrongDevice());
                dispatch(setYapSyncInProgress(false));
            }
            break;
        case yapWebSocketEventTypes.ASYNC_REQUEST:
            switch (data.request?.action) {
                case yapWebSocketActionTypes.ACTIVATE:
                case yapWebSocketActionTypes.DEACTIVATE: {
                    const yapStatus = proceedGamAction(data.status, data.requestId);

                    if (yapStatus === yapActionStatusTypes.FAILED) {
                        analyticsService.pushActivationFailedError(ACTIVATION_ERRORS_DATA.ERROR_ACTIVATION_FAILED);
                    }
                    if (yapStatus) {
                        checkProcessFinish(true);
                    }
                    break;
                }
                case yapWebSocketActionTypes.UPDATE_SETTING:
                    if (proceedGamAction(data.status, data.requestId)) {
                        checkProcessFinish(false);
                    }
                    break;
                case yapWebSocketActionTypes.REFRESH_INFO:
                    const yapStatus = proceedGamAction(data.status, data.requestId, true);

                    if (yapStatus) {
                        proceedRefreshInfo(yapStatus);
                    }
                    break;
                case yapWebSocketActionTypes.PROGRESS:
                    const value = data.request.value;
                    const progressInt = parseInt(value);
                    const progress = isNaN(progressInt) ? value : progressInt;
                    //todo: targets_holder always false for GAM (improve for P1 devices)
                    const newState = {device: {firmware: {progress}}};

                    dispatch(updateIotDeviceData(newState));
                    break;
                case yapWebSocketActionTypes.FIRMWARE_UPGRADE:
                    const fwYapStatus = proceedGamAction(data.status, data.requestId);

                    if (fwYapStatus) {
                        if (fwYapStatus === yapActionStatusTypes.SUCCESS) {
                            dispatch(setFWUSuccess());
                        } else {
                            dispatch(setFWUError());
                        }
                        dispatch(setYapFirmwareInProgress(false));
                    }
                    break;
            }
            break;

        case yapWebSocketEventTypes.DEVICE_DATA_CHANGE:
            if (!data.updatedData) return;

            data.updatedData.forEach((updatedObj) => {
                switch (updatedObj?.propertyName) {
                    case yapDataChangePropertyTypes.YAP_ACTIVATION_STATUS_PROPERTY:
                        updateYapActivationStatus(updatedObj.newValue);
                        break;
                    case yapDataChangePropertyTypes.YAP_RESPONSIVE_DRAW_PROPERTY:
                    case yapDataChangePropertyTypes.YAP_VAPE_CLOUD_SIZE_PROPERTY:
                    case yapDataChangePropertyTypes.YAP_PARENT_PROTECTION_PROPERTY:
                    case yapDataChangePropertyTypes.YAP_LED_PROPERTY:
                        updateDeviceSettings(updatedObj?.propertyName, updatedObj.newValue);
                        break;
                }
            });

            break;
        case yapWebSocketEventTypes.DEVICE_EVENT:
            if (!data.events) return;

            data.events.forEach((event) => {
                yapStateService.updateDeviceCharacteristics(event);
            });
            break;
        default:
            break;
    }
};

const proceedRefreshInfo = async (status) => {
    if (yapActionStatusTypes.SUCCESS === status) {
        const iqosService = new IqosServiceClient();
        let isDeviceConnected = iqosService.isDeviceConnected();

        if (isDeviceConnected) {
            const isDeviceInfoValid = await gamClientService.getGamDeviceInfo();

            if (isDeviceInfoValid) {
                isDeviceConnected = iqosService.isDeviceConnected();
                if (isDeviceConnected) {
                    log.info('End synchronization process. Success.');
                    dispatch(setIsDeviceReady());
                }
            }
        }
    }

    dispatch(setYapSyncInProgress(false));
};

let gamCheckProcessFinishTimeout;
const checkProcessFinish = (isActivation) => {
    clearTimeout(gamCheckProcessFinishTimeout);

    const state = getState();
    const isYapActivationInProgress = makeSelectIsYapActivationInProgress()(state);
    const isYapUpdateSettingsInProgress = makeSelectIsYapUpdateSettingsInProgress()(state);

    if (!isYapActivationInProgress && !isYapUpdateSettingsInProgress) {
        return;
    }

    const onFinish = () => {
        dispatch(setDeviceDataChangeReceived(false));
        dispatch(setYapLastRequestId(null));

        if (isActivation) {
            if (isYapActivationInProgress) {
                dispatch(setYapActivationInProgress(false));
            }
        } else {
            if (isYapUpdateSettingsInProgress) {
                dispatch(setYapUpdateSettingsInProgress(false));
            }
        }
    };

    const lastRequestId = makeSelectYapLastRequestId()(state);
    const isDeviceDataChangeReceived = makeSelectIsDeviceDataChangeReceived()(state);

    if (isDeviceDataChangeReceived && !lastRequestId) {
        onFinish();
        return;
    }

    const onFinishFailed = async () => {
        clearTimeout(gamCheckProcessFinishTimeout);

        if (isActivation) {
            await gamClientService.getGamDeviceInfo();
        } else {
            appErrorService.showDeviceSettingsGlobalError();
        }

        onFinish();
    };

    gamCheckProcessFinishTimeout = setTimeout(onFinishFailed, appConfig.getGamWebSocketMessageTimeout() * 1000);
};

const proceedGamAction = (status, requestId, isSync = false) => {
    const isPending = status === yapActionStatusTypes.PENDING;

    if (isPending) return false;

    const lastRequestId = isSync ? makeSelectSyncLastRequestId()(getState()) : makeSelectYapLastRequestId()(getState());

    if (!lastRequestId || requestId !== lastRequestId) {
        log.info(`gamWebSocketService: answer for requestId ${requestId} is ignored. Waiting for ${lastRequestId}`);
        return false;
    }

    const isFailed = status === yapActionStatusTypes.FAILED;
    const isSuccess = status === yapActionStatusTypes.SUCCESS;

    if (!isFailed && !isSuccess) return false;

    if (isSync) {
        dispatch(setSyncLastRequestId(null));
    } else {
        dispatch(setYapLastRequestId(null));
    }

    return isSuccess ? yapActionStatusTypes.SUCCESS : yapActionStatusTypes.FAILED;
};

const updateYapActivationStatus = async (yapActivationStatus) => {
    await gamService.updateGamActivationStatus(yapActivationStatus);

    dispatch(setDeviceDataChangeReceived(true));

    checkProcessFinish(true);
};

const updateDeviceSettings = (type, newValue) => {
    yapStateService.updateDeviceSettings({type, newValue, isNeedInformUser: true});

    dispatch(setDeviceDataChangeReceived(true));

    checkProcessFinish(false);
};

export default {proceedMessage, proceedRefreshInfo};
