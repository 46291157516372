import React, {useEffect} from 'react';

import {Button, ButtonDark} from '../../../components/Button';
import PopupErrorStyled from '../../../components/Popup/PopupStyled/PopupErrorStyled';
import pairingErrors from '../../../consts/device/pairingErrors';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import ModalTypes from '../../../enums/app/modalTypes';
import analyticsService from '../../../services/analyticsService';
import connectivityService from '../../../services/device/connectivityService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import modalService from '../../../services/modalService';
import pairingService from '../../../services/pairingService';
import appRouterService from '../../../services/route/appRouterService';
import {showConnectivitySelectModalAction} from '../../../state/ducks/modal';
import elementsTesIds from '../../../test/consts/elementsTesIds';
import styles from './PairingGuideErrorPopup.module.scss';
import {pairingGuideErrorsContent} from './pairingGuideErrorsContent';

const PairingGuideErrorPopup = (props) => {
    const {dispatch, deviceType, error, setError} = props;
    const {type} = error;
    const errorSet = getErrorSet(type, deviceType);

    useEffect(() => {
        analyticsService.pushPairingFailedEvent(type, deviceType);
    }, [type]);

    if (!errorSet) return null;

    const localizedStrings = getLocalizedStrings();
    const {description, mainButtonText, secondaryButtonText, title} = errorSet;

    const onErrorPopupTryAgainBtnClick = () => {
        const isUsb = connectivityService.isUsb();
        const isBleAndUsbEnabled = connectivityService.isBleAndUsbEnabled();

        pairingService.disconnectAndClearState();
        setError(null);

        if (isBleAndUsbEnabled && isUsb) {
            modalService.hideModal(ModalTypes.PAIRING_GUIDE_ERROR);
            dispatch(showConnectivitySelectModalAction({isRetry: true}));
        } else {
            appRouterService.forwardToSelectDevice();
        }
    };

    const onErrorPopupSkipBtnClick = () => {
        appRouterService.forwardToMyDevicesPage();
    };

    return (
        <PopupErrorStyled
            alt={localizedStrings[localizationKeys.DEVICE_PAIRING_ERROR_IMG_LOGO_ALT]}
            title={localizedStrings[title]}
        >
            <p
                className='ica--txt-regular'
                dangerouslySetInnerHTML={{
                    __html: localizedStrings[description],
                }}
            />
            <ButtonDark
                onClick={onErrorPopupTryAgainBtnClick}
                showArrow
                testId={elementsTesIds.PAIRING_SEQUENCE_ERROR_TRY_AGAIN_BTN}
            >
                {localizedStrings[mainButtonText]}
            </ButtonDark>
            <Button
                ariaLabel={localizedStrings[localizationKeys.DEVICE_PAIRING_ERROR_BTN_SKIP_ARIA_LABEL]}
                className={styles.ButtonSecondary}
                onClick={onErrorPopupSkipBtnClick}
                showUnderline
                testId={elementsTesIds.PAIRING_SEQUENCE_ERROR_SKIP_BTN}
            >
                {localizedStrings[secondaryButtonText]}
            </Button>
        </PopupErrorStyled>
    );
};

const getErrorSet = (type, deviceType) => {
    const {PAIRING_ERRORS, CONNECTION_ERRORS, DEVICE_SUPPORT_FAILED_ERRORS, WRONG_DEVICE_ERRORS} =
        pairingGuideErrorsContent();

    const connectionErrors = CONNECTION_ERRORS[deviceType];
    const wrongDeviceErrors = WRONG_DEVICE_ERRORS[deviceType];
    const getRestOfContent = ({mainButtonText, secondaryButtonText}) => ({mainButtonText, secondaryButtonText});

    switch (type) {
        case pairingErrors.ERROR_CONNECTION_NOT_FOUND.type:
            return {...connectionErrors, ...getRestOfContent(CONNECTION_ERRORS)};
        case pairingErrors.ERROR_PAIRING.type:
            return PAIRING_ERRORS;
        case pairingErrors.ERROR_NOT_SUPPORTED_DEVICE.type:
            return DEVICE_SUPPORT_FAILED_ERRORS;
        case pairingErrors.ERROR_WRONG_DEVICE.type:
            return {...wrongDeviceErrors, ...getRestOfContent(WRONG_DEVICE_ERRORS)};
        default:
            return null;
    }
};

export default PairingGuideErrorPopup;
