export default {
    data: {
        accessibility: {
            classMapping: undefined,
            isToolEnabled: undefined,
        },
        app: {
            appVersion: undefined,
            backendType: undefined,
            baseName: undefined,
            browserCheckPageLogoType: undefined,
            defaultVeevImage: undefined,
            deviceListOrder: undefined,
            env: undefined,
            isAccessProtected: undefined,
            isDataCollectionEnabled: undefined,
            isDeviceDiagnosticEnabled: undefined,
            isDeviceSettingAvailable: undefined,
            isQualtricsTrackingEnabled: undefined,
            isReconnectDeviceGuideEnabled: undefined,
            isRememberMeEnabled: undefined,
            isTestLoginEnabled: undefined,
            legalDisclaimerPdfName: undefined,
            publicUrl: undefined,
            tipsAndTricksAllowedSteps: undefined,
            welcomePageImagePath: undefined,
            yapMode: undefined,
        },
        appLinks: {
            linkAndroidStoreChrome: undefined,
            linkAndroidStoreIqos: undefined,
            linkDesktopApps: undefined,
            linkDesktopChrome: undefined,
            linkIosStoreBluenet: undefined,
            linkIosStoreIqos: undefined,
            linkMacWidget: undefined,
            linkWindowsWidget: undefined,
        },
        aws: {
            awsIotHost: undefined,
            awsRegion: undefined,
        },
        benefitPage: {
            isBenefitPageEnabled: undefined,
            benefitSections: undefined,
        },
        cm: {
            cmTimeout: undefined,
        },
        connectivity: {
            isBle: undefined,
            isUsb: undefined,
        },
        deviceFeatures: {
            isConnectCareEnabled: undefined,
            isDeviceAlarmEnabled: undefined,
            isLedEnabled: undefined,
            isParentProtectionEnabled: undefined,
            isResponsiveDrawProfileEnabled: undefined,
            isSelfCleaningEnabled: undefined,
            isVapeCloudSizeEnabled: undefined,
            isVibrationEnabled: undefined,
            isAutoStartEnabled: undefined,
            isGestureEnabled: undefined,
        },
        deviceSettingsPage: {
            isDeviceResetEnabled: undefined,
        },
        deviceSupport: {
            enableCortezSupport: undefined,
            enableCosmosSupport: undefined,
            enableDeltaSupport: undefined,
            enableOrdinarySupport: undefined,
            enableP4Support: undefined,
            enableSoyuzSupport: undefined,
            enableVespucciSupport: undefined,
            enableVoyagerSupport: undefined,
        },
        externalLinks: {
            connectingCareUrl: undefined,
            dcsRegisterUrl: undefined,
            dcsSupportUrl: undefined,
            iqosHomeUrl: undefined,
            iqosHavUrl: undefined,
            iqosStoreUrl: undefined,
            learnMoreUrl: undefined,
            loginUrl: undefined,
            productUnregistrationUrl: undefined,
        },
        fwu: {
            ble: {
                isP1Available: undefined,
                isP4Available: undefined,
                isP1V4Available: undefined,
            },
            isBackgroundFwuCheckEnabled: undefined,
            usb: {
                isP1Available: undefined,
                isP4Available: undefined,
                isP1V4Available: undefined,
            },
        },
        gam: {
            gamCheckRequestInterval: undefined,
            gamCheckStatusInterval: undefined,
            gamTimeout: undefined,
            gamWebSocketMessageTimeout: undefined,
            gamUpdateSettingTimeout: undefined,
            gamFirmwareTimeout: undefined,
            gamApiUrl: undefined,
            gamWssUrl: undefined,
        },
        hav: {
            isHAVMandatory: undefined,
            isMarketHAVAvailable: undefined,
        },
        icc: {
            anonymousToken: undefined,
            dataUserToken: undefined,
            iccApiBodyScope: undefined,
            iccApiHeader_a: undefined,
            iccApiHeader_m: undefined,
            iccApiHeaderAuthorization: undefined,
            iccApiHeaderUsername: undefined,
            iccApiUrl: undefined,
            iccAuthUrl: undefined,
        },
        iccConsumerServices: {
            isICCConsumerServiceEnabled: undefined,
            isDeviceListEnabled: undefined,
            isDeviceRegistrationEnabled: undefined,
            isDeviceRenameEnabled: undefined,
            isDeviceUnregistrationEnabled: undefined,
            isTNCEnabled: undefined,
        },
        lifestyleImages: {
            DEV_TYPE_V24_CHARGER: {
                deviceSettingsImage: undefined,
                tutorialsImage: undefined,
            },
            DEV_TYPE_V24P_CHARGER: {
                deviceSettingsImage: undefined,
                tutorialsImage: undefined,
            },
            DEV_TYPE_P1V30_CHARGER: {
                deviceSettingsImage: undefined,
                tutorialsImage: undefined,
            },
            DEV_TYPE_P1V30M: {
                deviceSettingsImage: undefined,
                tutorialsImage: undefined,
            },
            DEV_TYPE_P1V31_CHARGER: {
                deviceSettingsImage: undefined,
                tutorialsImage: undefined,
            },
            DEV_TYPE_P4_M3_G2: {
                deviceSettingsImage: undefined,
                tutorialsImage: undefined,
            },
            DEV_TYPE_P1V40V_CHARGER: {
                deviceSettingsImage: undefined,
                tutorialsImage: undefined,
            },
            DEV_TYPE_P1V40C_CHARGER: {
                deviceSettingsImage: undefined,
                tutorialsImage: undefined,
            },
            DEV_TYPE_P1V40D_CONTROL: {
                deviceSettingsImage: undefined,
                tutorialsImage: undefined,
            },
        },
        localization: {
            localizationFileName: undefined,
            localizationKey: undefined,
            localizationMapping: undefined,
        },
        login: {
            ageGateMinAge: undefined,
            loginEmailRegex: undefined,
            loginForgotPasswordUrl: undefined,
            loginPasswordRegex: undefined,
            loginRegisterUrl: undefined,
        },
        logs: {
            awsLogLevel: undefined,
            isAppVersionVisible: undefined,
            isAwsLoggingEnabled: undefined,
            isDebug: undefined,
            isLogsVisible: undefined,
            logLevel: undefined,
            mqttDebugLevel: undefined,
        },
        market: {
            dcs: {isDCS: true},
            dce1: {
                isDCE1: undefined,
            },
            dce2: {isDCE2: true},
        },
        promo: {
            tryFeature: {
                isTryFeatureEnabled: undefined,
                tryFeatureAvailabilityDays: undefined,
                supportedDeviceFeatures: {
                    DEV_TYPE_V24P_CHARGER: {
                        isCleaningModeEnabled: undefined,
                        isVibrateModeEnabled: undefined,
                        isDeviceDiagnosticEnabled: undefined,
                    },
                    DEV_TYPE_P1V30_CHARGER: {
                        isCleaningModeEnabled: undefined,
                        isVibrateModeEnabled: undefined,
                        isLedModeEnabled: undefined,
                        isDeviceDiagnosticEnabled: undefined,
                    },
                    DEV_TYPE_P1V30M: {
                        isVibrateModeEnabled: undefined,
                        isDeviceDiagnosticEnabled: undefined,
                    },
                    DEV_TYPE_P1V31_CHARGER: {
                        isCleaningModeEnabled: undefined,
                        isVibrateModeEnabled: undefined,
                        isLedModeEnabled: undefined,
                        isDeviceDiagnosticEnabled: undefined,
                    },
                    DEV_TYPE_P4_M3_G2: {
                        isLedModeEnabled: undefined,
                        isResponsiveDrawModeEnabled: undefined,
                        isVapeCloudSizeModeEnabled: undefined,
                    },
                    DEV_TYPE_P1V40V_CHARGER: {
                        isVibrateModeEnabled: undefined,
                        isLedModeEnabled: undefined,
                        isAutoStartModeEnabled: undefined,
                        isGestureModeEnabled: undefined,
                        isCountdownModeEnabled: undefined,
                    },
                    DEV_TYPE_P1V40C_CHARGER: {
                        isVibrateModeEnabled: undefined,
                        isLedModeEnabled: undefined,
                        isAutoStartModeEnabled: undefined,
                        isGestureModeEnabled: undefined,
                        isCountdownModeEnabled: undefined,
                    },
                    DEV_TYPE_P1V40D_CONTROL: {
                        isVibrateModeEnabled: undefined,
                        isLedModeEnabled: undefined,
                        isAutoStartModeEnabled: undefined,
                        isGestureModeEnabled: undefined,
                        isCountdownModeEnabled: undefined,
                    },
                },
            },
            getFeature: {
                isGetFeatureEnabled: undefined,
                supportedDeviceFeatures: {
                    DEV_TYPE_V24P_CHARGER: {
                        CLEANING: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                        VIBRATION: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                        DIAGNOSTIC: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                    },
                    DEV_TYPE_P1V30_CHARGER: {
                        CLEANING: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                        VIBRATION: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                        LED: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                        DIAGNOSTIC: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                    },
                    DEV_TYPE_P1V30M: {
                        VIBRATION: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                        DIAGNOSTIC: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                    },
                    DEV_TYPE_P1V31_CHARGER: {
                        CLEANING: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                        VIBRATION: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                        LED: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                        DIAGNOSTIC: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                    },
                    DEV_TYPE_P4_M3_G2: {
                        LED: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                        RESPONSIVE_DRAW: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                        VAPE_CLOUD_SIZE: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                    },
                    DEV_TYPE_P1V40V_CHARGER: {
                        VIBRATION: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                        LED: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                        AUTOSTART: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                        GESTURE: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                        COUNTDOWN: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                    },
                    DEV_TYPE_P1V40C_CHARGER: {
                        VIBRATION: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                        LED: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                        AUTOSTART: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                        GESTURE: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                        COUNTDOWN: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                    },
                    DEV_TYPE_P1V40D_CONTROL: {
                        VIBRATION: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                        LED: {
                            charger: undefined,
                            holder: undefined,
                            isEnabled: undefined,
                            image: undefined,
                        },
                    },
                },
            },
        },
        scp: {
            scpApiUrl: undefined,
            scpCloudTopicMarket: undefined,
            scpCloudTopicPrefix: undefined,
            scpCloudTopicMarketPrefix: undefined,
            scpCloudVersion: undefined,
        },
        storage: {
            assetsPath: undefined,
            holderAssetsPath: undefined,
            path: undefined,
            externalConfig: {
                folderName: undefined,
                linksFileName: undefined,
                videoGuidesConfigFileName: undefined,
            },
            introAssets: {
                compatible: undefined,
                notCompatible: undefined,
            },
            reconnectGuideAssetsPath: undefined,
        },
        purchaseDate: {
            isPurchaseDateEnabled: undefined,
            localizations: [
                {
                    language: undefined,
                    datePickerLanguage: undefined,
                    inputMask: undefined,
                    dateFormat: undefined,
                },
            ],
            minDateYearLimitation: undefined,
            calendarStartDay: undefined,
        },
        skipPage: {
            skipSections: undefined,
        },
        uam: {
            uamCheckRequestInterval: undefined,
            uamCheckStatusInterval: undefined,
            uamTimeout: undefined,
            uamWaitAssetTimeout: undefined,
            uamWebSocketMessageTimeout: undefined,
            uamUpdateSettingTimeout: undefined,
        },
    },
};
