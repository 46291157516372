export const CAAB = 'caab';
export const CABC = 'cabc';
export const CAON = 'caon';
export const CZ = 'cz';
export const FI = 'fi';
export const FR = 'fr';
export const GR = 'gr';
export const IT = 'it';
export const JP = 'jp';
export const NZ = 'nz';
export const PMI = 'pmi';
export const RO = 'ro';
export const UA = 'ua';
export const UAT = 'uat';
export const UK = 'gb';
