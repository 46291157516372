import appConfig from '../config/appConfig';
import * as marketTypes from '../consts/market/marketTypes';
import scpCloudService from './scpCloud/scpCloudService';

const convertMarketNameIfNeed = () => {
    const baseMarketName = appConfig.getScpCloudTopicMarket();

    if (baseMarketName === marketTypes.UK) {
        const UK_LOCALIZATION_MARKET_NAME = 'uk';

        return scpCloudService.getCompoundedScpCloudTopicMarket(UK_LOCALIZATION_MARKET_NAME);
    } else {
        return scpCloudService.getCompoundedScpCloudTopicMarket();
    }
};

const currentMarket = () => convertMarketNameIfNeed();

export default {
    currentMarket,
};
