import React from 'react';

import domClassNames from '../consts/app/domClassNames';
import queryParameters from '../consts/route/queryParameters';

type TAttributeData = string | null | undefined;
type TOverloadKeyboardEvents = {
    (event: KeyboardEvent, postFunc: (e: KeyboardEvent) => any): void;
    (event: React.KeyboardEvent, postFunc: (e: React.KeyboardEvent) => any): void;
};

const getHtmlElement = (): HTMLElement => document.documentElement;
const getDataFromRoot = (attr: string): TAttributeData => getRoot().getAttribute(attr);
const getDataLanguageFromRoot = (): TAttributeData => getDataFromRoot(queryParameters.DATA_LANGUAGE);
const getDataUserTokenFromRoot = (): TAttributeData => getDataFromRoot(queryParameters.DATA_USER_TOKEN);
const getElementBySelector = (selector: string): HTMLElement | null => document.querySelector(selector);
const getAllElementsBySelector = (selector: string): NodeListOf<HTMLElement> => document.querySelectorAll(selector);
const getRoot = (): HTMLElement => document.getElementById('root')!;
const getAppElement = (): HTMLElement => getElementBySelector(`.${domClassNames.APP_CLASSNAME}`)!;
const getComponentsWrapperElement = (): HTMLElement =>
    getElementBySelector(`.${domClassNames.COMPONENTS_CONTAINER_CLASS_NAME}`)!;
const getTokenUrlFromRoot = (): TAttributeData => getDataFromRoot(queryParameters.DATA_REFRESH_TOKEN_URL);
const getDeviceCodentifyFromRoot = (): TAttributeData => {
    const codentify = getDataFromRoot(queryParameters.DATA_DEVICE_CODENTIFY);

    if (codentify && codentify.toLowerCase() === 'undefined') {
        return null;
    }
    return codentify;
};

const getDeviceFactoryFromRoot = (): string | boolean | undefined | null => {
    const DATA_FACTORY_RESPONSE_YES = 'Y';
    const DATA_FACTORY_RESPONSE_NO = 'N';
    const DATA_FACTORY_RESPONSE_NULL = 'null';
    const DATA_FACTORY_RESPONSE_UNDEFINED = 'undefined';
    const DATA_FACTORY_RESPONSE_EMPTY = '';
    const dataFactory = getDataFromRoot(queryParameters.DATA_DEVICE_FACTORY);

    switch (dataFactory) {
        case DATA_FACTORY_RESPONSE_YES:
            return true;
        case DATA_FACTORY_RESPONSE_NO:
            return false;
        case DATA_FACTORY_RESPONSE_NULL:
            return null;
        case DATA_FACTORY_RESPONSE_UNDEFINED:
        case DATA_FACTORY_RESPONSE_EMPTY:
        case null:
            return '';
        default:
            return undefined;
    }
};

const handleBtnKeyPress: TOverloadKeyboardEvents = (event: any, postFunc: any) => {
    if (event.key === ' ' || event.key === 'Enter' || event.key === 'Spacebar') {
        event.preventDefault();
        postFunc(event);
    }
};

const handleChekboxKeyPress: TOverloadKeyboardEvents = (event: any, postFunc: any) => {
    if (event.key === ' ' || event.key === 'Spacebar') {
        event.preventDefault();
        postFunc(event);
    }
};

const setFocusToDomElement = (domElement: HTMLElement): void => domElement?.focus();

export default {
    getAllElementsBySelector,
    getAppElement,
    getComponentsWrapperElement,
    getDataFromRoot,
    getDataLanguageFromRoot,
    getDataUserTokenFromRoot,
    getDeviceCodentifyFromRoot,
    getDeviceFactoryFromRoot,
    getElementBySelector,
    getHtmlElement,
    getRoot,
    getTokenUrlFromRoot,
    handleBtnKeyPress,
    handleChekboxKeyPress,
    setFocusToDomElement,
};
