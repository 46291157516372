import converter from '../../utils/converter';
import CommunicatorClientBase, {IStateBase} from '../device/communicatorClientBase';
import log from '../logger/log';
import ConnectedExponentialBackOff from './connectedExponentialBackOff';
import IqosBleClient from './iqosBleClient';

let instance: ScpCharacteristicClient | null = null;

const RESPONSE_TIMEOUT_MS = 10 * 1000;
const WRITE_FRAME_RETRY_COUNT = 1;

interface IState extends IStateBase {
    isFullResponse: boolean;
    isWriteSuccess: boolean;
}

export default class ScpCharacteristicClient extends CommunicatorClientBase<IqosBleClient, IState> {
    constructor(createNew = false) {
        super();

        if (createNew && instance) {
            instance = null;
        }

        if (instance) {
            return instance;
        }

        instance = this;
        this.isActive = true;

        this.iqosClient = new IqosBleClient();
        this.resetState();
        this.addCharacteristicListener();
    }

    disable(): void {
        this.isActive = false;
        this.clearResponseTimeout();
        this.clearQueue();
    }

    getInitiaState(): IState {
        return {
            currentFrame: undefined,
            framesToResponse: [],
            isFullResponse: true,
            isWriteSuccess: true,
            onError: undefined,
            onSuccess: undefined,
            responseFrameHex: '',
            responses: [],
            retryCount: WRITE_FRAME_RETRY_COUNT,
            timeout: 0,
            wrongHeaderRetryCount: 0,
            skipErrorOnTimeout: undefined,
        };
    }

    resetResponseState(): void {
        this.state.isFullResponse = true;
        this.state.isWriteSuccess = true;
        this.state.responseFrameHex = '';
    }

    private addCharacteristicListener(): void {
        this.iqosClient.addScpCharacteristicListener(this.onScpCharacteristicResponse);
    }

    private onScpCharacteristicResponse = (value: DataView): void => {
        this.clearResponseTimeout();
        this.state.responseFrameHex += converter.buffer2hex(value);
        this.state.isFullResponse = false;

        const {responseFrameHex, currentFrame} = this.state;
        const restFrameCount = value.getUint8(0);

        log.debug(
            `ScpCharacteristicClient: frame: ${currentFrame}, received from SCP frame: ${responseFrameHex}, restFrameHexCount=${restFrameCount}`
        );

        if (restFrameCount === 0) {
            this.state.isFullResponse = true;
            this.state.responses.push(responseFrameHex);
            this.writeFrames();
        }
    };

    writeFrames(forceWriting?: boolean): void {
        const {framesToResponse, responses, isWriteSuccess, isFullResponse, timeout} = this.state;

        if ((isWriteSuccess && isFullResponse) || forceWriting) {
            const responseNumber = responses.length;
            const itWasLastFrame = !framesToResponse || responseNumber >= framesToResponse.length;

            if (itWasLastFrame) {
                this.onFramesResponse();
            } else {
                const frame = framesToResponse[responseNumber];

                this.resetResponseState();
                setTimeout(() => this.writeFrame(frame), timeout);
            }
        }
    }

    writeFrame = (frame: string): void => {
        const exponentialBackOff = new ConnectedExponentialBackOff();

        this.state.isWriteSuccess = false;
        this.state.isFullResponse = false;

        this.state.currentFrame = frame;
        const success = () => {
            if (this.isActive && this.iqosClient.isDeviceConnected()) {
                log.debug(`ScpCharacteristicClient: write frame - ${frame}, success`);
                this.state.isWriteSuccess = true;
                this.writeFrames();
            }
        };
        const fail = (e: Error) => {
            this.clearResponseTimeout();
            if (this.isActive) {
                log.debug(`ScpCharacteristicClient: write frame - ${frame}: error: ${e}`);

                this.onFrameResponseError();
            } else {
                this.clearQueue();
            }
        };
        const toTry = async () => {
            if (this.isActive) {
                if (this.iqosClient.isDeviceConnected()) {
                    log.debug(`ScpCharacteristicClient: write frame - ${frame}`);
                    this.initResponseTimeout(frame, exponentialBackOff);
                    await this.iqosClient.writeValueToScpCharacteristic(frame, true);
                } else {
                    log.debug(
                        `ScpCharacteristicClient: writeFrame - writing frame: ${frame} is stopped, device is disconnected`
                    );
                }
            } else {
                this.clearQueue();
            }
        };

        exponentialBackOff.run(3, 200, toTry, success, fail);
    };

    initResponseTimeout = (frame: string, exponentialBackOff: ConnectedExponentialBackOff): void => {
        this.clearResponseTimeout();
        this.responseTimeout = setTimeout(() => {
            this.onResponseTimeout(frame, exponentialBackOff);
        }, RESPONSE_TIMEOUT_MS);
    };

    onResponseTimeout = (frame: string, exponentialBackOff: ConnectedExponentialBackOff): void => {
        exponentialBackOff.unsubscribe();

        if (this.isActive) {
            const {retryCount, skipErrorOnTimeout} = this.state;

            if (retryCount > 0) {
                this.state.retryCount = retryCount - 1;

                log.debug(
                    `ScpCharacteristicClient: onResponseTimeout, there is no response from device for ${frame} frame. Try to write frame again, retry #${
                        WRITE_FRAME_RETRY_COUNT - this.state.retryCount
                    }, skipErrorOnTimeout: ${skipErrorOnTimeout}`
                );

                this.writeFrames(true);
            } else {
                if (skipErrorOnTimeout) {
                    this.state.responses.push('');
                    this.writeFrames(true);
                } else {
                    this.onFrameResponseError();
                }
            }
        } else {
            this.clearQueue();
        }
    };
}
