import cryptoService from '../cryptoService';

export default class StorageClient {
    storage: Storage;

    constructor(storage: Storage) {
        this.storage = storage;
    }

    getItem = <T>(key: string): T => JSON.parse(this.storage.getItem(key) as string);

    setItem = <T>(key: string, value: T): void => this.storage.setItem(key, JSON.stringify(value));

    removeItem = (key: string): void => this.storage.removeItem(key);

    getEncodedItem = <T>(key: string): T | null => {
        try {
            const item = this.storage.getItem(key);
            const encodedItem = cryptoService.encodeAtob(item);

            return JSON.parse(encodedItem as string);
        } catch (e) {
            return null;
        }
    };

    setDecodedItem = <T>(key: string, value: T): void =>
        this.storage.setItem(key, cryptoService.decodeBtoa(JSON.stringify(value)) as string);
}
