import cn from 'classnames';
import React, {FC} from 'react';

import {IButton} from '../Button/ButtonTypes';
import Link from '../Link/Link';
import styles from './Button.module.scss';

const ButtonRaw: FC<IButton> = (props) => {
    const {className, disabled, to, onClick, onDisableClick, children, ...restProps} = props;
    const btnClassName = cn(styles.ButtonRaw, className, {
        'ica-disabled': disabled,
    });

    const eventProps = disabled
        ? {
              onClick: onDisableClick,
          }
        : {
              to,
              onClick,
          };

    return (
        <Link {...restProps} {...eventProps} disabled={disabled} className={btnClassName}>
            {children}
        </Link>
    );
};

export default ButtonRaw;
