import appConfig from '../config/appConfig';
import deviceTypesService from './device/deviceTypesService';

//TODO: rename service

const isDeviceSupported = (type) =>
    (appConfig.getEnableOrdinarySupport() && deviceTypesService.isOrdinary(type)) ||
    (appConfig.getEnableCortezSupport() && deviceTypesService.isCortez(type)) ||
    (appConfig.getEnableSoyuzSupport() && deviceTypesService.isSoyuz(type)) ||
    (appConfig.getEnableVespucciSupport() && deviceTypesService.isVespucci(type)) ||
    (appConfig.getEnableP4Support() && deviceTypesService.isP4(type)) ||
    (appConfig.getEnableVoyagerSupport() && deviceTypesService.isVoyager(type)) ||
    (appConfig.getEnableDeltaSupport() && deviceTypesService.isDelta(type)) ||
    (appConfig.getEnableCosmosSupport() && deviceTypesService.isCosmos(type));

const isIccJourneyScreensEnabled = () => appConfig.getIsICCConsumerServiceEnabled();

const isDeviceListEnabled = () => appConfig.getIsDeviceListEnabled() && isIccJourneyScreensEnabled();
const isDeviceRegistrationEnabled = () => appConfig.getIsDeviceRegistrationEnabled() && isIccJourneyScreensEnabled();
const isDeviceRenameEnabled = () => appConfig.getIsDeviceRenameEnabled() && isIccJourneyScreensEnabled();
const isDeviceUnregistrationEnabled = () =>
    appConfig.getIsDeviceUnregistrationEnabled() && isIccJourneyScreensEnabled();
const isTNCEnabled = () => appConfig.getIsTNCEnabled() && isIccJourneyScreensEnabled();

export default {
    isDeviceListEnabled,
    isDeviceRegistrationEnabled,
    isDeviceRenameEnabled,
    isDeviceSupported,
    isDeviceUnregistrationEnabled,
    isIccJourneyScreensEnabled,
    isTNCEnabled,
};
