import React from 'react';
import {Offline} from 'react-detect-offline';

import standaloneLocalization from '../../../config/standaloneConfig/standaloneLocalization';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import log from '../../../services/logger/log';
import networkStatusService from '../../../services/networkStatusService';
import {setIsNetworkOnline, setNotificationInfo, setNotificationSuccess} from '../../../state/ducks/global';
import {dispatch} from '../../../state/store';

const OfflineDetector = () => {
    const localizedStrings = getLocalizedStrings();

    const onChange = (isOnline) => {
        dispatch(setIsNetworkOnline(isOnline));
        if (isOnline) {
            const onlineNotificationText = localizedStrings
                ? localizedStrings[localizationKeys.GLOBAL_DETECTOR_ONLINE]
                : standaloneLocalization.DETECTOR_ONLINE;

            dispatch(setNotificationSuccess(onlineNotificationText));
            networkStatusService.setIsOnline();
            log.resetLogger();
            log.info(`OfflineDetector: Internet connection is online`);
        } else {
            const offlineNotificationText = localizedStrings
                ? localizedStrings[localizationKeys.GLOBAL_DETECTOR_OFFLINE]
                : standaloneLocalization.DETECTOR_OFFLINE;

            dispatch(setNotificationInfo(offlineNotificationText));
            networkStatusService.setIsOffline();
            log.info(`OfflineDetector: Internet connection is offline`);
        }
    };

    const pollingUrl = process.env.PUBLIC_URL + '/storage/polling.png';

    return (
        <Offline
            polling={{
                interval: 10000,
                url: pollingUrl,
            }}
            onChange={onChange}
        />
    );
};

export default OfflineDetector;
