import React from 'react';

import Icon from './Icon';

const IconLock = (props) => {
    return (
        <Icon width='13' height='16' viewBox='0 0 13 16' {...props}>
            <g fill='none' fillRule='evenodd' stroke='#383839'>
                <path
                    strokeLinejoin='round'
                    d='M12.405 15.875H2.282c-1.57 0-2.252-.773-2.252-1.905V5.75h12.375v10.125z'
                />
                <path strokeLinecap='round' d='M6.306 9.725v2.51' />
                <path d='M10.155 5.763V4.45c0-2.233-1.763-4.043-3.938-4.043-1.198 0-2.27.55-2.993 1.416-.137.165-.261.34-.372.527' />
            </g>
        </Icon>
    );
};

export default IconLock;
