import * as qs from 'qs';
import urlJoin from 'url-join';

import arrayUtils from './arrayUtils';

type TJoin = (...params: string[]) => string;
type TParseQueryString = (query: string) => qs.ParsedQs;
type TStringify = (obj: {[key: string]: any}, options?: qs.IStringifyOptions) => string;
type TGetDataFromUrl = (...keys: [string]) => any[];
type TGetOriginLocation = () => string;
type TGetCurrentLocation = () => string;
type TGetCurrentPathname = () => string;
type TGetParameterFromPath = (index: number) => string | null;
type TUpdateParams = (url: string, params: any[], splitter: string) => string;

const join: TJoin = (...params) => urlJoin(params);

const parseQueryString: TParseQueryString = (query) => qs.parse(query, {ignoreQueryPrefix: true});

const stringify: TStringify = (obj, options) => qs.stringify(obj, options);

const getDataFromUrl: TGetDataFromUrl = (...keys) => {
    const url = window.location.search;
    const parsedUrl = parseQueryString(url);
    const keysList = arrayUtils.toArray(keys);

    return keysList.map((key) => parsedUrl[key]);
};

const getOriginLocation: TGetOriginLocation = () => window.location.origin;
const getCurrentLocation: TGetCurrentLocation = () => window.location.href;
const getCurrentPathname: TGetCurrentPathname = () => window.location.pathname;

const getParameterFromPath: TGetParameterFromPath = (index) => {
    try {
        const path = getCurrentPathname();
        const pathSplitted = path.split('/');

        return pathSplitted[index + 1];
    } catch (e) {
        return null;
    }
};

const updateParams: TUpdateParams = (url, params, splitter = '&') => {
    const paramsArray = arrayUtils.toArray(params);
    const isEntryDataValid = typeof url === 'string' && url && paramsArray.length;

    if (isEntryDataValid) {
        const splittedUrl = url.split(splitter);

        paramsArray.forEach((param) => {
            const {name, value, separator = '='} = param || {};
            const matchedParam = splittedUrl.find((urlParam) => urlParam.split(separator)[0] === name);

            if (matchedParam) {
                const matchedParamSplited = matchedParam.split('=');

                matchedParamSplited.pop();
                matchedParamSplited.push(value);

                const matchedParamJoined = matchedParamSplited.join('=');
                const matchedParamIndex = splittedUrl.indexOf(matchedParam);

                if (matchedParamIndex !== -1) {
                    splittedUrl[matchedParamIndex] = matchedParamJoined;
                }
            } else {
                splittedUrl.push(`${name}=${value}`);
            }
        });
        return splittedUrl.join(splitter);
    } else {
        return url;
    }
};

export default {
    getCurrentLocation,
    getCurrentPathname,
    getDataFromUrl,
    getOriginLocation,
    getParameterFromPath,
    join,
    parseQueryString,
    stringify,
    updateParams,
};
