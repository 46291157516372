import {useEffect} from 'react';

import appConfig from '../../../config/appConfig';
import domClassNames from '../../../consts/app/domClassNames';
import domService from '../../../services/domService';

const DEFAULT_CLASS_MAPPING = [
    domClassNames.FONT_BIG,
    domClassNames.FONT_MEDIUM,
    domClassNames.HIGH_CONTRAST,
    domClassNames.GREYSCALE,
];

const AccessibilityToolChecker = () => {
    const htmlEl = domService.getHtmlElement();
    const rootEl = domService.getRoot();
    const config = {attributes: true};
    const marketClassMapping = appConfig.getAccessibilityClassMapping() || DEFAULT_CLASS_MAPPING;
    const marketClassNames = marketClassMapping.map((classMapping) => {
        return classMapping.icaClassName;
    });

    const reassignClasses = (element) => {
        element.classList.forEach((className) => {
            const icaClassName = marketClassMapping.find(
                (classMapping) => classMapping.className === className
            )?.icaClassName;

            if (icaClassName) rootEl.classList.add(icaClassName);
        });
    };

    const callback = (mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.attributeName === 'class') {
                rootEl.classList.remove(...marketClassNames);
                reassignClasses(htmlEl);
            }
        });
    };

    useEffect(() => {
        const observer = new MutationObserver(callback);

        observer.observe(htmlEl, config);

        reassignClasses(htmlEl);
    }, []);

    return null;
};

export default AccessibilityToolChecker;
