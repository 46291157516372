import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import useDidUpdate from '../../../hooks/useDidUpdate';
import yapCheckStatusService from '../../../services/yap/yapCheckStatusService';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../../state/selectors/consumer';
import {makeSelectIsNetworkOnline} from '../../../state/selectors/global';

const mapStateToProps = createStructuredSelector({
    isNetworkOnline: makeSelectIsNetworkOnline(),
    iotProduct: makeSelectIotDeviceMergedWithIccProduct(),
});

const YapRequestStatusChecker = ({isNetworkOnline, iotProduct}) => {
    const {deviceSerialNumber: chargerSerialNumber, holder} = iotProduct || {};
    const {deviceSerialNumber: holderSerialNumber} = holder || {};

    useDidUpdate(() => {
        if (isNetworkOnline) {
            yapCheckStatusService.checkStatusOneTime(chargerSerialNumber);
            yapCheckStatusService.checkStatusOneTime(holderSerialNumber, true);
        }
    }, [isNetworkOnline]);

    return null;
};

export default connect(mapStateToProps)(YapRequestStatusChecker);
