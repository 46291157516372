import React, {FC} from 'react';

import styles from './ButtonArrowEnterSVG.module.scss';

const ButtonArrowEnterSVG: FC = () => {
    return (
        <div className={styles.ArrowContainer}>
            <ArrowEnterSVGIcon />
        </div>
    );
};

const ArrowEnterSVGIcon: FC = () => {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' className={styles.Arrow}>
            <path
                d='M16.621 10.5h-14.2c-.275
                0-.5-.224-.5-.5s.225-.5.5-.5h14.016L14.63
                7.507c-.186-.204-.17-.52.034-.706.205-.185.52-.17.706.035l2.69
                2.966c.18.197.173.5-.016.69l-2.69
                2.69c-.196.195-.512.195-.708
                0-.195-.195-.195-.512
                0-.707l1.975-1.975z'
            />
        </svg>
    );
};

export default ButtonArrowEnterSVG;
