import React from 'react';

import Icon from './Icon';

const PurchaseDateIcon = (props) => {
    return (
        <Icon width='24' height='24' viewBox='0 0 990 990' {...props}>
            <path
                stroke='none'
                d='M650 200l0 0c29 0 54-25 54-54v0c0-29-25-54-54-54h0c-29 0-54 25-54 54v0c0 33 25 54 54 54z m-217 0l0 0c30 0 55-25 55-54v0c0-29-25-54-55-54h0c-29 0-54 25-54 54v0c0 33 25 54 54 54z m-229 0l0 0c29 0 54-25 54-54v0c0-29-25-54-54-54h0c-29 0-54 25-54 54v0c0 33 25 54 54 54z m446 196l0 0c29 0 54-25 54-54v0c0-29-25-54-54-54h0c-29 0-54 25-54 54v0c0 29 25 54 54 54z m-221 0l0 0c29 0 54-25 54-54v0c0-29-25-54-54-54h0c-29 0-54 25-54 54v0c0 29 25 54 54 54z m-225 0l0 0c29 0 54-25 54-54v0c0-29-25-54-54-54h0c-29 0-54 25-54 54v0c0 29 25 54 54 54z m629 354c-50 50-116 79-191 79l-696-12 17-955 954 17v684c-4 70-34 137-84 187z m-191 0c50 0 96-21 133-54 21-21 33-46 46-71h-792l0 113 613 12z m-604-804l-9 596h800v-584l-791-12z'
                transform='translate(10, -100) rotate(179 460 460)'
            />
        </Icon>
    );
};

export default PurchaseDateIcon;
