import {Action} from 'redux';

import {IMwState, TMwActionProps} from '../types/mwDevice';
import {IFirmwareData, IIsDisconnectedWithNoReconnect, IMwInternalId, TFirmwareData} from './../types/mwDevice';

enum ActionTypes {
    CLEAR_MW_DEVICE = 'app/mwDevice/CLEAR_MW_DEVICE',
    SET_MW_DEVICE_FIRMWARE_DATA = 'app/mwDevice/SET_MW_DEVICE_FIRMWARE_DATA',
    SET_MW_DEVICE_INTERNAL_ID = 'app/mwDevice/SET_MW_DEVICE_INTERNAL_ID',
    SET_MW_DEVICE_IS_ADDITIONAL_FWU_GUIDE_NEEDED = 'app/mwDevice/SET_MW_DEVICE_IS_ADDITIONAL_FWU_GUIDE_NEEDED',
    SET_MW_DEVICE_IS_DISCONNECTED_WITH_NO_RECONNECT = 'app/mwDevice/SET_MW_DEVICE_IS_DISCONNECTED_WITH_NO_RECONNECT',
}

export interface IMwAction extends Action {
    type: ActionTypes;
    payload?: TMwActionProps;
}

const initialState: IMwState = {
    firmwareData: {},
    deviceSerialNumber: undefined,
    isIdentified: false,
    isDisconnectedWithNoReconnect: true,
};

export default (state = initialState, action: IMwAction): IMwState => {
    switch (action.type) {
        case ActionTypes.CLEAR_MW_DEVICE: {
            return initialState;
        }
        case ActionTypes.SET_MW_DEVICE_FIRMWARE_DATA: {
            const {firmwareData} = <IFirmwareData>action.payload;

            return {...state, firmwareData: {...state.firmwareData, ...firmwareData}};
        }
        case ActionTypes.SET_MW_DEVICE_IS_DISCONNECTED_WITH_NO_RECONNECT: {
            const {isDisconnectedWithNoReconnect} = <IIsDisconnectedWithNoReconnect>action.payload;

            return {...state, isDisconnectedWithNoReconnect};
        }
        case ActionTypes.SET_MW_DEVICE_INTERNAL_ID: {
            const {deviceSerialNumber, isIdentified} = <IMwInternalId>action.payload;

            return {...state, deviceSerialNumber, isIdentified};
        }
        default:
            return state;
    }
};

export const clearMwDevice = (): IMwAction => ({
    type: ActionTypes.CLEAR_MW_DEVICE,
});

export const setMwDeviceFirmwareData = (firmwareData: TFirmwareData): IMwAction => ({
    type: ActionTypes.SET_MW_DEVICE_FIRMWARE_DATA,
    payload: {firmwareData},
});

export const setMwDeviceInternalId = (data: IMwInternalId): IMwAction => ({
    type: ActionTypes.SET_MW_DEVICE_INTERNAL_ID,
    payload: data,
});

export const setMwDeviceIsDisconnectedWithNoReconnect = (isDisconnectedWithNoReconnect: boolean): IMwAction => ({
    type: ActionTypes.SET_MW_DEVICE_IS_DISCONNECTED_WITH_NO_RECONNECT,
    payload: {isDisconnectedWithNoReconnect},
});

export const clearMwDeviceInternalId = (): IMwAction => ({
    type: ActionTypes.SET_MW_DEVICE_INTERNAL_ID,
    payload: {deviceSerialNumber: undefined, isIdentified: false},
});
