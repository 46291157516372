import loadable from '@loadable/component';
import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import * as productRegistrationStatusTypes from '../../consts/product/productRegistrationStatusTypes';
import {makeSelectCurrentProductToChangeRegistrationStatus} from '../../state/selectors/productSettings';

const ProductRegisteringStatus = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.comp_reg" */ './ProductRegisteringStatus/ProductRegisteringStatus')
);
const ProductUnregisteringStatus = loadable(() =>
    import(/* webpackChunkName: "lazy-chunk.comp_unreg" */ './ProductUnregisteringStatus/ProductUnregisteringStatus')
);

const mapStateToProps = createStructuredSelector({
    currentProductToChangeRegistrationStatus: makeSelectCurrentProductToChangeRegistrationStatus(),
});

const ProductRegistrationStatus = (props) => {
    const {currentProductToChangeRegistrationStatus} = props;

    if (!currentProductToChangeRegistrationStatus) return null;

    const Component =
        currentProductToChangeRegistrationStatus.status === productRegistrationStatusTypes.REGISTERING
            ? ProductRegisteringStatus
            : ProductUnregisteringStatus;

    return (
        <Component
            productToChangeRegistrationStatus={currentProductToChangeRegistrationStatus}
            key={currentProductToChangeRegistrationStatus.deviceSerialNumber}
        />
    );
};

export default connect(mapStateToProps)(ProductRegistrationStatus);
