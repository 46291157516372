import {createSelector} from 'reselect';

import * as iotMessageTypes from '../../consts/iot/iotMessageTypes';
import objUtils from '../../utils/objUtils';

const selectState = (state) => state.iot;

const selectIot = createSelector(selectState, (state) => state);

const selectIotMessage = createSelector(selectIot, (state) => objUtils.returnEmptyObjectIfUndefined(state.message));

const selectIotCurrentFlowType = createSelector(selectIot, (state) => state.currentFlowType);

const selectIsCurrentFlowCleaning = createSelector(
    selectIotCurrentFlowType,
    (type) => type === iotMessageTypes.CLEANING
);

export {selectIotMessage, selectIsCurrentFlowCleaning};
