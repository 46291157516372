export default {
    APP_CLASSNAME: 'ica',
    COMPONENTS_CONTAINER_CLASS_NAME: 'ica-components-wrapper',
    FONT_BIG: {
        className: 'font-big',
        icaClassName: 'ica-font-big',
    },
    FONT_MEDIUM: {
        className: 'font-medium',
        icaClassName: 'ica-font-medium',
    },
    HIGH_CONTRAST: {
        className: 'high-contrast',
        icaClassName: 'ica-high-contrast',
    },
    GREYSCALE: {
        className: 'greyscale',
        icaClassName: 'ica-greyscale',
    },
};
