import React, {FC} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import BackButton from '../../../../../components/BackButton/BackButton';
import {ButtonDark} from '../../../../../components/Button';
import Carousel from '../../../../../components/Carousel/Carousel';
import GuideSliderItem from '../../../../../components/GuideSliderItem/GuideSliderItem';
import Image from '../../../../../components/Image/Image';
import Container from '../../../../../components/Layout/Container/Container';
import * as localizationKeys from '../../../../../consts/localization/localizationKeys';
import layoutService from '../../../../../services/layoutService';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import {makeSelectLayoutType} from '../../../../../state/selectors/global';
import elementsTesIds from '../../../../../test/consts/elementsTesIds';
import styles from './FWUAdditionalStepsGuide.module.scss';
import {fwuAdditionalStepsGuideData} from './FWUAdditionalStepsGuideData';

const mapStateToProps = createStructuredSelector({
    layout: makeSelectLayoutType(),
});

interface IProps {
    layout: number;
    onNextClick: () => void;
    onBackButtonClick: () => void;
}

const FWUAdditionalStepsGuide: FC<IProps> = (props) => {
    const {layout, onNextClick, onBackButtonClick} = props;
    const isDesktopLayout = layoutService.isDesktopLayout(layout);
    const localizedStrings = getLocalizedStrings();

    const lastSlideButton = (
        <ButtonDark showArrow onClick={onNextClick} className={styles.ContinueButton}>
            {localizedStrings[localizationKeys.QUICK_START_GUIDE_NEXT_BUTTON_TEXT]}
        </ButtonDark>
    );

    return (
        <>
            <Container isFloatHeight>
                <BackButton
                    ariaLabel={
                        localizedStrings[
                            localizationKeys.FWU_ADDITIONAL_STEPS_GUIDE_BTN_BACK_TO_ABOUT_DEVICE_ARIA_LABEL
                        ]
                    }
                    onClick={onBackButtonClick}
                    testId={elementsTesIds.ABOUT_DEVICE_BTN_BACK}
                />
            </Container>
            <Container className={styles.CommonContainer}>
                <Carousel
                    isControlArrowVisible
                    lastSlideButton={lastSlideButton}
                    isDotsVisible={isDesktopLayout}
                    nextSlideButtonClassName={styles.NextButtonClassName}
                    isCommonNextSlideButtonVisible={!isDesktopLayout}
                >
                    {fwuAdditionalStepsGuideData.map(
                        (element: {images: string | Array<string>; header: string}, index: number) => {
                            const {header, images} = element;

                            return (
                                <div key={index.toString()}>
                                    <GuideSliderItem header={header} element={<Image src={images} />} />
                                </div>
                            );
                        }
                    )}
                </Carousel>
            </Container>
        </>
    );
};

export default connect(mapStateToProps)(FWUAdditionalStepsGuide);
