import cn from 'classnames';
import React, {useEffect} from 'react';

import {Button, ButtonBorder} from '../../../components/Button';
import ConnectionTrackButton from '../../../components/Button/ConnectionTrackButton/ConnectionTrackButton';
import Container from '../../../components/Layout/Container/Container';
import PopupFullPage from '../../../components/Popup/PopupFullPage/PopupFullPage';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import analyticsService from '../../../services/analyticsService';
import connectivityService from '../../../services/device/connectivityService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {hideModalAction} from '../../../state/ducks/modal';
import elementsTesIds from '../../../test/consts/elementsTesIds';
import helpers from '../../../utils/helpers';
import IconTextList from './components/IconTextList/IconTextList';
import styles from './PairingSkipPopup.module.scss';

const PairingSkipPopup = (props) => {
    const {onTryAgain, onSkip, modalType, dispatch, deviceType} = props;
    const localizedStrings = getLocalizedStrings();
    const descriptionClassName = cn(styles.Description, 'ica--txt-regular');
    const isBle = connectivityService.isBle();
    const description =
        localizedStrings[
            isBle ? localizationKeys.QUIT_SET_UP_DESCRIPTION_TEXT : localizationKeys.QUIT_SET_UP_USB_DESCRIPTION_TEXT
        ];

    const hidePopup = () => dispatch(hideModalAction(modalType));
    const onTryAgainBtnClick = () => {
        //hide popup directly, fix documentTitle bug
        hidePopup();
        helpers.runFunction(onTryAgain);
    };
    const onSkipBtnClick = () => {
        hidePopup();
        helpers.runFunction(onSkip);
    };

    useEffect(() => analyticsService.pushPairingQuitSetupEvent(deviceType), []);

    useDocumentTitle(localizedStrings[localizationKeys.QUIT_DEVICE_SET_UP_TITLE], true);

    return (
        <PopupFullPage
            containerClassName={styles.PopupContainer}
            className={styles.Popup}
            data-testid={elementsTesIds.PAIRING_SEQUENCE_SKIP_PAGE}
        >
            <Container className={styles.Container}>
                <section>
                    <h2 className={styles.Title}>{localizedStrings[localizationKeys.QUIT_SET_UP_TITLE_TEXT]}</h2>
                    <p className={descriptionClassName}>{description}</p>
                    <IconTextList />
                </section>
                <div className={styles.Footer}>
                    <ButtonBorder
                        className={styles.ButtonAgree}
                        onClick={onTryAgainBtnClick}
                        showArrow
                        testId={elementsTesIds.PAIRING_SKIP_POPUP_TRY_AGAIN_BUTTON}
                    >
                        {localizedStrings[localizationKeys.QUIT_SET_UP_CONFIRM_BUTTON_TEXT]}
                    </ButtonBorder>
                    <ConnectionTrackButton
                        ariaLabel={
                            localizedStrings[localizationKeys.QUIT_DEVICE_SET_UP_BTN_DEVICE_LIST_PAGE_ARIA_LABEL]
                        }
                        button={Button}
                        className={styles.ButtonDecline}
                        onClick={onSkipBtnClick}
                        showUnderline
                        testId={elementsTesIds.PAIRING_SKIP_POPUP_SKIP_BUTTON}
                    >
                        {localizedStrings[localizationKeys.QUIT_SET_UP_SKIP_BUTTON_TEXT]}
                    </ConnectionTrackButton>
                </div>
            </Container>
        </PopupFullPage>
    );
};

export default PairingSkipPopup;
