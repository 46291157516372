import DEVICE_TYPES from '../../enums/device/deviceTypes';
import PRODUCT_COLORS from './productColors';
import PRODUCT_COLOR_TYPES from './productColorTypes';

const PRODUCT_COLORS_MAPPING = {
    [DEVICE_TYPES.DEV_TYPE_V24P_CHARGER]: {
        [PRODUCT_COLOR_TYPES.BRILLIANT_GOLD]: PRODUCT_COLORS.MELLOW_GOLD,
        [PRODUCT_COLOR_TYPES.RUBY]: PRODUCT_COLORS.PINK,
        [PRODUCT_COLOR_TYPES.MOTOR_SPORTS]: PRODUCT_COLORS.QUILL_GRAY,
        [PRODUCT_COLOR_TYPES.BLUE]: PRODUCT_COLORS.CATSKILL_WHITE,
        [PRODUCT_COLOR_TYPES.NIGO]: PRODUCT_COLORS.GRAY_NURSE,
        [PRODUCT_COLOR_TYPES.RED]: PRODUCT_COLORS.BIZARRE,
        [PRODUCT_COLOR_TYPES.NAVY]: PRODUCT_COLORS.IRON,
        [PRODUCT_COLOR_TYPES.WHITE]: PRODUCT_COLORS.WARM_WHITE,
        [PRODUCT_COLOR_TYPES.PINK]: PRODUCT_COLORS.ROSE_WHITE,
    },
    [DEVICE_TYPES.DEV_TYPE_P1V30M]: {
        [PRODUCT_COLOR_TYPES.WARM_WHITE]: PRODUCT_COLORS.WARM_WHITE,
        [PRODUCT_COLOR_TYPES.BRILLIANT_GOLD]: PRODUCT_COLORS.MELLOW_GOLD,
        [PRODUCT_COLOR_TYPES.GRAPHITE]: PRODUCT_COLORS.CONCRETE,
        [PRODUCT_COLOR_TYPES.BLUE]: PRODUCT_COLORS.CATSKILL_WHITE,
        [PRODUCT_COLOR_TYPES.RED]: PRODUCT_COLORS.BIZARRE,
        [PRODUCT_COLOR_TYPES.WHITE]: PRODUCT_COLORS.WARM_WHITE,
    },
    [DEVICE_TYPES.DEV_TYPE_P1V30_CHARGER]: {
        [PRODUCT_COLOR_TYPES.WARM_WHITE]: PRODUCT_COLORS.WARM_WHITE,
        [PRODUCT_COLOR_TYPES.SILVER_BLUE]: PRODUCT_COLORS.CATSKILL_WHITE,
        [PRODUCT_COLOR_TYPES.BRILLIANT_GOLD]: PRODUCT_COLORS.MELLOW_GOLD,
        [PRODUCT_COLOR_TYPES.GRAPHITE]: PRODUCT_COLORS.CONCRETE,
        [PRODUCT_COLOR_TYPES.BLUE]: PRODUCT_COLORS.CATSKILL_WHITE,
        [PRODUCT_COLOR_TYPES.RED]: PRODUCT_COLORS.BIZARRE,
        [PRODUCT_COLOR_TYPES.WHITE]: PRODUCT_COLORS.WARM_WHITE,
    },
    [DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER]: {
        [PRODUCT_COLOR_TYPES.WARM_ORANGE]: PRODUCT_COLORS.LINEN,
        [PRODUCT_COLOR_TYPES.BRILLIANT_GOLD]: PRODUCT_COLORS.MELLOW_GOLD,
        [PRODUCT_COLOR_TYPES.COPPER]: PRODUCT_COLORS.LINEN,
        [PRODUCT_COLOR_TYPES.GRAPHITE]: PRODUCT_COLORS.CONCRETE,
        [PRODUCT_COLOR_TYPES.BLUE]: PRODUCT_COLORS.CATSKILL_WHITE,
        [PRODUCT_COLOR_TYPES.WHITE]: PRODUCT_COLORS.WARM_WHITE,
    },
    [DEVICE_TYPES.DEV_TYPE_P4_M3_G2]: {
        [PRODUCT_COLOR_TYPES.BRILLIANT_GOLD]: PRODUCT_COLORS.MELLOW_GOLD,
        [PRODUCT_COLOR_TYPES.BRILLIANT_GOLD_IQOS_VEEV]: PRODUCT_COLORS.MELLOW_GOLD,
        [PRODUCT_COLOR_TYPES.BRILLIANT_GOLD_VEEV]: PRODUCT_COLORS.MELLOW_GOLD,
        [PRODUCT_COLOR_TYPES.COPPER_ROSE]: PRODUCT_COLORS.FANTASY,
        [PRODUCT_COLOR_TYPES.COPPER_ROSE_IQOS_VEEV]: PRODUCT_COLORS.FANTASY,
        [PRODUCT_COLOR_TYPES.COPPER_ROSE_VEEV]: PRODUCT_COLORS.FANTASY,
        [PRODUCT_COLOR_TYPES.VELVET_GREY]: PRODUCT_COLORS.CONCRETE,
        [PRODUCT_COLOR_TYPES.VELVET_GREY_IQOS_VEEV]: PRODUCT_COLORS.CONCRETE,
        [PRODUCT_COLOR_TYPES.VELVET_GREY_VEEV]: PRODUCT_COLORS.CONCRETE,
        [PRODUCT_COLOR_TYPES.MARINE_BLUE]: PRODUCT_COLORS.CATSKILL_WHITE,
        [PRODUCT_COLOR_TYPES.MARINE_BLUE_IQOS_VEEV]: PRODUCT_COLORS.CATSKILL_WHITE,
        [PRODUCT_COLOR_TYPES.MARINE_BLUE_VEEV]: PRODUCT_COLORS.CATSKILL_WHITE,
        [PRODUCT_COLOR_TYPES.LUCID_TEAL]: PRODUCT_COLORS.MYSTIC,
        [PRODUCT_COLOR_TYPES.LUCID_TEAL_IQOS_VEEV]: PRODUCT_COLORS.MYSTIC,
        [PRODUCT_COLOR_TYPES.LUCID_TEAL_VEEV]: PRODUCT_COLORS.MYSTIC,
    },
    [DEVICE_TYPES.DEV_TYPE_P1V40V_CHARGER]: {
        [PRODUCT_COLOR_TYPES.OBSIDIAN_BLACK]: PRODUCT_COLORS.IRON,
        [PRODUCT_COLOR_TYPES.JADE_GREEN]: PRODUCT_COLORS.AQUA_HAZE,
        [PRODUCT_COLOR_TYPES.GOLD_KHAKI]: PRODUCT_COLORS.PEARL_BUSH,
        [PRODUCT_COLOR_TYPES.BRONZE_TAUPE]: PRODUCT_COLORS.PAMPAS,
    },
    [DEVICE_TYPES.DEV_TYPE_P1V40C_CHARGER]: {
        [PRODUCT_COLOR_TYPES.MOSS_GREEN]: PRODUCT_COLORS.GREEN_WHITE,
        [PRODUCT_COLOR_TYPES.AZURE_BLUE]: PRODUCT_COLORS.POLAR,
        [PRODUCT_COLOR_TYPES.SUNSET_RED]: PRODUCT_COLORS.SOFT_PEACH,
        [PRODUCT_COLOR_TYPES.PEBBLE_BEIGE]: PRODUCT_COLORS.BEIGE,
        [PRODUCT_COLOR_TYPES.PEBBLE_GREY]: PRODUCT_COLORS.CONCRETE,
    },
    [DEVICE_TYPES.DEV_TYPE_P1V40D_CONTROL]: {
        // NEED TO REWRITE COLORS FOR DELTA
        [PRODUCT_COLOR_TYPES.MOSS_GREEN]: PRODUCT_COLORS.GREEN_WHITE,
        [PRODUCT_COLOR_TYPES.AZURE_BLUE]: PRODUCT_COLORS.POLAR,
        [PRODUCT_COLOR_TYPES.SUNSET_RED]: PRODUCT_COLORS.SOFT_PEACH,
        [PRODUCT_COLOR_TYPES.PEBBLE_BEIGE]: PRODUCT_COLORS.BEIGE,
        [PRODUCT_COLOR_TYPES.PEBBLE_GREY]: PRODUCT_COLORS.CONCRETE,
    },
};

export default PRODUCT_COLORS_MAPPING;
