import {createSelector} from 'reselect';

import {IRootState} from '../state';

const selectMwDevice = (state: IRootState) => state.mwDevice;

const selectMwDeviceInternalId = createSelector(selectMwDevice, (globalState) => globalState.deviceSerialNumber);

const selectMwDeviceIsIdentified = createSelector(selectMwDevice, (globalState) => globalState.isIdentified);

const selectMwDeviceIsDisconnectedWithNoReconnect = createSelector(
    selectMwDevice,
    (globalState) => globalState.isDisconnectedWithNoReconnect
);

const selectMwDeviceFirmwareData = createSelector(selectMwDevice, (globalState) => globalState.firmwareData);

export {
    selectMwDeviceFirmwareData,
    selectMwDeviceInternalId,
    selectMwDeviceIsDisconnectedWithNoReconnect,
    selectMwDeviceIsIdentified,
};
