import cn from 'classnames';
import React, {useState} from 'react';

import {
    FontSizeIcon,
    ToolColorContrastIcon,
    ToolColorGreyscaleIcon,
    ToolColorRegularIcon,
} from '../../../components/Icons';
import domService from '../../../services/domService';
import styles from './AccessibilityTool.module.scss';

const FONT_MEDIUM = 'font-medium';
const FONT_BIG = 'font-big';

const HIGH_CONTRAST = 'high-contrast';
const GREYSCALE = 'greyscale';

const changeHtmlClassName = (classesToDelete, className) => {
    const html = domService.getHtmlElement();

    html.classList.remove(...classesToDelete);
    if (className) html.classList.add(className);
};

const AccessibilityTool = () => {
    const [activeFontSizeIndex, setActiveFontSizeIndex] = useState(0);
    const [activeColorIndex, setActiveColorIndex] = useState(0);
    const activeFontSizeClassName = cn(styles.ToolButton, styles.TextSizeButtonActive);
    const activeColorClassName = cn(styles.ToolButton, styles.ColorButtonActive);

    //TODO: localize\delete\leave alt titles?
    const fontSizeButtons = [
        {
            title: 'Normal font size',
            className: undefined,
        },
        {
            title: 'Medium font size',
            className: FONT_MEDIUM,
        },
        {
            title: 'Big font size',
            className: FONT_BIG,
        },
    ];

    const colorButtons = [
        {
            title: 'Normal contrast',
            className: undefined,
            icon: <ToolColorRegularIcon />,
        },
        {
            title: 'High contrast',
            className: HIGH_CONTRAST,
            icon: <ToolColorContrastIcon />,
        },
        {
            title: 'Greyscale',
            className: GREYSCALE,
            icon: <ToolColorGreyscaleIcon />,
        },
    ];

    const changeHtmlFontClassName = (index, className) => {
        const isActive = activeFontSizeIndex === index;

        changeHtmlClassName([FONT_MEDIUM, FONT_BIG], isActive ? undefined : className);

        setActiveFontSizeIndex(isActive ? 0 : index);
    };

    const changeHtmlContrastClassName = (index, className) => {
        const isActive = activeColorIndex === index;

        changeHtmlClassName([HIGH_CONTRAST, GREYSCALE], isActive ? undefined : className);

        setActiveColorIndex(isActive ? 0 : index);
    };

    return (
        <div className={styles.Wrapper}>
            <ul className={styles.FontSizeList}>
                {fontSizeButtons.map((fontSizeButton, i) => {
                    const className = i === activeFontSizeIndex ? activeFontSizeClassName : styles.ToolButton;

                    return (
                        <li key={i}>
                            <button
                                title={fontSizeButton.title}
                                onClick={() => changeHtmlFontClassName(i, fontSizeButton.className)}
                                className={className}
                            >
                                <FontSizeIcon />
                            </button>
                        </li>
                    );
                })}
            </ul>

            <ul>
                {colorButtons.map((colorButton, i) => {
                    const className = i === activeColorIndex ? activeColorClassName : styles.ToolButton;

                    return (
                        <li key={i}>
                            <button
                                title={colorButton.title}
                                onClick={() => changeHtmlContrastClassName(i, colorButton.className)}
                                className={className}
                            >
                                {colorButton.icon}
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default AccessibilityTool;
