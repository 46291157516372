import {DeviceSupport, Personalization} from '../../../components/Icons';
import appConfig from '../../../config/appConfig';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {IData, IMergedData, IText} from './IconTextTypes';

const getTextIconData = (): IData => {
    const localizedStrings = getLocalizedStrings();

    const data = {
        customData: [
            {
                text: localizedStrings[localizationKeys.QUIT_SET_UP_EXTRA_FEATURES_DESCRIPTION_TEXT],
            },
            {
                text: localizedStrings[localizationKeys.QUIT_SET_UP_TROUBLESHOOT_DESCRIPTION_TEXT],
            },
            {
                text: localizedStrings[localizationKeys.QUIT_SET_UP_TRACK_USAGE_DESCRIPTION_TEXT],
            },
            {
                text: localizedStrings[localizationKeys.QUIT_SET_UP_PERSONAL_GOALS_DESCRIPTION_TEXT],
            },
        ],
        defaultData: [
            {
                text: localizedStrings[localizationKeys.QUIT_SET_UP_EXTRA_FEATURES_DESCRIPTION_TEXT],
                svgIcon: Personalization,
            },
            {
                text: localizedStrings[localizationKeys.QUIT_SET_UP_TROUBLESHOOT_DESCRIPTION_TEXT],
                svgIcon: DeviceSupport,
            },
        ],
    };

    return data;
};

const fetchMergedData = (): IMergedData[] | null => {
    const textData = getTextIconData().customData;
    const iconPathArr = appConfig.getSkipPageSections();
    const data = getMergedData(iconPathArr, textData);

    return data;
};

const getMergedData = (iconPathArr: string[] | undefined, textData: IText[]): IMergedData[] | null => {
    const data = [];

    if (!iconPathArr) {
        return null;
    }

    for (let index = 0; index < iconPathArr.length; index++) {
        const path = iconPathArr[index];
        const text = textData[index];

        if (path && text) {
            data.push({iconPath: path, ...text});
        }
    }

    return data;
};

const getIconTextRelatedData = (isCustomData: boolean): IMergedData[] | null => {
    const data = isCustomData ? fetchMergedData() : getTextIconData().defaultData;

    return data;
};

export {getIconTextRelatedData};
