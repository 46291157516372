export const SET_TNC_POPUP_CODE = 'app/tnc/SET_TNC_POPUP_CODE';
export const REMOVE_LAST_TNC_POPUP_CODE = 'app/tnc/REMOVE_LAST_TNC_POPUP_CODE';

const initialData = {
    tncPopupCodesList: [],
};

export default function reducer(state = initialData, action) {
    switch (action.type) {
        case SET_TNC_POPUP_CODE: {
            const code = action.payload;
            const {tncPopupCodesList} = state;

            return {
                ...state,
                tncPopupCodesList: [...tncPopupCodesList, code],
            };
        }
        case REMOVE_LAST_TNC_POPUP_CODE: {
            const {tncPopupCodesList} = state;
            const newTncPopupCodesList = tncPopupCodesList.slice(0, -1);

            return {
                ...state,
                tncPopupCodesList: newTncPopupCodesList,
            };
        }
        default:
            return state;
    }
}

export const setTncPopupCode = (data) => ({
    type: SET_TNC_POPUP_CODE,
    payload: data,
});
export const removeLastTncPopupCode = () => ({
    type: REMOVE_LAST_TNC_POPUP_CODE,
});
