import React from 'react';

import Icon from './Icon';

const IconCrescendo = (props) => {
    return (
        <Icon width='23' height='24' viewBox='0 0 36 38' {...props}>
            <g fill='none' fillRule='evenodd' strokeLinecap='round'>
                <g fillRule='nonzero' strokeWidth='1.5'>
                    <path
                        d='M0 19.906c3.634 0 7.087-5.02 10.13-9.47 1.455-2.131 2.863-4.12 4.135-5.398 2.499-2.51 4.588-4.073 7.814-4.783 3.453-.757 6.769.19 8.768 2.605 1.817 2.13 2.635 5.35 1.863 7.481-.455 1.231-1.2 2.074-2.244 2.633'
                        transform='translate(-219 -511) translate(72 123) translate(127 370) translate(21 19) translate(.5 .531)'
                    />
                    <path
                        d='M0 29.094c2.808 0 5.476-3.475 7.828-6.557 1.124-1.475 2.212-2.852 3.195-3.737 1.93-1.737 3.546-2.82 6.038-3.31 2.668-.525 5.23.13 6.775 1.802 1.404 1.475 2.036 3.705 1.44 5.18-.351.852-.927 1.435-1.734 1.822'
                        transform='translate(-219 -511) translate(72 123) translate(127 370) translate(21 19) translate(.5 .531)'
                    />
                    <path
                        d='M6 33.688c1.487 0 2.9-1.931 4.144-3.643.595-.82 1.171-1.584 1.692-2.076 1.022-.965 1.877-1.566 3.196-1.84 1.413-.291 2.77.073 3.587 1.002.744.82 1.078 2.058.762 2.878-.186.473-.49.797-.918 1.012'
                        transform='translate(-219 -511) translate(72 123) translate(127 370) translate(21 19) translate(.5 .531)'
                    />
                </g>
            </g>
        </Icon>
    );
};

export default IconCrescendo;
