import React from 'react';

import Icon from './Icon';

const IconPairing = (props) => {
    return (
        <Icon width='90' height='91' viewBox='0 0 90 91' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g stroke='#272A33' strokeWidth='3'>
                    <g>
                        <path
                            d='M46.172 41.146v13.026c0 3.313-2.687 6-6 6h-22c-9.942 0-18-8.06-18-18v-22c0-3.314 2.686-6 6-6h22c9.94 0 18 8.058 18 18'
                            transform='translate(-141 -120) rotate(-45 253.703 -79.663)'
                        />
                        <path
                            d='M18.232 52.366L18.05 38.05c0-3.313 2.687-6 6-6h22c9.941 0 18 8.06 18 18v22c0 3.314-2.686 6-6 6h-22c-9.94 0-18-8.059-18-18'
                            transform='translate(-141 -120) rotate(-45 253.703 -79.663)'
                        />
                    </g>
                </g>
            </g>
        </Icon>
    );
};

export default IconPairing;
