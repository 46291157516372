import ModalTypes from '../enums/app/modalTypes';
import {hideModalAction} from '../state/ducks/modal';
import {makeSelectVisibleModal} from '../state/selectors/modal';
import {dispatch, getState} from '../state/store';

const hideModal = (modalType: ModalTypes): void => {
    const modal = makeSelectVisibleModal(getState(), modalType);

    if (modal) {
        dispatch(hideModalAction(modalType));
    }
};

export default {hideModal};
