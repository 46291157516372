export const CONSUMER_PRODUCTS = '/consumer/consumerProductsAsync';
export const CONSUMER_PRODUCTS_V2 = '/consumer/v2/consumerProductsAsync';
export const CONSUMER_PRODUCTS_V3 = '/consumer/v3/consumerProductsAsync';
export const GET_ACCEPTED_TNC = '/consumer/acceptedConsumerTermAndConditions/grouped';
export const GET_CONSUMER = '/consumer/consumerProfile';
export const GET_EXTERNAL_CONSUMER = '/consumer/ExternalConsumerProfile';
export const GET_CONSUMER_ACTIVATION_STATUS = '/consumer/ProductActivation';
export const GET_CONSUMER_AGGREGATED_SETTINGS = '/consumer/consumerAggregatedSettings';
export const GET_CONSUMER_PRODUCTS_STATUS = '/consumer/ConsumerProductsStatus';
export const GET_MEDIA_URL = '/media/media/SignMediaUrlList';
export const GET_MEDIA_URL_V2 = '/media/media/v2/SignMediaUrlList';
export const GET_PRODUCT_INFO_LIST = '/consumer/v3/ProductInfoList';
export const GET_TNC = '/consumer/consumerTermAndConditions/grouped';
export const GET_TOKEN = '/auth/connect/token';
export const SET_CONSUMER_PRODUCT_ACTIVATED = '/consumer/ProductActivation';
export const SET_CONSUMER_PRODUCT_NAME = '/consumer/ConsumerProductsName';
export const SET_CONSUMER_TNC = '/consumer/consumerTermAndConditions';
export const GET_UAM_TOKEN = '/consumer/UamToken';
export const GET_UAM_TOKEN_V2 = '/consumer/v2/UamToken';
export const GET_CONSUMER_LOGIN_TOKEN = '/consumer/CreateLoginToken';
