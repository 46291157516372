import * as aggregatedSettingsTypes from '../../consts/tnc/aggregatedSettingTypes';
import {makeSelectAggregatedSettings} from '../../state/selectors/consumer';
import {getState} from '../../state/store';

const getConsumerAggregatedSettings = () => makeSelectAggregatedSettings()(getState())?.consumerAggregatedSettings;

const getSettingValueByKey = (key) => getConsumerAggregatedSettings()?.find((item) => item.key === key)?.value;

const isEnabledGA = () => getSettingValueByKey(aggregatedSettingsTypes.ENABLE_GA);

const isEnabledDataCollection = () => {
    const dataCollectionList = [
        aggregatedSettingsTypes.ENABLE_BLE_ERROR_DATA_COLLECTION,
        aggregatedSettingsTypes.ENABLE_BLE_ERROR_LOG_DATA_COLLECTION,
        aggregatedSettingsTypes.ENABLE_BLE_EXPERIENCE_DATA_COLLECTION,
        aggregatedSettingsTypes.ENABLE_BLE_LIFE_DATA_DATA_COLLECTION,
        aggregatedSettingsTypes.ENABLE_BLE_PUFF_DATA_DATA_COLLECTION,
        aggregatedSettingsTypes.NON_ANONYMIZE_DATA,
    ];

    return getConsumerAggregatedSettings()?.find((item) => dataCollectionList.includes(item.key) && item.value);
};

export default {
    isEnabledGA,
    isEnabledDataCollection,
};
