import React from 'react';

import Icon from './Icon';

const IconVivace = (props) => {
    return (
        <Icon width='20' height='20' viewBox='0 0 36 38' {...props}>
            <g fill='none' fillRule='evenodd' strokeLinecap='round'>
                <g strokeWidth='1.5'>
                    <path
                        d='M2.3 8.663l18.188-.188'
                        transform='translate(-219 -347) translate(72 123) translate(127 206) translate(21 19.75) translate(.5 .531) rotate(-45 11.394 8.57)'
                    />
                    <path
                        d='M11.756 25.94l15.156-.157'
                        transform='translate(-219 -347) translate(72 123) translate(127 206) translate(21 19.75) translate(.5 .531) rotate(-45 19.334 25.861)'
                    />
                    <path
                        d='M-6.294 17.017l45.469-.469'
                        transform='translate(-219 -347) translate(72 123) translate(127 206) translate(21 19.75) translate(.5 .531) rotate(-45 16.44 16.783)'
                    />
                </g>
            </g>
        </Icon>
    );
};

export default IconVivace;
