import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import * as localizationKeys from '../../../consts/localization/localizationKeys';
import ModalTypes from '../../../enums/app/modalTypes';
import useDidUpdate from '../../../hooks/useDidUpdate';
import appErrorService from '../../../services/app/appErrorService';
import iotDeviceConnectionStatusService from '../../../services/iotDevice/iotDeviceConnectionStatusService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import modalService from '../../../services/modalService';
import {setNotificationSuccess} from '../../../state/ducks/global';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../../state/selectors/consumer';
import {makeSelectIsReconnectGuideEnabled} from '../../../state/selectors/global';
import {makeSelectDeviceConnectionStatus} from '../../../state/selectors/iotDevice';
import {dispatch} from '../../../state/store';

const mapStateToProps = createStructuredSelector({
    iotProduct: makeSelectIotDeviceMergedWithIccProduct(),
    connectionStatus: makeSelectDeviceConnectionStatus(),
    isReconnectGuideEnabled: makeSelectIsReconnectGuideEnabled(),
});

const DeviceConnectionDetector = (props) => {
    const {iotProduct, connectionStatus, isReconnectGuideEnabled} = props;
    const [isDevicePairedBefore, setIsDevicePairedBefore] = useState(null);
    const isDeviceReady = iotDeviceConnectionStatusService.isDeviceReady(connectionStatus);
    const isDeviceDisconnectedWithNoReconnect = iotDeviceConnectionStatusService.isDeviceDisconnected(connectionStatus);

    useEffect(() => {
        if (isDeviceDisconnectedWithNoReconnect) {
            setIsDevicePairedBefore(false);
            modalService.hideModal(ModalTypes.RECONNECT_DEVICE_GUIDE);
        }
    }, [isDeviceDisconnectedWithNoReconnect]);

    const onConnectionStatusChange = () => {
        const localizedStrings = getLocalizedStrings();
        const isDevicePaired = iotDeviceConnectionStatusService.isDevicePaired(connectionStatus);
        const isDeviceConnected = iotDeviceConnectionStatusService.isDeviceConnected(connectionStatus);
        const {device} = iotProduct || {};
        const {name = ''} = device || {};

        const showSuccessReconnectNotification = () => {
            const connectionNotificationText = localizedStrings.formatString(
                localizedStrings[localizationKeys.DEVICE_RECONNECT_NOTIFICATION],
                name
            );

            dispatch(setNotificationSuccess(connectionNotificationText));
        };

        if (isDevicePaired || isDeviceConnected) {
            setIsDevicePairedBefore(true);
        }
        if (isDeviceDisconnectedWithNoReconnect) {
            setIsDevicePairedBefore(false);
            return;
        }
        if (isDevicePairedBefore) {
            if (isDeviceReady) {
                showSuccessReconnectNotification();
                modalService.hideModal(ModalTypes.RECONNECT_DEVICE_GUIDE);
            } else {
                appErrorService.showDeviceDisconnectNotification();
            }
        }
    };

    useDidUpdate(() => {
        if (isReconnectGuideEnabled) {
            onConnectionStatusChange();
        }
    }, [isDeviceReady, isDevicePairedBefore]);

    return null;
};

export default connect(mapStateToProps)(DeviceConnectionDetector);
