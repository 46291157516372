import React from 'react';

import Icon from './Icon';

const IconDarkDataCollection = (props) => {
    return (
        <Icon width='48' height='63' viewBox='0 0 48 63' {...props}>
            <g fill='none' fillRule='evenodd' stroke='#FFF' strokeWidth='2' opacity='.4'>
                <path d='M24.158 61.204l.418-.122A31.15 31.15 0 0 0 47 31.18V10.078a3 3 0 0 0-2.166-2.882L24.802 1.398a3 3 0 0 0-1.67 0L3.163 7.195A3 3 0 0 0 1 10.076V31.18A31.03 31.03 0 0 0 23.444 61l.714.205z' />
                <g strokeLinecap='round'>
                    <path d='M23.872 21v19.866M33.432 27.865v12.713M14.312 33.385v7.201' />
                </g>
            </g>
        </Icon>
    );
};

export default IconDarkDataCollection;
