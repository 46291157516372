import appConfig from '../../config/appConfig';
import * as logMessageTypes from '../../consts/app/logMessageTypes';
import {setYapDeviceConnected, setYapHolderConnected} from '../../state/ducks/yapEncrypted';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../state/selectors/consumer';
import {makeSelectIsHolderByAssetId} from '../../state/selectors/yapEncrypted';
import {makeSelectIsYapDeviceConnected, makeSelectIsYapHolderConnected} from '../../state/selectors/yapEncrypted';
import {dispatch, getState} from '../../state/store';
import helpers from '../../utils/helpers';
import analyticsService from '../analyticsService';
import backendService from '../app/backendService';
import IqosServiceClient from '../device/iqosServiceClient';
import gamClientService from '../gam/gamClientService';
import log from '../logger/log';
import uamClientService from '../uam/uamClientService';
import cmMessageRequestService from './cmMessageRequestService';
import cmMessageResponseService from './cmMessageResponseService';
import DeviceCmClient from './DeviceCmClient';
import HolderCmClient from './HolderCmClient';

let cmDeviceReconnectTimeout;
let cmHolderReconnectTimeout;
const clearReconnectTimeout = (isHolder) =>
    clearTimeout(isHolder ? cmHolderReconnectTimeout : cmDeviceReconnectTimeout);

const getCmClient = (isHolder) => (assetId, createNew, options) => {
    try {
        const Client = isHolder ? HolderCmClient : DeviceCmClient;

        return new Client(assetId, createNew, options);
    } catch (e) {
        return null;
    }
};

const initCmClient = async (assetId, isHolder) => {
    clearReconnectTimeout(isHolder);

    if (isCmClientConnected(assetId)) {
        const isYapDeviceConnected = isHolder
            ? makeSelectIsYapHolderConnected()(getState())
            : makeSelectIsYapDeviceConnected()(getState());

        if (!isYapDeviceConnected) {
            cmMessageRequestService.publishInitializationEvent(isHolder);
        }

        return;
    }

    log.info('Connect to CM is started.');

    const onError = () => {
        const RECONNECT_TIMEOUT_MS = 5000;

        disconnectCmClient(!!isHolder);

        const timeout = setTimeout(() => initCmClient(assetId, isHolder), RECONNECT_TIMEOUT_MS);

        if (isHolder) {
            cmHolderReconnectTimeout = timeout;
        } else {
            cmDeviceReconnectTimeout = timeout;
        }
    };

    const isGamBackend = backendService.isGamBackend();
    const type = getDeviceType();
    let cmSettings;

    if (isGamBackend) {
        analyticsService.pushConnectIotPlatformEvent(type);
        cmSettings = await gamClientService.connectGamDevice(assetId);
    } else {
        analyticsService.pushConnectIotPlatformEvent(type);
        cmSettings = await uamClientService.connectDevice(assetId);
    }

    if (cmSettings) {
        const iqosService = new IqosServiceClient();
        const isDeviceConnected = iqosService.isDeviceConnected();

        if (!isDeviceConnected) return;

        const cmClient = getCmClient(isHolder)(assetId, true, cmSettings);

        if (!cmClient) return;

        cmClient.attachCloseHandler(async (e) => {
            if (isHolder) {
                dispatch(setYapHolderConnected(false));
            } else {
                dispatch(setYapDeviceConnected(false));
            }

            switch (e.errorCode) {
                case 0:
                    //forced close from code
                    break;
                case 5:
                    //unhandled error from paho. ignored
                    break;
                default:
                    log.info(`CmClient: Try reconnect on ErrorCode: ${e.errorCode}`);
                    onError();
                    break;
            }
        });

        cmClient.attachConnectHandler(() => {
            subscribeOnMessage(cmSettings.topicSubscribe, isHolder);
        });

        cmClient.connect();
    }
};

const disconnectCmClient = (isHolder) => {
    let cmClient;

    const disconnectClient = (isHolder) => {
        cmClient = getCmClient(isHolder)();
        clearReconnectTimeout(isHolder);
        if (cmClient) {
            cmClient.disconnect();
        }
    };

    if (isHolder === undefined) {
        disconnectClient(false);
        disconnectClient(true);
    } else {
        disconnectClient(isHolder);
    }
};

const subscribeOnMessage = (topicSubscribe, isHolder) => {
    const cmClient = getCmClient(isHolder)();

    if (!cmClient) return;

    cmClient.subscribe(topicSubscribe);

    cmClient.attachMessageHandler((message) => {
        log.info(`CmClient: new message: ${message.payloadString}`);
        const mes = JSON.parse(message.payloadString);

        cmMessageResponseService.onCmMessage(mes, isHolder);
    });

    cmMessageRequestService.publishInitializationEvent(isHolder);
};

const publishMessage = (message, isHolder) => {
    const cmClient = getCmClient(isHolder)();

    if (!cmClient) return;

    cmClient.publish(JSON.stringify(message));
};

const isCmClientConnected = (assetId) => {
    const isHolder = makeSelectIsHolderByAssetId(assetId)(getState());
    const cmClient = getCmClient(isHolder)();

    if (!cmClient) return false;

    return cmClient.isConnected(assetId);
};

const waitUntilConnected = async (assetId, timer = appConfig.getCmTimeout()) => {
    const isHolder = makeSelectIsHolderByAssetId(assetId)(getState());

    const isYapDeviceConnected = isHolder
        ? makeSelectIsYapHolderConnected()(getState())
        : makeSelectIsYapDeviceConnected()(getState());

    if (isYapDeviceConnected) return true;

    const cmClient = getCmClient(isHolder)();

    if (!cmClient) return false;

    if (--timer < 0) {
        const type = getDeviceType();

        analyticsService.pushConnectIotPlatformErrorEvent(type);
        log.error('CM Client. CONNECTED status does not arrive from yap websocket.', logMessageTypes.IOT);
        return false;
    }

    await helpers.timeout(1000);
    return await waitUntilConnected(assetId, timer);
};

const getDeviceType = () => {
    const iotProduct = makeSelectIotDeviceMergedWithIccProduct()(getState());

    return iotProduct?.device?.type;
};

export default {
    initCmClient,
    publishMessage,
    disconnectCmClient,
    waitUntilConnected,
    isCmClientConnected,
};
