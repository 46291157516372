import appConfig from '../config/appConfig';
import {ENABLE_FW_UPDATES_CHECK} from '../consts/tnc/aggregatedSettingTypes';
import fwuService from './ble/fwuService';
import tncService from './tncService';

const getIsGetFeatureAllowed = (type?: string): boolean => {
    const isGetFeatureAllowed = !!(
        appConfig.getIsGetFeatureEnabled() &&
        fwuService.isFWUAvailable(type) &&
        tncService.isAggregatedSettingEnabled(ENABLE_FW_UPDATES_CHECK)
    );

    return isGetFeatureAllowed;
};

export default {
    getIsGetFeatureAllowed,
};
