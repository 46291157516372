import pairingErrors from '../consts/device/pairingErrors';
import {IO_ERROR_IO_GENERAL} from '../consts/iot/iotMessageErrorCodes';
import * as iotMessageErrorCodes from '../consts/iot/iotMessageErrorCodes';
import {makeSelectIotDeviceMergedWithIccProduct} from '../state/selectors/consumer';
import {
    makeSelectIsDeviceConnected,
    makeSelectIsDeviceReady,
    makeSelectIsWrongDevice,
    selectIsFirmwareInProgress,
} from '../state/selectors/iotDevice';
import {makeSelectIsYapDeviceInfoReceived, makeSelectIsYapHolderInfoReceived} from '../state/selectors/yapEncrypted';
import {getState} from '../state/store';
import helpers from '../utils/helpers';
import appErrorService from './app/appErrorService';
import backendService from './app/backendService';
import mwIotMessageRequestService from './scpCloud/mwIotMessageRequestService';

let instance = null;

export default class ErrorHandlerService {
    constructor() {
        if (instance) {
            return instance;
        }

        instance = this;
        this.errorHandler = null;
        this.pairingErrorHandler = null;
        this.iotErrorCodes = [];
        this.type = null;
    }

    setErrorHandler({iotErrorCodes, handler, type}) {
        this.errorHandler = handler;
        this.iotErrorCodes = iotErrorCodes;
        this.type = type;
    }

    setPairingErrorHandler({handler}) {
        this.pairingErrorHandler = handler;
    }

    removeErrorHandler() {
        this.errorHandler = null;
        this.pairingErrorHandler = null;
        this.iotErrorCodes = [];
        this.type = null;
    }

    raiseError(errorCode) {
        helpers.runFunction(this.errorHandler, errorCode);
    }

    handleError(iotMessage) {
        const {type, body} = iotMessage;
        const error_code = body?.error?.code;

        if (type === this.type || this.iotErrorCodes.includes(error_code)) {
            helpers.runFunction(this.errorHandler, error_code);
        } else {
            this.onUnhandledError(error_code);
        }
    }

    onUnhandledError = (errorCode) => {
        if (errorCode === IO_ERROR_IO_GENERAL) {
            appErrorService.showIoErrorWithAppReset();
        } else {
            const isFwuError = errorCode === iotMessageErrorCodes.FW_START_NOT_FOUND_DATA;
            const skipDeviceNotInSblModeError = errorCode === iotMessageErrorCodes.FW_DEVICE_NOT_IN_SBL_ERROR;
            const alarmIsNotSupportedWithoutHolder =
                errorCode === iotMessageErrorCodes.ALARM_IS_NOT_SUPPORTED_BY_DEVICE;
            const skipError = isFwuError || skipDeviceNotInSblModeError || alarmIsNotSupportedWithoutHolder;

            if (!skipError) {
                appErrorService.showGlobalErrorWithAppReset();
                return;
            }

            if (skipDeviceNotInSblModeError) {
                // publish initialization event, so DEVICE_INFO flow will be initialized from start
                // it is not finished in case of FW_DEVICE_NOT_IN_SBL_ERROR error
                mwIotMessageRequestService.publishInitializationEvent();
            }
        }
    };

    checkGamError() {
        const state = getState();
        const isDeviceReady = makeSelectIsDeviceReady()(state);

        if (isDeviceReady) return;

        const isWrongDevice = makeSelectIsWrongDevice()(state);
        const code = isWrongDevice ? pairingErrors.ERROR_WRONG_DEVICE : pairingErrors.ERROR_PAIRING;

        if (typeof this.pairingErrorHandler === 'function') {
            helpers.runFunction(this.pairingErrorHandler, code);
        } else {
            appErrorService.showIoErrorWithAppReset();
        }
    }

    checkUamError(isHolder) {
        const state = getState();
        const isDeviceReady = makeSelectIsDeviceReady()(state);
        const isYapDeviceInfoReceived = makeSelectIsYapDeviceInfoReceived()(state);
        const isYapHolderInfoReceived = makeSelectIsYapHolderInfoReceived()(state);
        const isInfoReceived = isHolder ? isYapHolderInfoReceived : isYapDeviceInfoReceived;
        const iotProduct = makeSelectIotDeviceMergedWithIccProduct()(state);
        const {holder, device} = iotProduct || {};
        const {isHolderConnected} = device || {};
        const {deviceSerialNumber} = holder || {};

        if ((isDeviceReady || (isHolderConnected && deviceSerialNumber)) && isInfoReceived) return;

        if (typeof this.pairingErrorHandler === 'function') {
            const isWrongDevice = makeSelectIsWrongDevice()(state);
            const code = isWrongDevice ? pairingErrors.ERROR_WRONG_DEVICE : pairingErrors.ERROR_PAIRING;

            helpers.runFunction(this.pairingErrorHandler, code);
        } else {
            const isScpCloudBackend = backendService.isScpCloudBackend();

            if (!isScpCloudBackend) {
                appErrorService.showIoErrorWithAppReset();
            }
        }
    }

    checkScpCloudError() {
        const state = getState();
        const isDeviceReady = makeSelectIsDeviceReady()(state);
        const isFirmwareInProgress = selectIsFirmwareInProgress(state);
        const isDeviceConnected = makeSelectIsDeviceConnected(state);

        if (isDeviceReady || (isFirmwareInProgress && isDeviceConnected)) return;

        if (typeof this.pairingErrorHandler === 'function') {
            helpers.runFunction(this.pairingErrorHandler, pairingErrors.ERROR_PAIRING);
        } else {
            appErrorService.showIoErrorWithAppReset();
        }
    }
}
