import React from 'react';
import {Redirect} from 'react-router-dom';

import * as routePaths from '../../consts/route/routePaths';

const Root = () => {
    return <Redirect to={routePaths.BROWSER_VERIFICATION} />;
};

export default Root;
