import backendService from '../app/backendService';
import gamMessageRequestService from '../gam/gamMessageRequestService';
import uiIotMessageRequestService from '../scpCloud/uiIotMessageRequestService';
import uamMessageRequestService from '../uam/uamMessageRequestService';

const client = (isSettingPublish) => {
    if (backendService.isGamBackend()) {
        return gamMessageRequestService;
    } else if (isSettingPublish && backendService.isUamBackend()) {
        return uamMessageRequestService;
    } else {
        return uiIotMessageRequestService;
    }
};

export default client;
