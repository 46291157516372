import {createSelector} from 'reselect';

const selectGlobal = (state) => state.global;

const makeIsAppLoading = () => createSelector(selectGlobal, (globalState) => globalState.isAppLoading);

const makeLoaders = () => createSelector(selectGlobal, (globalState) => globalState.loaders);

const makeSelectIsLoaderVisible = (loaderName) =>
    createSelector(makeLoaders(), (loaders) => {
        const loader = loaders?.filter(({name}) => name === loaderName);

        return loader?.length;
    });

const makeNotifications = () => createSelector(selectGlobal, (globalState) => globalState.notifications);

const makeSelectIsHolderRegisterFailed = () =>
    createSelector(selectGlobal, (globalState) => globalState.isHolderRegisterFailed);

const makeSelectIsDoubleRegistration = () =>
    createSelector(selectGlobal, (globalState) => globalState.isDoubleRegistration);

const makeSelectIsSkipRegAndSyncFlow = () =>
    createSelector(selectGlobal, (globalState) => globalState.isSkipRegAndSyncFlow);

const makeSelectLayoutType = () => createSelector(selectGlobal, (globalState) => globalState.layout);

const makeSelectIsNetworkOnline = () => createSelector(selectGlobal, (globalState) => globalState.isNetworkOnline);

const makeSelectIsReconnectGuideEnabled = () =>
    createSelector(selectGlobal, (globalState) => globalState.isReconnectGuideEnabled);

const makeSelectIsHolderConnectEnabled = () =>
    createSelector(selectGlobal, (globalState) => globalState.isHolderConnectEnabled);

const makeSelectIsBluetoothAvailable = () =>
    createSelector(selectGlobal, (globalState) => globalState.isBluetoothAvailable);

const makeSelectIsScpHolderInfoCheckInProgress = () =>
    createSelector(selectGlobal, (globalState) => globalState.isScpHolderInfoCheckInProgress);

export {
    makeIsAppLoading,
    makeLoaders,
    makeNotifications,
    makeSelectIsBluetoothAvailable,
    makeSelectIsHolderConnectEnabled,
    makeSelectIsDoubleRegistration,
    makeSelectIsHolderRegisterFailed,
    makeSelectIsLoaderVisible,
    makeSelectIsNetworkOnline,
    makeSelectIsReconnectGuideEnabled,
    makeSelectIsScpHolderInfoCheckInProgress,
    makeSelectIsSkipRegAndSyncFlow,
    makeSelectLayoutType,
};
