import helpers from '../../utils/helpers';
import log from '../logger/log';

interface IFramesQueueItem {
    frames: string[];
    onSuccess: () => void;
    onError: () => void;
    timeout: number;
    skipErrorOnTimeout?: boolean;
}

interface IFramesQueueParams extends IFramesQueueItem {
    processImmediately: boolean;
}

export interface IStateBase {
    currentFrame?: string;
    framesToResponse: string[];
    onError?: () => void;
    onSuccess?: (responses: string[]) => void;
    responseFrameHex: string;
    responses: string[];
    retryCount: number;
    timeout: number;
    skipErrorOnTimeout?: boolean;
    wrongHeaderRetryCount: number;
}

export default abstract class CommunicatorClientBase<T, K extends IStateBase> {
    protected isActive = false;
    protected framesQueue: IFramesQueueItem[] = [];
    protected responseTimeout?: ReturnType<typeof setTimeout>;
    protected iqosClient!: T;
    protected state: K = this.getInitiaState();

    abstract getInitiaState(): K;
    abstract writeFrames(): void;
    abstract resetResponseState(): void;
    abstract writeFrame: (frame: string) => void;

    addFramesToQueue({
        frames,
        onSuccess,
        onError,
        processImmediately = true,
        timeout,
        skipErrorOnTimeout,
    }: IFramesQueueParams): void {
        log.debug(`CommunicatorClientBase: add frames to queue, frames: ${JSON.stringify(frames)}`);

        this.framesQueue.push({frames, onSuccess, onError, timeout, skipErrorOnTimeout});

        if (processImmediately) {
            this.processQueue();
        }
    }

    restartListeners = (): void => undefined;

    protected resetState(): void {
        this.state = this.getInitiaState();
    }

    protected processQueue = (): void => {
        if (!this.state.framesToResponse.length) {
            this.processFrames();
        }
    };

    protected processFrames = (): void => {
        this.resetState();

        if (this.framesQueue.length) {
            const {frames, onSuccess, onError, timeout, skipErrorOnTimeout} = this.framesQueue.shift()!;

            log.debug(`CommunicatorClientBase: process frames: ${JSON.stringify(frames)}`);

            this.state.framesToResponse = frames;
            this.state.onSuccess = onSuccess;
            this.state.onError = onError;
            this.state.timeout = timeout;
            this.state.skipErrorOnTimeout = skipErrorOnTimeout;
            this.writeFrames();
        }
    };

    protected onFrameResponseError = (): void => {
        const {onError} = this.state;

        this.clearResponseTimeout();
        helpers.runFunction(onError);
        this.processFrames();
    };

    protected clearResponseTimeout(): void {
        clearTimeout(this.responseTimeout!);
    }

    protected onFramesResponse(): void {
        const {responses, onSuccess} = this.state;

        if (onSuccess) {
            const filteredResponses = responses.filter((response) => response !== '');

            onSuccess(filteredResponses);
        }

        this.processFrames();
    }

    protected clearQueue(): void {
        helpers.runFunction(this.state.onError, true);
        this.resetState();

        this.framesQueue.forEach((frameQueue) => {
            const {onError} = frameQueue;

            helpers.runFunction(onError, true);
        });
        this.framesQueue = [];
    }
}
