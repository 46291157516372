import * as cleaningSupportCodes from '../../consts/iot/cleaningSupportCodes';

const isHolderLedModeEnabled = (iotDevice) => getFeaturesConfig(iotDevice).holder_led_intensity_mode;

const isDeviceLedModeEnabled = (iotDevice) => getFeaturesConfig(iotDevice).device_led_intensity_mode;

const isVibrationEnabled = (iotDevice) => {
    const _isHolderChargedVibrationEnabled = isHolderChargedVibrationEnabled(iotDevice);
    const _isExtendedHapticProfileVibrationEnabled = isExtendedHapticProfileVibrationEnabled(iotDevice);
    const _isProgrammableVibrationSettingsEnabled = isProgrammableVibrationSettingsEnabled(iotDevice);
    const _isStartVibrationEnabled = isStartVibrationEnabled(iotDevice);

    return (
        _isHolderChargedVibrationEnabled ||
        _isExtendedHapticProfileVibrationEnabled ||
        _isProgrammableVibrationSettingsEnabled ||
        _isStartVibrationEnabled
    );
};

const isCommonVibrationEnabled = (iotDevice) => {
    const {setting_supported, common_vibration_switcher} = getFeaturesConfig(iotDevice).programmable_vibrations || {};

    return setting_supported && common_vibration_switcher;
};

const isCleaningEnabled = (iotDevice) => {
    const {holder} = iotDevice;
    const is_holder_manageable = holder ? holder.is_holder_manageable : true;

    return is_holder_manageable && !!getFeaturesConfig(iotDevice).device_self_cleaning;
};

const isHolderChargedVibrationEnabled = (iotDevice) => getFeaturesConfig(iotDevice).device_holder_charged_vibration;

const isExtendedHapticProfileVibrationEnabled = (iotDevice) =>
    getFeaturesConfig(iotDevice).extended_haptic_profile_vibration;

const isStartVibrationEnabled = (iotDevice) => getFeaturesConfig(iotDevice).start_charging_vibration;

const isProgrammableVibrationSettingsEnabled = (iotDevice) =>
    getFeaturesConfig(iotDevice).programmable_vibrations?.setting_supported;

const isDeviceAlarmEnabled = (iotDevice) => getFeaturesConfig(iotDevice).device_alarm;

const MILLISECONDS_IN_SECOND = 1000;

const getDeviceAlarmDuration = (iotDevice) =>
    getFeaturesConfig(iotDevice).device_alarm_duration * MILLISECONDS_IN_SECOND;

const isChargingWhileCleaningNeeded = (iotDevice) => {
    return getFeaturesConfig(iotDevice).device_self_cleaning === cleaningSupportCodes.AVAILABLE_WHILE_CHARGING;
};

const isParentProtectionMode = (iotDevice) => getFeaturesConfig(iotDevice).device_parent_protection_mode;

const isDiagnosticModeEnabled = (iotDevice) => getFeaturesConfig(iotDevice).connect_care_mode;

const isResponsiveDrawModeEnabled = (iotDevice) => getFeaturesConfig(iotDevice).device_responsive_draw_profile_mode;

const isVapeCloudModeEnabled = (iotDevice) => getFeaturesConfig(iotDevice).device_vape_cloud_size_mode;

const getFeaturesConfig = (iotDevice) => iotDevice?.device_management_features_config || {};

export default {
    getDeviceAlarmDuration,
    isChargingWhileCleaningNeeded,
    isCleaningEnabled,
    isCommonVibrationEnabled,
    isDeviceAlarmEnabled,
    isDeviceLedModeEnabled,
    isDiagnosticModeEnabled,
    isExtendedHapticProfileVibrationEnabled,
    isStartVibrationEnabled,
    isHolderChargedVibrationEnabled,
    isHolderLedModeEnabled,
    isParentProtectionMode,
    isProgrammableVibrationSettingsEnabled,
    isResponsiveDrawModeEnabled,
    isVapeCloudModeEnabled,
    isVibrationEnabled,
};
