import {config} from '../config';

export default {
    getUamCheckRequestInterval: () => config.data.uam.uamCheckRequestInterval || 0,
    getUamTimeout: () => config.data.uam.uamTimeout,
    getUamUpdateSettingTimeout: () => config.data.uam.uamUpdateSettingTimeout,
    getUamCheckStatusInterval: () => config.data.uam.uamCheckStatusInterval,
    getUamWaitAssetTimeout: () => config.data.uam.uamWaitAssetTimeout,
    getUamWebSocketMessageTimeout: () => config.data.uam.uamWebSocketMessageTimeout,
};
