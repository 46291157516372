import {Action} from 'redux';

import ModalTypes from '../../enums/app/modalTypes';
import FWU_TARGET_TYPES from '../../enums/iot/fwuTargetTypes';
import {IModalActionProps, TModalState} from '../types/modal';

enum ActionTypes {
    SHOW_MODAL = 'app/modal/SHOW_MODAL',
    HIDE_MODAL = 'app/modal/HIDE_MODAL',
}
interface IModalAction extends Action {
    type: ActionTypes;
    payload: IModalActionProps;
}

const initialState: TModalState = [];

export default (state = initialState, action: IModalAction): TModalState => {
    switch (action.type) {
        case ActionTypes.SHOW_MODAL: {
            const {modalType, modalProps} = action.payload;

            if (state.some((modal) => modal.modalType === modalType)) {
                return state;
            } else {
                return [
                    ...state,
                    {
                        modalType,
                        modalProps,
                    },
                ];
            }
        }
        case ActionTypes.HIDE_MODAL: {
            const {modalType} = action.payload;

            return state.filter((modal) => modal.modalType !== modalType);
        }
        default:
            return state;
    }
};

export const showConnectivitySelectModalAction = (modalProps?: unknown): IModalAction =>
    showModalAction({modalType: ModalTypes.CONNECTIVITY_SELECT, modalProps});

export const showHidReconnectModalAction = (modalProps?: unknown): IModalAction =>
    showModalAction({modalType: ModalTypes.HID_RECONNECT, modalProps});

interface IDeviceIsInSblModalProps {
    isInitialFlow?: boolean;
    fwu_target: FWU_TARGET_TYPES;
}

export const showDeviceIsInSblModalAction = (modalProps: IDeviceIsInSblModalProps): IModalAction =>
    showModalAction({modalType: ModalTypes.DEVICE_IS_IN_SBL, modalProps});

export const showReconnectDeviceGuideModalAction = (modalProps?: unknown): IModalAction =>
    showModalAction({modalType: ModalTypes.RECONNECT_DEVICE_GUIDE, modalProps});

export const showFWUAdditionalStepsGuidelAction = (modalProps?: unknown): IModalAction =>
    showModalAction({modalType: ModalTypes.FWU_ADDITIONAL_STEPS_GUIDE, modalProps});

export const showPurchaseDateModalAction = (modalProps?: unknown): IModalAction =>
    showModalAction({modalType: ModalTypes.PURCHASE_DATE, modalProps});

export const showDeviceHolderRegistrationModalAction = (modalProps?: unknown): IModalAction =>
    showModalAction({modalType: ModalTypes.REGISTRATION_HOLDER, modalProps});

export const showFWULedCheckAction = (modalProps?: unknown): IModalAction =>
    showModalAction({modalType: ModalTypes.FWU_LED_CHECK, modalProps});

export const showFWUErrorModalAction = (modalProps?: unknown): IModalAction =>
    showModalAction({modalType: ModalTypes.FWU_ERROR, modalProps});

export const showModalAction = ({modalType, modalProps}: IModalActionProps): IModalAction => ({
    type: ActionTypes.SHOW_MODAL,
    payload: {modalType, modalProps},
});

export const hideModalAction = (modalType: ModalTypes): IModalAction => ({
    type: ActionTypes.HIDE_MODAL,
    payload: {modalType},
});
