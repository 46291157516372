import React from 'react';

import Icon from './Icon';

const IconCharger = (props) => {
    return (
        <Icon width='23' height='50' viewBox='0 0 23 50' {...props}>
            <g fill='none' fillRule='evenodd' strokeWidth='2.26'>
                <path d='M1.3 2.603l.035 44.366 1.859-.864c-.824-.696-1.253-2.106-1.527-4.138l-.022-.162c-.073-.524-.106-1.542-.137-3.496a86.866 86.866 0 0 1-.025-2.777l.003-1.049.003-.722a13.94 13.94 0 0 0 0-.352l-.006-19.963c0-.446.005-1.402.017-2.34.02-1.533.055-2.72.105-3.3.123-1.434.413-2.528.812-3.33.253-.51.507-.823.717-.99l-.705-.884H1.3zM20.846 44.71c.178-.361.34-1.285.44-2.508.053-.655.087-1.36.105-2.074.012-.462.015-.85.014-1.125V9.112c0-.777-.031-1.467-.082-2.062-.03-.349-.06-.597-.079-.711a5.117 5.117 0 0 0-1.22-2.63c-.748-.867-1.691-1.443-2.712-1.79-.205-.072-.673-.196-1.4-.323-1.247-.218-2.763-.35-4.554-.35-1.702 0-3.429.189-5.086.502a27.637 27.637 0 0 0-1.62.356L4.65 47.469c.449.124 1.05.261 1.8.39 1.897.328 4.129.487 6.687.4 4.671-.157 6.602-1.3 7.709-3.548zM4.086 2.257l-.005.002.018-.005z' />
                <path
                    strokeLinejoin='round'
                    d='M20.593 4.642l.503-.528a.349.349 0 0 0-.013-.382l-.124-.174c-.268-.347-.82-1.005-1.506-1.517-.203-.152-.583-.435-1.134-.654-.154-.06-.263-.074-.35-.038a.267.267 0 0 0-.14.117c-.018.024-.225.317-.383.546'
                />
            </g>
        </Icon>
    );
};

export default IconCharger;
