import cn from 'classnames';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {ButtonDark} from '../../../components/Button';
import Button from '../../../components/Button/Button';
import ConnectionTrackButton from '../../../components/Button/ConnectionTrackButton/ConnectionTrackButton';
import PopupWarning from '../../../components/Popup/PopupWarning';
import * as loaderNames from '../../../consts/loader/loaderNames';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import ModalTypes from '../../../enums/app/modalTypes';
import {useHidReconnectHandler} from '../../../hooks/hid/useHidReconnectHandler';
import backendService from '../../../services/app/backendService';
import connectivityService from '../../../services/device/connectivityService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import modalService from '../../../services/modalService';
import productService from '../../../services/product/productService';
import {hideLoader, setHolderRegisterFailed, showLoader} from '../../../state/ducks/global';
import {showHidReconnectModalAction} from '../../../state/ducks/modal';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../../state/selectors/consumer';
import {makeSelectIsDeviceReady} from '../../../state/selectors/iotDevice';
import {dispatch} from '../../../state/store';
import styles from './RegistrationHolderPopup.module.scss';

const RegistrationHolderPopup = (props) => {
    const {isPopupModalMode = true, registrationAction} = props;
    const iotDevice = useSelector(makeSelectIotDeviceMergedWithIccProduct());
    const isDeviceReady = useSelector(makeSelectIsDeviceReady());
    const {device, holder} = iotDevice || {};
    const {name: deviceName, isHolderConnected} = device || {};
    const {name: holderName, codentify, deviceSerialNumber} = holder || {};
    const localizedStrings = getLocalizedStrings();
    const isUsb = connectivityService.isUsb();
    const isScpCloudBackend = backendService.isScpCloudBackend();

    const startHolderRegistration = () => {
        if (!isDeviceReady) {
            if (isUsb) {
                dispatch(showHidReconnectModalAction());
            }
            return;
        }

        if (isPopupModalMode) {
            productService.purchaseRegisterDeviceCheck({
                action: (purchasedOn) => {
                    dispatch(showLoader(loaderNames.REGISTERING_LOADER));
                    const registerData = {name: holderName, deviceSerialNumber, codentify, isHolder: true, purchasedOn};

                    productService.registerDevice(registerData).then((res) => {
                        if (!res) {
                            dispatch(hideLoader(loaderNames.REGISTERING_LOADER));
                        }
                    });
                },
            });

            modalService.hideModal(ModalTypes.REGISTRATION_HOLDER);
        } else {
            registrationAction();
        }
    };

    //hide global hid reconnect popup
    useHidReconnectHandler();

    useEffect(() => {
        if (!isHolderConnected && isPopupModalMode) {
            modalService.hideModal(ModalTypes.REGISTRATION_HOLDER);
        }
    }, [isHolderConnected]);

    const buttonDarkText =
        localizedStrings[
            isPopupModalMode
                ? localizationKeys.UNREGISTERED_HOLDER_INSERTED_POPUP_REGISTER_BUTTON
                : localizationKeys.CONNECTION_UNREGISTERED_HOLDER_INSERTED_POPUP_REGISTER_BUTTON
        ];

    const onClose = () => {
        dispatch(setHolderRegisterFailed(true));
        modalService.hideModal(ModalTypes.REGISTRATION_HOLDER);
    };

    return (
        <PopupWarning
            isCloseByOverlay={isScpCloudBackend}
            className={styles.Popup}
            onClose={isScpCloudBackend ? onClose : undefined}
        >
            <h2 className={styles.Title}>{deviceName}</h2>
            <div className={cn(styles.Description, 'ica--txt-regular')}>
                {localizedStrings[localizationKeys.UNREGISTERED_HOLDER_INSERTED_POPUP_DESCRIPTION]}
            </div>
            <ConnectionTrackButton
                button={ButtonDark}
                showArrow={!isPopupModalMode}
                className={styles.RegisterBtn}
                onClick={startHolderRegistration}
            >
                {buttonDarkText}
            </ConnectionTrackButton>
            {isScpCloudBackend && (
                <Button className={styles.ButtonSecondary} onClick={onClose} showUnderline>
                    {localizedStrings[localizationKeys.UNREGISTERED_HOLDER_INSERTED_POPUP_CANCEL_BUTTON]}
                </Button>
            )}
        </PopupWarning>
    );
};

export default RegistrationHolderPopup;
