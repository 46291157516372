import cn from 'classnames';
import React, {FC} from 'react';

import ConnectionTrackButton from '../../Button/ConnectionTrackButton/ConnectionTrackButton';
import styles from './CarouselArrow.module.scss';
import CarouselArrowPrev from './CarouselArrowPrev';
import {ICarouselArrowNext} from './types';

const CarouselArrowNext: FC<ICarouselArrowNext> = ({
    testId,
    className,
    onClick,
    to,
    customClassName,
    ariaLabel,
    ariaLabelLastSlide,
    isTabletShowArrows,
    isConnectionTrackButton,
}) =>
    isConnectionTrackButton ? (
        <ConnectionTrackButton
            button={CarouselArrowPrev}
            className={cn(styles.RightArrow, className, customClassName)}
            onClick={onClick}
            testId={testId}
            to={to}
            ariaLabel={ariaLabel}
            ariaLabelLastSlide={ariaLabelLastSlide}
            isTabletShowArrows={isTabletShowArrows}
        />
    ) : (
        <CarouselArrowPrev
            className={cn(styles.RightArrow, className, customClassName)}
            onClick={onClick}
            testId={testId}
            to={to}
            ariaLabel={ariaLabel}
            ariaLabelLastSlide={ariaLabelLastSlide}
            isTabletShowArrows={isTabletShowArrows}
        />
    );

export default CarouselArrowNext;
