export const IOT_SET_MESSAGE = 'app/iot/IOT_SET_MESSAGE';
export const IOT_SET_CURRENT_FLOW_TYPE = 'app/iot/IOT_SET_CURRENT_FLOW_TYPE';

const getInitialData = () => ({
    message: undefined,
    currentFlowType: undefined,
});

export default function reducer(state = getInitialData(), action) {
    switch (action.type) {
        case IOT_SET_MESSAGE: {
            const {data} = action.payload;

            return {
                ...state,
                message: data,
            };
        }
        case IOT_SET_CURRENT_FLOW_TYPE: {
            const {flowType} = action.payload;

            return {
                ...state,
                currentFlowType: flowType,
            };
        }
        default:
            return state;
    }
}

export const setMessage = (message) => ({
    type: IOT_SET_MESSAGE,
    payload: {data: message},
});

export const setCurrentFlowType = (flowType) => ({
    type: IOT_SET_CURRENT_FLOW_TYPE,
    payload: {flowType},
});

export const clearCurrentFlowType = setCurrentFlowType;
