import appConfig from '../../config/appConfig';
import {getLocalizedLanguage} from '../localization/localizationService';
import marketService from '../marketService';
import storageService from '../storage/storageService';

const setUserSessionLocalization = (countryCode, languageCode) => {
    const isRememberMeEnabled = appConfig.getIsRememberMeEnabled();

    if (isRememberMeEnabled) {
        const currentMarket = marketService.currentMarket();

        storageService.setMarketCodeToLocalStorage(countryCode ? countryCode : currentMarket);
        storageService.setLanguageCodeToLocalStorage(languageCode ? languageCode : getLocalizedLanguage());
    }
};

const setUserSessionTokenData = (data) => {
    const isRememberMeEnabled = appConfig.getIsRememberMeEnabled();
    const {accessToken, refreshToken, expirationDate, isAnonymous} = data;

    if (isRememberMeEnabled && !isAnonymous) {
        storageService.setAccessTokenToStorage(accessToken);
        storageService.setAccessTokenExpirationToStorage(expirationDate);
        storageService.setRefreshTokenToStorage(refreshToken);
    }
};

const setUserSessionSpiceTokenData = (token, username) => {
    const isRememberMeEnabled = appConfig.getIsRememberMeEnabled();

    if (isRememberMeEnabled) {
        storageService.setSpiceTokenToStorage(token);
        storageService.setUsernameToStorage(username);
    }
};

const removeUserSessionSpiceTokenData = () => {
    const isRememberMeEnabled = appConfig.getIsRememberMeEnabled();

    if (isRememberMeEnabled) {
        storageService.removeSpiceTokenFromStorage();
        storageService.removeUsernameFromStorage();
    }
};

export default {
    removeUserSessionSpiceTokenData,
    setUserSessionLocalization,
    setUserSessionSpiceTokenData,
    setUserSessionTokenData,
};
