import cn from 'classnames';
import React, {FC} from 'react';

import Button from '../Button';
import {IButton} from '../ButtonTypes';
import styles from './ButtonWhiteHigh.module.scss';

const ButtonWhiteHigh: FC<IButton> = ({className, ...props}) => (
    <Button {...props} className={cn(styles.ButtonWhiteHigh, className)} />
);

export default ButtonWhiteHigh;
