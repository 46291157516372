import React from 'react';

import ToolColorIcon from './ToolColorIcon';

const ToolColorRegularIcon = (props) => {
    return (
        <ToolColorIcon {...props}>
            <rect fill='#AAAAAA' x='0' y='0' width='6' height='16' />
            <rect fill='#D6A76F' x='6' y='0' width='6' height='16' />
            <rect fill='#404040' x='12' y='0' width='6' height='16' />
        </ToolColorIcon>
    );
};

export default ToolColorRegularIcon;
