import {config} from '../config';
import {TConfigGetterReturn} from './types';

const getSkipPageSections = (): TConfigGetterReturn<string[]> => {
    return config.data.skipPage.skipSections;
};

export default {
    getSkipPageSections,
};
