const PRODUCT_COLOR_TYPES = {
    BRILLIANT_GOLD: 'GOL',
    COPPER_ROSE: 'COR',
    RUBY: 'RUB',
    COPPER: 'COP',
    GRAPHITE: 'GRY',
    VELVET_GREY: 'VEG',
    MOTOR_SPORTS: 'MSR',
    BLUE: 'BLU',
    MARINE_BLUE: 'MAB',
    LUCID_TEAL: 'LUT',
    NIGO: 'CAM',
    RED: 'RED',
    NAVY: 'NAV',
    WHITE: 'WHT',
    PINK: 'PNK',
    OBSIDIAN_BLACK: 'OBL',
    JADE_GREEN: 'JAG',
    GOLD_KHAKI: 'GKK',
    BRONZE_TAUPE: 'BZT',
    MOSS_GREEN: 'MOSSGRE',
    AZURE_BLUE: 'ABL',
    SUNSET_RED: 'SUR',
    PEBBLE_BEIGE: 'PEB',
    PEBBLE_GREY: 'PEBBLEG',
    BRILLIANT_GOLD_IQOS_VEEV: 'VEEVBRIL',
    BRILLIANT_GOLD_VEEV: 'BRILLIA1',
    COPPER_ROSE_IQOS_VEEV: 'VEEVCOPP',
    COPPER_ROSE_VEEV: 'COPPERR',
    VELVET_GREY_IQOS_VEEV: 'VEEVVELV',
    VELVET_GREY_VEEV: 'VELVETG',
    MARINE_BLUE_IQOS_VEEV: 'VEEVMARI',
    MARINE_BLUE_VEEV: 'MARINEBL',
    LUCID_TEAL_IQOS_VEEV: 'VEEVLUCI',
    LUCID_TEAL_VEEV: 'LUCIDTE',
    WARM_WHITE: 'WWH',
    WARM_ORANGE: 'WRC',
    SILVER_BLUE: 'SRB',
};

export default PRODUCT_COLOR_TYPES;
