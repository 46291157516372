enum BLE_UUID_TYPES {
    RRP_SERVICE = 'daebb240-b041-11e4-9e45-0002a5d5c51b',
    SCP_CONTROL_POINT = 'e16c6e20-b041-11e4-a4c3-0002a5d5c51b',
    DEVICE_STATUS_CHAR = 'ecdfa4c0-b041-11e4-8b67-0002a5d5c51b',
    DEVICE_BATTERY_CHAR = 'f8a54120-b041-11e4-9be7-0002a5d5c51b',
    FW_UPGRADE_CONTROL = 'fe272aa0-b041-11e4-87cb-0002a5d5c51b',
    FW_UPGRADE_DATA = '04941060-b042-11e4-8bf6-0002a5d5c51b',
    FW_UPGRADE_STATUS = '15c32c40-b042-11e4-a643-0002a5d5c51b',
}

export default BLE_UUID_TYPES;
