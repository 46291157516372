import {getMappedInitialLanguageCode} from '../../services/localization/localizationService';

const getLocalizedPropertyOrDefault = (propertyPath) => {
    const language = getMappedInitialLanguageCode();

    const targetConfigProp = propertyPath?.[language?.toLowerCase()];

    return targetConfigProp ? targetConfigProp : propertyPath;
};

export default {
    getLocalizedPropertyOrDefault,
};
