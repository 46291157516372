export const PARENT_PROTECTION = 'parentProtection';
export const VAPE_CLOUD_SIZE = 'vapeCloudSize';
export const RESPONSIVE_DRAW_PROFILE = 'responsiveDrawProfile';
export const LED_INTENSITY = 'ledIntensity';
export const EXT_HAPTIC_PROFILE = 'extHapticProfile';
export const HEATING_VIBRATION = 'heatingVibration';
export const BATTERY_CHARGED_VIBRATION = 'batteryChargedVibration';
export const UI_BATTERY_CHARGED_VIBRATION = 'uiBatteryChargedVibration';
export const LIFT_UP_GESTURE = 'liftUpGesture';
export const DOUBLE_TAP_GESTURE = 'doubleTapGesture';
export const AUTO_START_HEATING = 'autoStartHeating';
