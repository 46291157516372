export const HEADER_CHARGER_RESPONSE = 0xc0;
export const HEADER_HOLDER_RESPONSE = 0x08;
export const OFFSET_DEVICE_TYPE = 1;
export const OFFSET_APP_LAYER_HEADER = 2;
export const OFFSET_PAYLOAD_START = 4;

//SN Payload
export const PLATFORM_CODE_OFFSET = 0;
export const PRODUCT_CODE_OFFSET = 2;
export const SITE_CODE_OFFSET = 4;
export const DEVICE_NUMBER_OFFSET = 6;
export const DEVICE_NUMBER_END = 10;

export const FRAME_CHARGER_CODENTIFY = '00C0000309';
export const FRAME_CHARGER_SERIAL = '00C0010015';
export const FRAME_HOLDER_CODENTIFY = '00C9000309';
export const FRAME_HOLDER_SERIAL = '00C9010015';

export const FRAME_CHARGER_CODENTIFY_USB = '3F04C0000309';
export const FRAME_CHARGER_SERIAL_USB = '3F04C0010015';
export const FRAME_HOLDER_SERIAL_USB = '3F04C9010015';
export const FRAME_HOLDER_CODENTIFY_USB = '3F04C9000309';
