import StorageClient from './storageClient';

class SessionStorageClient extends StorageClient {
    constructor() {
        super(sessionStorage);
    }
}

const storageClient = new SessionStorageClient();

export default storageClient;
