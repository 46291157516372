type TIsExpired = (expirationDate: number | Date) => boolean;
type TMsToDays = (ms: number) => number;
type TGetNowUtcTsUnix = () => string;
type TGetDaysCountBetweenTwoDates = (date1: number, date2: number) => number;

const isExpired: TIsExpired = (expirationDate) => expirationDate < Date.now();

const msToDays: TMsToDays = (ms) => {
    const md = 24 * 60 * 60 * 1000;

    return Math.floor(ms / md);
};

const getNowUtcTsUnix: TGetNowUtcTsUnix = () => {
    const ts = new Date().getTime();

    return (ts / 1000).toString();
};

const getDaysCountBetweenTwoDates: TGetDaysCountBetweenTwoDates = (date1, date2) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = new Date(date1).getTime();
    const secondDate = new Date(date2).getTime();

    return Math.floor(Math.abs((firstDate - secondDate) / oneDay));
};

export default {
    getDaysCountBetweenTwoDates,
    getNowUtcTsUnix,
    isExpired,
    msToDays,
};
