import deviceConnectionStatusTypes from '../../consts/device/deviceConnectionStatusTypes';

const isDeviceConnected = (status) => status >= deviceConnectionStatusTypes.CONNECTED;
const isDeviceConnectedStatus = (status) => status === deviceConnectionStatusTypes.CONNECTED;
const isDeviceDisconnected = (status) => status === deviceConnectionStatusTypes.DISCONNECTED;
const isDevicePaired = (status) => status === deviceConnectionStatusTypes.PAIRED;
const isDeviceReady = (status) => status === deviceConnectionStatusTypes.READY;
const isWrongDevice = (status) => status === deviceConnectionStatusTypes.WRONG_DEVICE;

export default {
    isDeviceConnected,
    isDeviceConnectedStatus,
    isDeviceDisconnected,
    isDevicePaired,
    isDeviceReady,
    isWrongDevice,
};
