import React from 'react';

import ToolColorIcon from './ToolColorIcon';

const ToolColorContrastIcon = (props) => {
    return (
        <ToolColorIcon {...props}>
            <rect fill='#E1E1E2' x='0' y='0' width='6' height='16' />
            <rect fill='#FFCC33' x='6' y='0' width='6' height='16' />
            <rect fill='#03CCCB' x='12' y='0' width='6' height='16' />
        </ToolColorIcon>
    );
};

export default ToolColorContrastIcon;
