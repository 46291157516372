import appConfig from '../../config/appConfig';
import * as yapActionStatusTypes from '../../consts/yap/yapActionStatusTypes';
import {
    setDeviceDataChangeReceived,
    setSyncLastRequestId,
    setYapActivationInProgress,
    setYapLastCheckRequestDate,
    setYapLastRequestId,
    setYapUpdateSettingsInProgress,
} from '../../state/ducks/yapEncrypted';
import {
    makeSelectFirstAsset,
    makeSelectIsDeviceSyncInProgress,
    makeSelectIsYapActivationInProgress,
    makeSelectIsYapDeviceConnected,
    makeSelectIsYapHolderConnected,
    makeSelectIsYapUpdateSettingsInProgress,
    makeSelectSyncLastRequestId,
    makeSelectYapLastCheckRequestDate,
    makeSelectYapLastRequestId,
} from '../../state/selectors/yapEncrypted';
import {dispatch, getState} from '../../state/store';
import backendService from '../app/backendService';
import gamClientService from '../gam/gamClientService';
import gamWebSocketResponseService from '../gam/gamWebSocketResponseService';
import gamWebSocketService from '../gam/gamWebSocketService';
import log from '../logger/log';
import uamClientService from '../uam/uamClientService';
import uamWebSocketResponseService from '../uam/uamWebSocketResponseService';
import uamWebSocketService from '../uam/uamWebSocketService';

let isOneMoreCheckStatusNeeded = false;
let checkStatusScheduler;

const createCheckStatusScheduler = (assetId, isHolder) => {
    if (checkStatusScheduler) return;

    const isGamBackend = backendService.isGamBackend();
    const interval = isGamBackend ? appConfig.getGamCheckStatusInterval() : appConfig.getUamCheckStatusInterval();

    log.info('yapWebSocketService: check status scheduler is started');
    checkStatusScheduler = setTimeout(checkStatus, interval * 1000, assetId, interval, isHolder);
};

const stopCheckStatusScheduler = () => {
    if (checkStatusScheduler) {
        clearTimeout(checkStatusScheduler);
        checkStatusScheduler = null;
        log.info('yapWebSocketService: check status scheduler is stopped');
    }
};

const checkStatus = async (assetId, interval, isHolder) => {
    const isGamBackend = backendService.isGamBackend();
    const isWebSocketConnected = isGamBackend
        ? gamWebSocketService.isGamWebSocketConnected(assetId)
        : uamWebSocketService.isUamWebSocketConnected(assetId, isHolder);

    if (isOneMoreCheckStatusNeeded || !isWebSocketConnected) {
        if (isWebSocketConnected) {
            isOneMoreCheckStatusNeeded = false;
        }

        await checkRequestInternal(isGamBackend, assetId, isHolder);
    }

    checkStatusScheduler = setTimeout(checkStatus, interval * 1000, assetId, interval, isHolder);
};

const checkRequestInternal = async (isGamBackend, assetId, isHolder) => {
    try {
        const state = getState();
        const isYapDeviceConnected = makeSelectIsYapDeviceConnected()(state);
        const isYapHolderConnected = makeSelectIsYapHolderConnected()(state);

        if ((isYapDeviceConnected && !isHolder) || (isHolder && isYapHolderConnected)) {
            await checkYapRequestStatus(isGamBackend, assetId, isHolder);
        } else {
            if (isGamBackend) {
                await gamClientService.checkGamConnectivityStatus(assetId);
            } else {
                await uamClientService.checkConnectivityStatus(assetId);
            }
        }
    } catch (e) {
        log.info(`yapCheckStatusService: request check is ignored due error: ${e}`);
    }
};

const checkStatusOneTime = async (serialNumber, isHolder) => {
    const state = getState();
    const isYapUpdateSettingsInProgress = makeSelectIsYapUpdateSettingsInProgress()(state);
    const isYapActivationInProgress = makeSelectIsYapActivationInProgress()(state);
    const isDeviceSyncInProgress = makeSelectIsDeviceSyncInProgress()(state);

    if (
        !isHolder &&
        serialNumber &&
        (isYapUpdateSettingsInProgress || isYapActivationInProgress || isDeviceSyncInProgress)
    ) {
        await checkStatusOneTimeInternal(serialNumber, isHolder);
    }

    const isYapHolderUpdateSettingsInProgress = makeSelectIsYapUpdateSettingsInProgress(true)(state);
    const isHolderSyncInProgress = makeSelectIsDeviceSyncInProgress(true)(state);

    if (isHolder && serialNumber && (isYapHolderUpdateSettingsInProgress || isHolderSyncInProgress)) {
        await checkStatusOneTimeInternal(serialNumber, isHolder);
    }
};

const checkStatusOneTimeInternal = async (serialNumber, isHolder) => {
    const state = getState();
    const isGamBackend = backendService.isGamBackend();
    let assetId;

    if (isGamBackend) {
        assetId = makeSelectFirstAsset()(state)?.assetId;
    } else {
        const uamAsset = await uamClientService.waitUntilGetUamAsset(serialNumber);

        assetId = uamAsset?.assetId;
    }

    if (!assetId) {
        log.debug('Check status is ignored because asset id is not defined');
        return;
    }

    const timestamp = Date.now();
    const checkRequestInterval = isGamBackend
        ? appConfig.getGamCheckRequestInterval()
        : appConfig.getUamCheckRequestInterval();

    const lastCheckDate = makeSelectYapLastCheckRequestDate()(state) + checkRequestInterval * 1000;

    if (lastCheckDate < timestamp) {
        dispatch(setYapLastCheckRequestDate(timestamp));

        await checkRequestInternal(isGamBackend, assetId, isHolder);
    } else {
        log.debug('Check status is ignored because current time is less then last check date + 10 sec');
    }
};

const checkYapRequestStatus = async (isGamBackend, assetId, isHolder) => {
    const state = getState();
    const syncRequestId = makeSelectSyncLastRequestId()(state);
    const holderRequestId = makeSelectYapLastRequestId()(state, true);
    const chargerRequestId = makeSelectYapLastRequestId()(state);
    const isSync = !!syncRequestId;
    const requestId = isSync ? syncRequestId : isHolder ? holderRequestId : chargerRequestId;

    if (!requestId) return;

    const status = isGamBackend
        ? await gamClientService.checkGamRequestStatus(assetId, requestId, isSync)
        : await uamClientService.checkUamRequestStatus({assetId, requestId, isSync, isHolder});

    if (status !== yapActionStatusTypes.PENDING) {
        await updateYapInProgressStatus({status, isSync, assetId, isHolder});

        if (isSync) {
            dispatch(setSyncLastRequestId(null));
        } else {
            dispatch(setYapLastRequestId(null, isHolder));
            dispatch(setDeviceDataChangeReceived(false));
        }
    }
};

const updateYapInProgressStatus = async ({status, isSync, assetId, isHolder}) => {
    const state = getState();
    const isGamBackend = backendService.isGamBackend();

    if (isSync) {
        const isDeviceSyncInProgress = makeSelectIsDeviceSyncInProgress()(state);
        const isHolderSyncInProgress = makeSelectIsDeviceSyncInProgress(true)(state);

        if ((isHolder && !isHolderSyncInProgress) || (!isHolder && !isDeviceSyncInProgress)) {
            return;
        }

        if (isGamBackend) {
            await gamWebSocketResponseService.proceedRefreshInfo(status);
        } else {
            await uamWebSocketResponseService.proceedRefreshInfo(status, assetId);
        }
    } else {
        const isYapActivationInProgress = makeSelectIsYapActivationInProgress()(state);

        if (isYapActivationInProgress) {
            dispatch(setYapActivationInProgress(false));
            return;
        }

        const isYapUpdateSettingsInProgress = makeSelectIsYapUpdateSettingsInProgress(isHolder)(state);

        if (isYapUpdateSettingsInProgress) {
            dispatch(setYapUpdateSettingsInProgress(false, isHolder));
        }
    }
};

const setIsOneMoreCheckStatusNeeded = (isCheckNeeded) => {
    isOneMoreCheckStatusNeeded = isCheckNeeded;
};

export default {
    createCheckStatusScheduler,
    stopCheckStatusScheduler,
    setIsOneMoreCheckStatusNeeded,
    checkStatusOneTime,
};
