import cn from 'classnames';
import React from 'react';

import Icon from '../Icon';
import styles from './IconTickSvg.module.scss';

const IconTickSvg = (props) => {
    const {className} = props;
    const IconTickClassName = cn(styles.IconTick, className);

    return (
        <Icon className={IconTickClassName} width='16' height='12' viewBox='0 0 16 12'>
            <g fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
                <g stroke='#272A33' strokeWidth='2'>
                    <path
                        d='M0 6L4 10 14 0'
                        transform='translate(-309 -306) translate(0 98) translate(28 140) translate(0 42) translate(282 27)'
                    />
                </g>
            </g>
        </Icon>
    );
};

export default IconTickSvg;
