import appConfig from '../../config/appConfig';
import * as logMessageTypes from '../../consts/app/logMessageTypes';
import * as iccProductsStatusMessageTypes from '../../consts/icc/iccProductsStatusMessageTypes';
import * as iccResponseErrorTypes from '../../consts/icc/iccResponseErrorTypes';
import ICC_PRODUCT_STATUS_TYPES from '../../enums/icc/iccProductStatusTypes';
import {
    removeConsumerProductsStatus,
    updateConsumerProductData,
    updateConsumerProductsStatus,
} from '../../state/ducks/consumer';
import {setIsDoubleRegistration} from '../../state/ducks/global';
import {setDeviceActivationStatus} from '../../state/ducks/iotDevice/actions';
import {showPurchaseDateModalAction} from '../../state/ducks/modal';
import {
    setDevicePurchaseData,
    setHolderPurchaseData,
    setProductToChangeRegisteringStatus,
    setProductToChangeUnregisteringStatus,
} from '../../state/ducks/productSettings';
import {makeSelectIotDeviceMergedWithIccProduct, makeSelectProductStatus} from '../../state/selectors/consumer';
import {
    makeSelectCurrentProductToChangeRegistrationStatus,
    makeSelectDevicePurchaseDate,
    makeSelectHolderPurchaseDate,
} from '../../state/selectors/productSettings';
import {dispatch, getState} from '../../state/store';
import helpers from '../../utils/helpers';
import analyticsService from '../analyticsService';
import appErrorService from '../app/appErrorService';
import backendService from '../app/backendService';
import cryptoService from '../cryptoService';
import gamService from '../gam/gamService';
import iccMarketService from '../icc/iccMarketService';
import log from '../logger/log';
import marketConfigService from '../marketConfigService';
import marketTypeService from '../marketTypeService';
import uamClientService from '../uam/uamClientService';
import uamService from '../uam/uamService';
import yapService from '../yap/yapService';

let productRegistrationAttempts = 0;

const setProductRegistrationAttempts = (attempts) => {
    productRegistrationAttempts = attempts;
};

const getProductRegistrationAttempts = () => productRegistrationAttempts;

const unregisterConsumerProduct = async ({productId, codentify, deviceSerialNumber, isP4, isHolder}) => {
    try {
        const msg = isHolder ? 'Holder ' : '';

        log.info(`Start ${msg}unregistration process for codentify: ${codentify}.`);
        const isDCE1 = marketTypeService.isDCE1();

        dispatch(removeConsumerProductsStatus());

        const getProductIdentity = () => {
            if (isDCE1) {
                return {codentifyId: codentify};
            } else {
                return productId ? {productId} : {codentifyId: codentify};
            }
        };

        const productIdentify = getProductIdentity();
        // const productIdentify = productId ? {productId} : {codentifyId: codentify};
        const response = await iccMarketService.removeConsumerProduct({data: productIdentify});

        if (response) {
            dispatch(
                updateConsumerProductData({
                    deviceSerialNumber,
                    codentify,
                    status: ICC_PRODUCT_STATUS_TYPES.UNREGISTERING,
                })
            );
            dispatch(setProductToChangeUnregisteringStatus(isP4, deviceSerialNumber, codentify, isHolder));
        }
    } catch (e) {
        appErrorService.showGlobalError();
        throw e;
    }
};

const purchaseRegisterDeviceCheck = ({action, isDoubleRegistration, cancelAction, isPurchaseDate}) => {
    const isPurchaseDateEnabled = appConfig.getIsPurchaseDateEnabled();

    if (isPurchaseDateEnabled && !isDoubleRegistration && !isPurchaseDate) {
        dispatch(showPurchaseDateModalAction({registrationAction: action, registrationCancelAction: cancelAction}));
    } else {
        action();
    }
};

const registerDevice = async (registerData) => {
    const {
        deviceSerialNumber: serialNumber,
        holderInternalId,
        codentify,
        name,
        isP4,
        isHolder,
        purchasedOn = undefined,
    } = registerData;
    const currentProductToChangeRegistrationStatus = makeSelectCurrentProductToChangeRegistrationStatus(!!isHolder)(
        getState()
    );

    if (currentProductToChangeRegistrationStatus) return;

    const dispatchSetProductToChangeRegisteringStatus = (isSchedulerRequired) => {
        dispatch(
            setProductToChangeRegisteringStatus(
                isP4,
                deviceSerialNumber,
                codentify,
                name,
                isHolder,
                isSchedulerRequired
            )
        );
    };
    const setProductStatusError = (statusMessage) => {
        dispatch(
            updateConsumerProductsStatus({
                deviceSerialNumber,
                codentify,
                isP4,
                status: ICC_PRODUCT_STATUS_TYPES.FAILED,
                statusMessage,
            })
        );
        dispatchSetProductToChangeRegisteringStatus(false);
    };

    dispatch(removeConsumerProductsStatus());

    dispatch(isHolder ? setHolderPurchaseData(purchasedOn) : setDevicePurchaseData(purchasedOn));

    const deviceSerialNumber = serialNumber || holderInternalId;

    let response;

    try {
        if (isP4) {
            if (codentify) {
                log.info(`Start registration process for codentify: ${codentify}.`);

                response = await iccMarketService.setConsumerProductV2({codentify, purchasedOn});
            } else {
                log.info(`Registration process is failed. Codentify is not defined`);
            }
        } else {
            const encryptedSerialNumber = cryptoService.encryptDeviceSerialNumber(deviceSerialNumber);

            if (encryptedSerialNumber) {
                log.info(`Start registration process for encryptedSerialNumber: ${encryptedSerialNumber}.`);
                response = await iccMarketService.setConsumerProductV3({
                    deviceSerialNumber: encryptedSerialNumber,
                    purchasedOn,
                });
            } else {
                log.info(
                    `Registration process is failed. Empty encryptedSerialNumber, deviceSerialNumber: ${deviceSerialNumber}`
                );
            }
        }

        if (response) {
            dispatch(
                updateConsumerProductData({
                    deviceSerialNumber,
                    codentify,
                    isP4,
                    status: ICC_PRODUCT_STATUS_TYPES.REGISTERING,
                })
            );
            dispatchSetProductToChangeRegisteringStatus();
        } else {
            setProductStatusError(iccProductsStatusMessageTypes.INVALID_DEVICE_STATUS);
        }
    } catch (e) {
        const errorStatus = e?.response?.status;
        const isRegistartionFailedError = errorStatus
            ? iccResponseErrorTypes.REGISTRATION_FAILED_ERRORS.some(({errorCode}) => errorCode === errorStatus)
            : false;

        if (!isRegistartionFailedError) {
            log.error(`Registration process is failed. Error: ${e}`, logMessageTypes.ICC);
            setProductStatusError(iccProductsStatusMessageTypes.EXTERNAL_SYSTEM_UNAVAILABLE);
        }
    }

    return response;
};

const registerConsumerProduct = async (product, name, registrationCancelAction) => {
    if (!product) return;

    const {device, holder, deviceSerialNumber} = product;
    const {isP4, codentify} = device;
    const holderInternalId = holder?.deviceSerialNumber;
    const isDeviceRegisterationNeeded = !isStatusRegistered(device.status);
    const isHolderRegistrationNeeded = holderInternalId && !isStatusRegistered(holder?.status);
    const isDoubleRegistration = isDeviceRegisterationNeeded && isHolderRegistrationNeeded;

    if (isDoubleRegistration) {
        dispatch(setIsDoubleRegistration(true));
    }
    const state = getState();
    const isDevicePurchaseDateInState = makeSelectDevicePurchaseDate()(state);
    const isHolderPurchaseDateInState = makeSelectHolderPurchaseDate()(state);

    purchaseRegisterDeviceCheck({
        action: (kitPurchasedOn) => {
            if (isDeviceRegisterationNeeded) {
                purchaseRegisterDeviceCheck({
                    action: (purchasedOn) => {
                        const registerData = {
                            deviceSerialNumber,
                            codentify,
                            name,
                            isP4,
                            isHolder: false,
                            purchasedOn: isDevicePurchaseDateInState || kitPurchasedOn || purchasedOn,
                        };

                        registerDevice(registerData);
                    },
                    isPurchaseDate: isDevicePurchaseDateInState,
                    isDoubleRegistration,
                    cancelAction: () => {
                        helpers.runFunction(registrationCancelAction);
                    },
                });
            }
            if (isHolderRegistrationNeeded) {
                purchaseRegisterDeviceCheck({
                    action: (purchasedOn) => {
                        const registerData = {
                            holderInternalId,
                            codentify: holder.codentify,
                            name,
                            isP4,
                            isHolder: true,
                            purchasedOn: isHolderPurchaseDateInState || kitPurchasedOn || purchasedOn,
                        };

                        registerDevice(registerData);
                    },
                    isPurchaseDate: isHolderPurchaseDateInState,
                    isDoubleRegistration,
                    cancelAction: () => {
                        helpers.runFunction(registrationCancelAction);
                    },
                });
            }
        },
        isDoubleRegistration: !isDoubleRegistration || (isDevicePurchaseDateInState && isHolderPurchaseDateInState),
        cancelAction: () => {
            helpers.runFunction(registrationCancelAction);
        },
    });
};

const fetchProductsStatusIfNeeded = () => {
    const currentProductToChangeRegistrationStatus = makeSelectCurrentProductToChangeRegistrationStatus()(getState());

    if (currentProductToChangeRegistrationStatus) {
        const {isHolder} = currentProductToChangeRegistrationStatus;

        fetchProductsStatusAndCheckIotProductStatus(isHolder);
    }
};

const fetchProductsStatusAndCheckIotProductStatus = async (isHolder) => {
    const iotProduct = makeSelectIotDeviceMergedWithIccProduct()(getState());

    if (!iotProduct) return;

    const {deviceSerialNumber} = isHolder ? iotProduct.holder : iotProduct;
    const {status, codentify, isP4} = isHolder ? iotProduct.holder : iotProduct.device;

    await iccMarketService.getConsumerProductsStatus();

    const productStatus = makeSelectProductStatus(isP4, deviceSerialNumber, codentify)(getState());

    if (!productStatus || productStatus.status !== status) {
        iccMarketService.getConsumerProducts();
    }
};

const isStatusRegistered = (status) => status === ICC_PRODUCT_STATUS_TYPES.REGISTERED;

const isStatusUnregistered = (status) => status === undefined || status === ICC_PRODUCT_STATUS_TYPES.UNREGISTERED;

const isStatusUnregistering = (status) => status === ICC_PRODUCT_STATUS_TYPES.UNREGISTERING;

const isStatusRegisterFailed = (status) => status === ICC_PRODUCT_STATUS_TYPES.FAILED;

const proceedDeviceStatus = (statusFunction, status) => {
    const isIccJourneyScreensEnabled = marketConfigService.isIccJourneyScreensEnabled();

    return isIccJourneyScreensEnabled ? statusFunction(status) : true;
};

const getIsRegistered = (status) => proceedDeviceStatus(isStatusRegistered, status);

const getIsUnregistered = (status) => proceedDeviceStatus(isStatusUnregistered, status);

const getIsRegisterFailed = (status) => proceedDeviceStatus(isStatusRegisterFailed, status);

const getIsUnregistering = (status) => proceedDeviceStatus(isStatusUnregistering, status);

const registerProductIfNeeded = (isDeviceActivated = true, iotProduct, isP4) => {
    if (!isDeviceActivated) return;

    const isUamBackend = backendService.isUamBackend();

    if (isUamBackend) return;

    const {device, holder} = iotProduct || {};
    const isCurrentProductRegistered = isStatusRegistered(device?.status);
    const isCurrentProductHolderRegistered = isStatusRegistered(holder?.status);
    const isNeedsToRegister = !isCurrentProductRegistered || (!isP4 && !isCurrentProductHolderRegistered);

    if (isNeedsToRegister) {
        analyticsService.pushRegistrationFlowStartEvent(device?.type);
        registerConsumerProduct(iotProduct, device?.name);
    }
};

const checkDeviceActivatedAndRegisterIfNeeded = async () => {
    if (backendService.isGamBackend()) {
        //to setup activation status
        await gamService.checkIsDeviceActivatedInGam();
        return;
    }

    const isDeviceRegistrationEnabled = marketConfigService.isDeviceRegistrationEnabled();
    const iotProduct = makeSelectIotDeviceMergedWithIccProduct()(getState());
    const {device, deviceSerialNumber} = iotProduct || {};
    const {isP4} = device || {};
    const isYAPFlowEnabled = yapService.isYapMode();
    const isCheckUamActivation = uamService.isUamMode(isP4) && backendService.isScpCloudBackend();

    let isDeviceActivated;

    if (isP4 && isYAPFlowEnabled) {
        if (backendService.isUamBackend()) return;

        if (isCheckUamActivation) {
            await uamClientService.getAssets(true);
            isDeviceActivated = await uamService.isDeviceActivatedInUam(deviceSerialNumber);
        } else {
            if (isDeviceRegistrationEnabled) {
                isDeviceActivated = await iccMarketService.getConsumerProductActivationStatus({
                    data: deviceSerialNumber,
                });
            }
        }
    } else {
        dispatch(setDeviceActivationStatus(true));
    }

    if (yapService.isDataCollectionEnabled()) return;

    if (isDeviceRegistrationEnabled) {
        registerProductIfNeeded(isDeviceActivated, iotProduct, isP4);
    }
};

export default {
    checkDeviceActivatedAndRegisterIfNeeded,
    fetchProductsStatusAndCheckIotProductStatus,
    fetchProductsStatusIfNeeded,
    getIsRegistered,
    getIsUnregistered,
    getIsRegisterFailed,
    getIsUnregistering,
    getProductRegistrationAttempts,
    isStatusRegistered,
    purchaseRegisterDeviceCheck,
    registerConsumerProduct,
    registerDevice,
    setProductRegistrationAttempts,
    unregisterConsumerProduct,
};
