import appConfig from '../../config/appConfig';
import * as cleaningSupportCodes from '../../consts/iot/cleaningSupportCodes';
import * as activationStatusTypes from '../../consts/uam/activationStatusTypes';
import {setDeviceActivationStatus, updateIotDeviceData} from '../../state/ducks/iotDevice/actions';
import {setYapAssets} from '../../state/ducks/yapEncrypted';
import {makeSelectIotDeviceData} from '../../state/selectors/iotDevice';
import {makeSelectFirstAsset} from '../../state/selectors/yapEncrypted';
import {dispatch, getState} from '../../state/store';
import backendService from '../app/backendService';
import deviceTypesService from '../device/deviceTypesService';
import domService from '../domService';
import log from '../logger/log';
import {deviceInfoMapping} from '../mapping/iotMappings';
import storageService from '../storage/storageService';
import yapService from '../yap/yapService';

const initGamData = async () => {
    const asset = makeSelectFirstAsset()(getState());
    const data = {
        device: {
            type: asset.type,
            name: deviceTypesService.getProductNameByType(asset.type),
            mediaId: asset.materialId,
        },
    };

    const mappedData = deviceInfoMapping(data);

    dispatch(updateIotDeviceData(mappedData));
};

const updateGamDeviceConfig = (firmwareVersion) => {
    const asset = makeSelectFirstAsset()(getState());
    const isP4 = deviceTypesService.isP4(asset?.type);
    const isHolderCheck = !isP4 && firmwareVersion;
    const isLedEnabled = firmwareVersion && appConfig.getIsLedEnabled();
    const isVibrationEnabled = !isP4 && firmwareVersion && appConfig.getIsVibrationEnabled();
    const isSelfCleaningEnabled = !isP4 && firmwareVersion && appConfig.getIsSelfCleaningEnabled();
    const isDeviceAlarmEnabled = firmwareVersion && appConfig.getIsDeviceAlarmEnabled();
    const isConnectCareEnabled = firmwareVersion && appConfig.getIsConnectCareEnabled();
    const isParentProtectionEnabled = firmwareVersion && appConfig.getIsParentProtectionEnabled();
    const isVapeCloudSizeEnabled = isP4 && firmwareVersion && appConfig.getIsVapeCloudSizeEnabled();
    const isResponsiveDrawProfileEnabled = isP4 && firmwareVersion && appConfig.getIsResponsiveDrawProfileEnabled();
    const isAutoStartEnabled = !isP4 && firmwareVersion && appConfig.getIsAutoStartEnabled();
    const isGestureEnabled = !isP4 && firmwareVersion && appConfig.getIsGestureEnabled();

    //todo: update all config after P1 release

    const data = {
        device_global_features_config: {
            is_autostart: isAutoStartEnabled,
            is_holder_check: isHolderCheck,
            is_led_intensity: isLedEnabled,
            is_vibration: isVibrationEnabled,
            is_self_cleaning: isSelfCleaningEnabled,
            is_device_alarm: isDeviceAlarmEnabled,
            is_status_gestures: isGestureEnabled,
        },
        device_management_features_config: {
            autostart_feature: isAutoStartEnabled,
            status_gestures: isGestureEnabled,
            connect_care_mode: isConnectCareEnabled,
            device_led_intensity_mode: isLedEnabled,
            device_parent_protection_mode: isParentProtectionEnabled,
            holder_led_intensity_mode: false,
            device_holder_charged_vibration: isVibrationEnabled,
            device_self_cleaning: isSelfCleaningEnabled
                ? cleaningSupportCodes.FULL_AVAILABLE
                : cleaningSupportCodes.NOT_AVAILABLE,
            programmable_vibrations: {setting_supported: false, common_vibration_switcher: false},
            device_alarm: isDeviceAlarmEnabled,
            device_alarm_duration: 30,
            device_vape_cloud_size_mode: isVapeCloudSizeEnabled,
            device_responsive_draw_profile_mode: isResponsiveDrawProfileEnabled,
        },
    };

    const mappedData = deviceInfoMapping(data);

    dispatch(updateIotDeviceData(mappedData));
};

const checkIsDeviceActivatedInGam = async () => {
    const isYAPFlowEnabled = yapService.isYapMode();

    if (!isYAPFlowEnabled) {
        dispatch(setDeviceActivationStatus(true));
        return;
    }

    let isDeviceActivated = false;
    const state = getState();

    const gamAsset = makeSelectFirstAsset()(state);

    if (gamAsset?.assetId) {
        isDeviceActivated = gamAsset.yapActivated;
    }

    log.info(`gamService: checkIsDeviceActivatedInGam: ${isDeviceActivated}`);
    dispatch(setDeviceActivationStatus(isDeviceActivated));
};

const saveGamRootDataToLocalStorage = () => {
    const isGamBackend = backendService.isGamBackend();

    if (!isGamBackend) return;

    let codentify = domService.getDeviceCodentifyFromRoot();
    let dataFactory = domService.getDeviceFactoryFromRoot();

    if (!codentify && !dataFactory) {
        return;
    }

    let deviceDataFromRoot;

    if (codentify && dataFactory) {
        deviceDataFromRoot = {
            codentify,
            dataFactory,
        };
    } else {
        const deviceDataFromLocalStorage = storageService.getDeviceCodentifyFactoryFromLocalStorage();

        let isCodentifyChanged = false;

        if (!codentify) {
            codentify = deviceDataFromLocalStorage?.codentify;
            isCodentifyChanged = true;
        } else if (codentify !== deviceDataFromLocalStorage?.codentify) {
            isCodentifyChanged = true;
        }

        if (dataFactory === '') {
            dataFactory = isCodentifyChanged ? undefined : deviceDataFromLocalStorage?.dataFactory;
        }

        deviceDataFromRoot = {
            codentify,
            dataFactory,
        };
    }

    storageService.setDeviceCodentifyFactoryToLocalStorage(deviceDataFromRoot);
};

const updateGamActivationStatus = async (yapActivationStatus) => {
    const state = getState();
    const gamAsset = makeSelectFirstAsset()(state);

    if (yapActivationStatus && gamAsset?.assetId) {
        gamAsset.yapActivated = yapActivationStatus.toLowerCase() === activationStatusTypes.UAM_ACTIVATED;
        dispatch(setYapAssets([gamAsset]));
        await checkIsDeviceActivatedInGam();
    }
};

const updateIotDevice = (version, batteryCharge, codentify) => {
    const iotDevice = makeSelectIotDeviceData()(getState());

    if (!iotDevice?.device) return;

    const data = {
        device: {
            version,
            battery_charge: batteryCharge,
            codentify,
        },
    };

    dispatch(updateIotDeviceData(data));
};

export default {
    checkIsDeviceActivatedInGam,
    initGamData,
    updateGamDeviceConfig,
    saveGamRootDataToLocalStorage,
    updateGamActivationStatus,
    updateIotDevice,
};
