import React from 'react';

import Icon from './Icon';

const IconSwitchOff = (props) => {
    return (
        <Icon width='24' height='24' viewBox='0 0 36 38' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g strokeWidth='1.5'>
                    <g transform='translate(-219 -756) translate(72 123) translate(127 616) translate(20 17.5) rotate(-90 17.763 17.408)'>
                        <circle cx='17.408' cy='17.408' r='16.658' />
                        <path strokeLinecap='round' d='M28.066 28.776L6.395 6.528' />
                    </g>
                </g>
            </g>
        </Icon>
    );
};

export default IconSwitchOff;
