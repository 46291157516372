const REGISTRATION_ERRORS_DATA = {
    EXTERNAL_SYSTEM_UNAVAILABLE: {
        type: 'EXTERNAL_SYSTEM_UNAVAILABLE',
        code: 'Error #101',
    },
    DEVICE_ALREADY_OWNED: {
        type: 'DEVICE_ALREADY_OWNED',
        code: 'Error #102',
    },
    NOT_FOUND: {
        type: '404_NOT_FOUND',
        code: 'Error #103',
    },
    INVALID_CODENTIFYID: {
        type: 'INVALID_CODENTIFYID',
        code: 'Error #104',
    },
    DEVICE_REMOVED_FROM_MASTER_DB: {
        type: 'DEVICE_REMOVED_FROM_MASTER_DB',
        code: 'Error #105',
    },
    INVALID_DEVICE_STATUS: {
        type: 'INVALID_DEVICE_STATUS',
        code: 'Error #106',
    },
    PURCHASE_DATE_INVALID: {
        type: 'PURCHASE_DATE_INVALID',
        code: 'Error #107',
    },
    DEVICE_ALREADY_YOURS: {
        type: 'DEVICE_ALREADY_YOURS',
        code: 'Error #108',
    },
    UNKNOWN_EXTERNAL_ERROR: {
        type: 'UNKNOWN_EXTERNAL_ERROR',
        code: 'Error #109',
    },
};

export default REGISTRATION_ERRORS_DATA;
