import converter from '../../utils/converter';
import CommunicatorClientBase, {IStateBase} from '../device/communicatorClientBase';
import log from '../logger/log';
import HIDClient from './hidClient';

let instance: HIDCommunicatorClient | null = null;

const RESPONSE_TIMEOUT_MS = 2 * 1000;
const WRITE_FRAME_RETRY_COUNT = 3;
const WRONG_HEADER_RETRY_COUNT = 1;

type IState = IStateBase;

export default class HIDCommunicatorClient extends CommunicatorClientBase<HIDClient, IState> {
    constructor(createNew = false) {
        super();

        if (createNew && instance) {
            instance = null;
        }

        if (instance) {
            return instance;
        }

        instance = this;
        this.isActive = true;

        this.iqosClient = new HIDClient();
        this.resetState();
        this.addOnInputReportListener();
    }

    disable = (): void => {
        this.isActive = false;
        this.clearResponseTimeout();
        this.clearQueue();
    };

    restartListeners = (): void => {
        this.addOnInputReportListener();
    };

    getInitiaState(): IState {
        return {
            currentFrame: undefined,
            framesToResponse: [],
            onError: undefined,
            onSuccess: undefined,
            responseFrameHex: '',
            responses: [],
            retryCount: WRITE_FRAME_RETRY_COUNT,
            wrongHeaderRetryCount: WRONG_HEADER_RETRY_COUNT,
            timeout: 0,
            skipErrorOnTimeout: undefined,
        };
    }

    resetResponseState = (): void => {
        this.state.responseFrameHex = '';
    };

    private addOnInputReportListener = (): void => {
        this.iqosClient.subscribeOnInputReport({onResponse: this.onInputReport});
    };

    private onInputReport = (value: string): void => {
        this.clearResponseTimeout();
        const {framesToResponse, responses} = this.state;
        const responseNumber = responses.length;
        const requestFrame = framesToResponse[responseNumber];
        const isRequestAndResponseHeaderEqual = this.isRequestAndResponseHeaderEqual(requestFrame, value);

        if (isRequestAndResponseHeaderEqual) {
            this.state.responses.push(value);
            this.writeFrames();
        } else {
            const {wrongHeaderRetryCount} = this.state;

            log.info(
                `HIDCommunicatorClient: request: ${requestFrame} and response: ${value} headers are not equel, retry count: ${wrongHeaderRetryCount}`
            );

            if (wrongHeaderRetryCount > 0) {
                this.state.wrongHeaderRetryCount = wrongHeaderRetryCount - 1;

                this.writeFrames();
            } else {
                this.onFrameResponseError();
            }
        }
    };

    private isRequestAndResponseHeaderEqual = (requestFrame: string, response: string): boolean => {
        try {
            const getHeader = (hex: string): string => {
                const frameHeader = hex.substring(6, 10);
                const headerBin = converter.hex2binString(frameHeader).padStart(10, '0').slice(-10);

                return headerBin;
            };
            const requestHeaderBin = getHeader(requestFrame);
            const responseHeaderBin = getHeader(response);
            const isEqualHeader = responseHeaderBin === requestHeaderBin;

            return isEqualHeader;
        } catch (e) {
            return false;
        }
    };

    writeFrames(): void {
        const {framesToResponse, responses, timeout} = this.state;
        const responseNumber = responses.length;
        const itWasLastFrame = !framesToResponse || responseNumber >= framesToResponse.length;

        if (itWasLastFrame) {
            this.onFramesResponse();
        } else {
            const frame = framesToResponse[responseNumber];

            this.resetResponseState();
            setTimeout(() => this.writeFrame(frame), timeout);
        }
    }

    writeFrame = (frame: string): void => {
        if (this.iqosClient.isDeviceConnected()) {
            this.iqosClient.sendReport({command: frame});
            this.initResponseTimeout(frame);
        } else {
            this.clearQueue();
            log.debug(`HIDCommunicatorClient: writeFrame - writing frame: ${frame} is stopped, device is disconnected`);
        }
    };

    initResponseTimeout = (frame: string): void => {
        this.clearResponseTimeout();
        this.responseTimeout = setTimeout(() => {
            this.onResponseTimeout(frame);
        }, RESPONSE_TIMEOUT_MS);
    };

    onResponseTimeout = (frame: string): void => {
        if (this.isActive) {
            const {retryCount, skipErrorOnTimeout} = this.state;

            if (retryCount > 0) {
                this.state.retryCount = retryCount - 1;

                log.debug(
                    `HIDCommunicatorClient: onResponseTimeout, there is no response from device for ${frame} frame. Try to write frame again, retry #${
                        WRITE_FRAME_RETRY_COUNT - this.state.retryCount
                    }, skipErrorOnTimeout: ${skipErrorOnTimeout}`
                );

                this.writeFrames();
            } else {
                if (skipErrorOnTimeout) {
                    this.state.responses.push('');
                    this.writeFrames();
                } else {
                    this.onFrameResponseError();
                }
            }
        } else {
            this.clearQueue();
        }
    };
}
