import {config} from '../config';

export default {
    getIsLedEnabled: () => config.data.deviceFeatures.isLedEnabled,
    getIsVibrationEnabled: () => config.data.deviceFeatures.isVibrationEnabled,
    getIsSelfCleaningEnabled: () => config.data.deviceFeatures.isSelfCleaningEnabled,
    getIsDeviceAlarmEnabled: () => config.data.deviceFeatures.isDeviceAlarmEnabled,
    getIsConnectCareEnabled: () => config.data.deviceFeatures.isConnectCareEnabled,
    getIsParentProtectionEnabled: () => config.data.deviceFeatures.isParentProtectionEnabled,
    getIsVapeCloudSizeEnabled: () => config.data.deviceFeatures.isVapeCloudSizeEnabled,
    getIsResponsiveDrawProfileEnabled: () => config.data.deviceFeatures.isResponsiveDrawProfileEnabled,
    getIsAutoStartEnabled: () => config.data.deviceFeatures.isAutoStartEnabled,
    getIsGestureEnabled: () => config.data.deviceFeatures.isGestureEnabled,
};
