export const IS_SBL_MODE = 'isSBLMode';
export const YAP_ACTIVATION_STATUS_PROPERTY = 'deviceSpecific.yapActivationStatus';
export const YAP_RESPONSIVE_DRAW_PROPERTY = 'deviceSpecific.responsiveDrawProfile.hapticPattern';
export const YAP_VAPE_CLOUD_SIZE_PROPERTY = 'deviceSpecific.vapeCloudSize.heatingProfile';
export const YAP_PARENT_PROTECTION_PROPERTY = 'deviceSpecific.parentProtection.protectionType';
export const YAP_LED_PROPERTY = 'deviceSpecific.ledIntensity.intensity';
export const YAP_FIRMWARE_VERSION_PROPERTY = 'firmwareVersion';
export const YAP_AVAILABLE_FIRMWARE_VERSION_PROPERTY = 'availableFirmwareVersion';
export const YAP_EXT_VIBRATION_PROPERTY = 'deviceSpecific.extHapticProfile.durations.0.durationIsOn';
export const YAP_VIBRATION_FULLY_HEATED_PROPERTY = 'deviceSpecific.heatingVibration.fullyHeated';
export const YAP_VIBRATION_NEAR_END_PROPERTY = 'deviceSpecific.heatingVibration.nearEnd';
export const YAP_VIBRATION_END_OF_HEATING_PROPERTY = 'deviceSpecific.heatingVibration.endOfHeating';
export const YAP_VIBRATION_START_HEATING_PROPERTY = 'deviceSpecific.heatingVibration.startHeating';
export const YAP_VIBRATION_HAPTIC_PROFILE_PROPERTY = 'deviceSpecific.heatingVibration.hapticProfile';
export const YAP_BATTERY_CHARGED_VIBRATION_PROPERTY = 'deviceSpecific.batteryChargedVibration.batteryCharged';
export const YAP_LIFT_UP_GESTURE_PROPERTY = 'deviceSpecific.liftUpGesture.gestureState';
export const YAP_DOUBLE_TAP_GESTURE_PROPERTY = 'deviceSpecific.doubleTapGesture.gestureState';
export const YAP_AUTO_START_HEATING_PROPERTY = 'deviceSpecific.autoStartHeating.featureState';
export const YAP_BATTERY_CHARGED_ONE_EXP_VIBRATION_PROPERTY =
    'deviceSpecific.uiBatteryChargedVibration.batteryChargedOneExp';
export const YAP_BATTERY_CHARGED_TWO_EXP_VIBRATION_PROPERTY =
    'deviceSpecific.uiBatteryChargedVibration.batteryChargedTwoExp';
