export default {
    TUTORIAL_TYPE_BATTERY_GUIDE: 'battery-guide',
    TUTORIAL_TYPE_CLEANING_GUIDE: 'cleaning-guide',
    TUTORIAL_TYPE_HELPFUL_TIPS: 'helpful-tips',
    TUTORIAL_TYPE_HOW_TO_USE_GUIDE: 'how-to-use-guide',
    TUTORIAL_TYPE_POD_GUIDE: 'pod-guide',
    TUTORIAL_TYPE_QUICK_START_GUIDE: 'quick-start-guide',
    TUTORIAL_TYPE_RESPONSIVE_DRAW_GUIDE: 'responsive-draw-guide',
    TUTORIAL_TYPE_ADVANCED_FEATURES: 'advanced-features',
    TUTORIAL_TYPE_RECONNECT_DEVICE_GUIDE: 'reconnect-device-guide',
};
