type THex2bin = (hex: string) => Uint8Array;
type THex2binSized = (hex: string, size: number) => Uint8Array;
type THex2binString = (hex: string) => string;
type TBuffer2hex = (data: DataView) => string;
type TBuffer2hexArr = (data: string) => number[];
type THex2dec = (num: string) => string;
type TConvertBase = (num: string) => {from: (baseFrom: number) => {to: (baseTo: number) => string}};

const hex2bin: THex2bin = (hex) => {
    const bytes = [];

    for (let i = 0; i < hex.length - 1; i += 2) bytes.push(parseInt(hex.substr(i, 2), 16));
    return new Uint8Array(bytes);
};

const hex2binSized: THex2binSized = (hex, size) => {
    const resultArray = new Uint8Array(size);

    for (let i = 0; i < hex.length - 1; i++) resultArray[i] = parseInt(hex.substr(i * 2, 2), 16);

    return resultArray;
};

const buffer2hex: TBuffer2hex = (data) => {
    const out = [];

    for (let i = 0; i < data.byteLength; i++) {
        out.push(('0' + data.getUint8(i).toString(16)).slice(-2));
    }
    return out.join('');
};

const buffer2hexArr: TBuffer2hexArr = (data) => {
    const result = [];

    for (let i = 0; i < data.length; i += 2) {
        result.push(parseInt(data.substr(i, 2), 16));
    }
    return result;
};

const hex2dec: THex2dec = (num) => {
    return ConvertBase(num).from(16).to(10);
};

const ConvertBase: TConvertBase = (num) => {
    return {
        from: (baseFrom) => {
            return {
                to: (baseTo) => {
                    return parseInt(num, baseFrom).toString(baseTo);
                },
            };
        },
    };
};

const hex2binString: THex2binString = (hex) => {
    return parseInt(hex, 16).toString(2).padStart(8, '0');
};

export default {
    buffer2hex,
    buffer2hexArr,
    hex2bin,
    hex2binSized,
    hex2binString,
    hex2dec,
};
