import AES from 'crypto-js/aes';
import {enc, lib} from 'crypto-js/core';
import ZeroPadding from 'crypto-js/pad-zeropadding';

import log from './logger/log';
import tncService from './tncService';

const encryptDeviceSerialNumber = (deviceSerialNumber?: string): string | null => {
    const intToken = tncService.getIntToken();

    if (deviceSerialNumber && intToken) {
        try {
            const key = enc.Base64.parse(intToken);
            const iv = lib.WordArray.random(16);

            const encryptedData = AES.encrypt(deviceSerialNumber, key, {
                iv,
                padding: ZeroPadding,
            });

            const convertWordArrayToUint8Array = (wordArray: lib.WordArray) => {
                const len = wordArray.words.length;
                const u8_array = new Uint8Array(wordArray.sigBytes);
                let offset = 0,
                    word,
                    i;

                for (i = 0; i < len; i++) {
                    word = wordArray.words[i];
                    u8_array[offset++] = word >> 24;
                    u8_array[offset++] = (word >> 16) & 0xff;
                    u8_array[offset++] = (word >> 8) & 0xff;
                    u8_array[offset++] = word & 0xff;
                }
                return u8_array;
            };

            const saltByteArray = Buffer.from(convertWordArrayToUint8Array(iv));
            const deviceSerialNumberByteArray = Buffer.from(convertWordArrayToUint8Array(encryptedData.ciphertext));
            const resultByteArray = Buffer.concat([saltByteArray, deviceSerialNumberByteArray]);

            const encryptedDeviceSerialNumber = resultByteArray.toString('base64');

            const preparedEncryptedDeviceSerialNumber = encodeURI(encryptedDeviceSerialNumber);

            return preparedEncryptedDeviceSerialNumber;
        } catch (e) {
            log.error(`cryptoService: encryptDeviceSerialNumber, error: ${e}`);
        }
    }

    return null;
};

const aesDecrypt = (baseString: string): string | null => {
    try {
        const key = enc.Utf8.parse('16c5d9e5a562f3b546f004b2bc5ec9d9');
        const iv = enc.Utf8.parse('__PMI__BWA__Int_');
        const cipher = enc.Base64.stringify(enc.Hex.parse(baseString));

        const decrypt = AES.decrypt(cipher, key, {
            iv,
        });

        const decrypted = decrypt.toString(enc.Utf8);

        return decrypted;
    } catch (e) {
        log.error(`cryptoService: aesDecrypt, error: ${e}`);
    }
    return null;
};

const decodeBtoa = (baseString: string | null): string | null => {
    if (baseString) {
        return window.btoa(baseString);
    }

    return null;
};

const encodeAtob = (encodedString: string | null): string | null => {
    if (encodedString) {
        return window.atob(encodedString);
    }

    return null;
};

export default {
    decodeBtoa,
    encodeAtob,
    aesDecrypt,
    encryptDeviceSerialNumber,
};
