import React from 'react';

import Icon from './Icon';

const IconSuccess = (props) => {
    return (
        <Icon width='20' height='20' viewBox='0 0 20 20' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g transform='translate(-31 -719) translate(11 701) translate(20 16) translate(0 2)'>
                    <circle cx='10' cy='10' r='10' fill='#A0CA32' />
                    <path
                        stroke='#FFF'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='1.8'
                        d='M6 11.319L7.976 13.292 13.167 8'
                    />
                </g>
            </g>
        </Icon>
    );
};

export default IconSuccess;
