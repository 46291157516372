import React from 'react';

import Icon from './Icon';

const IconSiren = (props) => {
    return (
        <Icon width='45' height='45' viewBox='0 0 45 45' {...props}>
            <g fill='none' fillRule='evenodd' strokeLinecap='round'>
                <g strokeWidth='1.5'>
                    <path
                        d='M0 3.902c6.738 5.202 13.825 5.202 21.262 0 7.437-5.203 14.656-5.203 21.658 0M0 15.607c6.738 5.203 13.825 5.203 21.262 0 7.437-5.202 14.656-5.202 21.658 0M0 27.313c6.738 5.202 13.825 5.202 21.262 0 7.437-5.203 14.656-5.203 21.658 0'
                        transform='translate(-362.000000, -575.000000) translate(72.000000, 123.000000) translate(228.000000, 432.000000) translate(62.000000, 20.000000) translate(1.000000, 6.730000)'
                    />
                </g>
            </g>
        </Icon>
    );
};

export default IconSiren;
