import appConfig from '../../config/appConfig';
import {getDeviceTypesData} from '../../consts/device/deviceTypesData';
import * as iccProductsStatusMessageTypes from '../../consts/icc/iccProductsStatusMessageTypes';
import ICC_PRODUCT_STATUS_TYPES from '../../enums/icc/iccProductStatusTypes';
import {makeSelectIotDeviceData} from '../../state/selectors/iotDevice';
import {getState} from '../../state/store';
import dateTimeUtils from '../../utils/dateTimeUtils';
import stringUtils from '../../utils/stringUtils';
import devicePlatformService from '../device/devicePlatformService';
import deviceTypesService from '../device/deviceTypesService';
import marketConfigService from '../marketConfigService';
import productHelpers from '../product/productHelpers';

export const aggregatedSettingsMapping = (data) => ({
    allMandatoryTermsApproved: data.AllMandatoryTermsApproved,
    allNonMandatoryTermsReviewed: data.AllNonMandatoryTermsReviewed,
    settingsRefreshTimeoutSec: data.SettingsRefreshTimeoutSec,
    consumerAggregatedSettings: data.ConsumerAggregatedSettings.map(consumerAggregatedSettingsMapping),
    intToken: data.IntToken,
});

const consumerAggregatedSettingsMapping = (data) => ({key: data.Key, value: data.Value?.toLowerCase() === 'true'});

export const consumerMapping = (data) => ({
    consumerId: data.ConsumerID,
    defaultLocaleCode: data.DefaultLocaleCode,
});

export const externalConsumerMapping = (data) => ({
    consumerId: data.ConsumerID,
    defaultLocaleCode: data.DefaultLocaleCode,
    hav: data.Hav,
    firstName: data.Firstname,
});

export const consumerProductsMapping = (products) => {
    const deviceFromState = makeSelectIotDeviceData()(getState());
    const {deviceSerialNumber, device} = deviceFromState || {};
    const {codentify} = device || {};

    const mappedProducts = products.map((product) => consumerProductMapping(product, codentify, deviceSerialNumber));
    const notEmptyProducts = mappedProducts.filter((x) => x);

    return notEmptyProducts;
};

const consumerProductMapping = (data, iotCodentify, iotInternalId) => {
    const codentify = data.CodentifyID;
    let deviceSerialNumber = data.DeviceSerialNumber;

    if (!deviceSerialNumber && codentify === iotCodentify) {
        deviceSerialNumber = iotInternalId;
    }

    if (!deviceSerialNumber) return null;

    const deviceType = devicePlatformService.getDeviceTypeBySerialNumber(deviceSerialNumber);
    const deviceTypeData = getDeviceTypesData()[deviceType];

    if (deviceTypeData) {
        const isDeviceAlreadyYoursStatusMessage =
            data.StatusMessage === iccProductsStatusMessageTypes.DEVICE_ALREADY_YOURS; //this is only needed to ignore the status message DEVICE_ALREADY_YOURS and not change status to failed

        return {
            deviceSerialNumber,
            codentify,
            productId: data.ProductID,
            pairingStatus: data.PairingStatus,
            productName: data.ProductName,
            status: isDeviceAlreadyYoursStatusMessage ? ICC_PRODUCT_STATUS_TYPES.REGISTERED : data.Status,
            isHolder: deviceTypeData.isHolder,
            purchaseDate: new Date(data.PurchasedOn).toUTCString(),
        };
    }
};

export const consumerProductsStatusMapping = (data) => data.map(deviceRegistrationMapping);

export const consumerProductsInfoMapping = (products) => {
    const mappedProducts = products.map(consumerProductInfoMapping);
    const notEmptyProducts = mappedProducts.filter((x) => x);

    return notEmptyProducts;
};

const consumerProductInfoMapping = (product) => {
    if (product?.DeviceSerialNumber) {
        const type = devicePlatformService.getDeviceTypeBySerialNumber(product.DeviceSerialNumber);
        const isDeviceSupported = marketConfigService.isDeviceSupported(type);

        return {
            colorHex: productHelpers.getHexColorByMaterialColor(type, product.Material?.MaterialColor),
            deviceSerialNumber: product.DeviceSerialNumber,
            mediaId: getMediaId(product.Material),
            pairingStatus: product.PairingStatus,
            productName: product.ProductName,
            defaultProductName: deviceTypesService.getProductNameByType(type),
            isDeviceSupported,
            type,
        };
    }

    return null;
};

const getMediaId = (material) => {
    try {
        const mediaIdParts = [
            material.MaterialGroup,
            material.MaterialBrandFamily,
            material.MaterialModelNumber,
            material.MaterialColor,
        ];
        const mediaName = mediaIdParts.join('_');

        return assetMapping(mediaName);
    } catch (e) {
        return null;
    }
};

export const holderAssetMapping = (mediaName) => mediaIdMapping(mediaName, appConfig.getHolderAssetsPath());
export const assetMapping = (mediaName) => mediaIdMapping(mediaName, appConfig.getAssetsPath());
export const mediaIdMapping = (mediaName, assetsPath) => stringUtils.formatString(assetsPath, mediaName);

export const mediaUrlsMapping = (data) =>
    data.map((item) => {
        const expirationDate = new Date(item.ExpirationDate).getTime();

        return {
            mediaId: item.MediaId,
            url: item.Url,
            expirationDate,
            isExpired: () => dateTimeUtils.isExpired(expirationDate),
        };
    });

export const deviceRegistrationMapping = (data) => {
    const isDeviceAlreadyYoursStatusMessage = data.StatusMessage === iccProductsStatusMessageTypes.DEVICE_ALREADY_YOURS; //this is only needed to ignore the status message DEVICE_ALREADY_YOURS and not change status to failed

    return {
        codentify: data.CodentifyID,
        deviceSerialNumber: data.DeviceSerialNumber,
        productID: data.ProductID,
        parentProductId: data.ParentProductID,
        status: isDeviceAlreadyYoursStatusMessage ? ICC_PRODUCT_STATUS_TYPES.REGISTERED : data.Status,
        statusMessage: isDeviceAlreadyYoursStatusMessage ? null : data.StatusMessage,
    };
};

export const deviceActivationStatusMapping = (data) => data.ActivationStatus;

export const deviceUnregistrationMapping = (data) => {
    const unregistrationData = data[0];

    return {
        codentify: unregistrationData.CodentifyID,
        deviceSerialNumber: unregistrationData.DeviceSerialNumber,
        productID: unregistrationData.ProductID,
        parentProductId: unregistrationData.ParentProductID,
        status: unregistrationData.Status,
        statusMessage: unregistrationData.StatusMessage,
    };
};

export const tncMapping = (data) => data.map(tncItemMapper);

const tncItemMapper = (data) => ({
    description: data.Description,
    updateDescription: data.UpdateDescription,
    groupName: data.GroupName,
    isMandatory: data.IsMandatory,
    legals: data.Legals.map(tncLegalMapper),
    position: data.Position,
    screenId: data.ScreenId,
});

const tncLegalMapper = (data) => ({
    changeList: data.ChangeList,
    code: data.Code,
    description: data.Description,
    externalTermAndConditionID: data.ExternalTermAndConditionID,
    isInformational: data.IsInformational,
    isMandatory: data.IsMandatory,
    isRevoked: data.IsRevoked,
    position: data.Position,
    settings: data.Settings,
    text: data.Text,
    title: data.Title,
    versionNumber: data.VersionNumber,
    webLink: data.WebLink,
});
