import {useEffect, useRef} from 'react';

const useDocumentTitle = (title, returnOnUnmount) => {
    const previousTitle = useRef(document.title);
    const setTitle = (title) => {
        if (title) {
            document.title = title;
        }
    };

    useEffect(() => {
        setTitle(title);
    }, [title]);

    useEffect(() => {
        return () => {
            if (title && returnOnUnmount) {
                setTitle(previousTitle.current);
            }
        };
    }, []);
};

export default useDocumentTitle;
