import {createSelector} from 'reselect';

import productHelpers from '../../services/product/productHelpers';
import {makeSelectIotDeviceData} from './iotDevice';

const selectProductSettings = (state) => state.productSettings;

const makeSelectProductsToChangeRegistrationStatus = () =>
    createSelector(selectProductSettings, (state) => state.productsToChangeRegistrationStatus);

const makeSelectCurrentProductToChangeRegistrationStatus = (isHolderCheckNeeded) =>
    createSelector(
        makeSelectProductsToChangeRegistrationStatus(),
        makeSelectIotDeviceData(),
        (productsToChangeRegistrationStatus, iotDevice) => {
            const {deviceSerialNumber, holder, device} = iotDevice;
            const {codentify, isP4} = device || {};
            let result;

            if (productsToChangeRegistrationStatus?.length && (deviceSerialNumber || codentify)) {
                const getProductToChangeRegistrationStatus = (serialNumber, codentifyId) =>
                    productHelpers.findProduct({
                        products: productsToChangeRegistrationStatus,
                        isP4,
                        deviceSerialNumber: serialNumber,
                        codentify: codentifyId,
                    });

                switch (isHolderCheckNeeded) {
                    case true:
                        if (!result && holder?.deviceSerialNumber) {
                            result = getProductToChangeRegistrationStatus(holder.deviceSerialNumber);
                        }
                        break;
                    case false:
                        result = getProductToChangeRegistrationStatus(deviceSerialNumber, codentify);
                        break;
                    default:
                        result = getProductToChangeRegistrationStatus(deviceSerialNumber, codentify);

                        if (!result && holder?.deviceSerialNumber) {
                            result = getProductToChangeRegistrationStatus(holder.deviceSerialNumber);
                        }
                }
            }

            result = result ? {...result, isP4} : result;

            return result;
        }
    );

const makeSelectDevicePurchaseDate = () => createSelector(selectProductSettings, (state) => state.devicePurchaseData);
const makeSelectHolderPurchaseDate = () => createSelector(selectProductSettings, (state) => state.holderPurchaseData);

export {
    makeSelectProductsToChangeRegistrationStatus,
    makeSelectCurrentProductToChangeRegistrationStatus,
    makeSelectDevicePurchaseDate,
    makeSelectHolderPurchaseDate,
};
