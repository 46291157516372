import {config} from '../config';

export default {
    getIsICCConsumerServiceEnabled: () => config.data.iccConsumerServices.isICCConsumerServiceEnabled,
    getIsDeviceListEnabled: () => config.data.iccConsumerServices.isDeviceListEnabled,
    getIsDeviceRegistrationEnabled: () => config.data.iccConsumerServices.isDeviceRegistrationEnabled,
    getIsDeviceRenameEnabled: () => config.data.iccConsumerServices.isDeviceRenameEnabled,
    getIsDeviceUnregistrationEnabled: () => config.data.iccConsumerServices.isDeviceUnregistrationEnabled,
    getIsTNCEnabled: () => config.data.iccConsumerServices.isTNCEnabled,
};
