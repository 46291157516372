import cn from 'classnames';
import React, {FC, forwardRef} from 'react';
import MaskedInput from 'react-text-mask';

import PurchaseDateIcon from '../../../../../components/Icons/PurchaseDateIcon';
import {ICustomInput} from '../../PurchaseDateFullPagePopupTypes';
import styles from './CustomInput.module.scss';

// eslint-disable-next-line react/display-name
const CustomInput: FC<ICustomInput> = forwardRef((props, ref: any) => {
    const {isEmptyDate, inputMask, inputLabelText, incorrectDateErrorText, ...rest} = props;

    return (
        <div className={styles.InputWrapper}>
            <MaskedInput
                required
                {...rest}
                className={cn(styles.DatePicker, {[styles.EmptyDatePicker]: isEmptyDate})}
                ref={ref}
                mask={inputMask}
            />
            <label className={styles.InputLabel}>{inputLabelText}</label>
            <div {...rest} className={styles.IconContainer}>
                <PurchaseDateIcon className={cn(styles.PurchaseDateIcon)} />
            </div>
            {isEmptyDate && <div className={styles.IncorrectDate}>{incorrectDateErrorText}</div>}
        </div>
    );
});

export default CustomInput;
