import React from 'react';

import Icon from './Icon';

const IconIqosPulseLogo = (props) => {
    return (
        <Icon width='65' height='61' viewBox='0 0 65 61' {...props}>
            <path
                fill='#272A33'
                fillRule='evenodd'
                d='M10.768 39.864c-1.662-1.895-2.748-4.776-2.82-9.306V7.583h29.506c3.904.164 6.725.988 8.605 2.717.073.083.362.41.507.494 1.663 1.895 2.748 4.777 2.82 9.306v22.974H19.882c-3.905-.247-6.726-1.069-8.606-2.716-.072-.083-.362-.411-.507-.494M.5 4.288v26.189c0 6.504 1.663 10.868 4.339 13.751 4.411 4.942 11.714 5.847 18.874 5.847h26.542l8.027 9.142c1.373 1.564 3.325 1.481 4.846-.247 1.59-1.812 1.59-4.037.362-5.436l-6.654-7.577V20.1c0-6.506-1.665-10.871-4.339-13.754C48.084 1.407 40.781.5 33.621.5H4.187C1.946.5.5 2.065.5 4.288'
            />
        </Icon>
    );
};

export default IconIqosPulseLogo;
