const ACTIVATION_ERRORS_DATA = {
    ERROR_IOT_SYSTEM_ERROR: {
        type: 'ERROR_IOT_SYSTEM_ERROR',
        code: 'Error #101',
    },
    ERROR_ACTIVATION_FAILED: {
        type: 'ERROR_ACTIVATION_FAILED',
        code: 'Error #102',
    },
    ERROR_ACTIVATION_TIMEOUT_ERROR: {
        type: 'ERROR_ACTIVATION_TIMEOUT_ERROR',
        code: 'Error #103',
    },
    ERROR_ACTIVATION_PAUSED: {
        type: 'ERROR_ACTIVATION_PAUSED',
        code: 'Error #104',
    },
};

export default ACTIVATION_ERRORS_DATA;
