import React from 'react';

import WarningIcon from '../Icons/WarningIcon';
import PopupHeaderIcon from './components/PopupHeaderIcon/PopupHeaderIcon';
import Popup from './Popup';

const PopupWarning = (props) => {
    const {alt} = props;

    return (
        <Popup {...props}>
            <PopupHeaderIcon ariaLabel={alt} icon={WarningIcon} />
            {props.children}
        </Popup>
    );
};

export default PopupWarning;
