import React from 'react';
import {useSelector} from 'react-redux';

import * as localizationKeys from '../../../consts/localization/localizationKeys';
import backendService from '../../../services/app/backendService';
import connectivityService from '../../../services/device/connectivityService';
import iotDeviceConnectionStatusService from '../../../services/iotDevice/iotDeviceConnectionStatusService';
import iotDeviceFWUStateService from '../../../services/iotDevice/iotDeviceFWUStateService';
import layoutService from '../../../services/layoutService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../../state/selectors/consumer';
import {makeSelectLayoutType} from '../../../state/selectors/global';
import {makeSelectDeviceConnectionStatus} from '../../../state/selectors/iotDevice';
import {selectMwDeviceFirmwareData} from '../../../state/selectors/mwDevice';
import elementsTesIds from '../../../test/consts/elementsTesIds';
import updateFWPopupData from './updateFWPopupData';
import UpdateFWPopupTemplate from './UpdateFWPopupTemplate';

const UpdateFWPopup = (props) => {
    const {isFWUAvailable, isHolderUpdate, isUpdateFWUpdateError, onClose, onStartUpdate, usbReconnectRedirectUrl} =
        props;
    const firmwareData = useSelector(selectMwDeviceFirmwareData);
    const iotProduct = useSelector(makeSelectIotDeviceMergedWithIccProduct());
    const deviceConnectionStatus = useSelector(makeSelectDeviceConnectionStatus());
    const layout = useSelector(makeSelectLayoutType());
    const {device, holder} = firmwareData;
    const {package_available} = (isHolderUpdate ? holder : device) || {};
    const {availableFirmwares} = (isHolderUpdate ? iotProduct?.holder?.firmware : iotProduct?.device?.firmware) || {};
    const localizedStrings = getLocalizedStrings();
    const ariaCommonProps = {
        popupIconAlt: localizedStrings[localizationKeys.FIRMWARE_UPDATE_NOTIFICATION_IMG_WARNING_ALT],
        ariaLabelClose: localizedStrings[localizationKeys.FIRMWARE_UPDATE_NOTIFICATION_BTN_CLOSE_ARIA_LABEL],
    };
    const isGamSBLMode =
        backendService.isGamBackend() &&
        package_available &&
        iotDeviceFWUStateService.isSblFirmware(availableFirmwares);
    const isFwuInstructionsPopupVisible = isHolderUpdate && isFWUAvailable && connectivityService.isBle();
    let popupProps;

    if (isUpdateFWUpdateError || isGamSBLMode || isFwuInstructionsPopupVisible) {
        const isDeviceDisconnected = iotDeviceConnectionStatusService.isDeviceDisconnected(deviceConnectionStatus);
        const isDesktopLayout = layoutService.isDesktopLayout(layout);
        const {descriptionPart1, primaryBtnText, primaryBtnAction} = updateFWPopupData.getSBLPopupProps(
            iotProduct?.device?.type,
            isDeviceDisconnected,
            isDesktopLayout,
            usbReconnectRedirectUrl
        );

        popupProps = {
            title: localizedStrings[localizationKeys.FIRMWARE_SBL_FOUND_POPUP_TITLE],
            descriptionPart1,
            descriptionPart2: '',
            secondaryBtnText: localizedStrings[localizationKeys.FIRMWARE_SBL_FOUND_POPUP_GOT_IT_BUTTON],
            secondaryBtnAction: onClose,
            primaryBtnText,
            primaryBtnAction,
        };
    } else if (package_available || isFWUAvailable) {
        popupProps = {
            title: localizedStrings[localizationKeys.ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_TITLE],
            descriptionPart1: localizedStrings[localizationKeys.ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_DESCRIPTION_PART_1],
            descriptionPart2: localizedStrings[localizationKeys.ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_DESCRIPTION_PART_2],
            secondaryBtnText: localizedStrings[localizationKeys.ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_BUTTON_SECONDARY],
            secondaryBtnAction: onStartUpdate,
            primaryBtnText: localizedStrings[localizationKeys.ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_BUTTON_PRIMARY],
            primaryBtnAction: onClose,
            ariaLabelCancel: localizedStrings[localizationKeys.FIRMWARE_UPDATE_NOTIFICATION_BTN_CANCEL_ARIA_LABEL],
            primaryBtnTestId: elementsTesIds.FWU_BEFORE_START_CANCEL_BTN,
            secondaryBtnTestId: elementsTesIds.FWU_BEFORE_START_START_BTN,
        };
    } else {
        popupProps = {
            title: localizedStrings[localizationKeys.FIRMWARE_UPDATE_NOT_NEEDED_TITLE],
            descriptionPart1: localizedStrings[localizationKeys.FIRMWARE_UPDATE_NOT_NEEDED_DESCRIPTION],
            descriptionPart2: '',
            secondaryBtnText: localizedStrings[localizationKeys.FIRMWARE_UPDATE_NOT_NEEDED_CONFIRM_TEXT],
            secondaryBtnAction: onClose,
            primaryBtnText: null,
            primaryBtnAction: onClose,
            secondaryBtnTestId: elementsTesIds.FWU_NOT_NEEDED_GOT_IT_BTN,
        };
    }

    return <UpdateFWPopupTemplate onClose={onClose} {...popupProps} {...ariaCommonProps} />;
};

export default UpdateFWPopup;
