import {setConsumerProductsInfo} from '../../state/ducks/consumer';
import {addSignedMedia} from '../../state/ducks/media';
import {makeSelectConsumerProductsMergedInfo} from '../../state/selectors/consumer';
import {dispatch, getState} from '../../state/store';
import arrayUtils from '../../utils/arrayUtils';
import cryptoService from '../cryptoService';
import deviceAssetService from '../device/deviceAssetService';
import mediaService from '../mediaService';
import iccMarketService from './iccMarketService';

const getDetailedProducts = async () => {
    const consumerProducts = await iccMarketService.getConsumerProducts();

    await getProductsInfo(consumerProducts);
    await getProductsAssetsByProducts();
};

const MAX_PRODUCTS_COUNT_PER_ONE_REQUEST = 10;

const getProductsInfo = async (consumerProducts) => {
    if (!consumerProducts) return;

    const encryptedProductsIds = consumerProducts
        .map(({deviceSerialNumber}) => cryptoService.encryptDeviceSerialNumber(deviceSerialNumber))
        .filter((id) => id);
    const productsIdsSplitted = arrayUtils.splitArray(encryptedProductsIds, MAX_PRODUCTS_COUNT_PER_ONE_REQUEST);

    const productsInfoRequestsList = productsIdsSplitted.map((consumerProductsIdsChunk) =>
        iccMarketService.getProductInfoList({serialNumbers: consumerProductsIdsChunk})
    );

    return Promise.all(productsInfoRequestsList).then((productsInfoList) => {
        const productsInfoListMerged = [].concat(...productsInfoList);
        const productsInfoFiltered = productsInfoListMerged.filter((productInfo) => !!productInfo);

        dispatch(setConsumerProductsInfo(productsInfoFiltered));

        return productsInfoListMerged;
    });
};

const getProductsAssetsByProducts = async () => {
    const consumerProducts = makeSelectConsumerProductsMergedInfo()(getState());

    if (!consumerProducts) return [];

    const mediaNamesList = consumerProducts.map(({mediaId}) => mediaId);
    const mediaNamesWithThumbnailsList = deviceAssetService.getDeviceAssetsList(mediaNamesList);

    return getProductsAssets(mediaNamesWithThumbnailsList);
};

const getProductsAssets = async (mediaNames) => {
    const assetsFromState = mediaService.getSignedMediaList();
    const mediaNamesList = Array.isArray(mediaNames) ? arrayUtils.unique(mediaNames) : [mediaNames];
    const mediaNamesToSign = mediaNamesList.filter(
        (mediaName) => !assetsFromState.some((asset) => asset.mediaId === mediaName && !asset.isExpired())
    );

    if (mediaNamesToSign.length) {
        const productAssets = await iccMarketService.getMediaUrl({mediaNameList: mediaNamesToSign});

        if (productAssets) {
            dispatch(addSignedMedia(productAssets));
        } else {
            dispatch(setConsumerProductsInfo(undefined));
        }

        return productAssets;
    } else {
        return [];
    }
};

export default {
    getDetailedProducts,
    getProductsAssets,
};
