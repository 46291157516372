import React from 'react';

import Icon from './Icon';

const AutoStartIcon = (props) => {
    return (
        <Icon width='46' height='42' viewBox='0 0 46 42' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g strokeWidth='2'>
                    <path
                        fillRule='nonzero'
                        d='M18.896 11.215c.53-.281 1.208-.306 1.808.054h0l12.464 7.467c.538.31.832.812.832 1.33 0 .517-.292 1.017-.833 1.332h0l-12.444 7.361c-.616.334-1.313.303-1.855.015-.264-.14-.492-.342-.649-.595-.14-.226-.219-.494-.219-.79h0V12.65c0-.305.084-.583.23-.819.16-.261.394-.472.666-.616z'
                        transform='translate(-452 -329) translate(375 248) translate(60 60) translate(18 22)'
                    />
                    <g strokeLinecap='round'>
                        <path
                            d='M6.64 29.94C10.09 35.95 16.573 40 24 40c11.046 0 20-8.954 20-20S35.046 0 24 0 4 8.954 4 20'
                            transform='translate(-452 -329) translate(375 248) translate(60 60) translate(18 22)'
                        />
                        <path
                            strokeLinejoin='round'
                            d='M0 21.802L4.101 18 8 22'
                            transform='translate(-452 -329) translate(375 248) translate(60 60) translate(18 22) rotate(-180 4 20)'
                        />
                    </g>
                </g>
            </g>
        </Icon>
    );
};

export default AutoStartIcon;
